import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  Typography,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ControlCameraRoundedIcon from "@material-ui/icons/ControlCameraRounded";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";

import { useDebouncedCallback } from "use-debounce";

import DialogCretaeProject from "./components/dialogs/DialogCretaeProject";
import TableTeam from "./components/tables/TableTeam";
import ContextProject from "./context/ContextProject";
import { SEARCH_PROJECT, TOGGLE_PROJECT } from "./context/types";
import TablePersonal from "./components/tables/TablePersonal";
import AnchorFilterProject from "./components/anchor/AnchorFilterProject";
import AnchorSortProject from "./components/anchor/AnchorSortProject";
import ListFilterPorject from "./components/task_components/ListFilterPorject";

const ViewTodoList = ({ classes }) => {
  const { setTriggerProject, projectTDispatch, projectTState } =
    useContext(ContextProject);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    statusUserLoginAfterParse?.member_position !== null
      ? statusUserLoginAfterParse.member_position?.is_superior
      : false;

  const selection = [
    {
      name: "Team Project",
      code: "TEAM",
      toggle: true,
    },
    {
      name: "Personal Project",
      code: "PERSONAL",
      toggle: false,
    },
  ];

  let defaulProject = { name: "Team Project", code: "TEAM", toggle: true };
  const defaultTeam = selection[0];
  const defaultPersonal = selection[1];

  if (role === "superadmin") {
    defaulProject = defaultTeam;
  } else if (superior) {
    defaulProject = defaultTeam;
  } else {
    if (projectTState.projectData?.length > 0) {
      defaulProject = defaultTeam;
    }

    if (
      projectTState.projectData?.length === 0 &&
      projectTState.personalData?.length > 0
    ) {
      defaulProject = defaultPersonal;
    }
  }

  const [projectItem, setProjectItem] = useState(defaulProject);
  const [anchor, setAnchor] = useState(null);
  const [openCreateProject, setOpenCreateProject] = useState(false);

  useEffect(() => {
    projectTDispatch({
      type: TOGGLE_PROJECT,
      payload: {
        toggleProject: projectItem.toggle,
        projectType: projectItem.code,
      },
    });
  }, [projectItem]);

  const projectSelection = [
    {
      name: "Team Project",
      code: "TEAM",
      toggle: true,
      icon: (
        <ControlCameraRoundedIcon
          style={{
            fontSize: 17,
            color: projectItem.code === "TEAM" ? "#d64253" : "#333",
          }}
        />
      ),
    },
    {
      name: "Personal Project",
      code: "PERSONAL",
      toggle: false,
      icon: (
        <PersonOutlineRoundedIcon
          style={{
            fontSize: 17,
            color: projectItem.code === "PERSONAL" ? "#d64253" : "#333",
          }}
        />
      ),
    },
  ];

  const handleShow = (e) => {
    setAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleProjectSelect = (data) => {
    setProjectItem(data);
    setAnchor(null);
    projectTDispatch({
      type: TOGGLE_PROJECT,
      payload: { toggleProject: data.toggle, projectType: data.code },
    });
  };

  const [handleSearch] = useDebouncedCallback(
    useCallback((value) => {
      projectTDispatch({
        type: SEARCH_PROJECT,
        payload: { searchProject: value },
      });
      setTriggerProject(Math.floor(Math.random() * 100));
    }, []),
    1000,
    { maxWait: 5000 }
  );

  return (
    <Box ml={9} mr={1} p={!projectTState.toggleProject && 1.5}>
      <Paper className={classes.shadowSection}>
        <Box p={2}>
          <Button
            size="large"
            variant="text"
            className={classes.button0}
            endIcon={<ArrowDropDownIcon />}
            onClick={handleShow}
            style={{ fontSize: 22, fontWeight: "normal" }}
          >
            {projectItem.name}
          </Button>
          <Menu
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {projectSelection.map((item, i) => {
              const isCurrent = item.code === projectItem.code;

              return (
                <MenuItem
                  value={item.code}
                  key={i}
                  onClick={() => handleProjectSelect(item)}
                >
                  <Box display="flex" alignItems="center">
                    {item.icon}
                    <Typography
                      variant="subtitle2"
                      style={{
                        marginLeft: 7,
                        color: isCurrent ? "#d64253" : "#555",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </Menu>

          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <TextField
                size="small"
                variant="outlined"
                placeholder="Search Projects"
                onChange={(e) => handleSearch(e.target.value)}
                style={{
                  outline: "none",
                  backgroundColor: "#fff",
                  border: "1px solid #DFDFDF",
                  fontSize: 16,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon
                        style={{ color: "#B1B1B1", fontSize: 16 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Box mx={1} />
              <AnchorFilterProject classes={classes} />
              <Box mx={1} />
              <AnchorSortProject classes={classes} />
              <Box mx={1} />
              <ListFilterPorject classes={classes} />
            </Box>

            {projectItem.code === "TEAM" && (
              <Button
                size="small"
                varian="outlined"
                className={classes.button1}
                startIcon={<AddIcon />}
                onClick={() => setOpenCreateProject(true)}
              >
                Project
              </Button>
            )}
          </Box>

          {/* TABLES HERE */}
          <Box
            mt={projectTState.toggleProject ? 4 : 0}
            mb={projectTState.toggleProject ? 2 : 0}
          >
            {projectItem.toggle && <TableTeam classes={classes} />}
          </Box>
        </Box>
      </Paper>
      {!projectItem.toggle && <TablePersonal classes={classes} />}

      <DialogCretaeProject
        classes={classes}
        openCreateProject={openCreateProject}
        setOpenCreateProject={setOpenCreateProject}
      />
    </Box>
  );
};

export default ViewTodoList;
