import { title } from "./styleKIt";

const weDoStyle = (theme) => ({
  title333: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#888",
    textDecoration: "none",
  },
  titleHelp: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#8F8F8F",
    textDecoration: "none",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  titleActive: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#FCE9EA",
    textDecoration: "none",
    textAlign: "center",
    backgroundColor: "#C8203C",
    // borderBottom: "1px solid #C8203C",
    borderRadius: 5,
    padding: "4px 18px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  headTitle: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#F2F4F7",
  },
  cardWeDo: {
    borderRadius: 15,
    padding: "7px 30px 0px 30px",
    // border: "3px solid #FCE9EA",
    // margin: "0 10px",
    // "@media (min-width: 576px)": {
    //   margin: 0,
    // },
  },
  btnLearn: {
    color: "#d64253",
    padding: "0px",
    fontWeight: "700",
    fontSize: "16px",
    textTransform: "capitalize",
    border: "none",
    backgroundColor: "transparent",
  },
});

export default weDoStyle;
