import React, { useContext, useEffect, useState } from "react";
import { styles } from "../Style/StyleCheckInCheckOut";
import { navigate } from "hookrouter";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  Box,
  Button,
  Paper,
  List,
  ListItem,
} from "@material-ui/core";
import { CancelRounded, CheckCircle, Domain } from "@material-ui/icons";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const HistoryItem = ({ data }) => {
  const classes = useStyles();

  const [location, setLocation] = useState(null);

  const getLocationByLatLng = () => {
    const lat = data.clock_out
      ? data.clock_out_coordinates_lat
      : data.clock_in_coordinates_lat;
    const lng = data.clock_out
      ? data.clock_out_coordinates_long
      : data.clock_in_coordinates_long;

    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=AIzaSyBcNgwF6ypP2rL1Ma4FlmH4JjPIGBDI9Ds"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        setLocation(responseJson.results[0].formatted_address);
      });
  };

  useEffect(() => {
    getLocationByLatLng();
  }, [data]);

  return (
    <MuiThemeProvider theme={theme}>
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background: "#F5F5F5",
          borderRadius: 11,
          padding: "10px 20px",
          marginTop: "20px",
        }}
        variant="outlined"
      >
        <List>
          <ListItem>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography
                  variant="body1"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    fontWeight: "bold",
                  }}
                >
                  ● Check {data.clock_out ? "Out" : "In"}
                  {data.status_attendance === "1" ? (
                    <CheckCircle
                      style={{
                        color: "#1AC692",
                        marginLeft: "1rem",
                      }}
                    />
                  ) : (
                    <CancelRounded
                      style={{ color: "#CE354A", marginLeft: "1rem" }}
                    />
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#262261", marginLeft: "1rem" }}
                >
                  <b>
                    {data.clock_out
                      ? moment(data.clock_out).format("HH:mm")
                      : moment(data.clock_in).format("HH:mm")}
                  </b>
                  , di <b>{location}</b>
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: "#262261",
                    marginLeft: "1rem",
                  }}
                >
                  Catatan:{" "}
                  {data.clock_out ? data.clock_out_note : data.clock_in_note}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body1"
                  style={{ color: "#221E5B", textAlign: "right" }}
                >
                  Hari Ini
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Paper>
    </MuiThemeProvider>
  );
};

export default HistoryItem;
