import React, { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";
// import { URL_API } from '../../../../constants/config-api';

import ContextTimeOff from "./ContextTimeOff";

const ContextGlobalTimeOff = (props) => {
  /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
  const [userTokenState, setUserTokenState] = useState("");

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    setUserTokenState(userToken);
  }, []);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isSnackbarResponse200, setSnackbarResponse200] = useState(false);
  const [messages, setMessages] = useState("");
  
  const [isModalProgress, setModalProgress] = useState(false);
  
  const [isModalDetail, setModalDetail] = useState(false);

  const [isModalDefaultNotFound, setModalDefaultNotFound] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [loader, setLoader] = useState(false);

  // Show / Hide Completed TDL
  const [showhideCompleted, setShowhideCompleted] = useState(false);

  /*
        `````
        FADE

        `````
    */
  const [isRunFade, setRunFade] = useState(false);


  /*
  ````````````````````
  HANDLE DIALOG ERRROR
  ````````````````````
  */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  return (
    <ContextTimeOff.Provider
      value={{
        setUserTokenState: setUserTokenState,
        userTokenState: userTokenState,

        setSnackbarResponse200: setSnackbarResponse200,
        isSnackbarResponse200: isSnackbarResponse200,

        setMessages: setMessages,
        messages: messages,

        setAnchorEl: setAnchorEl,
        anchorEl: anchorEl,

        loader: loader,
        setLoader: setLoader,

        setRunFade: setRunFade,
        isRunFade: isRunFade,

        showhideCompleted,
        setShowhideCompleted,
        
        isModalProgress: isModalProgress,
        setModalProgress: setModalProgress,
        
        isModalDetail: isModalDetail,
        setModalDetail: setModalDetail,
        
        isModalDefaultNotFound: isModalDefaultNotFound,
        setModalDefaultNotFound: setModalDefaultNotFound,

        /*
        ````````````````````
        HANDLE DIALOG ERRROR
        ````````````````````
        */
        isOpenDialogError: isOpenDialogError,
        setOpenDialogError: setOpenDialogError,

        textErrorInformation: textErrorInformation,
        setTextErrorInformation: setTextErrorInformation,

        errorStatus: errorStatus,
        setErrorStatus: setErrorStatus,

        listError: listError,
        setListError: setListError,
      }}>
      {props.children}
    </ContextTimeOff.Provider>
  );
};

export default ContextGlobalTimeOff;
