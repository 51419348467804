import React, { Fragment, useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Grid,
  Divider,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";

import useScrollTop from "react-hook-scrolltop";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { navigate } from "hookrouter";

import ImageSmallUser from "../components/ImageSmallUser";
import { StyledMenu, StyledMenuItem } from "../components/StyledMenuDropdown";

import DialogHelp from "../components/DialogHelp";
import PictBackgroundInHeader from "../assets/images/md_2.jpg";

import { lightBlue, grey, red, green } from "@material-ui/core/colors";

import Redirect from "../utilities/Redirect";
import {
  ToMembershipStatus,
  ToCompanySetting,
  ToCompanyProfile,
  ToRole,
  ToUserManagement,
  ToLogin,
  ToEmptyStateGeneral,
} from "../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiButton: {
      text: {
        color: "white",
      },
      textSecondary: {
        color: "white",
        fontFamily: "Roboto",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: lightBlue,
      },
      paper: {
        backgroundColor: green,
      },

      paperAnchorLeft: {},
    },
    MuiListItemIcon: {
      root: {
        color: "white",
      },
    },
    MuiBadge: {
      root: {
        color: "#d1354a",
      },
    },
  },
});

const styles = (theme) => ({
  list: {
    width: 250,
  },

  fullList: {
    width: "auto",
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  search: {
    position: "relative",
    borderRadius: 3,
    backgroundColor: grey,
    marginRight: 1 * 2,
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    color: "grey",
  },
  sectionDesktop: {
    display: "flex",
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  accountCircle: {
    color: "white",
  },
  menuIcon: {
    color: "grey",
  },
  mail: {
    color: "white",
  },

  notifIcon: {
    color: "white",
  },
  list: {
    backgroundColor: "red",
  },
  listDrawer: {
    backgroundColor: "red",
  },

  /* DRAWER */
  drawerRoot: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "transperent",
    color: "#707070",
  },
  nested: {
    paddingLeft: 7,
  },

  icon: {
    margin: 1 * 2,
  },
  iconHover: {
    margin: 1 * 2,
    "&:hover": {
      color: red[800],
    },
  },

  imageSvg: {
    width: 27,
    height: 27,
    marginLeft: 8,
  },
  iconMenuOffCanvass: {
    width: 27,
    height: 27,
  },
  buttonIconMenuOffCanvass: {
    padding: 0,
    marginLeft: 127,
    marginBottom: 12,
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonBackDashboard: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 5,
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "grey",
  },
  iconArrowLeft: {
    marginRight: theme.spacing(1),
  },
  buttonMenuBesiArrowLeft: {
    width: "27px",
    height: "27px",
    borderRadius: 2,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    padding: 0,
  },
  nav: {
    color: "#707070",
    fontFamily: "Roboto",
  },
  navActive: {
    color: "#c1272d",
    fontFamily: "Roboto",
  },
  perbaruiSampul: {
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(22),
    marginBottom: theme.spacing(1),
  },
  nested: {
    // paddingLeft: theme.spacing.unit * 4,
    paddingLeft: 7,
  },
  iconMenuOffCanvass: {
    width: 30,
    height: 30,
    cursor: "pointer",
    marginTop: 24,
  },
  title: {
    fontFamily: "Roboto",
  },
});

const HeaderProfileDetail = (props) => {
  const { classes } = props;

  const [state, setState] = useState({
    top: false,
    // left: true,
    left: false,
    bottom: false,
    right: false,
  });

  const handleCloseOffCanvass = () => {
    setState((state.left = false));
  };

  const toggleDrawer = (side, open) => () => {
    setState({ ...state, [side]: open });
  };

  function handleLogOut() {
    localStorage.removeItem("userToken");
    Redirect(ToLogin);
  }

  const ITEM_HEIGHT = 24;

  const options = ["Pengaturan", "Log-out"];

  const [anchorElMenuPojokKananAtas, setAnchorElPoKaAtas] = useState(null);
  const isOpenMenuPojokKananAtas = Boolean(anchorElMenuPojokKananAtas);

  function handleMenuPojokKananAtas(event) {
    setAnchorElPoKaAtas(event.currentTarget);
  }

  function handleCloseMenuPojokKananAtas() {
    setAnchorElPoKaAtas(null);
  }

  /*
      ````````````````````````````````
        LIST IN DRAWER

      ````````````````````````````````
    */

  const [openNestedList, setOpenNestedList] = useState(true);

  function handleOpenNestedList() {
    setOpenNestedList(!openNestedList);
  }

  /* 
        ``````````````````````````````````````````````
        START | HANDLE ACTIVE - INACTIVE NAV LIST OFF CANVASS
        
        ``````````````````````````````````````````````
    */

  let inisateLabel = {
    label: "",
    // image: ImageMembership,
    // type: 'Keanggotaan'
  };

  const currentLocation = window.location.pathname;
  // // console.log("currentLocation : ", currentLocation);

  if (currentLocation === ToCompanyProfile) {
    inisateLabel.label = "Profil Perusahaan";
  }

  if (currentLocation === ToRole) {
    inisateLabel.label = "Pengaturan Role";
  }

  if (currentLocation === ToUserManagement) {
    inisateLabel.label = "Pengaturan User";
  }

  if (currentLocation === ToMembershipStatus) {
    inisateLabel.label = "Keanggotaan";
  }

  const [isActiveList, setActiveList] = useState(inisateLabel);

  const handleActiveListItem = (item) => {
    // console.log("Nav : ", item);

    setActiveList(item);

    if (item.type === "ProfilPerusahaan") {
      Redirect(ToCompanyProfile);
    }

    if (item.type === "PengaturanUser") {
      Redirect(ToUserManagement);
    }

    if (item.type === "PengaturanRole") {
      Redirect(ToRole);
    }

    if (item.type === "Keanggotaan") {
      Redirect(ToMembershipStatus);
    }
  };

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const userToken = localStorage.getItem("userToken");
  const [userTokenState, setUserTokenState] = useState("");

  const [fotoQuery, setFotoQuery] = useState("");
  const [fotoQueryUser, setFotoQueryUser] = useState("");

  const [namaGroupPerusahaan, setNamaGroupPerusahaan] = useState("");
  const [roleName, setRoleName] = useState([]);

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [memberFirstName, setMemberFirstName] = useState("");
  const [memberLastName, setMemberLastName] = useState("");

  const [structurePositionTitleName, setStructurePositionTitleName] =
    useState("");

  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);

  useEffect(() => {
    setUserTokenState(userToken);
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);
    }

    if (statusUserLoginAfterParse.userRole.length > 0) {
      setRoleName(statusUserLoginAfterParse.userRole[0].name);
    } else {
      // console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
    }

    //*
    if (statusUserLoginAfterParse.hasOwnProperty("member_first_name")) {
      setMemberFirstName(statusUserLoginAfterParse.member_first_name);
    }

    if (statusUserLoginAfterParse.hasOwnProperty("member_last_name")) {
      setMemberLastName(statusUserLoginAfterParse.member_last_name);
    }

    if (
      statusUserLoginAfterParse.hasOwnProperty("structure_position_title_name")
    ) {
      setStructurePositionTitleName(
        statusUserLoginAfterParse.member_position.structure_position_title_name
      );
    }
  }, []);

  /*
        ``````````````````
        USE SCROLL TOP

        ``````````````````
    */
  const isTopOfPage = useScrollTop();

  /*
        ``````````````````
        USE SCROLL TOP - V2

        ``````````````````
    */

  const [offset, setOffset] = useState(0);

  const parallaxShift = () => {
    setOffset(window.pageYOffset);
  };

  useEffect(() => {
    // // console.log("Offset : ", offset);

    window.addEventListener("scroll", parallaxShift);

    //*Returned function will be called on component unmount
    return () => {
      window.removeEventListener("scroll", parallaxShift);
    };
  }, [offset]);

  /*
       `````````````````````````
       DROPDOWN POJOK KANAN ATAS

       ``````````````````````````
   */
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpenDropdownPojokKananAtas(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseDropdownPojokKananAtas() {
    setAnchorEl(null);
  }

  /*
        ``````````````````
        HANDLE DIALOG HELP

        ``````````````````
    */
  const [isOpenDialogHelp, setOpenDialogHelp] = useState(false);

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar
        position="sticky"
        style={{
          backgroundImage: "url(" + PictBackgroundInHeader + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          transition: "all 500ms",
          backgroundPositionY: `${offset}`,
        }}
      >
        <Toolbar>
          <IconButton onClick={() => navigate(ToEmptyStateGeneral)}>
            <i
              className="material-icons"
              style={{ fontSize: 27, color: "white" }}
            >
              keyboard_backspace
            </i>
          </IconButton>

          <Grid container alignItems="flex-end"></Grid>

          <IconButton
            aria-label="More"
            aria-owns={isOpenMenuPojokKananAtas ? "long-menu" : undefined}
            aria-haspopup="true"
            // onClick={handleMenuPojokKananAtas}
            onClick={handleOpenDropdownPojokKananAtas}
          >
            <ExpandMore color="#fff" />
          </IconButton>
          {/* 
                        <Menu
                            id="long-menu"
                            anchorEl={anchorElMenuPojokKananAtas}
                            open={isOpenMenuPojokKananAtas}
                            onClose={handleCloseMenuPojokKananAtas}
                            PaperProps={{
                                style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: 200,
                                },
                            }}
                        >
                            {options.map(option => (
                                <MenuItem key={option} selected={option === 'Pengaturan'} onClick={handleCloseMenuPojokKananAtas}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu> */}

          <StyledMenu
            style={{ marginRight: 16, marginLeft: -16 }}
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseDropdownPojokKananAtas}
          >
            <StyledMenuItem disabled>
              <ListItemIcon>
                {fotoQueryUser !== "" &&
                fotoQueryUser !== null &&
                fotoQueryUser !== undefined ? (
                  // <img src={URL_API + '/' +fotoQueryUser+ "&token=" + userToken}  className={classes.superBigRealFoto} />
                  <ImageSmallUser item={fotoQueryUser} index={1} />
                ) : (
                  <AccountCircle
                    className={classes.accountCircle}
                    fontSize="large"
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>
                      {memberFirstName + " "}
                      {memberLastName}
                    </b>
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2" className={classes.title}>
                    {structurePositionTitleName}
                  </Typography>
                }
              />
            </StyledMenuItem>
            <Divider />

            {roleName == "superadmin" && (
              <Fragment>
                <StyledMenuItem
                  // onClick={() => Redirect(ToUserManagement)}
                  onClick={() => navigate(ToCompanySetting)}
                >
                  <ListItemText
                    style={{ paddingRight: 24 }}
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "grey" }}
                      >
                        <b>Pengaturan</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <Divider />
              </Fragment>
            )}

            <br />
            <StyledMenuItem onClick={() => setOpenDialogHelp(true)}>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}
                  >
                    <b>Help</b>
                  </Typography>
                }
              />
            </StyledMenuItem>

            <StyledMenuItem
              //    onClick={(e) => handleCloseMenuPojokKananAtas(e, {item: "Log-out"})}
              onClick={() => handleLogOut()}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "grey" }}
                  >
                    <b>Sign out</b>
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </Toolbar>

        {/* 
                        ````````````````````````
                        PERBARUI BACKGROUND FOTO

                        ````````````````````````
                    */}
        <Grid container>
          <Grid item sm={12} style={{ textAlign: "right" }}>
            {isTopOfPage && (
              <Button
                variant="outlined"
                size="small"
                className={classes.perbaruiSampul}
                style={{ color: "transparent", borderWidth: 0 }}
              >
                Perbarui foto sampul
                <i
                  className="material-icons"
                  style={{ color: "transparent", marginLeft: 7 }}
                >
                  camera_alt
                </i>
              </Button>
            )}
          </Grid>
        </Grid>
      </AppBar>

      <DialogHelp
        isOpenDialogHelp={isOpenDialogHelp}
        setOpenDialogHelp={setOpenDialogHelp}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(HeaderProfileDetail);
