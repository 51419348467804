import React, { useEffect, useState, forwardRef } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Slide,
  AppBar,
  Toolbar,
  Container,
  Link,
  CircularProgress,
} from "@material-ui/core";

import Axios from "axios";
import Countdown from "react-countdown";

import { PAYMENT_STATUS_PAID } from "../constant-data";
import { URL_API } from "../../../../constants/config-api";
import { FormatThousand } from "../../../../utilities/FormatDecimal";

import fileDownload from "js-file-download";

import bank_bca from "../../../../assets/images/bank-bca.png";
import bank_bni from "../../../../assets/images/bank-bni.png";
import bank_bri from "../../../../assets/images/bank-bri.png";
import bank_permata from "../../../../assets/images/bank-permata.png";
import bank_mandiri from "../../../../assets/images/bank_mandiri.png";
import card_check from "../../../../assets/images/Group-727.png";

import DialogError from "../../../../components/DialogError";
import DialogPaidPayment from "./DialogPaidPayment";

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPayment = ({
  openDialogPayment,
  setOpenDialogPayment,
  setOpenDialogCheckoutReg,
  setOpenDialogPackage,
  classes,
  checkoutData,
  paket,
  membership,
  totalUser,
  totalDeployment,
  totalPmo,
  duration,
}) => {
  const userToken = localStorage.getItem("userToken");

  let [img_bank, setImgBank] = useState("");
  const [data, setData] = useState({
    id: "",
    invoice_number: "",
    duration: 0,
    membership_level_id: "",
    membership_level_name: "",
    payment_number: "",
    payment_number_transaction_id: "",
    payment_method_id: "",
    payment_method_name: "",
    payment_method_code: "",
    payment_method_type: "",
    order_category_id: "",
    order_category_name: "",
    order_category_code: "",
    group_id: "",
    group_name: "",
    billing_pic_user_id: "",
    billing_pic_email: "",
    billing_pic_first_name: "",
    billing_pic_last_name: "",
    billing_address_id: "",
    billing_address_country_id: "",
    billing_address_country_name: "",
    billing_address_province_id: null,
    billing_address_province_name: null,
    billing_address_city_id: null,
    billing_address_city_name: null,
    billing_address_postal_code_id: null,
    billing_address_postal_code_name: null,
    billing_address_detail: null,
    billing_phone: "",
    order_date: "",
    expiry_date: "",
    membership_account_type_id: "",
    membership_account_type_name: "",
    membership_package_id: "",
    membership_package_name: "",
    membership_voucher_id: "",
    membership_voucher_code: "",
    membership_voucher_usage_id: "",
    membership_voucher_name: "",
    membership_voucher_type: "",
    membership_voucher_value: "",
    membership_voucher_deduction: "",
    total_price: "",
    total_discount: "",
    total_price_after_discount: "",
    status_id: "",
    created_by: "",
    updated_by: null,
    deleted_by: null,
    created_at: "",
    updated_at: "",
    deleted_at: null,
    self: {
      rel: null,
    },
    payment_status: {
      id: "",
      name: "",
      code: "",
      created_by: null,
      updated_by: null,
      deleted_by: null,
      created_at: "",
      updated_at: "",
      deleted_at: null,
      self: {
        rel: null,
      },
    },
  });
  const [loadCancel, setLoadCancel] = useState(false);
  const [loadInvoice, setLoadInvoice] = useState(false);
  const [alreadyPaid, setAlredyPaid] = useState(false);

  const [openDialogPaidPayment, setOpenDialogPaidPayment] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogPayment) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      };

      Axios.defaults.headers.common = header;
      Axios.get(`${URL_API}/account-management/membership-payment/check-status`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            // // console.log("get Checkout Data", result);
            setData(result);

            if (result !== undefined) {
              if (result.payment_method_name === "BCA") {
                setImgBank(bank_bca);
              } else if (result.payment_method_name === "BNI") {
                setImgBank(bank_bni);
              } else if (result.payment_method_name === "BRI") {
                setImgBank(bank_bri);
              } else if (result.payment_method_name === "Permata") {
                setImgBank(bank_permata);
              } else if (result.payment_method_name === "Mandiri") {
                setImgBank(bank_mandiri);
              }
            }
          }
        })
        .catch((error) => {
          // console.log("Error : ", error.response);
        });
    }
  }, [openDialogPayment]);

  useEffect(() => {
    if (openDialogPayment) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      };

      Axios.defaults.headers.common = header;

      const intervalFetch = setInterval(() => {
        Axios.get(
          `${URL_API}/account-management/membership-payment/check-status`
        )
          .then((res) => {
            const result = res.data.data;
            // // console.log("Refres payment", res.data.data);

            if (res.status === 200) {
              setData(result);

              if (result.status_id === PAYMENT_STATUS_PAID) {
                setOpenDialogPaidPayment(true);
                setAlredyPaid(true);
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);
          });
      }, 10000);

      return () => clearInterval(intervalFetch);
    }
  }, [openDialogPayment]);

  const handleCancelPayment = () => {
    setLoadCancel(true);

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
    };

    Axios.defaults.headers.common = header;

    Axios.delete(`${URL_API}/account-management/membership-payment/${data.id}`)
      .then((res) => {
        // console.log("Res Cancel Payment", res);
        if (res.status === 200) {
          setOpenDialogPayment(false);
          // setOpenDialogCheckoutReg(false);
          // setOpenDialogPackage(false);
        }
        setLoadCancel(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoadCancel(false);
        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  const handleDownloadInvoice = () => {
    setLoadInvoice(true);

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
    };

    Axios.defaults.headers.common = header;

    Axios.get(
      `${URL_API}/account-management/membership-payment/${data.id}:invoice`,
      {
        responseType: "blob",
      }
    )
      .then((res) => {
        // console.log("Download Invoice", res);

        if (res.status === 200) {
          fileDownload(res.data, `Performate_${data.invoice_number}.pdf`);
        }
        setLoadInvoice(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoadInvoice(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      fullScreen
      open={openDialogPayment}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}
      >
        <Toolbar></Toolbar>
      </AppBar>

      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={7}>
            <Box marginTop={5}>
              <Box display="flex" alignItems="center" marginBottom={3}>
                <img src={card_check} height="35" />
                <Typography variant="h6" className={classes.title}>
                  <b>Terimakasih atas pesanan anda</b>
                </Typography>
              </Box>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ width: 600, color: "#555" }}
              >
                Anda melakukan pembayaran dengan{" "}
                <b>{data.payment_method_name} Virtual Account</b>, selesaikan
                pembayaran dalam jangka waktu berikut
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                marginBottom={5}
                marginTop={2}
                className={classes.countdownBox}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.countdownTitle}
                >
                  {alreadyPaid ? (
                    "0:0:0"
                  ) : (
                    <Countdown
                      date={new Date(data.expiry_date)}
                      renderer={({
                        days,
                        hours,
                        minutes,
                        seconds,
                        completed,
                      }) => {
                        if (completed) {
                          setOpenDialogPayment(false);
                        } else {
                          return (
                            <span>
                              {days} hari, {hours} : {minutes} : {seconds}
                            </span>
                          );
                        }
                      }}
                    />
                  )}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.countdownText}
                >
                  Jika melebihi dari waktu yang ditentukan, pemesanan akan
                  dibatalkan
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" marginBottom={3}>
                <img src={img_bank} height="17" style={{ marginRight: 10 }} />
                <Typography variant="subtitle1" className={classes.title}>
                  {data.payment_method_name} Virtual Account
                </Typography>
              </Box>

              <Divider orientation="horizontal" style={{ width: 600 }} />
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="subtitle2" className={classes.txtVA}>
                  Nomor Virtual Account
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>{data.payment_number}</b>
                </Typography>
              </Box>

              <Divider orientation="horizontal" style={{ width: 600 }} />
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="subtitle2" className={classes.txtVA}>
                  Nama Rekening Tujuan
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>PT Madinah Sistem Manajemen Bisnis</b>
                </Typography>
              </Box>

              <Divider orientation="horizontal" style={{ width: 600 }} />
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="subtitle2" className={classes.txtVA}>
                  Jumlah Transfer
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Rp {FormatThousand(data.total_price_after_discount)}</b>
                </Typography>
              </Box>

              <Divider orientation="horizontal" style={{ width: 600 }} />
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="subtitle2" className={classes.txtVA}>
                  Invoice
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>{data.invoice_number}</b>
                </Typography>
              </Box>

              <Divider orientation="horizontal" style={{ width: 600 }} />
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="subtitle2" className={classes.txtVA}>
                  Status
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  <b>
                    {data.payment_status !== undefined &&
                      data.payment_status.name}
                  </b>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={1}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <Divider orientation="vertical" />
          </Grid>
          <Grid item md={4}>
            <Box marginTop={5} style={{ backgroundColor: "#f5f5f5" }}>
              <Box padding={2}>
                <Typography variant="subtitle1" className={classes.title}>
                  <b>Panduan Pembayaran</b>
                </Typography>
                <ol className={classes.listGuild}>
                  <li className={classes.txtGuide}>
                    Masukkan kartu ATM lalu masukkan PIN
                  </li>
                  <li className={classes.txtGuide}>
                    Pilih menu lain lalu pilih Transfer
                  </li>
                  <li className={classes.txtGuide}>
                    Pilih rekening asal dan pilih Virtual Account Billing
                  </li>
                  <li className={classes.txtGuide}>
                    Masukkan nomor Virtual Account{" "}
                    <span style={{ color: "#d1354a" }}>
                      {data.payment_number}
                    </span>{" "}
                    dan pilih benar
                  </li>
                  <li className={classes.txtGuide}>
                    Tagihan yang harus dibayar akan muncul pada layar
                  </li>
                  <li className={classes.txtGuide}>
                    Periksa data transaksi di layar, pastikan nama penerima
                    adalah nama <b>PT Madinah Sistem Manajemen Bisnis</b> dan
                    jumlah sudah benar, jika benar pilih Ya
                  </li>
                  <li className={classes.txtGuide}>Simpan bukti transaksi</li>
                </ol>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              marginTop={3}
              marginBottom={3}
            >
              <Link
                component="button"
                variant="subtitle1"
                onClick={handleDownloadInvoice}
                className={classes.title}
              >
                {loadInvoice ? (
                  <CircularProgress color="#b1354a" size={16} />
                ) : (
                  "Download Invoice"
                )}
              </Link>
            </Box>

            <Button
              fullWidth
              variant="outlined"
              className={classes.button2}
              style={{ marginTop: 10 }}
              disabled={alreadyPaid}
              onClick={handleCancelPayment}
            >
              {loadCancel ? (
                <CircularProgress color="#fff" size={15} />
              ) : (
                "Batalkan Pembayaran"
              )}
            </Button>
          </Grid>
        </Grid>
      </Container>

      <DialogPaidPayment
        classes={classes}
        openDialogPaidPayment={openDialogPaidPayment}
        setOpenDialogPaidPayment={setOpenDialogPaidPayment}
        setOpenDialogPayment={setOpenDialogPayment}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutReg}
        setOpenDialogPackage={setOpenDialogPackage}
        dataPay={data}
        paket={paket}
        membership={membership}
        duration={duration}
        totalUser={totalUser}
        totalDeployment={totalDeployment}
        totalPmo={totalPmo}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogPayment;
