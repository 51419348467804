import React, { Fragment } from "react";
import { IconButton } from "@material-ui/core";
import { URL_API } from "../../../../constants/config-api";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const ImagePhoto = (props) => {
  const { classes, fotoQueryContent, handleBiggerPict, userToken } = props;

  return (
    <Fragment>
      {fotoQueryContent !== "" && fotoQueryContent !== null ? (
        <IconButton
          aria-haspopup="true"
          color="inherit"
          onClick={handleBiggerPict}>
          <img
            src={
              URL_API +
              "/" +
              fotoQueryContent +
              "&token=" +
              userToken +
              "&mode=thumbnail"
            }
            className={classes.userRealFoto}
            alt="."
          />
        </IconButton>
      ) : (
        <IconButton aria-haspopup="true" color="inherit">
          <AccountCircleIcon
            className={classes.imageAvatar}
            style={{ color: "#dcdcdc" }}
          />
        </IconButton>
      )}
    </Fragment>
  );
};

export default ImagePhoto;
