import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Chip,
  Typography,
  Popper,
  Paper,
  ClickAwayListener,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

import ContextProject from "../../context/ContextProject";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import Capitalize from "../../../../utilities/Capitalize";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import { ASSIGNEE_CONTENT_FILTER, FILTER_TASK } from "../../context/types";

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      clickableColorSecondary: {
        "&:hover, &:focus": {
          backgroundColor: "#9b9baf",
        },
        "&:active": {
          backgroundColor: "#83829C",
        },
      },
    },
  },
});

const ListFilterSort = ({ classes }) => {
  const project_id = window.location.pathname.split("/")[2];
  const { projectTState, setTriggerTask, projectTDispatch, setTriggerKanban } =
    useContext(ContextProject);

  const [anchorStatus, setAnchorStatus] = useState(null);
  const [anchorPriority, setAnchorPriority] = useState(null);
  const [anchorAssignee, setAnchorAssignee] = useState(null);
  const [anchorAchievement, setAnchorAchievement] = useState(null);
  const [anchorCompleted, setAnchorCompleted] = useState(null);
  const [anchorPeriods, setAnchorPeriods] = useState(null);
  const [anchorGroups, setAnchorGroups] = useState(null);

  const [groupCollection, setGroupCollection] = useState([]);

  useEffect(() => {
    if (projectTState.filterGroup !== null) {
      axiosConfig
        .get(`${URL_API}/project/${project_id}/task-group`)
        .then((res) => {
          const result = res.data.data;

          // // console.log("RES grp O", res);

          if (res.status === 200) {
            setGroupCollection(result);
          }
        })
        .catch((error) => {
          // console.log("Error", error);
        });
    }
  }, [projectTState.filterGroup]);

  // let DATE;

  // if (projectTState.sortDate === "start_date") {
  //   DATE = "Start Date";
  // }
  // if (projectTState.sortDate === "due_date") {
  //   DATE = "Due Date";
  // }
  // if (projectTState.sortDate === "end_date") {
  //   DATE = "End Date";
  // }
  // if (projectTState.sortDate === "created_at") {
  //   DATE = "Created At";
  // }

  const PRIORITY =
    projectTState.filterPriority !== null
      ? projectTState.filterPriority.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content;
        })
      : null;

  const STATUS =
    projectTState.filterStatus !== null
      ? projectTState.filterStatus.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content;
        })
      : null;

  const PERIODS =
    projectTState.periods !== null
      ? projectTState.periods.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content;
        })
      : null;

  const ACHIEVEMENT =
    projectTState.filterAchievement !== null
      ? projectTState.filterAchievement.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content;
        })
      : null;

  const IS_COMPLETE =
    projectTState.filterComplete !== null
      ? projectTState.filterComplete.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content === "1" ? "Incompleted" : "Completed";
        })
      : null;

  const ASSIGNEE =
    projectTState.assigneeFilterContent !== null
      ? projectTState.assigneeFilterContent
      : null;

  const TASK_GROUP =
    projectTState.filterGroup !== null
      ? projectTState.filterGroup.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content;
        })
      : null;

  // // console.log("ASSIGNEE", ASSIGNEE);
  // // console.log("STATUS", STATUS);

  const handleOpenStatus = (event) => {
    setAnchorStatus(event.currentTarget);
    // // console.log("openSTATUS", event.currentTarget);
  };

  const handleOpenPriority = (event) => {
    setAnchorPriority(event.currentTarget);
  };

  const handleOpenPeriods = (event) => {
    setAnchorPeriods(event.currentTarget);
  };

  const handleOpenAssignee = (event) => {
    setAnchorAssignee(event.currentTarget);
  };

  const handleOpenAchievement = (event) => {
    setAnchorAchievement(event.currentTarget);
  };

  const handleOpenCompleted = (event) => {
    setAnchorCompleted(event.currentTarget);
  };

  const handleOpenGroups = (event) => {
    setAnchorGroups(event.currentTarget);
  };

  const handleCloseAwayStatus = (event) => {
    if (anchorStatus.contains(event.target)) {
      return;
    }
    setAnchorStatus(null);
  };

  const handleCloseAwayPriority = (event) => {
    if (anchorPriority.contains(event.target)) {
      return;
    }
    setAnchorPriority(null);
  };

  const handleCloseAwayAssignee = (event) => {
    if (anchorAssignee.contains(event.target)) {
      return;
    }
    setAnchorAssignee(null);
  };

  const handleCloseAwayAchievement = (event) => {
    if (anchorAchievement.contains(event.target)) {
      return;
    }
    setAnchorAchievement(null);
  };

  const handleCloseAwayPeriods = (event) => {
    if (anchorPeriods.contains(event.target)) {
      return;
    }
    setAnchorPeriods(null);
  };

  const handleCloseAwayCompleted = (event) => {
    if (anchorCompleted.contains(event.target)) {
      return;
    }
    setAnchorCompleted(null);
  };

  const handleCloseAwayGroups = (event) => {
    if (anchorGroups.contains(event.target)) {
      return;
    }
    setAnchorGroups(null);
  };

  const RES_STATUS =
    STATUS !== null &&
    STATUS.map((el, i) => {
      let text;

      switch (el) {
        case "DOING":
          text = "Doing";
          break;
        case "DONE":
          text = "Done";
          break;
        case "TO DO":
          text = "To Do";
          break;
        case "ON HOLD":
          text = "On Hold";
          break;
        case "WAITING":
          text = "Waiting";
          break;
        default:
          text = "";
      }

      return text;
    });

  const RES_PRIORITY =
    PRIORITY !== null &&
    PRIORITY.map((el, i) => {
      let text;

      switch (el) {
        case "HIGH":
          text = "High";
          break;
        case "LOW":
          text = "low";
          break;
        case "MEDIUM":
          text = "Medium";
          break;
        default:
          text = "";
      }

      return text;
    });

  const RES_PERIODS =
    PERIODS !== null &&
    PERIODS.map((el, i) => {
      let text;

      switch (el) {
        case "not_started":
          text = "Not Started";
          break;
        case "about_to_start":
          text = "About To Start";
          break;
        case "started":
          text = "Started";
          break;
        case "early":
          text = "Completed (Early)";
          break;
        case "late":
          text = "Completed (Late)";
          break;
        case "on_time":
          text = "Completed (On Time)";
          break;
        case "overdue":
          text = "Overdue";
          break;
        case "overdue_soon":
          text = "Overdue Soon";
          break;
        default:
          text = "";
      }

      return text;
    });

  const RES_ACHIEVEMENT =
    ACHIEVEMENT !== null &&
    ACHIEVEMENT.map((el, i) => {
      let text;

      switch (el) {
        case "scope_on_target":
          text = "Scope On Target";
          break;
        case "scope_behind_target":
          text = "Scope Behind Target";
          break;
        case "schedule_on_target":
          text = "Schedule On Target";
          break;
        case "schedule_behind_target":
          text = "Schedule Behind Target";
          break;
        default:
          text = "";
      }

      return text;
    });

  const RES_TASK_GROUP =
    TASK_GROUP !== null &&
    groupCollection.length > 0 &&
    groupCollection
      .filter((el) => TASK_GROUP.includes(el.id))
      .map((el) => el.name);

  const handleClearFilter = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: null,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
        filterPhase: null,
        filterByGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
  };

  // // console.log("PERIODS", PERIODS);
  // // console.log("STATUS", STATUS);
  // // console.log("PRIORITY", PRIORITY);
  // // console.log("ASSIGNEE", ASSIGNEE);
  // // console.log("IS_COMPLETE", IS_COMPLETE);
  // // console.log("ACHIEVEMENT", ACHIEVEMENT);
  // // console.log("TASK_GROUP", TASK_GROUP);

  return (
    <MuiThemeProvider theme={theme}>
      <Box mr={3} display="flex" alignItems="center">
        {STATUS !== null && (
          <div>
            <Chip
              variant="outlined"
              size="small"
              className={classes.chipFilterSort}
              label={
                <Typography variant="caption">
                  {TruncateTextCustom(RES_STATUS.join(", "), 15)}
                </Typography>
              }
              style={{ marginRight: 8 }}
              onClick={handleOpenStatus}
            />

            <Popper
              open={Boolean(anchorStatus)}
              anchorEl={anchorStatus}
              transition
              disablePortal
              placement="top"
            >
              <ClickAwayListener onClickAway={handleCloseAwayStatus}>
                <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                  <Box px={2} py={1} display="flex" flexDirection="column">
                    {RES_STATUS.map((el, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="caption"
                          style={{
                            color: "#555",
                          }}
                        >
                          - {el}
                        </Typography>
                      );
                    })}
                  </Box>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        )}

        {PRIORITY !== null && (
          <div>
            <Chip
              variant="outlined"
              size="small"
              className={classes.chipFilterSort}
              label={
                <Typography variant="caption">
                  {TruncateTextCustom(RES_PRIORITY.join(", "), 15)}
                </Typography>
              }
              style={{ marginRight: 8 }}
              onClick={handleOpenPriority}
            />

            <Popper
              open={Boolean(anchorPriority)}
              anchorEl={anchorPriority}
              transition
              disablePortal
              placement="top"
            >
              <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                <ClickAwayListener onClickAway={handleCloseAwayPriority}>
                  <Box px={2} py={1} display="flex" flexDirection="column">
                    {RES_PRIORITY.map((el, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="caption"
                          style={{
                            color: "#555",
                          }}
                        >
                          - {el}
                        </Typography>
                      );
                    })}
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        )}
        {PERIODS !== null && (
          <div>
            <Chip
              variant="outlined"
              size="small"
              className={classes.chipFilterSort}
              label={
                <Typography variant="caption">
                  {TruncateTextCustom(RES_PERIODS.join(", "), 15)}
                </Typography>
              }
              style={{ marginRight: 8 }}
              onClick={handleOpenPeriods}
            />

            <Popper
              open={Boolean(anchorPeriods)}
              anchorEl={anchorPeriods}
              transition
              disablePortal
              placement="top"
            >
              <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                <ClickAwayListener onClickAway={handleCloseAwayPeriods}>
                  <Box px={2} py={1} display="flex" flexDirection="column">
                    {RES_PERIODS.map((el, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="caption"
                          style={{
                            color: "#555",
                          }}
                        >
                          - {el}
                        </Typography>
                      );
                    })}
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        )}
        {ACHIEVEMENT !== null && (
          <div>
            <Chip
              variant="outlined"
              size="small"
              className={classes.chipFilterSort}
              label={
                <Typography variant="caption">
                  {TruncateTextCustom(RES_ACHIEVEMENT.join(", "), 15)}
                </Typography>
              }
              style={{ marginRight: 8 }}
              onClick={handleOpenAchievement}
            />

            <Popper
              open={Boolean(anchorAchievement)}
              anchorEl={anchorAchievement}
              transition
              disablePortal
              placement="top"
            >
              <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                <ClickAwayListener onClickAway={handleCloseAwayAchievement}>
                  <Box px={2} py={1} display="flex" flexDirection="column">
                    {RES_ACHIEVEMENT.map((el, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="caption"
                          style={{
                            color: "#555",
                          }}
                        >
                          - {el}
                        </Typography>
                      );
                    })}
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        )}
        {IS_COMPLETE !== null && (
          <div>
            <Chip
              variant="outlined"
              size="small"
              className={classes.chipFilterSort}
              label={
                <Typography variant="caption">
                  {TruncateTextCustom(IS_COMPLETE.join(", "), 15)}
                </Typography>
              }
              style={{ marginRight: 8 }}
              onClick={handleOpenCompleted}
            />

            <Popper
              open={Boolean(anchorCompleted)}
              anchorEl={anchorCompleted}
              transition
              disablePortal
              placement="top"
            >
              <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                <ClickAwayListener onClickAway={handleCloseAwayCompleted}>
                  <Box px={2} py={1} display="flex" flexDirection="column">
                    {IS_COMPLETE.map((el, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="caption"
                          style={{
                            color: "#555",
                          }}
                        >
                          - {Capitalize(el)}
                        </Typography>
                      );
                    })}
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        )}
        {ASSIGNEE !== null && (
          <div>
            <Chip
              variant="outlined"
              size="small"
              className={classes.chipFilterSort}
              label={
                <Typography variant="caption">
                  {TruncateTextCustom(ASSIGNEE.join(", "), 15)}
                </Typography>
              }
              style={{ marginRight: 8 }}
              onClick={handleOpenAssignee}
            />

            <Popper
              open={Boolean(anchorAssignee)}
              anchorEl={anchorAssignee}
              transition
              disablePortal
              placement="top"
            >
              <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                <ClickAwayListener onClickAway={handleCloseAwayAssignee}>
                  <Box px={2} py={1} display="flex" flexDirection="column">
                    {ASSIGNEE.map((el, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="caption"
                          style={{
                            color: "#555",
                          }}
                        >
                          - {Capitalize(el)}
                        </Typography>
                      );
                    })}
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        )}

        {RES_TASK_GROUP !== null &&
          RES_TASK_GROUP !== undefined &&
          RES_TASK_GROUP.length > 0 && (
            <div>
              <Chip
                variant="outlined"
                size="small"
                className={classes.chipFilterSort}
                label={
                  <Typography variant="caption">
                    {TruncateTextCustom(RES_TASK_GROUP?.join(", "), 15)}
                  </Typography>
                }
                style={{ marginRight: 8 }}
                onClick={handleOpenGroups}
              />

              <Popper
                open={Boolean(anchorGroups)}
                anchorEl={anchorGroups}
                transition
                disablePortal
                placement="top"
              >
                <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                  <ClickAwayListener onClickAway={handleCloseAwayGroups}>
                    <Box px={2} py={1} display="flex" flexDirection="column">
                      {RES_TASK_GROUP.length > 0 &&
                        RES_TASK_GROUP.map((el, i) => {
                          return (
                            <Typography
                              key={i}
                              variant="caption"
                              style={{
                                color: "#555",
                              }}
                            >
                              - {Capitalize(el)}
                            </Typography>
                          );
                        })}
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </div>
          )}

        {(PERIODS !== null ||
          STATUS !== null ||
          PRIORITY !== null ||
          ASSIGNEE !== null ||
          IS_COMPLETE !== null ||
          ACHIEVEMENT !== null ||
          TASK_GROUP !== null) && (
          <Tooltip title="Clear filter">
            <IconButton size="small" onClick={handleClearFilter}>
              <HighlightOffRoundedIcon
                style={{ color: "#888", fontSize: 20 }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </MuiThemeProvider>
  );
};

export default ListFilterSort;
