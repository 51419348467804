import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Popover,
  Fab,
  Chip,
  Slider,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";

// import Slider from '@material-ui/lab/Slider';
import LensIcon from "@material-ui/icons/LensOutlined";

import Redirect from "../../../utilities/Redirect";
import { ToMembershipPaymentStatusAfterCountDown } from "../../../constants/config-redirect-url";
import { useGetHttp } from "../../../utilities-hook/useGetHttp";
import { stylesStatusPending } from "./styles/stylesStatusPending";

import PictPaketBasic from "../../../assets/images/SVG/Group_1655.svg";
import PictPaketSilver from "../../../assets/images/SVG/Group_1.svg";
import PictPaketGold from "../../../assets/images/SVG/Group_2.svg";
import PictPaketPlatinum from "../../../assets/images/SVG/Group_3.svg";
import IconSliderPacketMahkota from "../../../assets/images/Group_1745.png";
import PictBackgroundImage from "../../../assets/images/Group_1922.png";

import {
  ToMembershipStatus,
  ToMembershipStatusManagePENDING_SEMENTARA,
} from "../../../constants/config-redirect-url";

// import './backgroundPaper.css';
import { URL_API } from "../../../constants/config-api";

const numeral = require("numeral");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {},
});

const ViewMembershipStatus_scenarioPending = (props) => {
  const { classes } = props;

  /*
        ````````````````````
        COMPONENT DID MOUNT 

        ```````````````````

    */

  //*

  const [userTokenState, setUserTokenState] = useState();
  const [fetchedDataHistoryList, setDataFromCollHistory] = useState(undefined);

  const [statusPayment, setStatusPayment] = useState("");
  const [masaBerlakuAwal, setMasaBerlakuAwal] = useState("");
  const [durasiMembership, setDurasiMembership] = useState("");
  const [durasiDurasiMembership, setDurasiDurasiMembership] = useState("");
  const [bankId, setBankId] = useState("");
  const [durasiMembershipID, setDurasiMembershipID] = useState("");

  const [discount, setDiscount] = useState("");

  const [updatedAtOke, setUpdatedAtOke] = useState("");

  const [groupName, setGroupName] = useState("");
  const [expiredPaymentTanggal, setExpiredPaymentTanggal] = useState("");
  const [paymentCategoryName, setPaymentCategoryName] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [totalPriceAfterUnique, setTotalPriceAfterUnique] = useState("");

  useEffect(() => {
    const userTokenConst = localStorage.getItem("userToken");
    setUserTokenState(userTokenConst);

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userTokenConst,
    };

    axios.defaults.headers.common = header;

    axios
      .get(
        URL_API +
          `/group/membership/payment/history?options[embedded][]=membershipPaymentTracking`
      )
      .then(function (response) {
        // console.log("Response Original : ", response)

        if (response.status == 200) {
          // let dataCollcectionHistory = { //NEXT GANTI DENGAN DATA DETAIL

          //     membership_type_name: response.data.data[0].membership_type_name,
          //     membership_type_price: response.data.data[0].membership_type_price

          // };

          // setDataFromCollHistory(response.data.data[0])
          setDataFromCollHistory(response.data.data);
          setStatusPayment(response.data.data[0].status_name);
          setMasaBerlakuAwal(response.data.data[0].created_at);
          setDurasiMembership(response.data.data[0].membership_duration_name);
          setDurasiDurasiMembership(
            response.data.data[0].membership_duration_duration
          );

          setBankId(response.data.data[0].bank_id);

          setDiscount(response.data.data[0].membership_duration_discount);
          setDurasiMembershipID(response.data.data[0].membership_duration_id);

          setGroupName(response.data.data[0].group_name);

          setExpiredPaymentTanggal(response.data.data[0].expiry_datetime);
          setPaymentCategoryName(response.data.data[0].payment_category_name);
          setInvoiceNumber(response.data.data[0].invoice_number);
          setTotalPriceAfterUnique(
            response.data.data[0].total_price_after_unique
          );
        }
      })
      .catch(function (error) {
        // console.log("Error : ", error.response)
      });
  }, []);

  /*
        ```````````````````````````````
        HANDLE DATA FROM LOCAL STORAGE 

        ```````````````````````````````
    */

  // const [dataLocalStorage, setDataLocalStorage ] = useState();

  // useEffect(() => {

  //     const invoice_number = localStorage.getItem('invoice_number');
  //     const expired_date_payment = localStorage.getItem('expired_date_payment');
  //     const nominal_after_discount = localStorage.getItem('nominal_after_discount');

  //     // console.log("INVOICE : ", invoice_number);

  //     const data = {

  //         invoice_number: invoice_number,
  //         expired_date_payment: expired_date_payment,
  //         nominal_after_discount : nominal_after_discount

  //     };

  //     setDataLocalStorage(data);

  // }, []);

  /*
        ````````````````
        HANDLE POPOVER

        ````````````````
    */
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  /*
        ````````````````
        HANDLE SLIDER

        ````````````````
    */

  const [valueSlider, setValueSlider] = useState(20);

  const handleChangeSlider = (event, value) => {
    event.preventDefault();

    // console.log("Value : ", value);
    setValueSlider(value);
  };

  /*
        `````````````````````````````````
        HANDLE KELOLA KEANGGOTAAN

        ``````````````````````````````````
    */

  const handleKelolaKeanggotaan = () =>
    Redirect(ToMembershipStatusManagePENDING_SEMENTARA);

  /*
        ````````````````````````````````
        HANDLE NEXT BIRTHDAY IN MOMENT JS

        ````````````````````````````````

    */
  function daysRemaining() {
    var eventdate = moment(masaBerlakuAwal).add(
      durasiDurasiMembership !== "1y"
        ? parseInt(durasiMembership.charAt(0))
        : 12,
      "months"
    );
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }

  return (
    <MuiThemeProvider theme={theme}>
      {/* <Container maxWidth="sm"> */}
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              Keanggotaan
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {/* <Paper className='backgroundPaper' elevation={0} square style={{marginLeft:'16px', marginRight: '16px'}}> */}
      <Paper
        // className={classes.backgroundPaper}
        elevation={0}
        square
        style={{
          marginLeft: "16px",
          marginRight: "16px",
          backgroundImage: "url(" + PictBackgroundImage + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            <Typography
              variant="subtitle2"
              className={classes.kuotaUser}
              style={{ color: "grey" }}
            >
              2 <i>Kuota user</i> sudah di gunakan dari 3
            </Typography>
          </Grid>
          {/* <Grid item xs={0}>
                            <Slider
                                value={valueSlider}
                                aria-labelledby="slider-icon"
                                onChange={handleChangeSlider}
                                classes={{
                                    root: classes.slider,
                                    thumbIconWrapper: classes.thumbIconWrapper,
                                    track: classes.trackSlider,
                                }}
                                thumb = {
                                    <span
                                        style={{ color: '#2196f3' }} 
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                    >
                                        
                                    </span>
                                }
                            />
                            <Popover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                    paper: classes.paperPopover,
                                }}
                                open={openPopover}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                                >
                                <Typography>2 dari 3</Typography>
                            </Popover>
                        </Grid> */}
          <Grid item xs={8}>
            {/* 

                                ```````````````````````````````````````````````````````````
                                Masa berlaku data-nya dapat dari PUT when klik button TRIAL

                                ```````````````````````````````````````````````````````````
                                
                            */}

            <Typography variant="subtitle2" className={classes.masaBerlaku}>
              Masa berlaku anda &nbsp;
              <u>{daysRemaining() + " Hari lagi "}</u>
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Button
              variant="outlined"
              size="small"
              className={classes.buttonStatusActive}
            >
              <LensIcon
                style={{ color: "#19dc5a", width: 10, marginRight: 7 }}
              />
              Aktif
            </Button>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img
              src={PictPaketGold}
              alt="Gold"
              className={classes.titleMahkota}
            />
            <Typography variant="h5" className={classes.titleCompanyName}>
              {groupName}
              {/* {fetchedDataHistoryList[0] !== undefined ? fetchedDataHistoryList[0].group_name : ''} */}
            </Typography>

            <Typography variant="subtitle1" className={classes.titleTrial}>
              {/* {fetchedDataHistoryList[0] !== undefined ? fetchedDataHistoryList[0].membership_type_name : ''} */}
              ***
            </Typography>

            <Button
              variant="outlined"
              size="medium"
              className={classes.kelolaKeanggotaan}
              onClick={handleKelolaKeanggotaan}
            >
              Kelola Keanggotaan
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.subRoot3} elevation={0}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              Pembayaran Terakhir
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={4} className={classes.subRootCardRiwayat}>
        <Grid container>
          <Grid item xs={2}>
            <Chip
              label={statusPayment}
              className={classes.pendingButton}
              variant="outlined"
            />
            <br />
            <br />
            <Typography
              variant="subtitle1"
              className={classes.successButtonDate}
            >
              {moment(expiredPaymentTanggal).format("DD MMMM YYYY h:mm:ss")}
            </Typography>
          </Grid>

          <Grid item xs={7} style={{ textAlign: "left" }}>
            <Typography variant="h6" className={classes.langgananKeanggotaan}>
              {/* { fetchedDataHistoryList[0] !== undefined ? fetchedDataHistoryList[0].payment_category_name : ''} */}
              {paymentCategoryName}
            </Typography>
            <br />

            <Typography
              variant="subtitle1"
              className={classes.langgananInvoice}
            >
              {/* INV/{dataLocalStorage !== undefined ? dataLocalStorage.invoice_number : ''} */}
              {"INV/" + invoiceNumber}
            </Typography>
          </Grid>

          <Grid item xs={3} style={{ textAlign: "center" }}>
            <Typography variant="h6" className={classes.nominal}>
              Rp {numeral(totalPriceAfterUnique).format("0,0")}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
      </Paper>

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            className={classes.buttonLihatRiwayat}
            onClick={() => Redirect(ToMembershipPaymentStatusAfterCountDown)}
          >
            Lihat Riwayat
          </Button>
        </Grid>
      </Grid>
      {/* </Container> */}
    </MuiThemeProvider>
  );
};

export default withStyles(stylesStatusPending)(
  ViewMembershipStatus_scenarioPending
);
