import React, { useState } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { ToCompletionProfile } from "../../../../constants/config-redirect-url";
import { navigate } from "hookrouter";

function DialogSuccesContactUs({
  isSuccessContactUs,
  setSuccessContactUs,
  classes,
}) {
  const [loading, setLoading] = useState(false);

  const handleToCompleteProfile = () => {
    setLoading(true);

    setTimeout(() => {
      navigate(ToCompletionProfile);
      setSuccessContactUs(false);
      setLoading(false);
    }, 1500);
  };

  return (
    <Dialog
      open={isSuccessContactUs}
      onClose={() => setSuccessContactUs(false)}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle></DialogTitle>

      <DialogContent style={{ marginTop: 15, textAlign: "center" }}>
        <CheckCircleIcon
          style={{ fontSize: 36, color: "#10de77", marginBottom: 12 }}
        />

        <Typography
          variant="h5"
          className={classes.title}
          style={{ marginBottom: 12 }}>
          <b>Terimakasih</b>
        </Typography>

        <Typography
          variant="subtitle2"
          className={classes.textPro1}
          style={{ marginBottom: 15, marginTop: 0 }}>
          Permintaan anda akan segera di proses, tim kami akan menghubungi anda
          dalam waktu maksimal 24 jam
        </Typography>

        <Typography
          variant="subtitle2"
          className={classes.textPro2}
          style={{ marginBottom: 10 }}>
          Anda bisa menggunakan Performate secara gratis selama 1 bulan
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleToCompleteProfile}
          variant="contained"
          className={classes.buttonNext}
          fullWidth>
          Lanjut ke langkah berikutnya
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSuccesContactUs;
