import React, { Fragment } from "react";

import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";

import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";

import { red, yellow, green } from "@material-ui/core/colors";

import "react-circular-progressbar/dist/styles.css";

import { FormatDecimal } from "../utilities/FormatDecimal";

const CircelInPercent = (props) => {
  const { item } = props;

  const ORA = Number(
    item.calculatedValue.resultOriented.overall_result_achievement
  );

  return (
    <Fragment>
      {/* 
            ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
            # SCENARIO-00
            - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" 0
                
            ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
        */}

      {Number(ORA) * 100 === 0 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={Number(ORA)}
          duration={3}
          easingFunction={easeQuadInOut}
          // repeat
        >
          {(value) => {
            // const roundedValue = Math.round(value);//*Math.round() ==> "used to round a number to its nearest integer"

            return (
              <CircularProgressbar
                value={100}
                text={
                  Number(item.expected_result_value) === 0 &&
                  Number(item.target_value) > 0
                    ? "N/A"
                    : `${FormatDecimal(0)}%`
                  // : `${numeral(0).format("0.00")}%`
                }
                /* This is important to include, because if you're fully managing theanimation yourself, you'll want to disable the CSS animation. */
                styles={buildStyles({
                  // pathTransitionDuration: 0.5,
                  // pathColor: '#2ef062', //*GREEN
                  pathColor: "#bbb",
                  // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                  textColor: "black",
                  // strokeLinecap: 'butt',
                  pathTransition: "none",
                  textSize:
                    (item.has_goal_result === false && 17) ||
                    (item.has_measured_activity_result === false && 17),
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {/* 
            ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
            # SCENARIO-01
            - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" KURANG DARI dari 85% ==> merah
                
            ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
        */}

      {Number(ORA) * 100 > 0 && Number(ORA) * 100 < 85 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={Number(ORA)}
          duration={3}
          easingFunction={easeQuadInOut}
          // repeat
        >
          {(value) => {
            // const roundedValue = Math.round(value);//*Math.round() ==> "used to round a number to its nearest integer"

            return (
              <CircularProgressbar
                value={value * 100}
                text={
                  Number(item.expected_result_value) === 0 &&
                  Number(item.target_value) > 0
                    ? "N/A"
                    : `${FormatDecimal(value * 100)}%`
                  // : `${numeral(value * 100).format("0.00")}%`
                }
                /* This is important to include, because if you're fully managing theanimation yourself, you'll want to disable the CSS animation. */
                styles={buildStyles({
                  // pathTransitionDuration: 0.5,
                  // pathColor: '#2ef062', //*GREEN
                  pathColor: red[800],
                  // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                  textColor: "black",
                  // strokeLinecap: 'butt',
                  pathTransition: "none",
                  textSize:
                    (item.has_goal_result === false && 17) ||
                    (item.has_measured_activity_result === false && 17),
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {/* 

````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
# SCENARIO-02
- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " >= 85% 

- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" < 100% 

     ==> YELLOWuntuk trail "OVERALL_ACHIEVEMENT"-nya

     ==> RED untuk "POSISI AMAN/ EXPECTED_VALUE_ACHIEVEMENT"
    
````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
*/}

      {Number(ORA) * 100 >= 85 && Number(ORA) * 100 < 100 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={Number(ORA)}
          duration={3}
          easingFunction={easeQuadInOut}
          // repeat
        >
          {(value) => {
            return (
              <CircularProgressbar
                value={value * 100}
                text={
                  Number(item.expected_result_value) === 0 &&
                  Number(item.target_value) > 0
                    ? "N/A"
                    : `${FormatDecimal(value * 100)}%`
                  // : `${numeral(value * 100).format("0.00")}%`
                }
                styles={buildStyles({
                  pathColor: yellow[400],
                  trailColor: "#eee", //* Kalau di list goal detail  pakai *grey
                  textColor: "black",
                  // strokeLinecap: 'butt',
                  pathTransition: "none",
                  textSize:
                    (item.has_goal_result === false && 17) ||
                    (item.has_measured_activity_result === false && 17),
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {/* 
`````````````````````````````````````````````````````````
# SCENARIO-03
- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 100% 
- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 200% 

     ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

     ==> GREEN untuk 100

```````````````````````````````````````````````````````````
*/}

      {Number(ORA) * 100 !== 200 &&
        Number(ORA) * 100 >= 100 &&
        Number(ORA) * 100 <= 200 && (
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={Number(ORA)}
            duration={3}
            easingFunction={easeQuadInOut}
            // repeat
          >
            {(value) => {
              return (
                <CircularProgressbarWithChildren
                  value={100}
                  // value={numeral(value * 100).format('0.00')}
                  styles={buildStyles({
                    // pathColor: red[800],
                    // strokeLinecap: 'butt'//*Untuk supaya trail progress TEGAK
                    pathColor: green[500],
                    trailColor: "grey", //* Kalau di list goal pakai *#eee
                  })}
                >
                  <CircularProgressbar
                    value={value * 100 - 100}
                    // value={100}
                    text={
                      Number(item.expected_result_value) === 0 &&
                      Number(item.target_value) > 0
                        ? "N/A"
                        : `${FormatDecimal(value * 100)}%`
                      // : `${numeral(value * 100).format("0.00")}%`
                    }
                    styles={buildStyles({
                      // pathColor:  yellow[400],
                      // strokeLinecap: 'butt',//*Untuk supaya trail progress TEGAK
                      pathColor: green[800], //lightGreen['A400'],
                      trailColor: "transparent", //*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                      textColor: "black",
                      pathTransition: "none",
                      textSize:
                        (item.has_goal_result === false && 17) ||
                        (item.has_measured_activity_result === false && 17),
                    })}
                  />
                </CircularProgressbarWithChildren>
              );
            }}
          </AnimatedProgressProvider>
        )}

      {/* 
```````````````````````````````````````````````````````````
# SCENARIO-04
- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 200

     ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

```````````````````````````````````````````````````````````
*/}

      {Number(ORA) * 100 >= 200 && Number(ORA) * 100 <= 999 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={Number(ORA)}
          duration={3}
          easingFunction={easeQuadInOut}
          // repeat
        >
          {(value) => {
            // const roundedValue = Math.round(value);

            return (
              <CircularProgressbar
                value={value * 100}
                // text={`${value * 100 }%`}//*Khusus yang ini ga pake numeral-an
                // counterClockwise={true}
                text={
                  Number(item.expected_result_value) === 0 &&
                  Number(item.target_value) > 0
                    ? "N/A"
                    : `${FormatDecimal(value * 100)}%`
                  // : `${numeral(value * 100).format("0.00")}%`
                }
                styles={buildStyles({
                  pathColor: green[800], //lightGreen['A400'],
                  textColor: "black",
                  trailColor: "white", //* Kalau di list goal pakai *#eee
                  pathTransition: "none",
                  // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                  // strokeLinecap: 'butt',
                  textSize:
                    (item.has_goal_result === false && 17) ||
                    (item.has_measured_activity_result === false && 17),
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {/* 
```````````````````````````````````````````````````````````
# SCENARIO-05
- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 0

     ==> MERAH untuk trail "OVERALL_ACHIEVEMENT"-nya

     ==> counterClockwise === true 

     ==> Value "overall_achievement"-nya bernilai MINUS

```````````````````````````````````````````````````````````
*/}

      {Number(ORA) * 100 < 0 && Number(ORA) * 100 > -1000 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={Number(ORA) - Number(ORA) * 2}
          duration={3}
          easingFunction={easeQuadInOut}
          // repeat
        >
          {(value) => {
            return (
              <CircularProgressbar
                value={value * 100}
                // text={`-${value * 100 }%`}
                text={
                  Number(item.expected_result_value) === 0 &&
                  Number(item.target_value) > 0
                    ? "N/A"
                    : `${FormatDecimal(
                        item.calculatedValue.resultOriented
                          .overall_result_achievement * 100
                      )}%`
                  // : `${numeral(
                  //   item.calculatedValue.resultOriented
                  //     .overall_result_achievement * 100
                  // ).format("0.00")}%`
                }
                counterClockwise={true}
                styles={buildStyles({
                  pathColor: red[800],
                  // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                  textColor: "black",
                  // strokeLinecap: 'butt',
                  pathTransition: "none",
                  textSize:
                    (item.has_goal_result === false && 17) ||
                    (item.has_measured_activity_result === false && 17),
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {/* 
```````````````````````````````````````````````````````````
# SCENARIO-06
- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 999

     ==> MERAH untuk trail "OVERALL_ACHIEVEMENT"-nya

     ==> counterClockwise === true 

     ==> Value "overall_achievement"-nya bernilai MINUS

```````````````````````````````````````````````````````````
*/}

      {Number(ORA) * 100 >= 999 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={999}
          duration={3}
          easingFunction={easeQuadInOut}
          // repeat
        >
          {(value) => {
            // const roundedValue = Math.round(value);

            return (
              <CircularProgressbar
                value={value * 100}
                // text={`${value * 100 }%`}//*Khusus yang ini ga pake numeral-an
                // counterClockwise={true}
                text={
                  Number(item.expected_result_value) === 0 &&
                  Number(item.target_value) > 0
                    ? "N/A"
                    : `>${FormatDecimal(value)}%`
                  // : `>${numeral(value).format("0.00")}%`
                }
                styles={buildStyles({
                  pathColor: green[800], //lightGreen['A400'],
                  textColor: "black",
                  pathTransition: "none",
                  // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                  // strokeLinecap: 'butt',
                  textSize:
                    (item.has_goal_result === false && 17) ||
                    (item.has_measured_activity_result === false && 17),
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {/* 
```````````````````````````````````````````````````````````
# SCENARIO-06
- INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 999

     ==> MERAH untuk trail "OVERALL_ACHIEVEMENT"-nya

     ==> counterClockwise === true 

     ==> Value "overall_achievement"-nya bernilai MINUS

```````````````````````````````````````````````````````````
*/}

      {Number(ORA) * 100 <= -1000 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={-999}
          duration={3}
          easingFunction={easeQuadInOut}
          // repeat
        >
          {(value) => {
            return (
              <CircularProgressbar
                value={value}
                // text={`-${value * 100 }%`}
                text={
                  Number(item.expected_result_value) === 0 &&
                  Number(item.target_value) > 0
                    ? "N/A"
                    : `<${FormatDecimal(value)}%`
                  // : `<${numeral(value).format("0.00")}%`
                }
                counterClockwise={true}
                styles={buildStyles({
                  pathColor: red[800],
                  trailColor: red[800],
                  // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                  textColor: "black",
                  // strokeLinecap: 'butt',
                  pathTransition: "none",
                  textSize: 16,
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}
    </Fragment>
  );
};

export default CircelInPercent;
