/* 

  ``````````````````````````
    
    #AM
    
      - Sign up/ Register
  

  ```````````````````````````

*/

export const GET_LABEL_REGISTER_GROUP = `/register/register-by-email `;
export const POST_REGISTER_GROUP = `/register/register-by-email `;

