import { Box } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

const StyledIconPlusButton = withStyles({
  root: {
    display: "inline-block",
    position: "relative",

    "&::before": {
      content: "''",
      display: "block",
      width: "1px",
      height: "16px",
      position: "absolute",
      left: "50%",
      bottom: "100%",
      background: "grey",
      zIndex: 10,
    },
  },
})(Box);

export default StyledIconPlusButton;
