import React from "react";
import { MenuItem, Typography, Radio } from "@material-ui/core";

const ListItemDailyInWeek = ({ classes, isCurrent, item, day, handleDay }) => {
  // // console.log("DAY", day);

  return (
    <MenuItem
      style={{
        margin: 0,
        padding: "5px 10px 0 10px",
        backgroundColor: isCurrent && "#bebdcf",
        color: isCurrent ? "#282560" : "#333",
      }}
      dense
      onClick={() => handleDay(item)}
    >
      <label style={{ margin: 0, padding: 0, height: 37.5 }}>
        <Typography
          variant="subtitle2"
          className={classes.itemFreq}
          style={
            {
              // backgroundColor: isCurrent && "#bebdcf",
              // color: isCurrent ? "#282560" : "#333",
            }
          }
        >
          {item.day_name}
        </Typography>
        <Radio
          checked={item.day_name === day.day_name}
          value={day.day}
          style={{
            visibility: "hidden",
            height: 0,
            width: 0,
            opacity: 0,
          }}
        />
      </label>
    </MenuItem>
  );
};

export default ListItemDailyInWeek;
