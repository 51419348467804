import React from "react";
import { Box, Typography, Checkbox, Tooltip } from "@material-ui/core";

import clsx from "clsx";

const ListItemUnit = ({
  classes,
  index,
  unit,
  selected,
  setSelected,
  isSelected,
}) => {
  const isItemSelected = isSelected(unit.id);

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected ALL MA", newSelected);

    setSelected(newSelected);
  };

  // // console.log("UNIT>", unit);

  return (
    <Tooltip title={unit.position}>
      <Box
        display="flex"
        alignItems="center"
        marginLeft={1}
        marginRight={2}
        onClick={() => handleChangeCheckbox(unit)}
      >
        <Checkbox
          className={classes.checkboxRoot}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          checked={isItemSelected}
          disableRipple
          color="default"
          inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${index}` }}
        />
        <Typography variant="subtitle2" className={classes.title333}>
          {unit.structure_unit_name}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default ListItemUnit;
