import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Container } from "@material-ui/core";

import styles from "./Style/landingStyle";

import HeaderNew from "./ComponentViewLandingPage/home/HeaderNew";
import NavRight from "./ComponentViewLandingPage/NaviRight";
import NavLeft from "./ComponentViewLandingPage/NavLeft";
import ForMarketing from "./ComponentViewLandingPage/solution/ForMarketing";
import TheySaid from "./ComponentViewLandingPage/solution/TheySaid";
import TouchUs from "./ComponentViewLandingPage/home/TouchUs";
import Footer from "./ComponentViewLandingPage/Footer";
import FloatingWhatsapp from "./ComponentViewLandingPage/components/FloatingWhatsapp";

import bgTouch from "../../assets/images/bgTouch.png";
import hrd1 from "../../assets/images/hrd1.png";
import { navigate } from "hookrouter";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const ViewHRD = () => {
  const classes = useStyles();

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <div>
      <HeaderNew
        color="transparent"
        routes={dashboardRoutes}
        brand="Performate"
        rightLinks={<NavRight origin="2" />}
        leftLinks={<NavLeft origin="2" />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white",
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={20} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            className={classes.title}
            style={{ color: "#333" }}
          >
            <b>Jadikan karyawan anda lebih loyal kepada anda</b>
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.heroDesc}
            style={{ color: "#333" }}
          >
            Pantau kedisiplinan divisi HRD dalam menjalankan program yang
            mendukung ‘employee satisfaction‘
          </Typography>

          <Box mt={4} className={classes.btnHero}>
            <Button
              variant="outlined"
              className={classes.btnSales2}
              onClick={() => navigate("/contact")}
            >
              Hubungi Sales Kami
            </Button>
            <Box mx={0.8} />
            <Button
              variant="outlined"
              className={classes.btnFree}
              onClick={handleTryFree}
            >
              Coba Gratis Sekarang
            </Button>
          </Box>
        </Box>
      </Box>

      <ForMarketing
        img={hrd1}
        title="Bagaimana Tim HRD memanfaatkan Performate untuk output maksimal"
        benefits={[
          "Performate.ID Membantu Tim HRD Untuk Memantau Progress Aktivitas Mereka Dalam Menjalankan Program Yang Mendukung ‘Employee Satisfaction’",
          "Performate.ID Membantu Tim HRD Untuk Menganalisa Program Mana Yang Efektif Pada Program Yang Mendukung ‘Employee Satisfaction’",
          "Performate.ID Membantu Tim HRD Untuk Memanage Aktivitas Yang Mereka Rencanakan Saat Menjalankan Program Yang Mendukung ‘Employee Satisfaction’",
        ]}
      />
      {/* <TheySaid /> */}

      <Box
        style={{
          backgroundImage: "url(" + bgTouch + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Container>
          <TouchUs />
        </Container>
      </Box>

      <FloatingWhatsapp />

      <Footer />
    </div>
  );
};

export default ViewHRD;
