import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogEditText from "./DialogEditText";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
}));

// COnvert string to whatsapp text format
function WhatsAppFormat(msg) {
  if (!msg) return;

  return msg
    .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
    .replace(/_(_*[^_\n]+_*)_/g, "<em>$1</em>")
    .replace(/#(#*[^_\n]+#*)#/g, "<u>$1</u>")
    .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>");
}

function DialogPreviewText({
  openDialogPreviewText,
  setOpenDialogPreviewText,
  item,
  itemId,
  itemName,
  classes,
  origin,
}) {
  const classes0 = useStyles();
  const inputEl = useRef(null);

  const [loading, setLoading] = useState(false);
  const [pureText, setPureText] = useState(false);
  const [openEditText, setOpenEditText] = useState(false);

  useEffect(() => {
    if (openDialogPreviewText === true) {
      setLoading(true);

      if (origin === "Drive") {
        axiosConfig
          .get(`${URL_API}/drive/file/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // console.log("Response View Content", response);

            if (response.status === 200) {
              const file = new Blob([response.data], { type: "text/plain" });

              const reader = new FileReader();

              reader.onload = (event) => {
                let text = event.srcElement.result;
                // // console.log("EVENTS", text);
                // // console.log("tipe tx", typeof text);

                setPureText(text);

                const formatedText = WhatsAppFormat(text);
                inputEl.current.innerHTML = formatedText;
              };

              reader.readAsText(file);
              // window.open(obj_url);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "EvidenceDrive") {
        axiosConfig
          .get(`${URL_API}/drive/file/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // console.log("Response View Content", response);

            if (response.status === 200) {
              const file = new Blob([response.data], { type: "text/plain" });

              const reader = new FileReader();

              reader.onload = (event) => {
                let text = event.srcElement.result;
                // // console.log("EVENTS", text);
                // // console.log("tipe tx", typeof text);

                setPureText(text);

                const formatedText = WhatsAppFormat(text);
                inputEl.current.innerHTML = formatedText;
              };

              reader.readAsText(file);
              // window.open(obj_url);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "EvidenceGR") {
        axiosConfig
          .get(
            `${URL_API}/swd/goal-result/${item.goal_result_id}/evidence/${itemId}/content`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            // console.log("Response View Content", response);

            if (response.status === 200) {
              const file = new Blob([response.data], { type: "text/plain" });

              const reader = new FileReader();

              reader.onload = (event) => {
                let text = event.srcElement.result;
                // // console.log("EVENTS", text);
                // // console.log("tipe tx", typeof text);

                setPureText(text);

                const formatedText = WhatsAppFormat(text);
                inputEl.current.innerHTML = formatedText;
              };

              reader.readAsText(file);
              // window.open(obj_url);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "EvidenceMAR") {
        axiosConfig
          .get(
            `${URL_API}/swd/measured-activity-result/${item.measured_activity_result_id}/evidence/${itemId}/content`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            // console.log("Response View Content", response);

            if (response.status === 200) {
              const file = new Blob([response.data], { type: "text/plain" });

              const reader = new FileReader();

              reader.onload = (event) => {
                let text = event.srcElement.result;
                // // console.log("EVENTS", text);
                // // console.log("tipe tx", typeof text);

                setPureText(text);

                const formatedText = WhatsAppFormat(text);
                inputEl.current.innerHTML = formatedText;
              };

              reader.readAsText(file);
              // window.open(obj_url);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "Gallery") {
        axiosConfig
          .get(`${URL_API}/drive/gallery/goal-result-evidence/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // console.log("Response View Content", response);

            if (response.status === 200) {
              const file = new Blob([response.data], { type: "text/plain" });

              const reader = new FileReader();

              reader.onload = (event) => {
                let text = event.srcElement.result;
                // // console.log("EVENTS", text);
                // // console.log("tipe tx", typeof text);

                setPureText(text);

                const formatedText = WhatsAppFormat(text);
                inputEl.current.innerHTML = formatedText;
              };

              reader.readAsText(file);
              // window.open(obj_url);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "GalleryMA") {
        axiosConfig
          .get(
            `${URL_API}/drive/gallery/measured-activity-result-evidence/${itemId}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            // console.log("Response View Content", response);

            if (response.status === 200) {
              const file = new Blob([response.data], { type: "text/plain" });

              const reader = new FileReader();

              reader.onload = (event) => {
                let text = event.srcElement.result;
                // // console.log("EVENTS", text);
                // // console.log("tipe tx", typeof text);

                setPureText(text);

                const formatedText = WhatsAppFormat(text);
                inputEl.current.innerHTML = formatedText;
              };

              reader.readAsText(file);
              // window.open(obj_url);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      }
    }
  }, [openDialogPreviewText]);

  const handleClosePreview = () => {
    setLoading(false);
    setOpenDialogPreviewText(false);
  };

  return (
    <>
      {loading ? (
        <Backdrop
          className={classes0.backdrop}
          open={loading}
          onClick={handleClosePreview}
        >
          <CircularProgress color="#f28181" />
        </Backdrop>
      ) : (
        <Dialog
          open={openDialogPreviewText}
          aria-labelledby="customized-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg" //*Default "md"
          style={{ height: "auto", overflowX: "hidden" }}
        >
          <DialogTitle id="customized-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5" className={classes.title}>
                {itemName}
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setOpenDialogPreviewText(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ width: 700, maxHeight: 1000 }}>
            <Typography
              className={classes.title}
              variant="subtitle2"
              style={{
                whiteSpace: "pre-line",
              }}
              ref={inputEl}
            />
          </DialogContent>
          {origin === "Drive" && (
            <DialogActions
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
                padding: 0,
                margin: 20,
              }}
            >
              {item.goalResultEvidence !== undefined &&
              item.goalResultEvidence.length > 0 ? (
                <Typography
                  className={classes.title}
                  style={{ color: "#d1354a", marginRight: 15, fontSize: 12 }}
                >
                  * File ini sudah dijadikan bukti untuk Goal, tidak bisa Edit
                </Typography>
              ) : null}

              <Button
                variant="contained"
                className={classes.button}
                disabled={
                  item.goalResultEvidence !== undefined &&
                  item.goalResultEvidence.length > 0
                }
                onClick={() => {
                  setOpenEditText(true);
                  setOpenDialogPreviewText(false);
                }}
              >
                Edit Text
              </Button>
            </DialogActions>
          )}

          {origin === "Evidence" ||
            (origin === "Gallery" && (
              <DialogActions
                style={{
                  margin: 20,
                }}
              />
            ))}
        </Dialog>
      )}

      <DialogEditText
        openEditText={openEditText}
        setOpenEditText={setOpenEditText}
        classes={classes}
        itemId={itemId}
        itemName={itemName}
        content={pureText}
      />
    </>
  );
}

export default DialogPreviewText;
