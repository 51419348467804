import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
  useContext,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Button,
  Box,
  List,
  FormControlLabel,
  Radio,
  TextField,
  CircularProgress,
  Breadcrumbs,
  InputAdornment,
} from "@material-ui/core";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";

import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { useDebouncedCallback } from "use-debounce/lib";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import dragDropImg from "../../../../assets/images/dragToUpload.png";
import DriveEvidence from "./DriveComponent/DriveEvidence";
import ContextGoalDetail from "../Context/ContextGoalDetail";

import "../Style/custom.css";
import DialogError from "../../../../components/DialogError";
import ListFiles from "./DriveComponent/ListFiles";
import FileDriveSelected from "./DriveComponent/FileDriveSelected";
import PasteClipboard from "../../../../components/PasteClipboard";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

function DialogAddEvidence({
  openDialogAddEvidence,
  setOpenDialogAddEvidence,
  classes,
  item,
  setFireGoalDetail,
  goalDetailState,
  greLength,
}) {
  const maxSize = 31457280;
  let inputRefSearch = useRef(null);
  const context = useContext(ContextGoalDetail);

  // Super Admin  => role
  const user_login = localStorage.getItem("status_user_login");
  const superAdmin = JSON.parse(user_login);
  const role = superAdmin.userRole[0].name;

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingEvidence, setLoadingEvidence] = useState(false);
  const [evidenceCategoryCollections, setEvidenceCategoryCollections] =
    useState([]);
  const [selectEvidenceCategory, setSelectEvidenceCategory] = useState({
    id: "c9441e2a-972d-47da-a07c-a1eb5215de05",
    name: "Attachment",
    code: "swd_goal_result_evidence_category_attachment",
    sort_order: 0,
  });
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [triggerNavigate, setTriggerNavigate] = useState(null);

  const [listFolders, setListFolders] = useState([]);
  const [listFiles, setListFiles] = useState([]);
  const [hyperlink, setHyperlink] = useState("");
  const [fileDrive, setFileDrive] = useState([]);

  const [driveSearchValue, setDriveSearchValue] = useState("");
  const [isSearchRun, setSearchRun] = useState(false);
  const [clearSearchResult, setClearSearchResult] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
    Side Effect
  ==================================================== */

  // =============================== Handle GET Create Form GRE ===============================
  useEffect(() => {
    if (openDialogAddEvidence) {
      axiosConfig
        .get(`${URL_API}/swd/goal-result/${item.id}/evidence/create`)
        .then((response) => {
          // // console.log("Evidence Create", response);

          const result = response.data.data;

          if (response.status === 200) {
            setEvidenceCategoryCollections(result.evidenceCategoryCollections);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);
        });
    }
  }, [openDialogAddEvidence]);

  // =============================== Handle Revoke File ===============================
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  // =============================== Handle GET List Folders ===============================
  useEffect(() => {
    if (openDialogAddEvidence) {
      let gre_id_folder = localStorage.getItem("gre_id_folder");
      gre_id_folder = gre_id_folder ? gre_id_folder.split(",") : [];

      if (role === "superadmin") {
        setLoadingFolder(true);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
              gre_id_folder.length === 0
                ? "root"
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // // console.log("List Folder", response);

            const result = response.data.data.filter(
              (fldr) =>
                fldr.status_id === "2caec8df-bc0c-4463-9887-9f9af6236bc9" // 33984fe3-3018-43a0-8d8b-5a64d23c4643 (restored)
            );

            if (response.status === 200) {
              setListFolders(result);
            }
            setLoadingFolder(false);
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error", error);
          });
      } else {
        setLoadingFolder(true);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
              gre_id_folder.length === 0
                ? ""
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // console.log("List Folder", response);

            if (response.status === 200) {
              if (gre_id_folder.length === 0) {
                const partedFolders = response.data.data
                  .map((folder) => {
                    // Filter Check if id & folder_parent_id is equal
                    const filtering = response.data.data.filter(
                      (item) => item.id === folder.folder_parent_id
                    );

                    // Check if parent_id doesn't match with any id
                    if (filtering.length === 0) {
                      return { ...folder, folder_parent_id: null };
                    }
                  })
                  .filter((item) => item !== undefined)
                  .filter(
                    (fldr) =>
                      fldr.status_id === "2caec8df-bc0c-4463-9887-9f9af6236bc9" // 33984fe3-3018-43a0-8d8b-5a64d23c4643 (restored)
                  );

                setListFolders(partedFolders);
              } else {
                setListFolders(response.data.data);
              }
            }
            setLoadingFolder(false);
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error", error);
          });
      }
    }
  }, [openDialogAddEvidence, triggerNavigate]);

  // =============================== Handle GET List Files ===============================
  useEffect(() => {
    if (openDialogAddEvidence) {
      let gre_id_folder = localStorage.getItem("gre_id_folder");
      gre_id_folder = gre_id_folder ? gre_id_folder.split(",") : [];

      if (role === "superadmin") {
        setLoadingFile(true);

        axiosConfig
          .get(
            `${URL_API}/drive/file?options[filter][folder_id]=${
              gre_id_folder.length === 0
                ? "root"
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // // console.log("List File", response);

            const result = response.data.data.filter(
              (file) =>
                file.status_id === "a9821d66-e047-4a11-8c0e-beb316a01d87" // cbb1d326-8643-40ca-a808-07ffacba0422 (restored)
            );

            if (response.status === 200) {
              setListFiles(result);
            }
            setLoadingFile(false);
          })
          .catch((error) => {
            setLoadingFile(false);
            // console.log("Error", error);
          });
      } else {
        setLoadingFile(true);

        axiosConfig
          .get(
            `${URL_API}/drive/file?options[filter][folder_id]=${
              gre_id_folder.length === 0
                ? ""
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // // console.log("List File", response);

            if (response.status === 200) {
              // GET Folder Collection
              axiosConfig
                .get(
                  `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
                    gre_id_folder.length === 0
                      ? ""
                      : gre_id_folder[gre_id_folder.length - 1]
                  }`
                )
                .then((result) => {
                  if (result.status === 200) {
                    if (gre_id_folder.length === 0) {
                      const partedFiles = response.data.data
                        .map((file) => {
                          // Filter Check if id & folder_id is equal
                          const filtering = result.data.data.filter(
                            (item) => item.id === file.folder_id
                          );

                          // Check if folder_id doesn't match with any id of folders
                          if (filtering.length === 0) {
                            return { ...file };
                          }
                        })
                        .filter((item) => item !== undefined) // if item undefined, then remove it!
                        .filter(
                          (file) =>
                            file.status_id ===
                            "a9821d66-e047-4a11-8c0e-beb316a01d87" // cbb1d326-8643-40ca-a808-07ffacba0422 (restored)
                        );

                      setListFiles(partedFiles);
                    } else {
                      setListFiles(response.data.data);
                    }
                  }
                });
            }
            setLoadingFile(false);
          })
          .catch((error) => {
            setLoadingFile(false);
            // console.log("Error", error);
          });
      }
    }
  }, [openDialogAddEvidence, triggerNavigate]);

  // =============================== Handle Upload File ===============================
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      // console.log("acep file", acceptedFiles);
      // console.log("rejek file", fileRejections);

      if (acceptedFiles.length === 0) {
        return;
      } else if (acceptedFiles.length + greLength > 5) {
        alert(`Jumlah file tidak boleh lebih dari ${5 - greLength}`);
      } else {
        setFiles((prev) => {
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );

          let lastArr = 5 - greLength;

          return prev.concat(acceptedFiles).slice(0, lastArr);
        });
      }

      fileRejections.forEach((file) => {
        // File is too large
        if (file.size > maxSize) {
          alert("Ukuran File tidak boleh melebihi 30 MB");
        }

        // Rejected File
        if (file) {
          // // console.log("file reject", file);
          alert(
            `File ${file.name}, tidak dapat diunggah! (File tidak mendukung)`
          );
        }
      });
    },
    maxSize: maxSize,
    accept:
      ".txt, .xlsx, .xls, .doc, .docx, .pdf, .ppt, .pptx, .csv, text/plain, text/csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,	application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  image/jpeg, image/png, image/gif, audio/mpeg, audio/wav, video/mp4, video/x-m4v, video/mpeg, video/ms-asf, video/x-ms-wmv, video/quicktime, video/x-msvideo, application/x-shockwave-flash, application/xml, text/xml",
  });

  // =============================== Handle After Clear Search ===============================
  useEffect(() => {
    if (
      driveSearchValue === "" &&
      isSearchRun === false &&
      clearSearchResult === true
    ) {
      // Call List ID Folder for Handle Search
      let gre_id_folder = localStorage.getItem("gre_id_folder");
      gre_id_folder = gre_id_folder ? gre_id_folder.split(",") : [];

      setLoadingFolder(true);
      setLoadingFile(true);

      axiosConfig
        .get(
          `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
            gre_id_folder.length === 0
              ? "root"
              : gre_id_folder[gre_id_folder.length - 1]
          }`
        )
        .then((responseFolder) => {
          // // console.log("Clear Folder", responseFolder);

          if (responseFolder.status === 200) {
            setListFolders(responseFolder.data.data);
            setLoadingFolder(false);

            axiosConfig
              .get(
                `${URL_API}/drive/file?options[filter][folder_id]=${
                  gre_id_folder.length === 0
                    ? "root"
                    : gre_id_folder[gre_id_folder.length - 1]
                }`
              )
              .then((responseFile) => {
                // // console.log("Clear File", responseFile);

                if (responseFile.status === 200) {
                  setListFiles(responseFile.data.data);
                  setLoadingFile(false);
                }
              })
              .catch((error) => {
                setLoadingFile(false);
                // console.log("Error : ", error);
              });
          }
        })
        .catch((error) => {
          setLoadingFolder(false);
          // console.log("Error : ", error);
        });
    }
  }, [driveSearchValue, isSearchRun, clearSearchResult]);

  // =============================== Handle Search File/Folder ===============================
  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      // Call List ID Folder for Handle Search
      let gre_id_folder = localStorage.getItem("gre_id_folder");
      gre_id_folder = gre_id_folder ? gre_id_folder.split(",") : [];

      if (value !== "") {
        setLoadingFolder(true);
        setLoadingFile(true);

        localStorage.setItem("filemanager_search", value);

        setDriveSearchValue(value);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][ancestor_id]=${
              gre_id_folder.length === 0
                ? ""
                : gre_id_folder[gre_id_folder.length - 1]
            }&options[filter][search]=${value}`
          )
          .then((responseFolder) => {
            // // console.log("Search Folder", responseFolder);

            if (responseFolder.status === 200) {
              setSearchRun(true);
              setListFolders(responseFolder.data.data);
              setLoadingFolder(false);

              axiosConfig
                .get(
                  `${URL_API}/drive/file?options[filter][ancestor_id]=${
                    gre_id_folder.length === 0
                      ? ""
                      : gre_id_folder[gre_id_folder.length - 1]
                  }&options[filter][search]=${value}`
                )
                .then((responseFile) => {
                  // // console.log("Search File", responseFile);

                  if (responseFile.status === 200) {
                    setSearchRun(true);
                    setListFiles(responseFile.data.data);
                    setLoadingFile(false);
                  }
                })
                .catch((error) => {
                  setLoadingFile(false);
                  // console.log("Error : ", error);
                });
            }
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error : ", error);
          });
      } else {
        setSearchRun(false);
        setDriveSearchValue("");
        localStorage.removeItem("filemanager_search");
        inputRefSearch.current.value = "";

        setLoadingFolder(true);
        setLoadingFile(true);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
              gre_id_folder.length === 0
                ? ""
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((responseFolder) => {
            // // console.log("Search Folder", responseFolder);
            if (responseFolder.status === 200) {
              setSearchRun(true);
              setListFolders(responseFolder.data.data);
              setLoadingFolder(false);
              axiosConfig
                .get(
                  `${URL_API}/drive/file?options[filter][folder_id]=${
                    gre_id_folder.length === 0
                      ? ""
                      : gre_id_folder[gre_id_folder.length - 1]
                  }`
                )
                .then((responseFile) => {
                  // // console.log("Search File", responseFile);
                  if (responseFile.status === 200) {
                    setSearchRun(true);
                    setListFiles(responseFile.data.data);
                    setLoadingFile(false);
                  }
                })
                .catch((error) => {
                  setLoadingFile(false);
                  // console.log("Error : ", error);
                });
            }
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error : ", error);
          });
      }
    })
  );

  // Handle Remove File From List
  const handleRemoveFile = (file) => {
    setFiles((prev) => prev.filter((item) => item.name !== file.name));
  };

  const handleChangeEvidenceCategory = (e, data) => {
    setSelectEvidenceCategory(data);
  };

  // Choose File Drive
  const handleListItemClick = (event, index, data) => {
    setSelectedIndex(index);
    setFileDrive((prev) => {
      const sameId = prev.some((item) => item.id === data.id);
      let lastArr = 5 - greLength;

      if (!sameId) {
        return prev.concat(data).slice(0, lastArr);
      } else {
        return prev;
      }
    });
  };

  // Delete List Selected File Drive
  const handleDeleteSelectedDrive = (data) => {
    setFileDrive((prev) => prev.filter((item) => item.id !== data.id));
  };

  // // console.log("Drive selek", fileDrive);

  const handleNavigateFolder = (data) => {
    let gre_breadcrumb = localStorage.getItem("gre_breadcrumb");
    gre_breadcrumb = gre_breadcrumb ? gre_breadcrumb.split(",") : [];
    gre_breadcrumb.push(data.name);
    localStorage.setItem("gre_breadcrumb", gre_breadcrumb.toString());

    let gre_id_folder = localStorage.getItem("gre_id_folder");
    gre_id_folder = gre_id_folder ? gre_id_folder.split(",") : [];
    gre_id_folder.push(data.id);
    localStorage.setItem("gre_id_folder", gre_id_folder.toString());

    setTriggerNavigate(Math.floor(Math.random() * 1000 + 1));

    // console.log("Breads", gre_breadcrumb);
    // console.log("ListID", gre_id_folder);
  };

  const handlBreadcrumb = (data) => {
    let gre_breadcrumb = localStorage.getItem("gre_breadcrumb");
    gre_breadcrumb = gre_breadcrumb ? gre_breadcrumb.split(",") : [];
    let breadcrumb = gre_breadcrumb.slice(0, data + 1);
    localStorage.setItem("gre_breadcrumb", breadcrumb.toString());

    let gre_id_folder = localStorage.getItem("gre_id_folder");
    gre_id_folder = gre_id_folder ? gre_id_folder.split(",") : [];
    let id_folder = gre_id_folder.slice(0, data + 1);
    localStorage.setItem("gre_id_folder", id_folder.toString());

    setTriggerNavigate(Math.floor(Math.random() * 1000 + 1));
  };

  // Call List Name Folder for Breadcrumb
  let gre_breadcrumb = localStorage.getItem("gre_breadcrumb");
  gre_breadcrumb = gre_breadcrumb ? gre_breadcrumb.split(",") : [];

  const userToken = localStorage.getItem("userToken");

  /* ======================================================================
  Handle Add Evidence
  ====================================================================== */
  const handleAddEvidence = () => {
    if (selectEvidenceCategory.name === "Attachment") {
      setLoadingEvidence(true);
      const dataFile = new FormData();

      files.length > 0 &&
        files.map((file) => {
          setFireGoalDetail(false);
          // console.log("Da Fail", file);

          dataFile.append("GoalResultEvidence[content]", file);
          dataFile.append(
            "GoalResultEvidence[evidence_category_id]",
            selectEvidenceCategory.id
          );
          dataFile.append("GoalResultEvidence[description]", file.name);
        });

      axiosConfig
        .post(`${URL_API}/swd/goal-result/${item.id}/evidence`, dataFile, {
          headers: {
            ["Content-Type"]: "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log("Upload File Drive", response);
          // // console.log("Response GRE Drive", response);

          //*GOL RESULT LIST SELF - ONLY OWNER GOAL USER LOGIN
          axiosConfig
            .get(`${URL_API}/swd/goal/${goalDetailState.id}/result`)
            .then(function (response) {
              console.log(
                "Response Original COLLECTION GOAL RESULT FORCE INSIDE CREATE GR : ",
                response
              );

              if (response.status === 200) {
                setFireGoalDetail(true);
                context.seTriggerGR(Math.floor(Math.random() * 1000 + 1));
                setOpenDialogAddEvidence(false);
                setFiles([]);

                context.setCollectionGoalResultList(response.data.data);
                setLoadingEvidence(false);
              }
            })
            .catch(function (error) {
              // context.setLoaderEvidance(false);
              // console.log("Error : ", error.response);
            });
        })
        .catch((error) => {
          setLoadingEvidence(false);
          setFireGoalDetail(false);
          // console.log("Error  : ", error.response);

          setFiles([]);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    } else if (selectEvidenceCategory.name === "Drive") {
      setLoadingEvidence(true);

      return Promise.all(
        fileDrive.length > 0 &&
          fileDrive.map((drive) => {
            setFireGoalDetail(false);

            const data = {
              GoalResultEvidence: {
                evidence_category_id: selectEvidenceCategory.id,
                content: drive.id,
                description: drive.name,
              },
            };

            // // console.log("Drive Data : ", data);

            return axiosConfig.post(
              `${URL_API}/swd/goal-result/${item.id}/evidence`,
              data
            );
          })
      )
        .then((response) => {
          // // console.log("Response GRE Drive", response);

          // context.setSuccessAddEvidence(Math.floor(Math.random() * 1000 + 1));
          setFireGoalDetail(true);
          context.seTriggerGR(Math.floor(Math.random() * 1000 + 1));
          setOpenDialogAddEvidence(false);

          setFiles([]);
          setFileDrive([]);
          setLoadingEvidence(false);
        })
        .catch((error) => {
          setLoadingEvidence(false);
          setFireGoalDetail(false);
          // console.log("Error  : ", error.response);

          setFiles([]);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    } else {
      setFireGoalDetail(false);
      const data = {
        GoalResultEvidence: {
          evidence_category_id: selectEvidenceCategory.id,
          content: hyperlink,
          description: "",
        },
      };

      // // console.log("Drive Data : ", data);

      setLoadingEvidence(true);

      axiosConfig
        .post(`${URL_API}/swd/goal-result/${item.id}/evidence`, data)
        .then((response) => {
          // // console.log("Response GRE Drive", response);

          if (response.status === 200) {
            // context.setSuccessAddEvidence(Math.floor(Math.random() * 1000 + 1));
            setFireGoalDetail(true);
            context.seTriggerGR(Math.floor(Math.random() * 1000 + 1));
            setOpenDialogAddEvidence(false);

            setFiles([]);
          }
          setLoadingEvidence(false);
        })
        .catch((error) => {
          setLoadingEvidence(false);
          setFireGoalDetail(false);
          // console.log("Error  : ", error.response);

          setFiles([]);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }

    localStorage.removeItem("gre_id_folder");
    localStorage.removeItem("gre_breadcrumb");
  };

  return (
    <Dialog
      open={openDialogAddEvidence}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
        <Grid container direction="row" justify="space-between">
          <Typography variant="subtitle1" className={classes.title}>
            <b>{MyLang() ? bahasa.addEvidence : english.backToOri}</b>
          </Typography>

          <IconButton
            size="small"
            onClick={() => setOpenDialogAddEvidence(false)}
          >
            <HighlightOffIcon
              fontSize="small"
              style={{ color: "grey", fontSize: "24px" }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent
        style={{
          textAlign: "left",
          width: 425,
          marginBottom: 20,
        }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress style={{ color: "#d1354a" }} size={20} />
          </Box>
        ) : (
          <Fragment>
            <Box>
              <List>
                {evidenceCategoryCollections.map((item, i) => {
                  let nameEvidence = "";

                  if (MyLang()) {
                    switch (item.name) {
                      case "Attachment":
                        nameEvidence = "Lampiran";
                        break;
                      case "Drive":
                        nameEvidence = "Penyimpanan";
                        break;
                      case "Link":
                        nameEvidence = "Tautan";
                        break;
                      default:
                        nameEvidence = "";
                    }
                  } else {
                    nameEvidence = item.name;
                  }

                  return (
                    <Fragment key={i}>
                      <FormControlLabel
                        label={
                          <p
                            style={{ color: "#aaa", fontSize: 14 }}
                            className={classes.title}
                          >
                            {nameEvidence}
                          </p>
                        }
                        control={
                          <Radio
                            className={classes.rootRadio}
                            disableRipple
                            color="default"
                            name={nameEvidence}
                            value={item.id}
                            checked={item.id === selectEvidenceCategory.id}
                            onChange={(e) =>
                              handleChangeEvidenceCategory(e, item)
                            }
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.iconRadio,
                                  classes.checkedIconRadio
                                )}
                              />
                            }
                            icon={<span className={classes.iconRadio} />}
                          />
                        }
                      />
                    </Fragment>
                  );
                })}
              </List>
            </Box>

            {selectEvidenceCategory.name === "Attachment" ? (
              <Fragment>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ marginBottom: 5, color: "#888" }}
                >
                  {MyLang() ? bahasa.uploadEvidence : english.uploadEvidence}{" "}
                  <i style={{ marginBottom: 5, color: "#aaa" }}>
                    (image / video / pdf / txt)
                  </i>
                </Typography>

                <Box>
                  <div className={classes.uploadContainer}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <img src={dragDropImg} width="75" height="75" />
                      <p className={classes.title}>
                        {MyLang()
                          ? bahasa.dragDropCopas
                          : english.dragDropCopas}
                      </p>
                    </div>
                  </div>
                </Box>

                <Typography className={classes.title} variant="subtitle1">
                  <b>
                    File{" "}
                    {files.length === 5 - greLength
                      ? `(${files.length} - Max)`
                      : `(${files.length})`}
                  </b>
                </Typography>

                <List dense>
                  {files.length > 0 &&
                    files.map((file, i) => {
                      return (
                        <ListFiles
                          key={i}
                          file={file}
                          classes={classes}
                          onRemoveFile={handleRemoveFile}
                        />
                      );
                    })}
                </List>
                <PasteClipboard onFiles={setFiles} />
              </Fragment>
            ) : selectEvidenceCategory.name === "Drive" ? (
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom={1}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#888" }}
                  >
                    {MyLang() ? "Pengelola File" : "File Manager"}
                  </Typography>

                  <TextField
                    id="input-with-icon-textfield"
                    size="small"
                    style={{ width: 200 }}
                    placeholder="search..."
                    onChange={(e) => handleSearch(e.target.value)}
                    inputRef={inputRefSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            fontSize="small"
                            style={{ color: "#888" }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSearchRun(false);
                              setDriveSearchValue("");
                              localStorage.removeItem("filemanager_search");
                              setClearSearchResult(true);

                              setTimeout(() => {
                                inputRefSearch.current.value = "";
                              }, 200);
                            }}
                          >
                            <CloseRoundedIcon
                              fontSize="small"
                              style={{ color: "#888" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    variant="subtitle2"
                    className={classes.textBreadcrum}
                    onClick={() => {
                      localStorage.removeItem("gre_id_folder");
                      localStorage.removeItem("gre_breadcrumb");
                      setTriggerNavigate(Math.floor(Math.random() * 1000 + 1));
                    }}
                    style={{
                      color: gre_breadcrumb.length === 0 && "#333",
                    }}
                  >
                    Drive
                  </Typography>
                  {gre_breadcrumb.length > 0 &&
                    gre_breadcrumb.map((item, idx) => {
                      const colorText =
                        gre_breadcrumb.length - 1 === idx && "#333";

                      return (
                        <Typography
                          variant="subtitle2"
                          className={classes.textBreadcrum}
                          onClick={() => handlBreadcrumb(idx)}
                          style={{
                            color: colorText,
                          }}
                        >
                          {item}
                        </Typography>
                      );
                    })}
                </Breadcrumbs>

                <DriveEvidence
                  classes={classes}
                  listFolders={listFolders}
                  listFiles={listFiles}
                  selectedIndex={selectedIndex}
                  onListItemClick={handleListItemClick}
                  onNavigateFolder={handleNavigateFolder}
                  openDialogAddEvidence={openDialogAddEvidence}
                  triggerNavigate={triggerNavigate}
                  loadingFolder={loadingFolder}
                  loadingFile={loadingFile}
                />

                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ fontSize: 13 }}
                  >
                    {MyLang()
                      ? bahasa.fileDriveSelected
                      : english.fileDriveSelected}{" "}
                    {fileDrive.length === 5 - greLength
                      ? `(${fileDrive.length} - Max)`
                      : `(${fileDrive.length})`}{" "}
                    :
                  </Typography>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    marginTop={1}
                    marginBottom={1}
                  >
                    {fileDrive !== undefined &&
                      fileDrive.length > 0 &&
                      fileDrive.map((item, i) => (
                        <FileDriveSelected
                          key={i}
                          item={item}
                          handleDeleteSelectedDrive={handleDeleteSelectedDrive}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box marginBottom={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ marginBottom: 5, color: "#888" }}
                >
                  {MyLang() ? bahasa.link : english.link}
                </Typography>

                <TextField
                  id="outlined-size-small"
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder={MyLang() ? bahasa.pasteLink : english.pasteLink}
                  helperText="example : https://mypict.com/img002.jpg"
                  onChange={(e) => setHyperlink(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <LinkRoundedIcon
                          style={{ color: "#555", marginRight: 7.5 }}
                          fontSize="small"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          </Fragment>
        )}

        <DialogActions style={{ padding: 0, margin: 0 }}>
          {selectEvidenceCategory.name === "Link" ? (
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              size="large"
              disabled={hyperlink === ""}
              onClick={handleAddEvidence}
            >
              {loadingEvidence ? (
                <CircularProgress style={{ color: "#fff" }} size={16} />
              ) : MyLang() ? (
                bahasa.add
              ) : (
                english.add
              )}
            </Button>
          ) : selectEvidenceCategory.name === "Drive" ? (
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              size="large"
              disabled={fileDrive.length === 0}
              onClick={handleAddEvidence}
            >
              {loadingEvidence ? (
                <CircularProgress style={{ color: "#fff" }} size={16} />
              ) : MyLang() ? (
                bahasa.add
              ) : (
                english.add
              )}
            </Button>
          ) : selectEvidenceCategory.name === "Attachment" ? (
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              size="large"
              disabled={files.length === 0}
              onClick={handleAddEvidence}
            >
              {loadingEvidence ? (
                <CircularProgress style={{ color: "#fff" }} size={16} />
              ) : MyLang() ? (
                bahasa.add
              ) : (
                english.add
              )}
            </Button>
          ) : null}
        </DialogActions>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogAddEvidence;
