import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, CircularProgress } from "@material-ui/core";
import { navigate } from "hookrouter";

import useSWR from "swr";
import numeral from "numeral";

import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";

import ListItemYourGoal from "./ListItemYourGoal";

import PictNewDashboard from "../../../../../../assets/images/new_dashboard.png";
import handleError from "../../../../../Report/components/global/handleError";
import DialogError from "../../../../../../components/DialogError";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

const YourGoal = ({ classes, structurePositionTitleId, period, period200 }) => {
  const [yourPerform, setYourPerform] = useState([]);
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const dataYourPerform = {
    name: "Table",
    platform: "web",
    report_format: "table",
    filter_by: {
      entity: [],
      date_range: "all_time",
      user: structurePositionTitleId !== "" ? [structurePositionTitleId] : [],
      unit: [],
      status: [3],
      period_id: period !== undefined ? period.id : "",
      achievement: [
        {
          field: "goal",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
        {
          field: "ma",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
      ],
    },
    entities: ["goal", "ma"],
    group_by: ["full_name"],
    group_by_calculation: "avg",
    order_by: [{ field: "full_name", method: "ASC", name: "User Name" }],
    primary_column: "overall_result_achievement",
    unit_type_id: "",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  useEffect(() => {
    setLoading(true);

    if (period200 === 200) {
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=1&per_page=5`,
        dataYourPerform
      )
        .then((res) => {
          const result = res.data.data.results.data;
          // // console.log("RES SUMMARY YOUR PERFORM", res);

          if (res.status === 200) {
            setYourPerform(structurePositionTitleId !== "" ? result : []);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [period]);

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        <Box display="flex" justifyContent="center" mb={2}>
          <img src={PictNewDashboard} width={104} height={52} alt="dashboard" />
        </Box>

        <Box mb={2} display="flex" justifyContent="center">
          <Typography className={classes.title333} variant="subtitle1">
            <b>
              {MyLang() ? bahasa.youHaveReached : english.youHaveReached}{" "}
              {yourPerform.length > 0
                ? numeral(yourPerform[0].goal).format("0.00")
                : "0,00"}
              % {MyLang() ? bahasa.fromYourTarget : english.fromYourTarget} !
            </b>
          </Typography>
        </Box>

        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              size={14}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography className={classes.title888} variant="caption">
              {MyLang() ? bahasa.loading : english.loading}...
            </Typography>
          </Box>
        )}

        {!loading && yourPerform.length > 0 && (
          <ListItemYourGoal
            classes={classes}
            item={yourPerform[0].goal}
            origin="goal"
          />
        )}

        {!loading && yourPerform.length > 0 && (
          <ListItemYourGoal
            classes={classes}
            item={yourPerform[0].ma}
            origin="ma"
          />
        )}
        {!loading && yourPerform.length === 0 && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Typography className={classes.title555} variant="caption">
              {MyLang() ? bahasa.noAchievements : english.noAchievements} Goal{" "}
              {MyLang() ? bahasa.or : english.or} MA
            </Typography>
          </Box>
        )}
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default YourGoal;
