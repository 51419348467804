import React, { useEffect, useState } from "react";

import {
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CE354A", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const DialogNotifDetail = ({
  isOpenDialogNotifDetail,
  setOpenDialogNotifDetail,
  notifDetail,
  handleApprove,
  handleReject,
}) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={isOpenDialogNotifDetail}
        onClose={() => setOpenDialogNotifDetail(false)}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {notifDetail.type !== "notification"
            ? "REQUEST DETAIL"
            : "NOTIFICATION DETAIL"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {notifDetail.title}
          </Typography>
          <Typography variant="body1" style={{ textAlign: "center" }}>
            Pada{" "}
            {moment(notifDetail.created_at)
              .locale("id")
              .format("dddd, D MMMM YYYY")}
          </Typography>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "1rem",
            }}
          >
            <Grid item xs={5} sm={5}>
              {notifDetail.type !== "notification" ? (
                <>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Requester : <b>{notifDetail.user_name}</b>
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Status : <b>{notifDetail.status}</b>
                  </Typography>
                </>
              ) : (
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  Perusahaan : <b>{notifDetail.company_name}</b>
                </Typography>
              )}
              {notifDetail.desc != null && notifDetail.desc !== "" && (
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  Desc. : <b>{notifDetail.desc}</b>
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={5} sm={5}>
              {notifDetail.type === "request_exception" && (
                <>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Request Date :{" "}
                    <b>
                      {moment(notifDetail.date_before)
                        .locale("id")
                        .format("dddd, D MMMM YYYY")}
                    </b>
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Selected Date :{" "}
                    <b>
                      {moment(notifDetail.date_after)
                        .locale("id")
                        .format("dddd, D MMMM YYYY")}
                    </b>
                  </Typography>
                </>
              )}
              {notifDetail.type === "request_attendance" && (
                <>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Date :{" "}
                    <b>
                      {moment(notifDetail.created_at)
                        .locale("id")
                        .format("dddd, D MMMM YYYY")}
                    </b>
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Time : <b>{notifDetail.time}</b>
                  </Typography>
                </>
              )}
              {notifDetail.type === "request_time_off" && (
                <>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Schedule In : <b>{notifDetail.schedule_in}</b>
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Schedule Out : <b>{notifDetail.schedule_out}</b>
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    File Bukti :{" "}
                    <b>
                      <a href={notifDetail.file} target="_blank">
                        Download
                      </a>
                    </b>
                  </Typography>
                </>
              )}
              {notifDetail.type === "request_overtime" && (
                <>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Workday :{" "}
                    <b>
                      {moment(notifDetail.workday)
                        .locale("id")
                        .format("dddd, D MMMM YYYY")}
                    </b>
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Durasi Overtime :{" "}
                    <b>{parseInt(notifDetail.overtime_duration) / 60} Menit</b>
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Kategori Overtime :{" "}
                    <b>
                      {notifDetail.category === "after_shift"
                        ? "Setelah Shift"
                        : "Sebelum Shift"}
                    </b>
                  </Typography>
                </>
              )}
              {notifDetail.type === "notification" && (
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  Shift : <b>{notifDetail.shift}</b>
                </Typography>
              )}
            </Grid>
          </Grid>

          {notifDetail.type !== "notification" && (
            <Grid
              container
              spacing={3}
              style={{
                marginTop: "1rem",
              }}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleReject(notifDetail.id, notifDetail.type);
                  }}
                  style={{
                    height: "3rem",
                  }}
                  //disabled={loading}
                >
                  Reject
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleApprove(notifDetail.id, notifDetail.type);
                  }}
                  style={{
                    height: "3rem",
                  }}
                  //disabled={loading}
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default DialogNotifDetail;
