import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import moment from "moment";
import FormatBytes from "../../../../utilities/FormatBytes";
import TruncateTextShort from "../../../../utilities/TruncateTextShort";

function DialogInfoFolder({
  openDialogInfoFolder,
  setOpenDialogInfoFolder,
  item,
  classes,
}) {
  return (
    <Dialog
      open={openDialogInfoFolder}
      onClose={() => setOpenDialogInfoFolder(false)}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent style={{ width: 380, paddingBottom: 30, marginTop: 20 }}>
        <Box style={{ textAlign: "center" }}>
          <InfoOutlinedIcon
            style={{ fontSize: 30, color: "#888", marginBottom: 12 }}
          />
        </Box>

        <Grid container justify="flex-end">
          <Grid item md={5}>
            <List className={classes.title}>
              <ListItem>
                <ListItemText secondary={<b>Folder name : </b>} />
              </ListItem>

              <ListItem>
                <ListItemText secondary={<b>Size : </b>} />
              </ListItem>

              <ListItem>
                <ListItemText secondary={<b>Owner : </b>} />
              </ListItem>

              <ListItem>
                <ListItemText secondary={<b>Date created : </b>} />
              </ListItem>

              <ListItem>
                <ListItemText secondary={<b>Last modified : </b>} />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6}>
            <List className={classes.title}>
              <ListItem>
                <Tooltip title={item.name} placement="top-end">
                  <ListItemText secondary={TruncateTextShort(item.name)} />
                </Tooltip>
              </ListItem>

              <ListItem>
                <ListItemText secondary={FormatBytes(Number(item.size))} />
              </ListItem>

              <ListItem>
                <ListItemText
                  secondary={
                    item.owner !== undefined && item.owner !== null
                      ? `${item.owner.member.first_name} ${item.owner.member.last_name}`
                      : "User has been deleted"
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  secondary={moment(item.created_at).format(
                    "DD MMM YYYY, HH:mm"
                  )}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  secondary={moment(item.modified_at).format(
                    "DD MMM YYYY, HH:mm"
                  )}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default DialogInfoFolder;
