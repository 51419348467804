import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import numeral from "numeral";

import TruncateTextCustom from "../../../../../../utilities/TruncateTextCustom";
import ProgressBarItem from "../../../ComponentsDashboard/ProgressBarItem";
import ImageSmallUser from "../../../../../../components/ImageSmallUser";

const ListItemTopMas = ({ item, classes }) => {
  let color = "";
  let bgColor = "";

  if (numeral(item.ma).format("0.00") < 85) {
    color = "#e15367";
    bgColor = "#FDDBD8";
  } else if (
    numeral(item.ma).format("0.00") > 85 &&
    numeral(item.ma).format("0.00") < 100
  ) {
    color = "#fab445";
    bgColor = "#F8E4B0";
  } else if (numeral(item.ma).format("0.00") > 99) {
    color = "#34cc80";
    bgColor = "#D3F3E8";
  }

  return (
    <Paper className={classes.cardList}>
      <Box mb={1} p={1} display="flex">
        <AccountCircleIcon fontSize="large" style={{ color: "#555" }} />

        <Box width="100%" ml={2}>
          <Typography className={classes.title555} variant="subtitle2">
            <b>{TruncateTextCustom(item.full_name, 50)}</b>
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ProgressBarItem item={item.ma} />
            <Typography
              variant="caption"
              style={{
                background: bgColor,
                color: color,
                padding: "2px 8px",
                borderRadius: 25,
                marginLeft: 10,
              }}
            >
              {numeral(item.ma).format("0.00")}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ListItemTopMas;
