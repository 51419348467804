import React from "react";
import {
  Menu as MenuIcon,
  AccountCircle,
  Home,
  SupervisedUserCircle,
} from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Person from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";
import MoreIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SearchIcon from "@material-ui/icons/Search";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PollRoundedIcon from "@material-ui/icons/PollRounded";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SpeedIcon from "@material-ui/icons/Speed";
import PeopleIcon from "@material-ui/icons/People";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ArchiveIcon from "@material-ui/icons/Archive";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";

import ImageDashboardHome from "../assets/images/Subtraction_3.png";

import {
  ToCompanySetting,
  ToDashboard,
  ToUserManagement,
  ToLogin,
  ToEmptyStateGeneral,
  ToNewSO,
  To7wdPeriode,
  ToGoal,
  ToGoalDetail,
  ToGoalDetailALL,
  ToTask,
  ToAllMA,
  ToDrive,
  ToReports,
  ToArchive,
  ToAttendance,
  ToAttendanceSetting,
  ToAttendanceReportAdmin,
  ToTimeOff,
  ToTimeOffSetting,
  ToTimeOffCategory,
  ToAttendanceListRequest,
  ToOvertime,
  ToOvertimeSetting,
  ToException,
  ToProject,
  ToPlayground,
  ToAttendanceDashboard,
} from "../constants/config-redirect-url";

const MenuItems = [
  {
    name: "Dashboard",
    icon: "",
    image: "",
    path: "",
    isSuperAdmin: false,
    child: [
      {
        name: "Dashboard",
        icon: "",
        image: ImageDashboardHome,
        path: ToDashboard,
        isSuperAdmin: false,
        child: [],
      },
    ],
  },
  {
    name: "Attendance Dashboard",
    icon: "",
    image: "",
    path: "",
    isSuperAdmin: false,
    child: [
      {
        name: "Attendance Home",
        icon: <Home style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToAttendanceDashboard,
        isSuperAdmin: false,
        child: [],
      },
    ],
  },
  {
    name: "7WD",
    icon: "",
    image: "",
    path: "",
    isSuperAdmin: false,
    child: [
      {
        name: "Goal",
        icon: <ControlCameraIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToGoal,
        isSuperAdmin: false,
        child: [],
      },
      {
        name: "Measured Activities",
        icon: <SpeedIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToAllMA,
        isSuperAdmin: false,
        child: [],
      },
      {
        name: "Project",
        icon: <PlaylistAddCheckIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToProject,
        isSuperAdmin: false,
        child: [],
      },
      {
        name: "Periode",
        icon: <DateRangeIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: To7wdPeriode,
        isSuperAdmin: false,
        child: [],
      },
    ],
  },
  {
    name: "Human Capital",
    icon: "",
    image: "",
    path: "",
    isSuperAdmin: false,
    child: [
      {
        name: "SO",
        icon: <AccountTreeIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToNewSO,
        isSuperAdmin: true,
        child: [],
      },
      {
        name: "Karyawan",
        icon: <PeopleIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToEmptyStateGeneral,
        isSuperAdmin: false,
        child: [],
      },
    ],
  },
  {
    name: "Extra",
    icon: "",
    image: "",
    path: "",
    isSuperAdmin: false,
    child: [
      {
        name: "Drive",
        icon: <FolderOpenIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToDrive,
        isSuperAdmin: false,
        child: [],
      },
      {
        name: "Reports",
        icon: <PollRoundedIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToReports,
        isSuperAdmin: false,
        child: [],
      },
      {
        name: "Archive",
        icon: <ArchiveIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToArchive,
        isSuperAdmin: true,
        child: [],
      },
      {
        name: "To Do List",
        icon: <CheckBoxIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToTask,
        isSuperAdmin: false,
        child: [],
      },
    ],
  },
  {
    name: "Time Management",
    icon: "",
    image: "",
    path: "",
    isSuperAdmin: true,
    child: [
      {
        name: "Attendance",
        icon: <EventAvailableIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToAttendance,
        isSuperAdmin: true,
        child: [],
      },
      {
        name: "Time Off",
        icon: <EventBusyIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToTimeOff,
        isSuperAdmin: true,
        child: [],
      },
      {
        name: "Overtime",
        icon: <AlarmAddIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToOvertime,
        isSuperAdmin: true,
        child: [],
      },
      {
        name: "Exception",
        icon: <ReportProblemOutlinedIcon style={{ color: "#d4d3df" }} />,
        image: "",
        path: ToException,
        isSuperAdmin: true,
        child: [],
      },
    ],
  },
];

export default MenuItems;
