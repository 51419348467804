import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
  Snackbar,
  Slide,
} from "@material-ui/core";

import ButtonSimpanNOW from "../KontakDarurat/Components/ButtonSimpanNOW";

import Redirect from "../../../utilities/Redirect";
import { ToEmptyStateGeneral } from "../../../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  /*
        ````````````````
        INFO ERROR PAPER

        ````````````````
    */
  paperStyle: {
    // marginLeft : theme.spacing(3),
    // marginRight : theme.spacing(3),
    // marginBottom : theme.spacing(3),
    backgroundColor: "#ffcbcb",
  },
  titlePaperStyle: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    // marginLeft: theme.spacing(3),
    fontFamily: "Roboto",
    color: "#a50016",
  },
  closePaperStyle: {
    color: "#a50016",
  },
  timeFrameIcon: {
    fontFamily: "Roboto",
    color: "#4aa9fb", //#4aa9fb
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
    // backgroundColor: '#cfe8fd'
  },
});

const Snackbeer = (props) => {
  const {
    classes,
    isOpenDialogSimpanSekarang,
    setOpenDialogSimpanSekarang,
    dataForSimpanNow,
  } = props;

  useEffect(() => {
    if (isOpenDialogSimpanSekarang == true) {
      // console.log('dataForSimpanNow : ', dataForSimpanNow);
    }
  }, [isOpenDialogSimpanSekarang]);

  return (
    <MuiThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isOpenDialogSimpanSekarang}
        autoHideDuration={10000}
        onClose={() => setOpenDialogSimpanSekarang(false)}
        ContentProps={{
          "aria-describedby": "message-id",
          classes: {
            root: classes.paperStyle,
          },
        }}
        message={
          <span id="message-id" className={classes.titlePaperStyle}>
            <b>Mau simpan data karyawan sekarang & lanjutkan nanti ? </b>
          </span>
        }
        action={[
          <ButtonSimpanNOW
            classes={classes}
            isOpenDialogSimpanSekarang={isOpenDialogSimpanSekarang}
            setOpenDialogSimpanSekarang={setOpenDialogSimpanSekarang}
            dataForSimpanNow={dataForSimpanNow}
          />,
          // <Button
          //     onClick={ () => Redirect(ToEmptyStateGeneral) }
          //     variant='outlined'
          //     size='small'
          //     className={classes.timeFrameIcon}
          // >
          // {
          //     /* <i className="material-icons" style={{color: 'grey', fontSize: 14}}>
          //         calendar_today
          //     </i> */
          // }
          // &nbsp;
          // <b> Ya, simpan sekarang</b>
          // </Button>
        ]}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(Snackbeer);
