import React, { useContext, useState, useEffect } from "react";
import { Box, Snackbar, Button, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import styleTable from "../../style/task-list-table.module.css";

import moment from "moment";

import { getStartEndDateForProject } from "../global/helperGantt";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";
import handleError from "../../../Report/components/global/handleError";

// import DialogError from "../../../../components/DialogError";
import TaskListTable from "./TaskListTable";
import TaskListHeader from "./TaskListHeader";
import SnackbarError from "../../../../components/SnackbarError";

const GroupedGantt = ({ classes, view, isChecked, project }) => {
  const { setTriggerTask, projectTState } = useContext(ContextProject);

  // ============= STATE =============
  //   const [tasks, setTasks] = useState([...projects, ...tasks_form]);
  const [tasks, setTasks] = useState([...projectTState.groupsForm]);
  const [openBar, setOpenBar] = useState(false);
  const [loadUndo, setLoadUndo] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    setTasks([...projectTState.groupsForm]);
  }, [projectTState.groupsForm]);

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 120;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  // Change Task Name
  const tasksName = tasks?.map((el) => {
    return {
      ...el,
      name: el.title,
    };
  });

  // Ungroup Tasks
  const tasksUngroup = tasks?.filter((el) => el?.type === "task");

  // Change Task Name
  const tasksUngroupName = tasksUngroup?.map((el) => {
    return {
      ...el,
      name: el.title,
    };
  });

  // // console.log("TASKS", tasks);

  // ==================================================================
  // ~~~~~~~~~~~~~~ Handle Task Change (DRAG) ~~~~~~~~~~~~~~
  // ==================================================================
  const handleTaskChange = (task) => {
    // // console.log("On date START TASK: " + task.start_date);
    // // console.log("On date END TASK: " + task.due_date);

    localStorage.setItem("task_gantt_id", task.id);
    localStorage.setItem("task_gantt_start", task.start_date);
    localStorage.setItem("task_gantt_due", task.due_date);

    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    let start_date = null;
    let end_date = null;
    let start_error = [];
    let end_error = [];

    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);

      const mulai = moment(task?.start).format("dddd");
      const akhir = moment(task?.end).format("dddd");
      const projects =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];

      if (project?.exclude_weekend === 1) {
        if (akhir === "Sabtu") {
          const newEnd = new Date(task?.end);
          newEnd.setDate(newEnd.getDate() - 1);
          end_date = newEnd;
          end_error.push({
            description:
              "Tanggal selesai dipindahkan ke Jum'at, karena Sabtu adalah akhir pekan",
          });

          // console.log("AKhir sabtu", end_date);
        } else if (akhir === "Minggu") {
          const newEnd = new Date(task?.end);
          newEnd.setDate(newEnd.getDate() + 1);
          end_date = newEnd;
          end_error.push({
            description:
              "Tanggal selesai dipindahkan ke Senin, karena Minggu adalah akhir pekan",
          });
        } else if (mulai === "Sabtu") {
          const newStart = new Date(task?.start);
          newStart.setDate(newStart.getDate() - 1);
          start_date = newStart;
          start_error.push({
            description:
              "Tanggal mulai dipindahkan ke Jum'at, karena Sabtu adalah akhir pekan",
          });
        } else if (mulai === "Minggu") {
          const newStart = new Date(task?.start);
          newStart.setDate(newStart.getDate() + 1);
          start_date = newStart;
          start_error.push({
            description:
              "Tanggal mulai dipindahkan ke Senin, karena Minggu adalah akhir pekan",
          });
        } else if (
          mulai !== "Minggu" &&
          mulai !== "Sabtu" &&
          akhir !== "Minggu" &&
          akhir !== "Sabtu"
        ) {
          if (
            projects?.start?.getTime() !== start?.getTime() ||
            projects?.end?.getTime() !== end?.getTime()
          ) {
            const changedProject = { ...projects, start, end };
            newTasks = newTasks.map((t) =>
              t.id === task.project ? changedProject : t
            );
          }
        }
      } else {
        if (
          projects?.start?.getTime() !== start?.getTime() ||
          projects?.end?.getTime() !== end?.getTime()
        ) {
          const changedProject = { ...projects, start, end };
          newTasks = newTasks.map((t) =>
            t.id === task.project ? changedProject : t
          );
        }
      }
    }

    setTasks(newTasks);
    // // console.log("NEW TSK", newTasks);

    // ======= Select / Filter task that has same ID ======= //
    const taskSelect = newTasks.find((el) => el.id === task.id);
    const startDate = start_date !== null ? start_date : taskSelect.start;
    const endDate = end_date !== null ? end_date : taskSelect.end;

    const data = {
      Todolist: {
        title: taskSelect.title,
        description: taskSelect.description,
        remarks: taskSelect.remarks,
        owner_id: taskSelect.owner_id,
        status: taskSelect.status.id,
        priority: taskSelect.priority.id,
        start_date: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        due_date: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        tags: taskSelect.tags,
        actual_result: Number(taskSelect.actual_result),
        target_result: Number(taskSelect.target_result),
        project_id: taskSelect?.project_id,
        task_group_id: taskSelect?.task_group_id,
        predecessor_id: taskSelect?.dependencies,
        custom_phase_id: taskSelect?.custom_phase_id,
      },
    };

    // // console.log("DATAa", data);

    AxiosConfig.put(`${URL_API}/todolist/${task.id}`, data)
      .then((res) => {
        // console.log("RES Update", res);

        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          start_date = null;
          end_date = null;

          setOpenBar(true);

          if (start_error.length > 0) {
            // Error State
            setOpenDialogError(true);
            setTextErrorMessage("Info");
            setListError(start_error);
            setErrorStatus(400);
          }

          if (end_error.length > 0) {
            // Error State
            setOpenDialogError(true);
            setTextErrorMessage("Info");
            setListError(end_error);
            setErrorStatus(400);
          }
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setTriggerTask(Math.floor(Math.random() * 100));

        start_date = null;
        end_date = null;

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleUndoTask = () => {
    const task_gantt_id = localStorage.getItem("task_gantt_id");
    const task_gantt_start = localStorage.getItem("task_gantt_start");
    const task_gantt_due = localStorage.getItem("task_gantt_due");

    const taskSelect = tasks.find((task) => task.id === task_gantt_id);

    setLoadUndo(true);

    const data = {
      Todolist: {
        title: taskSelect?.title,
        description: taskSelect?.description,
        remarks: taskSelect?.remarks,
        owner_id: taskSelect?.owner_id,
        status: taskSelect?.status.id,
        priority: taskSelect?.priority.id,
        start_date: moment(task_gantt_start).format("YYYY-MM-DD HH:mm:ss"),
        due_date: moment(task_gantt_due).format("YYYY-MM-DD HH:mm:ss"),
        tags: taskSelect?.tags,
        actual_result: Number(taskSelect?.actual_result),
        target_result: Number(taskSelect?.target_result),
        project_id: taskSelect?.project_id,
        task_group_id: taskSelect?.task_group_id,
        predecessor_id: taskSelect?.dependencies,
        custom_phase_id: taskSelect?.custom_phase_id,
      },
    };

    // // console.log("DAta Undo", data);

    AxiosConfig.put(`${URL_API}/todolist/${task_gantt_id}`, data)
      .then((res) => {
        // console.log("RES Update", res);

        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));

          localStorage.removeItem("task_gantt_id");
          localStorage.removeItem("task_gantt_start");
          localStorage.removeItem("task_gantt_due");

          setOpenBar(false);
          setLoadUndo(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setTriggerTask(Math.floor(Math.random() * 100));
        setLoadUndo(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleCloseBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenBar(false);
  };

  //   // console.log("TASKSz", tasks);

  let THE_TASKS;

  if (isChecked && !projectTState.hideTaskGroup) {
    THE_TASKS = tasks;
  } else if (isChecked && projectTState.hideTaskGroup) {
    THE_TASKS = tasksUngroup;
  } else if (!isChecked && !projectTState.hideTaskGroup) {
    THE_TASKS = tasksName;
  } else if (!isChecked && projectTState.hideTaskGroup) {
    THE_TASKS = tasksUngroupName;
  }

  return (
    <Box mb={3}>
      {tasks.length > 0 ? (
        <Gantt
          tasks={THE_TASKS}
          viewMode={view}
          onDateChange={handleTaskChange}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
          todayColor="#d7eef4"
          barCornerRadius={5}
          fontSize={12}
          barBackgroundColor="#d2d2d2"
          barBackgroundSelectedColor="#c0c0c0"
          rowHeight={35}
          locale="id"
          TaskListHeader={({
            headerHeight,
            fontFamily,
            fontSize,
            rowWidth,
          }) => {
            return (
              <TaskListHeader
                headerHeight={headerHeight}
                fontFamily={fontFamily}
                fontSize={fontSize}
                rowWidth={rowWidth}
              />
            );
          }}
          TaskListTable={({
            tasks,
            onExpanderClick,
            rowHeight,
            rowWidth,
            fontFamily,
            fontSize,
          }) => {
            return (
              <div
                className={styleTable.taskListWrapper}
                style={{
                  fontFamily: fontFamily,
                  fontSize: fontSize,
                }}
              >
                {tasks.map((task) => {
                  let expanderSymbol = "";
                  if (task.hideChildren === false) {
                    expanderSymbol = "▼";
                  } else if (task.hideChildren === true) {
                    expanderSymbol = "▶";
                  }

                  return (
                    <TaskListTable
                      key={task.id}
                      task={task}
                      onExpanderClick={onExpanderClick}
                      rowHeight={rowHeight}
                      rowWidth={rowWidth}
                      expanderSymbol={expanderSymbol}
                    />
                  );
                })}
              </div>
            );
          }}
        />
      ) : (
        "no tasks"
      )}

      <Snackbar
        open={openBar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={6000}
        sev
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert onClose={handleCloseBar} severity="success">
          Berhasil mengubah tanggal
          <Button
            color="secondary"
            size="small"
            onClick={handleUndoTask}
            style={{ textTransform: "capitalize" }}
          >
            {loadUndo ? "Undoing changes.." : "UNDO"}
          </Button>
        </Alert>
      </Snackbar>

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default GroupedGantt;
