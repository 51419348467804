import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RemoveIcon from "@material-ui/icons/Remove";

import ContextGoal from "../Context/ContextGoalDetail";

import DialogError from "../../../../components/DialogError";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import handleError from "../../../Report/components/global/handleError";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";

const DialogSetAsComplete = (props) => {
  const {
    classes,
    isOpenDialogSetAsComplete,
    setOpenDialogSetAsComplete,
    goalDetailState,
    setFireGoalDetail,
  } = props;

  const context = useContext(ContextGoal);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [goalForComplete, setGoalForComplete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadGoal, setLoadGoal] = useState(false);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (isOpenDialogSetAsComplete) {
      setLoadGoal(true);

      const data = {
        selected_entities: [{ entity_id: goalDetailState.id }],
      };

      // // console.log("Multi Complete Create", data);

      AxiosConfig.post(
        `${URL_API}/swd/goal/recursive-complete/create-form`,
        data
      )
        .then((res) => {
          // console.log("RES Goal for Complete", res);
          const result = res.data.data;

          if (res.status === 200) {
            setGoalForComplete(result.fields.entity_list.default_value);
          }
          setLoadGoal(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoadGoal(false);
        });
    }
  }, [isOpenDialogSetAsComplete]);

  const handleComplete = () => {
    setLoading(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data = {
      entity_list: goalForComplete,
    };

    AxiosConfig.post(`${URL_API}/swd/goal/recursive-complete/store`, data)
      .then((response) => {
        // // console.log("Response Original : ", response);

        if (response.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: "goal",
              event: "update",
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ""
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: "",
              device_version: OSName,
              long: "",
              lat: "",
              entity_id: goalDetailState?.id,
              entity_name: goalDetailState?.name,
              remarks: "Status Goal (Completed) 1",
              comment: "",
            },
          };

          setDataRemarks(dataLog);

          setFireGoalDetail(true);
          // setOpenDialogSetAsComplete(false);
          setOpenRemarksLog(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // ========================================================================
  // ~ Render Tree ~
  // ========================================================================
  const renderTree = (nodes) => (
    <TreeItem key={nodes.entity_id} nodeId={nodes.entity_id} label={nodes.name}>
      {Array.isArray(nodes.sub_goals)
        ? nodes.sub_goals.map((node) => renderTree(node))
        : null}
      {Array.isArray(nodes.ma)
        ? nodes.ma.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  // ========================================================================
  // ~ Main Component ~
  // ========================================================================
  return (
    <Fragment>
      <Dialog
        open={isOpenDialogSetAsComplete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title333}>
            <b>Ubah status Goal menjadi Complete</b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: 550 }}>
          <Typography
            variant="subtitle2"
            className={classes.title333}
            style={{ textAlign: "center" }}
          >
            Apakah Anda yakin ingin mengubah status Goal{" "}
            <i>"{goalDetailState.name}"</i> menjadi <i>Completed Goal</i> ?
          </Typography>

          <Box pt={2} mb={3} display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ marginBottom: 5 }}
            >
              Goal :
            </Typography>
            {loadGoal && (
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <CircularProgress
                  size={16}
                  style={{ color: "#d64253", marginRight: 5 }}
                />
                <Typography variant="caption" className={classes.title888}>
                  Loading...
                </Typography>
              </Box>
            )}
            {!loadGoal &&
              goalForComplete.length > 0 &&
              goalForComplete.map((item, i) => (
                <TreeView
                  key={i}
                  defaultExpanded={["entity_id"]}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  defaultEndIcon={<RemoveIcon />}
                >
                  {renderTree(item)}
                </TreeView>
              ))}
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: 15,
            marginTop: 20,
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            className={classes.button0}
            onClick={() => setOpenDialogSetAsComplete(false)}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            size="medium"
            className={classes.button1}
            disabled={loadGoal}
            onClick={handleComplete}
          >
            {loading ? "Loading..." : "Set Complete"}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks={`Set Complete Goal - ${
          dataRemarks !== null ? dataRemarks.user_activity_log?.entity_name : ""
        }`}
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenDialogSetAsComplete}
        onTriggerRemarks={setFireGoalDetail}
        editRemarks={true}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogSetAsComplete;
