import React, { useEffect, useState } from "react";
import { styles } from "../Style/StyleAttendanceHome";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Paper,
  Divider,
} from "@material-ui/core";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";
import { navigate } from "hookrouter";
import {
  ToAttendance,
  ToAttendanceListRequest,
  ToAttendanceReportAdmin,
  ToException,
  ToOvertime,
  ToTimeOff,
} from "../../../../constants/config-redirect-url";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const AdminMenu = ({ data }) => {
  const classes = useStyles();
  const [userImage, setUserImage] = React.useState(null);
  const [roleName, setRoleName] = useState([]);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const [memberFirstName, setMemberFirstName] = useState("");
  const [memberLastName, setMemberLastName] = useState("");
  const [structurePositionTitleName, setStructurePositionTitleName] =
    useState("");
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      // setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);
        let menuUpdate = menu;

      if (statusUserLoginAfterParse.member_position && statusUserLoginAfterParse.member_position.is_superior) {
        setRoleName('manage');
        menuUpdate = [
          {
            id: 1,
            name: "Attendance",
            icon: <EventAvailableIcon style={{ color: "#221E5B" }} />,
            link: ToAttendance,
          },
          {
            id: 2,
            name: "Time Off",
            icon: <EventBusyIcon style={{ color: "#221E5B" }} />,
            link: ToTimeOff,
          },
          {
            id: 3,
            name: "Overtime",
            icon: <AlarmAddIcon style={{ color: "#221E5B" }} />,
            link: ToOvertime,
          },
          {
            id: 4,
            name: "Exception",
            icon: <ReportProblemOutlinedIcon style={{ color: "#221E5B" }} />,
            link: ToException,
          }
        ]
      } else {
        setRoleName('member');
        menuUpdate = [
          {
            id: 1,
            name: "Attendance",
            icon: <EventAvailableIcon style={{ color: "#221E5B" }} />,
            link: ToAttendance,
          },
          {
            id: 2,
            name: "Time Off",
            icon: <EventBusyIcon style={{ color: "#221E5B" }} />,
            link: ToTimeOff,
          },
          {
            id: 3,
            name: "Overtime",
            icon: <AlarmAddIcon style={{ color: "#221E5B" }} />,
            link: ToOvertime,
          },
          {
            id: 4,
            name: "Exception",
            icon: <ReportProblemOutlinedIcon style={{ color: "#221E5B" }} />,
            link: ToException,
          }
        ]

      }

      if (statusUserLoginAfterParse.userRole.length > 0) {
        if (statusUserLoginAfterParse.userRole[0].name === "superadmin") {
          setRoleName(statusUserLoginAfterParse.userRole[0].name);
          menuUpdate = [
            {
              id: 1,
              name: "Attendance",
              icon: <EventAvailableIcon style={{ color: "#221E5B" }} />,
              link: ToAttendance,
            },
            {
              id: 2,
              name: "Time Off",
              icon: <EventBusyIcon style={{ color: "#221E5B" }} />,
              link: ToTimeOff,
            },
            {
              id: 3,
              name: "Overtime",
              icon: <AlarmAddIcon style={{ color: "#221E5B" }} />,
              link: ToOvertime,
            },
            {
              id: 4,
              name: "Exception",
              icon: <ReportProblemOutlinedIcon style={{ color: "#221E5B" }} />,
              link: ToException,
            },
          ]
        }
        if (statusUserLoginAfterParse.userRole.length > 1) {
          if (statusUserLoginAfterParse.userRole[1].name === "admin") {
            setRoleName(statusUserLoginAfterParse.userRole[1].name);
          }
        }
      } else {
        // console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
      }
      setMenu(menuUpdate);

      //*
      if (statusUserLoginAfterParse.hasOwnProperty("member_first_name")) {
        setMemberFirstName(statusUserLoginAfterParse.member_first_name);
      }

      if (statusUserLoginAfterParse.hasOwnProperty("member_last_name")) {
        setMemberLastName(statusUserLoginAfterParse.member_last_name);
      }

      if (
        statusUserLoginAfterParse.hasOwnProperty(
          "structure_position_title_name"
        )
      ) {
        setStructurePositionTitleName(
          statusUserLoginAfterParse.member_position
            .structure_position_title_name
        );
      }
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      {(roleName === "superadmin" || roleName === "manage" || roleName === "member") && (
        <div style={{ marginBottom: "20px" }}>
          <Typography variant="h6" style={{ color: "#262261" }}>
            { roleName === "superadmin" && (<b> Admin </b>) }
            { roleName === "manage" && (<b> Manage </b>) }
            { roleName === "member" && (<b> List Request </b>) }
          </Typography>
          <Paper style={{ padding: "10px 20px" }} variant="outlined">
            <Grid
              container
              spacing={1}
              style={{ justifyContent: "space-between" }}
            >
              {menu.map((item, index) => (
                <Grid item xs={3} key={index}>
                  <Paper
                    variant="outlined"
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    <IconButton
                      aria-label={item.name}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                      }}
                    >
                      {item.icon}
                    </IconButton>
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: 8,
                        fontSize: 14,
                        color: "#262261",
                      }}
                    >
                      {item.name}
                    </p>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </div>
      )}
    </MuiThemeProvider>
  );
};

export default AdminMenu;
