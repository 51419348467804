import React, { useContext } from "react";
import { Grid, Card, CardHeader, Avatar, Typography } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import { navigate } from "hookrouter";

import ContextGlobalDrive from "../../context/ContextDrive";

const FolderRecycleBin = ({ classes }) => {
  const context = useContext(ContextGlobalDrive);
  const folderRecycleBin = { name: "Recycle Bin", id: "recyclebin" };

  const handleNavigateRecycleBin = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    breadcrumb.push(data.name);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  return (
    <Grid item md={3}>
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : 7,
          backgroundColor: "#e5fbfb",
        }}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#6ed8fb" }}>
              <DeleteRoundedIcon style={{ color: "#3498db" }} />
            </Avatar>
          }
          title={
            <Typography
              className={classes.folderNameText}
              variant="subtitle2"
              onClick={() => handleNavigateRecycleBin(folderRecycleBin)}>
              Recycle Bin
            </Typography>
          }
        />
      </Card>
    </Grid>
  );
};

export default FolderRecycleBin;
