import {
  ARCHIVE_TABS,
  ARCHIVE_ENTITY_LIST,
  ARCHIVE_GROUP_LIST,
  ARCHIVE_USER_LIST,
} from "../types";

// ~ Initial State for Table ~
export const initialArchive = {
  archiveTabs: 0,
  archiveEntityList: [],
  archiveGroupList: [],
  archiveUserList: [],
  loadGroup: false,
  loadUser: false,
  loadEntity: false,
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Table ~
export const archiveReducer = (state, { type, payload }) => {
  switch (type) {
    case ARCHIVE_TABS:
      return {
        ...state,
        archiveTabs: payload.archiveTabs,
      };
    case ARCHIVE_ENTITY_LIST:
      return {
        ...state,
        archiveEntityList: payload.archiveEntityList,
        loadEntity: payload.loadEntity,
      };
    case ARCHIVE_GROUP_LIST:
      return {
        ...state,
        archiveGroupList: payload.archiveGroupList,
        loadGroup: payload.loadGroup,
      };
    case ARCHIVE_USER_LIST:
      return {
        ...state,
        archiveUserList: payload.archiveUserList,
        loadUser: payload.loadUser,
      };
    default:
      return state;
  }
};
