import React from "react";
import { styles } from "../Style/StyleCheckInCheckOut";
import { navigate } from "hookrouter";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Button,
} from "@material-ui/core";
import { CancelRounded, CheckCircle, Domain } from "@material-ui/icons";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const PanelCard = ({
  verificationMethod,
  isCheckIn,
  setPinDialog,
  setPasswordDialog,
  setLiveAttendanceDialog,
}) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Card
        style={{
          position: "fixed",
          margin: "0 auto",
          background: "#221E5B",
          borderRadius: 24,
          marginTop: "1rem",
          width: "36%",
          maxWidth: "inherit",
        }}
        elevation={15}
      >
        <CardContent>
          <Typography
            variant="h2"
            style={{
              color: "#fff",
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            <Moment
              format="HH:mm"
              locale="id"
              interval={1}
              unit="seconds"
            ></Moment>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ color: "#fff", textAlign: "center" }}
            decoration="bold"
          >
            <Moment
              format="dddd, D MMMM YYYY"
              locale="id"
              interval={1}
              unit="seconds"
            ></Moment>
          </Typography>

          <Card
            style={{
              margin: "0 auto",
              width: "100%",
              marginTop: "1rem",
              borderRadius: 24,
            }}
          >
            <CardContent>
              <Typography
                variant="body1"
                style={{ color: "#221E5B", textAlign: "center" }}
              >
                Jadwal{" "}
                <Moment
                  format="D MMMM YYYY"
                  locale="id"
                  interval={1}
                  unit="seconds"
                ></Moment>
              </Typography>
              <Typography
                variant="h5"
                style={{
                  color: "#221E5B",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {verificationMethod?.shift?.schedule_in} -{" "}
                {verificationMethod?.shift?.schedule_out}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: "#221E5B",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Di {verificationMethod.schedule?.location.location_name}
              </Typography>

              <Box
                textAlign="center"
                style={{
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    marginTop: "1rem",
                    border: "2px",
                    borderColor: isCheckIn === true ? "#CE354A" : "#221E5B",
                    borderStyle: "solid",
                    width: "100%",
                    height: "3rem",
                    background: "#fff",
                    color: isCheckIn === true ? "#CE354A" : "#221E5B",
                    fontWeight: "bold",
                    marginBottom: "2rem",
                    height: "3rem",
                  }}
                  startIcon={<Domain />}
                  onClick={() => {
                    if (
                      verificationMethod &&
                      verificationMethod.verification !== null
                    ) {
                      if (
                        verificationMethod.verification
                          .verification_member_pin === 1
                      ) {
                        setPinDialog(true);
                      } else if (
                        verificationMethod.verification
                          .verification_member_pin === 0
                      ) {
                        if (
                          verificationMethod.verification
                            .verification_member_password === 1
                        ) {
                          setPasswordDialog(true);
                        } else if (
                          verificationMethod.verification
                            .verification_member_password === 0
                        ) {
                          setLiveAttendanceDialog(true);
                        }
                      }
                    }
                  }}
                >
                  {isCheckIn === true ? "Check Out" : "Check In"}
                </Button>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" style={{ color: "#221E5B" }}>
                      Jadwal Check In &amp; Out
                    </Typography>
                    {verificationMethod.schedule_detail?.map(
                      (schedule, index) => (
                        <Typography
                          variant="body1"
                          style={{ color: "#221E5B", fontWeight: "bold" }}
                          key={index}
                        >
                          {schedule.check_in} - {schedule.check_out}
                        </Typography>
                      )
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" style={{ color: "#221E5B" }}>
                      Lokasi
                    </Typography>
                    {verificationMethod.schedule_detail?.map(
                      (schedule, index) => (
                        <Typography
                          variant="body1"
                          style={{ color: "#221E5B", fontWeight: "bold" }}
                          key={index}
                        >
                          {schedule.location.location_name}
                        </Typography>
                      )
                    )}
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
};

export default PanelCard;
