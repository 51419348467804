import React, { useContext } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

import Title from "./Title";
import Card from "./Card";
import InputContainer from "./InputContainer";
import ContextProject from "../../context/ContextProject";

import "./style/list.css";

const List = ({
  list,
  index,
  classes,
  loadList,
  phaseCollection,
  taskGroupList,
  onTriggerPhase,
  onTriggerTaskGroup,
}) => {
  const { projectTState } = useContext(ContextProject);

  const listTitle = list.title;
  const objCustomPhase = phaseCollection?.find((el) => el.title === listTitle);
  const objTaskGroup = taskGroupList?.find((el) => el.name === listTitle);

  // // console.log("objCustomPhase", objCustomPhase);
  // // console.log("objTaskGroup", objTaskGroup);
  // // console.log("taskGroupList", taskGroupList);

  return (
    <Draggable
      draggableId={list.id}
      index={index}
      isDragDisabled={projectTState.groupBy === "custom_phase" ? false : true}
    >
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <div className="list-cards" {...provided.dragHandleProps}>
            <div className="title-list">
              <Title
                classes={classes}
                title={list.title}
                cardsCount={list.cards?.length}
                listId={list.id}
                loadList={loadList}
                phaseCollection={phaseCollection}
                onTriggerPhase={onTriggerPhase}
                onTriggerTaskGroup={onTriggerTaskGroup}
              />
            </div>
            <div className="container-cards">
              <Droppable droppableId={list.id} type="task">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="card-container"
                  >
                    {list.cards.map((card, index) => (
                      <Card
                        key={card.id}
                        card={card}
                        index={index}
                        listId={list.id}
                        classes={classes}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <InputContainer
              listId={list.id}
              objCustomPhase={objCustomPhase}
              objTaskGroup={objTaskGroup}
              type="card"
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default List;
