import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from '@material-ui/icons/Delete';
import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogCreate = (props) => {
  const {
    classes,
    isModalCreate,
    setModalCreate,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    masterDataShift,
    setMasterDataShift,
    dataShift,
    setDataShift,
    listError,
    addBreak,
    deleteBreak,
    addSchedule,
    deleteSchedule,
    createShift,
  } = props;

  const context = useContext(ContextAttendanceSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );
  const [locale, setLocale] = useState("id");

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalCreate}
        onClose={() => setModalCreate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
          disableTypography={true}
        >
          <Typography variant="h4">Buat Shift</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <Box p={1} my={1}>
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                Nama Work Pattern
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                options={masterDataShift.masterWorkPatternCollections}
                getOptionLabel={(option) => {
                  if (option && option.name) {
                     return option.name;
                  } else {
                    return "";
                  }
                }}
                value={dataShift.work_pattern}
                onChange={(event, newValue) => {
                  let dataShiftTemporary = dataShift;
                  dataShiftTemporary.work_pattern = newValue;
                  dataShiftTemporary.work_pattern_id = newValue ? newValue.id : '';
                  setDataShift({...dataShiftTemporary});
                }}
                noOptionsText="Tidak ada pilihan"
                popupIcon={<ArrowDropDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Pilih Work Pattern"
                    error={listError.some(item => 'work_pattern_id' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'work_pattern_id') {
                        return item.description;
                      }
                    })}
                  />
                )}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                Tulis Nama Shift
              </Typography>
              <Box my={0.5} />
              <TextField
                className={classes.textField}
                size="small"
                variant="outlined"
                fullWidth
                placeholder="e.g. Shift Pagi..."
                value={dataShift.title}
                onChange={(event, newValue) => {
                  let dataShiftTemporary = dataShift;
                  dataShiftTemporary.title = event.target.value;
                  setDataShift({...dataShiftTemporary});
                }}
                error={listError.some(item => 'title' === item.key)}
                helperText={listError.map((item) => {
                  if (item.key === 'title') {
                    return item.description;
                  }
                })}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                Cycle Time
              </Typography>
              <Box my={0.5} />
              <TextField
                className={classes.textField}
                size="small"
                variant="outlined"
                fullWidth
                placeholder="e.g. 0,1,2..."
                InputProps={{
                  endAdornment: <InputAdornment position="end">Menit</InputAdornment>,
                }}
                type="number"
                value={dataShift.cycle_time}
                onChange={(event, newValue) => {
                  let dataShiftTemporary = dataShift;
                  dataShiftTemporary.cycle_time = event.target.value;
                  setDataShift({...dataShiftTemporary});
                }}
                error={listError.some(item => 'cycle_time' === item.key)}
                helperText={listError.map((item) => {
                  if (item.key === 'cycle_time') {
                    return item.description;
                  }
                })}
              />
            </div>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  Jadwal Masuk
                </Typography>
                <Box my={0.5} />
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                  <TimePicker
                    placeholder="Jadwal Masuk"
                    value={dataShift.schedule_in_date}
                    onChange={(date) => {
                      let dataShiftTemporary = dataShift;
                      dataShiftTemporary.schedule_in_date = date;
                      dataShiftTemporary.schedule_in = moment(date).format("HH:mm");
                      setDataShift({...dataShiftTemporary});
                    }}
                    autoOk={true}
                    ampm={false}
                    inputVariant="outlined"
                    size="small"
                    className={classes.textField}
                    format="HH:mm"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small">
                            <EventAvailableIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  Jadwal Keluar
                </Typography>
                <Box my={0.5} />
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                  <TimePicker
                    placeholder="Jadwal Keluar"
                    value={dataShift.schedule_out_date}
                    onChange={(date) => {
                      let dataShiftTemporary = dataShift;
                      dataShiftTemporary.schedule_out_date = date;
                      dataShiftTemporary.schedule_out = moment(date).format("HH:mm");
                      setDataShift({...dataShiftTemporary});
                    }}
                    autoOk={true}
                    ampm={false}
                    inputVariant="outlined"
                    size="small"
                    className={classes.textField}
                    format="HH:mm"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small">
                            <EventAvailableIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  GMT
                </Typography>
                <Box my={0.5} />
                <Autocomplete
                  options={masterDataShift.masterGMTCollections}
                  getOptionLabel={(option) => {
                    if (option && option.name) {
                      return option.name;
                    } else {
                      return "";
                    }
                  }}
                  value={dataShift.gmt_obj}
                  onChange={(event, newValue) => {
                    let dataShiftTemporary = dataShift;
                    dataShiftTemporary.gmt_obj = newValue;
                    dataShiftTemporary.gmt = newValue ? newValue.id : '';
                    setDataShift({...dataShiftTemporary});
                  }}
                  noOptionsText="Tidak ada pilihan"
                  popupIcon={<ArrowDropDownIcon />}
                  renderInput={(params) => (
                    <TextField
                      style={{ fontSize: 13 }}
                      {...params}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Pilih Interval"
                      error={listError.some(item => 'gmt' === item.key)}
                      helperText={listError.map((item) => {
                        if (item.key === 'gmt') {
                          return item.description;
                        }
                      })}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div>
                  <div>
                    <Typography variant="subtitle2" component={'span'} className={classes.title333}>
                      Toleransi Datang Awal
                    </Typography>
                    <Switch
                      checked={dataShift.early_tolerance_status === 1}
                      onChange={(event) => {
                        let dataShiftTemporary = dataShift;
                        dataShiftTemporary.early_tolerance_status = event.target.checked ? 1 : 0;
                        if (dataShiftTemporary.early_tolerance_status === 0) {
                          dataShiftTemporary.early_tolerance = "";
                        }
                        setDataShift({...dataShiftTemporary});
                      }}
                      name="early_tolerance_status"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1,2..."
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Menit</InputAdornment>,
                    }}
                    type="number"
                    disabled={dataShift.early_tolerance_status !== 1}
                    value={dataShift.early_tolerance}
                    onChange={(event, newValue) => {
                      let dataShiftTemporary = dataShift;
                      dataShiftTemporary.early_tolerance = event.target.value;
                      setDataShift({...dataShiftTemporary});
                    }}
                    error={listError.some(item => 'early_tolerance' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'early_tolerance') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div>
                  <div>
                    <Typography variant="subtitle2" component={'span'} className={classes.title333}>
                      Toleransi Datang Terlambat
                    </Typography>
                    <Switch
                      checked={dataShift.late_tolerance_status === 1}
                      onChange={(event) => {
                        let dataShiftTemporary = dataShift;
                        dataShiftTemporary.late_tolerance_status = event.target.checked ? 1 : 0;
                        setDataShift({...dataShiftTemporary});
                      }}
                      name="late_tolerance_status"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1,2..."
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Menit</InputAdornment>,
                    }}
                    type="number"
                    disabled={dataShift.late_tolerance_status !== 1}
                    value={dataShift.late_tolerance}
                    onChange={(event, newValue) => {
                      let dataShiftTemporary = dataShift;
                      dataShiftTemporary.late_tolerance = parseInt(event.target.value);
                      setDataShift({...dataShiftTemporary});
                    }}
                    error={listError.some(item => 'late_tolerance' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'late_tolerance') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div>
                  <div>
                    <Typography variant="subtitle2" component={'span'} className={classes.title333}>
                      Toleransi Pulang Awal
                    </Typography>
                    <Switch
                      checked={dataShift.clock_out_early_tolerance_status === 1}
                      onChange={(event) => {
                        let dataShiftTemporary = dataShift;
                        dataShiftTemporary.clock_out_early_tolerance_status = event.target.checked ? 1 : 0;
                        if (dataShiftTemporary.clock_out_early_tolerance_status === 0) {
                          dataShiftTemporary.clock_out_early_tolerance = "";
                        }
                        setDataShift({...dataShiftTemporary});
                      }}
                      name="clock_out_early_tolerance_status"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1,2..."
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Menit</InputAdornment>,
                    }}
                    type="number"
                    disabled={dataShift.clock_out_early_tolerance_status !== 1}
                    value={dataShift.clock_out_early_tolerance}
                    onChange={(event, newValue) => {
                      let dataShiftTemporary = dataShift;
                      dataShiftTemporary.clock_out_early_tolerance = event.target.value;
                      setDataShift({...dataShiftTemporary});
                    }}
                    error={listError.some(item => 'clock_out_early_tolerance' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'clock_out_early_tolerance') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div>
                  <div>
                    <Typography variant="subtitle2" component={'span'} className={classes.title333}>
                      Toleransi Pulang Terlambat
                    </Typography>
                    <Switch
                      checked={dataShift.clock_out_late_tolerance_status === 1}
                      onChange={(event) => {
                        let dataShiftTemporary = dataShift;
                        dataShiftTemporary.clock_out_late_tolerance_status = event.target.checked ? 1 : 0;
                        setDataShift({...dataShiftTemporary});
                      }}
                      name="clock_out_late_tolerance_status"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1,2..."
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Menit</InputAdornment>,
                    }}
                    type="number"
                    disabled={dataShift.clock_out_late_tolerance_status !== 1}
                    value={dataShift.clock_out_late_tolerance}
                    onChange={(event, newValue) => {
                      let dataShiftTemporary = dataShift;
                      dataShiftTemporary.clock_out_late_tolerance = parseInt(event.target.value);
                      setDataShift({...dataShiftTemporary});
                    }}
                    error={listError.some(item => 'clock_out_late_tolerance' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'clock_out_late_tolerance') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <div>
              <div>
                <Grid container>
                  <Grid item md={6} xs={12} style={{ textAlign: "left" }}>
                    <Typography variant="subtitle2" className={classes.title333}>
                      Waktu Istirahat
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12} style={{ textAlign: "right" }}>
                    <Button
                      variant="outlined"
                      className={(masterDataShift.masterBreakCollections.length <= 0 || dataShift.break_obj.length >= masterDataShift.masterBreakCollections.length) ? classes.buttonDisabled : classes.button}
                      disabled={masterDataShift.masterBreakCollections.length <= 0 || dataShift.break_obj.length >= masterDataShift.masterBreakCollections.length}
                      onClick={() => {
                        addBreak();
                      }}>
                      Tambah Istirahat
                    </Button>
                  </Grid>
                </Grid>
              </div>
              {
                dataShift.break_obj.map((item, i) => {
                  return (
                    <Box key={i} mt={2}>
                      <Grid container>
                        <Grid item md={11} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={masterDataShift.masterBreakCollections}
                            getOptionLabel={(option) => {
                              if (option && option.title) {
                                return option.title + " (" + 
                                  option.break_start + '-' + option.break_end + 
                                  ")";
                              } else {
                                return "";
                              }
                            }}
                            value={dataShift.break_obj[i]}
                            onChange={(event, newValue) => {
                              let dataShiftTemporary = dataShift;
                              dataShiftTemporary.break_obj[i] = newValue;
                              dataShiftTemporary.break[i] = newValue ? newValue.id : '';
                              setDataShift({...dataShiftTemporary});
                            }}
                            noOptionsText="Tidak ada pilihan"
                            popupIcon={<ArrowDropDownIcon />}
                            renderInput={(params) => (
                              <TextField
                                style={{ fontSize: 13 }}
                                {...params}
                                fullWidth
                                variant="outlined"
                                size="small"
                                label=""
                                error={listError.some(item => 'break' + i === item.key)}
                                helperText={listError.map((item) => {
                                  if (item.key === 'break' + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={1} style={{ textAlign: "center" }}>
                          <IconButton aria-label="delete" color="secondary"
                            style={{marginTop: "5px"}} size="small"
                            onClick={() => {
                              deleteBreak(i);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              }
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setModalCreate(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant="outlined"
              className={classes.button1}
              onClick={createShift}
            >
              Simpan !
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogCreate;