import React, { useContext, useState } from "react";
import DialogCreate from "./DialogCreate";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../../components/StyledMenuDropdown";
import DialogDelete from "./DialogDelete";
import DialogEdit from "./DialogEdit";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
function ViewTabSchedule({
  classes,
  isModalProgress,
  setModalProgress,
  dataScheduleList,
  setDataScheduleList,
  getScheduleList,
}) {
  const context = useContext(ContextAttendanceSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterDataSchedule, setMasterDataSchedule] = useState({
    fields: {},
    masterWorkPatternCollections: [],
    masterShiftCollections: [],
    masterLocationCollections: [],
    masterConsequenceTypeCollections: [
      {
        id: "leave",
        name: "Leave",
      },
      {
        id: "financial",
        name: "Financial",
      },
      {
        id: "",
        name: "Tidak Ada",
      },
    ],
  });
  const [dataSchedule, setDataSchedule] = useState({
    id: "",
    shift_id: "",
    shift: {},
    name: "",
    location_id: "",
    location: {},
    day_in: "",
    day_in_arr: [],
    day_out: "",
    day_out_arr: [],
    start_date: "",
    schedule_in: moment(),
    schedule_out: moment(),
    work_pattern: {},
    work_pattern_id: {},
    work_pattern_obj: {},
    consequence_type: "",
    consequence_type_obj: null,
    total_consequence: 0,
    allow_failed_verification: 0,
    verification: {
      schedule: 1,
      location_coordinate: 1,
      member_pin: 1,
      member_password: 1,
      thumb_recognition: 1,
      face_recognition: 0,
      schedule_max_attempts: 1,
      location_coordinate_max_attempts: 1,
      member_pin_max_attempts: 1,
      member_password_max_attempts: 1,
      thumb_recognition_max_attempts: 1,
      face_recognition_max_attempts: 0,
    },
  });

  const [masterMonthlyCollections, setMasterMonthlyCollections] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]);

  const [masterWeeklyCollections, setMasterWeeklyCollections] = useState([
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ]);

  const [masterCustomCollections, setMasterCustomCollections] = useState([]);

  const resetDataSchedule = () => {
    let dataScheduleTemporary = dataSchedule;
    dataScheduleTemporary.id = "";
    dataScheduleTemporary.shift_id = "";
    dataScheduleTemporary.shift = {};
    dataScheduleTemporary.name = "";
    dataScheduleTemporary.location_id = "";
    dataScheduleTemporary.location = {};
    dataScheduleTemporary.day_in = "";
    dataScheduleTemporary.day_in_arr = [];
    dataScheduleTemporary.day_out = "";
    dataScheduleTemporary.day_out_arr = [];
    dataScheduleTemporary.start_date = "";
    dataScheduleTemporary.schedule_in = moment();
    dataScheduleTemporary.schedule_out = moment();
    dataScheduleTemporary.work_pattern = {};
    dataScheduleTemporary.consequence_type = null;
    dataScheduleTemporary.consequence_type_obj = null;
    dataScheduleTemporary.total_consequence = 0;
    dataScheduleTemporary.allow_failed_verification = 0;
    dataScheduleTemporary.work_pattern_id = "";
    dataScheduleTemporary.work_pattern_obj = {};
    dataScheduleTemporary.verification = {
      schedule: 1,
      location_coordinate: 1,
      member_pin: 1,
      member_password: 1,
      thumb_recognition: 1,
      face_recognition: 0,
      schedule_max_attempts: 1,
      location_coordinate_max_attempts: 1,
      member_pin_max_attempts: 1,
      member_password_max_attempts: 1,
      thumb_recognition_max_attempts: 1,
      face_recognition_max_attempts: 0,
    };
    setDataSchedule({ ...dataScheduleTemporary });
  };

  const handleShowModalCreate = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/schedule-pattern/create`)
      .then(function (response) {
        resetDataSchedule();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataSchedule;
        masterData.fields = response.data.data.fields;
        masterData.masterWorkPatternCollections =
          response.data.data.masterWorkPatternCollections;
        masterData.masterShiftCollections =
          response.data.data.masterShiftCollections;
        masterData.masterLocationCollections =
          response.data.data.masterLocationCollections;
        setMasterDataSchedule(masterData);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const createSchedule = () => {
    context.setModalProgress(true);
    setLoader(true);
    handleSetDayInDayOut();
    let dataScheduleTemp = dataSchedule;
    if (dataSchedule.schedule_in) {
      dataScheduleTemp.start_date =
        dataScheduleTemp.schedule_in.format("YYYY-MM-DD");
    }
    let data = {
      SchedulePattern: dataScheduleTemp,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/schedule-pattern/create`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(false);
        context.setMessages("Data jadwal berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetDataSchedule();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataScheduleList.options.paging.limit * newPage,
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 0, parseInt(event.target.value));
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDelete = (e, item) => {
    handleDropdownClose();
    let dataScheduleTemporary = dataSchedule;
    dataScheduleTemporary.id = item.id;
    dataScheduleTemporary.name = item.name;
    setDataSchedule({ ...dataScheduleTemporary });
    context.setModalDelete(true);
  };

  const handleDelete = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/human-resource/schedule-pattern/${dataSchedule.id}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalDelete(false);
          context.setMessages("Berhasil di hapus !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, 0, 0, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleShowModalEdit = (e, item) => {
    handleDropdownClose();
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/schedule-pattern/${item.id}/update`)
      .then(function (response) {
        resetDataSchedule();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataSchedule;
        masterData.fields = response.data.data.fields;
        masterData.masterWorkPatternCollections =
          response.data.data.masterWorkPatternCollections;
        masterData.masterShiftCollections =
          response.data.data.masterShiftCollections;
        masterData.masterLocationCollections =
          response.data.data.masterLocationCollections;
        setMasterDataSchedule(masterData);
        getDataSchedule(item);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangeWorkPattern = (newValue) => {
    setLoader(true);
    context.setModalProgress(true);
    let dataTemp = dataSchedule;
    dataTemp.work_pattern_obj = newValue;
    dataTemp.work_pattern_id = newValue ? newValue.id : "";
    dataTemp.shift_id = "";
    dataTemp.shift = {};
    setDataSchedule({ ...dataTemp });
    getMasterShift(newValue);
  };

  const getMasterShift = (newValue) => {
    if (newValue) {
      let data = {
        SchedulePatternDetail: {
          work_patterns: [newValue.id],
        },
      };
      axiosConfig
        .post(`${URL_API}/human-resource/schedule-pattern-detail/shift`, data)
        .then(function (response) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Response Original : ", response.data);
          let dataTemp = masterDataSchedule;
          masterDataSchedule.masterShiftCollections = response.data.data;
          setMasterDataSchedule({ ...dataTemp });
        })
        .catch(function (error) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Error : ", error.response);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      let dataTemp = masterDataSchedule;
      masterDataSchedule.masterShiftCollections = [];
      setMasterDataSchedule({ ...dataTemp });
      setLoader(false);
      context.setModalProgress(false);
    }
  };

  const getDataSchedule = (item) => {
    axiosConfig
      .get(`${URL_API}/human-resource/schedule-pattern/${item.id}`)
      .then(function (response) {
        resetDataSchedule();
        // console.log("Response Original : ", response.data);
        let dataScheduleTemporary = dataSchedule;
        let momentNow = moment();
        dataScheduleTemporary.id = response.data.data.id;
        dataScheduleTemporary.shift_id = response.data.data.shift_id;
        dataScheduleTemporary.shift = response.data.data.shift;
        dataScheduleTemporary.name = response.data.data.name;
        dataScheduleTemporary.pattern_type = response.data.data.pattern_type;
        dataScheduleTemporary.location_id = response.data.data.location_id;
        dataScheduleTemporary.location = response.data.data.location;
        dataScheduleTemporary.start_date = response.data.data.initial_date;
        dataScheduleTemporary.day_in = response.data.data.day_in;
        dataScheduleTemporary.day_out = response.data.data.day_out;
        dataScheduleTemporary.consequence_type =
          response.data.data.consequence_type;
        dataScheduleTemporary.consequence_type_obj = null;
        if (dataScheduleTemporary.consequence_type) {
          masterDataSchedule.masterConsequenceTypeCollections.map((item) => {
            if (item.id === dataScheduleTemporary.consequence_type) {
              dataScheduleTemporary.consequence_type_obj = item;
            }
          });
        }
        dataScheduleTemporary.total_consequence = response.data.data
          .total_consequence
          ? parseInt(response.data.data.total_consequence)
          : null;
        if (response.data.data.verification) {
          dataScheduleTemporary.verification = {
            schedule: response.data.data.verification.verification_schedule,
            location_coordinate:
              response.data.data.verification.verification_location_coordinate,
            member_pin: response.data.data.verification.verification_member_pin,
            member_password:
              response.data.data.verification.verification_member_password,
            thumb_recognition:
              response.data.data.verification.verification_thumb_recognition,
            face_recognition:
              response.data.data.verification.verification_face_recognition,
            schedule_max_attempts:
              response.data.data.verification
                .verification_schedule_max_attempts,
            location_coordinate_max_attempts:
              response.data.data.verification
                .verification_location_coordinate_max_attempts,
            member_pin_max_attempts:
              response.data.data.verification
                .verification_member_pin_max_attempts,
            member_password_max_attempts:
              response.data.data.verification
                .verification_member_password_max_attempts,
            thumb_recognition_max_attempts:
              response.data.data.verification
                .verification_thumb_recognition_max_attempts,
            face_recognition_max_attempts:
              response.data.data.verification
                .verification_face_recognition_max_attempts,
          };
        } else {
          dataScheduleTemporary.verification = {
            schedule: 1,
            location_coordinate: 1,
            member_pin: 1,
            member_password: 1,
            thumb_recognition: 1,
            face_recognition: 1,
            schedule_max_attempts: 1,
            location_coordinate_max_attempts: 1,
            member_pin_max_attempts: 1,
            member_password_max_attempts: 1,
            thumb_recognition_max_attempts: 1,
            face_recognition_max_attempts: 1,
          };
        }
        if (dataScheduleTemporary.shift) {
          dataScheduleTemporary.work_pattern_id =
            response.data.data.shift.work_pattern_id;
          dataScheduleTemporary.work_pattern_obj =
            response.data.data.shift.workPattern;
          getMasterShift(dataScheduleTemporary.shift.work_pattern_id);
        }
        dataScheduleTemporary.allow_failed_verification =
          response.data.data.allow_failed_verification;
        setDataSchedule({ ...dataScheduleTemporary });
        handleChangeShiftEdit(response.data.data.shift, dataScheduleTemporary);
        console.log(dataScheduleTemporary);
        context.setModalEdit(true);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalEdit(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleUpdate = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      handleSetDayInDayOut();
      let dataScheduleTemp = dataSchedule;
      if (dataSchedule.schedule_in) {
        dataScheduleTemp.start_date =
          dataScheduleTemp.schedule_in.format("YYYY-MM-DD");
      }
      let data = {
        SchedulePattern: dataScheduleTemp,
      };
      axiosConfig
        .put(
          URL_API + `/human-resource/schedule-pattern/${dataSchedule.id}`,
          data
        )
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalEdit(false);
          context.setMessages("Berhasil di edit !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, null, null, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const refreshList = (search, offset, page, limit) => {
    let dataScheduleListTemporary = dataScheduleList;
    dataScheduleListTemporary.options.filter.search =
      search !== null
        ? search
        : dataScheduleListTemporary.options.filter.search;
    dataScheduleListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataScheduleListTemporary.options.paging.offset;
    dataScheduleListTemporary.options.paging.page =
      page !== null ? page : dataScheduleListTemporary.options.paging.page;
    dataScheduleListTemporary.options.paging.limit =
      limit !== null ? limit : dataScheduleListTemporary.options.paging.limit;
    setDataScheduleList({ ...dataScheduleListTemporary });
    getScheduleList();
  };

  const handleChangeShift = (value) => {
    let dataScheduleTemporary = dataSchedule;
    dataScheduleTemporary.shift = value;
    dataScheduleTemporary.shift_id = value ? value.id : "";
    if (value) {
      setLoader(true);
      context.setModalProgress(true);
      axiosConfig
        .get(
          `${URL_API}/human-resource/schedule-pattern/${value.id}/work-pattern/interval`
        )
        .then(function (response) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Response Original : ", response.data);
          dataScheduleTemporary.day_in = "";
          dataScheduleTemporary.day_in_arr = [];
          dataScheduleTemporary.day_out = "";
          dataScheduleTemporary.day_out_arr = [];
          let masterMonthly = [];
          let masterCustom = [];
          if (response.data.data) {
            dataScheduleTemporary.schedule_in = moment();
            dataScheduleTemporary.schedule_out = moment().add(
              response.data.data.duration,
              "days"
            );
            dataScheduleTemporary.work_pattern = response.data.data;
            if (
              response.data.data &&
              response.data.data.interval === "Monthly"
            ) {
              for (
                let index = 0;
                index < response.data.data.duration;
                index++
              ) {
                masterMonthly.push(parseInt(index + 1));
              }
            } else if (
              response.data.data &&
              response.data.data.interval === "Custom"
            ) {
              // let startingMoment = dataScheduleTemporary.schedule_in.clone();;
              // while(startingMoment <= dataScheduleTemporary.schedule_out) {
              //   masterCustom.push({
              //     id: startingMoment.clone().format("YYYY-MM-DD"),
              //     name: startingMoment.clone().format("DD-MM-YYYY")
              //   });
              //   startingMoment.add(1, 'days');
              // }
              let totalDuration = response.data.data.duration;
              for (let index = 1; index <= totalDuration; index++) {
                masterCustom.push({
                  id: index,
                  name: "Hari ke-" + index,
                });
              }
            }
          }
          setMasterMonthlyCollections(masterMonthly);
          setMasterCustomCollections(masterCustom);
          setDataSchedule({ ...dataScheduleTemporary });
        })
        .catch(function (error) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Error : ", error.response);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      dataScheduleTemporary.work_pattern = {};
      setDataSchedule({ ...dataScheduleTemporary });
      setLoader(false);
      context.setModalProgress(false);
    }
  };

  const handleChangeShiftEdit = (value, dataScheduleObj) => {
    let dataScheduleTemporary = dataSchedule;
    dataScheduleTemporary.shift = value;
    dataScheduleTemporary.shift_id = value ? value.id : "";
    if (value) {
      setLoader(true);
      context.setModalProgress(true);
      axiosConfig
        .get(
          `${URL_API}/human-resource/schedule-pattern/${value.id}/work-pattern/interval`
        )
        .then(function (response) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Response Original : ", response.data);
          dataScheduleTemporary.day_in = dataScheduleObj.day_in;
          dataScheduleTemporary.day_out = dataScheduleObj.day_out;
          let masterMonthly = [];
          let masterCustom = [];
          if (response.data.data) {
            let schedule_in_str = "";
            let schedule_out_str = "";
            dataScheduleTemporary.work_pattern = response.data.data;
            if (dataScheduleObj.day_in) {
              dataScheduleTemporary.day_in_arr = dataScheduleObj.day_in
                .split(",")
                .map(function (item) {
                  if (
                    response.data.data &&
                    response.data.data.interval === "Custom"
                  ) {
                    // if (schedule_in_str || schedule_in_str < item) {
                    //   schedule_in_str = item;
                    // }
                    // if (schedule_out_str || schedule_out_str > item) {
                    //   schedule_out_str = item;
                    // }
                    return item;
                  } else {
                    return parseInt(item);
                  }
                });
            }
            if (dataScheduleObj.day_out) {
              dataScheduleTemporary.day_out_arr = dataScheduleObj.day_out
                .split(",")
                .map(function (item) {
                  if (
                    response.data.data &&
                    response.data.data.interval === "Custom"
                  ) {
                    // if (schedule_in_str || schedule_in_str < item) {
                    //   schedule_in_str = item;
                    // }
                    // if (schedule_out_str || schedule_out_str > item) {
                    //   schedule_out_str = item;
                    // }
                    return item;
                  } else {
                    return parseInt(item);
                  }
                });
            }
            // dataScheduleTemporary.schedule_in = schedule_in_str ?
            //   moment(schedule_in_str, 'YYYY-MM-DD') :
            //   moment();
            // dataScheduleTemporary.schedule_out = schedule_out_str ?
            //   moment(schedule_out_str, 'YYYY-MM-DD') :
            //   moment().add(response.data.data.duration, 'days');

            dataScheduleTemporary.schedule_in = dataScheduleTemporary.start_date
              ? moment(dataScheduleTemporary.start_date, "YYYY-MM-DD")
              : moment();

            if (
              response.data.data &&
              response.data.data.interval === "Monthly"
            ) {
              for (
                let index = 0;
                index < response.data.data.duration;
                index++
              ) {
                masterMonthly.push(parseInt(index + 1));
              }
            } else if (
              response.data.data &&
              response.data.data.interval === "Custom"
            ) {
              // let startingMoment = dataScheduleTemporary.schedule_in.clone();;
              // while(startingMoment <= dataScheduleTemporary.schedule_out) {
              //   masterCustom.push({
              //     id: startingMoment.clone().format("YYYY-MM-DD"),
              //     name: startingMoment.clone().format("DD-MM-YYYY")
              //   });
              //   startingMoment.add(1, 'days');
              // }
              let totalDuration = response.data.data.duration;
              for (let index = 1; index <= totalDuration; index++) {
                masterCustom.push({
                  id: index,
                  name: "Hari ke-" + index,
                });
              }
            }
          }
          setMasterMonthlyCollections(masterMonthly);
          setMasterCustomCollections(masterCustom);
          setDataSchedule({ ...dataScheduleTemporary });
          // console.log("Response Original Test: ", response.data);
        })
        .catch(function (error) {
          // console.log("Response Original error: ", error);
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Error : ", error.response);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      dataScheduleTemporary.work_pattern = {};
      setDataSchedule({ ...dataScheduleTemporary });
      setLoader(false);
      context.setModalProgress(false);
    }
  };

  const handleOnChangeTimePatternWeeklyOrMonthly = (event) => {
    let dataScheduleTemporary = dataSchedule;
    let timePattern = dataScheduleTemporary.day_in_arr;
    if (event.target.checked) {
      if (timePattern.indexOf(parseInt(event.target.value)) === -1) {
        if (
          dataScheduleTemporary.day_in_arr.length >=
          dataSchedule.work_pattern.work_duration
        ) {
          context.setErrorStatus(400);
          context.setTextErrorInformation(
            "Maksimal waktu kerja adalah " +
              dataSchedule.work_pattern.work_duration +
              " hari"
          );
          context.setOpenDialogError(true);
        } else {
          timePattern.push(parseInt(event.target.value));
        }
      }
    } else {
      if (timePattern.indexOf(parseInt(event.target.value)) > -1) {
        const index = timePattern.indexOf(parseInt(event.target.value));
        if (index > -1) {
          timePattern.splice(index, 1);
        }
      }
    }
    dataScheduleTemporary.day_in_arr = timePattern;
    dataScheduleTemporary.day_in = timePattern.toString();
    setDataSchedule({ ...dataScheduleTemporary });
  };

  const handleOnChangeTimePatternCustom = (event) => {
    let dataScheduleTemporary = dataSchedule;
    let timePattern = dataScheduleTemporary.day_out_arr;
    if (event.target.checked) {
      if (timePattern.indexOf(event.target.value) === -1) {
        if (
          dataScheduleTemporary.day_out_arr.length >=
          dataSchedule.work_pattern.off_duration
        ) {
          context.setErrorStatus(400);
          context.setTextErrorInformation(
            "Maksimal waktu libur adalah " +
              dataSchedule.work_pattern.off_duration +
              " hari"
          );
          context.setOpenDialogError(true);
        } else {
          timePattern.push(event.target.value);
        }
      }
    } else {
      if (timePattern.indexOf(event.target.value) > -1) {
        const index = timePattern.indexOf(event.target.value);
        if (index > -1) {
          timePattern.splice(index, 1);
        }
      }
    }
    dataScheduleTemporary.day_out_arr = timePattern;
    dataScheduleTemporary.day_out = timePattern.toString();
    console.log(timePattern);
    console.log(timePattern.toString());
    console.log(event.target.value);
    setDataSchedule({ ...dataScheduleTemporary });
  };

  const handleOnScheduleIn = (date) => {
    let dataScheduleTemporary = dataSchedule;
    dataScheduleTemporary.schedule_in = date;
    dataScheduleTemporary.schedule_out = moment(date).add(
      dataSchedule.work_pattern.work_duration,
      "days"
    );
    setDataSchedule({ ...dataScheduleTemporary });
    handleSetMasterCustomCollections();
  };

  const handleOnScheduleOut = (date) => {
    let dataScheduleTemporary = dataSchedule;
    dataScheduleTemporary.schedule_in = moment(date).subtract(
      dataSchedule.work_pattern.work_duration,
      "days"
    );
    dataScheduleTemporary.schedule_out = date;
    setDataSchedule({ ...dataScheduleTemporary });
    handleSetMasterCustomCollections();
  };

  const handleSetMasterCustomCollections = () => {
    let dataScheduleTemporary = dataSchedule;
    // let startingMoment = dataScheduleTemporary.schedule_in.clone();
    dataScheduleTemporary.day_in = "";
    dataScheduleTemporary.day_in_arr = [];
    dataScheduleTemporary.day_out = "";
    dataScheduleTemporary.day_out_arr = [];
    let masterCustom = [];
    // while(startingMoment <= dataScheduleTemporary.schedule_out) {
    //   masterCustom.push({
    //     id: startingMoment.clone().format("YYYY-MM-DD"),
    //     name: startingMoment.clone().format("DD-MM-YYYY")
    //   });
    //   startingMoment.add(1, 'days');
    // }
    if (dataScheduleTemporary.work_pattern) {
      let totalDuration = dataScheduleTemporary.work_pattern.duration;
      for (let index = 1; index <= totalDuration; index++) {
        masterCustom.push({
          id: index,
          name: "Hari ke-" + index,
        });
      }
    }
    setMasterCustomCollections(masterCustom);
    setDataSchedule({ ...dataScheduleTemporary });
  };

  const handleSetDayInDayOut = () => {
    let dataScheduleTemp = dataSchedule;
    if (
      dataScheduleTemp.work_pattern &&
      dataScheduleTemp.work_pattern.interval === "Custom"
    ) {
      dataScheduleTemp.day_in_arr = [];
      masterCustomCollections.map(function (item) {
        if (dataScheduleTemp.day_out_arr.indexOf(item.id) === -1) {
          dataScheduleTemp.day_in_arr.push(item.id);
        }
      });
      dataScheduleTemp.day_in = dataScheduleTemp.day_in_arr.toString();
    } else if (
      dataScheduleTemp.work_pattern &&
      dataScheduleTemp.work_pattern.interval === "Monthly"
    ) {
      dataScheduleTemp.day_out_arr = [];
      masterMonthlyCollections.map(function (item, key) {
        if (dataScheduleTemp.day_in_arr.indexOf(key + 1) === -1) {
          dataScheduleTemp.day_out_arr.push(key + 1);
        }
      });
      dataScheduleTemp.day_out = dataScheduleTemp.day_out_arr.toString();
    } else if (
      dataScheduleTemp.work_pattern &&
      dataScheduleTemp.work_pattern.interval === "Weekly"
    ) {
      dataScheduleTemp.day_out_arr = [];
      masterWeeklyCollections.map(function (item, key) {
        if (dataScheduleTemp.day_in_arr.indexOf(key + 1) === -1) {
          dataScheduleTemp.day_out_arr.push(key + 1);
        }
      });
      dataScheduleTemp.day_out = dataScheduleTemp.day_out_arr.toString();
    }
    setDataSchedule({ ...dataScheduleTemp });
  };

  const generateWeekly = (item) => {
    let lable = [];
    item.day_in.split(",").map(function (indexDay) {
      lable.push(masterWeeklyCollections[parseInt(indexDay) - 1]);
    });

    return lable.toString(", ");
  };

  const generateCustom = (item) => {
    let lable = [];
    item.day_in.split(",").map(function (indexDay) {
      lable.push(" Hari ke-" + indexDay);
    });

    return lable.toString(", ");
  };

  return (
    <div>
      <Box>
        <Grid container spacing={3} style={{ marginBottom: "1em" }}>
          <Grid item md={6}>
            <Typography
              component={"span"}
              style={{ display: "inline-block", marginTop: "3px" }}
              className={classes.title333}
            >
              <b>Data Jadwal</b>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                handleShowModalCreate();
              }}
            >
              Tambah Data Jadwal
            </Button>
          </Grid>
        </Grid>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            classes={classes}
            numSelected={selected.length}
            dataScheduleList={dataScheduleList}
            setDataScheduleList={setDataScheduleList}
            getScheduleList={getScheduleList}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={dataScheduleList.list.length}
            />

            {dataScheduleList &&
              dataScheduleList.list &&
              dataScheduleList.list.length > 0 && (
                <TableBody>
                  {dataScheduleList.list.map((item, i) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                  inputProps={{ "aria-label": "select all desserts" }}
                                /> */}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {dataScheduleList.options.paging.page *
                              dataScheduleList.options.paging.limit +
                              i +
                              1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.shift ? item.shift.title : ""}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.shift &&
                              item.shift.workPattern &&
                              item.shift.workPattern.interval === "Weekly" &&
                              item.day_in &&
                              generateWeekly(item)}
                            {item.shift &&
                            item.shift.workPattern &&
                            item.shift.workPattern.interval === "Monthly"
                              ? item.day_in
                              : ""}
                            {item.shift &&
                            item.shift.workPattern &&
                            item.shift.workPattern.interval === "Custom"
                              ? generateCustom(item)
                              : ""}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.location ? item.location.location_name : ""}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDropdownOpen(e, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl && anchorIn === i)}
                            onClose={(e) => handleDropdownClose(e, i)}
                          >
                            <StyledMenuItem
                              onClick={(e) => handleShowModalEdit(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Edit</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={(e) => handleShowModalDelete(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Hapus</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

            {dataScheduleList &&
              dataScheduleList.list &&
              dataScheduleList.list.length <= 0 &&
              !isLoaderEmpty && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title333}
                          style={{
                            marginTop: 16,
                            marginBottom: 16,
                          }}
                        >
                          <b>Data jadwal tidak tersedia</b>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.paginationTimeManagement}
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  colSpan={7}
                  count={dataScheduleList.options.paging.count}
                  rowsPerPage={dataScheduleList.options.paging.limit}
                  page={dataScheduleList.options.paging.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  labelRowsPerPage="Baris setiap halaman"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataSchedule={masterDataSchedule}
        setMasterDataSchedule={setMasterDataSchedule}
        dataSchedule={dataSchedule}
        setDataSchedule={setDataSchedule}
        listError={context.listError}
        createSchedule={createSchedule}
        handleChangeShift={handleChangeShift}
        masterMonthlyCollections={masterMonthlyCollections}
        masterWeeklyCollections={masterWeeklyCollections}
        masterCustomCollections={masterCustomCollections}
        handleOnChangeTimePatternWeeklyOrMonthly={
          handleOnChangeTimePatternWeeklyOrMonthly
        }
        handleOnChangeTimePatternCustom={handleOnChangeTimePatternCustom}
        handleOnScheduleIn={handleOnScheduleIn}
        handleOnScheduleOut={handleOnScheduleOut}
        handleChangeWorkPattern={handleChangeWorkPattern}
      />
      <DialogEdit
        classes={classes}
        isModalEdit={context.isModalEdit}
        setModalEdit={context.setModalEdit}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataSchedule={masterDataSchedule}
        setMasterDataSchedule={setMasterDataSchedule}
        dataSchedule={dataSchedule}
        setDataSchedule={setDataSchedule}
        listError={context.listError}
        handleUpdate={handleUpdate}
        handleChangeShift={handleChangeShift}
        masterMonthlyCollections={masterMonthlyCollections}
        masterWeeklyCollections={masterWeeklyCollections}
        masterCustomCollections={masterCustomCollections}
        handleOnChangeTimePatternWeeklyOrMonthly={
          handleOnChangeTimePatternWeeklyOrMonthly
        }
        handleOnChangeTimePatternCustom={handleOnChangeTimePatternCustom}
        handleOnScheduleIn={handleOnScheduleIn}
        handleOnScheduleOut={handleOnScheduleOut}
        handleChangeWorkPattern={handleChangeWorkPattern}
      />
      <DialogDelete
        classes={classes}
        isModalDelete={context.isModalDelete}
        setModalDelete={context.setModalDelete}
        dataSchedule={dataSchedule}
        setDataSchedule={setDataSchedule}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ViewTabSchedule;
