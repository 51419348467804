import React from "react";
import styles from "../../style/task-list-table.module.css";
import numeral from "numeral";

const TaskListTable = ({
  task,
  onExpanderClick,
  rowHeight,
  rowWidth,
  expanderSymbol,
}) => {
  //   // console.log("TAZK", task);

  return (
    <div className={styles.taskListTableRow} style={{ height: rowHeight }}>
      <div
        className={styles.taskListCell}
        style={{
          minWidth: rowWidth,
          maxWidth: rowWidth,
        }}
        title={task.title}
      >
        <div className={styles.taskListNameWrapper}>
          <div
            className={
              expanderSymbol
                ? styles.taskListExpander
                : styles.taskListEmptyExpander
            }
            onClick={() => onExpanderClick(task)}
          >
            {expanderSymbol}
          </div>
          <div>{task.title}</div>
        </div>
      </div>
      <div
        className={styles.taskListCell}
        style={{
          minWidth: 70,
          maxWidth: 75,
          textAlign: "center",
        }}
      >
        &nbsp;{task.planned_duration}
      </div>
      <div
        className={styles.taskListCell}
        style={{
          minWidth: 70,
          maxWidth: 75,
          textAlign: "center",
        }}
      >
        &nbsp;{numeral(task.progress).format("0.00")}%
      </div>
    </div>
  );
};

export default TaskListTable;
