import { Box, Card, CardContent, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { URL_API } from '../../../constants/config-api';
import AxiosConfig from '../../../constants/config-axios';
import { styles } from './Style/StyleInbox';

const useStyles = makeStyles(styles);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && <Box style={{ padding: '0px 25px' }}>{children}</Box>}
		</div>
	);
}

function ViewInbox() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(0);
	const [data, setData] = useState([]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const fetchInbox = () => {
		setLoading(true);
		AxiosConfig.get(`${URL_API}/human-resource/inbox`)
			.then(res => {
				const result = res.data.data;
				console.log('RES inbox ', result);
				if (res.status === 200) {
					setData(result);
				}
			})
			.catch(error => console.log('Error', error))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchInbox();
	}, []);

	return (
		<div className={classes.root}>
			<Typography variant="h4" align="center" style={{ color: '#262261' }}>
				<b>Team</b>
			</Typography>
			<Box sx={{ width: '100%' }}>
				<Box>
					<Tabs
						style={{ textColor: '#AAB2BD', maxWidth: 500, margin: 'auto', marginBottom: 20 }}
						textColor="primary"
						indicatorColor="primary"
						value={value}
						onChange={handleChange}
						aria-label="team-tab"
						variant="fullWidth"
						TabIndicatorProps={{ style: { borderBottom: `3px solid #262261`, borderRadius: '20px' } }}
					>
						<Tab label="Notifikasi" />
						<Tab label="Requests" />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', margin: '30px 0px' }}>
						{data
							.filter(item => item.type === 'notification')
							.map(item => (
								<Card style={{ padding: '6px', color: '#221E5B', borderRadius: '10px' }}>
									<CardContent>
										<Grid container spacing={6} alignItems="center">
											<Grid item xs={1}>
												<img
													src={require('../../../assets/images/SVG/location.svg')}
													style={{ width: '40px' }}
													alt=""
												/>
											</Grid>
											<Grid item xs={7}>
												<Typography variant="h5">
													<b>{item.title}</b>
												</Typography>
											</Grid>
										</Grid>
										<Typography variant="h6" style={{ margin: '10px 0px' }}>
											<b>{item.desc}</b>
										</Typography>
										<Typography variant="subtitle1" style={{ color: '#656D78' }}>
											Pada : {item.date}
										</Typography>
										<Typography variant="subtitle1" style={{ color: '#656D78' }}>
											Jam kerja : {item.shift}
										</Typography>
										<Typography variant="subtitle1" style={{ color: '#656D78' }}>
											Lokasi kerja : {item.company_name}
										</Typography>
									</CardContent>
								</Card>
							))}
					</div>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', margin: '30px 0px' }}>
						{data
							.filter(item => item.type === 'request')
							.map(item => (
								<Card style={{ padding: '6px', color: '#221E5B', borderRadius: '10px' }}>
									<CardContent>
										<Grid container spacing={6} alignItems="center" style={{ marginBottom: '5px' }}>
											<Grid item xs={1}>
												<img
													src={
														item.title === 'Req.Overtime'
															? require('../../../assets/images/SVG/req-overtime.svg')
															: item.title === 'Req.Time-Off'
															? require('../../../assets/images/SVG/req-time-off.svg')
															: require('../../../assets/images/SVG/location.svg')
													}
													style={{ width: '40px' }}
													alt=""
												/>
											</Grid>
											<Grid item xs={7}>
												<Typography variant="h5">
													<b>{item.title}</b>
												</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="subtitle1" align="right">
													10.41
												</Typography>
											</Grid>
										</Grid>
										<Typography variant="subtitle2" style={{ color: '#656D78' }}>
											Pada : {item.date}
										</Typography>
										{item.time && (
											<Typography variant="subtitle2" style={{ color: '#656D78' }}>
												Pukul : {item.time}
											</Typography>
										)}
										{item.desc !== undefined && (
											<Typography variant="subtitle2" style={{ color: '#656D78' }}>
												Deskripsi : {item.time}
											</Typography>
										)}
										<Typography
											variant="subtitle2"
											style={{ color: item.status === 'approved' ? '#25225e' : '#d1354a' }}
										>
											<b>Status : {item.status}</b>
										</Typography>
									</CardContent>
								</Card>
							))}
					</div>
				</TabPanel>
			</Box>
		</div>
	);
}

export default ViewInbox;
