import React, { useContext, useEffect, useState, useRef } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { styles } from "../Style/StyleAttandanceOvertime";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Input,
  Grid,
  InputAdornment,
  TextField,
  Card,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AlarmAddRounded } from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { id } from "date-fns/locale";
import { DurationPicker } from "material-duration-picker";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

import ContextAttendanceOvertime from "../Context/ContextAttendanceOvertime";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CE354A", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const OvertimeRequestDialog = ({
  handleClose,
  open,
  verification,
  value,
  setValue,
}) => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceOvertime);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          AJUKAN OVERTIME
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <InputLabel htmlFor="overtime-type">Jenis Overtime</InputLabel>
            <Select
              id="overtime-type"
              value={value.overtimeType}
              onChange={(e) => {
                setValue({ ...value, overtimeType: e.target.value });
              }}
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <AlarmAddRounded />
                </InputAdornment>
              }
            >
              <MenuItem value={"after_shift"}>
                After Shift (Setelah Jadwal Jam Kerja)
              </MenuItem>
              <MenuItem value={"before_shift"}>
                Before Shift (Sebelum Jadwal Jam Kerja)
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={id}>
              <KeyboardDatePicker
                margin="normal"
                id="date"
                label="Kapan"
                format="d, MMMM, yyyy"
                value={value.overtimeWorkday}
                onChange={(v) => {
                  setValue({ ...value, overtimeWorkday: v });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <DurationPicker
              label="Durasi Overtime"
              value={value.overtimeDuration}
              onValueChange={(v) => {
                setValue({ ...value, overtimeDuration: v });
              }}
              formatDuration={(duration) =>
                formatDuration(duration, {
                  locale: id,
                })
              }
              DurationDialogProps={{
                labels: {
                  cancel: "Batal",
                  ok: "Ok",
                  weeks: "Minggu",
                  days: "Hari",
                  hours: "Jam",
                  minutes: "Menit",
                  seconds: "Detik",
                },
                DurationFieldsContainerProps: {
                  labels: {
                    cancel: "Batal",
                    ok: "Ok",
                    weeks: "Minggu",
                    days: "Hari",
                    hours: "Jam",
                    minutes: "Menit",
                    seconds: "Detik",
                  },
                },
              }}
            />
          </FormControl>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <DurationPicker
              label="Durasi Break"
              value={value.overtimeRestDuration}
              onValueChange={(v) => {
                setValue({ ...value, overtimeRestDuration: v });
              }}
              formatDuration={(duration) =>
                formatDuration(duration, {
                  locale: id,
                })
              }
              DurationDialogProps={{
                labels: {
                  cancel: "Batal",
                  ok: "Ok",
                  weeks: "Minggu",
                  days: "Hari",
                  hours: "Jam",
                  minutes: "Menit",
                  seconds: "Detik",
                },
                DurationFieldsContainerProps: {
                  labels: {
                    cancel: "Batal",
                    ok: "Ok",
                    weeks: "Minggu",
                    days: "Hari",
                    hours: "Jam",
                    minutes: "Menit",
                    seconds: "Detik",
                  },
                },
              }}
            />
          </FormControl>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <TextField
              fullWidth
              label="Deskripsi"
              placeholder="Masukkan deskripsi (Opsional)"
              onChange={(e) => {
                setValue({ ...value, overtimeDescription: e.target.value });
              }}
              value={value.overtimeDescription}
            />
          </FormControl>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "1rem",
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={
                  () => {
                    handleClose();
                  } /*handleCancel()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={
                  () => {
                    verification();
                  } /*handleSubmit()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Ajukan
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default OvertimeRequestDialog;
