import React, { useCallback, useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import axios from "axios";
import moment from "moment";

import Snackber from "../../Components/Snackber";
import DialogError from "../../../../components/DialogError";

import ConvertDataUrlToFile from "../../../../utilities/ConvertDataUrlToFile";
import { URL_API } from "../../../../constants/config-api";
import Redirect from "../../../../utilities/Redirect";
import Capitalize from "../../../../utilities/Capitalize";

import {
  ToHrEmployeeRiwayatUrgentContact,
  ToHrEmployeeProfileDetail,
  ToHrEmployeeProfileDetailSeeDetail,
  ToHrEmployeeIdentitas,
  ToHrEmployeeIdentitasAlamat,
  ToHrEmployeeIdentitasInfoTambahan,
} from "../../../../constants/config-redirect-url";

const ButtonSimpanNOW = (props) => {
  const {
    classes,
    isOpenDialogSimpanSekarang,
    setOpenDialogSimpanSekarang,
    dataForSimpanNow,
  } = props;

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ``````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    if (isOpenDialogSimpanSekarang == true) {
      // console.log("dataForSimpanNow : ", dataForSimpanNow);

      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      setShowButtonSimpanNow(true);
    }
  }, [isOpenDialogSimpanSekarang]);

  const handleSimpanDataToDB = () => {
    // console.log("dataForSimpanNow : ", dataForSimpanNow);

    let fotoKaryawanFile =
      dataForSimpanNow.foto !== ""
        ? ConvertDataUrlToFile(
            dataForSimpanNow.foto,
            dataForSimpanNow.foto_name
          )
        : "";
    const fileFoto = new Blob([fotoKaryawanFile]); //*Work

    let formData = new FormData();

    formData.append("Employee[employee_number]", dataForSimpanNow.nip);
    formData.append("Employee[first_name]", dataForSimpanNow.nama_depan);
    formData.append("Employee[last_name]", dataForSimpanNow.nama_belakang);
    formData.append("Employee[nickname]", dataForSimpanNow.nama_panggilan);
    formData.append("Employee[mobile_number_personal]", dataForSimpanNow.no_hp);
    formData.append("Employee[email]", dataForSimpanNow.email);
    formData.append("Employee[place_of_birth]", dataForSimpanNow.tempat_lahir);
    formData.append(
      "Employee[date_of_birth]",
      moment(dataForSimpanNow.tanggal_lahir).format("YYYY-MM-DD")
    );
    formData.append(
      "Employee[religion_id]",
      "007fba3e-35ce-485f-bc22-feda9125c813"
    );
    formData.append(
      "Employee[sex_id]",
      dataForSimpanNow.jenis_kelamin == "lakiLaki" ? 1 : 2
    );

    // formData.append('Employee[identity_number]',  identitas.nip); // HARUS-NYA KTP

    formData.append("Employee[photo_url]", fileFoto);

    if (dataForSimpanNow.foto == "") {
      formData.delete("Employee[photo_url]");
    }

    // console.log("Form Data : ", formData);

    if (userTokenState !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + "/human-resource/employee/batch", formData)
        .then(function (response) {
          setShowButtonSimpanNow(false);

          // console.log("Response Original : ", response);

          if (response.status == 200) {
            /*
                            ````````````````````````````````
                            SET ID EMPLOYEE IN LOCAL STORAGE

                            ````````````````````````````````
                        */
            localStorage.setItem("employee_id", response.data.data.id);
            localStorage.setItem(
              "response_employee_detail",
              JSON.stringify(response.data.data)
            );

            /*
                            ``````````````````````````````````````````````````````````````````
                            REMOVE ALL DATA IN LOCAL STORAGE AFTER  BERHASIL SIMPAN DATA TO DB

                            ``````````````````````````````````````````````````````````````````
                        */
            localStorage.removeItem("data_identitas");
            localStorage.removeItem("data_identitas_alamat");
            localStorage.removeItem("data_identitas_info_tambahan");
            localStorage.removeItem("employee_data_info_pegawai");
            localStorage.removeItem("employee_data_keahlian");
            localStorage.removeItem("employee_data_keluarga");
            localStorage.removeItem("employee_data_kontak_darurat");
            localStorage.removeItem("employee_data_riwayat_experience");
            localStorage.removeItem("employee_data_riwayat_formal");
            localStorage.removeItem("employee_data_riwayat_informal");
            localStorage.removeItem("employee_data_riwayat_org");
            localStorage.removeItem("employee_data_info_position");
            localStorage.removeItem("employee_data_riwayat_kesehatan");

            /*
                            ```````````````````````````````````
                            REDIRECT TO EMPLOYEE PROFILE DETAIL

                            ```````````````````````````````````
                        */
            Redirect(ToHrEmployeeProfileDetailSeeDetail);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);

          setShowButtonSimpanNow(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);
              if (error.response.data.info !== null) {
                if (error.response.data.info !== null) {
                  if (error.response.data.info.errors !== null) {
                    if (error.response.data.info.errors.Employee !== null) {
                      if (
                        error.response.data.info.errors.Employee.hasOwnProperty(
                          "email"
                        ) == true
                      ) {
                        // // console.log("Yes, catch it : ", error.response.data.info.errors.Employee.email)
                        setTextErrorInformationValue(
                          error.response.data.info.errors.Employee.email
                        );
                        setOpenDialogError(true);
                      }

                      if (
                        error.response.data.info.errors.Employee.hasOwnProperty(
                          "date_of_birth"
                        ) == true
                      ) {
                        setTextErrorInformationValue(
                          error.response.data.info.errors.Employee.date_of_birth
                        );
                      }
                    }
                  }
                }
              }
            }
          } else {
            setTextErrorInformationValue("Whoops, something went wrong!");
            setOpenDialogError(true);
          }
        });
    } else {
      alert("No Access Token available!");
    }
  };

  /*
        ````````````````
        HANDLE SNACK-BAR

        ````````````````
    */

  const [isFormFilledAll, setFormFilledAll] = useState(false);
  const [isShowButtonSimpanNow, setShowButtonSimpanNow] = useState(true);

  return (
    <Fragment>
      {isShowButtonSimpanNow == true && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          style={{ margin: 14 }}
          disabled={false}
          onClick={handleSimpanDataToDB}
        >
          Ya, simpan sekarang
        </Button>
      )}

      {/*  
                `````````````````````
                VALIDATION SNACK BAR

                `````````````````````
            */}

      <Snackber
        isFormFilledAll={isFormFilledAll}
        setFormFilledAll={setFormFilledAll}
      />

      {/* 
                ````````````
                DIALOG ERROR

                ````````````
            */}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={Capitalize(textErrorInformationValue)}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default ButtonSimpanNOW;
