import React, { useContext } from "react";
import { Grid, Card, CardHeader, Avatar, Typography } from "@material-ui/core";
import RestoreRoundedIcon from "@material-ui/icons/RestoreRounded";

import { navigate } from "hookrouter";

import ContextGlobalDrive from "../../context/ContextDrive";

const FolderRecovered = ({ classes }) => {
  const context = useContext(ContextGlobalDrive);
  const folderRecovered = { name: "Recovered Files & Folder", id: "recovered" };

  const handleNavigateRecovered = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    breadcrumb.push(data.name);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  return (
    <Grid item md={3}>
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : 7,
          backgroundColor: "#eafaf1",
        }}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#7bed9f" }}>
              <RestoreRoundedIcon style={{ color: "#209550" }} />
            </Avatar>
          }
          title={
            <Typography
              className={classes.folderNameText}
              variant="subtitle2"
              onClick={() => handleNavigateRecovered(folderRecovered)}>
              Recovered File(s) & Folder(s)
            </Typography>
          }
        />
      </Card>
    </Grid>
  );
};

export default FolderRecovered;
