import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { useTable, useExpanded } from "react-table";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { styles, theme } from "../../../Style/StyleTable";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ContextAttendanceReportAdmin from "../../../Context/ContextAttendanceReportAdmin";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";

import "../../../Style/Table.css";
import TotalRecords from "./TotalRecords";
import Pagination from "./Pagination";
import TableMainHead from "./TableMainHead";

const useStyles = makeStyles(styles);

const TableMain = () => {
  const context = useContext(ContextAttendanceReportAdmin);
  const classes = useStyles();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showHeaderFixed, setShowHeaderFixed] = useState(0);
  const [headerFixedWidth, setHeaderFixedWidth] = useState(0);
  const [headerFixedLeft, setHeaderFixedLeft] = useState(0);
  const [headerTableFixedWidth, setHeaderTableFixedWidth] = useState(0);
  const handleScrollDetail = () => {
      const position = window.pageYOffset;
      const positionX = window.pageXOffset;
      let headerPosition = document.getElementById("table-main-head-detail");
      let headerClonePosition = document.getElementById("table-main-head-detail-clone");
      if (position + 70 > headerPosition.offsetTop) {
        setShowHeaderFixed(1);
      } else {
        setShowHeaderFixed(0);
      }

      if (positionX > 0) {
        if (positionX >= headerPosition.offsetLeft) {
          headerClonePosition.scrollLeft = positionX - headerPosition.offsetLeft;
          setHeaderFixedLeft(0);
          setHeaderFixedWidth(window.innerWidth);
        } else {
          headerClonePosition.scrollLeft = 0;
          setHeaderFixedLeft(headerPosition.offsetLeft - positionX);
          setHeaderFixedWidth(window.innerWidth + headerPosition.offsetLeft - positionX);
        }
      } else {
        setHeaderFixedLeft(headerPosition.offsetLeft);
        headerClonePosition.scrollLeft = 0;
        setHeaderFixedWidth(window.innerWidth - headerPosition.offsetLeft);
      }
      setHeaderTableFixedWidth(headerPosition.offsetWidth);
      setScrollPosition(position);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScrollDetail, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScrollDetail);
      };
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <TableContainer className={classes.shadowTable}>
        <div id="table-main-head-detail-clone" style={{ 
          display: showHeaderFixed ? "block" : "none", position: "fixed", top: "65px", 
          width: headerFixedWidth + "px", backgroundColor: "rgb(249, 249, 249)",
          left: headerFixedLeft + "px", overflowX: 'hidden'
        }}>
          <Table size="small" style={{width: headerTableFixedWidth + "px"}}>
            <TableMainHead classes={classes} />
          </Table>
        </div>
        <div id="table-main-head-detail"  style={{
          visibility: showHeaderFixed ? "hidden" : "unset"
        }}>
          <Table size="small">
            <TableMainHead classes={classes} />
          </Table>
        </div>
        <Table size="small">
          <TableBody
            style={{ backgroundColor: "#fff" }}
          >
            {context.dataReportDetailList.list.length > 0 && 
              context.dataReportDetailList.list.map((item, i) => (
                <TableRow key={i}>
                  <TableCell
                    className={classes.titleHead}
                    style={{
                      width: "2%"
                    }}
                  ></TableCell>
                  <TableCell
                    className={classes.titleHead}
                    style={{
                      width: "13%"
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <AccountCircleRoundedIcon style={{ color: "#c4c5c4", fontSize: "40px" }} />
                      <Box ml={2}>
                        <Tooltip TransitionComponent={Zoom} title={item.name ? item.name : '-'}>
                          <Typography
                            variant="subtitle2"
                            style={{
                              display: "inline-block",
                              margin: "5px 0",
                              fontWeight: "normal",
                              color: "#333",
                              whiteSpace: "nowrap",
                              width: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.unit_name ? item.unit_name : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.unit_name}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                    style={{
                      width: "8%"
                    }}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.date ? item.date : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.date}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.clock_in_time ? item.clock_in_time : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.clock_in_time}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.clock_in_status ? item.clock_in_status : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.clock_in_status}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.clock_in_remarks ? item.clock_in_remarks : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.clock_in_remarks}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.clock_out_time ? item.clock_out_time : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.clock_out_time}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.clock_out_status ? item.clock_out_status : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.clock_out_status}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.clock_out_remarks ? item.clock_out_remarks : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.clock_out_remarks}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.duration ? item.duration : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.duration}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                  >
                    <Tooltip TransitionComponent={Zoom} title={item.status ? item.status : "-"}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline-block",
                          margin: "5px 0",
                          fontWeight: "normal",
                          color: "#333",
                        }}
                      >
                        {item.status}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.titleHead}
                    style={{
                      width: "2%"
                    }}
                  ></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* LOADIER */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        {context.loader && (
          <>
            <CircularProgress
              size={16}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography variant="subtitle2" className={classes.title888}>
              Loading...
            </Typography>
          </>
        )}

        {context.dataReportDetailList.list.length === 0 && !context.loader && (
          <Typography variant="subtitle2" className={classes.title888}>
            Data not found
          </Typography>
        )}
      </Box>

      {/* PAGINATION */}
      <Box display="flex" justifyContent="space-between" mt={1} pb={2}>
        <TotalRecords classes={classes} />
        <Pagination classes={classes} />
      </Box>
    </ThemeProvider>
  );
};
  
export default TableMain;