export const SUBJECT_CATEGORY_ID_MEMBER_USER =
  "8ee54618-f7a3-4609-9c63-853521eafef6";

export const SUBJECT_CATEGORY_ID_MEMBER_UNIT =
  "0c5cc0f9-6a9d-401d-b643-a0d83202b0f8";

export const EVIDENCE_CATEGORY_ATTCHMENT =
  "c9441e2a-972d-47da-a07c-a1eb5215de05";
export const EVIDENCE_CATEGORY_DRIVE = "4700760d-891a-4eee-87bb-bdd8cb28198d";
export const EVIDENCE_CATEGORY_LINK = "1afdcfec-4503-4044-9f62-8e2566f24cd5";

export const MARE_CATEGORY_ATTCHMENT = "f436ea12-c1ec-4f1f-a0d0-c8e1ad9763ab";
export const MARE_CATEGORY_DRIVE = "095c1444-f46d-4ba1-8a5c-0d7c6cc340bd";
export const MARE_CATEGORY_LINK = "1b8365f7-4424-4dbb-8ee5-d9ccf8e8f593";
