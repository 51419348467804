import React, { useContext } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../../../../style/styleGrouping";

import PresetFilter from "./preset/PresetFilter";
import EntitiesFilter from "./entities/EntitiesFilter";
import UserFilter from "./user/UserFilter";
import StatusFilter from "./status/StatusFilter";
import AchievementFilter from "./achievement/AchievementFilter";
import UnitFilter from "./unit/UnitFilter";
// import GroupBy from "./group-by/GroupBy";
import OrderBy from "./order-by/OrderBy";
import ContextReports from "../../../../context/ContextReports";

const useStyles = makeStyles(styles);

const Grouping = () => {
  const classes = useStyles();
  const { filterState } = useContext(ContextReports);

  // // console.log("filterState", filterState);

  return (
    <Box display="flex">
      <Box mr={1.5}>
        <PresetFilter classes={classes} />
      </Box>
      <Box mr={1.5}>
        <EntitiesFilter classes={classes} />
      </Box>
      <Box mr={1.5}>
        <UnitFilter classes={classes} />
      </Box>
      <Box mr={1.5}>
        <UserFilter classes={classes} />
      </Box>
      <Box mr={1.5}>
        <StatusFilter classes={classes} />
      </Box>
      <Box mr={1.5}>
        <AchievementFilter classes={classes} />
      </Box>

      <Box ml={5}>
        {/* <GroupBy classes={classes} /> */}
        <OrderBy classes={classes} />
      </Box>

      {filterState.loadFilter && (
        <Box ml={5} display="flex" alignItems="center">
          <CircularProgress
            size={16}
            style={{ color: "#d64253", marginRight: 5 }}
          />
          <Typography variant="subtitle2" className={classes.title888}>
            Initial data loading...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Grouping;
