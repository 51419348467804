import React, { useContext, useMemo, useEffect, useState } from "react";
import {
  Table as Tables,
  TableBody,
  TableContainer,
  Box,
  Typography,
  LinearProgress,
} from "@material-ui/core";

import ContextTask from "../../Context/ContextTask";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import TableHeaderTDL from "./TableHeaderTDL";
import Pagination from "./Pagination";
import ListItemTable from "./ListItemTable";

// =========================================================
// ~ Table Main Component ~
// =========================================================
const Table = ({ classes }) => {
  const { todoState } = useContext(ContextTask);
  const data = useMemo(() => todoState.todoData, [todoState.todoData]);

  const [ownerCollections, setOwnerCollections] = useState([]);

  useEffect(() => {
    AxiosConfig.get(`${URL_API}/todolist/create`)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setOwnerCollections(result.ownerCollections);
        }
      })
      .catch((error) => {
        // console.log("Error", error);
      });
  }, []);

  return (
    <div>
      <TableContainer className={classes.shadowTable}>
        {todoState.loadTodos && <LinearProgress />}
        <Tables size="small" aria-label="a dense table">
          <TableHeaderTDL classes={classes} />
          <TableBody style={{ backgroundColor: "#fff" }}>
            {data.map((row, i) => {
              return (
                <ListItemTable
                  key={i}
                  classes={classes}
                  row={row}
                  ownerCollections={ownerCollections}
                />
              );
            })}
          </TableBody>
        </Tables>
      </TableContainer>

      <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
        {todoState.todoData.length === 0 && !todoState.loadTodos && (
          <Typography variant="subtitle2" className={classes.title888}>
            Data not found
          </Typography>
        )}
      </Box>

      <Pagination classes={classes} />
    </div>
  );
};

export default Table;
