import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  FormControlLabel,
  Typography,
  ClickAwayListener,
  Radio,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import classNames from "classnames";
import clsx from "clsx";

import ContextProject from "../../context/ContextProject";

import { SORT_NAME_PROJECT } from "../../context/types";

const AnchorSortProject = ({ classes }) => {
  const { projectTDispatch, projectTState, setTriggerProject } =
    useContext(ContextProject);

  const [anchorEl, setAnchorEl] = useState(null);

  const [sortName, setSortName] = useState({
    code: projectTState.sortNameproject,
    name: "",
  });

  const [openName, setOpenName] = useState(false);

  const ExpandTrue = () => (
    <ExpandMore style={{ color: "#8F8F8F" }} fontSize="small" />
  );
  const ExpandFalse = ({ color }) => (
    <ExpandLess style={{ color }} fontSize="small" />
  );

  const handleName = () => setOpenName((s) => !s);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleResetFilter = () => {
    projectTDispatch({
      type: SORT_NAME_PROJECT,
      payload: {
        sortNameproject: "asc",
      },
    });

    setTriggerProject(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleChangeName = (data) => {
    setSortName(data);

    projectTDispatch({
      type: SORT_NAME_PROJECT,
      payload: {
        sortNameproject: data?.code,
      },
    });

    setTriggerProject(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleSortProject = () => {};

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        className={classes.btnIcon}
        endIcon={
          <KeyboardArrowDownIcon fontSize="large" style={{ color: "#333" }} />
        }
        startIcon={
          <SortRoundedIcon fontSize="large" style={{ color: "#333" }} />
        }
        onClick={handleShow}
      >
        <Typography variant="subtitle1" className={classes.title333}>
          Sort
        </Typography>
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <div>
                  <Box minWidth={250} maxWidth={330}>
                    <Box m={2} display="flex" justifyContent="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.title888}
                      >
                        <b>Sort</b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtLink}
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Typography>
                    </Box>

                    <List dense>
                      <ListItem
                        button
                        onClick={handleName}
                        style={{
                          backgroundColor:
                            openName && "rgba(251, 234, 234, 0.5)",
                          color: openName && "#C8203C",
                        }}
                      >
                        <ListItemText primary="Project Name" />
                        {openName ? (
                          <ExpandFalse color="#C8203C" />
                        ) : (
                          <ExpandTrue />
                        )}
                      </ListItem>
                      <Collapse in={openName} timeout="auto" unmountOnExit>
                        <Box pt={1} pb={2} px={3} display="flex">
                          {listNameSort.map((item, i) => {
                            return (
                              <div key={i}>
                                <FormControlLabel
                                  style={{ margin: 0, padding: 0 }}
                                  label={
                                    <Box display="flex" alignItems="center">
                                      <p
                                        style={{
                                          color: "#555",
                                          fontSize: 14,
                                          marginLeft: 3,
                                        }}
                                        className={classes.title}
                                      >
                                        {item.name}
                                      </p>
                                    </Box>
                                  }
                                  control={
                                    <Radio
                                      className={classes.rootRadio}
                                      disableRipple
                                      color="default"
                                      checked={item.code === sortName.code}
                                      onChange={() => handleChangeName(item)}
                                      checkedIcon={
                                        <span
                                          className={clsx(
                                            classes.iconRadio,
                                            classes.checkedIconRadio
                                          )}
                                        />
                                      }
                                      icon={
                                        <span className={classes.iconRadio} />
                                      }
                                    />
                                  }
                                />
                              </div>
                            );
                          })}
                        </Box>
                      </Collapse>
                    </List>
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const listNameSort = [
  {
    name: "Ascending",
    code: "asc",
  },
  {
    name: "Descending",
    code: "desc",
  },
];

export default AnchorSortProject;
