import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

import moment from "moment";
import { navigate } from "hookrouter";

const styles = () => ({
  title333: {
    color: "#333",
  },
  title888: {
    color: "#888",
  },
  btnGrey: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    color: "#333",
    textTransform: "capitalize",
    padding: "2px 12px",
    backgroundColor: "#DFDFDF",
    "&:hover": {
      backgroundColor: "#B1B1B1",
    },
  },
});

const useStyle = makeStyles(styles);

const DialogInfoSplitWeight = ({
  openInfoSplitWeight,
  setOpenInfoSplitWeight,
  onOpenDialog,
  onTrigger,
  origin,
}) => {
  const classes = useStyle();

  const handleContinue = () => {
    onOpenDialog(false);
    setOpenInfoSplitWeight(false);
    onTrigger(Math.floor(Math.random() * 1000 + 1));

    if (origin === "detail") {
      navigate("/goal");
    }
    if (origin === "deletema") {
      navigate("/all-ma");
    }
  };

  return (
    <Dialog
      open={openInfoSplitWeight}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Set weight otomatis</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 400, textAlign: "center" }}>
        <Typography variant="subtitle2" className={classes.title888}>
          Bobot goal telah didistribusikan secara otomatis oleh sistem
        </Typography>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          fullWidth
          className={classes.btnGrey}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogInfoSplitWeight;
