import React, { useContext, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  Divider,
  IconButton,
  Collapse,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";

import ContextNewSO from "../Context/ContextNewSO";
import UnitSettings from "./drawer-components/UnitSettings";
import PositionSettings from "./drawer-components/PositionSettings";
import UserSettings from "./drawer-components/UserSettings";

// import

const DrawerSettings = ({ classes }) => {
  const context = useContext(ContextNewSO);

  const [openUnit, setOpenUnit] = useState(true);
  const [openPosition, setOpenPosition] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const handleCloseDrawer = () => {
    context.setOpenDrawer(false);
  };

  const handleUnit = () => setOpenUnit((s) => !s);
  const handlePosition = () => setOpenPosition((s) => !s);
  const handleUser = () => setOpenUser((s) => !s);

  return (
    <Drawer
      anchor="right"
      open={context.openDrawer}
      onClose={handleCloseDrawer}
    >
      <Box minWidth={350}>
        <Box p={2}>
          <Typography variant="h6" className={classes.title555}>
            <b>Structure Settings</b>
          </Typography>
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
        >
          <Typography variant="subtitle1" className={classes.title555}>
            Unit
          </Typography>

          <IconButton size="small" onClick={handleUnit}>
            {openUnit ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </IconButton>
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        {/* UNIT SSETTIGNS */}
        <Collapse in={openUnit}>
          <UnitSettings classes={classes} />
        </Collapse>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
        >
          <Typography variant="subtitle1" className={classes.title555}>
            Jabatan
          </Typography>

          <IconButton size="small" onClick={handlePosition}>
            {openPosition ? (
              <ExpandLessRoundedIcon />
            ) : (
              <ExpandMoreRoundedIcon />
            )}
          </IconButton>
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        {/* POSITION SSETTIGNS */}
        <Collapse in={openPosition}>
          <PositionSettings classes={classes} />
        </Collapse>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
        >
          <Typography variant="subtitle1" className={classes.title555}>
            User
          </Typography>

          <IconButton size="small" onClick={handleUser}>
            {openUser ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </IconButton>
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        {/* USER SSETTIGNS */}
        <Collapse in={openUser}>
          <UserSettings classes={classes} />
        </Collapse>
      </Box>
    </Drawer>
  );
};

export default DrawerSettings;
