import React from "react";
import { withStyles, Tab, Tabs } from "@material-ui/core";

export const AntTabs = withStyles({
  root: {
    // borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "transparent",
    borderBottom: "0px",
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ["Roboto", "Roboto", "BlinkMacSystemFont", '"Segoe UI"'].join(
      ","
    ),
    "&:hover": {
      color: "#d1354a",
      opacity: 1,
    },
    "&$selected": {
      color: "#d1354a",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#d1354a",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
