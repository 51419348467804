import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AvatarIconWithoutLineInOrgChart from "./Components/AvatarIconWithoutLineInOrgChart";
import AvatarIconInOrgChart from "./Components/AvatarIconInOrgChart";

import StyledTreeItem from "./Components/StyledTreeItem";
import StyledTreeItemDetail from "./Components/StyledTreeItemDetail";

import DialogTambahHead from "./Components/DialogTambahHead";
import DialogTambahDeputyHead from "./Components/DialogTambahDeputyHead";
import DialogTambahAssistant from "./Components/DialogTambahAssistant";
import DialogTambahStaff from "./Components/DialogTambahStaff";

import DialogEditJenisUnit from "./ComponentsDialogDetail/DialogEditJenisUnit";
import DialogEditNamaUnit from "./ComponentsDialogDetail/DialogEditNamaUnit";

import DialogEditPositionTitle from "./ComponentsDialogPositionTitleEDIT/DialogEditPositionTitle";
import DialogEditPositionTitleInTambahASSISTANT from "./ComponentsDialogPositionTitleEDIT/DialogEditPositionTitleInTambahASSISTANT";

import DialogEditEmployeeMember from "./ComponentsDialogEmployeeEDIT/DialogEditEmployeeMember";
import DialogEditEmployeeMemberInEditRootTOP from "./ComponentsDialogEmployeeEDIT/DialogEditEmployeeMemberInEditRootTOP";
import DialogEditEmployeeMemberInEditHEAD from "./ComponentsDialogEmployeeEDIT/DialogEditEmployeeMemberInEditHEAD";
import DialogEditEmployeeMemberInEditDeputyHEAD from "./ComponentsDialogEmployeeEDIT/DialogEditEmployeeMemberInEditDeputyHEAD";
import DialogEditEmployeeMemberInEditASSISTANT from "./ComponentsDialogEmployeeEDIT/DialogEditEmployeeMemberInEditASSISTANT";
import DialogEditEmployeeMemberInEditSTAFF from "./ComponentsDialogEmployeeEDIT/DialogEditEmployeeMemberInEditSTAFF";

import DialogCreateJenisUnit from "./ComponentsDialogDetail/DialogCreateJenisUnit";
import DialogCreateNamaUnit from "./ComponentsDialogDetail/DialogCreateNamaUnit";

import DialogCreatePositionTitle from "./ComponentsDialogPositionTitleCREATE/DialogCreatePositionTitle";
import DialogCreatePositionTitleInDeputyHEAD from "./ComponentsDialogPositionTitleCREATE/DialogCreatePositionTitleInDeputyHEAD";
import DialogCreatePositionTitleInASSISTANT from "./ComponentsDialogPositionTitleCREATE/DialogCreatePositionTitleInASSISTANT";
import DialogCreatePositionTitleInSTAFF from "./ComponentsDialogPositionTitleCREATE/DialogCreatePositionTitleInSTAFF";

import DialogCreatePositionTitleInEditNodeSoHEAD from "./ComponentsDialogPositionTitleCREATE/DialogCreatePositionTitleInEditNodeSoHEAD";
import DialogCreatePositionTitleInEditRootTop from "./ComponentsDialogPositionTitleCREATE/DialogCreatePositionTitleInEditRootTop";

import DialogCreateEmployeeInHEAD from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeInHEAD";
import DialogCreateEmployeeInDeputyHEAD from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeInDeputyHEAD";
import DialogCreateEmployeeInASSISTANT from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeInASSISTANT";

import DialogCreateEmployeeEditRootTOP from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeEditRootTOP";
import DialogCreateEmployeeInEditHEAD from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeInEditHEAD";
import DialogCreateEmployeeInEditDeputyHEAD from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeInEditDeputyHEAD";
import DialogCreateEmployeeInEditASSISTANT from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeInEditASSISTANT";
import DialogCreateEmployeeInEditSTAFF from "./ComponentsDialogEmployeeCREATE/DialogCreateEmployeeInEditSTAFF";

import DialogCreateJenisUnitInHEAD from "./ComponentsDialogDetail/DialogCreateJenisUnitInHEAD";
import DialogCreateNamaUnitInHEAD from "./ComponentsDialogDetail/DialogCreateNamaUnitInHEAD";

import DialogDeleteNodeSO from "./ComponentsDialogDetail/DialogDeleteNodeSO";

import DialogEditNodeSoClassificationTopLeader from "./ComponentsDialogDetail/DialogEditNodeSoClassificationTopLeader";
import DialogEditNodeSoHEAD from "./ComponentsDialogDetail/DialogEditNodeSoHEAD";
import DialogEditNodeSoDeputyHEAD from "./ComponentsDialogDetail/DialogEditNodeSoDeputyHEAD";
import DialogEditNodeSoDeputyASSISTANT from "./ComponentsDialogDetail/DialogEditNodeSoDeputyASSISTANT";

import DialogEditNodeSoSTAFF from "./ComponentsDialogDetail/DialogEditNodeSoSTAFF";

import ContextNewSO from "./Context/ContextNewSO";
import { styles } from "./Style/StyleNewSO";

const useStyles = makeStyles(styles);

const DataTreeView = (props) => {
  /**
   *
   * ```````````````````````````````````````````````
   *
   *  context : {
   *
   *      isOpenDialogCreatePositionTitle : Boolean
   *
   *  };
   *
   * ```````````````````````````````````````````````
   *
   */

  const context = useContext(ContextNewSO);

  const classes = useStyles();
  // const theme = useTheme();

  const { treeItems } = props;

  const [mainRoot, setMainRoot] = useState("1");
  const [firstRootId, setFirstRootId] = useState("");

  const [userTokenState, setUserTokenState] = useState("");

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    setUserTokenState(userToken);

    // setExpanded(treeItems[0].id)

    // // console.log("treeItems : ", treeItems)
  }, []);

  return (
    <Fragment>
      {context.isOpenDialogCreatePositionTitle !== true &&
      context.isOpenDialogCreatePositionTitleInDeputyHEAD !== true &&
      context.isOpenDialogCreatePositionTitleInASSISTANT !== true &&
      context.isOpenDialogCreatePositionTitleInSTAFF !== true &&
      context.isOpenDialogCreatePositionTitleInEditNodeSoHEAD !== true &&
      context.isOpenDialogCreatePositionTitleInEditRootTop !== true &&
      context.isOpenDialogCreateJenisUnit !== true &&
      context.isOpenDialogCreateEmployee !== true &&
      context.isOpenDialogCreateEmployeeInDeputyHEAD !== true &&
      context.isOpenDialogCreateEmployeeInASSISTANT !== true &&
      context.isOpenDialogCreateEmployeeInEditRootTOP !== true &&
      context.isOpenDialogCreateEmployeeInEditHEAD !== true &&
      context.isOpenDialogCreateEmployeeInEditDeputyHEAD !== true &&
      context.isOpenDialogCreateEmployeeInEditASSISTANT !== true &&
      context.isOpenDialogCreateEmployeeInEditSTAFF !== true &&
      context.isOpenDialogEditJenisUnit !== true &&
      context.isOpenDialogEditNamaUnit !== true &&
      context.isOpenDialogEditPositionTitle !== true &&
      context.isOpenDialogEditPositionTitleInTambahASSISTANT !== true &&
      context.isOpenDialogEditMemberEmployee !== true &&
      context.isOpenDialogEditMemberEmployeeInEditRootTOP !== true &&
      context.isOpenDialogEditMemberEmployeeInEditHEAD !== true &&
      context.isOpenDialogEditMemberEmployeeInEditDeputyHEAD !== true &&
      context.isOpenDialogEditMemberEmployeeInEditASSISTANT !== true &&
      context.isOpenDialogEditMemberEmployeeInEditSTAFF !== true ? (
        <TreeView
          className={classes.root}
          // defaultExpanded={[firstRootId !== "" ? firstRootId : "1"]} //*Not work
          defaultCollapseIcon={
            mainRoot !== "1" ? (
              <AvatarIconInOrgChart mainRoot={mainRoot} />
            ) : (
              <AvatarIconWithoutLineInOrgChart />
            )
          }
          defaultExpandIcon={
            mainRoot !== "1" ? (
              <AvatarIconInOrgChart mainRoot={mainRoot} />
            ) : (
              <AvatarIconWithoutLineInOrgChart />
            )
          }
          defaultParentIcon={
            mainRoot !== "1" ? (
              <AvatarIconInOrgChart mainRoot={mainRoot} />
            ) : (
              <AvatarIconWithoutLineInOrgChart />
            )
          }
          defaultEndIcon={
            <AvatarIconWithoutLineInOrgChart mainRoot={mainRoot} />
          }
          // defaultEndIcon = { <AvatarIconInOrgChart mainRoot= { mainRoot } /> }
          // onNodeToggle={() => // console.log("Toggle in TreeView ...")}
          // expanded={expanded}
        >
          {getTreeItemsFromData(treeItems)}
        </TreeView>
      ) : (
        <Fragment>
          <Paper
            style={{
              maxWidth: 370,
              marginLeft: "-24px",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: "#f7d9dd",
              borderLeft: "0.4em solid #d64253",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ marginLeft: 8, fontFamily: "Roboto", color: "red" }}
            >
              ...
            </Typography>
          </Paper>
          <Paper
            style={{
              maxWidth: 370,
              marginLeft: "-24px",
              borderLeft: "0.4em solid #d64253",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              padding: 16,
              marginBottom: 16,
              paddingBottom: 0,
              paddingTop: 0,
            }}
          >
            <List>
              <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: "grey",
                        fontFamily: "Roboto",
                        marginLeft: 16,
                        marginBottom: 20,
                      }}
                    >
                      <i>Vacancy</i>
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "Roboto",
                        marginLeft: 16,
                        marginTop: "-24px",
                      }}
                    >
                      <i>wait...</i>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItemSecondaryAction>
                <IconButton style={{ marginRight: "-32px" }}>
                  <i className="material-icons">more_vert</i>
                </IconButton>
              </ListItemSecondaryAction>
            </List>
            <Divider />

            <Box marginBottom={1}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Typography
                  variant="caption"
                  style={{ fontFamily: "Roboto", color: "grey" }}
                >
                  <i>... Subordinat </i>

                  <IconButton size="small">
                    <ExpandMoreIcon style={{ color: "grey" }} />
                  </IconButton>
                </Typography>
              </Grid>
            </Box>
          </Paper>
        </Fragment>
      )}

      <DialogTambahHead
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogTambahDeputyHead
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeInASSISTANT
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogTambahAssistant
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogTambahStaff
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditJenisUnit
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditNamaUnit
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditPositionTitle
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditPositionTitleInTambahASSISTANT
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditEmployeeMember
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditEmployeeMemberInEditRootTOP
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditEmployeeMemberInEditHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditEmployeeMemberInEditDeputyHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditEmployeeMemberInEditASSISTANT
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditEmployeeMemberInEditSTAFF
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateJenisUnit
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateJenisUnitInHEAD //*Component ini muncul dari cikal bakal klik "Edit" in HEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateNamaUnit
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateNamaUnitInHEAD //*Component ini muncul dari cikal bakal klik "Edit" in HEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreatePositionTitle
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreatePositionTitleInDeputyHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreatePositionTitleInASSISTANT
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreatePositionTitleInSTAFF
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreatePositionTitleInEditNodeSoHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreatePositionTitleInEditRootTop
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeInHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeInDeputyHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeEditRootTOP
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeInEditHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeInEditDeputyHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeInEditASSISTANT
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogCreateEmployeeInEditSTAFF
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogDeleteNodeSO
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditNodeSoClassificationTopLeader
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditNodeSoHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditNodeSoDeputyHEAD
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditNodeSoDeputyASSISTANT
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      <DialogEditNodeSoSTAFF
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />
    </Fragment>
  );
};

export default DataTreeView;

function getTreeItemsFromData(treeItems) {
  //*This function is called inside Recursive component

  return treeItems.map((treeItemData, i) => {
    let child = undefined;

    if (treeItemData.child !== undefined && treeItemData.child.length > 0) {
      child = getTreeItemsFromData(treeItemData.child);
    }

    return (
      <Fragment key={i}>
        <StyledTreeItem
          style={{ backgroundColor: "transparent" }}
          nodeId={treeItemData.id}
          label={<StyledTreeItemDetail {...treeItemData} />}
          children={child}
        />
      </Fragment>
    );
  });
}

/*

    https://medium.com/@swatisucharita94/recursive-rendering-in-react-42666102eae2

*/
