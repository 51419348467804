import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  Checkbox,
  Badge,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import TrackChangesOutlinedIcon from "@material-ui/icons/TrackChangesOutlined";

import clsx from "clsx";

import ContextReports from "../../../../../context/ContextReports";
import {
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_ENTITIES,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
} from "../../../../../context/types";
import { entities } from "../../../../global/dataFilterReport";

import ListItemEntities from "./ListItemEntities";
import { URL_API } from "../../../../../../../constants/config-api";
import AxiosConfig from "../../../../../../../constants/config-axios";
import extractTable from "../../../../global/extractTable";
import handleError from "../../../../global/handleError";
import DialogError from "../../../../../../../components/DialogError";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const EntitiesFilter = ({ classes }) => {
  const { filterState, tableState, tableDispatch, reportDispatch } =
    useContext(ContextReports);

  const [selected, setSelected] = useState([]);
  const [anchorEntities, setAnchorEntities] = useState(null);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const itemCount = selected.length;
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleShowAnchorEntities = (event) => {
    setAnchorEntities(event.currentTarget);
  };

  const handleCloseAnchorEntities = () => {
    setAnchorEntities(null);
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filterState.entities.map((n) => n.code);
      setSelected(newSelecteds);
      // // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleShowResult = () => {
    setAnchorEntities(null);

    tableDispatch({
      type: HIT_ENTITIES,
      payload: { entity: selected },
    });

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: tableState.usersSelected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: selected,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const postWithCompare = {
      ...dataPost,
      comparisons: {
        date_range: tableState.comparison.code,
        fields: [
          "result_value",
          "overall_result_achievement",
          "expected_result_value",
        ],
      },
    };

    const { currentPage, perPage } = tableState.pagination;

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data;

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        // // console.log("Ori Result PRST", result);

        if (res.status === 200) {
          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  return (
    <>
      <StyledBadge
        badgeContent={selected.length}
        children={
          <Button
            variant="outlined"
            size="small"
            startIcon={<TrackChangesOutlinedIcon style={{ fontSize: 15 }} />}
            className={classes.actionBtn}
            disabled={tableState.loadTable || filterState.loadFilter}
            onClick={handleShowAnchorEntities}
          >
            Entities
          </Button>
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEntities}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEntities)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginLeft={1}
          marginRight={2}
          marginBottom={1}
        >
          <Box display="flex" alignItems="center">
            <Checkbox
              disableRipple
              indeterminate={selected.length > 0 && selected.length < itemCount}
              checked={itemCount > 0 && selected.length === itemCount}
              onChange={handleSelectAllClick}
              className={classes.checkboxRoot}
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              color="default"
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
            <Typography variant="subtitle2" className={classes.title555}>
              All
            </Typography>
          </Box>
          <Typography
            className={classes.btnTxt}
            onClick={() => setSelected([])}
          >
            None
          </Typography>
        </Box>
        {entities.length > 0 &&
          entities.map((item, i) => (
            <MenuItem key={i} style={{ padding: 0, paddingRight: 15 }} dense>
              <ListItemEntities
                classes={classes}
                index={i}
                entity={item}
                selected={selected}
                setSelected={setSelected}
                isSelected={isSelected}
              />
            </MenuItem>
          ))}

        <Box margin={1.5}>
          <Divider />
        </Box>

        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorEntities}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            disabled={selected.length === 0}
            onClick={handleShowResult}
          >
            Show results
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default EntitiesFilter;
