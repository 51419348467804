import React, { useContext, useState } from "react";
import DialogCreate from "./DialogCreate";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../../components/StyledMenuDropdown";
import DialogDelete from "./DialogDelete";
import DialogEdit from "./DialogEdit";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
function ViewTabBreak({
  classes,
  isModalProgress,
  setModalProgress,
  dataBreakList,
  setDataBreakList,
  getBreakList,
}) {
  const context = useContext(ContextAttendanceSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterDataBreak, setMasterDataBreak] = useState({
    fields: {},
  });
  const [dataBreak, setDataBreak] = useState({
    id: "",
    title: "",
    break_start: moment().format("HH:mm"),
    break_start_date: moment(),
    break_end: moment().format("HH:mm"),
    break_end_date: moment(),
  });

  const resetDataBreak = () => {
    let dataBreakTemporary = dataBreak;
    dataBreakTemporary.id = "";
    dataBreakTemporary.title = "";
    dataBreakTemporary.break_start = moment().format("HH:mm");
    dataBreakTemporary.break_start_date = new Date();
    dataBreakTemporary.break_end = moment().format("HH:mm");
    dataBreakTemporary.break_end_date = new Date();
    setDataBreak({ ...dataBreakTemporary });
  };

  const handleShowModalCreate = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/breaks/create`)
      .then(function (response) {
        resetDataBreak();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataBreak;
        masterData.fields = response.data.data.fields;
        setMasterDataBreak(masterData);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const createBreak = () => {
    context.setModalProgress(true);
    setLoader(true);
    let data = {
      Breaks: dataBreak,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/breaks/create`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(false);
        context.setMessages("Data istirahat berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetDataBreak();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataBreakList.options.paging.limit * newPage,
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 0, parseInt(event.target.value));
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDelete = (e, item) => {
    handleDropdownClose();
    let dataBreakTemporary = dataBreak;
    dataBreakTemporary.id = item.id;
    dataBreakTemporary.title = item.title;
    setDataBreak({ ...dataBreakTemporary });
    context.setModalDelete(true);
  };

  const handleDelete = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/human-resource/breaks/${dataBreak.id}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalDelete(false);
          context.setMessages("Berhasil di hapus !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, 0, 0, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleShowModalEdit = (e, item) => {
    handleDropdownClose();
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/breaks/${item.id}/update`)
      .then(function (response) {
        resetDataBreak();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataBreak;
        masterData.fields = response.data.data.fields;
        setMasterDataBreak(masterData);
        getDataBreak(item);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const getDataBreak = (item) => {
    axiosConfig
      .get(`${URL_API}/human-resource/breaks/${item.id}`)
      .then(function (response) {
        resetDataBreak();
        // console.log("Response Original : ", response.data);
        let dataBreakTemporary = dataBreak;
        let momentNow = moment();
        dataBreakTemporary.id = response.data.data.id;
        dataBreakTemporary.title = response.data.data.title;
        dataBreakTemporary.break_start = response.data.data.break_start;
        dataBreakTemporary.break_start_date = moment(
          momentNow.format("YYYY-MM-DD") + " " + response.data.data.break_start,
          "YYYY-MM-DD HH:mm"
        );
        dataBreakTemporary.break_end = response.data.data.break_end;
        dataBreakTemporary.break_end_date = moment(
          momentNow.format("YYYY-MM-DD") + " " + response.data.data.break_end,
          "YYYY-MM-DD HH:mm"
        );
        setDataBreak({ ...dataBreakTemporary });
        context.setModalEdit(true);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalEdit(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleUpdate = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      let data = {
        Breaks: dataBreak,
      };
      axiosConfig
        .put(URL_API + `/human-resource/breaks/${dataBreak.id}`, data)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalEdit(false);
          context.setMessages("Berhasil di edit !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, null, null, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const refreshList = (search, offset, page, limit) => {
    let dataBreakListTemporary = dataBreakList;
    dataBreakListTemporary.options.filter.search =
      search !== null ? search : dataBreakListTemporary.options.filter.search;
    dataBreakListTemporary.options.paging.offset =
      offset !== null ? offset : dataBreakListTemporary.options.paging.offset;
    dataBreakListTemporary.options.paging.page =
      page !== null ? page : dataBreakListTemporary.options.paging.page;
    dataBreakListTemporary.options.paging.limit =
      limit !== null ? limit : dataBreakListTemporary.options.paging.limit;
    setDataBreakList({ ...dataBreakListTemporary });
    getBreakList();
  };

  return (
    <div>
      <Box>
        <Grid container spacing={3} style={{ marginBottom: "1em" }}>
          <Grid item md={6}>
            <Typography
              component={"span"}
              style={{ display: "inline-block", marginTop: "3px" }}
              className={classes.title333}
            >
              <b>Istirahat Shift</b>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                handleShowModalCreate();
              }}
            >
              Tambah Data Istirahat
            </Button>
          </Grid>
        </Grid>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            classes={classes}
            numSelected={selected.length}
            dataBreakList={dataBreakList}
            setDataBreakList={setDataBreakList}
            getBreakList={getBreakList}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={dataBreakList.list.length}
            />

            {dataBreakList &&
              dataBreakList.list &&
              dataBreakList.list.length > 0 && (
                <TableBody>
                  {dataBreakList.list.map((item, i) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                  inputProps={{ "aria-label": "select all desserts" }}
                                /> */}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {dataBreakList.options.paging.page *
                              dataBreakList.options.paging.limit +
                              i +
                              1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.title}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.longTitle}
                          >
                            {item.list_shift}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDropdownOpen(e, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl && anchorIn === i)}
                            onClose={(e) => handleDropdownClose(e, i)}
                          >
                            <StyledMenuItem
                              onClick={(e) => handleShowModalEdit(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Edit</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={(e) => handleShowModalDelete(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Hapus</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

            {dataBreakList &&
              dataBreakList.list &&
              dataBreakList.list.length <= 0 &&
              !isLoaderEmpty && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title333}
                          style={{
                            marginTop: 16,
                            marginBottom: 16,
                          }}
                        >
                          <b>Data istirahat tidak tersedia</b>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.paginationTimeManagement}
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  colSpan={6}
                  count={dataBreakList.options.paging.count}
                  rowsPerPage={dataBreakList.options.paging.limit}
                  page={dataBreakList.options.paging.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  labelRowsPerPage="Baris setiap halaman"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataBreak={masterDataBreak}
        setMasterDataBreak={setMasterDataBreak}
        dataBreak={dataBreak}
        setDataBreak={setDataBreak}
        listError={context.listError}
        createBreak={createBreak}
      />
      <DialogEdit
        classes={classes}
        isModalEdit={context.isModalEdit}
        setModalEdit={context.setModalEdit}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataBreak={masterDataBreak}
        setMasterDataBreak={setMasterDataBreak}
        dataBreak={dataBreak}
        setDataBreak={setDataBreak}
        listError={context.listError}
        handleUpdate={handleUpdate}
      />
      <DialogDelete
        classes={classes}
        isModalDelete={context.isModalDelete}
        setModalDelete={context.setModalDelete}
        dataBreak={dataBreak}
        setDataBreak={setDataBreak}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ViewTabBreak;
