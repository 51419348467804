import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import ListItemRemindInWeek from "./ListItemRemindInWeek";

const FreqDaily = ({
  classes,
  onDailyFreq,
  dailyFreq,
  goalDetail,
  dayList,
  remindList,
  autoInsert,
  onTrigger,
}) => {
  const date = new Date();
  const is_locked =
    goalDetail?.update_frequency?.result_locked === null
      ? false
      : goalDetail?.update_frequency?.result_locked === 0
      ? false
      : true;

  const auto_insert = autoInsert === "1" ? true : false;

  // =========================================================
  // Set Day Exist
  const defaultDays = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
  };
  const days =
    dayList?.length > 0 &&
    dayList?.reduce((a, v) => ({ ...a, [v.day]: v.selected }), {});

  const [selectedDate, setSelectedDate] = useState(
    goalDetail?.update_frequency === null
      ? moment("00:01", [moment.ISO_8601, "HH:mm"])
      : goalDetail?.update_frequency?.interval !== "daily"
      ? moment("00:01", [moment.ISO_8601, "HH:mm"])
      : moment(goalDetail?.update_frequency?.scheduled_at, [
          moment.ISO_8601,
          "HH:mm",
        ])
  );
  const [formatedDate, setFormatedDate] = useState(
    goalDetail?.update_frequency === null
      ? "00:01"
      : goalDetail?.update_frequency?.interval !== "daily"
      ? "00:01"
      : goalDetail?.update_frequency?.scheduled_at
  );
  const [locking, setLocking] = useState(is_locked);
  const [autoInsertResult, setAutoInsertResult] = useState(auto_insert);
  const [daily, setDaily] = useState(defaultDays);
  const [anchorRemind, setAnchorRemind] = useState(null);
  const [reminder, setReminder] = useState(false);
  const [remindMe, setRemindMe] = useState({
    id: "",
    name: "",
    selected: false,
    self: {
      rel: null,
    },
  });

  useEffect(() => {
    setDaily(days);
  }, [dayList]);

  useEffect(() => {
    const getDayTrue = Object.fromEntries(
      Object.entries(daily).filter(([key, value]) => value === true)
    );
    const selectedDay = Object.getOwnPropertyNames(getDayTrue).map((el) => {
      return {
        day: el,
        scheduled_at: formatedDate,
      };
    });

    // // console.log("GET getDayTrue", getDayTrue);
    // // console.log("GET Prop Name", selectedDay);
    // // console.log("selectedDate", moment(selectedDate).format("HH:mm"));

    onDailyFreq({
      ...dailyFreq,
      scheduled_at: formatedDate,
      result_locked: locking ? 1 : 0,
      is_locked: locking,
      reminder: remindMe.id,
      auto_insert_result: autoInsertResult ? "1" : "0",
      daily_details: {
        selected_days: selectedDay,
      },
    });
  }, [selectedDate, daily, locking, remindMe, autoInsertResult]);

  const handleDateChange = (date) => {
    const formatHour = moment(date).format("HH:mm");
    setSelectedDate(date);
    setFormatedDate(formatHour);

    onTrigger(Math.floor(Math.random() * 100));
  };

  const handleDaily = (e) => {
    setDaily({ ...daily, [e.target.name]: e.target.checked });
  };

  const handleLocking = (e) => {
    setLocking(e.target.checked);
  };

  const handleShowAnchorRemind = (e) => {
    setAnchorRemind(e.currentTarget);
  };
  const handleCloseAnchorRemind = () => {
    setAnchorRemind(null);
  };

  const handleReminder = (e) => {
    setReminder(e.target.checked);
  };

  const handleAutoInsert = (e) => {
    setAutoInsertResult(e.target.checked);
  };

  const handleRemind = (data) => {
    setRemindMe(data);
    handleCloseAnchorRemind();
  };

  return (
    <>
      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Scheduled at</b>
        </Typography>

        <Box ml={9}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              value={selectedDate}
              onChange={handleDateChange}
              keyboardIcon={<WatchLaterIcon style={{ fontSize: 17 }} />}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              variant="dialog"
              style={{ width: 150 }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Scheduled on</b>
        </Typography>

        <Box ml={9} display="flex" flexWrap="wrap" width={400}>
          {dayList?.length > 0 &&
            dayList?.map((day, i) => {
              // const hadnleChec = () => {}

              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={daily[`${day.day}`]}
                      onChange={handleDaily}
                      name={day.day}
                      className={classes.checkDaily}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{
                        backgroundColor: daily[`${day.day}`] && "#282560",
                        color: daily[`${day.day}`] ? "#fff" : "#888",
                        border: !daily[`${day.day}`] && "1px solid #888",
                        borderRadius: 50,
                        padding: "3px 10px",
                        marginBottom: 7.5,
                        marginRight: 2.5,
                      }}
                    >
                      {day.day_name}
                    </Typography>
                  }
                />
              );
            })}
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Locking</b>
        </Typography>

        <Box ml={10}>
          <Switch checked={locking} onChange={handleLocking} color="primary" />
        </Box>

        {!locking && (
          <Typography variant="caption" className={classes.title}>
            <i>No update is allowed after schduled update is passed</i>
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Auto Insert</b>
        </Typography>

        <Box ml={7}>
          <Switch
            checked={autoInsertResult}
            onChange={handleAutoInsert}
            color="primary"
          />
        </Box>

        {!autoInsertResult && (
          <Typography variant="caption" className={classes.title}>
            <i>
              When user not inserted the result before frequency time, system
              will automatically insert result to 0.
            </i>
          </Typography>
        )}
      </Box>

      {/* <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Add Reminder</b>
        </Typography>

        <Box ml={7}>
          <Switch
            checked={reminder}
            onChange={handleReminder}
            color="primary"
          />
        </Box>
      </Box> */}

      {reminder && (
        <Box display="flex" alignItems="center" mt={4}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>Reminder me</b>
          </Typography>

          <Box ml={9}>
            <Button
              variant="outlined"
              size="small"
              className={classes.actionBtn}
              endIcon={<ArrowDropDownIcon style={{ fontSize: 25 }} />}
              onClick={handleShowAnchorRemind}
            >
              {remindMe.name}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorRemind}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorRemind)}
              onClose={handleCloseAnchorRemind}
            >
              {remindList.length > 0 &&
                remindList.map((item, i) => {
                  const isCurrent = item.name === remindMe.name;

                  return (
                    <ListItemRemindInWeek
                      key={i}
                      classes={classes}
                      isCurrent={isCurrent}
                      remind={remindMe}
                      item={item}
                      handleRemind={handleRemind}
                    />
                  );
                })}
            </Menu>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FreqDaily;

const listDays = [
  {
    id: 1,
    code: "monday",
    label: "Monday",
  },
  {
    id: 2,
    code: "tuesday",
    label: "Tuesday",
  },
  {
    id: 3,
    code: "wednesday",
    label: "Wednesday",
  },
  {
    id: 4,
    code: "thursday",
    label: "Thursday",
  },
  {
    id: 5,
    code: "friday",
    label: "Friday",
  },
  {
    id: 6,
    code: "saturday",
    label: "Saturday",
  },
  {
    id: 7,
    code: "sunday",
    label: "Sunday",
  },
];
