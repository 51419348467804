import { createMuiTheme, fade } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Lato", "Lato"].join(","),
  },
  button: {
    fontFamily: ["Lato", "Lato"].join(","),
  },
});

export const styles = () => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  layoutMarginLeftAndTop: {
    paddingLeft: 72,
    marginTop: 56,
  },
  fixedLayout: {
    // width: "100%",
    // maxWidth: 2192,
    marginLeft: "auto",
    marginRight: "auto",
  },
  layoutMarginTop: {
    // marginLeft: 72,
    marginTop: 56,
  },
  layoutMarginTopNew: {
    marginTop: theme.spacing(10),
  },
  layoutMarginLeftList: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },

  backButton: {
    marginLeft: 22,
  },

  button: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    borderWidth: 0,
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  button0: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  button2: {
    borderRadius: 5,
    background: "#d1354a",
    border: 0,
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      background: "#f28181",
    },
  },
  button3: {
    borderRadius: 5,
    background: "#fff",
    border: 0,
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "#333",
    "&:hover": {
      color: "#d1354a",
      backgroundColor: "#eee",
    },
  },
  button4: {
    borderRadius: 5,
    color: "#27ae60",
    border: 0,
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    backgroundColor: "#9fffaf",
    fontSize: 12.5,
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    "&:hover": {
      backgroundColor: "#7bed9f",
      color: "#27ae60",
    },
  },
  button5: {
    borderRadius: 5,
    color: "#d1354a",
    border: 0,
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    backgroundColor: "#FFAC9C",
    fontSize: 12.5,
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    "&:hover": {
      background: "#f28181",
      color: "#d1354a",
    },
  },
  button6: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "#d64253",
    padding: "10px 20px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ffcccc",
    },
  },
  button7: {
    borderRadius: 5,
    color: "#1D80DB",
    border: 0,
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    backgroundColor: "#B8CDEF",
    fontSize: 12.5,
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    "&:hover": {
      background: "#8ebfed",
      color: "#1D80DB",
    },
  },
  uploadBtn: {
    fontFamily: "'Lato', sans-serif",
    color: "#00ACE6",
    backgroundColor: "rgba(0, 172, 230, 0.1)",
    borderRadius: 5,
    padding: "10px, 3px",
    border: "none",
    fontSize: 13,
    fontWeight: "500",
    textTransform: "capitalize",
    "&:hover": {
      opacity: 0.9,
    },
  },
  buttonDisabled: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "grey",
    fontWeight: "bold",
  },
  buttonOutlined: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },
  btnWhite: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #B1B1B1",
    color: "#5B5B5B",
    textTransform: "capitalize",
    // padding: "2px 12px",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  btnGrey: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    color: "#333",
    textTransform: "capitalize",
    padding: "2px 12px",
    backgroundColor: "#DFDFDF",
    "&:hover": {
      backgroundColor: "#B1B1B1",
    },
  },
  btnFilter: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 10,
    border: "1px solid #8F8F8F",
    padding: "2px 8px",
    textTransform: "capitalize",
    color: "#8F8F8F",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
  btnIcon: {
    padding: `4px 4px`,
    borderRadius: 10,
    border: "1px solid #8F8F8F",
    minWidth: 20,
    textTransform: "capitalize",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
  chipFilterSort: {
    backgroundColor: "#83829C",
    color: "#fff",
    borderRadius: 9,
    "&:hover, &:focus": {
      backgroundColor: "#9b9baf",
      color: "#5b5b6d",
    },
  },
  title: {
    fontFamily: "Lato",
    // color: "#333",
  },
  titleWithOpacity: {
    fontFamily: "Lato",
    opacity: 0.3,
  },
  titleListChoose: {
    fontFamily: "Lato",
    cursor: "pointer",
    fontWeight: "bold",
  },
  titleListChooseAktif: {
    fontFamily: "Lato",
    color: "#d6d3d3",
    cursor: "pointer",
  },

  timeFrameIcon: {
    fontFamily: "Lato",
    color: "grey", //#4aa9fb
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
    // backgroundColor: '#cfe8fd'
  },
  timeFrameIcontDateRangeText: {
    fontFamily: "Lato",
    color: "#4aa9fb",
    textTransform: "capitalize",
    backgroundColor: "#edf6ff",
    textDecoration: "none",
  },

  timeFrameIconInModal: {
    fontFamily: "Lato",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      color: "#edcfd8", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },

  timeFrameButonDuplicate: {
    marginRight: 12,
    fontFamily: "Lato",
    color: "grey",
    fontSize: 14,
    textTransform: "capitalize",
    borderWidth: 1,
    borderColor: "grey",
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      borderColor: "#cc0707",
      color: "#cc0707",
    },
  },

  /*
        ```````
        SEARCH

        ```````
    */
  search: {
    borderRadius: 12,
    backgroundColor: "#fff",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
    fontSize: 15,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: 300,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
    fontFamily: "'Lato', sans-serif",
    fontSize: 15,
    width: 300,
  },
  inputInput: {
    fontSize: 15,
    width: 300,
    padding: "4px 8px",
    transition: theme.transitions.create("width"),
    color: "grey",
  },
  /*
        ````````````````````````````
        PAPER, CIRCULAR PROGRESS BAR

        ````````````````````````````        
    */
  listCardItem: {
    paddingBottom: 0,
    paddingTop: 7,
  },
  paperList: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
  },
  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderBottom: "0.3em solid #c6332d",
    // borderBottom: '0.5em solid red'
    // borderBottom:  `${0.5em solid  red[800]}`
  },
  circularProgressBar: {
    width: 65,
    height: 65,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  circularBeside: {
    width: 65,
    height: 65,
    // marginLeft: theme.spacing(2),
    // marginTop: theme.spacing(0.5),
    // marginBottom: theme.spacing(2)
  },
  /*
        ````````````
        MODAL CREATE 

        ````````````
    */
  textField: {
    minWidth: 425,
    [theme.breakpoints.only("xs")]: {
      minWidth: 200,
    },
  },

  /*
        `````````````````````````````
        FOTO REAL USER NAME, USERNAME

        `````````````````````````````
    */
  userRealFoto: {
    // margin: 10,
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  imageAvatar: {
    width: 50,
    height: 40,
  },
  userRealFotoInCardItemGoal: {
    // margin: 10,
    width: 32,
    height: 32,
    borderRadius: 16,
  },

  /*
        ````
        TAB 

        ````
    */
  tabList: {
    borderWidth: 1,
    paddingLeft: 0,
    borderColor: "#d9dada",
    borderStyle: "solid",
  },
  tabListWithoutBorder: {
    borderWidth: 0,
    paddingLeft: 0,
    borderColor: "#d9dada",
    borderStyle: "solid",
  },

  /*

        ``````````
        DATE RANGE

        ``````````
    */
  dateRangePerbaruiSampul: {
    fontFamily: "Lato",
    color: "grey",
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
  },

  /*

        ````````````
        YOU - ACTIVE

        ````````````
    */
  moreDetail: {
    fontFamily: "Lato",
    color: "black", //#4aa9fb
    textTransform: "capitalize",
    fontSize: 11,
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 3,
    paddingLeft: 3,
    backgroundColor: "yellow",
  },

  /*
        ````````````````````````
        MODAL CREATE GOAL WIZARD

        ````````````````````````
    */
  colorIconCancel: {
    color: "red",
  },
  colorPrimaryAppbar: {
    backgroundColor: "white",
  },
  moreDetailForMoreVert: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    fontSize: 11,
    cursor: "pointer",
  },

  /*
        ````````````````````
        HANDLE TAB ALL & YOU

        ````````````````````
    */

  outlinedTabActive: {
    borderBottom: `3px solid #d64253`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: "1px 2px",
    borderRadius: 3,
  },

  outlinedTabNotActive: {
    borderBottom: `3px solid transparent`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: "1px 2px",
    borderRadius: 3,
  },

  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
    // margin: 12
  },
  noBorderBottom: {
    borderBottom: "none",
  },

  /*
        ````````````````````````
        TOOLBAR PAGINATION TABLE

        ````````````````````````
    */

  toolbar: {
    backgroundColor: "transparent",
  },

  linearProgress: {
    height: 2,
  },

  /*
        ``````````````````````
        NEW TAB IN STATUS GOAL

        ``````````````````````
    */
  widthTab: {
    minWidth: 75, //
  },
  bigRealFoto: {
    // margin: 10,
    width: 24,
    height: 24,
    borderRadius: 20,
  },

  paperTransparent: {
    backgroundColor: "transparent",
  },
  paperColumnDuaRowFirst: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
  },
  paperNewColorOfGoal: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0.2),
    // background: 'linear-gradient(1deg, #0D50C2, #A4FCFF)',
    background: "linear-gradient(1deg, #0D50C2,  #25c6cc)",

    // background: 'linear-gradient(1deg, #262261, #6B688B)',
    // borderLeft: '0.5em solid red'
  },

  titleInLineChart: {
    fontFamily: "Lato",
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
  },

  circularProgressBarInMoreDetail: {
    // width: 60,
    // height: 60,
    width: 100,
    height: 100,
  },
  circularProgressBarInPaperDetail: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  buttonModalDelete: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },

  // CONNECT MA WITH GOAL BUTTON
  connectMAToGoal: {
    fontFamily: "Lato",
    color: "#d85466",
    textTransform: "capitalize",
    "&:hover": {
      color: "grey", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },

  // Set Color untuk Goal yang valid / tdk valid untuk set as completed
  validGoal: {
    color: "#fff",
    backgroundColor: "#1EBC61",
    fontSize: 13,
    borderRadius: 10,
    padding: `0px 5px`,
  },
  notValidGoal: {
    color: "#fff",
    backgroundColor: "#d64253",
    fontSize: 13,
    borderRadius: 10,
    padding: `0px 5px`,
  },
  rootLinearProgress: {
    width: "100%",
  },

  //============================ MARE ============================
  titleMARList: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#bbb",
  },

  expandMARBtn: {
    backgroundColor: "transparent",
    color: "#888",
    fontFamily: "Lato",
    border: 0,
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonFab: {
    // width: '503px',
    // height: '42px',
    background: "#d1354a",
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },

  // ========================================
  // MARE
  // ========================================
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconRadio: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    padding: 0,
  },
  checkedIconRadio: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1354a",
    },
  },

  // ========================================
  // Styled Checkbox
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a",
    },
  },

  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: "center",
    padding: 10,
    border: `3px dashed #eeeeee`,
    backgroundColor: "#fafafa",
    color: "#bdbdbd",

    marginBottom: 10,
    marginTop: 10,

    "&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },

  textBreadcrum: {
    "&:hover": { cursor: "pointer", color: "#333" },
    fontFamily: "Lato",
    fontSize: 11,
  },
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    top: "100%",
    zIndex: "1000",
    minWidth: "160px",
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
  },
  dropdownItem: {
    fontWeight: "300",
    lineHeight: "1.5em",
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "fit-content",
    color: "#333",
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    height: "1px",
    overflow: "hidden",
  },
  popperResponsive: {
    zIndex: "1200",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black",
    },
  },
  //  new card
  shadowSection: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  shadowSectionLight: {
    borderRadius: 8.5,
    boxShadow: "0px 10px 10px -4px rgba(0,0,0,0.05)",
  },

  toDetails: {
    fontFamily: "'Lato', sans-serif",
    color: "#333",
    fontWeight: "normal",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      textDecoration: "underline",
    },
  },

  // FREQ
  checkDaily: {
    visibility: "hidden",
    display: "none",
    height: 0,
    width: 0,
    opacity: 0,
  },
});
