import React, { useContext, useState } from 'react';
import {
  TableCell,
  TableRow,
  Box,
  Typography,
  Tooltip,
  Checkbox,
  Select,
  MenuItem,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';

import moment from 'moment';
import clsx from 'clsx';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import ImageSmalluser from '../../../../components/ImageSmallUser';
import ContextTask from '../../Context/ContextTask';
import { DRAWER_OPEN, SET_TODO_DETAILS } from '../../Context/types';
import AxiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';

import ResultBar from '../global/ResultBar';
import AnchorTodoItem from '../Action/anchor/AnchorTodoItem';
import handleError from '../../../Report/components/global/handleError';
import DialogError from '../../../../components/DialogError';

import icon_high from '../../../../assets/images/SVG/icon_high.svg';
import icon_medium from '../../../../assets/images/SVG/icon_medium.svg';
import icon_low from '../../../../assets/images/SVG/icon_low.svg';
import TruncateTextCustom from '../../../../utilities/TruncateTextCustom';
import AnchorOwner from './AnchorOwner';
import TruncateNumber from '../../../../utilities/TruncateNumberCustom';
import { FormatDecimal } from '../../../../utilities/FormatDecimal';

const checkStyle = makeStyles((theme) => ({
  // ========================================
  // Rounded Icon
  // ========================================
  checkboxRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    border: '1px solid #888',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',

    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(209, 53, 74,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    borderRadius: 20,
    backgroundColor: 'rgba(16,107,163,.5)', // #137cbd
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'rgba(16,107,163,.3)', // #106ba3 #b1354a
    },
  },
  completedIcon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    border: '1px solid #888',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23888'/%3E%3C/svg%3E\")",
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedCompleteIcon: {
    borderRadius: 20,
    backgroundColor: 'rgba(16,107,163,.5)', // #137cbd
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23106ba3'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'rgba(16,107,163,.3)', // #106ba3 #b1354a
    },
  },
}));

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const ListItemTable = ({ classes, row, ownerCollections }) => {
  const classCheck = checkStyle();
  const { todoDispatch, selected, setSelected, setTriggerTodo } =
    useContext(ContextTask);
  const completed = row.toggle.completed === '2' ? true : false;

  const [loadStatus, setLoadStatus] = useState(false);
  const [loadPriority, setLoadPriority] = useState(false);
  const [loadDueDate, setLoadDueDate] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );
  /* =========================
    VARS
    ============================= */
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isItemSelected = isSelected(row.id);
  const dataUpdate = {
    Todolist: {
      title: row.name.title,
      description:
        row.name.description !== null ? row.name.description : '',
      remarks: row.name.remarks !== null ? row.name.remarks : '',
      owner_id: row.name.owner_id,
      status: row.status !== null ? row?.status?.id : null,
      priority: row.priority !== null ? row?.priority?.id : null,
      due_date: moment(row.due_date).format('YYYY-MM-DD HH:mm:ss'),
      start_date: moment(row.start_date).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      tags: row.name.tags !== null ? row.name.tags : '',
      actual_result:
        row.result.actual !== null ? Number(row.result.actual) : 0,
      target_result: Number(row.result.target),
      // task_group_id: null,
      // project_id: null,
    },
  };

  // // console.log("Asdwec", row.name);

  /* =========================
    toggle TODO
    ============================= */
  const toggleTodo = () => {
    todoDispatch({
      type: DRAWER_OPEN,
      payload: { drawer: true },
    });
    todoDispatch({
      type: SET_TODO_DETAILS,
      payload: { todoDetails: row.name },
    });
  };

  /* =========================
              Handle Multiple Select
              ============================= */
  const handleChangeCheckbox = (e, item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  /* =========================
              Handle STATUS
              ============================= */
  const handleStatus = (e) => {
    setLoadStatus(true);

    const data = {
      Todolist: {
        ...dataUpdate.Todolist,
        status: e.target.value,
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${row.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTodo(Math.floor(Math.random() * 100));
          setLoadStatus(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadStatus(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  /* =========================
              Handle PRIORITY
              ============================= */
  const handlePriority = (e) => {
    setLoadPriority(true);

    const data = {
      Todolist: {
        ...dataUpdate.Todolist,
        priority: e.target.value,
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${row.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTodo(Math.floor(Math.random() * 100));
          setLoadPriority(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadPriority(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleDueDate = (e) => {
    setLoadDueDate(true);

    const data = {
      Todolist: {
        ...dataUpdate.Todolist,
        due_date: moment(e).format('YYYY-MM-DD HH:mm:ss'),
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${row.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTodo(Math.floor(Math.random() * 100));
          setLoadDueDate(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadDueDate(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // ===================== | LATE BADGE | =====================
  const isLate = moment(new Date()).isAfter(row.due_date, 'day');
  const isDueToday = moment(row.due_date).isSame(new Date(), 'day');
  const isToday = moment(row.name.created_at).isSame(
    new Date(),
    'day'
  );

  return (
    <TableRow
      selected={isItemSelected}
      className={completed ? classes.tableRowGrey : classes.tableRow}
    >
      <TableCell className={classes.txtBody} align="left">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              checked={isItemSelected}
              className={classCheck.checkboxRoot}
              checkedIcon={
                <span
                  className={
                    completed
                      ? classCheck.checkedCompleteIcon
                      : classCheck.checkedIcon
                  }
                />
              }
              icon={
                <span
                  className={
                    completed
                      ? classCheck.completedIcon
                      : classCheck.icon
                  }
                />
              }
              disableRipple
              color="default"
              onClick={(e) => handleChangeCheckbox(e, row)}
            />
            <Typography
              variant="subtitle2"
              className={classes.todoName}
              style={{
                minWidth: 300,
                marginLeft: 10,
              }}
              onClick={toggleTodo}
            >
              {row.name.title}
            </Typography>
          </Box>

          <Box ml={1}>
            {(row?.status?.id !== 'DONE' || completed) && isLate && (
              <Box
                bgcolor="#C8203C"
                px={1}
                py={0.25}
                fontSize={10}
                color="#FFF"
                borderRadius={5}
              >
                LATE
              </Box>
            )}
            {(row?.status?.id !== 'DONE' || completed) &&
              isDueToday && (
                <Box
                  minWidth={75}
                  textAlign="center"
                  bgcolor="#FFDD55"
                  px={1}
                  py={0.25}
                  fontSize={10}
                  color="#444"
                  borderRadius={5}
                >
                  DUE TODAY
                </Box>
              )}
            {isToday && (
              <Box
                bgcolor="#00a8ff"
                px={1}
                py={0.25}
                fontSize={10}
                color="#FFF"
                borderRadius={5}
              >
                NEW
              </Box>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        className={classes.txtBody}
        style={{ borderLeft: '1px solid #D9D9D9', width: 120 }}
      >
        <AnchorOwner
          classes={classes}
          row={row}
          ownerCollections={ownerCollections}
        />
      </TableCell>
      <TableCell
        align="center"
        className={classes.txtBody}
        style={{ width: 120 }}
      >
        <Select
          fullWidth
          value={row.status && row?.status?.id}
          onChange={handleStatus}
          disableUnderline={true}
          disabled={completed}
        >
          {statusCollections.map((status, i) => {
            let bgColor;
            let color;

            switch (status.id) {
              case 'TO DO':
                bgColor = '#DFDFDF';
                color = '#777777';
                break;
              case 'DOING':
                bgColor = '#CFEAF1';
                color = '#4194AC';
                break;
              case 'DONE':
                bgColor = '#D9EBDD';
                color = '#1E874D';
                break;
              case 'ON HOLD':
                bgColor = '#F6EDC3';
                color = '#B68812';
                break;
              case 'WAITING':
                bgColor = '#E2DAE5';
                color = '#92739F';
                break;
              default:
                bgColor = '#555555';
                color = '#fff';
            }

            return (
              <MenuItem value={status.id} key={i}>
                <span
                  className={classes.statusItem}
                  style={{
                    backgroundColor: bgColor,
                    color,
                  }}
                >
                  {loadStatus && (
                    <CircularProgress
                      size={13}
                      style={{ color, marginRight: 5 }}
                    />
                  )}{' '}
                  <span>{status.name}</span>
                </span>
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell
        align="left"
        className={classes.txtBody}
        style={{ width: 120 }}
      >
        <Select
          fullWidth
          value={row.priority && row?.priority?.id}
          onChange={handlePriority}
          disableUnderline={true}
          disabled={completed}
        >
          {priorityCollections.map((priority, i) => {
            let icon;

            switch (priority.id) {
              case 'HIGH':
                icon = icon_high;
                break;
              case 'MEDIUM':
                icon = icon_medium;
                break;
              case 'LOW':
                icon = icon_low;
                break;
              default:
                icon = null;
            }

            return (
              <MenuItem value={priority.id} key={i}>
                <Box display="flex" alignItems="center">
                  {loadPriority && (
                    <CircularProgress
                      size={13}
                      style={{ color: '#d64253', marginRight: 5 }}
                    />
                  )}
                  <img src={icon} height={16} />
                  <span
                    style={{
                      color: '#4D4D4D',
                      fontSize: 14,
                      marginLeft: 5,
                    }}
                  >
                    {priority.name}
                  </span>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell
        align="left"
        className={classes.txtBody}
        style={{ width: 150 }}
      >
        <Box display="flex" alignItems="center">
          {loadDueDate && (
            <CircularProgress
              size={20}
              style={{ color: '#d64253', marginRight: 5 }}
            />
          )}
          <MuiPickersUtilsProvider utils={LocalizedUtils} locale="id">
            <DatePicker
              autoOk
              value={row.due_date}
              onChange={handleDueDate}
              animateYearScrolling
              variant="dialog" // dialog, static, inline
              disableToolbar={false}
              format="DD-MM-YYYY"
              inputVariant="standard"
              size="small"
              disabled={completed}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small">
                      <DateRangeIcon
                        style={{ color: '#555', fontSize: 17 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
                style: { fontSize: 14, color: '#555' },
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        className={classes.txtBody}
        style={{ width: 130 }}
      >
        <Tooltip title={row.created_by.full_name}>
          {row.created_by.photo_url !== null ? (
            <Box
              className={classes.justHover}
              display="flex"
              alignItems="center"
            >
              <ImageSmalluser
                item={row.created_by.photo_url}
                index={1}
              />
              <span
                style={{ color: '#555', fontSize: 12, marginLeft: 5 }}
              >
                {TruncateTextCustom(row.created_by.full_name, 5)}
              </span>
            </Box>
          ) : (
            <Box
              className={classes.justHover}
              display="flex"
              alignItems="center"
            >
              <AccountCircleIcon
                className={classes.justHover}
                style={{ color: '#888' }}
              />
              <span
                style={{ color: '#555', fontSize: 12, marginLeft: 5 }}
              >
                {TruncateTextCustom(row.created_by.full_name, 5)}
              </span>
            </Box>
          )}
        </Tooltip>
      </TableCell>
      <TableCell
        align="left"
        className={classes.txtBody}
        style={{ width: 200 }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          m={0}
        >
          <Tooltip
            title={FormatDecimal(
              row.result.actual === null ? 0 : row.result.actual
            )}
          >
            <Box width={30} display="flex" justifyContent="center">
              <span style={{ color: '#555', fontSize: 13 }}>
                {row.result.actual === null
                  ? 0
                  : TruncateNumber(row.result.actual, 2)}
              </span>
            </Box>
          </Tooltip>
          <Box mx={0.25} />
          <Box width={100}>
            <ResultBar item={row.result} status={row.status.code} />
          </Box>
          <Box mx={0.25} />
          <Tooltip title={FormatDecimal(row.result.target)}>
            <Box width={30} display="flex" justifyContent="center">
              <span style={{ color: '#555', fontSize: 13 }}>
                {TruncateNumber(row.result.target, 2)}
              </span>
            </Box>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell
        align="right"
        className={classes.txtBody}
        style={{ width: 40 }}
      >
        <AnchorTodoItem classes={classes} todo={row.name} />
      </TableCell>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </TableRow>
  );
};

export default ListItemTable;

const statusCollections = [
  {
    id: 'TO DO',
    code: 'TO DO',
    name: 'To Do',
    sort_order: 0,
  },
  {
    id: 'DOING',
    code: 'DOING',
    name: 'Doing',
    sort_order: 1,
  },
  {
    id: 'DONE',
    code: 'DONE',
    name: 'Done',
    sort_order: 2,
  },
  {
    id: 'ON HOLD',
    code: 'ON HOLD',
    name: 'On Hold',
    sort_order: 3,
  },
  {
    id: 'WAITING',
    code: 'WAITING',
    name: 'Waiting',
    sort_order: 3,
  },
];

const priorityCollections = [
  {
    id: 'HIGH',
    code: 'HIGH',
    name: 'High',
    sort_order: 0,
  },
  {
    id: 'MEDIUM',
    code: 'MEDIUM',
    name: 'Medium',
    sort_order: 1,
  },
  {
    id: 'LOW',
    code: 'LOW',
    name: 'Low',
    sort_order: 2,
  },
];
