import React, { Fragment, useContext } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  IconButton,
} from "@material-ui/core";

import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { navigate } from "hookrouter";

function MAListGallery({ classes, item, count }) {
  const evidenceCount = count[item.measured_activity_id];

  const handleNavigateGallery = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    let maName = data.measuredActivity.name.replace(/,+/g, " ");
    breadcrumb.push(`MA : ${maName}`);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.measured_activity_id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.measured_activity_id}`);
  };

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#fffee0" }}>
        <TableCell padding="checkbox" />
        <TableCell>
          <Box display="flex" alignItems="center">
            <Avatar
              style={{
                width: 34,
                height: 34,
                backgroundColor: "#ffbe76",
              }}>
              <TrendingUpRoundedIcon
                style={{ fontSize: 22, color: "#e67e22" }}
              />
            </Avatar>

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15 }}
              onClick={() => handleNavigateGallery(item)}>
              {`MA : ${item !== undefined && item.measuredActivity.name}`}
            </Typography>

            <span style={{ color: "#999", marginLeft: 5, marginRight: 5 }}>
              -
            </span>

            <Typography style={{ color: "#999", fontSize: 13 }}>
              {`${evidenceCount.length} Evidence(s)`}
            </Typography>
          </Box>
        </TableCell>

        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />

        <TableCell align="right">
          <IconButton>
            <MoreVertIcon style={{ color: "transparent" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default MAListGallery;
