import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Button,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableSortLabel,
  InputBase,
  Divider,
  Container,
  TextField,
} from "@material-ui/core";

import axios from "axios";
import axiosConfig from "../../../constants/config-axios";

import { navigate } from "hookrouter";
import { useDebouncedCallback } from "use-debounce";

import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";

// import ButtonColorGrey from '../../../components/ButtonColorGrey';
import EnhancedTableToolbar from "./Components/EnhancedTableToolbar";
import LightTooltip from "./Components/LightTooltip";

import Snackbeer from "../../../components/Snackbeer";
import PictDefaultDashboard from "../../../assets/images/Mask_Group_2.png";
// import IconPreviewGrid from "../../../assets/images/SVG/Group_1002.svg";
// import IconPreviewList from "../../../assets/images/SVG/Group_1001_red.svg";
// import IconFilter from '../../../assets/images/SVG/Group_1250.svg';
import IconFilterNew from "../../../assets/images/SVG/Group_1117.svg";

// import Redirect from "../../../utilities/Redirect";
import { URL_API } from "../../../constants/config-api";
import {
  ToHrEmployeeIdentitas,
  ToHrEmployeeProfileDetailSeeDetail,
} from "../../../constants/config-redirect-url";

import { styles } from "./Style/StyleEmpty";
import DialogDelete from "./Components/DialogDelete";
import ListEmployee from "./Components/ListEmployee";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewEmpty = (props) => {
  const { classes } = props;

  const userToken = localStorage.getItem("userToken");
  let inputRefSearch = useRef(null);

  const [userTokenState, setUserTokenState] = useState("");
  const [data, setData] = useState([]);

  const [isSearchRun, setSearchRun] = useState(false);
  const [memberSearchValue, setMemberSearchValue] = useState("");
  const [clearSearchResult, setClearSearchResult] = useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setData([]);
    setLoader(true);

    setUserTokenState(userToken);

    const memberSearch = localStorage.getItem("member_search");

    if (userToken !== undefined) {
      if (memberSearch !== null && memberSearch !== undefined) {
        setSearchRun(true);
        setMemberSearchValue(memberSearch);

        axiosConfig
          .get(
            `${URL_API}/human-resource/employee?options[filter][search]=${memberSearch}`
          )
          .then((response) => {
            // console.log("Search EMployeaw : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setSearchRun(true);
                setData(response.data.data); // GET EMPLOYEE COLLECTION;
              }
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            // console.log("Error response : ", error.response);
          });
      } else {
        setSearchRun(false);
        setMemberSearchValue("");

        axiosConfig
          .get(URL_API + `/human-resource/employee`)
          .then((response) => {
            // console.log("Original response : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setData(response.data.data); // GET EMPLOYEE COLLECTION;
              }
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            // console.log("Error response : ", error.response);
          });
      }
    } else {
      // console.log("Ga dapet User Token !");
    }
  }, []);

  /* ================================================
    Handle Clear Search Result
  ================================================ */
  useEffect(() => {
    if (
      memberSearchValue === "" &&
      isSearchRun === false &&
      clearSearchResult === true
    ) {
      setLoader(true);

      if (userToken !== undefined) {
        axiosConfig
          .get(`${URL_API}/human-resource/employee`)
          .then((response) => {
            // console.log("Original response : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setData(response.data.data); // GET EMPLOYEE COLLECTION;
              }
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            // console.log("Error response : ", error.response);
          });
      }
    }
  }, [memberSearchValue, isSearchRun, clearSearchResult]);

  /*
        ````````````````````````
        isSuccessDelete === true

        ````````````````````````
    */
  const [isSuccessDelete, setSuccessDelete] = useState(false);
  useEffect(() => {
    setData([]);
    setLoader(true);

    setUserTokenState(userToken);

    if (userToken !== undefined) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        crossorigin: true,
        crossDomain: true,
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = headers;
      axios
        .get(URL_API + `/human-resource/employee`)
        .then((response) => {
          // console.log("Original response : ", response);

          if (response.status === 200) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setData(response.data.data); // GET EMPLOYEE COLLECTION;
            }
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          // console.log("Error response : ", error.response);
        });
    } else {
      // console.log("Ga dapet User Token !");
    }
  }, [isSuccessDelete]);

  /*
        ````````````````````````
        isSuccessDelete === true

        ````````````````````````
    */

  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      if (value !== "") {
        // console.log("The Employee Search : ", value);
        localStorage.setItem("member_search", value);

        setMemberSearchValue(value);

        setLoader(true);
        if (userToken !== undefined) {
          axiosConfig
            .get(
              `${URL_API}/human-resource/employee?options[filter][search]=${value}`
            )
            .then((response) => {
              // console.log("Search EMployeaw : ", response);

              if (response.status === 200) {
                if (
                  response.data.data !== null &&
                  response.data.data !== undefined
                ) {
                  setSearchRun(true);
                  setData(response.data.data); // GET EMPLOYEE COLLECTION;
                }
              }
              setLoader(false);
            })
            .catch((error) => {
              setLoader(false);
              // console.log("Error response : ", error.response);
            });
        }
      } else {
        setSearchRun(false);
        setMemberSearchValue("");
        localStorage.removeItem("member_search");

        axiosConfig
          .get(URL_API + `/human-resource/employee`)
          .then((response) => {
            // console.log("Original response : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setData(response.data.data); // GET EMPLOYEE COLLECTION;
              }
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            // console.log("Error response : ", error.response);
          });
      }
    }, []),
    1000,

    { maxxWait: 5000 }
  );

  /* 
   
     ```````````````````````````
     Get List, Table functional

     ```````````````````````````
     
   */

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  }

  /*
        ````````````````
        Get ID EMPLOYEE
        
        ````````````````
    */
  const [idEmployee, setIdEmployee] = useState([]);
  const [employeeName, setEmployeeName] = useState("");

  function handleClick(event, id) {
    let ids = [];

    // console.log("Id :", id);
    const newIds = [...ids, { id: id }];

    setIdEmployee(newIds);

    /*
            ````````````````
            
            *********

            ````````````````
        */

    const selectedIndex = selected.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (data !== null && data !== undefined ? data.length : 0) -
        page * rowsPerPage
    );

  /*

        ````````````
        MODAL DETAIL

        ````````````
    */
  const [isChooseDetail, setChooseDetail] = useState(false);
  const handleCloseModalEdit = () => setChooseDetail(false);

  /*
      ```````````````````````````
      HANDLE TAMBAH KARYAWAN NEW

      ```````````````````````````
    */

  const handleTambahKaryawanNEW = () => {
    // Redirect(ToHrEmployeeIdentitas);
    navigate(ToHrEmployeeIdentitas);
  };

  /*
        ```````````````````
        HANDLE CLICK DETAIL

        ```````````````````
    */
  const handleClickDetail = (e, idEmployee) => {
    e.preventDefault();

    localStorage.setItem("employee_id", idEmployee);

    if (userTokenState !== undefined) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        crossorigin: true,
        crossDomain: true,
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = headers;
      axios
        .get(URL_API + `/human-resource/employee/batch/${idEmployee}`)
        .then((response) => {
          // console.log("Original emplyee  : ", response);

          if (response.status === 200) {
            localStorage.setItem(
              "response_employee_detail",
              JSON.stringify(response.data.data)
            );
            // navigate(ToHrEmployeeProfileDetailSeeDetail);
          }
        })
        .catch((error) => {
          // console.log("Error response : ", error.response);
        });
    } else {
      // console.log("Ga dapet User Token !");
    }

    //*
    navigate(ToHrEmployeeProfileDetailSeeDetail);
  };

  /*
        ```````````````````````````````````
        HANDLE OPEN DIALOG DELETE EMPLOYEEE

        ````````````````````````````````````
    */
  const [isModalDelete, setModalDelete] = useState(false);

  /*
        ``````````````````````````````
        ON MOUSE OVER & ON MOUSE LEAVE

        ``````````````````````````````
    */

  // GET SUPER ADMIN
  // GET SUPER ADMIN
  const getSA = localStorage.getItem("status_user_login");
  const superAdmin = JSON.parse(getSA);
  let isSuperAdmin = false;

  superAdmin.userRole &&
    superAdmin.userRole.forEach((item) => {
      if (item.name === "superadmin") {
        isSuperAdmin = true;
      }
    });

  return (
    <MuiThemeProvider theme={theme}>
      <br />

      {/* 
                ````````````` 
                LIST EMPLOYEE 
                
                ````````````` 
            */}

      <Box marginLeft={theme.spacing(1.6)} marginTop={theme.spacing(1.5)}>
        <Paper
          style={{ backgroundColor: "transparent" }}
          className={classes.rootEmployee}
          elevation={0}
        >
          {/* <Grid container>   
                <Grid item sm={4}></Grid>
                  <Grid item sm={5} style={{paddingTop: 0}}>

                      <Paper className={classes.paper} elevation={1}>
                          <IconButton className={classes.iconButton} aria-label="Search">
                              <SearchIcon />
                          </IconButton>
                          <InputBase className={classes.input} placeholder="Cari ..." />
                          <Divider className={classes.divider} />
                      </Paper>
                  </Grid>
                  <Grid item sm={1}></Grid>

                  <Grid item sm={1} style={{textAlign: 'right'}}>
                      <img src={IconPreviewList} className={classes.iconPreviewList} alt="." />
                  </Grid>
                  <Grid item sm={1} style={{textAlign: 'left'}}>
                      <img src={IconPreviewGrid} className={classes.iconPreviewGrid} alt="." />
                  </Grid>
              </Grid> */}
          <br />
          <br />

          {/* 
                            
                                ``````````````````````````
                                    Search, Button Invite

                                ``````````````````````````
                            */}

          <Grid
            container
            spacing={8}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item sm={2} style={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                className={classes.title}
                style={{ marginLeft: 42 }}
              >
                Data Karyawan
              </Typography>
            </Grid>

            <Grid item sm={8} style={{ textAlign: "right" }}>
              <Paper
                style={{
                  padding: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 500,
                }}
              >
                <SearchIcon style={{ marginLeft: 5 }} />
                <InputBase
                  style={{ marginLeft: 10, flex: 1 }}
                  placeholder={memberSearchValue}
                  onChange={(e) => handleSearch(e.target.value)}
                  inputRef={inputRefSearch}
                />
                {isSearchRun && (
                  <IconButton
                    style={{ padding: 10 }}
                    aria-label="search"
                    onClick={() => {
                      setSearchRun(false);
                      setMemberSearchValue("");
                      setClearSearchResult(true);
                      localStorage.removeItem("member_search");

                      setTimeout(() => {
                        inputRefSearch.current.value = "";
                      }, 200);
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>

            <Grid item sm={2} style={{ textAlign: "right" }}>
              <Button
                disabled={!isSuperAdmin}
                onClick={handleTambahKaryawanNEW}
                variant="contained"
                color="secondary"
                className={classes.button2}
                style={{ marginRight: 64 }}
              >
                Tambah
              </Button>
            </Grid>
          </Grid>
          {/* 
                            
                                ```
                                END

                                ```
                            */}
        </Paper>
      </Box>

      <Divider style={{ marginTop: 15, marginBottom: -25 }} />

      {/* 
                `````
                TABLE

                `````
            */}

      <Box marginLeft={theme.spacing(1.6)}>
        {/* ROW OF DELETE  */}
        <EnhancedTableToolbar
          numSelected={selected.length}
          idEmployee={idEmployee}
        />

        <Table className={classes.table} aria-labelledby="tableTitle">
          {data !== null && data !== undefined && data.length > 0 ? (
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data !== null ? data.length : 0}
              classes={classes}
            />
          ) : (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <br />
              <br />
              <img
                src={PictDefaultDashboard}
                style={{ height: 150 }}
                alt="Default Empty State Pict"
              />
              <br />
              <br />
              <Typography
                variant="h6"
                className={classes.title}
                style={{ color: "black", textAlign: "center" }}
              >
                Belum ada data karyawan yang bisa ditampilkan !
              </Typography>
            </Grid>
          )}

          {loader ? (
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <CircularProgress
                size={32}
                style={{ marginTop: 48, color: "red" }}
              />
            </Grid>
          ) : (
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, i) => {
                  const isItemSelected = isSelected(item.id);

                  return (
                    <ListEmployee
                      key={i}
                      n={item}
                      classes={classes}
                      isSuperAdmin={isSuperAdmin}
                      setIdEmployee={setIdEmployee}
                      handleClickDetail={handleClickDetail}
                      setEmployeeName={setEmployeeName}
                      setModalDelete={setModalDelete}
                    />
                  );
                })}
              {/* {
                emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                )
            } */}
            </TableBody>
          )}
        </Table>
      </Box>

      {/* {data.length === 0 && (
        <Paper elevation={1} className={classes.paperList}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <br />
              <br />
              <img
                src={PictDefaultDashboard}
                style={{ height: 150 }}
                alt="Default Empty State Pict"
              />
              <br />
              <br />
              <Typography
                variant="h6"
                className={classes.title}
                style={{ color: "black", textAlign: "center" }}>
                Belum ada data karyawan yang bisa ditampilkan !
              </Typography>
              <br />
              <Button
                variant="contained"
                className={classes.button}
                // onClick={() => Redirect(ToEm)}
                // onClick={() => Redirect(ToHrEmployeeIdentitas)}
                onClick={() => navigate(ToHrEmployeeIdentitas)}>
                Tambah karyawan
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )} */}

      {data !== null && data !== undefined && data.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data !== null && data !== undefined ? data.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <DialogDelete
        classes={classes}
        isModalDelete={isModalDelete}
        setModalDelete={setModalDelete}
        idEmployee={idEmployee}
        employeeName={employeeName}
        setSuccessDelete={setSuccessDelete}
      />

      <Snackbeer
        isModalResponse200={isSuccessDelete}
        setModalResponse200={setSuccessDelete}
        messages="Berhasil menghapus karyawan "
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewEmpty);

/*

    ```````````````````````````````````
    functions utilities TABLE COMPONENT

    ```````````````````````````````````
*/

let counter = 0;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  // // console.log("stableSort : ", array)

  if (array !== null && array !== undefined) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);

      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  return [];
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "name", align: "center", disablePadding: true, label: "" },
  {
    id: "statusAcitve",
    align: "right",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "dataKaryawan",
    align: "center",
    disablePadding: false,
    label: "Nama ",
  },
  {
    id: "tingkatJabatan",
    align: "center",
    disablePadding: false,
    label: "Email",
  },
  { id: "nip", align: "center", disablePadding: false, label: "NIP" },
  { id: "jabatan", align: "center", disablePadding: false, label: "Jabatan" },
  { id: "detail", align: "center", disablePadding: false, label: "" },
  // { id: 'delete', align: 'center', disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    classes,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell> */}
        {rows.map(
          (row) => (
            <TableCell
              key={row.id}
              numeric={row.numeric}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
              className={classes.title}
            >
              <Tooltip
                title="Sort"
                placement={row.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  <b>{row.label}</b>
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
}
