import moment from "moment";

export const mapProjectGantt = (project) => {
  const percent =
    (Number(project.actual_results) / Number(project.project_scope_results)) *
    100;

  // // console.log("ARR PROJ", project);

  return [
    {
      start: new Date(moment(project.start_date).format("YYYY-MM-DD")),
      end: new Date(moment(project.end_date).format("YYYY-MM-DD")),
      name: project.name,
      title: project.name,
      id: project.id,
      type: "project",
      progress: percent > 100 ? 100 : percent,
      isDisabled: false,
      // project: project.name,
      styles: {
        backgroundColor: "#9ccddc",
        backgroundSelectedColor: "#8cb8c6",
        progressColor: "#4e9ebf",
        progressSelectedColor: "#468eab",
      },
    },
  ];
};

export const mapTaskGantt = (arr, project) => {
  // // console.log("ARR DATS", arr);

  const data =
    arr !== undefined &&
    arr.length > 0 &&
    arr.map((task) => {
      const complete = task.is_complete.id;
      const status = task.status.id;
      let percentage;
      // let color;
      // let selectColor;

      // // console.log("TASK", task);

      if (complete === "2" || status === "DONE") {
        percentage = Number(task.schedule_result) * 100;
        // color = "#d34c62";
        // selectColor = "#c8203c";
      }
      if (complete !== "2" || status !== "DONE") {
        percentage = Number(task.scope_result) * 100;
        // color = "#66a9bc";
        // selectColor = "#4194ac";
      }

      // passed due date
      const dateAfter = moment(
        task?.end_date === null ? new Date() : task?.end_date
      ).isAfter(task?.due_date, "day");

      // Color Bar
      let bgBar;
      let bgSelectedBar;
      let progressBar;
      let progressSelectedBar;

      const status_id = task.status.id;

      if (!dateAfter && status_id !== "DONE") {
        if (status_id === "TO DO") {
          bgBar = "#aaafb3";
          bgSelectedBar = "#999da1";
          progressBar = "#898d90          ";
          progressSelectedBar = "#7a7d80";
        }
        if (status_id === "DOING") {
          bgBar = "#91d8e5";
          bgSelectedBar = "#82c2ce";
          progressBar = "#75aeb9";
          progressSelectedBar = "#689ba4";
        }
        if (status_id === "ON HOLD") {
          bgBar = "#cbab59";
          bgSelectedBar = "#b69950";
          progressBar = "#a38948";
          progressSelectedBar = "#917a40";
        }
        if (status_id === "WAITING") {
          bgBar = "#b29dbb";
          bgSelectedBar = "#a08da8";
          progressBar = "#907e97";
          progressSelectedBar = "#807086";
        }
      } else {
        bgBar = "#ff5e5e";
        bgSelectedBar = "#e55454";
        progressBar = "#ce4b4b";
        progressSelectedBar = "#b74343";
      }

      if (status_id === "DONE") {
        bgBar = "#8ec3a6";
        bgSelectedBar = "#7faf95";
        progressBar = "#729d86";
        progressSelectedBar = "#658c77";
      }

      return {
        start: new Date(moment(task.start_date).format("YYYY-MM-DD")),
        end: new Date(moment(task.due_date).format("YYYY-MM-DD")),
        title: task.title,
        name: `${task?.owner?.member?.first_name} ${task?.owner?.member?.last_name}`,
        id: task.id,
        type: "task",
        progress: percentage > 100 ? 100 : percentage,
        isDisabled: false,
        dependencies: task.dependencies,
        project: project.id,
        description: task.description,
        remarks: task.remarks,
        status: task.status,
        priority: task.priority,
        tags: task.tags,
        actual_result: task.actual_result,
        target_result: task.target_result,
        project_id: task.project_id,
        task_group_id: task.task_group_id,
        predecessor_id: task.dependencies,
        owner_id: task.owner_id,
        styles: {
          // backgroundColor: task.status.id === "DONE" ? "#7bed9f" : "#4bcffa",
          // backgroundSelectedColor:
          //   task.status.id === "DONE" ? "#2ed573" : "#0fbcf9",
          // progressColor: task.status.id === "DONE" ? "#6ed58f" : "#3498db",
          // progressSelectedColor:
          //   task.status.id === "DONE" ? "#29bf67" : "#2e88c5",
          backgroundColor: bgBar,
          backgroundSelectedColor: bgSelectedBar,
          progressColor: progressBar,
          progressSelectedColor: progressSelectedBar,
          // textColor: "#555",
        },
      };
    });

  return data.length > 0 ? data : [];
};
