import React, { useContext } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";

import numeral from "numeral";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import PictGAP from "../../../../assets/images/Group_2619.png";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

function TextOverDotLineChartThird({ classes, goalDetailState }) {
  const context = useContext(ContextGoalDetail);

  let GAPTOSTARTVAL;
  const startVal = Number(goalDetailState.start_value);
  const targetVal = Number(goalDetailState.target_value);
  const resultVal = Number(
    goalDetailState.calculatedValue.resultOriented.result_value
  );
  const actualVal = Number(
    goalDetailState.calculatedValue.actualOriented.actual_value
  );

  // Calculate
  if (startVal === 0) {
    // // console.log("SPAM RES");

    if (goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE) {
      const withPercent = targetVal - resultVal;
      GAPTOSTARTVAL = `${FormatDecimal(withPercent)}%`;
    } else {
      if (context.modeRupiahOn === true) {
        const withRP = targetVal - resultVal;
        GAPTOSTARTVAL = `Rp ${FormatDecimal(withRP)}`;
      } else {
        GAPTOSTARTVAL = FormatDecimal(targetVal - resultVal);
      }
    }
  }

  if (startVal > 0) {
    // // console.log("SPAM ACT");

    if (goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE) {
      const withPercent = targetVal - actualVal;
      GAPTOSTARTVAL = `${FormatDecimal(withPercent)}%`;
    } else {
      if (context.modeRupiahOn === true) {
        const withRP = targetVal - actualVal;
        GAPTOSTARTVAL = `Rp ${FormatDecimal(withRP)}`;
      } else {
        GAPTOSTARTVAL = FormatDecimal(targetVal - actualVal);
      }
    }
  }

  return (
    <List>
      <ListItem>
        <ListItemIcon
          style={{
            marginLeft: 8,
          }}
        >
          <img src={PictGAP} style={{ width: 40, height: 40 }} alt="." />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemtext}
          primary={
            <Typography variant="subtitle2" className={classes.title}>
              <b>{GAPTOSTARTVAL}</b>
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}
            >
              {MyLang() ? bahasa.gapToTV : english.gapToTV}
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}

export default TextOverDotLineChartThird;
