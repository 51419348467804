import React from "react";
import { FormControlLabel, Typography, Radio } from "@material-ui/core";
import clsx from "clsx";

const ModeSelect = ({ classes, item, mode, onMode }) => {
  const isCurrent = mode === item.method;

  return (
    <FormControlLabel
      label={
        <Typography
          variant="subtitle2"
          className={classes.title555}
          style={{ textTransform: "capitalize" }}>
          {item.name}
        </Typography>
      }
      style={{ paddingLeft: 15 }}
      control={
        <Radio
          className={classes.rootRadio}
          onClick={() => onMode(item.method)}
          value={item.method}
          checked={isCurrent}
          checkedIcon={
            <span
              className={clsx(classes.iconRadio, classes.checkedIconRadio)}
            />
          }
          icon={<span className={classes.iconRadio} />}
        />
      }
    />
  );
};

export default ModeSelect;
