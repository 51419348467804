import React from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  TablePagination,
} from "@material-ui/core";
import TablePaginationCustom from "../../Goal/Components/TablePaginationCustom";

function PaginationMA({
  theme,
  classes,
  loader,
  collectionMALength,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <Grid marginTop={theme.spacing(0.3)} container justify="flex-end">
      {loader ? (
        <CircularProgress size={14} color="#d64253" />
      ) : (
        <TablePagination
          classes={{
            toolbar: classes.toolbar,
            root: classes.noBorderBottom,
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          colSpan={3}
          count={collectionMALength}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({ from, to, count }) => (
            <Typography variant="subtitle2" className={classes.title}>
              <h4>
                {`${from} - ${to === -1 ? count : to} dari total `}
                <strong>{count}</strong> baris
              </h4>
            </Typography>
          )}
          labelRowsPerPage="Baris setiap halaman"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationCustom}
        />
      )}
    </Grid>
  );
}

export default PaginationMA;
