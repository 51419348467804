import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  Divider,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Event  from "@material-ui/icons/Event";
import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";
import clsx from "clsx";
import ListItemWorkPattern from "./ListItemWorkPattern";
import ListItemShift from "./ListItemShift";
import ListItemSchedule from "./ListItemSchedule";
import ListItemWorkLocation from "./ListItemWorkLocation";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const ScheduleFilter = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  // ==============================================
  // ~~~~~~~~~~~~~~~~~ STATE ~~~~~~~~~~~~~~~~~
  // ==============================================
  const [selectedWorkPattern, setSelectedWorkPattern] = useState([]);
  const [selectedShift, setSelectedShift] = useState([]);
  const [selectedScheduleName, setSelectedScheduleName] = useState([]);
  const [anchorSchedule, setAnchorSchedule] = useState(null);
  const [anchorWorkPattern, setAnchorWorkPattern] = useState(null);
  const [anchorShift, setAnchorShift] = useState(null);
  const [anchorScheduleName, setAnchorScheduleName] = useState(null);
  const isSelectedWorkPattern = (id) => selectedWorkPattern.indexOf(id) !== -1;
  const isSelectedShift = (id) => selectedShift.indexOf(id) !== -1;
  const isSelectedScheduleName = (id) => selectedScheduleName.indexOf(id) !== -1;
  

  
  const handleShowAnchorSchedule = (event) => {
    setAnchorSchedule(event.currentTarget);
  };
  const handleCloseAnchorSchedule = () => {
    setAnchorSchedule(null);
  };

  const handleShowAnchorWorkPattern = (event) => {
    setAnchorWorkPattern(event.currentTarget);
  };
  const handleCloseAnchorWorkPattern = () => {
    setAnchorWorkPattern(null);
  };
  const handleSelectAllClickWorkPattern = async (event) => {
    setSelectedShift([]);
    setSelectedScheduleName([]);
    if (event.target.checked) {

      let newSelecteds = [];
      await context.masterFilterReportSummary.work_patterns.filter((item) => {
          newSelecteds.push(item.id);
      });
      setSelectedWorkPattern(newSelecteds);
      return;
    }
    setSelectedWorkPattern([]);
  };
  const setSelectedWorkPatternFunc = async (data) => {
    setSelectedWorkPattern(data);
    setSelectedShift([]);
    setSelectedScheduleName([]);
  }

  const handleShowAnchorShift = (event) => {
    setAnchorShift(event.currentTarget);
  };
  const handleCloseAnchorShift = () => {
    setAnchorShift(null);
  };
  const handleSelectAllClickShift = async (event) => {
    setSelectedScheduleName([]);
    if (event.target.checked) {

      let newSelecteds = [];
      await context.masterFilterReportSummary.shifts.filter((item) => {
        if (selectedWorkPattern.includes(item.work_pattern_id)) {
          newSelecteds.push(item.id);
        }
      });
      setSelectedShift(newSelecteds);
      return;
    }
    setSelectedShift([]);
  };
  const setSelectedShiftFunc = async (data) => {
    setSelectedShift(data);
    setSelectedScheduleName([]);
  }

  const handleShowAnchorScheduleName = (event) => {
    setAnchorScheduleName(event.currentTarget);
  };
  const handleCloseAnchorScheduleName = () => {
    setAnchorScheduleName(null);
  };
  const handleSelectAllClickScheduleName = async (event) => {
    if (event.target.checked) {

      let newSelecteds = [];
      await context.masterFilterReportSummary.schedules.filter((item) => {
        if (selectedShift.includes(item.shift_id)) {
          newSelecteds.push(item.id);
        }
      });
      setSelectedScheduleName(newSelecteds);
      return;
    }
    setSelectedScheduleName([]);
  };

  const handleShowResult = () => {
    setAnchorSchedule(null);
    let dataTemporary                 = context.dataSettingSummary;
    dataTemporary.filter.work_pattern = selectedWorkPattern;
    let workPatternName               = [];
    context.masterFilterReportSummary.work_patterns.map((item) => {
      if (selectedWorkPattern.includes(item.id)) {
        const name = `${item.name ? item.name : ""}`;
        workPatternName.push(name);
      }
      return 0;
    })
    dataTemporary.filter.work_pattern_name  = workPatternName;
    dataTemporary.filter.shift              = selectedShift;
    let shiftName                           = [];
    context.masterFilterReportSummary.shifts.map((item) => {
      if (selectedShift.includes(item.id)) {
        const name = `${item.title ? item.title : ""}`;
        shiftName.push(name);
      }
      return 0;
    })
    dataTemporary.filter.shift_name = shiftName;
    dataTemporary.filter.schedule   = selectedScheduleName;
    let scheduleName                = [];
    context.masterFilterReportSummary.schedules.map((item) => {
      if (selectedScheduleName.includes(item.id)) {
        const name = `${item.name ? item.name : ""}`;
        scheduleName.push(name);
      }
      return 0;
    })
    dataTemporary.filter.schedule_name      = scheduleName;
    dataTemporary.paging.offset             = 0;
    dataTemporary.paging.page               = 1;
    context.setDataSettingSummary({...dataTemporary});
    context.getReportSummaryList();
    handleCloseAnchorSchedule();
  };

  return (
    <>
        <StyledBadge
        badgeContent={selectedWorkPattern.length + selectedShift.length + selectedScheduleName.length}
            children={
            <Button
                variant="outlined"
                size="small"
                startIcon={<Event  style={{ fontSize: 15 }} />}
                className={classes.actionBtn}
                disabled={context.loader}
                onClick={handleShowAnchorSchedule}
            >
                Schedule
            </Button>
            }
        />
        <Menu
            id="simple-menu"
            anchorEl={anchorSchedule}
            keepMounted
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
            }}
            transformOrigin={{
            vertical: "top",
            horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorSchedule)}
            onClose={handleCloseAnchorSchedule}>

            <Box mx={2} mt={2} mb={3} style={{ width: 200 }}>
              <Typography variant="subtitle2" className={classes.title555}>
                  <b>Work Pattern  {selectedWorkPattern.length > 0 && `(${selectedWorkPattern.length})`}</b>
              </Typography>
      
              <Button
                  variant="outlined"
                  className={classes.actionBtn}
                  fullWidth
                  endIcon={<ArrowDropDownIcon />}
                  style={{ marginTop: 5 }}
                  onClick={handleShowAnchorWorkPattern}>
                  Pilih Work Pattern
              </Button>
              <Menu
                  id="simple-menu"
                  anchorEl={anchorWorkPattern}
                  keepMounted
                  anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                  }}
                  transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                  }}
                  getContentAnchorEl={null}
                  open={Boolean(anchorWorkPattern)}
                  onClose={handleCloseAnchorWorkPattern}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  ml={1}
                  mr={2}
                  mb={1}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      className={classes.checkboxRoot}
                      checkedIcon={
                        <span className={clsx(classes.icon, classes.checkedIcon)} />
                      }
                      icon={<span className={classes.icon} />}
                      checked={context.masterFilterReportSummary.work_patterns.length > 0 && selectedWorkPattern.length === context.masterFilterReportSummary.work_patterns.length}
                      disableRipple
                      color="default"
                      inputProps={{ "aria-label": "decorative checkbox" }}
                      onChange={handleSelectAllClickWorkPattern}
                    />
                    <Typography variant="subtitle2" className={classes.title555}>
                      All
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.btnTxt}
                    onClick={() => setSelectedWorkPatternFunc([])}>
                    None
                  </Typography>
                </Box>
                <Box>
                  {context.loader && (
                    <Box m={2}>
                      <Typography
                        variant="caption"
                        className={classes.title555}
                        style={{ textAlign: "center" }}>
                        Loading...
                      </Typography>
                    </Box>
                  )}
                  {context.masterFilterReportSummary.work_patterns.length > 0 &&
                    context.masterFilterReportSummary.work_patterns.map((item, i) => {
                      // if (selectedWorkPattern.includes(item.work_pattern_id)) {
                        return (
                          <MenuItem key={i} style={{ padding: 0 }} dense>
                            <ListItemWorkPattern
                              classes={classes}
                              index={i}
                              item={item}
                              selected={selectedWorkPattern}
                              setSelected={setSelectedWorkPatternFunc}
                              isSelected={isSelectedWorkPattern}
                            />
                          </MenuItem>
                        );
                      // }
                    })}
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  my={1.5}
                  mx={2}
                  style={{ minWidth: 200 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button0}
                    onClick={() => {
                      handleCloseAnchorWorkPattern();
                      setSelectedWorkPatternFunc([]);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 7.5 }}
                    size="small"
                    className={classes.button1}
                    onClick={handleCloseAnchorWorkPattern}>
                    Select ({selectedWorkPattern.length})
                  </Button>
                </Box>

              </Menu>
            </Box>

            <Box mx={2} mt={2} mb={3} style={{ width: 200 }}>
              <Typography variant="subtitle2" className={classes.title555}>
                  <b>Shift {selectedShift.length > 0 && `(${selectedShift.length})`}</b>
              </Typography>

              <Button
                variant="outlined"
                className={classes.actionBtn}
                fullWidth
                endIcon={<ArrowDropDownIcon />}
                style={{ marginTop: 5 }}
                onClick={handleShowAnchorShift}>
                Pilih Shift
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorShift}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                getContentAnchorEl={null}
                open={Boolean(anchorShift)}
                onClose={handleCloseAnchorShift}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  ml={1}
                  mr={2}
                  mb={1}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      className={classes.checkboxRoot}
                      checkedIcon={
                        <span className={clsx(classes.icon, classes.checkedIcon)} />
                      }
                      icon={<span className={classes.icon} />}
                      checked={context.masterFilterReportSummary.shifts.length > 0 && 
                          selectedShift.length === context.masterFilterReportSummary.shifts.filter(function(item){ return selectedWorkPattern.includes(item.work_pattern_id);}).length}
                      disableRipple
                      color="default"
                      inputProps={{ "aria-label": "decorative checkbox" }}
                      onChange={handleSelectAllClickShift}
                    />
                    <Typography variant="subtitle2" className={classes.title555}>
                      All
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.btnTxt}
                    onClick={() => setSelectedShiftFunc([])}>
                    None
                  </Typography>
                </Box>
                <Box>
                  {context.loader && (
                    <Box m={2}>
                      <Typography
                        variant="caption"
                        className={classes.title555}
                        style={{ textAlign: "center" }}>
                        Loading...
                      </Typography>
                    </Box>
                  )}
                  {context.masterFilterReportSummary.shifts.length > 0 &&
                    context.masterFilterReportSummary.shifts.map((item, i) => {
                      if (selectedWorkPattern.includes(item.work_pattern_id)) {
                        return (
                          <MenuItem key={i} style={{ padding: 0 }} dense>
                            <ListItemShift
                              classes={classes}
                              index={i}
                              item={item}
                              selected={selectedShift}
                              setSelected={setSelectedShiftFunc}
                              isSelected={isSelectedShift}
                            />
                          </MenuItem>
                        );
                      }
                    })}
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  my={1.5}
                  mx={2}
                  style={{ minWidth: 200 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button0}
                    onClick={() => {
                      handleCloseAnchorShift();
                      setSelectedShiftFunc([]);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 7.5 }}
                    size="small"
                    className={classes.button1}
                    onClick={handleCloseAnchorShift}>
                    Select ({selectedShift.length})
                  </Button>
                </Box>
              </Menu>
            </Box>

            <Box mx={2} mt={2} mb={3} style={{ width: 200 }}>
              <Typography variant="subtitle2" className={classes.title555}>
                  <b>Schedule  {selectedScheduleName.length > 0 && `(${selectedScheduleName.length})`}</b>
              </Typography>

              <Button
                variant="outlined"
                className={classes.actionBtn}
                fullWidth
                endIcon={<ArrowDropDownIcon />}
                style={{ marginTop: 5 }}
                onClick={handleShowAnchorScheduleName}>
                Pilih Schedule
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorScheduleName}
                keepMounted
                anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
                }}
                transformOrigin={{
                vertical: "top",
                horizontal: "left",
                }}
                getContentAnchorEl={null}
                open={Boolean(anchorScheduleName)}
                onClose={handleCloseAnchorScheduleName}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  ml={1}
                  mr={2}
                  mb={1}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      className={classes.checkboxRoot}
                      checkedIcon={
                        <span className={clsx(classes.icon, classes.checkedIcon)} />
                      }
                      icon={<span className={classes.icon} />}
                      checked={context.masterFilterReportSummary.schedules.length > 0 && 
                        selectedScheduleName.length === context.masterFilterReportSummary.schedules.filter(function(item){ return selectedShift.includes(item.shift_id);}).length}
                      disableRipple
                      color="default"
                      inputProps={{ "aria-label": "decorative checkbox" }}
                      onChange={handleSelectAllClickScheduleName}
                    />
                    <Typography variant="subtitle2" className={classes.title555}>
                      All
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.btnTxt}
                    onClick={() => setSelectedScheduleName([])}>
                    None
                  </Typography>
                </Box>
                <Box>
                  {context.loader && (
                    <Box m={2}>
                      <Typography
                        variant="caption"
                        className={classes.title555}
                        style={{ textAlign: "center" }}>
                        Loading...
                      </Typography>
                    </Box>
                  )}
                  {context.masterFilterReportSummary.schedules.length > 0 &&
                    context.masterFilterReportSummary.schedules.map((item, i) => {
                      if (selectedShift.includes(item.shift_id)) {
                        return (
                          <MenuItem key={i} style={{ padding: 0 }} dense>
                            <ListItemSchedule
                              classes={classes}
                              index={i}
                              item={item}
                              selected={selectedScheduleName}
                              setSelected={setSelectedScheduleName}
                              isSelected={isSelectedScheduleName}
                            />
                          </MenuItem>
                        );
                      }
                    })}
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  my={1.5}
                  mx={2}
                  style={{ minWidth: 200 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button0}
                    onClick={() => {
                      handleCloseAnchorScheduleName();
                      setSelectedScheduleName([]);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 7.5 }}
                    size="small"
                    className={classes.button1}
                    onClick={handleCloseAnchorScheduleName}>
                    Select ({selectedScheduleName.length})
                  </Button>
                </Box>
              </Menu>
            </Box>

            <Box>
              <Box m={1.5} mt={1}>
                <Divider />
              </Box>

              <Box
                marginRight={2}
                marginBottom={1}
                display="flex"
                justifyContent="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.button0}
                  onClick={handleCloseAnchorSchedule}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  className={classes.button1}
                  style={{ marginLeft: 7.5 }}
                  onClick={handleShowResult}
                >
                  {context.loader ? "Showing..." : "Show results"}
                </Button>
              </Box>
            </Box>
        </Menu>
    </>
  );
};

export default ScheduleFilter;
