import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";

import moment from "moment";

import SummaryConfig from "./SummaryConfig";
import BarChartSection from "./bar-chart-section";
import Businessprofile from "./business-profile";
import TableSection from "./table-section";
import OverviewAverage from "./overview-average";
import ContextReports from "../../../context/ContextReports";

const SummaryType = ({ classes }) => {
  const { summaryState } = useContext(ContextReports);

  return (
    <Box>
      <Box
        py={1.5}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Typography variant="subtitle2" className={classes.title333}>
          Info Status :{" "}
          {summaryState.infoStatus !== null
            ? summaryState.infoStatus && summaryState.infoStatus.status
            : "latest"}
          <SyncIcon style={{ color: "#555", fontSize: 14, marginLeft: 5 }} />
        </Typography>
        <Typography variant="subtitle2" className={classes.title333}>
          Last sync time :{" "}
          {summaryState.syncSummary !== ""
            ? moment(summaryState.syncSummary).format("DD/MM/YYYY") + "at "
            : "Not "}
          {summaryState.syncSummary !== ""
            ? moment(summaryState.syncSummary).format("hh:mm A")
            : "sync"}
          <SyncIcon style={{ color: "#555", fontSize: 14, marginLeft: 5 }} />
        </Typography>
      </Box>
      <SummaryConfig classes={classes} />

      {/* Summary Body Container */}
      <Grid container spacing={3}>
        <Grid item md={9}>
          <BarChartSection classes={classes} />
          <br />
          <TableSection classes={classes} />
        </Grid>
        <Grid item md={3}>
          <Businessprofile classes={classes} />
          <br />
          <OverviewAverage classes={classes} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryType;
