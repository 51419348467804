import React, { useContext, useState } from "react";
import {
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Box,
  Divider,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import TimelapseRoundedIcon from "@material-ui/icons/TimelapseRounded";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import classNames from "classnames";
import { navigate } from "hookrouter";

import { PROJECT_TEAM_DETAILS } from "../../context/types";
import ContextProject from "../../context/ContextProject";

import DialogDeleteProject from "../dialogs/DialogDeleteProject";
import DialogCreateSubProject from "../dialogs/DialogCreateSubProject";
import DialogEditProject from "../dialogs/DialogEditProject";
import DialogImportProject from "../dialogs/DialogImportProject";

import iconImport from "../../../../assets/images/icon_import.png";
import DialogSetCompleteProject from "../dialogs/DialogSetCompleteProject";
import DialogUpdateFrequency from "../dialogs/DialogUpdateFrequency";
import DialogDuplicateProject from "../dialogs/DialogDuplicateProject";

const AnchorProject = ({ classes, project }) => {
  const { projectTDispatch } = useContext(ContextProject);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditProject, setOpenEditProject] = useState(false);
  const [openImportProject, setOpenImportProject] = useState(false);
  const [openDeleteProject, setOpenDeleteProject] = useState(false);
  const [openCreateSubProject, setOpenCreateSubProject] = useState(false);
  const [openSetComplete, setOpenSetComplete] = useState(false);
  const [openUpdateFrequency, setOpenUpdateFrequency] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    navigate(`/project/${project.id}`);
    localStorage.setItem("project_details", JSON.stringify(project));
    projectTDispatch({
      type: PROJECT_TEAM_DETAILS,
      payload: { projectDetails: project },
    });
    handleClose();
  };

  const handleEditProject = () => {
    setOpenEditProject(true);
    handleClose();
  };

  const handleImportProject = () => {
    setOpenImportProject(true);
    handleClose();
  };

  const handleSetComplete = () => {
    setOpenSetComplete(true);
    handleClose();
  };

  const handleUpdateFrequency = () => {
    setOpenUpdateFrequency(true);
    handleClose();
  };

  const handleDuplicate = () => {
    setOpenDuplicate(true);
    handleClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleShow}>
        <MoreVertIcon fontSize="small" style={{ color: "#555555" }} />
      </IconButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    onClick={handleViewDetails}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <VisibilityIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>View Details</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={handleEditProject}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <EditOutlinedIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>Edit Project</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleImportProject}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        src={iconImport}
                        height={14}
                        style={{ marginRight: 7 }}
                      />
                      <span style={{ color: "#555" }}>Import Project</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleDuplicate}
                  >
                    <Box display="flex" alignItems="center">
                      <FileCopyOutlinedIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>Duplicate Project</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleSetComplete}
                  >
                    <Box display="flex" alignItems="center">
                      <DoneRoundedIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>Set Complete</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={handleUpdateFrequency}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <TimelapseRoundedIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>Update Frequency</span>
                    </Box>
                  </MenuItem>
                  <Divider className={classes.dropdownDividerItem} />
                  <MenuItem
                    onClick={() => {
                      setOpenDeleteProject(true);
                      handleClose();
                    }}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <DeleteIcon
                        style={{
                          color: "#C8203C",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#C8203C" }}>Delete</span>
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogCreateSubProject
        classes={classes}
        openCreateSubProject={openCreateSubProject}
        setOpenCreateSubProject={setOpenCreateSubProject}
      />
      <DialogEditProject
        classes={classes}
        openEditProject={openEditProject}
        setOpenEditProject={setOpenEditProject}
        goal={project}
      />
      <DialogImportProject
        classes={classes}
        project={project}
        openImportProject={openImportProject}
        setOpenImportProject={setOpenImportProject}
      />
      <DialogDeleteProject
        classes={classes}
        origin="project"
        openDeleteProject={openDeleteProject}
        setOpenDeleteProject={setOpenDeleteProject}
        project={project}
      />
      <DialogSetCompleteProject
        classes={classes}
        project={project}
        openSetComplete={openSetComplete}
        setOpenSetComplete={setOpenSetComplete}
      />
      <DialogUpdateFrequency
        classes={classes}
        goal={project}
        openUpdateFrequency={openUpdateFrequency}
        setOpenUpdateFrequency={setOpenUpdateFrequency}
      />
      <DialogDuplicateProject
        classes={classes}
        project={project}
        openDuplicate={openDuplicate}
        setOpenDuplicate={setOpenDuplicate}
      />
    </>
  );
};

export default AnchorProject;
