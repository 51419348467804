import React, { useContext, useState } from "react";
import DialogCreate from "./DialogCreate";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../../components/StyledMenuDropdown";
import DialogDelete from "./DialogDelete";
import DialogEdit from "./DialogEdit";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
function ViewTabShift({
  classes,
  isModalProgress,
  setModalProgress,
  dataShiftList,
  setDataShiftList,
  getShiftList,
}) {
  const context = useContext(ContextAttendanceSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterDataShift, setMasterDataShift] = useState({
    fields: {},
    masterBreakCollections: [],
    masterWorkPatternCollections: [],
    masterGMTCollections: [
      {
        id: "GMT+7",
        name: "GMT+7",
      },
      {
        id: "GMT+8",
        name: "GMT+8",
      },
      {
        id: "GMT+9",
        name: "GMT+9",
      },
    ],
  });
  const [dataShift, setDataShift] = useState({
    id: "",
    title: "",
    schedule_in: moment().format("HH:mm"),
    schedule_in_date: moment(),
    schedule_out: moment().format("HH:mm"),
    schedule_out_date: moment(),
    gmt: "GMT+7",
    gmt_obj: "GMT+7",
    work_pattern_id: "",
    work_pattern: {},
    break: [],
    break_obj: [{}],
    late_tolerance_status: 0,
    late_tolerance: 0,
    early_tolerance_status: 0,
    early_tolerance: "",
    clock_out_late_tolerance_status: 0,
    clock_out_late_tolerance: 0,
    clock_out_early_tolerance_status: 0,
    clock_out_early_tolerance: "",
    cycle_time: "",
  });

  const resetDataShift = () => {
    let dataShiftTemporary = dataShift;
    dataShiftTemporary.id = "";
    dataShiftTemporary.title = "";
    dataShiftTemporary.schedule_in = moment().format("HH:mm");
    dataShiftTemporary.schedule_in_date = moment();
    dataShiftTemporary.schedule_out = moment().format("HH:mm");
    dataShiftTemporary.schedule_out_date = moment();
    dataShiftTemporary.gmt = "GMT+7";
    dataShiftTemporary.gmt_obj = masterDataShift.masterGMTCollections[0];
    dataShiftTemporary.work_pattern_id = "";
    dataShiftTemporary.work_pattern = {};
    if (masterDataShift.masterBreakCollections.length <= 0) {
      dataShiftTemporary.break = [];
      dataShiftTemporary.break_obj = [];
    } else {
      dataShiftTemporary.break = [];
      dataShiftTemporary.break_obj = [{}];
    }
    dataShiftTemporary.late_tolerance_status = 0;
    dataShiftTemporary.late_tolerance = 0;
    dataShiftTemporary.early_tolerance_status = 0;
    dataShiftTemporary.early_tolerance = "";
    dataShiftTemporary.clock_out_late_tolerance_status = 0;
    dataShiftTemporary.clock_out_late_tolerance = 0;
    dataShiftTemporary.clock_out_early_tolerance_status = 0;
    dataShiftTemporary.clock_out_early_tolerance = "";
    dataShiftTemporary.cycle_time = "";
    setDataShift({...dataShiftTemporary});
  }
  
  const handleShowModalCreate = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/shift/create`)
      .then(function (response) {
        resetDataShift();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataShift;
        masterData.fields = response.data.data.fields;
        masterData.masterBreakCollections =
          response.data.data.masterBreakCollections;
        masterData.masterWorkPatternCollections =
          response.data.data.masterWorkPatternCollections;
        setMasterDataShift(masterData);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const createShift = () => {
    context.setModalProgress(true);
    setLoader(true);
    let data = {
      Shift: dataShift,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/shift/create`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(false);
        context.setMessages("Data shift berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetDataShift();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataShiftList.options.paging.limit * newPage,
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 0, parseInt(event.target.value));
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDelete = (e, item) => {
    handleDropdownClose();
    let dataShiftTemporary = dataShift;
    dataShiftTemporary.id = item.id;
    dataShiftTemporary.title = item.title;
    setDataShift({ ...dataShiftTemporary });
    context.setModalDelete(true);
  };

  const handleDelete = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/human-resource/shift/${dataShift.id}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalDelete(false);
          context.setMessages("Berhasil di hapus !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, 0, 0, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleShowModalEdit = (e, item) => {
    handleDropdownClose();
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/shift/${item.id}/update`)
      .then(function (response) {
        resetDataShift();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataShift;
        masterData.fields = response.data.data.fields;
        masterData.masterBreakCollections =
          response.data.data.masterBreakCollections;
        masterData.masterWorkPatternCollections =
          response.data.data.masterWorkPatternCollections;
        setMasterDataShift(masterData);
        getDataShift(item);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const getDataShift = (item) => {
    axiosConfig
    .get(`${URL_API}/human-resource/shift/${item.id}`)
    .then(function (response) {
      resetDataShift();
      console.log("Response Original : ", response.data);
      let momentNow                       = moment();
      let dataShiftTemporary              = dataShift;
      dataShiftTemporary.id               = response.data.data.id;
      dataShiftTemporary.title            = response.data.data.title;
      dataShiftTemporary.schedule_in      = response.data.data.schedule_in ? response.data.data.schedule_in : moment().format("HH:mm");;
      dataShiftTemporary.schedule_in_date = response.data.data.schedule_in ? 
        moment(momentNow.format('YYYY-MM-DD') + ' ' + response.data.data.schedule_in, 'YYYY-MM-DD HH:mm') : moment();
      dataShiftTemporary.schedule_out     = response.data.data.schedule_out ? response.data.data.schedule_out : moment().format("HH:mm");;
      dataShiftTemporary.schedule_out_date= response.data.data.schedule_out ? 
        moment(momentNow.format('YYYY-MM-DD') + ' ' + response.data.data.schedule_out, 'YYYY-MM-DD HH:mm') : moment();
      dataShiftTemporary.gmt              = response.data.data.gmt;
      dataShiftTemporary.cycle_time       = response.data.data.cycle_time ? response.data.data.cycle_time : '';
      if (dataShiftTemporary.gmt) {
        masterDataShift.masterGMTCollections.map((item) => {
          if (item.id === dataShiftTemporary.gmt) {
            dataShiftTemporary.gmt_obj = item;
          }
        })
      }

        dataShiftTemporary.work_pattern_id = response.data.data.work_pattern_id;
        if (dataShiftTemporary.work_pattern_id) {
          masterDataShift.masterWorkPatternCollections.map((item) => {
            if (item.id === dataShiftTemporary.work_pattern_id) {
              dataShiftTemporary.work_pattern = item;
            }
          });
        }
        dataShiftTemporary.break = [];
        if (response.data.data.breaks) {
          response.data.data.breaks.map((item) => {
            dataShiftTemporary.break.push(item.id);
          });
        }
        dataShiftTemporary.break_obj = response.data.data.breaks;
        dataShiftTemporary.late_tolerance_status =
          response.data.data.late_tolerance_status;
        dataShiftTemporary.late_tolerance = response.data.data.late_tolerance;
        dataShiftTemporary.early_tolerance_status =
          response.data.data.early_tolerance === "" ||
          response.data.data.early_tolerance === null
            ? 0
            : 1;
        dataShiftTemporary.early_tolerance = response.data.data.early_tolerance;

        dataShiftTemporary.clock_out_late_tolerance_status =
          response.data.data.clock_out_late_tolerance_status;
        dataShiftTemporary.clock_out_late_tolerance =
          response.data.data.clock_out_late_tolerance;
        dataShiftTemporary.clock_out_early_tolerance_status =
          response.data.data.clock_out_early_tolerance === "" ||
          response.data.data.clock_out_early_tolerance === null
            ? 0
            : 1;
        dataShiftTemporary.clock_out_early_tolerance =
          response.data.data.clock_out_early_tolerance;

        setDataShift({ ...dataShiftTemporary });
        context.setModalEdit(true);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalEdit(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleUpdate = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      let data = {
        Shift: dataShift,
      };
      axiosConfig
        .put(URL_API + `/human-resource/shift/${dataShift.id}`, data)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalEdit(false);
          context.setMessages("Berhasil di edit !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, null, null, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const refreshList = (search, offset, page, limit) => {
    let dataShiftListTemporary = dataShiftList;
    dataShiftListTemporary.options.filter.search =
      search !== null ? search : dataShiftListTemporary.options.filter.search;
    dataShiftListTemporary.options.paging.offset =
      offset !== null ? offset : dataShiftListTemporary.options.paging.offset;
    dataShiftListTemporary.options.paging.page =
      page !== null ? page : dataShiftListTemporary.options.paging.page;
    dataShiftListTemporary.options.paging.limit =
      limit !== null ? limit : dataShiftListTemporary.options.paging.limit;
    setDataShiftList({ ...dataShiftListTemporary });
    getShiftList();
  };

  const addBreak = () => {
    let dataShiftTemporary = dataShift;
    dataShiftTemporary.break_obj = dataShift.break_obj;
    dataShiftTemporary.break_obj.push({});
    dataShiftTemporary.break.push("");
    setDataShift({ ...dataShiftTemporary });
  };

  const deleteBreak = (index) => {
    let dataShiftTemporary = dataShift;
    dataShiftTemporary.break_obj = dataShift.break_obj;
    if (dataShiftTemporary.break_obj.length > 0) {
      dataShiftTemporary.break_obj.splice(index, 1);
      dataShiftTemporary.break.splice(index, 1);
    }
    setDataShift({ ...dataShiftTemporary });
  };

  const addSchedule = () => {
    let dataShiftTemporary = dataShift;
    dataShiftTemporary.schedule_obj = dataShift.schedule_obj;
    dataShiftTemporary.schedule_obj.push({});
    dataShiftTemporary.schedule.push("");
    setDataShift({ ...dataShiftTemporary });
  };

  const deleteSchedule = (index) => {
    let dataShiftTemporary = dataShift;
    dataShiftTemporary.schedule_obj = dataShift.schedule_obj;
    if (dataShiftTemporary.schedule_obj.length > 0) {
      dataShiftTemporary.schedule_obj.splice(index, 1);
      dataShiftTemporary.schedule.splice(index, 1);
    }
    setDataShift({ ...dataShiftTemporary });
  };

  return (
    <div>
      <Box>
        <Grid container spacing={3} style={{ marginBottom: "1em" }}>
          <Grid item md={6}>
            <Typography
              component={"span"}
              style={{ display: "inline-block", marginTop: "3px" }}
              className={classes.title333}
            >
              <b>Data Shift</b>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                handleShowModalCreate();
              }}
            >
              Buat Shift
            </Button>
          </Grid>
        </Grid>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            classes={classes}
            numSelected={selected.length}
            dataShiftList={dataShiftList}
            setDataShiftList={setDataShiftList}
            getShiftList={getShiftList}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={dataShiftList.list.length}
            />

            {dataShiftList &&
              dataShiftList.list &&
              dataShiftList.list.length > 0 && (
                <TableBody>
                  {dataShiftList.list.map((item, i) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                  inputProps={{ "aria-label": "select all desserts" }}
                                /> */}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {dataShiftList.options.paging.page *
                              dataShiftList.options.paging.limit +
                              i +
                              1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.title}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.workPattern ? item.workPattern.name : ""}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDropdownOpen(e, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl && anchorIn === i)}
                            onClose={(e) => handleDropdownClose(e, i)}
                          >
                            <StyledMenuItem
                              onClick={(e) => handleShowModalEdit(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Edit</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={(e) => handleShowModalDelete(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Hapus</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

            {dataShiftList &&
              dataShiftList.list &&
              dataShiftList.list.length <= 0 &&
              !isLoaderEmpty && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title333}
                          style={{
                            marginTop: 16,
                            marginBottom: 16,
                          }}
                        >
                          <b>Data shift tidak tersedia</b>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.paginationTimeManagement}
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  colSpan={6}
                  count={dataShiftList.options.paging.count}
                  rowsPerPage={dataShiftList.options.paging.limit}
                  page={dataShiftList.options.paging.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  labelRowsPerPage="Baris setiap halaman"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataShift={masterDataShift}
        setMasterDataShift={setMasterDataShift}
        dataShift={dataShift}
        setDataShift={setDataShift}
        listError={context.listError}
        addBreak={addBreak}
        deleteBreak={deleteBreak}
        addSchedule={addSchedule}
        deleteSchedule={deleteSchedule}
        createShift={createShift}
      />
      <DialogEdit
        classes={classes}
        isModalEdit={context.isModalEdit}
        setModalEdit={context.setModalEdit}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataShift={masterDataShift}
        setMasterDataShift={setMasterDataShift}
        dataShift={dataShift}
        setDataShift={setDataShift}
        listError={context.listError}
        addBreak={addBreak}
        deleteBreak={deleteBreak}
        addSchedule={addSchedule}
        deleteSchedule={deleteSchedule}
        handleUpdate={handleUpdate}
      />
      <DialogDelete
        classes={classes}
        isModalDelete={context.isModalDelete}
        setModalDelete={context.setModalDelete}
        dataShift={dataShift}
        setDataShift={setDataShift}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ViewTabShift;
