import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";

import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";

const DialogSetCompleteProject = ({
  classes,
  project,
  openSetComplete,
  setOpenSetComplete,
}) => {
  const { setTriggerProject } = useContext(ContextProject);

  const [loading, setLoading] = useState(false);

  const [projectData, setProjectData] = useState([]);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openSetComplete) {
      AxiosConfig.get(`${URL_API}/project/${project.id}/complete`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setProjectData(result?.fields?.entity_list?.default_value);
          }
        })
        .catch((error) => {
          // console.log("Error", error);
        });
    }
  }, [openSetComplete]);

  const handleSetComplete = () => {
    setLoading(true);

    const data = {
      project: true,
      entity_list: projectData,
    };

    AxiosConfig.post(`${URL_API}/project/${project.id}/complete`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerProject(Math.floor(Math.random() * 100));
          setOpenSetComplete(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openSetComplete}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Set Complete Project</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Apakah Anda yakin ingin mengubah <b>{project?.name}</b> menjadi
            Complete ?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenSetComplete(false)}
        >
          Cancel
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleSetComplete}
        >
          {loading ? "Applying..." : "Set Complete"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogSetCompleteProject;
