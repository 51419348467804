import React, { useContext, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Slide,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";

// Externam Component Utilities
import CircleInPercentForExsponent from "../../../../components/CircleInPercentForExsponent";
import CircleInPercentNEW from "../../../../components/CircleInPercentNEW";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
// import FormatTitleCase from "../../../../utilities/FormatTitleCase";

const ListCardItemMeasureActivityv2 = ({
  classes,
  item,
  handleDetail,
  setSelected,
  selected,
  isSelected,
}) => {
  const context = useContext(ContextGoalDetail);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;
  /* ===============================================
      STATE COMPONENT
     ================================================= */

  const [anchorEl, setAnchorEl] = useState(null);

  // Function Show Dropdown List
  function handleDropdownOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
  }

  //   Handle Dialog Set As Complete
  const handleDialogSetAsComplete = () => {
    handleDropdownClose();
    // setOpenDialogSetAsComplete(true);
    // setOpenDialogInfoSchedule(true);
    // setOriginSchedule("complete");
  };

  // Function For Handling Dialog DUPLICATE is Show or Not
  function handleDialogDuplicate() {
    handleDropdownClose();
    // setModalDuplicate(true);
  }

  // Function For Handling Dialog Delete Goal
  function handleDialogDelete() {
    handleDropdownClose();
    // setModalDeleteMA(true);
    // setOpenDialogConfirmWeight(true);
    // setOpenDialogInfoSchedule(true);
    // setOriginSchedule("delete");
  }

  // Function For Handling Dialog Archive Goal
  function handleDialogArchive() {
    handleDropdownClose();
    // setOpenDialogArchive(true);
  }

  const handleChangeCheckbox = (e, item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // // console.log("SELECTEDZ ", newSelected);

    setSelected(newSelected);
  };

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
    =============================================================================================== */

  let viewDetailAllowed = false;
  let deleteAllowed = false;
  let setCompleteAllowed = false;

  item.policy &&
    item.policy.map((isAllowed) => {
      if (isAllowed.actionName === "viewDetail") {
        viewDetailAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "delete") {
        deleteAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "completed") {
        setCompleteAllowed = isAllowed.result;
      }
    });

  const isItemSelected = isSelected(item.id);

  let INTERVAL_COLOR;
  let INTERVAL_BG;

  if (item?.update_frequency?.interval === "hourly") {
    INTERVAL_BG = "#D7E4F7";
    INTERVAL_COLOR = "#576FC2";
  } else if (item?.update_frequency?.interval === "daily") {
    INTERVAL_BG = "#D7F3F4";
    INTERVAL_COLOR = "#009345";
  } else if (item?.update_frequency?.interval === "weekly") {
    INTERVAL_BG = "#FBE0B3";
    INTERVAL_COLOR = "#FAA922";
  } else if (item?.update_frequency?.interval === "monthly") {
    INTERVAL_BG = "#FDDBD8";
    INTERVAL_COLOR = "#C8203C";
  }

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Paper className={classes.shadowSection}>
        <Box
          p={1}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Box width={50}>
              <Checkbox
                size="small"
                disabled={!setCompleteAllowed}
                checked={isItemSelected}
                checkedIcon={
                  <CheckBoxRoundedIcon
                    style={{ color: "#0F68D2", fontSize: 18 }}
                  />
                }
                icon={
                  <CheckBoxOutlineBlankRoundedIcon
                    style={{ color: "#0F68D2", fontSize: 18 }}
                  />
                }
                onClick={(e) => handleChangeCheckbox(e, item)}
              />
            </Box>
            <Box width={70} ml={6}>
              <div className={classes.circularBeside}>
                {item.calculatedValue.resultOriented.overall_result_achievement
                  .toString()
                  .indexOf("e") !== -1 ? (
                  <CircleInPercentForExsponent classes={classes} item={item} />
                ) : (
                  <CircleInPercentNEW classes={classes} item={item} />
                )}
              </div>
            </Box>
            <Box ml={5} minWidth={350}>
              <Tooltip title={item.name}>
                <Typography
                  variant="subtitle1"
                  className={classes.toDetails}
                  onClick={(e) => handleDetail(e, item)}
                >
                  {/* <b>{FormatTitleCase(item.name)}</b> */}
                  <b>{TruncateTextCustom(item.name, 43)}</b>
                </Typography>
              </Tooltip>
              <Typography variant="caption" className={classes.title333}>
                {item.period !== null && item.period.name}

                {item.start_date !== null &&
                  item.end_date !== null &&
                  item.period === null && (
                    <span>
                      {moment(item.start_date).format("DD MMMM") +
                        " - " +
                        moment(item.end_date).format("DD MMMM YYYY")}
                    </span>
                  )}

                <span>
                  {item.start_date == null &&
                    item.end_date !== null &&
                    item.period == null &&
                    moment(item.end_date).format("DD MMMM YYYY")}
                </span>
                <span
                  style={{
                    color: INTERVAL_COLOR,
                    backgroundColor: INTERVAL_BG,
                    borderRadius: 5,
                    padding: "2px 10px",
                    marginLeft: 10,
                  }}
                >
                  {item?.update_frequency?.interval}
                </span>
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              minWidth={250}
              maxWidth={350}
            >
              {item.member !== null &&
              item.member.photo_url !== null &&
              item.member.self.rel.photo_url !== null ? (
                <ImageSmallUser
                  item={item.member.self.rel.photo_url}
                  index={1}
                />
              ) : (
                <AccountCircleRoundedIcon style={{ color: "#c4c5c4" }} />
              )}

              <Box ml={2}>
                <Typography variant="subtitle1" className={classes.title333}>
                  {TruncateTextCustom(
                    `${item?.member?.first_name} ${item?.member?.last_name}`,
                    17
                  )}
                </Typography>
                <Typography variant="caption" className={classes.title888}>
                  <span>
                    {item.owner
                      ? TruncateTextCustom(
                          item?.owner?.structure_position_title_name,
                          18
                        )
                      : "-"}
                  </span>
                </Typography>
              </Box>
            </Box>

            <Box width={175} ml={4}>
              <Typography variant="subtitle1" className={classes.title333}>
                {FormatDecimal(
                  item.calculatedValue.resultOriented.result_value
                )}
              </Typography>
            </Box>

            <Box width={150} ml={4}>
              <Typography variant="subtitle1" className={classes.title333}>
                {item.last_measured_activity_result_date !== null
                  ? moment(item.last_measured_activity_result_date).format(
                      "DD MMMM YYYY"
                    )
                  : "-"}
              </Typography>
              <Typography variant="caption" className={classes.title333}>
                {item.last_measured_activity_result_date !== null
                  ? moment(item.last_measured_activity_result_date).format(
                      "h:mm:ss a"
                    )
                  : "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Slide>
  );
};

export default ListCardItemMeasureActivityv2;
