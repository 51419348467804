import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Tooltip,
} from "@material-ui/core";

import AvatarDummy from "../../../../assets/images/Avatar_dummy.png";

// import axios from 'axios';
// import { URL_API } from '../../../../constants/config-api';

const DialogModalDetail = (props) => {
  const { classes, isChooseDetail, setChooseDetail, dataDetailUser } = props;

  const [name, setName] = useState("***");
  const [email, setEmail] = useState("");

  const [listMasterRole, setListMasterRole] = useState([]);

  useEffect(() => {
    if (isChooseDetail == true) {
      // setNamaAnggotaKeluarga(dataDetailDelete.name);
      if (dataDetailUser !== null) {
        // console.log("dataDetailUser : ", dataDetailUser);

        setEmail(dataDetailUser.email);
        setName(dataDetailUser.name);

        setListMasterRole(dataDetailUser.masterRole);
      }
    }
  }, [isChooseDetail]);

  return (
    <Dialog
      open={isChooseDetail}
      onClose={() => setChooseDetail(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "black" }}
        >
          <b>Detail User</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List className={classes.listRoot}>
          <ListItem
            // dense
            style={{ paddingLeft: 0 }}
            // button
            // aria-checked={isItemSelected}
            // selected={isItemSelected}
            // onClick={(e) => handleClickCheckbox(e, item)}
          >
            <img
              src={AvatarDummy}
              alt="Gear Picture"
              className={classes.imageAvatar}
            />
            &nbsp;&nbsp;
            <ListItemText
              className={classes.listItemtext}
              primary={
                <Typography variant="subtitle2" className={classes.title}>
                  <b>{name !== undefined ? name : "***"} </b>
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2" className={classes.title}>
                  {email}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton style={{ color: "#cc0707" }}>
                <Tooltip title="Edit" placement="top">
                  <i className="material-icons">edit</i>
                </Tooltip>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <hr style={{ borderColor: "#c4c1c1", opacity: 0.6 }} />

          <ListItem
          // style={{paddingLeft: 0, marginRight: 24}}
          >
            <ListItemText
              // style={{ marginRight: 72}}
              // className={classes.listItemtext}
              primary={
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Role : </b>
                </Typography>
              }
            />
          </ListItem>

          <ListItem>
            {listMasterRole.length > 0 &&
              listMasterRole.map((item, i) => {
                return (
                  <Chip
                    style={{
                      fontFamily: "Roboto",
                      backgroundColor: "#f3bbc4",
                      color: "#cc0707",
                      fontWeight: "bold",
                    }}
                    label={item.name}
                  />
                );
              })}
          </ListItem>
        </List>
        <DialogContentText id="alert-dialog-description">
          {/* <Typography variant='subtitle1' className={classes.title} style={{color: 'black'}}>
                        <b>Apakah Anda yakin ingin menghapus ?</b>
                    </Typography> */}
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        {/* 
                    <Button 
                        onClick={handleDelete}
                        variant='contained' 
                        className={classes.buttonModalDelete}
                        fullWidth
                    >  
                        Yakin
                    </Button> 
                */}
      </DialogActions>
    </Dialog>
  );
};

export default DialogModalDetail;
