import React, { useReducer, useState } from "react";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextAttendanceReportAdmin from "./ContextAttendanceReportAdmin";
import moment from "moment";

const ContextGlobalAttendanceReportAdmin = (props) => {
  /*
  ````````````````````
  REPORT STATE
  ````````````````````
  */
  const [reportState, setReportState] = useState({
    reportCategories: [
      {
        id: "summary",
        name: "Summary",
      },
      {
        id: "detailed",
        name: "Detailed",
      },
    ],
    valueTab: 0,
  });

  /*
  ````````````````````
  ATTENDANCE REPORT ADMIN
  ````````````````````
  */
  const [masterFilterReportSummary, setMasterFilterReportSummary] = useState({
    users: [],
    positions: [],
    unit_types: [],
    units: [],
    // locations: [],
    status: [],
    work_patterns: [],
    shifts: [],
    schedules: [],
    work_locations: [],
  });
  const [masterFilterReportDetail, setMasterFilterReportDetail] = useState({
    users: [],
    unit_types: [],
    units: [],
    positions: [],
    work_patterns: [],
    schedules: [],
    shifts: [],
  });
  const [masterGroupByReportList, setMasterGroupByReportList] = useState([]);
  const [dataSettingSummary, setDataSettingSummary] = useState({
    date_range: {
      start_date  : moment().startOf('month').format('YYYY-MM-DD'),
      end_date    : moment().format('YYYY-MM-DD')
    },
    date_range_obj: {
      title: "This Month",
      code: "this_month",
      start_date: new Date(moment().startOf("months").format('YYYY-MM-DD HH:mm:ss')),
      end_date: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
    },
    group: "staff_name",
    group_selected: "staff_name",
    filter: {
      unit: [],
      user: [],
      position: [],
      work_pattern: [],
      schedule: [],
      shift: [],
      unit_name: [],
      user_name: [],
      position_name: [],
      work_pattern_name: [],
      schedule_name: [],
      shift_name: [],
    },
    order_by: {
      field: "name",
      method: "ASC",
    },
    pagination: {
      limit: 5,
      offset: 0,
      count: 0,
      page: 1,
    },
    paging: {
      limit: 5,
      offset: 0,
      count: 0,
      page: 1,
    },
  });
  const [dataSettingDetail, setDataSettingDetail] = useState({
    date_range: {
      start_date  : moment().startOf('month').format('YYYY-MM-DD'),
      end_date    : moment().format('YYYY-MM-DD')
    },
    date_range_obj: {
      title: "This Month",
      code: "this_month",
      start_date: new Date(moment().startOf("months").format('YYYY-MM-DD HH:mm:ss')),
      end_date: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
    },
    filter: {
      user: [],
      unit: [],
      position: [],
      work_pattern: [],
      schedule: [],
      shift: [],
      unit_name: [],
      user_name: [],
      position_name: [],
      work_pattern_name: [],
      schedule_name: [],
      shift_name: [],
    },
    order_by: {
      field: "user_name",
      method: "ASC",
    },
    paging: {
      limit: 5,
      offset: 0,
      count: 0,
      page: 1,
    },
  });
  const [dataReportSummaryList, setDataReportSummaryList] = useState({
    list: [],
  });
  const [dataReportDetailList, setDataReportDetailList] = useState({
    list: [],
  });
  const getReportSummaryList = () => {
    setModalProgress(true);
    setLoader(true);
    let setting = dataSettingSummary;
    setting.pagination = dataSettingSummary.paging;
    axiosConfig
      .post(`${URL_API}/human-resource/report-account/attendance/summary`, {
        setting: setting,
        filter_by: dataSettingSummary.filter,
        order_by: dataSettingSummary.order_by,
      })
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        if (response.data.info.status === 200) {
          let dataResponse = response.data.data;
          let dataReportListTemp = dataReportSummaryList;
          dataReportListTemp.list = dataResponse.result;
          setDataReportSummaryList({ ...dataReportListTemp });

          let dataSettingTemp = dataSettingSummary;
          dataSettingTemp.paging.limit = dataResponse.option.setting.pagination.limit;
          dataSettingTemp.paging.count = dataResponse.option.setting.pagination.total;
          dataSettingTemp.paging.page  = dataResponse.option.setting.pagination.page;
          dataSettingTemp.group_selected = dataSettingTemp.group;
          setDataSettingSummary({ ...dataSettingTemp });

          let masterFilterReportTemp = masterFilterReportSummary;
          masterFilterReportTemp.users =
            dataResponse.option.master.filter.users;
          masterFilterReportTemp.positions =
            dataResponse.option.master.filter.positions;
          masterFilterReportTemp.schedules =
            dataResponse.option.master.filter.schedules;
          masterFilterReportTemp.shifts =
            dataResponse.option.master.filter.shifts;
          masterFilterReportTemp.work_patterns =
            dataResponse.option.master.filter.work_patterns;
          try {
            masterFilterReportTemp.unit_types = dataResponse.option.master
              .filter.unit_types
              ? dataResponse.option.master.filter.unit_types
              : [];
          } catch (error) {
            masterFilterReportTemp.unit_types = [];
          }
          masterFilterReportTemp.units =
            dataResponse.option.master.filter.units;
          setMasterFilterReportSummary({ ...masterFilterReportTemp });

          let masterGroupByReportSummaryTemp = [];
          dataResponse.option.master.group.map((item) => {
            masterGroupByReportSummaryTemp.push({
              id: item,
              name: item.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
                c ? c.toUpperCase() : " " + d.toUpperCase()
              ),
              val: item,
            });
            return 0;
          });
          setMasterGroupByReportList(masterGroupByReportSummaryTemp);
          // console.log("masterGroupByReportSummaryTemp", masterGroupByReportSummaryTemp);
        }
        setLoader(false);
        setModalProgress(false);
      })
      .catch(function (error) {
        setLoader(false);
        setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            setErrorStatus(429);
            setTextErrorInformation("Too Many Request !");
            setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            setErrorStatus(error.response.status);
            setTextErrorInformation(error.response.data.info.message);
            setOpenDialogError(true);
          } else {
            setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };
  const getReportDetailList = () => {
    setModalProgress(true);
    setLoader(true);
    let setting = dataSettingDetail;
    setting.pagination = dataSettingDetail.paging;
    axiosConfig
      .post(`${URL_API}/human-resource/report-account/attendance/detail`, {
        setting: setting,
        order_by: dataSettingDetail.order_by,
      })
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        setModalProgress(false);
        if (response.data.info.status === 200) {
          let dataResponse = response.data.data;
          let dataReportListTemp = dataReportDetailList;
          dataReportListTemp.list = dataResponse.result;
          setDataReportDetailList({ ...dataReportListTemp });

          let dataSettingTemp = dataSettingDetail;
          dataSettingTemp.paging.limit = dataResponse.option.setting.pagination.limit;
          dataSettingTemp.paging.count = dataResponse.option.setting.pagination.total;
          dataSettingTemp.paging.page  = dataResponse.option.setting.pagination.page;
          setDataSettingDetail({ ...dataSettingTemp });

          let masterFilterReportDetailTemp = masterFilterReportDetail;
          masterFilterReportDetailTemp.users =
            dataResponse.option.master.filter.users;
          try {
            masterFilterReportDetailTemp.unit_types = dataResponse.option.master
              .filter.unit_types
              ? dataResponse.option.master.filter.unit_types
              : [];
          } catch (error) {
            masterFilterReportDetailTemp.unit_types = [];
          }
          masterFilterReportDetailTemp.units =
            dataResponse.option.master.filter.units;
          masterFilterReportDetailTemp.positions =
            dataResponse.option.master.filter.positions;
          masterFilterReportDetailTemp.work_patterns =
            dataResponse.option.master.filter.work_patterns;
          masterFilterReportDetailTemp.schedules =
            dataResponse.option.master.filter.schedules;
          masterFilterReportDetailTemp.shifts =
            dataResponse.option.master.filter.shifts;
          setMasterFilterReportDetail({ ...masterFilterReportDetailTemp });
        }
      })
      .catch(function (error) {
        setLoader(false);
        setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            setErrorStatus(429);
            setTextErrorInformation("Too Many Request !");
            setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            setErrorStatus(error.response.status);
            setTextErrorInformation(error.response.data.info.message);
            setOpenDialogError(true);
          } else {
            setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };
  const exportSummary = () => {
    setModalProgress(true);
    setLoader(true);
    axiosConfig
      .post(`${URL_API}/human-resource/report-account/attendance/summary/export`, {
        setting: dataSettingSummary,
        filter_by: dataSettingSummary.filter,
        order_by: dataSettingSummary.order_by,
      })
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        setModalProgress(false);
        if (response.data.info.status === 200) {
          window.location.href = response.data.data.link;
        }
      })
      .catch(function (error) {
        setLoader(false);
        setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            setErrorStatus(429);
            setTextErrorInformation("Too Many Request !");
            setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            setErrorStatus(error.response.status);
            setTextErrorInformation(error.response.data.info.message);
            setOpenDialogError(true);
          } else {
            setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };
  const exportDetailed = () => {
    setModalProgress(true);
    setLoader(true);
    axiosConfig
      .post(`${URL_API}/human-resource/report-account/attendance/detail/export`, {
        setting: dataSettingDetail,
        order_by: dataSettingDetail.order_by,
      })
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        setModalProgress(false);
        if (response.data.info.status === 200) {
          window.location.href = response.data.data.link;
        }
      })
      .catch(function (error) {
        setLoader(false);
        setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            setErrorStatus(429);
            setTextErrorInformation("Too Many Request !");
            setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            setErrorStatus(error.response.status);
            setTextErrorInformation(error.response.data.info.message);
            setOpenDialogError(true);
          } else {
            setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  const [loader, setLoader] = useState(false);
  const [isModalProgress, setModalProgress] = useState(false);

  /*
  ````````````````````
  HANDLE DIALOG ERRROR
  ````````````````````
  */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  return (
    <ContextAttendanceReportAdmin.Provider
      value={{
        /*
        ````````````````````
        REPORT STATE
        ````````````````````
        */
        reportState: reportState,
        setReportState: setReportState,

        /*
        ````````````````````
        ATTENDANCE REPORT ADMIN
        ````````````````````
        */
        masterFilterReportSummary: masterFilterReportSummary,
        setMasterFilterReportSummary: setMasterFilterReportSummary,
        masterFilterReportDetail: masterFilterReportDetail,
        setMasterFilterReportDetail: setMasterFilterReportDetail,

        masterGroupByReportList: masterGroupByReportList,
        setMasterGroupByReportList: setMasterGroupByReportList,
        dataReportSummaryList: dataReportSummaryList,
        setDataReportSummaryList: setDataReportSummaryList,
        dataSettingSummary: dataSettingSummary,
        setDataSettingSummary: setDataSettingSummary,
        dataReportDetailList: dataReportDetailList,
        setDataReportDetailList: setDataReportDetailList,
        dataSettingDetail: dataSettingDetail,
        setDataSettingDetail: setDataSettingDetail,
        
        getReportSummaryList: getReportSummaryList,
        getReportDetailList: getReportDetailList,
        exportSummary: exportSummary,
        exportDetailed: exportDetailed,

        isModalProgress: isModalProgress,
        setModalProgress: setModalProgress,

        loader: loader,
        setLoader: setLoader,

        /*
        ````````````````````
        HANDLE DIALOG ERRROR
        ````````````````````
        */
        isOpenDialogError: isOpenDialogError,
        setOpenDialogError: setOpenDialogError,

        textErrorInformation: textErrorInformation,
        setTextErrorInformation: setTextErrorInformation,

        errorStatus: errorStatus,
        setErrorStatus: setErrorStatus,

        listError: listError,
        setListError: setListError,
      }}
    >
      {props.children}
    </ContextAttendanceReportAdmin.Provider>
  );
};

export default ContextGlobalAttendanceReportAdmin;
