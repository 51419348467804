import React, { useState, useEffect, Fragment } from "react";
import { Grid, Box, Typography, Paper } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import TreeView from "@material-ui/lab/TreeView";
import { styles } from "./Style/StyleNewSO";
import {
  classificationTopLeader,
  classificationHead,
} from "./Constants/config-classification-preview";

import ComponentDescription from "./Components/ComponentDescription";
import StyledTreeItem from "./Components/StyledTreeItem";
import AvatarIconWithoutLine from "./Components/AvatarIconWithoutLine";
import AvatarIcon from "./Components/AvatarIcon";

import HeaderMembershipOptions from "../../layout/HeaderMembershipOptions";

import { URL_API } from "../../constants/config-api";

const useStyles = makeStyles(styles);

const ViewNewSOTreeview = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [mainRoot, setMainRoot] = useState("1");
  const [treeItems, setTreeItems] = useState([
    {
      id: null,
      parent_id: null,
      masterStructurePositionTitle: {
        name: null,
      },
      classification: {
        id: null,
      },
      masterStructureUnit: {
        name: null,
      },
      masterStructureUnitType: {
        name: null,
      },
    },
  ]);

  const [firstRootId, setFirstRootId] = useState("");

  const [listPreview, setListPreview] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    //*
    const structurePositionTitleName = localStorage.getItem("data_preview_so");
    const structurePositionTitleNameAfterParse = JSON.parse(
      structurePositionTitleName
    );

    //*
    let orgChartParsed = [];

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .get(
          `${URL_API}/human-resource/structure-wizard/create?StructurePosition[top_leader_name]=${structurePositionTitleNameAfterParse.structure_position_title_name}`
        )
        .then((response) => {
          // console.log("Response Original : ", response);
          // orgChartParsed.push(response.data.data)
          setListPreview(response.data.data);
        })
        .catch((error) => {
          // console.log("Error : ", error.response);
        });
    }

    //*Cara old
    const orgCharts = localStorage.getItem("data_preview_so");

    let dataReadyChart = [];
    let dataReadyChartSecond = [];

    // if(orgCharts !== null){
    //   if(orgCharts !== undefined){

    //     const orgChartParsed = JSON.parse(orgCharts);

    //     // console.log("orgChartParsed : ", orgChartParsed);

    //     if(orgChartParsed.length > 0){

    //       orgChartParsed.map((item) => {

    //         if(item.classification.code === classificationTopLeader){

    //           let dataTopLeader = {

    //               id: item.id,
    //               masterStructurePositionTitle: item.masterStructurePositionTitle.name,
    //               masterStructureUnitType: item.masterStructureUnitType  !== null ? item.masterStructureUnitType.name : '-',
    //               masterStructureUnit: item.masterStructureUnit !== null ? item.masterStructureUnit.name : '',
    //               child : []

    //           };

    //           dataReadyChart.push(dataTopLeader);
    //         };

    //         if(item.classification.code === classificationHead){

    //             const dataHeadLeader = {

    //               id: item.id,
    //               // parent_id: item.parent_id,
    //               masterStructurePositionTitle: item.masterStructurePositionTitle.name,
    //               masterStructureUnitType: item.masterStructureUnitType  !== null ? item.masterStructureUnitType.name : '',
    //               masterStructureUnit: item.masterStructureUnit !== null ? item.masterStructureUnit.name : '',
    //               child : []
    //             };

    //             dataReadyChartSecond.push(dataHeadLeader);

    //         };
    //       });

    //       if( dataReadyChartSecond.length > 0 ){

    //         // console.log("dataReadyChartSecond : ", dataReadyChartSecond);

    //         dataReadyChartSecond.map(item => {

    //           dataReadyChart[0].child.push(item);

    //         })
    //       };

    //       // console.log("dataReadyChart : ", dataReadyChart);
    //       setTreeItems(dataReadyChart);

    //     };
    //   };
    // };
  }, []);

  useEffect(() => {
    let dataReadyChart = [];
    let dataReadyChartSecond = [];

    if (listPreview.length > 0) {
      listPreview.map((item) => {
        if (item.classification.code === classificationTopLeader) {
          let dataTopLeader = {
            id: item.id,
            masterStructurePositionTitle:
              item.masterStructurePositionTitle.name,
            masterStructureUnitType:
              item.masterStructureUnitType !== null
                ? item.masterStructureUnitType.name
                : "-",
            masterStructureUnit:
              item.masterStructureUnit !== null
                ? item.masterStructureUnit.name
                : "",
            child: [],
          };

          dataReadyChart.push(dataTopLeader);
        }

        if (item.classification.code === classificationHead) {
          const dataHeadLeader = {
            id: item.id,
            // parent_id: item.parent_id,
            masterStructurePositionTitle:
              item.masterStructurePositionTitle.name,
            masterStructureUnitType:
              item.masterStructureUnitType !== null
                ? item.masterStructureUnitType.name
                : "",
            masterStructureUnit:
              item.masterStructureUnit !== null
                ? item.masterStructureUnit.name
                : "",
            child: [],
          };

          dataReadyChartSecond.push(dataHeadLeader);
        }
      });

      if (dataReadyChartSecond.length > 0) {
        // console.log("dataReadyChartSecond : ", dataReadyChartSecond);

        dataReadyChartSecond.map((item) => {
          dataReadyChart[0].child.push(item);
        });
      }

      // console.log("dataReadyChart : ", dataReadyChart);
      setTreeItems(dataReadyChart);
    }
  }, [listPreview]);

  return (
    <Fragment>
      <HeaderMembershipOptions />

      <Grid container>
        <Grid item sm={1} md={1} lg={1}></Grid>

        <Grid item sm={5} md={5} lg={5}>
          <Box marginTop={theme.spacing(1)}>
            <DataTreeView
              classes={classes}
              treeItems={treeItems}
              mainRoot={mainRoot}
              firstRootId={firstRootId}
            />
          </Box>
        </Grid>

        <Grid item sm={5} md={5} lg={5}>
          <ComponentDescription classes={classes} />
        </Grid>

        <Grid item sm={1} md={1} lg={1}></Grid>
      </Grid>
    </Fragment>
  );
};

export default ViewNewSOTreeview;

const DataTreeView = (props) => {
  const { classes, treeItems, mainRoot, firstRootId } = props;

  return (
    <TreeView
      className={classes.rootPreview}
      defaultExpanded={["0"]} //*Not work
      defaultCollapseIcon={
        mainRoot !== "1" ? (
          <AvatarIcon mainRoot={mainRoot} />
        ) : (
          <AvatarIconWithoutLine />
        )
      }
      defaultExpandIcon={
        mainRoot !== "1" ? (
          <AvatarIcon mainRoot={mainRoot} />
        ) : (
          <AvatarIconWithoutLine />
        )
      }
      defaultEndIcon={<AvatarIcon mainRoot={mainRoot} />}
    >
      {getTreeItemsFromData(treeItems)}
    </TreeView>
  );
};

const getTreeItemsFromData = (treeItems) => {
  return treeItems.map((treeItemData) => {
    // console.log("treeItemData : ", treeItemData);

    // let children = undefined;
    let child = undefined;

    // if (treeItemData.children && treeItemData.children.length > 0) {

    //   children = getTreeItemsFromData(treeItemData.children);

    // };

    if (treeItemData.child && treeItemData.child.length > 0) {
      // children = getTreeItemsFromData(treeItemData.child);
      child = getTreeItemsFromData(treeItemData.child);
    }

    return (
      <Fragment key={treeItemData.id}>
        <StyledTreeItem
          nodeId={treeItemData.id}
          label={
            <Fragment>
              <Paper
                style={{
                  marginLeft: "-24px",
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  backgroundColor: "#f7d9dd",
                  borderLeft: "0.4em solid #d64253",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ marginLeft: 8, fontFamily: "Roboto", color: "red" }}
                >
                  {treeItemData.parent_id !== null
                    ? treeItemData.masterStructureUnitType +
                      " " +
                      treeItemData.masterStructureUnit
                    : "."}
                  {/* { treeItemData.parent_id !== null ? treeItemData.structure_unit_type_name  + " " + treeItemData.structure_unit_name : '.'} */}
                </Typography>
              </Paper>
              <Paper
                style={{
                  marginLeft: "-24px",
                  borderLeft: "0.4em solid #d64253",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  padding: 16,
                  marginBottom: 16,
                  // borderTop: '0.8em solid #f7d9dd',
                  // marginTop: 16,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    marginLeft: "32px",
                    marginBottom: 8,
                    fontFamily: "Roboto",
                  }}
                  // className={classes.title}
                >
                  {treeItemData.parent_id !== null
                    ? treeItemData.masterStructurePositionTitle
                    : "-"}
                  {/* { treeItemData.id !== null ? treeItemData.structure_position_title_name : '-'} */}
                </Typography>
              </Paper>
            </Fragment>
          }
          // children={children}
          children={child}
        />
      </Fragment>
    );
  });
};

// function AvatarIconWithoutLine(props){

//   const classes = useStyles();

//   return (

//     <Box style={{marginLeft: 24}}>
//         <Avatar className={classes.small}  >
//           <PersonIcon style={{fontSize: 17}} />
//         </Avatar>

//     </Box>

//   )
// };

// function AvatarIcon(props){

//   const classes = useStyles();

//   const { mainRoot } = props;

//   return (

//     <Box className={ classes.horizontalLine} style={{marginLeft: 16}}>
//         <Avatar className={classes.small}  >
//           <PersonIcon style={{fontSize: 17}} />
//         </Avatar>

//     </Box>

//   )
// };

/*
    ```````````````````````````````````````````````````````````````````````````````````````
    #TREE VIEW
  
    https://stackoverflow.com/questions/57712610/reactjs-and-material-ui-treeview-can-i-use-a-json-array-of-object-to-populate-t
  
    https://codesandbox.io/s/data-driven-treeview-51hib
  
    ```````````````````````````````````````````````````````````````````````````````````````
  
  */
