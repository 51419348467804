import React, { Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../../Style/StyleGrouping";
import ContextAttendanceReportAdmin from "../../Context/ContextAttendanceReportAdmin";
import SummaryType from "./SummaryType/Index";
import DetailedType from "./DetailedType/Index";

const useStyles = makeStyles(styles);

const Grouping = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceReportAdmin);

  return (
    <Fragment>
        {context.reportState.valueTab === 0 && <SummaryType classes={classes} />}
        {context.reportState.valueTab === 1 && <DetailedType classes={classes} />}
    </Fragment>
  );
};

export default Grouping;
