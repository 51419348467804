import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../../../AM/CompanySetting/Style/StyleCompanySetting";

import icons_alert from "../../../../assets/images/whats_new_ex.png";

const useStyle = makeStyles(styles);

const DialogWhatsNew = ({ openDialogWhatsNew, setOpenDialogWhatsNew }) => {
  const classes = useStyle();
  return (
    <Dialog
      open={openDialogWhatsNew}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" className={classes.title}>
          <b>Fitur Baru Performate - Weighting!</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" className={classes.title}>
          Set MA Weight pada Goals
        </Typography>
        <br />
        <Typography variant="subtitle2" className={classes.title}>
          Dengan memberikan bobot pada Goal/MA, maka user dapat menentukan besar
          faktor kontribusi masing2 MA terhadap sebuah goal dan kontribusi
          masing2 Goal terhadap sebuah user di dalam report.
        </Typography>
        <br />
        <Typography variant="subtitle2" className={classes.title}>
          Pembobotan di goal berpengaruh terhadap penilaian akhir pencapaian
          goal seorang user, dan pembobotan di MA berpengaruh terhadap penilaian
          akhir pencapaian MA untuk masing2 goal user.
        </Typography>

        <Box mt={3} display="flex" justifyContent="center">
          <img src={icons_alert} height={110} />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", margin: 20 }}>
        <Button
          size="small"
          variant="outlined"
          className={classes.button2}
          onClick={() => setOpenDialogWhatsNew(false)}>
          Mengerti
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogWhatsNew;
