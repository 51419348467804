import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";

import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogMoveMultiple({
  classes,
  openDialogMoveMultiple,
  setOpenDialogMoveMultiple,
}) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [loading, setLoading] = useState(false);
  const [loadingTree, setLoadingTree] = useState(false);
  const [listFolders, setListFolders] = useState({});
  const [folderParentId, setFolderParentId] = useState("");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
    SIDE EFFECT
  ==================================================== */
  useEffect(() => {
    if (openDialogMoveMultiple === true) {
      setLoadingTree(true);

      axiosConfig
        .get(`${URL_API}/drive/folder?options[embedded][]=policy`)
        .then((response) => {
          // console.log("Folder Collection", response);

          if (response.status === 200) {
            // Filter By Create Policy
            const filterCreate = response.data.data.map((item) => {
              let pol_temp = false;

              item.policy.forEach((pol) => {
                if (pol.actionName === "create") {
                  // // console.log("POLISI", pol.result);
                  pol_temp = pol.result;
                }
              });

              return {
                ...item,
                policy: pol_temp,
              };
            });

            // add property child_folder
            const folders = filterCreate.map((folder) => {
              folder.child_folder = [];
              return folder;
            });

            // push child folder to parent folder
            const folders2 = folders.map((parent) => {
              folders.forEach((folder) => {
                if (parent.id === folder.folder_parent_id) {
                  parent.child_folder.push(folder);
                }

                return folder;
              });

              return parent;
            });

            // filter for parent folders
            const newFolder = folders2.filter(
              (item) => item.folder_parent_id === null
            );

            // add list folder to rot object
            const newFolderList = {
              id: "",
              name: "Drive",
              child_folder: newFolder,
            };

            // // console.log("Child Parent", newFolderList);
            setListFolders(newFolderList);
            setLoadingTree(false);
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoadingTree(false);
        });
    }
  }, [openDialogMoveMultiple]);

  /* ====================================================
    FUNCTION / LOCAL METHODS
  ==================================================== */
  // GET paren folder ID
  const handleTreeFolder = (id) => {
    // // console.log("THE parent ID", id);
    setFolderParentId(id);
  };

  // render Tree Item
  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      className={classes.title}
      label={
        <Typography
          variant="subtitle2"
          className={classes.title}
          style={{ color: "#555", fontWeight: "600" }}
        >
          {nodes.name}
        </Typography>
      }
      onClick={() => handleTreeFolder(nodes.id)}
    >
      {Array.isArray(nodes.child_folder)
        ? nodes.child_folder.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const handleMoveMultiple = () => {
    //Check If selected Files more than 0
    if (context.selectedFile.length > 0) {
      setLoading(true);

      const params = new URLSearchParams();

      context.selectedFile.map((item, index) => {
        params.append(`DriveFile[${index}][id]`, item);
        params.append(`DriveFile[${index}][folder_id]`, folderParentId);
      });

      axiosConfig
        .put(`${URL_API}/drive/file/batch:move`, params)
        .then((response) => {
          // console.log("Response Move Multiple File", response);

          // If No Folder Selected, Finish The Request
          if (context.selectedFolder.length === 0) {
            context.setSuccessTriggerFile(Math.floor(Math.random() * 1000 + 1));
            context.setModalResponse200(true);
            context.setMessages("Move multiple successfully");

            context.setSelectedFile([]); // Empty Selected File
            setOpenDialogMoveMultiple(false);

            setLoading(false);

            // If There is/are folder(s) selected then rquest to move it
          } else if (context.selectedFolder.length > 0) {
            setLoading(true);

            const params = new URLSearchParams();

            context.selectedFolder.map((item, index) => {
              params.append(`DriveFolder[${index}][id]`, item);
              params.append(
                `DriveFolder[${index}][folder_parent_id]`,
                folderParentId
              );
            });

            axiosConfig
              .put(`${URL_API}/drive/folder/batch:move`, params)
              .then((response) => {
                // // console.log("Response Move Multiple Folder", response);

                context.setSuccessTriggerFolder(
                  Math.floor(Math.random() * 1000 + 1)
                );
                context.setSuccessTriggerFile(
                  Math.floor(Math.random() * 1000 + 1)
                );
                context.setModalResponse200(true);
                context.setMessages("Move multiple successfully");

                context.setSelectedFolder([]); // Empty Selected Folder
                context.setSelectedFile([]); // Empty Selected File
                setOpenDialogMoveMultiple(false);

                setLoading(false);
              })
              .catch((error) => {
                // console.log("Error : ", error);
                setLoading(false);

                // error response
                if (error.response !== undefined) {
                  let responseStatus = error.response.status;

                  setErrorStatus(responseStatus);
                  setOpenDialogError(true);

                  if ([422, 400].includes(responseStatus)) {
                    if (
                      error.response.data.info.errors !== null &&
                      error.response.data.info.errors !== undefined
                    ) {
                      if (error.response.data.info.errors.length > 0) {
                        setOpenDialogError(true);
                        setListError(error.response.data.info.errors);
                      }
                    }
                  }

                  if (
                    [503, 500, 403, 429, 405, 404, 401].includes(responseStatus)
                  ) {
                    if (error.response.data.info.hasOwnProperty("message")) {
                      setTextErrorMessage(
                        `Error ${responseStatus} : ` +
                          error.response.data.info.message
                      );
                    }
                  }
                } else {
                  setOpenDialogError(true);
                  setTextErrorMessage("Whoops, something went wrong !");
                }
              });
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });

      // IF No Files Selected & Folder Selected > 0, Do This!
    } else if (context.selectedFolder.length > 0) {
      setLoading(true);

      const params = new URLSearchParams();

      context.selectedFolder.map((item, index) => {
        params.append(`DriveFolder[${index}][id]`, item);
        params.append(
          `DriveFolder[${index}][folder_parent_id]`,
          folderParentId
        );
      });

      axiosConfig
        .put(`${URL_API}/drive/folder/batch:move`, params)
        .then((response) => {
          // console.log("Response Move Multiple Folder", response);

          context.setSuccessTriggerFolder(Math.floor(Math.random() * 1000 + 1));
          context.setModalResponse200(true);
          context.setMessages("Deleted multiple successfully");

          context.setSelectedFolder([]); // Empty Selected Folder
          context.setSelectedFile([]); // Empty Selected File
          setOpenDialogMoveMultiple(false);

          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  };

  return (
    <Dialog
      open={openDialogMoveMultiple}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Move Folder</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 375 }}>
        <Box marginBottom={2} textAlign="center">
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#333" }}
          >
            Apakah Anda yakin ingin memindahkan{" "}
            <strong>{context.selectedFolder.length}</strong> Folder dan{" "}
            <strong>{context.selectedFile.length}</strong> File yang ditandai ?
          </Typography>
        </Box>

        {loadingTree ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={15} />
          </Box>
        ) : (
          <TreeView
            className={classes.rootTreeView}
            defaultExpanded={[""]}
            defaultCollapseIcon={
              <FolderOpenIcon style={{ color: "#888" }} fontSize="small" />
            }
            defaultExpandIcon={
              <CreateNewFolderIcon style={{ color: "#888" }} fontSize="small" />
            }
            defaultEndIcon={
              <FolderIcon style={{ color: "#888" }} fontSize="small" />
            }
          >
            {renderTree(listFolders)}
          </TreeView>
        )}

        <DialogContentText id="alert-dialog-description">
          <DialogActions
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              className={classes.button2}
              onClick={() => setOpenDialogMoveMultiple(false)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              disabled={
                context.selectedFolder.length === 0 &&
                context.selectedFile.length === 0
              }
              onClick={handleMoveMultiple}
            >
              {loading ? <CircularProgress color="#fff" size={15} /> : "Move"}
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogMoveMultiple;
