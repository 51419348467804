import React, { useState, useEffect, useCallback } from "react";
import { Box, List, Button, Typography, TextField } from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import Pagination from "@material-ui/lab/Pagination";
import { useDebouncedCallback } from "use-debounce";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ListItemUser from "./ListItemUser";
import DialogCreateUser from "./DialogCreateUser";

const UserSettings = ({ classes }) => {
  const [userCollections, setUserCollections] = useState([]);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [triggerUser, setTriggerUser] = useState(null);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    AxiosConfig.get(
      `${URL_API}/account-management/user/quick?options[paging][offset]=${offset}&options[paging][limit]=${perPage}&options[filter][name]=${search}`
    )
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setUserCollections(result);
          setCount(res.data?.info?.count);
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
      });
  }, [triggerUser, page]);

  const handlePagination = (e, value) => {
    setPage(value);
    const offsetPage = (value - 1) * perPage;

    setOffset(offsetPage);
  };

  const [handleSearch] = useDebouncedCallback(
    useCallback((value) => {
      setSearch(value);
      setTriggerUser(Math.floor(Math.random() * 100));
    }, []),
    1000,
    { maxWait: 5000 }
  );

  return (
    <Box>
      <Box p={2}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          placeholder="Search Unit"
          InputProps={{
            startAdornment: <SearchRoundedIcon />,
          }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>
      <Box mb={1} px={1}>
        <List dense>
          {userCollections.length > 0 &&
            userCollections.map((item, i) => {
              return (
                <ListItemUser
                  key={i}
                  item={item}
                  classes={classes}
                  onTriggerUser={setTriggerUser}
                />
              );
            })}
        </List>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          size="small"
          variant="outlined"
          className={classes.button2}
          startIcon={<AddRoundedIcon />}
          onClick={() => setOpenCreateUser(true)}
        >
          Tambah User
        </Button>
      </Box>

      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.title888} variant="caption">
          Records{" "}
          <span
            style={{
              backgroundColor: "#aaa",
              color: "#fff",
              padding: "0 5px",
              borderRadius: 4,
            }}
          >
            <b>{count}</b>
          </span>
        </Typography>

        <Pagination
          size="small"
          variant="text"
          shape="rounded"
          hideNextButton
          hidePrevButton
          page={page}
          count={Math.ceil(count / perPage)}
          onChange={handlePagination}
        />
      </Box>

      <DialogCreateUser
        classes={classes}
        openCreateUser={openCreateUser}
        setOpenCreateUser={setOpenCreateUser}
        onTriggerUser={setTriggerUser}
      />
    </Box>
  );
};

export default UserSettings;
