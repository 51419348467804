import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import ContextAttendanceAccount from "../Context/ContextAttendanceAccount";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const DialogProgress = (props) => {
  const { classes, isModalProgress, setModalProgress, userLoginName } = props;

  const context = useContext(ContextAttendanceAccount);
  return (
    <Fragment>
      <Dialog
        open={isModalProgress}
        onClose={() => setModalProgress(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        scroll="paper"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Grid container>
          <Grid item md={12} xs={12}>
            <DialogContent style={{ textAlign: "left" }}>
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="secondary" />
              </div>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default DialogProgress;
