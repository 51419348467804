import React, { useEffect, useState } from "react";
import {
  Fade,
  Chip,
  Typography,
  Avatar,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AudiotrackRoundedIcon from "@material-ui/icons/AudiotrackRounded";

import TruncateTextShortSuperPendek from "../../../../../utilities/TruncateTextShortSuperPendek";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";

import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../../assets/images/icons_file/Word.png";
import text_icon from "../../../../../assets/images/icons_file/Text.png";

import DialogPreviewPDF from "../DialogPreviewPDF";
import DialogPreviewDocument from "../DialogPreviewDocument";
import DialogDeleteEvidenceMA from "../DialogDeleteEvidenceMA";
import DialogPreviewText from "../../../../Drive/View/components/DialogPreviewText";
import LightTooltip from "../../../../../components/LightTooltip";
import DialogPlayAudio from "../../../Goal/ComponentsGoalResult/DialogPlayAudio";

function DocItem({
  i,
  tile,
  classes,
  showFade,
  deleteAllowed,
  listPictState,
  setListPictState,
  userToken,
}) {
  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [response_type, setResponseType] = useState(null);
  const [dataStringTile, setDataStringTile] = useState("");

  const [loading, setLoading] = useState(false);
  const [openDialogPreviewDocument, setOpenDialogPreviewDocument] =
    useState(false);
  const [openDialogPreviewText, setOpenDialogPreviewText] = useState(false);
  const [openDialogPreviewPDF, setOpenDialogPreviewPDF] = useState(false);
  const [openDialogDeleteEvidence, setOpenDialogDeleteEvidence] =
    useState(false);
  const [isOpenDialogAudioPlayer, setOpenDialogAudioPlayer] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(
        `${URL_API}/swd/measured-activity-result/${tile.measured_activity_result_id}/evidence/${tile.id}/content?mode=thumbnail`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        // console.log("Response Thumbnail", response);
        if (response.status === 200) {
          setResponseType(response.data.type);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);
      });
  }, []);

  let source = "";
  if (response_type !== null) {
    if (response_type === "application/pdf") {
      source = pdf_icon;
    } else if (
      response_type === "application/msword" ||
      response_type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      source = word_icon;
    } else if (
      response_type === "application/vnd.ms-powerpoint" ||
      response_type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      source = ppt_icon;
    } else if (
      response_type === "application/vnd.ms-excel" ||
      response_type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      source = excel_icon;
    } else if (response_type === "text/plain") {
      source = text_icon;
    }
  }

  const handleClick = () => {
    if (response_type !== null) {
      if (
        response_type === "application/vnd.ms-excel" ||
        response_type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        response_type === "application/vnd.ms-powerpoint" ||
        response_type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        response_type === "application/msword" ||
        response_type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setOpenDialogPreviewDocument(true);
      } else if (response_type === "application/pdf") {
        setOpenDialogPreviewPDF(true);
      } else if (response_type === "text/plain") {
        setOpenDialogPreviewText(true);
      }
    }
  };

  const handleOpenDialogAudio = (e, tile) => {
    // console.log("Data Audio : ", tile);

    e.preventDefault();
    setOpenDialogAudioPlayer(true);
    setDataStringTile(tile.self.rel.content);
  };

  return (
    <span key={i}>
      <Fade in={showFade}>
        <Tooltip title={tile.label}>
          <Chip
            avatar={
              source !== "" && loading ? (
                <CircularProgress size={12} style={{ color: "#d1354a" }} />
              ) : source !== "" && !loading ? (
                <Avatar
                  alt={tile.label}
                  src={source}
                  style={{
                    borderRadius: 5,
                    width: 32,
                    border: "2px solid #f2f2f2",
                    margin: 3,
                  }}
                />
              ) : null
            }
            icon={
              response_type === "audio/mpeg" ? (
                <LightTooltip
                  arrow
                  title="Putar Audio sekarang"
                  placement="left"
                >
                  <AudiotrackRoundedIcon style={{ color: "#d1354a" }} />
                </LightTooltip>
              ) : null
            }
            label={
              <Typography variant="caption" className={classes.title}>
                {TruncateTextShortSuperPendek(tile.label)}
              </Typography>
            }
            style={{ margin: 3 }}
            onClick={
              response_type !== "audio/mpeg"
                ? handleClick
                : (e) => handleOpenDialogAudio(e, tile)
            }
            onDelete={
              deleteAllowed ? () => setOpenDialogDeleteEvidence(true) : null
            }
            deleteIcon={
              deleteAllowed ? (
                <HighlightOffIcon
                  fontSize="small"
                  style={{ color: "#d1354a" }}
                />
              ) : null
            }
          />
        </Tooltip>
      </Fade>

      <DialogPreviewDocument
        openDialogPreviewDocument={openDialogPreviewDocument}
        setOpenDialogPreviewDocument={setOpenDialogPreviewDocument}
        item={tile}
      />

      <DialogPreviewPDF
        openDialogPreviewPDF={openDialogPreviewPDF}
        setOpenDialogPreviewPDF={setOpenDialogPreviewPDF}
        item={tile}
      />

      <DialogPreviewText
        openDialogPreviewText={openDialogPreviewText}
        setOpenDialogPreviewText={setOpenDialogPreviewText}
        item={tile}
        itemId={tile.id}
        itemName={tile.label}
        classes={classes}
        origin="EvidenceMAR"
      />

      <DialogDeleteEvidenceMA
        openDialogDeleteEvidence={openDialogDeleteEvidence}
        setOpenDialogDeleteEvidence={setOpenDialogDeleteEvidence}
        item={tile}
        classes={classes}
        listPictState={listPictState}
        setListPictState={setListPictState}
      />

      <DialogPlayAudio
        classes={classes}
        tile={tile}
        dataStringTile={dataStringTile}
        userToken={userToken}
        isOpenDialogAudioPlayer={isOpenDialogAudioPlayer}
        setOpenDialogAudioPlayer={setOpenDialogAudioPlayer}
      />
    </span>
  );
}

export default DocItem;
