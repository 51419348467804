import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styles } from "./Style/StyleTimeOffSetting";
import { navigate } from "hookrouter";

import ContextTimeOffSetting from "./Context/ContextTimeOffSetting";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core";

import ViewTabCategory from "./Components/TabCategory/ViewTabCategory";
import CategoryIcon from "@material-ui/icons/Category";
import DialogProgress from "./Components/DialogProgress";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ViewTimeOffSetting = () => {
  const classes = useStyles();
  const context = useContext(ContextTimeOffSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const [loader, setLoader] = useState(false);

  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      // Category
      getCategoryList();
    } else if (newValue === 1) {
      //
    }
  };

  useEffect(() => {
    checkTimeOffDefault();
  }, []);

  // Category List
  const [dataCategoryList, setDataCategoryList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
  });
  const getCategoryList = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/timeoff-category` +
          `?options[filter][search]=${dataCategoryList.options.filter.search}` +
          `&options[paging][limit]=${dataCategoryList.options.paging.limit}` +
          `&options[paging][offset]=${dataCategoryList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataCategoryListTemp = dataCategoryList;
        dataCategoryListTemp.list = response.data.data;
        dataCategoryListTemp.options.paging.count = response.data.info.count;
        setDataCategoryList({ ...dataCategoryListTemp });
        console.log(dataCategoryList);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  const checkTimeOffDefault = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/timeoff-category` +
          `?options[filter][search]=` +
          `&options[filter][type]=default` +
          `&options[paging][limit]=1` +
          `&options[paging][offset]=0`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let totalDefault = response.data.info.count;
        if (totalDefault > 0) {
          getCategoryList();
        } else {
          context.setModalCreateDefault(true);
        }
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        <div className={classes.tabContainer}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab
              id="vertical-tab-work-pattern"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-work-pattern`}
              label={
                <React.Fragment>
                  <CategoryIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Category
                  </span>
                </React.Fragment>
              }
            />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            className={classes.tabPanelContainer}
          >
            <ViewTabCategory
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataCategoryList={dataCategoryList}
              setDataCategoryList={setDataCategoryList}
              getCategoryList={getCategoryList}
            />
          </TabPanel>
        </div>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
    </MuiThemeProvider>
  );
};

export default ViewTimeOffSetting;
