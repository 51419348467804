import React, { useState, useContext } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";

import moment from "moment";

import ContextReports from "../../../../../context/ContextReports";
import {
  GET_FIELD_PRESETS,
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
} from "../../../../../context/types";
import AxiosConfig from "../../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../../constants/config-api";
import { field_presets } from "../../../../global/dataFilterReport";

import ListItemPreset from "./ListItemPreset";
import extractTable from "../../../../global/extractTable";
import handleError from "../../../../global/handleError";
import DialogError from "../../../../../../../components/DialogError";

const PresetFilter = ({ classes }) => {
  const { filterState, tableState, tableDispatch, reportDispatch } =
    useContext(ContextReports);

  const [anchorPreset, setAnchorPreset] = useState(null);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleShowAnchorPreset = (event) => {
    setAnchorPreset(event.currentTarget);
  };

  const handleCloseAnchorPreset = () => {
    setAnchorPreset(null);
  };

  const handleChangePreset = (data) => {
    // // console.log("data preset", data);
    tableDispatch({
      type: GET_FIELD_PRESETS,
      payload: {
        fieldPresets: data,
      },
    });

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: data.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: tableState.usersSelected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const postWithCompare = {
      ...dataPost,
      comparisons: {
        date_range: tableState.comparison.code,
        fields: [
          "result_value",
          "overall_result_achievement",
          "expected_result_value",
        ],
      },
    };

    const { currentPage, perPage } = tableState.pagination;

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data;

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        // // console.log("Ori Result PRST", result);

        if (res.status === 200) {
          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  // // console.log("filterState.filterPresets", filterState.filterPresets);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<TableChartOutlinedIcon style={{ fontSize: 15 }} />}
        className={classes.actionBtn}
        disabled={tableState.loadTable || filterState.loadFilter}
        onClick={handleShowAnchorPreset}
      >
        Field Preset
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorPreset}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorPreset)}
        onClose={handleCloseAnchorPreset}
      >
        {filterState.filterPresets !== undefined &&
          filterState.filterPresets.length > 0 &&
          filterState.filterPresets.map((item, i) => (
            <MenuItem key={i} style={{ margin: 0, padding: "0 10px" }} dense>
              <ListItemPreset
                classes={classes}
                item={item}
                preset={tableState.fieldPresets}
                onHandlePreset={handleChangePreset}
              />
            </MenuItem>
          ))}
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default PresetFilter;
