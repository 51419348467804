import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  CircularProgress,
  Box,
  Avatar,
  IconButton,
  Chip,
} from "@material-ui/core";

import { useDropzone } from "react-dropzone";
import { useIndexedDB } from "react-indexed-db";

import imageCompression from "browser-image-compression";
// import Compressor from 'compressorjs';

import DialogBiggerPict from "./DialogBiggerPict";
import DialogDeleteBuktiGR from "./DialogDeleteBuktiGR";
import DialogDeleteBuktiGRInDatabase from "./DialogDeleteBuktiGRInDatabase";

import DialogError from "../../../../components/DialogError";

import { extractImageFileExtensionFromBase64 } from "../../../../utilities/ReusableUtils";

import LightTooltip from "../../../../components/LightTooltip";

import PictPlusUploadDocument from "../../../../assets/images/Group_2268.png";

import { URL_API } from "../../../../constants/config-api";

const UploaderInGoalResult = (props) => {
  const {
    classes,
    goalResultDataDetail,
    userToken,
    loaderEvidance,
    getAcceptedFiles,
    disableAddResult,
  } = props;

  const theme = useTheme();

  const { add, getAll } = useIndexedDB("video");

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  let listPict = [];

  /* 
        ````````````````````````````````````
        PLAY WITH LOCAL STORAGE, *DEPRECATED

        ````````````````````````````````````
    */
  const dataPictGoalResult = localStorage.getItem("data_pict_goal_result");
  let dataPictGoalResultAfterParse =
    dataPictGoalResult !== null ? JSON.parse(dataPictGoalResult) : [];

  /* 
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const [listPictState, setListPictState] = useState([]);

  useEffect(() => {
    /*
        ``````````````````````````````````````````````````
        Di sini 'listPictState' di dapat dari localStorage

        ``````````````````````````````````````````````````
    */
    if (dataPictGoalResultAfterParse !== null) {
      console.log(
        "dataPictGoalResultAfterParse : ",
        dataPictGoalResultAfterParse
      );
      setListPictState(dataPictGoalResultAfterParse);
    }

    /*
        ``````````````````````````````````````````````
        Di sini 'listPictState' di dapat dari DATABASE

        ``````````````````````````````````````````````
    */
    let listDummyPictGR = [];

    if (goalResultDataDetail !== null) {
      if (goalResultDataDetail !== undefined) {
        if (goalResultDataDetail.goalResultEvidence.length > 0) {
          console.log(
            "List goalResultEvidence : ",
            goalResultDataDetail.goalResultEvidence
          );

          goalResultDataDetail.goalResultEvidence.map((pict, i) => {
            // console.log("Pict : ", pict);

            let templatePict = {
              id: pict.id,
              img: pict.self.rel.attachment,
              title: pict.label,
              goal_result_id: pict.goal_result_id,
              format_type_id: pict.format_type_id,
              formatType: {
                id: pict.formatType.id,
                code: pict.formatType.code,
                name: pict.formatType.name,
              },
            };

            listDummyPictGR.push(templatePict);
          });

          setListPictState(listDummyPictGR);
        }
      }
    }

    //*
    getAll().then((item) => {
      // console.log("Item in IndexedDB : ", item);
      if (item.length > 0) {
        setListPictState(item);
      }
    });
  }, []);

  //*
  useEffect(() => {
    /*
            ``````````````````````````````````````````````````
            Di sini 'listPictState' di dapat dari localStorage

            ``````````````````````````````````````````````````
        */
    if (dataPictGoalResultAfterParse !== null) {
      console.log(
        "dataPictGoalResultAfterParse : ",
        dataPictGoalResultAfterParse
      );
      setListPictState(dataPictGoalResultAfterParse);
    }

    /*
            ``````````````````````````````````````````````
            Di sini 'listPictState' di dapat dari DATABASE

            ``````````````````````````````````````````````
        */
    let listDummyPictGR = [];

    if (goalResultDataDetail !== null) {
      if (goalResultDataDetail !== undefined) {
        if (goalResultDataDetail.goalResultEvidence.length > 0) {
          console.log(
            "List goalResultEvidence : ",
            goalResultDataDetail.goalResultEvidence
          );

          goalResultDataDetail.goalResultEvidence.map((pict, i) => {
            // console.log("Pict : ", pict);

            let templatePict = {
              id: pict.id,
              img: pict.self.rel.attachment,
              title: pict.label,
              goal_result_id: pict.goal_result_id,
              format_type_id: pict.format_type_id,
              formatType: {
                id: pict.formatType.id,
                code: pict.formatType.code,
                name: pict.formatType.name,
              },
            };

            listDummyPictGR.push(templatePict);
          });

          setListPictState(listDummyPictGR);
        }
      }
    }

    //*
    getAll().then((item) => {
      // console.log("Item in IndexedDB : ", item);
      if (item.length > 0) {
        setListPictState(item);
      }
    });
  }, [goalResultDataDetail]);

  /* 
        ````````````````````
        Feature Upload Foto 
        
        ````````````````````
    */
  const [nameFile, setNameFile] = useState("");
  const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState("");

  const [videoBinaryPreviewUrl, setVideoBinaryPreviewUrl] = useState("");

  const [imgSrcExt, setImgSrcExt] = useState();
  const [contentFile, setContentFile] = useState(null);
  // const [sizeImg, setSizeImg ] = useState(0);
  const [videoCountLocalStorage, setVideoCountLocalStorage] = useState(0);
  const [isMaxVideoCountLocalStorage, setMaxVideoCountLocalStorage] =
    useState(false);

  //*
  const [isVideoSizeBig, setVideoSizeBig] = useState(false);

  //*https://stackoverflow.com/questions/2989284/what-is-the-max-size-of-localstorage-values

  const onDrop = useCallback((acceptedFiles, i) => {
    setShowTooltip(false);

    getAcceptedFiles(acceptedFiles);

    // console.log("acceptedFiles : ", acceptedFiles);
    console.log(
      "originalFile instanceof Blob",
      acceptedFiles[0] instanceof Blob
    );
    if (acceptedFiles[0] !== undefined) {
      console.log(
        `originalFile size ${acceptedFiles[0].size / 1024 / 1024} MB`
      );

      let acceptedFileSecond = [];

      /*
                ```````````````````````````````````````````````````````````
                VALIDASI JIKA size image > 8MB, gunakan imageCompression();

                VALIDASI JIKA size image < 8MB, gunakan new Compressor();

                ```````````````````````````````````````````````````````````
            */
      if (acceptedFiles[0].type.toString().indexOf("image") !== -1) {
        //*HANDLE IMAGE with imageCompression();
        //    if( acceptedFiles[0].size / 1024 / 1024 >= 9.004190444946289){

        // console.log("Running compress with imageCompression()...");

        let options = {
          maxSizeMB: 2, //0.5,
          // maxSizeMB: 0.,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        imageCompression(acceptedFiles[0], options)
          .then(function (compressedFile) {
            console.log(
              "imageCompression instanceof Blob",
              compressedFile instanceof Blob
            ); // true
            console.log(
              `imageCompression size ${compressedFile.size / 1024 / 1024} MB`
            ); // smaller than maxSizeMB

            // let file = new File([acceptedFiles[0]], acceptedFiles[0].name, {type: acceptedFiles[0].type, lastModified: Date.now()});
            let file = new File([compressedFile], compressedFile.name, {
              type: compressedFile.type,
              lastModified: Date.now(),
            });

            acceptedFileSecond = [...acceptedFileSecond, file];

            // Do something with the files, you can upload multiple here too !
            setNameFile(acceptedFiles[0].name);

            //*
            const reader = new FileReader();

            reader.onabort = () =>
              // console.log("file reading was aborted");
              (reader.onerror = () =>
                // console.log("file reading has failed");

                (reader.onload = () => {
                  // Do whatever you want with the file contents
                  const binaryStr = reader.result;

                  setImageBinaryPreviewUrl(binaryStr);
                  setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));

                  // };
                }));

            acceptedFileSecond.forEach((file) => reader.readAsDataURL(file));
          })
          .catch(function (error) {
            console.log(error.message);
          });

        // } else {

        //     // console.log("Running compress with new Compressor()...");

        //     //*HANDLE IMAGE with new Compressor();
        //     new Compressor(acceptedFiles[0], {

        //         quality: 0.6,
        //         convertSize: 2000000,//2MB
        //         success(result) {

        //             // console.log("Result Compressor: ", result);
        //             let file = new File([result], result.name, {type: result.type, lastModified: Date.now()});

        //             acceptedFileSecond = [...acceptedFileSecond, file];

        //             // Do something with the files, you can upload multiple here too !
        //             setNameFile(result.name);

        //             //*
        //             const reader = new FileReader()

        //             reader.onabort = () => console.log('file reading was aborted');
        //             reader.onerror = () => console.log('file reading has failed');

        //             reader.onload = () => {

        //                 // Do whatever you want with the file contents
        //                 const binaryStr = reader.result

        //                     setImageBinaryPreviewUrl(binaryStr);
        //                     setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));

        //                 // };
        //             };

        //             acceptedFileSecond.forEach(file => reader.readAsDataURL(file));

        //         },
        //         error(err) {
        //             console.log(err.message);
        //         },
        //     });
        // };
      }

      //*HANDLE VIDEO
      if (acceptedFiles[0].type.toString().indexOf("video") !== -1) {
        let fileVideo = new File([acceptedFiles[0]], acceptedFiles[0].name, {
          type: acceptedFiles[0].type,
          lastModified: Date.now(),
        });

        acceptedFileSecond = [...acceptedFileSecond, fileVideo];
        setNameFile(acceptedFiles[0].name);

        //*
        const reader = new FileReader();

        reader.onabort = () =>
          // console.log("file reading was aborted");
          (reader.onerror = () =>
            // console.log("file reading has failed");

            (reader.onload = () => {
              // Do whatever you want with the file contents
              const binaryStr = reader.result;

              if (acceptedFiles[0].size / 1024 / 1024 >= 20.004190444946289) {
                //284871

                setOpenDialogError(true);
                setErrorStatus(400);
                setTextErrorInformation(
                  "File video yang di unggah tidak boleh melebihi 20MB"
                );
              } else if (
                acceptedFiles[0].size / 1024 / 1024 <=
                20.004190444946289
              ) {
                const videoCount = localStorage.getItem("video_count");

                if (videoCount !== null) {
                  setErrorStatus(400);
                  setTextErrorInformation(
                    "Maximal hanya 1 File untuk upload video !"
                  );
                  setOpenDialogError(true);
                } else {
                  localStorage.setItem("video_count", 1);
                  setImageBinaryPreviewUrl(binaryStr);
                  setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));
                }
              }
              // else {

              //     setOpenDialogError(true);
              //     setErrorStatus(400);
              //     setTextErrorInformation('Format file yang Anda upload sepertinya belum tepat atau ukuran File yang hendak di upload melebihi batas kuota yang di tentukan !');
              // };
            }));

        acceptedFileSecond.forEach((fileVideo) =>
          reader.readAsDataURL(fileVideo)
        );
      }
    } else {
      setOpenDialogError(true);
      setErrorStatus(400);
      setTextErrorInformation(
        "Format file yang Anda upload sepertinya belum tepat atau ukuran File yang hendak di upload melebihi batas kuota yang di tentukan !"
      );
    }
  }, []);

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);

  /*
        `````````````````````````````````````````````````````````````````
        HANDLE OPEN DIALOG DELETE PICT BUKTI GOAL RESULT in Local Storage

        `````````````````````````````````````````````````````````````````
    */
  const [isOpenDialogDeleteBukti, setOpenDialogDeleteBukti] = useState(false);

  /*
        `````````````````````````````````````````````````````
        HANDLE DELETE PICT BUKTI GOAL RESULT in Local Storage

        `````````````````````````````````````````````````````
    */
  const [dataDetailDelete, setDataDetailDelete] = useState("");

  const handleDeletePictBuktiGR = (e, data) => {
    e.preventDefault();
    // console.log('Data Delete PICT : ', data);
    setDataDetailDelete(data);

    setOpenDialogDeleteBukti(true);
  };

  /*
        `````````````````````````````````````````````````````````````````
        HANDLE OPEN DIALOG DELETE PICT BUKTI GOAL RESULT in DATABASE

        `````````````````````````````````````````````````````````````````
    */
  const [isOpenDialogDeleteBuktiInDB, setOpenDialogDeleteBuktiInDB] =
    useState(false);

  /*
        ````````````````````````````````````````````````
        HANDLE DELETE PICT BUKTI GOAL RESULT in DATABASE

        ````````````````````````````````````````````````
    */

  const handleDeletePictBuktiGRInDatabase = (e, data) => {
    e.preventDefault();
    // console.log('Data Delete PICT in DATABASE: ', data);
    setDataDetailDelete(data);

    setOpenDialogDeleteBuktiInDB(true);
  };

  /*
        ```````````````````````````````
    */
  const [loaderPreviewBukti, setLoaderPreviewBukti] = useState(false);
  useEffect(() => {
    let data = {
      // id: ID(),
      img: imageBinaryPreviewUrl,
      title: nameFile,
    };

    if (imageBinaryPreviewUrl !== "") {
      setLoaderPreviewBukti(true);

      add(data).then(
        (event) => {
          console.log(
            `File dengan id : ${event} berhasil di tambahkan ke dalam IndexedDB !`
          );
          setLoaderPreviewBukti(false);

          if (listPictState.length > 0) {
            let dataReadyIndexedDB = {
              id: event,
              img: imageBinaryPreviewUrl,
              title: nameFile,
            };

            // =========================
            // const newList = [...listPictState, dataReadyIndexedDB];
            // =========================
            const newList = [...listPictState, dataReadyIndexedDB];
            setListPictState(newList);
          } else {
            setLoaderPreviewBukti(false);
            setListPictState([data]);
          }
        },
        (error) => {
          console.log(error);
          // localStorage.removeItem("video_count")
        }
      );
    }
  }, [imageBinaryPreviewUrl]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept:
        ".jpeg, .jpg, .png .gif, .bmp, .jfif, .JPEG, .JPG, .PNG, .GIF, .BMP, .JFIF, .mp4, .MP4, .ogg, .oga, .ogv, .ogx .3gp, .flv, .wmv, .webm",
      minSize: 0,
      maxSize: 20971520,
    });

  /*
        ````
        ID()

        ````
    */
  const ID = function () {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  /*
        ``````````````````````````````````
        OPEN DIALOG LIHAT PICT LEBIH BESAR

        ``````````````````````````````````
    */
  const [isOpenDialog, setOpenDialog] = useState(false);

  /*
        ``````````````````````````````
        ON MOUSE OVER & ON MOUSE LEAVE

        ``````````````````````````````
    */
  const [isShowTooltip, setShowTooltip] = useState(true);

  return (
    <Grid container alignItems="left" justify="left">
      <Grid item sm={12}>
        {listPictState.length > 0 && (
          <div {...getRootProps()}>
            <input {...getInputProps()} multiple />
            <Box marginLeft={theme.spacing(-0.2)}>
              <LightTooltip title="Tambah bukti lagi" arrow placement="right">
                <IconButton>
                  {listPictState.length > 0 && listPictState.length < 5 && (
                    <img
                      src={PictPlusUploadDocument}
                      style={{ width: 24 }}
                      alt="."
                    />
                  )}
                </IconButton>
              </LightTooltip>
            </Box>
            {isDragReject &&
              alert(
                "Maaf, ada beberapa file yang tidak kami izinkan untuk di upload"
              )}
          </div>
        )}
      </Grid>

      <div>
        {loaderPreviewBukti === true && (
          <Typography variant="caption" className={classes.title}>
            <i>Loading preview...</i>
          </Typography>
        )}

        {listPictState.length > 0 ? (
          listPictState.map((tile, i) => {
            /*
                ``````````````````````````````````
                PART-01, play direct with Database

                ``````````````````````````````````
            */
            if (goalResultDataDetail !== null) {
              if (goalResultDataDetail !== undefined) {
                if (goalResultDataDetail.goalResultEvidence.length > 0) {
                  return (
                    <span key={i}>
                      <Box marginBottom={theme.spacing(0.1)}>
                        <Chip
                          style={{ marginRight: 4 }}
                          avatar={
                            loaderEvidance === true ? (
                              <CircularProgress
                                size={12}
                                style={{ color: "#cc0707" }}
                              />
                            ) : (
                              <Avatar
                                src={`${URL_API}/${tile.img}?token=${userToken}&mode=thumbnail`}
                                // src = {`${URL_API}/${tile.img}?token=${userToken}`}

                                alt={tile.title}
                                className={classes.bigAvatar}
                                style={{
                                  borderRadius: 5,
                                  width: 32,
                                  border: "2px solid #f2f2f2",
                                  margin: 3,
                                }}
                              />
                            )
                          }
                          label={
                            <Typography
                              variant="caption"
                              className={classes.title}
                            >
                              {tile.title}
                            </Typography>
                          }
                          onDelete={(e) =>
                            handleDeletePictBuktiGRInDatabase(e, tile)
                          }
                        />
                      </Box>
                    </span>
                  );
                }
              }
            }

            /*
                ````````````````````````````````````````
                PART-02,  play direct with Local Storage

                ````````````````````````````````````````
            */
            if (goalResultDataDetail !== null) {
              if (goalResultDataDetail !== undefined) {
                if (goalResultDataDetail.goalResultEvidence.length === 0) {
                  return (
                    <span key={i}>
                      <Box marginBottom={theme.spacing(0.1)}>
                        <Chip
                          style={{ marginRight: 4 }}
                          avatar={
                            loaderEvidance === true ? (
                              <CircularProgress
                                size={16}
                                style={{ color: "#cc0707" }}
                              />
                            ) : (
                              <Avatar
                                alt={tile.title}
                                src={tile.img}
                                className={classes.bigAvatar}
                                style={{
                                  borderRadius: 5,
                                  width: 32,
                                  border: "2px solid #f2f2f2",
                                  margin: 3,
                                }}
                              />
                            )
                          }
                          label={
                            <Typography
                              variant="caption"
                              className={classes.title}
                            >
                              {tile.title}
                            </Typography>
                          }
                          onDelete={(e) => handleDeletePictBuktiGR(e, tile)}
                        />
                      </Box>
                    </span>
                  );
                }
              }
            }
          })
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} multiple disabled={disableAddResult} />
            <LightTooltip
              title={
                disableAddResult
                  ? "Tidak diperbolehkan upload bukti"
                  : "Tambah bukti"
              }
              arrow
              placement="right"
              open={isShowTooltip}
            >
              <Box marginLeft={theme.spacing(-0.2)}>
                <IconButton disabled={disableAddResult}>
                  <img
                    src={PictPlusUploadDocument}
                    style={{ width: 24 }}
                    alt="."
                  />
                </IconButton>
              </Box>
            </LightTooltip>
            {isDragReject &&
              alert(
                "Maaf, ada beberapa file yang tidak kami izinkan untuk di upload"
              )}
          </div>
        )}
      </div>

      <DialogBiggerPict
        classes={classes}
        isOpenDialog={isOpenDialog}
        setOpenDialog={setOpenDialog}
      />

      <DialogDeleteBuktiGR
        classes={classes}
        isOpenDialogDeleteBukti={isOpenDialogDeleteBukti}
        setOpenDialogDeleteBukti={setOpenDialogDeleteBukti}
        dataDetailDelete={dataDetailDelete}
        listPictState={listPictState}
        setListPictState={setListPictState}
      />

      <DialogDeleteBuktiGRInDatabase
        classes={classes}
        isOpenDialogDeleteBuktiInDB={isOpenDialogDeleteBuktiInDB}
        setOpenDialogDeleteBuktiInDB={setOpenDialogDeleteBuktiInDB}
        dataDetailDelete={dataDetailDelete}
        listPictState={listPictState}
        setListPictState={setListPictState}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
      />
    </Grid>
  );
};

export default UploaderInGoalResult;
