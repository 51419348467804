import React, { useState } from "react";
import { Typography, Box, Slider, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const SimpleSlider = withStyles({
  root: {
    width: 300,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: "1px solid #888",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 5,
    borderRadius: 4,
    color: "#d1354a",
  },
  rail: {
    height: 5,
    borderRadius: 4,
    color: "#10266F",
  },
})(Slider);

const SliderTwoSide = ({ classes, scope, schedule, onScope, onSchedule }) => {
  const handleWeight = (e, value) => {
    const valueSchedule = 100 - value;
    onScope(value);
    onSchedule(valueSchedule);
  };

  const handleScope = (e) => {
    const value = e.target.value;

    if (value < 0) {
      const valueSchedule = 100 - 0;
      onScope(0);
      onSchedule(valueSchedule);
    } else if (value > 100) {
      const valueSchedule = 100 - 100;
      onScope(100);
      onSchedule(valueSchedule);
    } else {
      const valueSchedule = 100 - value;
      onScope(value);
      onSchedule(valueSchedule);
    }
  };

  const handleSchedule = (e) => {
    const value = e.target.value;
    if (value < 0) {
      const valueScope = 100 - 0;
      onSchedule(0);
      onScope(valueScope);
    } else if (value > 100) {
      const valueScope = 100 - 100;
      onSchedule(100);
      onScope(valueScope);
    } else {
      const valueScope = 100 - value;
      onSchedule(value);
      onScope(valueScope);
    }
  };

  return (
    <Box mt={1} display="flex" alignItems="center">
      <Typography variant="subtitle2" className={classes.title888}>
        <b>Scope </b>
      </Typography>

      <Box mx={1} />
      <TextField
        size="small"
        type="number"
        variant="outlined"
        style={{ width: 100 }}
        value={scope}
        onChange={handleScope}
      />
      <Box mx={1} />

      <SimpleSlider value={scope} onChange={handleWeight} />

      <Box mx={1} />
      <TextField
        size="small"
        type="number"
        variant="outlined"
        style={{ width: 100 }}
        value={schedule}
        onChange={handleSchedule}
      />
      <Box mx={1} />

      <Typography variant="subtitle2" className={classes.title888}>
        <b> Schedule</b>
      </Typography>
    </Box>
  );
};

export default SliderTwoSide;
