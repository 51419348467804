import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Menu,
  Typography,
  Divider,
  IconButton,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import MenuSelect from "./MenuSelect";
import Mode from "./Mode";
import { order_by_report_detail } from "../../../Global/DataFilterReport";
import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const OrderBy = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  const [expandMode, setExpandMode] = useState(false);
  const [anchorOrderBy, setAnchorOrderBy] = useState(null);
  const [listFirstOrder, setListFirstOrder] = useState(order_by_report_detail);
  const [listSecondOrder, setListSecondOrder] = useState([]);
  const [listThirdOrder, setListThirdOrder] = useState([]);
  const [firstOrder, setFirstOrder] = useState({
    field: "user_name",
    name: "User Name",
    method: "ASC",
  });
  const [secondOrder, setSecondOrder] = useState({
    field: "",
    name: "",
    method: "",
  });
  const [thirdOrder, setThirdOrder] = useState({
    field: "",
    name: "",
    method: "",
  });
  const [mode, setMode] = useState({ name: "Ascending", method: "ASC" });

  let allOrderSelect = [
    { ...firstOrder },
    { ...secondOrder },
    { ...thirdOrder },
  ].filter((item) => item.name !== "");

  // ========= SIDE EFFECTS =========
  useEffect(() => {
    if (anchorOrderBy !== null) {
      setListFirstOrder(order_by_report_detail);
    }
  }, [anchorOrderBy]);
  useEffect(() => {
    const secondOrderList =
      order_by_report_detail.length > 0 &&
      order_by_report_detail.filter((item) => {
        return item.name !== firstOrder.name;
      });
    const thirdOrderList =
      listSecondOrder.length > 0 &&
      listSecondOrder.filter((item) => {
        return item.name !== secondOrder.name;
      });

    setListSecondOrder(secondOrderList);
    setListThirdOrder(thirdOrderList);
  }, [firstOrder, secondOrder, thirdOrder, anchorOrderBy]);

  // ========= FUNC & METHODS =========
  const handleShowAnchorOrderBy = (event) => {
    setAnchorOrderBy(event.currentTarget);
  };

  const handleCloseAnchorOrderBy = () => {
    setAnchorOrderBy(null);
    setFirstOrder({ field: "user_name", name: "User Name", method: "ASC" });
    setSecondOrder({ field: "", name: "", method: "" });
    setThirdOrder({ field: "", name: "", method: "" });
    setListFirstOrder([]);
    setListSecondOrder([]);
    setListThirdOrder([]);
  };

  const handleShowResults = () => {
    setAnchorOrderBy(null);
    let dataTemporary = context.dataSettingDetail;
    dataTemporary.order_by.field = firstOrder.field;
    dataTemporary.order_by.method = mode.method;
    context.setDataSettingDetail({ ...dataTemporary });
    context.getReportDetailList();
  };

  const handleExpandMode = () => {
    setExpandMode((more) => !more);
  };

  const handleMode = (data) => {
    // // console.log("MODE", data);
    setMode(data);
  };

  return (
    <>
      <StyledBadge
        badgeContent={allOrderSelect.length}
        children={
          <Button
            variant="outlined"
            size="small"
            className={classes.groupBtn}
            endIcon={<ExpandMoreIcon />}
            disabled={context.loader}
            onClick={handleShowAnchorOrderBy}
          >
            Order By
          </Button>
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorOrderBy}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorOrderBy)}
      >
        <Box
          minWidth={350}
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}
        >
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Order Table</b>
          </Typography>
        </Box>
        <Box
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}
          display="flex"
          alignItems="center"
        >
          <Typography variant="caption" className={classes.title333}>
            Order by
          </Typography>
          <MenuSelect
            classes={classes}
            isDisable={false}
            OrderByList={listFirstOrder}
            // OrderByList={anchorOrderBy === null ? [] : order_by_report_detail}
            onSelected={setFirstOrder}
          />
        </Box>
        <Box margin={2}>
          <Divider />
        </Box>
        <Box
          margin={2}
          mt={0}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Mode</b>
          </Typography>

          <IconButton size="small" onClick={handleExpandMode}>
            {!expandMode ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </Box>
        {expandMode && (
          <Box display="flex">
            {listMode.map((item, i) => (
              <Mode
                key={i}
                classes={classes}
                item={item}
                mode={mode}
                onMode={handleMode}
              />
            ))}
          </Box>
        )}
        <Box margin={2}>
          <Divider />
        </Box>
        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorOrderBy}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResults}
          >
            Show results
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default OrderBy;

const listMode = [
  {
    id: 1,
    name: "Ascending",
    method: "ASC",
  },
  {
    id: 2,
    name: "Descending",
    method: "DESC",
  },
];
