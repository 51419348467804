import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Typography,
  Box,
  DialogActions,
  Button,
} from "@material-ui/core";

import axios from "axios";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

function DialogDownloadFile({
  item,
  classes,
  openDialogDownloadFile,
  setOpenDialogDownloadFile,
  origin,
}) {
  const [loading, setLoading] = useState(false);
  const [downloadFile, setDownloadFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [percentage, setPercentage] = useState("0");
  const [cancelDownload, setCancelDownload] = useState(null);

  useEffect(() => {
    if (openDialogDownloadFile) {
      setLoading(true);

      // setProgress(0);
      // setPercentage("0");

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      setCancelDownload(source);

      if (origin === "Drive") {
        axiosConfig
          .get(`${URL_API}/drive/file/${item.id}?download=true`, {
            responseType: "blob",
            onDownloadProgress: (ProgressEvent) => {
              const { loaded, total } = ProgressEvent;
              const percent = Math.floor((loaded / total) * 100);

              if (percent <= 100) {
                setPercentage(percent);
                setProgress((prev) => (prev = percent));
              }
            },
            cancelToken: source.token,
          })
          .then((response) => {
            // console.log("Response Download", response);

            if (response.status === 200) {
              setDownloadFile(response.data);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${item.name}.${item.extension}`); //or any other extension
              document.body.appendChild(link);
              link.click();

              setOpenDialogDownloadFile(false);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
            setOpenDialogDownloadFile(false);
          });
      } else if (origin === "Gallery") {
        axiosConfig
          .get(
            `${URL_API}/drive/gallery/goal-result-evidence/${item.id}?download=true`,
            {
              responseType: "blob",
              onDownloadProgress: (ProgressEvent) => {
                const { loaded, total } = ProgressEvent;
                const percent = Math.floor((loaded / total) * 100);

                if (percent <= 100) {
                  setPercentage(percent);
                  setProgress((prev) => (prev = percent));
                }
              },
              cancelToken: source.token,
            }
          )
          .then((response) => {
            // // console.log("Response Download", response);

            if (response.status === 200) {
              setDownloadFile(response.data);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${item.label}.${item.extension}`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
            setOpenDialogDownloadFile(false);
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
            setOpenDialogDownloadFile(false);
          });
      } else if (origin === "GalleryMA") {
        axiosConfig
          .get(
            `${URL_API}/drive/gallery/measured-activity-result-evidence/${item.id}?download=true`,
            {
              responseType: "blob",
              onDownloadProgress: (ProgressEvent) => {
                const { loaded, total } = ProgressEvent;
                const percent = Math.floor((loaded / total) * 100);

                if (percent <= 100) {
                  setPercentage(percent);
                  setProgress((prev) => (prev = percent));
                }
              },
              cancelToken: source.token,
            }
          )
          .then((response) => {
            // // console.log("Response Download", response);

            if (response.status === 200) {
              setDownloadFile(response.data);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${item.label}.${item.extension}`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
            setOpenDialogDownloadFile(false);
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
            setOpenDialogDownloadFile(false);
          });
      }
    }
  }, [openDialogDownloadFile]);

  const handleCancelDownload = () => {
    if (window.confirm("Cancel download file ?")) {
      cancelDownload.cancel();
      setOpenDialogDownloadFile(false);
    }
  };

  return (
    <Dialog
      open={openDialogDownloadFile}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>
            {origin === "Drive"
              ? item.name
              : origin === "Gallery"
              ? item.label
              : item.label}
          </b>
        </Typography>
      </DialogTitle>

      {loading && (
        <DialogContent
          style={{
            width: 400,
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          <Typography
            variant="body2"
            className={classes.title}
            color="textSecondary"
          >
            Dowanloading...
          </Typography>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.title}
              >{`${percentage}%`}</Typography>
            </Box>
          </Box>
        </DialogContent>
      )}

      <DialogActions
        style={{
          justifyContent: "center",
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        <Button
          variant="contained"
          className={classes.btnCancelDownload}
          style={{ width: 200 }}
          onClick={handleCancelDownload}
        >
          Cancel Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDownloadFile;
