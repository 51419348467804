import React from "react";
import { styles } from "../Style/StyleHistoryCheckInOut";

import {
  makeStyles,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import DateRange from "@material-ui/icons/DateRange";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { id } from "date-fns/locale";

const FilterDate = ({ date, setDate }) => {
  const handleDateChange = (date) => {
    setDate(date.toISOString().split("T")[0]);
  };

  return (
    <div
      style={{
        marginLeft: 100,
        marginRight: 100,
        marginTop: 20,
        textAlign: "center",
      }}
    >
      <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="outlined"
          format="dd MMMM yyyy"
          margin="normal"
          id="date-filter"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "ganti tanggal",
          }}
          style={{
            width: "40%",
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default FilterDate;
