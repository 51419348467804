import React, { useContext, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import ContextProject from '../../context/ContextProject';
import TableTaskHeader from './TableTaskHeader';
import ListTableTask from './ListTableTask';
import ListTableGroups from './ListTableGroups';
import PaginateTask from './PaginateTask';

const TableTask = ({ classes, project }) => {
  const { projectTState } = useContext(ContextProject);
  const tasks = useMemo(
    () => projectTState.taskData,
    [projectTState.taskData]
  );
  const groups = useMemo(
    () => projectTState.groupTaskData,
    [projectTState.groupTaskData]
  );

  // const task = projectTState?.taskDetails;

  for (let i = 0; i < groups.length; i++) {
    if (
      groups[i].todolists.length === 0 &&
      groups[i].name === 'Default Task Group'
    ) {
      groups.splice(i, 1);
      i--;
    }
  }

  // // console.log("tasks", tasks);
  // // console.log("groups", groups);

  return (
    <div>
      {projectTState.loadTask && <LinearProgress />}
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableTaskHeader classes={classes} project={project} />

          <TableBody style={{ backgroundColor: '#fff' }}>
            {tasks?.length > 0 &&
              tasks.map((row, i) => {
                return (
                  <ListTableTask
                    key={i}
                    classes={classes}
                    row={row}
                    project={project}
                    taskGroupName="-"
                  />
                );
              })}
            {groups?.length > 0 &&
              groups.map((row, i) => {
                return (
                  <ListTableGroups
                    key={i}
                    classes={classes}
                    row={row}
                    project={project}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={4}
      >
        {groups?.length === 0 &&
          tasks?.length === 0 &&
          !projectTState.loadTask && (
            <Typography
              variant="subtitle2"
              className={classes.title888}
            >
              Data not found
            </Typography>
          )}
      </Box>

      {/* {tasks.length > 0 && <PaginateTask classes={classes} />} */}
      <PaginateTask classes={classes} />
    </div>
  );
};

export default TableTask;
