import React, { Fragment, useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@material-ui/icons/Info";

import ContextAllMA from "../context/AllMaContext";

import DialogRemarksLog from "../../../../components/DialogRemarksLog";

function DialogFeedbackSetAsCompleteMA({
  classes,
  openDialogFeedbackSetAsComplete,
  setOpenDialogFeedbackSetAsComplete,
  responseSetAsComplete,
  listMA,
}) {
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);
  const context = useContext(ContextAllMA);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  // Take Succes or Failed Array
  // const completeItem = responseSetAsComplete.map((item) => item.data.data);
  //   // console.log("Response Set as COMCOM", responseSetAsComplete);

  //   Take ID and Name
  const maItem = listMA.map((item) => {
    return {
      name: item.data.data.name,
      id: item.data.data.id,
    };
  });
  // // console.log("MA Item", maItem);

  //   Handle Close Dialog And Refresh collection of Goal
  const closeDialogUpdateListMA = () => {
    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const dataLog = {
      user_activity_log: {
        module: "ma",
        event: "update",
        user_id: user?.user_id,
        user_email: user?.user_email,
        nama: `${user?.member_first_name} ${user?.member_last_name}`,
        position:
          user?.member_position?.structure_position_title_name === undefined
            ? ""
            : user?.member_position?.structure_position_title_name,
        device_type: device_os,
        device_model: "",
        device_version: OSName,
        long: "",
        lat: "",
        entity_id: "",
        entity_name: "",
        remarks: "Set complete Measured Activity 1",
        comment: "",
      },
    };

    setDataRemarks(dataLog);

    // context.setSuccessSetAsComplete(Math.floor(Math.random() * 1000 + 1));
    // setOpenDialogFeedbackSetAsComplete(false);

    setOpenRemarksLog(true);
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="md"
        open={openDialogFeedbackSetAsComplete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title-422"
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <span></span>
            <InfoIcon style={{ fontSize: 36, color: "#FFAC9C" }} />
            <IconButton size="small" onClick={closeDialogUpdateListMA}>
              <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List>
              {maItem.length > 0 &&
                maItem.map((ma, idx) => (
                  <ListItem>
                    <ListItemText
                      primary={
                        <span
                          style={{
                            color: "#333",
                            fontStyle: "normal",
                          }}
                        >
                          <b>{ma.name}</b>
                        </span>
                      }
                      secondary={
                        <span
                          style={{
                            fontStyle: "normal",
                          }}
                        >
                          <ul>
                            {responseSetAsComplete.failed !== undefined &&
                              responseSetAsComplete.failed.length > 0 &&
                              responseSetAsComplete.failed.map((fail) => {
                                if (fail.id === ma.id) {
                                  // // console.log("logError", fail.errors);

                                  return fail.errors.map((error) => (
                                    <li style={{ color: "#d1354a" }}>
                                      {error.description}
                                    </li>
                                  ));
                                }
                              })}

                            {responseSetAsComplete.success !== undefined &&
                              responseSetAsComplete.success.length > 0 &&
                              responseSetAsComplete.success.map((sccs) => {
                                if (sccs.id === ma.id) {
                                  // // console.log("logSuccess", sccs.result);

                                  return (
                                    <li style={{ color: "#1EBC61" }}>
                                      Berhasil!
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </span>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            marginBottom: 15,
          }}
        >
          <Button
            onClick={closeDialogUpdateListMA}
            variant="contained"
            className={classes.buttonModalDelete}
            style={{ paddingRight: 25, paddingLeft: 25 }}
          >
            Tutup
          </Button>
        </DialogActions>
      </Dialog>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks={`Set Complete beberapa MA`}
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenDialogFeedbackSetAsComplete}
        onTriggerRemarks={context.setSuccessSetAsComplete}
        editRemarks={true}
      />
    </Fragment>
  );
}

export default DialogFeedbackSetAsCompleteMA;
