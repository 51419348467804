import React, { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";
// import { URL_API } from '../../../../constants/config-api';

import ContextAttendanceSetting from "./ContextAttendanceSetting";

const ContextGlobalAttendanceSetting = (props) => {
  /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
  const [userTokenState, setUserTokenState] = useState("");

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    setUserTokenState(userToken);
  }, []);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isSnackbarResponse200, setSnackbarResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  /*
        `````````````````````````````
        DATA DETAIL GLOBAL HORIZ ICON

        `````````````````````````````
    */
  const [dataDetailHoriz, setDataDetailHoriz] = useState({
    id: "",
    name: "",
    owner: {
      member: {
        first_name: "",
        last_name: "",
      },
    },
    target_result: "",
    status_id: "",
    owner_id: "",
  });

  const [actualResultGlobal, setActualResultGlobal] = useState(0);


  
  const [isModalCreate, setModalCreate] = useState(false);
  const [isModalEdit, setModalEdit] = useState(false);
  const [isModalDelete, setModalDelete] = useState(false);
  const [isModalProgress, setModalProgress] = useState(false);
  // const [ valueNoteGlobal, setValueNoteGlobal ] = useState('');

  /*
        `````````````````````````````````````````
        HANDLE SUCCESS 
            
            - DELETE TDL

            - COMPLETED

            - INCOMPLETED ,

            - EDIT TDL,

            - SET ANCHOR EL in Edit & Delete Task

        `````````````````````````````````````````
    */
  const [isSuccessDeleteTDL, setSucessDeleteTDL] = useState(false);
  const [isSuccessEditTDL, setSuccessEditTDL] = useState(false);

  const [isSuccessTaskCOMPLETED, setSuccessTaskCOMPLETED] = useState(false);
  const [isSuccessTaskINCOMPLETED, setSuccessTaskINCOMPLETED] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  // Show / Hide Completed TDL
  const [showhideCompleted, setShowhideCompleted] = useState(false);

  /*
        `````
        FADE

        `````
    */
  const [isRunFade, setRunFade] = useState(false);

  
  let [dataLocation, setDataLocation] = useState({
    location_name: "",
    location_static_type_id: "",
    location_static_type: {},
    country_id: "",
    country: {},
    city_id: "",
    city: {},
    postal_code_id: "",
    postal_code: {},
    address: "",
    location_coordinates_lat_min: "",
    location_coordinates_long_min: "",
    radius: "",
  });


  /*
  ````````````````````
  HANDLE DIALOG ERRROR
  ````````````````````
  */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);


  return (
    <ContextAttendanceSetting.Provider
      value={{
        setUserTokenState: setUserTokenState,
        userTokenState: userTokenState,

        setSnackbarResponse200: setSnackbarResponse200,
        isSnackbarResponse200: isSnackbarResponse200,

        setMessages: setMessages,
        messages: messages,

        setDataDetailHoriz: setDataDetailHoriz,
        dataDetailHoriz: dataDetailHoriz,

        setSucessDeleteTDL: setSucessDeleteTDL,
        isSuccessDeleteTDL: isSuccessDeleteTDL,

        setSuccessEditTDL: setSuccessEditTDL,
        isSuccessEditTDL: isSuccessEditTDL,

        setSuccessTaskCOMPLETED: setSuccessTaskCOMPLETED,
        isSuccessTaskCOMPLETED: isSuccessTaskCOMPLETED,

        setSuccessTaskINCOMPLETED: setSuccessTaskINCOMPLETED,
        isSuccessTaskINCOMPLETED: isSuccessTaskINCOMPLETED,

        setAnchorEl: setAnchorEl,
        anchorEl: anchorEl,

        setActualResultGlobal: setActualResultGlobal,
        actualResultGlobal: actualResultGlobal,

        // setValueNoteGlobal : setValueNoteGlobal,
        // valueNoteGlobal: valueNoteGlobal,

        setRunFade: setRunFade,
        isRunFade: isRunFade,

        showhideCompleted,
        setShowhideCompleted,
        
        //*
        isModalCreate: isModalCreate,
        setModalCreate: setModalCreate,

        isModalEdit: isModalEdit,
        setModalEdit: setModalEdit,

        isModalDelete: isModalDelete,
        setModalDelete: setModalDelete,
        
        isModalProgress: isModalProgress,
        setModalProgress: setModalProgress,

        dataLocation: dataLocation,
        setDataLocation: setDataLocation,

        /*
        ````````````````````
        HANDLE DIALOG ERRROR
        ````````````````````
        */
        isOpenDialogError: isOpenDialogError,
        setOpenDialogError: setOpenDialogError,

        textErrorInformation: textErrorInformation,
        setTextErrorInformation: setTextErrorInformation,

        errorStatus: errorStatus,
        setErrorStatus: setErrorStatus,

        listError: listError,
        setListError: setListError,
      }}>
      {props.children}
    </ContextAttendanceSetting.Provider>
  );
};

export default ContextGlobalAttendanceSetting;
