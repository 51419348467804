import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import AxiosConfig from '../../../../../../constants/config-axios';
import { URL_API } from '../../../../../../constants/config-api';
import handleError from '../../../../../Report/components/global/handleError';

import BarChartDashboard from './BarChartDashboard';
import DialogError from '../../../../../../components/DialogError';

import MyLang from '../../../../../../utilities/MyLang';
import {
  bahasa,
  english,
} from '../../../../../../constants/config-lang';

const BarChart = ({
  classes,
  period,
  structureUnitTypeId,
  structureUnitTypeName,
  unitType,
  is_admin,
  is_full_access,
  is_superior,
  divisionData,
  period200,
  unit200,
  divisi200,
}) => {
  const [barChartData, setBarChartData] = useState([]);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  // // console.log("UNIT TYPE", unitType);

  let unit_type_id;
  let unit_type_name;
  const units =
    divisionData.length > 0 ? divisionData.map((el) => el.id) : [];
  const unitName =
    divisionData.length > 0
      ? divisionData.map((el) => el.structure_unit_name)[0]
      : '';

  if (is_admin || is_full_access) {
    // // console.log("Ini Admin");
    unit_type_id = unitType !== undefined ? unitType.id : '';
    unit_type_name = unitType !== undefined ? unitType.name : '';
  }

  if (is_superior && !is_admin && !is_full_access) {
    // // console.log("Ini Superior");
    unit_type_id = '';
    unit_type_name = unitName;
  }

  if (!is_superior && !is_admin && !is_full_access) {
    // // console.log("Ini User");
    unit_type_id = structureUnitTypeId;
    unit_type_name = structureUnitTypeName;
  }

  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const dataBar = {
    name: 'Bar Chart',
    platform: 'web',
    report_format: 'bar_chart',
    filter_by: {
      entity: [],
      date_range: 'all_time',
      user: [],
      unit: is_admin || is_full_access ? [] : units,
      status: [3],
      period_id: period !== undefined ? period.id : '',
      achievement: [
        {
          field: 'goal',
          cal_method: [
            { formula: '_value_ >= 100' },
            { formula: '_value_ >= 85 && _value_ <= 100' },
            { formula: '_value_ < 85' },
          ],
        },
        {
          field: 'ma',
          cal_method: [
            { formula: '_value_ >= 100' },
            { formula: '_value_ >= 85 && _value_ <= 100' },
            { formula: '_value_ < 85' },
          ],
        },
      ],
    },
    entities: ['goal', 'ma'],
    group_by:
      !is_admin && !is_full_access && !is_superior
        ? ['full_name']
        : unit_type_id !== ''
        ? ['structure_unit_name']
        : is_superior
        ? ['full_name']
        : ['full_name'],
    group_by_calculation: 'avg',
    order_by: [
      { field: 'full_name', method: 'ASC', name: 'User Name' },
    ],
    primary_column: 'overall_result_achievement',
    unit_type_id:
      !is_admin && !is_full_access && !is_superior
        ? ''
        : unit_type_id !== undefined || unit_type_id !== ''
        ? unit_type_id
        : '',
    limit_count_number: 0,
    week_start: 'monday',
    date_format: 'd m Y',
    summary_only: false,
  };

  // // console.log("unit_type_id", unit_type_id);
  // // console.log("dataBar", dataBar);

  useEffect(() => {
    setLoading(true);

    if (period200 === 200 && unit200 === 200 && divisi200 === 200) {
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=1&per_page=8`,
        dataBar
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("RES SUMMARY BAR", res);

          if (res.status === 200) {
            setBarChartData(
              result.results !== null ? result.results.data : []
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [period, unit_type_id]);

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Typography className={classes.title333} variant="subtitle1">
          <b>
            {MyLang() ? bahasa.achievement : english.achievement}{' '}
            {unit_type_name !== ''
              ? unit_type_name
              : MyLang()
              ? bahasa.company
              : english.company}
          </b>
        </Typography>

        <Box>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress
                size={16}
                style={{ color: '#d64253', marginRight: 5 }}
              />
              <Typography
                className={classes.title888}
                variant="subtitle1"
              >
                {MyLang() ? bahasa.loading : english.loading}...
              </Typography>
            </Box>
          ) : (
            <BarChartDashboard
              classes={classes}
              data={barChartData}
              is_all_access={is_admin || is_full_access}
            />
          )}
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default BarChart;
