import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleCheckInCheckOut";
import { navigate } from "hookrouter";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  Box,
  Button,
  Paper,
  List,
  ListItem,
} from "@material-ui/core";
import { CancelRounded, CheckCircle, Domain } from "@material-ui/icons";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
import { isEmpty } from "lodash";

import ContextCheckInCheckOut from "./Context/ContextCheckInCheckOut";

import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";

import PanelCard from "./Components/PanelCard";
import HistoryItem from "./Components/HistoryItem";
import PinDialog from "../MyAttendance/Components/PinDialog";
import PasswordDialog from "../MyAttendance/Components/PasswordDialog";
import ErrorDialog from "../MyAttendance/Components/ErrorDialog";
import SuccessDialog from "../MyAttendance/Components/SuccessDialog";
import LiveAttendanceDialog from "./Components/LiveAttendanceDialog";
import DialogProgress from "../Account/Components/DialogProgress";

import { isAuthenticated } from "../MyAttendance/Hook/isAuthenticated";
import { ToHistoryCheckInOut } from "../../../constants/config-redirect-url";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ViewCheckInCheckOut = () => {
  const classes = useStyles();
  const context = useContext(ContextCheckInCheckOut);

  const [verificationMethod, setVerificationMethod] = useState([]);
  const [historyCheck, setHistoryCheck] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isClockIn, setIsClockIn] = useState(false);
  const [isCheckIn, setIsCheckIn] = useState(false);
  const [pinDialog, setPinDialog] = useState(false);
  const [pinValue, setPinValue] = useState(false);
  const [wrongPinAttempt, setWrongPinAttempt] = useState(1);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [passwordValue, setPasswordValue] = useState({
    password: "",
    showPassword: false,
  });
  const [wrongPasswordAttempt, setWrongPasswordAttempt] = useState(1);
  const [liveAttendanceDialog, setLiveAttendanceDialog] = useState(false);
  const [liveAttendanceValue, setLiveAttendanceValue] = useState({
    time: moment().format("HH:mm"),
    long: 0,
    lat: 0,
    location: "",
    note: null,
    photo: null,
    schedule_id: null,
    attendance_id: null,
  });
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [successStatus, setSuccessStatus] = useState("");

  //pin
  const handlePinDialogClose = () => {
    setPinDialog(false);
    setPinValue(false);
  };

  const verificationPin = async () => {
    //// console.log("verificationPin", pinValue);
    if (pinValue !== false) {
      try {
        setLoader(true);
        await axiosConfig
          .post(`${URL_API}/human-resource/attendance/pin-verification`, {
            Attendance: {
              pin: pinValue,
            },
          })
          .then((res) => {
            if (res.data.info.status === 200) {
              setPinDialog(false);
              setPinValue(false);
              if (verificationMethod && verificationMethod.verification) {
                if (
                  verificationMethod.verification.verification_member_password
                ) {
                  setPasswordDialog(true);
                } else {
                  setLiveAttendanceDialog(true);
                }
              }
              setWrongPinAttempt(1);
            }
          });
      } catch (error) {
        setErrorStatus(
          `${
            verificationMethod.check_in === null ? "Check In" : "Check Out"
          } gagal!`
        );
        if (error.response.data.info.status === 400) {
          if (
            wrongPinAttempt ===
            verificationMethod.verification.verification_member_pin_max_attempts
          ) {
            handlePinDialogClose();
            setErrorMessage(`${wrongPinAttempt} kali salah memasukan pin!`);
            setWrongPinAttempt(1);
          } else {
            setWrongPinAttempt(wrongPinAttempt + 1);
            setErrorMessage(
              `${error.response.data.info.message} (${wrongPinAttempt}/${verificationMethod.verification.verification_member_pin_max_attempts})`
            );
          }
        } else {
          setErrorMessage(error.response.data.info.message);
        }
        setErrorDialog(true);
      } finally {
        setLoader(false);
      }
    }
  };

  //password
  const handlePasswordDialogClose = () => {
    setPasswordDialog(false);
    setPasswordValue({
      password: "",
    });
  };

  const verificationPassword = async () => {
    if (passwordValue.password !== "") {
      try {
        setLoader(true);
        await axiosConfig
          .post(`${URL_API}/human-resource/attendance/password-verification`, {
            Attendance: {
              password: passwordValue.password,
            },
          })
          .then((res) => {
            if (res.data.info.status === 200) {
              setPasswordDialog(false);
              setPasswordValue({
                password: "",
              });
              setLiveAttendanceDialog(true);
              setWrongPasswordAttempt(1);
            }
          });
      } catch (error) {
        setErrorStatus(
          `${
            verificationMethod.check_in === null ? "Check In" : "Check Out"
          } gagal!`
        );
        if (error.response.data.info.status === 400) {
          if (
            wrongPasswordAttempt ===
            verificationMethod.verification
              .verification_member_password_max_attempts
          ) {
            handlePasswordDialogClose();
            setErrorMessage(
              `${wrongPasswordAttempt} kali salah memasukan password!`
            );
            setWrongPasswordAttempt(1);
          } else {
            setWrongPasswordAttempt(wrongPasswordAttempt + 1);
            setErrorMessage(
              `${error.response.data.info.message} (${wrongPasswordAttempt}/${verificationMethod.verification.verification_member_password_max_attempts})`
            );
          }
        } else {
          setErrorMessage(error.response.data.info.message);
        }
        setErrorDialog(true);
      } finally {
        setLoader(false);
      }
    }
  };

  const handlePasswordChange = (prop) => (event) => {
    setPasswordValue({ ...passwordValue, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPasswordValue({
      ...passwordValue,
      showPassword: !passwordValue.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // live attendance
  const handleLiveAttendanceDialogClose = () => {
    setLiveAttendanceDialog(false);
  };

  const dataURItoBlob = () => {
    var binary = atob(liveAttendanceValue.photo.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  const verificationLiveAttendance = async () => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.set("Attendance[time]", moment().format("YYYY-MM-DD HH:mm"));
      formData.set("Attendance[long]", liveAttendanceValue.long);
      formData.set("Attendance[lat]", liveAttendanceValue.lat);
      formData.set("Attendance[note]", liveAttendanceValue.note);
      formData.set("Attendance[attendance_id]", verificationMethod.clock_in.id);

      const pictureFile = dataURItoBlob();
      formData.append("Attendance[photo]", pictureFile);

      if (verificationMethod.check_in === null) {
        let response = await axiosConfig.post(
          `${URL_API}/human-resource/attendance/check-in`,
          formData
        );
        // console.log("response", response);
        setLiveAttendanceDialog(false);
        setSuccessDialog(true);
        setSuccessStatus("Check In Berhasil");
        setLiveAttendanceValue({
          time: moment().format("HH:mm"),
          lat: 0,
          long: 0,
          location: "",
          note: null,
          photo: null,
          schedule_id: null,
          attendance_id: null,
        });
        setIsCheckIn(true);
      } else {
        formData.set("Attendance[check_id]", verificationMethod.check_in.id);
        let response = await axiosConfig.post(
          `${URL_API}/human-resource/attendance/check-out`,
          formData
        );
        // console.log("response", response);
        setLiveAttendanceDialog(false);
        setSuccessDialog(true);
        setSuccessStatus("Check Out Berhasil");
        setLiveAttendanceValue({
          time: moment().format("HH:mm"),
          lat: 0,
          long: 0,
          location: "",
          note: null,
          photo: null,
          schedule_id: null,
          attendance_id: null,
        });
        setIsCheckIn(false);
      }
    } catch (error) {
      // console.log("error", error);
      setErrorDialog(true);
      setErrorMessage("Check In gagal!");
      setErrorStatus(
        `${
          verificationMethod.check_in === null ? "Check In" : "Check Out"
        } gagal!`
      );
    } finally {
      setLoader(false);
    }
  };

  // error
  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  // success
  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
  };

  useEffect(
    () => {
      getVerificationMethod();
      fetchHistoryCheck();
    } /* eslint-disable-line */,
    [isCheckIn]
  );

  useEffect(() => {
    if (
      verificationMethod.clock_in !== null &&
      verificationMethod.clock_in?.check_in !== null
    ) {
      setIsClockIn(true);
    } else if (
      verificationMethod.clock_in !== null &&
      verificationMethod.clock_in?.clock_out !== null
    ) {
      setIsClockIn(false);
      navigate("/attendance-dashboard");
    } else if (verificationMethod.schedule_detail == null) {
      navigate("/attendance-dashboard");
    } else if (isEmpty(verificationMethod.schedule_detail)) {
      navigate("/attendance-dashboard");
    } else {
      setIsClockIn(false);
      navigate("/attendance-dashboard");
    }

    if (verificationMethod.schedule_detail == null) {
      navigate("/attendance-dashboard");
    } else if (isEmpty(verificationMethod.schedule_detail)) {
      navigate("/attendance-dashboard");
    }

    if (!isAuthenticated()) {
      navigate("/");
    }
  }, [verificationMethod]);

  const getVerificationMethod = async () => {
    try {
      setLoader(true);
      const { data } = await axiosConfig
        .get(`${URL_API}/human-resource/attendance/verification-method`)
        .then((res) => {
          return res.data;
        });
      setVerificationMethod(data);
      if (
        data.check_in !== null &&
        data.check_in?.clock_in !== null &&
        data.check_in?.clock_out === null
      ) {
        setIsCheckIn(true);
      } else {
        setIsCheckIn(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const fetchHistoryCheck = async () => {
    let response;
    try {
      setLoader(true);
      response = await axiosConfig.get(
        `${URL_API}/human-resource/attendance/history/check-in`,
        {
          params: {
            "options[filter][search]": moment().format("YYYY-MM-DD"),
          },
        }
      );
      if (!isEmpty(response.data.data)) {
        /*let sliceHistoryCheck1 = response.data.data[0].log.slice(0, 2);
        let sliceHistoryCheck2 = response.data.data[1]?.log.slice(0, 2);
        let sliceHistoryCheck3 = response.data.data[2]?.log.slice(0, 2);
        setHistoryCheck(
          sliceHistoryCheck1.concat(sliceHistoryCheck2, sliceHistoryCheck3)
        );*/
        if (response.data.data.length === 1) {
          let sliceHistoryCheck1 = response.data.data[0].log.slice(0, 2);
          setHistoryCheck(sliceHistoryCheck1);
        } else if (response.data.data.length === 2) {
          let sliceHistoryCheck1 = response.data.data[0].log.slice(0, 2);
          let sliceHistoryCheck2 = response.data.data[1]?.log.slice(0, 2);
          setHistoryCheck(sliceHistoryCheck1.concat(sliceHistoryCheck2));
        } else if (response.data.data.length === 3) {
          let sliceHistoryCheck1 = response.data.data[0].log.slice(0, 2);
          let sliceHistoryCheck2 = response.data.data[1]?.log.slice(0, 2);
          let sliceHistoryCheck3 = response.data.data[2]?.log.slice(0, 2);
          setHistoryCheck(
            sliceHistoryCheck1.concat(sliceHistoryCheck2, sliceHistoryCheck3)
          );
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <PanelCard
                verificationMethod={verificationMethod}
                isCheckIn={isCheckIn}
                setPinDialog={setPinDialog}
                setPasswordDialog={setPasswordDialog}
                setLiveAttendanceDialog={setLiveAttendanceDialog}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bold", color: "#262261" }}
                  >
                    History Check In &amp; Out
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="right">
                    <Button
                      variant="outlined"
                      style={{
                        background: "#fff",
                        color: "#221E5B",
                        borderRadius: 64,
                      }}
                      onClick={() => {
                        navigate(ToHistoryCheckInOut);
                      }}
                    >
                      <b>Lihat Semua</b>
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {!isEmpty(historyCheck) ? (
                  historyCheck.map((log, index) => (
                    <HistoryItem data={log} key={index} />
                  ))
                ) : (
                  <Typography
                    variant="h5"
                    style={{ color: "#221E5B", textAlign: "center" }}
                  >
                    <b>Tidak ada data history hari ini</b>
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        <PinDialog
          open={pinDialog}
          handleClose={handlePinDialogClose}
          verification={verificationPin}
          pinValue={pinValue}
          setPinValue={setPinValue}
        />

        <PasswordDialog
          open={passwordDialog}
          handleClose={handlePasswordDialogClose}
          verification={verificationPassword}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          handleChange={handlePasswordChange}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        />

        <LiveAttendanceDialog
          open={liveAttendanceDialog}
          handleClose={handleLiveAttendanceDialogClose}
          verification={verificationLiveAttendance}
          liveAttendanceValue={liveAttendanceValue}
          setLiveAttendanceValue={setLiveAttendanceValue}
          verificationMethod={verificationMethod}
          isCheckIn={isCheckIn}
        />

        <ErrorDialog
          open={errorDialog}
          handleClose={handleErrorDialogClose}
          status={errorStatus}
          message={errorMessage}
        />

        <SuccessDialog
          open={successDialog}
          handleClose={handleSuccessDialogClose}
          status={successStatus}
          liveAttendanceValue={liveAttendanceValue}
        />

        <DialogProgress
          classes={classes}
          isModalProgress={loader}
          setModalProgress={setLoader}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewCheckInCheckOut;
