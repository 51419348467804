import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogCreate = (props) => {
  const {
    classes,
    isModalCreate,
    setModalCreate,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    masterWorkPattern,
    setMasterWorkPattern,
    workPattern,
    setWorkPattern,
    listError,
    createWorkPattern,
    optionDurationMonthly,
    selectedDurationMonthly,
    setSelectedDurationMonthly,
    handleChangeInterval,
    handleChangeWorkDuration,
    handleChangeOffDuration,
  } = props;

  const context = useContext(ContextAttendanceSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalCreate}
        onClose={() => setModalCreate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
          disableTypography={true}
        >
          <Typography variant="h4">Buat Work Pattern</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <Box p={1} my={1}>
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                Nama Work Pattern
              </Typography>
              <Box my={0.5} />
              <TextField
                className={classes.textField}
                size="small"
                variant="outlined"
                fullWidth
                placeholder="e.g. Work Pattern..."
                value={workPattern.name}
                onChange={(event, newValue) => {
                  let workPatternTemporary = workPattern;
                  workPatternTemporary.name = event.target.value;
                  setWorkPattern({...workPatternTemporary});
                }}
                error={listError.some(item => 'name' === item.key)}
                helperText={listError.map((item) => {
                  if (item.key === 'name') {
                    return item.description;
                  }
                })}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                Pilih Interval
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                options={masterWorkPattern.masterIntervalCollections}
                getOptionLabel={(option) => {
                  if (option && option.name) {
                     return option.name;
                  } else {
                    return "";
                  }
                }}
                value={workPattern.interval_obj}
                onChange={(event, newValue) => {
                  handleChangeInterval(newValue);
                }}
                noOptionsText="Tidak ada pilihan"
                popupIcon={<ArrowDropDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Pilih Interval"
                    error={listError.some(item => 'interval' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'interval') {
                        return item.description;
                      }
                    })}
                  />
                )}
              />
            </div>
            <Box my={2.5} />
            <Grid container spacing={2}>
              {
                (workPattern.interval === "Weekly" || workPattern.interval === "Custom"|| workPattern.interval === "") && 
                  <Grid item md={4}>
                    <Typography variant="subtitle2" className={classes.title333}>
                      Total Duration
                    </Typography>
                    <Box my={0.5} />
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Hari</InputAdornment>,
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. 1, 2..."
                      type="number"
                      value={workPattern.duration}
                      onChange={(event, newValue) => {
                        let workPatternTemporary = workPattern;
                        if (!isNaN(event.target.value) && event.target.value !== "") {
                          workPatternTemporary.duration = parseInt(event.target.value);
                          if (
                            !isNaN(workPattern.work_duration) && 
                            !isNaN(workPattern.off_duration) && 
                            (workPattern.work_duration + workPattern.off_duration) > parseInt(event.target.value)) {
                              workPatternTemporary.work_duration  = "";
                              workPatternTemporary.off_duration   = "";
                          } else {
                            workPatternTemporary.work_duration  = parseInt(event.target.value);
                            workPatternTemporary.off_duration   = 0;
                          }
                        } else {
                          workPatternTemporary.duration = "";
                          workPatternTemporary.work_duration  = "";
                          workPatternTemporary.off_duration   = "";
                        }
                        setWorkPattern({...workPatternTemporary});
                      }}
                      error={listError.some(item => 'duration' === item.key)}
                      helperText={listError.map((item) => {
                        if (item.key === 'duration') {
                          return item.description;
                        }
                      })}
                    />

                  </Grid>
              }
              {
                (workPattern.interval === "Monthly") && 
                  <Grid item md={4}>
                    <Typography variant="subtitle2" className={classes.title333}>
                      Total Duration
                    </Typography>
                    <Box my={0.5} />
                    <Autocomplete
                      options={optionDurationMonthly}
                      getOptionLabel={(option) => {
                        if (option && option.name) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      value={selectedDurationMonthly}
                      onChange={(event, newValue) => {
                        let workPatternTemporary       = workPattern;
                        workPatternTemporary.duration  = newValue ? newValue.id : '';
                        setWorkPattern({...workPatternTemporary});
                        setSelectedDurationMonthly(newValue);
                        if (
                          !isNaN(workPattern.work_duration) && 
                          !isNaN(workPattern.off_duration) && 
                          !isNaN(newValue.id) && 
                          (workPattern.work_duration + workPattern.off_duration) > parseInt(newValue.id)) {
                            workPatternTemporary.work_duration  = "";
                            workPatternTemporary.off_duration   = "";
                        }  else {
                          workPatternTemporary.work_duration  = parseInt(newValue.id );
                          workPatternTemporary.off_duration   = 0;
                        }
                        setWorkPattern({...workPatternTemporary});
                      }}
                      noOptionsText="Tidak ada pilihan"
                      popupIcon={<ArrowDropDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          style={{ fontSize: 13 }}
                          {...params}
                          fullWidth
                          variant="outlined"
                          size="small"
                          label=""
                          error={listError.some(item => 'interval' === item.key)}
                          helperText={listError.map((item) => {
                            if (item.key === 'interval') {
                              return item.description;
                            }
                          })}
                        />
                      )}
                    />
                  </Grid>
              }
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  Work Duration
                </Typography>
                <Box my={0.5} />
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Hari</InputAdornment>,
                  }}
                  size="small"
                  variant="outlined"
                  fullWidth
                  placeholder="e.g. 1, 2..."
                  type="number"
                  value={workPattern.work_duration}
                  onChange={(event, newValue) => {
                    handleChangeWorkDuration(event);
                  }}
                  error={listError.some(item => 'work_duration' === item.key)}
                  helperText={listError.map((item) => {
                    if (item.key === 'work_duration') {
                      return item.description;
                    }
                  })}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  Off Duration
                </Typography>
                <Box my={0.5} />
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Hari</InputAdornment>,
                  }}
                  size="small"
                  variant="outlined"
                  fullWidth
                  placeholder="e.g. 1, 2..."
                  type="number"
                  value={workPattern.off_duration}
                  onChange={(event, newValue) => {
                    handleChangeOffDuration(event);
                  }}
                  error={listError.some(item => 'off_duration' === item.key)}
                  helperText={listError.map((item) => {
                    if (item.key === 'off_duration') {
                      return item.description;
                    }
                  })}
                />
              </Grid>

            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setModalCreate(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant="outlined"
              className={classes.button1}
              onClick={createWorkPattern}
            >
              Simpan !
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogCreate;