import {
  SET_VALUE_TAB,
  TOGGLE_DRAWER_CHART,
  GET_REPORT_CATEGORY,
  GET_SYNC,
} from "../types";

// ~ Initial State for Report ~
export const initialReport = {
  valueTab: 1,
  category: "detailed",
  group: "goal-ma",
  drawerChart: false,
  reportCategories: [],
  lastSync: "",
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Report ~
export const reportReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_VALUE_TAB:
      return {
        ...state,
        valueTab: payload.valueTab,
        category: payload.category,
      };
    case GET_REPORT_CATEGORY:
      return { ...state, reportCategories: payload.data };
    case TOGGLE_DRAWER_CHART:
      return { ...state, drawerChart: payload };
    case GET_SYNC:
      return { ...state, lastSync: payload.lastSync };
    default:
      return state;
  }
};
