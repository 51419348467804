export const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    // width: '503px',
    // height: '42px',s
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "white",
    textTransform: "capitalize",
    "&:disabled": {
      background: "grey",
      color: "white",
    },
  },
  buttonOutlined: {
    borderRadius: 5,
    // background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "grey",
    // color: '#d2d2d2',
    textTransform: "capitalize",
    "&:hover": {
      color: "grey",
      background: "#d2d2d2",
      borderWidth: 0,
    },
  },
  title: {
    fontFamily: "Roboto",
  },
  titleHeader: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop:  theme.spacing(2),
  },
  titleForm: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop: theme.spacing(2),
  },
  titleActive: {
    color: "#cc0707",
    fontFamily: "Roboto",
  },
  titleNotActive: {
    fontFamily: "Roboto",
    color: "grey",
  },
  paperInfoIdentitas: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  paperInfoDasar: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },

  /*
        ````````
        FORM

        ````````
    */
  textField: {
    minWidth: 425,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  list: {
    // padding: theme.spacing(5, 2),
    // marginTop: theme.spacing(4),
    width: 675,
    borderRadius: 7,
    fontFamily: "Roboto",
  },
  textFieldAvatar: {
    minWidth: 125,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  realAvatar: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(25),
  },
  bigAvatar: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(25),
    cursor: "pointer",
  },
  bigAvatarSpan: {
    color: "#cc0707",
    cursor: "pointer",
    marginRight: theme.spacing(15),
  },

  /*
        ````````````````
        INFO ERROR PAPER

        ````````````````
    */
  paperStyle: {
    // marginLeft : theme.spacing(3),
    // marginRight : theme.spacing(3),
    // marginBottom : theme.spacing(3),
    backgroundColor: "#ffcbcb",
  },
  titlePaperStyle: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    // marginLeft: theme.spacing(3),
    fontFamily: "Roboto",
    color: "#a50016",
  },
  closePaperStyle: {
    color: "#a50016",
  },
});

{
  /* {
                isFormFilledAll == false ? (
                    <Grid container>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <Paper 
                                    elevation={1} 
                                    className={classes.paperStyle} 
                                    // onClick={(e) => handlePaper(e, item)}
                                
                                >
                                    <Typography variant='subtitle1' className={classes.titlePaperStyle} style={{textAlign: 'left'}}>
                                        <b>Anda belum mengisi semua data yang wajib di isi !</b>
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}></Grid>
                    </Grid>

                ) : null
            } */
}
