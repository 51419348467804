import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Icon,
} from "@material-ui/core";

import Redirect from "../../../utilities/Redirect";
import { ToMembershipStatusManageSEMENTARA } from "../../../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 2,
  },
  title: {
    fontFamily: "Roboto",
  },
  titleMenangguhkan: {
    color: "#c1272d",
    cursor: "pointer",
    fontFamily: "Roboto",
    // marginTop: theme.spacing(16),
    // marginLeft: theme.spacing(4),
  },
  titleBack: {
    fontFamily: "Roboto",
    color: "#333333",
    cursor: "pointer",
    fontWeight: "bold",
  },
  icon: {
    color: "white",
    position: "absolute",
  },
  button: {
    // width: '503px',
    height: "42px",
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginLeft:theme.spacing(1),
    // marginRight:theme.spacing(8),
    marginBottom: theme.spacing(6),
    color: "white",
    textTransform: "capitalize",
  },
});

const ViewMembershipStatusDeactivate = (props) => {
  const { classes } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              Tangguhkan Masa Aktif Keanggotaan
            </Typography>

            <br />
            <br />
            <Typography variant="subtitle1" className={classes.title}>
              <b>Perhatian !</b>
            </Typography>
            <br />
            <br />
            <ol>
              <li>
                <Typography variant="subtitle1" className={classes.title}>
                  Dengan menangguhkan masa aktif, keanggotaan Anda akan di
                  hentikan sementara
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" className={classes.title}>
                  Selama masa penangguhan, hanya <b>Super Admin</b> yang di
                  perbolehkan untuk <i>Sign-in</i>
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" className={classes.title}>
                  Selama masa penangguhan, hanya <b>Super Admin</b> hanya di
                  berikan hak akses untuk membaca data
                </Typography>
              </li>
            </ol>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              className={classes.titleMenangguhkan}>
              Lanjutkan untuk menangguhkan masa aktif keanggotaan
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography
              variant="subtitle1"
              className={classes.titleBack}
              onClick={() => Redirect(ToMembershipStatusManageSEMENTARA)}>
              Kembali
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewMembershipStatusDeactivate);
