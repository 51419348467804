import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import ReactPlayer from "react-player";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
  fileText: {
    fontFamily: "Roboto",
    fontWeight: "600",
  },
}));

function DialogPreviewVideo({
  openDialogPreviewVideo,
  setOpenDialogPreviewVideo,
  itemId,
  itemName,
  origin,
}) {
  const classes = useStyles();

  const [videoPreview, setVideoPreview] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openDialogPreviewVideo) {
      setLoading(true);

      if (origin === "Drive") {
        axiosConfig
          .get(`${URL_API}/drive/file/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setVideoPreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "EvidenceDrive") {
        axiosConfig
          .get(`${URL_API}/drive/file/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setVideoPreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "Gallery") {
        axiosConfig
          .get(`${URL_API}/drive/gallery/goal-result-evidence/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setVideoPreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "GalleryMA") {
        axiosConfig
          .get(
            `${URL_API}/drive/gallery/measured-activity-result-evidence/${itemId}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setVideoPreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      }
    }
  }, [openDialogPreviewVideo]);

  const blobUrlVideo = new Blob([videoPreview], { type: "video/mp4" });

  const handleClosePreview = () => {
    setLoading(false);
    setOpenDialogPreviewVideo(false);
  };

  return (
    <>
      {loading ? (
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={handleClosePreview}
        >
          <CircularProgress style={{ color: "#f28181" }} />
        </Backdrop>
      ) : (
        <Dialog
          open={openDialogPreviewVideo}
          aria-labelledby="customized-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl" //*Default "md"
          style={{ height: "auto", width: "100%" }}
        >
          <DialogTitle id="customized-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" className={classes.fileText}>
                {itemName}
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setOpenDialogPreviewVideo(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <ReactPlayer
              url={
                videoPreview !== ""
                  ? URL.createObjectURL(blobUrlVideo)
                  : undefined
              }
              playing={true}
              controls={true}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default DialogPreviewVideo;
