import React, { useContext, useState } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  Button,
  IconButton,
  ListItemText,
  Box,
  Tooltip,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';

import moment from 'moment';

import axiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';

import ContextGoalDetail from '../Context/ContextGoalDetail';
import { FormatDecimal } from '../../../../utilities/FormatDecimal';
import {
  StyledMenu,
  StyledMenuItem,
} from '../../../../components/StyledMenuDropdown';
import DialogEditResult from './DialogEditResult';
import DialogDelete from './DialogDelete';
import DialogError from '../../../../components/DialogError';
import DialogEvidences from './DialogEvidences';

const ListItemGoalResult = ({
  classes,
  item,
  rupiahMode,
  goalDetailState,
  setFireGoalDetail,
  isStatusCompleted,
  currentCyle,
  collectionGoalResultList,
  userToken,
}) => {
  const context = useContext(ContextGoalDetail);

  const [anchorElListGoalResult, setAnchorElListGoalResult] =
    useState(null);
  const [openDialogEditResult, setOpenDialogEditResult] =
    useState(false);
  const [isModalDelete, setModalDelete] = useState(false);
  const [openEvidences, setOpenEvidences] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  const handleDropdownOpenGoalResult = (event) => {
    event.preventDefault();
    setAnchorElListGoalResult(event.currentTarget);
  };

  function handleDropdownCloseGoalResult() {
    setAnchorElListGoalResult(null);
  }

  const handleDialogDelete = (e, data) => {
    e.preventDefault();

    handleDropdownCloseGoalResult();
    setModalDelete(true);
  };

  const handleUnlock = () => {
    axiosConfig
      .post(
        `${URL_API}/swd/goal/${goalDetailState?.id}/result/unlock/${item?.id}`
      )
      .then((res) => {
        if (res.status === 200) {
          setFireGoalDetail(true);
          context.seTriggerGR(Math.floor(Math.random() * 1000 + 1));
        }
      })
      .catch((error) => {
        setFireGoalDetail(false);
        // console.log("Error  : ", error.response);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if (
            [503, 500, 403, 429, 405, 404, 401].includes(
              responseStatus
            )
          ) {
            if (error.response.data.info.hasOwnProperty('message')) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` +
                  error.response.data.info.message
              );
            }
          }
        }
      });
  };

  // let editAllowed = false;
  // let deleteAllowed = false;

  // item?.policy &&
  //   item?.policy.forEach((item) => {
  //     if (item.actionName === "update") {
  //       editAllowed = item.result;
  //     } else if (item.actionName === "delete") {
  //       deleteAllowed = item.result;
  //     }
  //   });

  // console.log("ITEM currentCyle", currentCyle);

  return (
    <TableRow style={{ backgroundColor: '#fbfbfb' }}>
      <TableCell
        className={classes.txtBody}
        align="left"
        style={{ borderLeft: '1px solid #e9e9e9' }}
      >
        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{
            color: currentCyle
              ? '#55dc87'
              : item?.is_locked
              ? '#888'
              : !currentCyle
              ? '#888'
              : '#55dc87',
          }}
        >
          <b>
            {rupiahMode
              ? `Rp ${FormatDecimal(item.result_value)}`
              : goalDetailState.valueType.id === '2'
              ? FormatDecimal(item.result_value) + ' %'
              : FormatDecimal(item.result_value)}
          </b>
        </Typography>
      </TableCell>
      <TableCell className={classes.txtBody} align="left">
        <Tooltip
          title={item.description !== null ? item.description : '-'}
          placement="top-end"
        >
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: 'grey' }}
          >
            {item.description !== null ? item.description : '-'}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.txtBody} align="left">
        <Typography
          variant="subtitle2"
          className={classes.title555}
          style={{ opacity: item?.is_locked ? 0.5 : 1 }}
        >
          <i style={{ fontSize: 12 }}>
            {`${moment(item.result_date).format('DD MMM YYYY')}`}
          </i>
          ,
          <i style={{ fontSize: 12 }}>
            {' '}
            {item.result_time?.slice(0, 5)}
          </i>
        </Typography>
      </TableCell>
      <TableCell className={classes.txtBody} align="left">
        <Box display="flex" alignItems="center" width={70}>
          <IconButton
            size="medium"
            onClick={() => setOpenEvidences(true)}
            disabled={item.is_locked}
          >
            <PhotoLibraryOutlinedIcon style={{ color: '#888' }} />
          </IconButton>
          <Typography variant="caption" className={classes.title888}>
            + {item.goalResultEvidence?.length}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        className={classes.txtBody}
        align="right"
        style={{ borderRight: '1px solid #e9e9e9' }}
      >
        {goalDetailState.goal_category === 'OPERATIONAL' &&
          item?.is_locked === false && (
            <IconButton
              size="small"
              disabled={
                isStatusCompleted === true
                  ? true
                  : goalDetailState.goal_category !== 'OPERATIONAL'
                  ? true
                  : false
              }
              onClick={handleDropdownOpenGoalResult}
            >
              <MoreHorizIcon />
            </IconButton>
          )}

        {goalDetailState.goal_category === 'OPERATIONAL' &&
          item?.is_locked && (
            <Button
              startIcon={
                <LockOpenRoundedIcon
                  style={{ color: '#55dc87', fontSize: 14 }}
                />
              }
              size="small"
              className={classes.expandGRBtn}
              onClick={handleUnlock}
            >
              <Typography
                className={classes.titleGRList}
                variant="subtitle2"
                style={{ color: '#55dc87' }}
              >
                Unlock
              </Typography>
            </Button>
          )}

        <StyledMenu
          id="customized-menu-goal-result"
          anchorEl={anchorElListGoalResult}
          keepMounted
          open={Boolean(anchorElListGoalResult)}
          onClose={handleDropdownCloseGoalResult}
        >
          <StyledMenuItem
            // disabled={!editAllowed}
            onClick={() => {
              setOpenDialogEditResult(true);
              setAnchorElListGoalResult(null);
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                >
                  <b>Edit</b>
                </Typography>
              }
            />
          </StyledMenuItem>
          <StyledMenuItem
            // disabled={!deleteAllowed}
            onClick={(e) => {
              handleDialogDelete(e, item);
              setAnchorElListGoalResult(null);
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                >
                  <b>Delete</b>
                </Typography>
              }
            />
          </StyledMenuItem>
        </StyledMenu>
      </TableCell>

      <DialogEditResult
        openDialogEditResult={openDialogEditResult}
        setOpenDialogEditResult={setOpenDialogEditResult}
        setFireGoalDetail={setFireGoalDetail}
        goalDetailState={goalDetailState}
        classes={classes}
        item={item}
        evidenceCount={item.goalResultEvidence?.length}
      />

      <DialogDelete
        classes={classes}
        isModalDelete={isModalDelete}
        setModalDelete={setModalDelete}
        setFireGoalDetail={setFireGoalDetail}
        goalResultId={item.id}
        goalDetailState={goalDetailState}
      />

      <DialogEvidences
        classes={classes}
        openEvidences={openEvidences}
        setOpenEvidences={setOpenEvidences}
        item={item}
        setFireGoalDetail={setFireGoalDetail}
        goalDetailState={goalDetailState}
        collectionGoalResultList={collectionGoalResultList}
        userToken={userToken}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </TableRow>
  );
};

export default ListItemGoalResult;
