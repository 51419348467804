import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Typography,
  Drawer,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import FormatAlignLeftRoundedIcon from '@material-ui/icons/FormatAlignLeftRounded';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

import moment from 'moment';
import { navigate } from 'hookrouter';
import queryString from 'query-string';

import axiosConfig from '../../constants/config-axios';
import { URL_API } from '../../constants/config-api';
import { AntTabs, AntTab } from './style/tabStyle';

import { styles, theme } from './style/styleProject';
import ContextProject from './context/ContextProject';
import {
  TASK_TEAM_COLLECTIONS,
  DRAWER_OPEN,
  GANTT_MAP_FORM,
  SEARCH_TASK,
  SET_TASK_DETAILS,
  FILTER_TASK,
  ASSIGNEE_CONTENT_FILTER,
  SET_PAGINATE_TASK,
  PROJECT_TEAM_DETAILS,
} from './context/types';
import handleError from '../Report/components/global/handleError';
import TableTask from './components/tables/TableTask';
import DialogError from '../../components/DialogError';
import TaskDetails from './components/task_components/TaskDetails';
import TaskInfoTop from './components/task_components/TaskInfoTop';
import AchievementBar from './components/global/AchievementBar';
import ViewTimeLine from './ViewTimeLine';
import {
  mapProjectGantt,
  mapTaskGantt,
} from './components/global/mapTaskGantt';
import mapGroupGantt from './components/global/mapGroupGantt';
import CircularBar from './components/global/CircularBar';
import CircularBarNA from './components/global/CircularBarNA';
import ViewKanban from './ViewKanban';

const useStyles = makeStyles(styles);

const ViewProjectDetail = () => {
  const classes = useStyles();
  const project_id = window.location.pathname.split('/')[2];
  // const status_login = localStorage.getItem("status_user_login");
  // const user = JSON.parse(status_login);
  // const member_position = user?.member_position;
  // const userRole = user?.userRole[0]?.name;
  // const its_me = `${user?.member_first_name} ${user?.member_last_name}`;
  const {
    projectTState,
    projectTDispatch,
    triggerTask,
    triggerKanban,
    setSelectTask,
    openSuccessImportTasks,
    setOpenSuccessImportTasks,
    setTriggerTask,
    setTriggerKanban,
  } = useContext(ContextProject);

  const getTask = localStorage.getItem('task_details');

  if (getTask === 'undefined') {
    // // console.log("ceki", getTask);
    localStorage.removeItem('task_details');
  }

  const [projects, setProjects] = useState(null);
  const [value, setValue] = useState(0);
  const [existTasks, setExisitTasks] = useState([]);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    if (parsed?.task === 'list') {
      setValue(0);
      projectTDispatch({
        type: SET_PAGINATE_TASK,
        payload: {
          limitTask: 50,
          offsetTask: 0,
        },
      });
    }
    if (parsed?.task === 'gantt') {
      setValue(1);
      projectTDispatch({
        type: SET_PAGINATE_TASK,
        payload: {
          limitTask: 0,
          offsetTask: 0,
        },
      });
    }
    if (parsed?.task === 'kanban') {
      setValue(2);
    }

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
  }, []);

  useEffect(() => {
    projectTDispatch({
      type: TASK_TEAM_COLLECTIONS,
      payload: {
        loadTask: true,
        taskData: projectTState.taskData,
        groupTaskData: projectTState.groupTaskData,
        countTask: 0,
        defaultTaskGroup: projectTState.defaultTaskGroup,
      },
    });

    // ===== DEFINES ======
    let BASE_URL = `${URL_API}/project/${project_id}/result?options[paging][limit]=${projectTState.limitTask}&options[paging][offset]=${projectTState.offsetTask}&options[filter][search]=${projectTState.searchTask}&group_by=${projectTState.groupByInList}`;

    // Filter
    let PERIODS_URL = `&${projectTState.periods}`;
    let ASSIGNEE_URL = `&${projectTState.filterAssignee}`;
    let ACHIEVEMENT_URL = `&${projectTState.filterAchievement}`;
    let STATUS_URL = `&${projectTState.filterStatus}`;
    let PRIORITY_URL = `&${projectTState.filterPriority}`;
    let COMPLETE_URL = `&${projectTState.filterComplete}`;
    let GROUPS_URL = `&${projectTState.filterGroup}`;
    // Sort
    let SORT_BY_DATE = `&${projectTState.sortByDate}`;
    let SORT_TITLE_TASK = `&${projectTState.sortTitleTask}`;

    // if (COND_5) // console.log("cek con 5");
    // if (COND_3) // console.log("cek con 3");
    // // console.log("ASSIGNEE_URL", ASSIGNEE_URL);
    // // console.log("FINAL_URL", FINAL_URL);
    // // console.log("projectTState.filterPriority;", projectTState.filterPriority);

    const arrFilter = [
      PERIODS_URL,
      ASSIGNEE_URL,
      ACHIEVEMENT_URL,
      STATUS_URL,
      PRIORITY_URL,
      COMPLETE_URL,
      SORT_TITLE_TASK,
      SORT_BY_DATE,
      GROUPS_URL,
    ];

    const combinedURL = arrFilter
      .filter((el) => el !== '&null')
      .join('');

    // // console.log("arrFilter", arrFilter);
    // // console.log("BASE_URL", BASE_URL);
    // // console.log("combinedURL", combinedURL);

    axiosConfig
      .get(`${BASE_URL}${combinedURL}`)
      .then((res) => {
        const result = res.data.data;
        const tasks = result?.todolists;
        const groups = result?.task_groups;
        const project = result.project;
        const count = res.data.info.count;

        // console.log("RES Proj Detail", result);

        const tasks_form = mapTaskGantt(
          result.hasOwnProperty('todolists') ? tasks : [],
          project
        );
        const project_form = mapProjectGantt(project);
        const groups_form = mapGroupGantt(
          result.hasOwnProperty('task_groups') ? groups : [],
          project
        );

        const defaultTaskGroup =
          result.hasOwnProperty('task_groups') && groups?.length > 0
            ? groups.filter(
                (group) => group.name === 'Default Task Group'
              )[0]
            : null;

        // // console.log("defaultTaskGroup", defaultTaskGroup);

        if (res.status === 200) {
          projectTDispatch({
            type: TASK_TEAM_COLLECTIONS,
            payload: {
              loadTask: false,
              taskData: result.hasOwnProperty('todolists')
                ? tasks
                : [],
              groupTaskData: result.hasOwnProperty('task_groups')
                ? groups
                : [],
              countTask: count,
              defaultTaskGroup,
            },
          });

          projectTDispatch({
            type: GANTT_MAP_FORM,
            payload: {
              taskForm: tasks_form,
              projectForm: project_form,
              groupsForm: [...project_form, ...groups_form],
            },
          });

          projectTDispatch({
            type: PROJECT_TEAM_DETAILS,
            payload: {
              projectDetails: result.project,
            },
          });

          setProjects(project);

          // for task details ============
          if (
            getTask !== null &&
            getTask !== undefined &&
            getTask !== 'undefined'
          ) {
            // // console.log("OKB", getTask);

            const task = JSON.parse(getTask);

            if (result.hasOwnProperty('todolists')) {
              if (tasks?.length > 0) {
                const newTask = tasks?.find(
                  (el) => el.id === task?.id
                );

                // // console.log("NEWTASK", newTask);

                projectTDispatch({
                  type: SET_TASK_DETAILS,
                  payload: { taskDetails: newTask },
                });

                localStorage.setItem(
                  'task_details',
                  JSON.stringify(newTask)
                );
              }
            }

            if (result.hasOwnProperty('task_groups')) {
              if (groups?.length > 0) {
                const newGroup = groups?.find(
                  (el) => el.id === task?.task_group_id
                );
                const newTask =
                  newGroup?.todolists.length > 0
                    ? newGroup?.todolists?.find(
                        (el) => el.id === task?.id
                      )
                    : null;
                // // console.log("newGroup", newGroup);
                projectTDispatch({
                  type: SET_TASK_DETAILS,
                  payload: { taskDetails: newTask },
                });

                localStorage.setItem(
                  'task_details',
                  JSON.stringify(newTask)
                );
              }
            }
          }

          setExisitTasks([...tasks_form, ...groups_form]);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        projectTDispatch({
          type: TASK_TEAM_COLLECTIONS,
          payload: {
            loadTask: false,
            taskData: projectTState.taskData,
            groupTaskData: projectTState.groupTaskData,
            countTask: 0,
            defaultTaskGroup: projectTState.defaultTaskGroup,
          },
        });

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  }, [project_id, triggerTask, triggerKanban, value]);

  const handleBack = () => {
    navigate('/project');
    localStorage.removeItem('project_details');
    setSelectTask([]);

    projectTDispatch({
      type: SEARCH_TASK,
      payload: { searchTask: '' },
    });

    projectTDispatch({
      type: TASK_TEAM_COLLECTIONS,
      payload: {
        loadTask: false,
        taskData: [],
        groupTaskData: [],
        countTask: 0,
        defaultTaskGroup: null,
      },
    });

    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: null,
        filterPriority: null,
        filterComplete: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
  };

  const handleDrawer = () => {
    projectTDispatch({
      type: DRAWER_OPEN,
      payload: {
        drawerTask: false,
        actionTask: 'update',
      },
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccessImportTasks(false);
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      projectTDispatch({
        type: SET_PAGINATE_TASK,
        payload: {
          limitTask: 50,
          offsetTask: 0,
        },
      });
    }

    if (newValue === 1) {
      projectTDispatch({
        type: SET_PAGINATE_TASK,
        payload: {
          limitTask: 0,
          offsetTask: 0,
        },
      });
    }
  };

  // ================================================================
  // Conditional SCOPE
  // ================================================================
  let ACHIEVEMENT = null;

  if (projects !== null) {
    switch (projects.achievement_calculation_method) {
      case 'SCOPE':
        ACHIEVEMENT = projects.project_scope_results;
        break;
      case 'SCHEDULE':
        ACHIEVEMENT = projects.project_schedule_results;
        break;
      case 'SCOPE SCHEDULE':
        ACHIEVEMENT = projects.project_progress_results;
        break;
      default:
        ACHIEVEMENT = null;
    }
  }

  console.log('projects', projects);
  console.log('ACHIEVEMENT', ACHIEVEMENT);

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root} bgcolor="#F9F9F9">
        <Box
          mr={3}
          mt={12}
          zIndex={100}
          className={classes.layoutMarginLeftAndTop}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #e8e8e8"
          bgcolor="#F9F9F9"
          position="fixed"
          width="100%"
        >
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            {listProjects.map((tab, i) => (
              <AntTab
                key={i}
                label={
                  <Box display="flex" alignItems="center">
                    {tab.icon}
                    {tab.name}
                  </Box>
                }
              />
            ))}
          </AntTabs>

          <Box>{/* <AnchorFilterTask classes={classes} /> */}</Box>
        </Box>
        <Box
          mt={14.2}
          ml={9}
          pr={10}
          position="fixed"
          zIndex={100}
          width="100%"
        >
          <Paper className={classes.shadowSection2}>
            <Box p={2}>
              {/* CONTENT HEADER */}
              <Box display="flex">
                <IconButton
                  style={{ maxHeight: 50 }}
                  onClick={handleBack}
                >
                  <ArrowBackRoundedIcon style={{ color: '333' }} />
                </IconButton>

                <Box width={75}>
                  {ACHIEVEMENT !== null ? (
                    <CircularBar
                      percentage={Number(ACHIEVEMENT) * 100}
                    />
                  ) : (
                    <CircularBarNA />
                  )}
                </Box>

                <Box ml={2} display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h5"
                      className={classes.title333}
                    >
                      <b>{projects?.name}</b>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        background:
                          projects?.status_project === 'LATE'
                            ? '#C8203C'
                            : projects?.status_project === 'ON TRACK'
                            ? '#518e59'
                            : '#999',
                        color: '#fff',
                        marginLeft: 16,
                        padding: '1px 8px',
                        borderRadius: 5,
                      }}
                    >
                      {projects?.status_project}
                    </Typography>
                  </Box>
                  <Box mt={1} display="flex">
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >{`${moment(projects?.start_date).format(
                      'DD MMM YYYY'
                    )} - ${moment(projects?.end_date).format(
                      'DD MMM YYYY'
                    )}`}</Typography>
                    <Box mx={0.5} />
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      {projects?.exclude_weekend === 1
                        ? '(Exclude weekend)'
                        : '(Include weekend)'}
                    </Typography>
                  </Box>
                  {value === 0 && (
                    <Box width={500} mt={0.8}>
                      {projects !== null && (
                        <AchievementBar
                          classes={classes}
                          percentage={Number(projects?.progress)}
                          origin="head"
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>

              {/* {value === 0 && ( */}
              <TaskInfoTop
                classes={classes}
                project={projects}
                tabValue={value}
              />
              {/* )} */}
              <Box mt={1} display="flex" justifyContent="flex-end">
                <Tooltip title="Setiap Task terdapat Checklist yang harus di centang">
                  <Box display="flex" alignItems="center">
                    <InfoRoundedIcon
                      style={{ fontSize: 14, color: '#555' }}
                    />
                    <Box mx={0.2} />
                    <Typography
                      variant="caption"
                      className={classes.title333}
                    >
                      Informasi
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
        </Box>

        <br />

        {/* CONTENT BODY */}
        <Box mt={value === 0 ? 37 : 25} ml={9} mr={1}>
          <Paper className={classes.shadowSection}>
            <Box p={2}>
              {value === 0 && (
                <TableTask classes={classes} project={projects} />
              )}
              {value === 1 && <ViewTimeLine project={projects} />}
              {value === 2 && <ViewKanban project={projects} />}
            </Box>
          </Paper>
        </Box>

        <Drawer
          anchor="right"
          open={projectTState.drawerTask}
          onClose={handleDrawer}
        >
          <TaskDetails classes={classes} />
        </Drawer>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbar
        open={openSuccessImportTasks}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Success Import Tasks!
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ViewProjectDetail;

const listProjects = [
  {
    name: 'List',
    icon: (
      <FormatListBulletedIcon
        style={{ fontSize: 16, marginRight: 5 }}
      />
    ),
  },
  {
    name: 'Gantt Chart',
    icon: (
      <FormatAlignLeftRoundedIcon
        style={{ fontSize: 16, marginRight: 5 }}
      />
    ),
  },
  {
    name: 'Kanban',
    icon: (
      <ViewColumnOutlinedIcon
        style={{ fontSize: 16, marginRight: 5 }}
      />
    ),
  },
];
