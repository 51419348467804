import React, { useState, useContext, useEffect } from "react";
import { Paper, Box, Typography, IconButton, Menu } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import PieChart from "./PieChart";
import ListItemMenu from "./ListItemMenu";
import ContextReports from "../../../../context/ContextReports";

const OverviewAverage = ({ classes }) => {
  const { pieState, summaryState } = useContext(ContextReports);

  ///STATE
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [overview, setOverview] = useState({
    name: "Overview Goal",
    code: "goal",
  });

  useEffect(() => {
    if (summaryState.selectedEntities?.length === 1) {
      if (summaryState.selectedEntities[0] === "ma") {
        setOverview({ code: "ma", name: "Overview MA" });
      } else {
        setOverview({ code: "goal", name: "Overview Goal" });
      }
    } else {
      setOverview({ code: "goal", name: "Overview Goal" });
    }
  }, [summaryState.selectedEntities]);

  const handleShowAnchorMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseAnchorMenu = () => {
    setAnchorMenu(null);
  };

  // console.log("sum s endty", summaryState.entities);
  // // console.log("PIE STATE", pieState);
  // // console.log("SUmState STATE", summaryState.selectedEntities);

  return (
    <Paper className={classes.shadowSection}>
      <Box padding={3}>
        {/* ====== Title & Icon More ====== */}
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title333} variant="subtitle1">
            <b>{overview.name}</b>
          </Typography>
          <IconButton size="small" onClick={handleShowAnchorMenu}>
            <MoreHorizIcon fontSize="small" style={{ color: "#888" }} />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={anchorMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorMenu)}
            onClose={handleCloseAnchorMenu}
          >
            <ListItemMenu
              classes={classes}
              overview={overview}
              setOverview={setOverview}
              setAnchorMenu={setAnchorMenu}
            />
          </Menu>
        </Box>
        {/* ====== Pie Chart ====== */}
        <Box>
          {overview.code === "goal" &&
            summaryState.selectedEntities?.includes("goal") && (
              <PieChart classes={classes} dataChart={pieState.pieDataGoal} />
            )}
          {overview.code === "ma" &&
            summaryState.selectedEntities?.includes("ma") && (
              <PieChart classes={classes} dataChart={pieState.pieDataMa} />
            )}
        </Box>
      </Box>
    </Paper>
  );
};

export default OverviewAverage;
