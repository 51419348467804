import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import numeral from "numeral";

import ProgressBarItem from "../../../ComponentsDashboard/ProgressBarItem";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

const ListItemYourGoal = ({ classes, item, origin }) => {
  let color = "";
  let bgColor = "";

  if (numeral(item).format("0.00") < 85) {
    color = "#e15367";
    bgColor = "#FDDBD8";
  } else if (
    numeral(item).format("0.00") >= 85 &&
    numeral(item).format("0.00") <= 100
  ) {
    color = "#fab445";
    bgColor = "#F8E4B0";
  } else if (numeral(item).format("0.00") > 99) {
    color = "#34cc80";
    bgColor = "#D3F3E8";
  }

  return (
    <Paper className={classes.cardList}>
      <Box mb={1.5} p={2}>
        <Box
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography className={classes.title555} variant="subtitle1">
            <b>
              {MyLang() ? bahasa.perform : english.perform}{" "}
              {origin === "goal" ? "Goal" : "Measured Activity"}
            </b>
          </Typography>
          <Typography
            className={classes.title888}
            variant="subtitle2"
            style={{
              background: bgColor,
              color: color,
              padding: "2px 8px",
              borderRadius: 25,
            }}
          >
            {numeral(item).format("0.00")}%
          </Typography>
        </Box>

        <ProgressBarItem item={item} />
      </Box>
    </Paper>
  );
};

export default ListItemYourGoal;
