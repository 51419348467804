import {
  Box,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  ListItemText,
  Paper,
  Tab,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styles } from "./Style/StyleOvertime";
import { navigate } from "hookrouter";
import moment from "moment";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";

import ContextOvertime from "./Context/ContextOvertime";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core";

import CategoryIcon from "@material-ui/icons/Category";
import DialogProgress from "./Components/DialogProgress";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";
import EnhancedTableToolbar from "./Components/EnhancedTableToolbar";
import { Button, Table } from "react-bootstrap";
import EnhancedTableHead from "./Components/EnhancedTableHead";
import DialogDetail from "./Components/DialogDetail";
import OvertimeListMenu from "./Components/OvertimeListMenu";
import ListCardItemOvertime from "./Components/ListCardItemOvertime";
import PaginationOvertime from "./Components/PaginationOvertime";
import TableFilter from "./Components/TableFilter";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewOvertime = (props) => {
  const classes = useStyles();
  const context = useContext(ContextOvertime);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const [roleName, setRoleName] = useState([]);
  const [userID, setUserID] = useState([]);

  useEffect(() => {
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      // setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);
      setUserID(statusUserLoginAfterParse.user_id);
      if (statusUserLoginAfterParse.member_position && statusUserLoginAfterParse.member_position.is_superior) {
        setRoleName('manage');
      }

      if (statusUserLoginAfterParse.userRole.length > 0) {
        if (statusUserLoginAfterParse.userRole[0].name === "HR") {
          setRoleName('hr');
        }
        if (statusUserLoginAfterParse.userRole[0].name === "superadmin") {
          setRoleName(statusUserLoginAfterParse.userRole[0].name);
        }
        if (statusUserLoginAfterParse.userRole.length > 1) {
          if (statusUserLoginAfterParse.userRole[1].name === "admin") {
            setRoleName(statusUserLoginAfterParse.userRole[1].name);
          }
        }
      } else {
        // console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
      }
    }
    getOvertime();
  }, []);

  const [masterCategory, setMasterCategory] = useState({
    masterStatusCollections: [],
  });

  const [dataOvertimeList, setDataOvertimeList] = useState({
    options: {
      filter: {
        search: "",
        status: "",
        status_obj: null,
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 1,
      },
    },
    list: [],
    detail: {},
  });

  const getOvertime = () => {
    context.setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/overtime` +
          `?options[filter][search]=${dataOvertimeList.options.filter.search}` +
          `&options[filter][status]=${dataOvertimeList.options.filter.status}` +
          `&options[filter][date]=${dataOvertimeList.options.filter.date}` +
          `&options[paging][limit]=${dataOvertimeList.options.paging.limit}` +
          `&options[paging][offset]=${dataOvertimeList.options.paging.offset}`
      )
      .then(async function (response) {
        console.log("Response Original : ", response.data.master.status);
        // console.log("Response Original : ", response.data);
        let dataOvertimeListTemporary = dataOvertimeList;
        dataOvertimeListTemporary.list = response.data.data;
        dataOvertimeListTemporary.options.paging.count =
          response.data.info.count;
        setDataOvertimeList({ ...dataOvertimeListTemporary });
        let masterStatusCollections = [];
        if (response.data.master && response.data.master.status) {
          await response.data.master.status.forEach((item) => {
            let masterStatus = {
              id: item,
              name: item,
            };
            console.log("Response Original : ", item);
            masterStatusCollections.push(masterStatus);
          })

        }
        let masterCategoryTemp = masterCategory;
        masterCategoryTemp.masterStatusCollections = masterStatusCollections
        setMasterCategory({ ...masterCategoryTemp })
        context.setLoader(false);
      })
      .catch(function (error) {
        context.setLoader(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  // status '0' then 'rejected'
  // status '1' then 'pending'
  // status '99' then 'approved'

  const handleApprove = (item) => {
    context.setModalProgress(true);
    let status_approve = [];
    item.status_approve.map((approve, key) => {
      status_approve.push({
        approver: approve.approver,
        status: "99",
        status_name: "approved",
        comment: approve.comment,
      });
    });
    let data = {
      approver: item.approver,
      status_approve: status_approve,
    };
    
    axiosConfig
      .post(`${URL_API}/human-resource/overtime/${item.id}/approve`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setAnchorEl(null);
        setAnchorIn(null);
        context.setModalProgress(false);
        context.setMessages("Approve berhasil");
        context.setSnackbarResponse200(true);
        refreshList(null, 0, 1, null);
      })
      .catch(function (error) {
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.message
          ) {
            context.setErrorStatus(error.response.data.info.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleReject = (item) => {
    context.setModalProgress(true);
    let status_approve = [];
    item.status_approve.map((approve, key) => {
      status_approve.push({
        approver: approve.approver,
        status: "0",
        status_name: "rejected",
        comment: approve.comment,
      });
    });
    let data = {
      approver: item.approver,
      status_approve: status_approve,
    };

    axiosConfig
      .post(`${URL_API}/human-resource/overtime/${item.id}/reject`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setAnchorEl(null);
        setAnchorIn(null);
        context.setModalProgress(false);
        context.setMessages("Reject berhasil");
        context.setSnackbarResponse200(true);
        refreshList(null, 0, 1, null);
      })
      .catch(function (error) {
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.message
          ) {
            context.setErrorStatus(error.response.data.info.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleDetail = (item) => {
    context.setModalProgress(true);
    axiosConfig
      .get(`${URL_API}/human-resource/overtime/${item.id}`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        let dataTerm = dataOvertimeList;
        dataTerm.detail = response.data.data;
        setDataOvertimeList({ ...dataTerm });
        setAnchorEl(null);
        setAnchorIn(null);
        context.setModalProgress(false);
        context.setModalDetail(true);
      })
      .catch(function (error) {
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataOvertimeList.options.paging.limit * (newPage - 1),
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 1, parseInt(event.target.value));
  };

  const refreshList = (search, offset, page, limit) => {
    let dataOvertimeListTemporary = dataOvertimeList;
    dataOvertimeListTemporary.options.filter.search =
      search !== null
        ? search
        : dataOvertimeListTemporary.options.filter.search;
    dataOvertimeListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataOvertimeListTemporary.options.paging.offset;
    dataOvertimeListTemporary.options.paging.page =
      page !== null ? page : dataOvertimeListTemporary.options.paging.page;
    dataOvertimeListTemporary.options.paging.limit =
      limit !== null ? limit : dataOvertimeListTemporary.options.paging.limit;
    setDataOvertimeList({ ...dataOvertimeListTemporary });
    getOvertime();
  };

  const getStatus = (status_approve, approver) => {
    let status = "";
    if (status_approve) {
      status_approve.map((item, key) => {
        if (item && item.status && item.status === "1") {
          status = item.status;
        } else if (status !== "1" && item && item.status) {
          status = item.status;
        }
      });
    }
    return status;
  };

  const getStatusName = (status_approve, approver) => {
    let status = "";
    if (status_approve) {
      status_approve.map((item, key) => {
        if (item && item.status && item.status === "1") {
          status = item.status_name;
        } else if (status !== "1" && item && item.status) {
          status = item.status_name;
        }
      });
    }
    return status;
  };

  const timeConvert = (second) => {
    var minute = second / 60;
    var num = minute;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " Jam " + rminutes + " Menit";
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        {roleName === "superadmin" && (
          <OvertimeListMenu classes={classes} />
        )}

        <TableFilter
          classes={classes}
          masterCategory={masterCategory}
          dataOvertimeList={dataOvertimeList}
          setDataOvertimeList={setDataOvertimeList}
          getOvertime={getOvertime}
        />
        <EnhancedTableHead classes={classes} />

        {context.loader ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={15}
          >
            <CircularProgress
              size={20}
              style={{ marginRight: 10, color: "#d64253" }}
            />
            <Typography className={classes.title555} variant="subtitle1">
              Loading...
            </Typography>
          </Box>
        ) : (
          <Table
            className={classes.table}
            aria-label="enhanced table"
            style={{ marginTop: "16px", paddingRight: "24px" }}
          >
            <TableBody mt={2}>
              {dataOvertimeList &&
                dataOvertimeList.list &&
                dataOvertimeList.list.length > 0 &&
                dataOvertimeList.list.map((item, i) => {
                  return (
                    <ListCardItemOvertime
                      keyItem={i}
                      key={i}
                      classes={classes}
                      item={item}
                      dataOvertimeList={dataOvertimeList}
                      handleApprove={handleApprove}
                      handleReject={handleReject}
                      handleShowModalDetail={handleDetail}
                      getStatusName={getStatusName}
                      timeConvert={timeConvert}
                      getStatus={getStatus}
                      userTokenState={context.userTokenStateGlobal}
                      roleName={roleName}
                      userID={userID}
                    />
                  );
                })}
            </TableBody>
          </Table>
        )}

        {dataOvertimeList.list.length === 0 && context.loader !== true && (
          <Box width="100%" style={{ paddingRight: 24 }}>
            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                <IconButton disabled>
                  <AlarmAddIcon style={{ color: "#d64253" }} />
                </IconButton>
                <b>
                  Tidak ada Request Time Off yang dapat di tampilkan saat ini ;(
                </b>
              </Typography>
            </Paper>
          </Box>
        )}

        <Box ml={1} mr={2}>
          <PaginationOvertime
            classes={classes}
            dataOvertimeList={dataOvertimeList}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
      <DialogDetail
        classes={classes}
        isModalDetail={context.isModalDetail}
        setModalDetail={context.setModalDetail}
        userLoginName={context.userLoginName}
        dataOvertimeList={dataOvertimeList}
        timeConvert={timeConvert}
      />
    </MuiThemeProvider>
  );
};

export default ViewOvertime;
