import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleMyAttendance";
import { navigate } from "hookrouter";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Paper,
  List,
  ListItem,
} from "@material-ui/core";
import { CancelRounded, CheckCircle } from "@material-ui/icons";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
import { isEmpty } from "lodash";

import ContextGlobalMyAttendance from "./Context/ContextGlobalMyAttendance";
import { isAuthenticated } from "./Hook/isAuthenticated";

import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";

import PinDialog from "./Components/PinDialog";
import PasswordDialog from "./Components/PasswordDialog";
import LiveAttendanceDialog from "./Components/LiveAttendanceDialog";
import RequestExceptionDialog from "./Components/RequestExceptionDialog";
import ErrorDialog from "./Components/ErrorDialog";
import SuccessDialog from "./Components/SuccessDialog";
import DialogProgress from "../Account/Components/DialogProgress";

import ImageCheckSuccess from "../../../assets/images/success_check.png";
import ImageCheckWarning from "../../../assets/images/warning_check.png";

import { ToRequestAttendance } from "../../../constants/config-redirect-url";
import ExceptionSuccessDialog from "./Components/ExceptionSuccessDialog";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ViewMyAttendance = () => {
  const classes = useStyles();
  const context = useContext(ContextGlobalMyAttendance);
  const [loader, setLoader] = useState(false);
  const [pinDialog, setPinDialog] = useState(false);
  const [pinValue, setPinValue] = useState(false);
  const [wrongPinAttempt, setWrongPinAttempt] = useState(1);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [passwordValue, setPasswordValue] = useState({
    password: "",
    showPassword: false,
  });
  const [wrongPasswordAttempt, setWrongPasswordAttempt] = useState(1);
  const [liveAttendanceDialog, setLiveAttendanceDialog] = useState(false);
  const [liveAttendanceValue, setLiveAttendanceValue] = useState({
    time: moment().format("HH:mm"),
    long: 0,
    lat: 0,
    location: "",
    location_address: "",
    note: null,
    photo: null,
    schedule_id: null,
    attendance_id: null,
    clock_in_schedule_status: null,
    clock_in_location_status: null,
    check_image: null,
  });
  const [verificationMethod, setVerificationMethod] = useState([]);
  const [historyAttendance, setHistoryAttendance] = useState([]);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [successStatus, setSuccessStatus] = useState("");
  const [successExceptionDialog, setSuccessExceptionDialog] = useState(false);
  const [successExceptionStatus, setSuccessExceptionStatus] = useState("");
  const [isClockIn, setIsClockIn] = useState(false);

  const [shiftList, setShiftList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  const [requestExceptionDialog, setRequestExceptionDialog] = useState(false);
  const [requestExceptionValue, setRequestExceptionValue] = useState({
    date_before: null,
    shift_id: null,
    schedule_id: null,
    date_after: null,
    clock_in: null,
    clock_out: null,
    clock_location: null,
    deskripsi: "",
    detail_schedule: [],
    master_location: [],
  });
  const [requestExceptionStaticValue, setRequestExceptionStaticValue] =
    useState({
      clock_in: null,
      clock_out: null,
      clock_location: null,
      detail_schedule: [],
    });

  const [verification, setVerification] = useState({
    pin: false,
    password: false,
  });

  //pin
  const handlePinDialogClose = () => {
    setPinDialog(false);
    setPinValue(false);
  };

  const verificationPin = async () => {
    //// console.log("verificationPin", pinValue);
    if (pinValue !== false) {
      try {
        setLoader(true);
        await axiosConfig
          .post(`${URL_API}/human-resource/attendance/pin-verification`, {
            Attendance: {
              pin: pinValue,
            },
          })
          .then((res) => {
            if (res.data.info.status === 200) {
              setPinDialog(false);
              setPinValue(false);
              if (verificationMethod && verificationMethod.verification) {
                if (
                  verificationMethod.verification.verification_member_password
                ) {
                  setPasswordDialog(true);
                } else {
                  setLiveAttendanceDialog(true);
                }
              }
              setVerification({
                ...verification,
                pin: true,
              });
              setWrongPinAttempt(1);
            }
          });
      } catch (error) {
        setErrorStatus(
          `${
            verificationMethod.clock_in === null ? "Clock In" : "Clock Out"
          } gagal!`
        );
        if (error.response.data.info.status === 400) {
          if (
            wrongPinAttempt ===
            verificationMethod.verification.verification_member_pin_max_attempts
          ) {
            handlePinDialogClose();
            setErrorMessage(`${wrongPinAttempt} kali salah memasukan pin!`);
            setWrongPinAttempt(1);
          } else {
            setWrongPinAttempt(wrongPinAttempt + 1);
            setErrorMessage(
              `${error.response.data.info.message} (${wrongPinAttempt}/${verificationMethod.verification.verification_member_pin_max_attempts})`
            );
          }
        } else {
          setErrorMessage(error.response.data.info.message);
        }
        setVerification({
          ...verification,
          pin: false,
        });
        setErrorDialog(true);
      } finally {
        setLoader(false);
      }
    }
  };

  //password
  const handlePasswordDialogClose = () => {
    setPasswordDialog(false);
    setPasswordValue({
      password: "",
    });
  };

  const verificationPassword = async () => {
    if (passwordValue.password !== "") {
      try {
        setLoader(true);
        await axiosConfig
          .post(`${URL_API}/human-resource/attendance/password-verification`, {
            Attendance: {
              password: passwordValue.password,
            },
          })
          .then((res) => {
            if (res.data.info.status === 200) {
              setPasswordDialog(false);
              setPasswordValue({
                password: "",
              });
              setLiveAttendanceDialog(true);
              setVerification({
                ...verification,
                password: true,
              });
              setWrongPasswordAttempt(1);
            }
          });
      } catch (error) {
        setErrorStatus(
          `${
            verificationMethod.clock_in === null ? "Clock In" : "Clock Out"
          } gagal!`
        );
        if (error.response.data.info.status === 400) {
          if (
            wrongPasswordAttempt ===
            verificationMethod.verification
              .verification_member_password_max_attempts
          ) {
            handlePasswordDialogClose();
            setErrorMessage(
              `${wrongPasswordAttempt} kali salah memasukan password!`
            );
            setWrongPasswordAttempt(1);
          } else {
            setWrongPasswordAttempt(wrongPasswordAttempt + 1);
            setErrorMessage(
              `${error.response.data.info.message} (${wrongPasswordAttempt}/${verificationMethod.verification.verification_member_password_max_attempts})`
            );
          }
        } else {
          setErrorMessage(error.response.data.info.message);
        }
        setVerification({
          ...verification,
          password: false,
        });
        setErrorDialog(true);
      } finally {
        setLoader(false);
      }
    }
  };

  const handlePasswordChange = (prop) => (event) => {
    setPasswordValue({ ...passwordValue, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPasswordValue({
      ...passwordValue,
      showPassword: !passwordValue.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // live attendance
  const handleLiveAttendanceDialogClose = () => {
    setLiveAttendanceDialog(false);
  };

  const dataURItoBlob = () => {
    var binary = atob(liveAttendanceValue.photo.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  const verificationLiveAttendance = async () => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.set("Attendance[time]", moment().format("YYYY-MM-DD HH:mm:ss"));
      formData.set("Attendance[long]", liveAttendanceValue.long);
      formData.set("Attendance[lat]", liveAttendanceValue.lat);
      formData.set("Attendance[note]", liveAttendanceValue.note);

      const pictureFile = dataURItoBlob();
      formData.append("Attendance[photo]", pictureFile);

      if (verificationMethod.clock_in === null) {
        formData.set(
          "Attendance[schedule_id]",
          verificationMethod.schedule ? verificationMethod.schedule.id : null
        );
        let response = await axiosConfig.post(
          `${URL_API}/human-resource/attendance/clock-in`,
          formData
        );
        setLiveAttendanceValue({
          ...liveAttendanceValue,
          time: moment(response.data.data.clock_in).format("HH:mm:ss"),
          long: response.data.data.clock_in_coordinates_long,
          lat: response.data.data.clock_in_coordinates_lat,
          location: response.data.data.location,
          note: response.data.data.clock_in_note,
          photo: response.data.data.clock_in_photo_path,
          schedule_id: response.data.data.schedule_id,
          attendance_id: null,
          clock_in_schedule_status: response.data.data.clock_in_schedule_status,
          clock_in_location_status: response.data.data.clock_in_location_status,
          check_image:
            response.data.data.clock_in_schedule_status === "On Time"
              ? response.data.data.clock_in_location_status === 1
                ? ImageCheckSuccess
                : ImageCheckWarning
              : ImageCheckWarning,
        });
        setLiveAttendanceDialog(false);
        setSuccessDialog(true);
        setSuccessStatus("Clock In Berhasil");
        console.log(response.data);
        setIsClockIn(true);
      } else {
        formData.set(
          "Attendance[attendance_id]",
          verificationMethod.clock_in.id
        );
        let response = await axiosConfig.post(
          `${URL_API}/human-resource/attendance/clock-out`,
          formData
        );
        setLiveAttendanceValue({
          ...liveAttendanceValue,
          time: moment(response.data.data.clock_out).format("HH:mm:ss"),
          long: response.data.data.clock_out_coordinates_long,
          lat: response.data.data.clock_out_coordinates_lat,
          location: response.data.data.location,
          note: response.data.data.clock_out_note,
          photo: response.data.data.clock_out_photo_path,
          schedule_id: response.data.data.schedule_id,
          attendance_id: null,
          clock_in_schedule_status:
            response.data.data.clock_out_schedule_status,
          clock_in_location_status:
            response.data.data.clock_out_location_status,
          check_image:
            response.data.data.clock_out_schedule_status === "On Time"
              ? response.data.data.clock_out_location_status === 1
                ? ImageCheckSuccess
                : ImageCheckWarning
              : ImageCheckWarning,
        });
        setLiveAttendanceDialog(false);
        setSuccessDialog(true);
        setSuccessStatus("Clock Out Berhasil");
        console.log(response.data);
        setIsClockIn(false);
      }
    } catch (error) {
      setErrorDialog(true);
      setErrorMessage(error.response.data.info.message);
      setErrorStatus(
        `${
          verificationMethod.clock_in === null ? "Clock In" : "Clock Out"
        } gagal!`
      );
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  // request exception
  const handleRequestDialogClose = () => {
    setRequestExceptionDialog(false);
    setRequestExceptionValue({
      date_before: null,
      shift_id: null,
      schedule_id: null,
      date_after: null,
      clock_in: null,
      clock_out: null,
      clock_location: null,
      deskripsi: "",
      detail_schedule: [],
      master_location: [],
    });
    setRequestExceptionStaticValue({
      clock_in: null,
      clock_out: null,
      clock_location: null,
      detail_schedule: [],
    });
  };

  const verificationRequest = async () => {
    try {
      setLoader(true);
      const data = {
        RequestException: {
          shift_id: requestExceptionValue.shift_id,
          schedule_id: requestExceptionValue.schedule_id,
          date_before: requestExceptionValue.date_before
            .toISOString()
            .split("T")[0],
          date_after: requestExceptionValue.date_after
            .toISOString()
            .split("T")[0],
          clock_in: requestExceptionValue.clock_in,
          clock_out: requestExceptionValue.clock_out,
          location_id: requestExceptionValue.clock_location,
          deskripsi: requestExceptionValue.deskripsi,
          detail_schedule: requestExceptionValue.detail_schedule,
        },
      };

      let response = await axiosConfig.post(
        `${URL_API}/human-resource/request-exception`,
        data
      );

      if (response.data.info.status === 200) {
        setRequestExceptionDialog(false);
        setSuccessExceptionDialog(true);
        setSuccessExceptionStatus("Request Exception Berhasil");
      } else {
        setRequestExceptionDialog(false);
        setErrorDialog(true);
        setErrorMessage("Request Exception Gagal!");
        setErrorStatus("Request Exception Gagal!");
      }
    } catch (error) {
      // console.log("error", error);
      setErrorDialog(true);
      setErrorMessage("Request Exception Gagal!");
      setErrorStatus("Request Exception Gagal!");
    } finally {
      setLoader(false);
    }
  };

  // error
  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  // success
  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
  };

  const handleSuccessExceptionDialogClose = () => {
    setSuccessExceptionDialog(false);
  };

  const getShiftList = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/request-exception/shift`
      );

      setShiftList(response.data.data.shift);
    } catch (error) {
      // console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const getScheduleDetail = async () => {
    try {
      setLoader(true);
      const date_before = requestExceptionValue.date_before;
      if (date_before !== null) {
        const response = await axiosConfig.get(
          `${URL_API}/human-resource/request-exception/${
            date_before.toISOString().split("T")[0]
          }/schedule-detail`
        );

        setRequestExceptionValue({
          ...requestExceptionValue,
          shift_id: response.data.data.shift.id,
          schedule_id: response.data.data.schedule.id,
          clock_in: response.data.data.shift.schedule_in,
          clock_out: response.data.data.shift.schedule_out,
          clock_location: response.data.data.schedule.location_id,
          detail_schedule: response.data.data.schedule_detail
            ? response.data.data.schedule_detail
            : [],
          master_location: response.data.data.masterLocationCollections,
        });
        setRequestExceptionStaticValue({
          ...requestExceptionStaticValue,
          clock_in: response.data.data.shift.schedule_in,
          clock_out: response.data.data.shift.schedule_out,
          clock_location: response.data.data.schedule.location_id,
          detail_schedule: response.data.data.schedule_detail
            ? response.data.data.schedule_detail
            : [],
        });
      } else {
        setRequestExceptionValue({
          ...requestExceptionValue,
          shift_id: null,
          schedule_id: null,
          clock_in: null,
          clock_out: null,
          clock_location: null,
          detail_schedule: [],
          master_location: [],
        });
        setRequestExceptionStaticValue({
          ...requestExceptionStaticValue,
          clock_in: null,
          clock_out: null,
          clock_location: null,
          detail_schedule: [],
        });
      }
    } catch (error) {
      // console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    getScheduleDetail();
  }, [requestExceptionValue.date_before]);

  useEffect(() => {
    getVerificationMethod();
    getHistoryAttendance();
  }, [isClockIn]);

  useEffect(() => {
    async function fetchScheduleList() {
      try {
        setLoader(true);
        const shift_id = requestExceptionValue.shift_id;
        if (shift_id !== null) {
          const response = await axiosConfig.get(
            `${URL_API}/human-resource/request-exception/${requestExceptionValue.shift_id}/schedule`
          );
          setScheduleList(response.data.data.schedule);
        } else {
          setScheduleList([]);
        }
      } catch (error) {
        // console.log("error", error);
      } finally {
        setLoader(false);
      }
    }
    fetchScheduleList();
  }, [requestExceptionValue.shift_id]);

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      getShiftList();
    } /* eslint-disable-line */,
    []
  );

  const getVerificationMethod = async () => {
    try {
      setLoader(true);
      const { data } = await axiosConfig
        .get(`${URL_API}/human-resource/attendance/verification-method`)
        .then((res) => {
          return res.data;
        });
      setVerificationMethod(data);
      if (
        data.clock_in !== null &&
        data.clock_in?.clock_in !== null &&
        data.clock_in?.clock_out === null
      ) {
        setIsClockIn(true);
      } else {
        setIsClockIn(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const getHistoryAttendance = async () => {
    try {
      setLoader(true);
      const { data } = await axiosConfig
        .get(`${URL_API}/human-resource/attendance/history/clock-in`, {
          params: {
            "options[filter][year]": moment().format("YYYY"),
            "options[filter][month]": moment().format("MM"),
            "options[filter][day]": moment().format("DD"),
          },
        })
        .then((res) => {
          return res.data;
        });
      setHistoryAttendance(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100,
          }}
        >
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background:
                "linear-gradient(to left bottom, #221E5B 30%, #3f51b5 90%)",
              borderRadius: 11,
              padding: "10px 20px",
              marginTop: "20px",
            }}
          >
            <CardContent
              style={{
                flex: "1 0 auto",
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    style={{ fontWeight: "bold", color: "#ffffff" }}
                  >
                    <Moment
                      format="HH.mm"
                      locale="id"
                      interval={1}
                      unit="seconds"
                    ></Moment>
                  </Typography>
                  <Typography variant="h6" style={{ color: "#ffffff" }}>
                    <Moment
                      format="dddd, D MMMM YYYY"
                      locale="id"
                      interval={1}
                      unit="seconds"
                    ></Moment>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Card
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      background: "#F5F5F5",
                      borderRadius: 11,
                      padding: "10px 20px",
                    }}
                  >
                    <CardContent
                      style={{
                        flex: "1 0 auto",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ color: "#262261" }}
                          >
                            Jadwal{" "}
                            <Moment
                              format="D MMMM YYYY"
                              locale="id"
                              interval={1}
                              unit="seconds"
                            ></Moment>
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{ fontWeight: "bold", color: "#262261" }}
                          >
                            {verificationMethod?.shift?.schedule_in} -{" "}
                            {verificationMethod?.shift?.schedule_out}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: "bold", color: "#262261" }}
                          >
                            Di{" "}
                            {
                              verificationMethod?.schedule?.location
                                .location_name
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Button
                            variant="contained"
                            style={{
                              background:
                                verificationMethod &&
                                verificationMethod.clock_in !== null &&
                                verificationMethod.clock_in?.clock_in !==
                                  null &&
                                verificationMethod.clock_in?.clock_out === null
                                  ? "#CE354A"
                                  : "#221E5B",
                              color: "#FFFFFF",
                              height: "50px",
                            }}
                            fullWidth
                            onClick={() => {
                              if (
                                verificationMethod &&
                                verificationMethod.verification !== null
                              ) {
                                if (
                                  verificationMethod.verification
                                    .verification_member_pin === 1
                                ) {
                                  if (
                                    verification.pin === false &&
                                    verification.password === false
                                  ) {
                                    setPinDialog(true);
                                  } else if (
                                    verification.pin === true &&
                                    verification.password === false
                                  ) {
                                    setPasswordDialog(true);
                                  } else if (
                                    verification.pin === true &&
                                    verification.password === true
                                  ) {
                                    setLiveAttendanceDialog(true);
                                  }
                                } else if (
                                  verificationMethod.verification
                                    .verification_member_pin === 0
                                ) {
                                  if (
                                    verificationMethod.verification
                                      .verification_member_password === 1
                                  ) {
                                    if (verification.password === false) {
                                      setPasswordDialog(true);
                                    } else if (verification.password === true) {
                                      setLiveAttendanceDialog(true);
                                    }
                                  } else if (
                                    verificationMethod.verification
                                      .verification_member_password === 0
                                  ) {
                                    setLiveAttendanceDialog(true);
                                  }
                                }
                              } else {
                                setLiveAttendanceDialog(true);
                              }
                            }}
                          >
                            {verificationMethod &&
                            verificationMethod.clock_in !== null &&
                            verificationMethod.clock_in?.clock_in !== null &&
                            verificationMethod.clock_in?.clock_out === null
                              ? "Clock Out"
                              : "Clock In"}
                          </Button>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                style={{
                                  marginTop: "1rem",
                                  width: "100%",
                                  height: "3rem",
                                  background: "#F5F5F5",
                                  color: "#221E5B",
                                }}
                                onClick={() => {
                                  navigate(ToRequestAttendance);
                                }}
                              >
                                <b>Request Attendance</b>
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                style={{
                                  marginTop: "1rem",
                                  width: "100%",
                                  height: "3rem",
                                  background: "#F5F5F5",
                                  color: "#221E5B",
                                }}
                                onClick={() => {
                                  setRequestExceptionDialog(true);
                                }}
                              >
                                <b>Request Exception</b>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>

        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 20,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", color: "#262261" }}
              >
                History Clock In &amp; Out
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="right">
                <Button
                  variant="outlined"
                  style={{
                    background: "#fff",
                    color: "#221E5B",
                    borderRadius: 64,
                  }}
                  onClick={() => {
                    navigate("/history-clock-in-out");
                  }}
                >
                  <b>Lihat Semua</b>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 20,
          }}
        >
          {isEmpty(historyAttendance) ? (
            <Typography
              variant="h5"
              style={{
                color: "#221E5B",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Tidak ada data
            </Typography>
          ) : (
            historyAttendance.map((item, index) => (
              <div key={index}>
                {isEmpty(item.log) ? (
                  <Typography
                    variant="h5"
                    style={{
                      color: "#221E5B",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Tidak ada data
                  </Typography>
                ) : (
                  item.log.map((log, index) => (
                    <Paper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        background: "#F5F5F5",
                        borderRadius: 11,
                        padding: "10px 20px",
                        marginTop: "20px",
                      }}
                      variant="outlined"
                      key={index}
                    >
                      <List>
                        <ListItem>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  fontWeight: "bold",
                                }}
                              >
                                ● Clock {log.clock_out ? "Out" : "In"}
                                {log.status_attendance === "1" ? (
                                  <CheckCircle
                                    style={{
                                      color: "#1AC692",
                                      marginLeft: "1rem",
                                    }}
                                  />
                                ) : (
                                  <CancelRounded
                                    style={{
                                      color: "#CE354A",
                                      marginLeft: "1rem",
                                    }}
                                  />
                                )}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ color: "#262261", marginLeft: "1rem" }}
                              >
                                {log.clock_out
                                  ? moment(log.clock_out).format("HH:mm")
                                  : moment(log.clock_in).format("HH:mm")}
                              </Typography>
                              {log.clock_in_note !== "null" &&
                              log.clock_out_note !== "null" ? (
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#262261",
                                    marginLeft: "1rem",
                                  }}
                                >
                                  Catatan:{" "}
                                  {log.clock_in != null
                                    ? log.clock_in_note
                                    : log.clock_out_note}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#262261",
                                    marginLeft: "1rem",
                                  }}
                                >
                                  Catatan: Tidak ada
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                style={{ color: "#221E5B", textAlign: "right" }}
                              >
                                Hari Ini
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    </Paper>
                  ))
                )}
              </div>
            ))
          )}
        </div>

        <PinDialog
          open={pinDialog}
          handleClose={handlePinDialogClose}
          verification={verificationPin}
          pinValue={pinValue}
          setPinValue={setPinValue}
          loader={loader}
        />

        <PasswordDialog
          open={passwordDialog}
          handleClose={handlePasswordDialogClose}
          verification={verificationPassword}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          handleChange={handlePasswordChange}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          loader={loader}
        />

        <LiveAttendanceDialog
          open={liveAttendanceDialog}
          handleClose={handleLiveAttendanceDialogClose}
          verification={verificationLiveAttendance}
          liveAttendanceValue={liveAttendanceValue}
          setLiveAttendanceValue={setLiveAttendanceValue}
          verificationMethod={verificationMethod}
          isClockIn={isClockIn}
          loader={loader}
        />

        <RequestExceptionDialog
          handleClose={handleRequestDialogClose}
          open={requestExceptionDialog}
          verification={verificationRequest}
          value={requestExceptionValue}
          staticValue={requestExceptionStaticValue}
          setValue={setRequestExceptionValue}
          shiftList={shiftList}
          scheduleList={scheduleList}
        />

        <ErrorDialog
          open={errorDialog}
          handleClose={handleErrorDialogClose}
          status={errorStatus}
          message={errorMessage}
        />

        <SuccessDialog
          open={successDialog}
          handleClose={handleSuccessDialogClose}
          status={successStatus}
          liveAttendanceValue={liveAttendanceValue}
        />

        <ExceptionSuccessDialog
          open={successExceptionDialog}
          handleClose={handleSuccessExceptionDialogClose}
          status={successExceptionStatus}
          value={requestExceptionValue}
        />

        <DialogProgress
          classes={classes}
          isModalProgress={loader}
          setModalProgress={setLoader}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewMyAttendance;
