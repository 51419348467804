import React, { useState, useContext } from "react";
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContentText,
  DialogContent,
  Box,
  CircularProgress,
  Button,
} from "@material-ui/core";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogDeleteFolder({
  openDialogDeleteFolder,
  setOpenDialogDeleteFolder,
  item,
  classes,
}) {
  const context = useContext(ContextGlobalDrive);

  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteFolder = () => {
    setLoading(true);

    const params = new URLSearchParams();

    params.append(`DriveFolder[0][id]`, item.id);

    axiosConfig
      .delete(`${URL_API}/drive/folder/batch`, {
        data: params,
      })
      .then((response) => {
        // console.log("Response Delete Folder", response);

        if (response.status === 200) {
          context.setSuccessTriggerFolder(Math.floor(Math.random() * 1000 + 1));
          context.setSuccessTriggerRecoveredFolder(
            Math.floor(Math.random() * 1000 + 1)
          );
          context.setModalResponse200(true);
          context.setMessages("Folder deleted successfully");

          context.setSelectedFolder([]); // Empty Selected Folder
          context.setSelectedFile([]); // Empty Selected File
          setOpenDialogDeleteFolder(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogDeleteFolder}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ textAlign: "center" }}>
        <Box marginTop={2} marginBottom={2}>
          <HelpOutlineRoundedIcon
            style={{ fontSize: 30, color: "#d64253", marginBottom: 12 }}
          />
        </Box>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            Apakah Anda yakin ingin menghapus Folder '
            <strong>{item.name}</strong>' ?
            <br />
            <span style={{ color: "#555", fontSize: 13 }}>
              Folder yang dihapus akan tersimpan di folder <b>Recycle Bin</b>
            </span>
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          marginTop: 20,
          marginBottom: 25,
        }}
      >
        <Button
          variant="contained"
          disableElevation
          className={classes.button2}
          onClick={() => setOpenDialogDeleteFolder(false)}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className={classes.button}
          onClick={handleDeleteFolder}
        >
          {loading ? <CircularProgress color="#fff" size={15} /> : "Delete"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogDeleteFolder;
