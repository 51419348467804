import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Collapse,
  TextField,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { useDebouncedCallback } from "use-debounce";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ListItemStructureUnit from "./ListItemStructureUnit";
import DialogDeleteUnitType from "./DialogDeleteUnitType";
import DialogEditUnitType from "./DialogEditUnitType";
import DialogCreateStructureUnit from "./DialogCreateStructureUnit";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    padding: "2px 15px",
  },
}))(MenuItem);

const ListItemStructureUnitType = ({ classes, item, onTriggerType }) => {
  const [structureUnits, setStructureUnits] = useState([]);
  const [openUnit, setOpenUnit] = useState(false);
  const [search, setSearch] = useState("");
  const [triggerUnitType, setTriggerUnitType] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openDeleteUnitType, setOpenDeleteUnitType] = useState(false);
  const [openEditUnitType, setOpenEditUnitType] = useState(false);
  const [openCreateStructureUnit, setOpenCreateStructureUnit] = useState(false);

  useEffect(() => {
    AxiosConfig.get(
      `${URL_API}/human-resource/master-structure-unit?options[filter][structure_unit_type_id]=${item.id}&options[filter][search]=${search}`
    )
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setStructureUnits(result);
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
      });
  }, [item, triggerUnitType]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUnit = () => {
    setOpenUnit((s) => !s);
  };

  const handleAdd = () => {
    setOpenCreateStructureUnit(true);
    handleClose();
  };

  const handleEdit = () => {
    setOpenEditUnitType(true);
    handleClose();
  };

  const handleDelete = () => {
    setOpenDeleteUnitType(true);
    handleClose();
  };

  const [handleSearch] = useDebouncedCallback(
    useCallback((value) => {
      setSearch(value);
      setTriggerUnitType(Math.floor(Math.random() * 100));
    }, []),
    1000,
    { maxWait: 5000 }
  );

  return (
    <Box>
      <ListItem>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction style={{ zIndex: 10 }}>
          <IconButton size="small" onClick={handleOpenUnit}>
            {openUnit ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </IconButton>
          <IconButton size="small" onClick={handleClick}>
            <MoreVertRoundedIcon style={{ fontSize: 20 }} />
          </IconButton>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={handleAdd}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <AddRoundedIcon style={{ fontSize: 16 }} />
              </ListItemIcon>
              <ListItemText secondary="Add" />
            </StyledMenuItem>
            <StyledMenuItem onClick={handleEdit}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <EditRoundedIcon style={{ fontSize: 16 }} />
              </ListItemIcon>
              <ListItemText secondary="Edit" />
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem onClick={handleDelete}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <DeleteRoundedIcon style={{ fontSize: 16, color: "#d64253" }} />
              </ListItemIcon>
              <ListItemText
                secondary={<span style={{ color: "#d64253" }}>Delete</span>}
              />
            </StyledMenuItem>
          </StyledMenu>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openUnit}>
        <Box p={1}>
          <Box p={2}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              placeholder="Search Unit"
              InputProps={{
                startAdornment: <SearchRoundedIcon />,
              }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
          <List dense>
            {structureUnits.length > 0 &&
              structureUnits.map((unit, i) => {
                return (
                  <ListItemStructureUnit
                    key={i}
                    unit={unit}
                    classes={classes}
                    setTriggerUnitType={setTriggerUnitType}
                  />
                );
              })}
          </List>
        </Box>
      </Collapse>

      <DialogCreateStructureUnit
        classes={classes}
        item={item}
        openCreateStructureUnit={openCreateStructureUnit}
        setOpenCreateStructureUnit={setOpenCreateStructureUnit}
        onTriggerUnitType={setTriggerUnitType}
      />

      <DialogEditUnitType
        classes={classes}
        item={item}
        openEditUnitType={openEditUnitType}
        setOpenEditUnitType={setOpenEditUnitType}
        onTriggerType={onTriggerType}
      />

      <DialogDeleteUnitType
        classes={classes}
        item={item}
        openDeleteUnitType={openDeleteUnitType}
        setOpenDeleteUnitType={setOpenDeleteUnitType}
        onTriggerType={onTriggerType}
      />
    </Box>
  );
};

export default ListItemStructureUnitType;
