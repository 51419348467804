import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Button,
} from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

import { useDebouncedCallback } from "use-debounce";
import _ from "lodash";
import moment from "moment";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import { OFFLINE_MODE, SEARCH_CARDS, SEARCH_TASK } from "../../context/types";
import ContextProject from "../../context/ContextProject";

import AnchorTaskSettings from "../anchor/AnchorTaskSettings";
import AnchorTaskList from "../anchor/AnchorTaskList";
// import AnchorFilterTask from "../anchor/AnchorFilterTask";
import AnchorQuickTask from "../anchor/AnchorQuickTask";
import AnchorSortTask from "../anchor/AnchorSortTask";

import ListFilterSort from "./ListFilterSort";
import AnchorFilterKanban from "../anchor/AnchorFilterKanban";
import AnchorKanbanSetting from "../anchor/AnchorKanbanSetting";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

const TaskInfoTop = ({ classes, project, tabValue }) => {
  const project_id = window.location.pathname.split("/")[2];
  const oriCards = localStorage.getItem("oriKanban");
  const kanbanOri = JSON.parse(oriCards);

  const {
    projectTDispatch,
    projectTState,
    setTriggerTask,
    setTriggerKanban,
    dataKanban,
    oriKanban,
    offlineMethod,
  } = useContext(ContextProject);

  const [offlineAddCard, setOfflineAddCard] = useState([]);
  const [offlineRenameCard, setOfflineRenameCard] = useState([]);
  const [offlineDeleteCard, setOfflineDeleteCard] = useState([]);
  const [offlineStatusCard, setOfflineStatusCard] = useState([]);
  const [offlinePhaseCard, setOfflinePhaseCard] = useState([]);
  const [offlineTaskGroupCard, setOfflineTaskGroupCard] = useState([]);

  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const [handleSearch] = useDebouncedCallback(
    useCallback(
      (value) => {
        // console.log("{valueTab", tabValue);

        if (Number(tabValue) !== 2) {
          projectTDispatch({
            type: SEARCH_TASK,
            payload: { searchTask: value },
          });
          setTriggerTask(Math.floor(Math.random() * 100));
        }
        if (Number(tabValue) === 2) {
          projectTDispatch({
            type: SEARCH_CARDS,
            payload: { searchCards: value },
          });
          setTriggerKanban(Math.floor(Math.random() * 100));
        }
      },
      [tabValue]
    ),
    1000,
    { maxWait: 5000 }
  );

  useEffect(() => {
    if (dataKanban !== null) {
      const lists = dataKanban.lists;

      const existCards = Array.prototype.reduce
        .call(
          Object.values(lists),
          function (prev, val) {
            prev.push(val);
            return prev;
          },
          []
        )
        .map((el) => el.cards)
        .flat();

      const compareDeleteCard = _.difference(oriKanban, existCards);
      const compareNewCard = _.differenceBy(existCards, kanbanOri, "title");
      const compareRenameCard = _.differenceBy(existCards, kanbanOri, "title");
      const compareDragStatus = _.differenceBy(
        existCards,
        kanbanOri,
        "status.id"
      );
      const compareDragPhase = _.differenceBy(
        existCards,
        kanbanOri,
        "custom_phase_id"
      );
      const compareDragTaskGroup = _.differenceBy(
        existCards,
        kanbanOri,
        "task_group_id"
      );

      // // console.log("existCards", existCards);
      // // console.log("oriKanban", oriKanban);
      // // console.log("kanbanOri", kanbanOri);

      if (offlineMethod === "create") {
        // // console.log("compareNewCard", compareNewCard);
        setOfflineAddCard(compareNewCard);
      }

      if (offlineMethod === "delete") {
        // // console.log("compareDeleteCard", compareDeleteCard);
        setOfflineDeleteCard(compareDeleteCard);
      }

      if (offlineMethod === "rename") {
        // // console.log("compareRenameCard", compareRenameCard);
        setOfflineRenameCard(compareRenameCard);
      }

      if (offlineMethod === "update") {
        if (projectTState.groupBy === "") {
          // // console.log("compareDragStatus", compareDragStatus);
          setOfflineStatusCard(compareDragStatus);
        }
        if (projectTState.groupBy === "custom_phase") {
          // // console.log("compareDragPhase", compareDragPhase);
          setOfflinePhaseCard(compareDragPhase);
        }
        if (projectTState.groupBy === "task_group") {
          // // console.log("compareDragTaskGroup", compareDragTaskGroup);
          setOfflineTaskGroupCard(compareDragTaskGroup);
        }
      }
    }
  }, [oriKanban, dataKanban]);

  // // console.log("{RPJ", project);
  // // console.log("{tabValue", tabValue);

  // ===================================================================
  // Proccess Data For Payloads
  const adds = offlineAddCard?.map((el) => {
    return {
      title: el.title,
      target_result: 0,
      start_date: el.start_date,
      due_date: el.due_date,
      owner_id: el?.owner_id,
      status:
        projectTState.groupBy === "custom_phase" ||
        projectTState.groupBy === "task_group"
          ? "TO DO"
          : el?.status?.id,
      priority: "LOW",
      remarks: null,
      description: null,
      tags: null,
      project_id,
      // actual_result: "0",
      predecessor_id: [],
      task_group_id:
        projectTState.groupBy === "task_group" ? el?.task_group_id : "",
      custom_phase_id:
        projectTState.groupBy === "custom_phase" ? el?.custom_phase_id : "",
    };
  });

  const updates =
    offlineRenameCard.length > 0
      ? offlineRenameCard?.map((el) => {
          return {
            id: el.id,
            title: el.title,
          };
        })
      : [];

  const phases =
    offlinePhaseCard.length > 0
      ? offlinePhaseCard?.map((el) => {
          return {
            id: el.id,
            custom_phase_id: el.custom_phase_id,
          };
        })
      : [];

  const groups =
    offlineTaskGroupCard.length > 0
      ? offlineTaskGroupCard?.map((el) => {
          return {
            id: el.id,
            task_group_id: el.task_group_id,
          };
        })
      : [];

  const deletes = offlineDeleteCard?.map((el) => el?.id);

  // Methods Save Online
  const handleSaveOffline = () => {
    setLoading(true);

    let DATA;
    let newUpdates = [...updates, ...phases, ...groups];

    if (adds.length > 0 && newUpdates.length === 0 && deletes.length === 0) {
      DATA = {
        tasks: {
          createds: Object.assign({}, adds),
        },
      };
    }

    if (adds.length === 0 && newUpdates.length > 0 && deletes.length === 0) {
      DATA = {
        tasks: {
          updateds: Object.assign({}, newUpdates),
        },
      };
    }

    if (adds.length > 0 && newUpdates.length === 0 && deletes.length > 0) {
      DATA = {
        tasks: {
          deleteds: Object.assign({}, deletes),
        },
      };
    }

    if (adds.length > 0 && newUpdates.length > 0 && deletes.length === 0) {
      DATA = {
        tasks: {
          createds: Object.assign({}, adds),
          updateds: Object.assign({}, newUpdates),
        },
      };
    }

    if (adds.length > 0 && newUpdates.length === 0 && deletes.length > 0) {
      DATA = {
        tasks: {
          createds: Object.assign({}, adds),
          deleteds: Object.assign({}, deletes),
        },
      };
    }

    if (adds.length === 0 && newUpdates.length > 0 && deletes.length > 0) {
      DATA = {
        tasks: {
          updateds: Object.assign({}, newUpdates),
          deleteds: Object.assign({}, deletes),
        },
      };
    }

    if (adds.length > 0 && newUpdates.length > 0 && deletes.length > 0) {
      DATA = {
        tasks: {
          createds: Object.assign({}, adds),
          updateds: Object.assign({}, newUpdates),
          deleteds: Object.assign({}, deletes),
        },
      };
    }

    AxiosConfig.post(
      `${URL_API}/project/${project_id}/kanban-offline/process`,
      DATA
    )
      .then((res) => {
        // console.log("Res save offline", res);

        if (res.status === 200) {
          projectTDispatch({
            type: OFFLINE_MODE,
            payload: {
              offlineMode: false,
            },
          });

          setTriggerTask(Math.floor(Math.random() * 100));
          setTriggerKanban(Math.floor(Math.random() * 100));

          setOfflineAddCard([]);
          setOfflineRenameCard([]);
          setOfflineDeleteCard([]);
          setOfflineStatusCard([]);
          setOfflinePhaseCard([]);
          setOfflineTaskGroupCard([]);

          localStorage.removeItem("oriKanban");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("ERR", error);

        setOfflineAddCard([]);
        setOfflineRenameCard([]);
        setOfflineDeleteCard([]);
        setOfflineStatusCard([]);
        setOfflinePhaseCard([]);
        setOfflineTaskGroupCard([]);

        projectTDispatch({
          type: OFFLINE_MODE,
          payload: {
            offlineMode: false,
          },
        });

        localStorage.removeItem("oriKanban");

        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Box
      mt={3}
      pl={8}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <TextField
        size="small"
        variant="outlined"
        placeholder={`Search ${tabValue !== 2 ? "Tasks" : "Cards"}`}
        onChange={(e) => handleSearch(e.target.value)}
        style={{
          outline: "none",
          backgroundColor: "#fff",
          border: "1px solid #DFDFDF",
          fontSize: 16,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon style={{ color: "#B1B1B1", fontSize: 16 }} />
            </InputAdornment>
          ),
        }}
      />
      {project?.project_type === "TEAM" && (
        <Box display="flex" alignItems="center">
          <ListFilterSort classes={classes} />

          {tabValue !== 2 && <AnchorSortTask classes={classes} />}
          {tabValue !== 2 && <Box mx={0.5} />}
          {tabValue !== 2 && <AnchorQuickTask classes={classes} />}
          {tabValue !== 2 && <Box mx={0.5} />}
          {tabValue !== 2 && (
            <AnchorTaskSettings classes={classes} project={project} />
          )}
          {tabValue !== 2 && <Box mx={0.5} />}
          {tabValue === 0 && (
            <AnchorTaskList classes={classes} project={project} />
          )}
          {tabValue === 2 && projectTState.offlineMode && (
            <Box display="flex" alignItems="center">
              <CheckCircleOutlineRoundedIcon
                style={{ color: "#e15367", fontSize: 15 }}
              />
              <Box mx={0.2} />
              <Typography variant="subtitle2">Offline Mode</Typography>
              <Box mx={0.5} />
              <Button
                size="small"
                variant="contained"
                disabled={
                  loading ||
                  (offlineAddCard.length === 0 &&
                    offlineRenameCard.length === 0 &&
                    offlineDeleteCard.length === 0 &&
                    offlineStatusCard.length === 0 &&
                    offlinePhaseCard.length === 0 &&
                    offlineTaskGroupCard.length === 0)
                }
                className={classes.button1}
                onClick={handleSaveOffline}
              >
                {offlineAddCard.length > 0 ||
                offlineRenameCard.length > 0 ||
                offlineDeleteCard.length > 0 ||
                offlineStatusCard.length > 0 ||
                offlinePhaseCard.length > 0 ||
                offlineTaskGroupCard.length > 0
                  ? `(${
                      offlineAddCard.length +
                      offlineRenameCard.length +
                      offlineDeleteCard.length +
                      offlineStatusCard.length +
                      offlinePhaseCard.length +
                      offlineTaskGroupCard.length
                    })`
                  : null}{" "}
                {loading ? "Saving..." : "Save"}
              </Button>
            </Box>
          )}
          {tabValue === 2 && <Box mx={1} />}
          {tabValue === 2 && <AnchorKanbanSetting classes={classes} />}
          {tabValue === 2 && <Box mx={0.5} />}
          {tabValue === 2 && <AnchorFilterKanban classes={classes} />}
        </Box>
      )}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default TaskInfoTop;
