import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
} from "@material-ui/core";

import axios from "axios";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import ContextNewSO from "../Context/ContextNewSO";

import DialogError from "../../../components/DialogError";
import Snackbeer from "../../../components/Snackbeer";
import Capitalize from "../../../utilities/Capitalize";
import { URL_API } from "../../../constants/config-api";

import { styles } from "../Style/StyleDialog";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogTambahStaff = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  //   let textInputReff = useRef(null);

  const [loader, setLoader] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  const [isDisabledStructurePositionTitle, setDisabledStructurePositionTitle] =
    useState(false);

  useEffect(() => {
    if (context.isOpenDialogTambahStaff == true) {
      //*Focus in Text Field
      // setTimeout(() => {
      //     textInputReff.current.focus();
      // }, 100);

      context.setSuccessTambahSTAFF(false);

      context.setTreeItems([
        {
          id: null,
          parent_id: null,
          structure_position_title_id: null,
          structure_position_title_name: null,

          structure_unit_id: null,
          structure_unit_name: null,

          structure_unit_type_id: null,
          structure_unit_type_name: null,

          member_first_name: null,
          member_last_name: null,
          self: null,

          classification: {
            id: null,
          },

          child: [],
        },
      ]);

      /*
                ````````````````````````
                HANDLE LIST NAMA JABATAN

                ````````````````````````
            */

      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/structure-position/batch/create?classification=4`
          )
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (
                response.data.data.structurePositionTitleCollections !==
                undefined
              ) {
                if (
                  response.data.data.structurePositionTitleCollections.length >
                  0
                ) {
                  setListPositionTitle(
                    response.data.data.structurePositionTitleCollections
                  );
                }
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }
    }
  }, [context.isOpenDialogTambahStaff]);

  /*
        `````````````````````
        HANDLE QUANTITY STAFF

        `````````````````````
    */

  const [staffQuantity, setStaffQuantity] = useState(0);

  const handleChangeQuantityStaff = (e) => {
    e.preventDefault();
    setStaffQuantity(e.target.value);
  };

  /*
        ```````
        EXPAND

        ```````
    */
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  /*
        ````````````````````````````
        DIALOG  POSITION TITLE

        ````````````````````````````
    */

  const [listPositionTitle, setListPositionTitle] = useState([]);

  const handleTambahJabatan = () => {
    context.setOpenDialogTambahStaff(false);
    context.setOpenDialogCreatePositionTitleInSTAFF(true);
  };

  const handleChoosePositionTitle = (e, data) => {
    e.preventDefault();

    // console.log("Wew Position Title : ", data);
    context.setPositionTitleId(data.id);
    context.setPositionTitleName(data.name);
    context.setPositionTitleChoose(true);

    //*Set state to next panel
    setExpanded("panel5");
    handleChange("panel5");
  };

  /*
        ``````````````````````````
        DIALOG HANDLE TAMBAH STAFF

        ``````````````````````````
    */
  const handleTambah = () => {
    setLoader(true);

    let data = {
      StructurePosition: {
        quantity: staffQuantity,
        structure_position_title_id: context.positionTitleId,
        // parent_id : context.dataDetail.parent_id !== null ? context.dataDetail.parent_id : context.dataDetail.id
        parent_id: context.dataDetail.id,
      },
    };

    // console.log("Data SUBMIT : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(
          URL_API + "/human-resource/structure-position/batch?classification=4",
          data
        )
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          if (response.status === 200) {
            context.setSuccessTambahSTAFF(true);

            context.setOpenDialogTambahStaff(false);
            setModalResponse200(true);
            setMessages("Berhasil menambahkan posisi staff !");
          }
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              if (
                error.response.data.info.errors !== undefined &&
                error.response.data.info.errors !== null
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);

                  setTextErrorInformationValue(".");
                  setOpenDialogError(true);
                  // if(error.response.data.info.errors[0].code == "VALIDATION_ERROR" ){

                  //     setOpenDialogError(true);
                  //     setTextErrorInformationValue('Error 400 : ' + Capitalize(error.response.data.info.errors[0].description))
                  // }
                }
              }
            }

            if (error.response.status == 500) {
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
                setErrorStatus(error.response.status);
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogTambahStaff}
        onClose={() => context.setOpenDialogTambahStaff(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Tambah <i>Staff</i>
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <TextField
            // inputRef={ textInputReff }
            value={staffQuantity !== 0 ? staffQuantity : ""}
            id="outlined-bare"
            className={classes.textField}
            onChange={handleChangeQuantityStaff}
            type="number"
            placeholder="Masukan jumlah kuantiti staff  dalam bentuk angka"
            variant="outlined"
            fullWidth
          />

          <br />
          <br />

          <div className={classes.rootList}>
            {/* 
                            ````````````````````````````````````````````
                            PILIH NAMA JABATAN/ STRUCTURE POSITION TITLE

                            ````````````````````````````````````````````
                        */}
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              disabled={isDisabledStructurePositionTitle == true ? true : false}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle2" className={classes.title}>
                  <IconButton style={{ padding: 4, marginBottom: 1 }}>
                    {expanded !== "panel4" ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                  {context.isPositionTitleChoosed === true ? (
                    <Fragment>
                      <b>Terpilih {context.positionTitleName}</b>
                      <IconButton size="small">
                        <CheckCircleIcon
                          style={{ color: "green", fontSize: 14 }}
                        />
                      </IconButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      Pilih <i>Nama Jabatan</i>
                    </Fragment>
                  )}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <div
                  className={classes.columnSeratusPersen}
                  style={{ textAlign: "left" }}
                >
                  {listPositionTitle.length == 0 && (
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "red" }}
                    >
                      <IconButton size="small" style={{ marginBottom: 1 }}>
                        <ErrorIcon style={{ color: "red" }} />
                      </IconButton>
                      <b>
                        Belum ada <i>Nama Jabatan</i>
                      </b>
                    </Typography>
                  )}

                  {listPositionTitle.length > 0 &&
                    listPositionTitle.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          onClick={(e) => handleChoosePositionTitle(e, item)}
                          variant="outlined"
                          className={classes.buttonOutlined}
                        >
                          <b>{item.name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}
                </div>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                {/* <Button  //*Nanti ane tambahin di sini "Set as vacant" ane taro di edit dulu
                                    onClick={() => {

                                        handleChange('panelX');
                                        setExpanded('panelX');

                                        context.setFirstName('Vacant');
                                    }}
                                    variant='outlined' size='small' className={classes.buttonOutlined}>
                                    <i style={{color: 'black'}}>Set as vacant</i>
                                </Button> */}

                <Button
                  onClick={handleTambahJabatan}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp;<i>Jabatan</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </div>
        </DialogContent>
        <DialogActions style={{ alignItems: "flex-end", justify: "flex-end" }}>
          <Button
            // onClick={handleDelete}
            onClick={() => context.setOpenDialogTambahStaff(false)}
            variant="outlined"
            className={classes.buttonOutlined}
            // fullWidth
          >
            Batal
          </Button>
          <Button
            onClick={handleTambah}
            variant="contained"
            className={classes.buttonDialog}
            // fullWidth
          >
            {loader !== true ? (
              "Tambah"
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={messages}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogTambahStaff);
