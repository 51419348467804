import moment from 'moment';

/**
 * Getting the format date string.
 * @return {string} formatted date string.
 * 
 */
export const getDateFormat = () => {
    // example is 20 October 2019
    return 'DD MMMM YYYY';
}

/**
 * Getting the server format date string.
 * 
 * @return {string} format date string.
 * 
 */
export const getServerDateFormat = () => {
    // example is 2019-12-31
    return 'YYYY-MM-DD';
}

/**
 * Convert period startDate to endDate to format 'hari ke'
 * 
 * @param {string} startDate date string with any format.
 * @param {string} endDate date string with any format.
 * 
 * @return {string} formatted string.
 * 
 */
export const formatHariKe = ( startDate, endDate ) => {
    let dateNow = moment();
    
    startDate = moment(startDate);
    endDate = moment(endDate);
    
    let elapsedDays = endDate.diff(dateNow, 'days');
    let totalDays = endDate.diff(startDate, 'days');
    
    if (dateNow.isSameOrAfter(startDate)) {
        if (dateNow.isSameOrAfter(endDate)) {
            return totalDays.toString() + "/" + totalDays.toString();
        } else {
            return elapsedDays.toString() + "/" + totalDays.toString();
        }
    } else {
        return "0" + "/" + totalDays.toString();
    }
}