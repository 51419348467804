import React from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { AppBar, Toolbar, Button, Typography, Hidden } from "@material-ui/core";

import { navigate } from "hookrouter";

import logo from "../assets/images/performate.png";

import {
  ToLogin,
  ToRegister,
  ToNewWelcome,
} from "../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiTypography: {
      root: {
        // textTransform: 'lowercase'
      },
      // button: {
      //     textTransform: 'lowercase',
      //     color: 'red'
      // }
    },
  },
});

// const useStyles = makeStyles(theme => ({
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  //   color: 'grey',

  // },
  title: {
    flexGrow: 1,
    color: "grey",
    paddingBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  question: {
    color: "grey",
    textTransform: "capitalize",
    fontFamily: "Roboto",
  },
  buttonAction: {
    textTransform: "capitalize",
    fontFamily: "Roboto",
  },
  logoText: {
    flexGrow: 1,
    width: 120,
    height: 24,
  },
  logo: {
    width: 30,
    height: 30,
  },
});

const useStyles = makeStyles(styles);

const Header = (props) => {
  const classes = useStyles();
  // const { classes} = props;

  // useEffect(() => {

  // }, [])
  const currentUrl = window.location.pathname;

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography
            onClick={() => navigate("/")}
            className={classes.title}
            style={{ cursor: "pointer" }}>
            <img
              src={logo}
              alt="logo-text"
              className={classes.logoText}
              style={{
                width: 132,
                height: 30,
              }}
            />
          </Typography>

          {/* <Grid container spacing={8} alignItems="flex-end"></Grid> */}

          <Hidden only="xs">
            <Typography variant="button" className={classes.question}>
              {currentUrl === ToRegister || currentUrl === ToNewWelcome
                ? "Sudah punya  akun ? "
                : "Belum punya akun ?"}
            </Typography>
          </Hidden>

          <Button
            color="primary"
            className={classes.buttonAction}
            onClick={
              currentUrl === ToRegister || currentUrl === ToNewWelcome
                ? () => navigate(ToLogin)
                : () => navigate(ToRegister)
            }>
            {currentUrl === ToRegister || currentUrl === ToNewWelcome
              ? "Masuk"
              : "Daftar"}
          </Button>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};

export default Header;
// export default withStyles(styles)(Header);
