/*
    ````````````````````````````````````````````````````````````````````````````````````````````````
    This bar is inspired by : https://www.quackit.com/css/flexbox/tutorial/create_a_flexible_box.cfm

    ````````````````````````````````````````````````````````````````````````````````````````````````
*/

/* 
    ``````````````````````````````````````````````````````````````````````````````````````````````````
    #SCENARIO-04 :

    - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &

    - "result_value_achievement" KURANG DARI 100

    - "result_value_achievement" LEBIH DARI 0
                                                    
    ``````````````````````````````````````````````````````````````````````````````````````````````````
*/
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { lightGreen, yellow, red } from "@material-ui/core/colors";

import ProgressBar from "react-bootstrap/ProgressBar";
import "../Components/Bar.css";

const numeral = require("numeral");

const styles = (theme) => ({
  title: {
    fontFamily: "Roboto",
  },
  titleWhite: {
    fontFamily: "Roboto",
    color: "white",
  },

  green: {
    background: "green",
  },

  green1: {
    background: lightGreen["A400"],
    borderRight: `${"2px solid " + yellow[500]}`,
  },
  green2: {
    background: lightGreen["A700"],
  },

  //*
  red: {
    background: red[800],
  },

  yellow: {
    background: yellow[300],
    borderRight: `${"2px solid " + yellow["A400"]}`,
    // borderBottom: `${'1px solid' + " " + yellow[800]}`,
    // borderTop: `1px solid transparent`,
    // borderLeft: `1px solid transparent`,
    // padding: '1px 2px',
    // borderRadius: 3
  },
  grey: {
    background: "#eee",
  },
  transparent: {
    background: "transparent",
  },
});

const useStyles = makeStyles(styles);

const BarDominantRedScenarioFOURTH = (props) => {
  const { goalDetailState, resultValueAchievement, expectedValueAchievement } =
    props;

  const classes = useStyles();
  const theme = useTheme();
  // const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  // const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
  // const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <div style={{ width: "100%" }}>
      {/*             
                ````````````````                
                TEXT BAGIAN ATAS

                ````````````````
            */}

      <Box display="flex" justifyContent="flex-end">
        <Typography
          variant="subtitle2"
          className={classes.titleWhite}
          style={{
            position: "relative",
            display: "block",
          }}>
          {numeral(expectedValueAchievement).format("0.00")}%
          <i style={{ fontSize: 14 }}>&nbsp;(Expected)</i>
        </Typography>
      </Box>

      <Box>
        <ProgressBar max={100} min={0} style={{ height: "1.2rem" }}>
          <ProgressBar
            style={{ backgroundColor: red[800] }}
            now={resultValueAchievement}
            key={2}
          />
          <ProgressBar
            style={{
              background: yellow[300],
              borderRight: `${"2px solid " + yellow["A400"]}`,
            }}
            now={expectedValueAchievement - resultValueAchievement}
            key={1}
          />
        </ProgressBar>
      </Box>

      <Box display="flex" justifyContent="flex-start">
        <Typography variant="subtitle2" className={classes.titleWhite}>
          {numeral(resultValueAchievement).format("0.00")}%
        </Typography>
        <Typography variant="subtitle2" className={classes.titleWhite}>
          <i>&nbsp;(Result)</i>
        </Typography>
      </Box>
    </div>
  );
};

export default BarDominantRedScenarioFOURTH;
