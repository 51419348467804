import React from "react";
import { Typography, Box } from "@material-ui/core";

import SetAchievements from "./SetAchievements";

import MyLang from "../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../constants/config-lang";

const Achievements = ({
  classes,
  achievementGoal,
  achievementMa,
  setAchievementGoal,
  setAchievementMa,
  enabledGoal,
  setEnabledGoal,
  enabledMa,
  setEnabledMa,
  onReadyData,
  lockSetting,
}) => {
  const handleEnabledGoal = (e) => {
    setEnabledGoal(e.target.checked);
    onReadyData("open");
  };
  const handleEnabledMa = (e) => {
    setEnabledMa(e.target.checked);
    onReadyData("open");
  };

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title333}>
        <b>{MyLang() ? bahasa.achievement : english.achievement}</b>
      </Typography>
      <Box my={1} />
      <Typography variant="caption" className={classes.title888}>
        {MyLang() ? bahasa.descAchievement : english.descAchievement}
      </Typography>
      <Box my={1} />

      <SetAchievements
        classes={classes}
        entity="Goal"
        achievement={achievementGoal}
        onAchievement={setAchievementGoal}
        enabled={enabledGoal}
        onEnabled={handleEnabledGoal}
        onReadyData={onReadyData}
        lockSetting={lockSetting}
      />
      <SetAchievements
        classes={classes}
        entity="Measured Activity"
        achievement={achievementMa}
        onAchievement={setAchievementMa}
        enabled={enabledMa}
        onEnabled={handleEnabledMa}
        onReadyData={onReadyData}
        lockSetting={lockSetting}
      />
    </div>
  );
};

export default Achievements;
