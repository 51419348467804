import React, { useState, useContext } from "react";
import {
  Button,
  Menu,
  Box,
  Typography,
  MenuItem,
  Divider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextReport from "../../context/ContextReports";
import {
  GET_DATA_FIELD,
  GET_FIELD_COMPARE,
  GET_FIELD_PRESETS,
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
} from "../../context/types";

import ListItemComparison from "./ListItemComparison";
import ListItemField from "./ListItemField";
import handleError from "../global/handleError";
import extractTable from "../global/extractTable";
import DialogError from "../../../../components/DialogError";

const Comparison = ({ classes }) => {
  const { reportDispatch, tableDispatch, tableState, filterState } =
    useContext(ContextReport);
  const codeRange = filterState.dateRange.code;

  const [anchorCompare, setAnchorCompare] = useState(null);
  const [selected, setSelected] = useState([]);
  const [fields, setFields] = useState([]);
  const [compareVal, setCompareVal] = useState({
    name: "",
    code: "",
    value: "",
    id: null,
  });
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleShowAnchorCompare = (event) => {
    setAnchorCompare(event.currentTarget);
  };

  const handleCloseAnchorCompare = () => {
    setAnchorCompare(null);
  };

  const handleChangeCompare = (data) => {
    // // console.log("CompareVal", data);
    setCompareVal(data);
  };

  const handleClickNone = () => {
    setSelected([]);
    setCompareVal({ name: "", code: "" });
    tableDispatch({
      type: GET_DATA_FIELD,
      payload: {
        comparison: { name: "", code: "" },
      },
    });
    tableDispatch({
      type: GET_FIELD_COMPARE,
      payload: { fieldCompare: [], fieldDiff: [] },
    });
  };

  const handleShowResult = () => {
    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: tableState.usersSelected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const postWithCompare = {
      ...dataPost,
      comparisons: {
        date_range: compareVal.code,
        fields: [
          "result_value",
          "overall_result_achievement",
          "expected_result_value",
        ],
      },
    };

    handleCloseAnchorCompare();

    if (selected.length > 0) {
      tableDispatch({
        type: GET_DATA_FIELD,
        payload: {
          comparison: compareVal,
        },
      });

      tableDispatch({
        type: HIT_DATA_PRESET,
        payload: { dataPresets: [], load: true },
      });

      const paginating = tableState.pagination;
      const page = paginating && paginating.currentPage;
      const perPage = paginating && paginating.perPage;

      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail?page=${page}&per_page=${perPage}`,
        compareVal.code === "" ? dataPost : postWithCompare
      )
        .then((res) => {
          const result = res.data.data;
          const lastSync = res.data.info.data;

          const field_preset = result.settings.field_preset;
          const DATATABLE = extractTable(result.results.data);
          const pagination = {
            currentPage: result.results.current_page,
            perPage: result.results.per_page,
            lastPage: result.results.last_page,
          };

          // =======================================================================
          // ~~~~~~~~~~~~~~~~~~~ Get Field Compare & Difference ~~~~~~~~~~~~~~~~~~~
          // =======================================================================
          const compDiff = field_preset.selected_fields.filter(
            (el) => typeof el.id === "string"
          );
          // selected compare
          const selectLength = selected.length > 0;
          const selectComp =
            selectLength && selected.map((el) => `${el}_compare`);
          const selectDiff =
            selectLength && selected.map((el) => `${el}_difference`);

          // comparison field
          const fieldCompare = compDiff
            .filter((el) => el.id.includes("compare") === true)
            .filter((elm) => selectComp.includes(elm.id));
          // difference field
          const fieldDiff = compDiff
            .filter((el) => el.id.includes("difference") === true)
            .filter((elm) => selectDiff.includes(elm.id));

          if (res.status === 200) {
            reportDispatch({
              type: GET_SYNC,
              payload: { lastSync },
            });

            tableDispatch({
              type: GET_FIELD_COMPARE,
              payload: { fieldCompare, fieldDiff },
            });

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: DATATABLE, load: false },
            });

            tableDispatch({
              type: SET_PAGINATION,
              payload: { pagination },
            });

            tableDispatch({
              type: HIT_TOTAL_RECORDS,
              payload: { totalRecords: result.results.total },
            });
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
          handleCloseAnchorCompare();
          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: [], load: false },
          });
        });
    } else if (selected.length === 0) {
      handleClickNone();
    }
  };

  const comparisons =
    tableState.comparison !== undefined && tableState.comparison.name;

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        className={classes.bntCompare}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleShowAnchorCompare}
        disabled={tableState.loadTable || filterState.loadFilter}
        style={{
          backgroundColor: comparisons !== "" && "#d1354a",
          color: comparisons !== "" && "#fff",
        }}
      >
        Compare with {comparisons}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorCompare}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorCompare)}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={200}
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}
        >
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Comparison</b>
          </Typography>
          <Typography className={classes.btnTxt} onClick={handleClickNone}>
            None
          </Typography>
        </Box>

        {filterState.comparisonList !== undefined &&
          filterState.comparisonList.length > 0 &&
          filterState.comparisonList
            .filter((el) => el.code !== codeRange)
            .map((item, i) => (
              <MenuItem key={i} style={{ padding: 0, paddingRight: 20 }} dense>
                <ListItemComparison
                  classes={classes}
                  item={item}
                  compareVal={compareVal}
                  onChangeCompare={handleChangeCompare}
                />
              </MenuItem>
            ))}

        <Box margin={1.5}>
          <Divider />
        </Box>

        <Box marginBottom={1.5} marginTop={1.5} marginLeft={2} marginRight={2}>
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Field</b>
          </Typography>
        </Box>

        {filterState.comparisonFields !== undefined &&
          filterState.comparisonFields.length > 0 &&
          filterState.comparisonFields
            .filter((el) => el.name !== "Expected")
            .map((el) => {
              let id = "";
              if (el.name === "Result") id = "12";
              if (el.name === "Achievement") id = "15";

              return { ...el, id };
            })
            .map((item, i) => (
              <MenuItem key={i} style={{ padding: 0, paddingRight: 20 }} dense>
                <ListItemField
                  classes={classes}
                  item={item}
                  index={i}
                  selected={selected}
                  setSelected={setSelected}
                  isSelected={isSelected}
                  setFields={setFields}
                />
              </MenuItem>
            ))}

        <Box margin={1.5}>
          <Divider />
        </Box>

        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorCompare}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            disabled={selected.length === 0}
            onClick={handleShowResult}
          >
            Show results
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default Comparison;
