import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Button,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_PAGINATING,
  TOGGLE_DRAWER_CHART,
  // selected summary
  HIT_ORDERBY,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
  SET_SUMMARY_TAB,
  GET_DATA_DETAIL,
} from "../../../../context/types";
import ContextReports from "../../../../context/ContextReports";

import handleError from "../../../global/handleError";
import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";

import ListItemOrderBy from "./sub-components/ListItemOrderBy";
import ModeSelect from "./sub-components/ModeSelect";
import DialogError from "../../../../../../components/DialogError";
import mapTable from "../../../global/mapTable";

const ConfigOrderBy = ({ classes }) => {
  const {
    summaryState,
    tableSmrState,
    summaryDispatch,
    tableSmrDispatch,
    reportDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  // =================== STATE ===================
  const [mode, setMode] = useState(summaryState.selectedOrder.method);
  const [anchorOrder, setAnchorOrder] = useState(null);
  const [theOrder, setTheOrder] = useState(summaryState.selectedOrder);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // =================== SIDE EFFECT ===================
  useEffect(() => {
    setTheOrder({ ...theOrder, method: mode });
    summaryDispatch({
      type: HIT_ORDERBY,
      payload: { selectedOrder: { ...theOrder, method: mode } },
    });
  }, [mode]);

  // =================== Load List From Store ===================
  const orderBy = summaryState.orderBy.length > 0 ? summaryState.orderBy : [];

  // =================== FUNC ? METHOD ===================
  const handleShowAnchorOrder = (event) => {
    setAnchorOrder(event.currentTarget);
  };
  const handleCloseAnchorOrder = () => {
    setAnchorOrder(null);
  };

  const handleOrderBy = (data) => {
    const order = { ...data, method: mode };

    setTheOrder(order);
    summaryDispatch({
      type: HIT_ORDERBY,
      payload: { selectedOrder: order },
    });
    // handleCloseAnchorOrder();
  };

  const handleMode = (data) => {
    // // console.log("DATA MODE", data);
    setMode(data);
    setTheOrder({ ...theOrder, method: data });
  };

  const handleShowResults = () => {
    // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
    const pagination = tableSmrState.paginating;
    const currentPage = pagination.currentPage;
    const perPage = pagination.perPage;

    // Convet achievement
    const calMethodGoal =
      summaryState.selectedAchievements.length > 0
        ? summaryState.selectedAchievements.map((item) => {
            return {
              formula: item,
            };
          })
        : [];
    const calMethodMa =
      summaryState.selectedAchievementsMa.length > 0
        ? summaryState.selectedAchievementsMa.map((item) => {
            return {
              formula: item,
            };
          })
        : [];

    const achievementGoal = {
      field: "goal",
      cal_method: calMethodGoal,
    };
    const achievementMa = {
      field: "ma",
      cal_method: calMethodMa,
    };

    const refGoal = [].concat(
      achievementGoal.cal_method.length > 0 ? achievementGoal : []
    );
    const refMa = [].concat(
      achievementMa.cal_method.length > 0 ? achievementMa : []
    );

    let newAchievement = [];

    if (
      summaryState.selectedEntities.includes("goal") &&
      summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal, ...refMa];
    }

    if (
      summaryState.selectedEntities.includes("goal") &&
      !summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal];
    }

    if (
      summaryState.selectedEntities.includes("ma") &&
      !summaryState.selectedEntities.includes("goal")
    ) {
      newAchievement = [...refMa];
    }

    // ~~~~~~~~~~~ Data POST Summary ~~~~~~~~~~~
    const dataSummary = {
      name: "Table",
      platform: "web",
      report_format: "table",
      filter_by: {
        entity: [],
        date_range: summaryState.dateRange.code,
        user: summaryState.selectedUsers,
        unit: summaryState.selectedUnits,
        status: summaryState.selectedStatus,
        achievement: newAchievement,
        period_id: summaryState.selectedPeriod.id,
      },
      entities: summaryState.selectedEntities,
      group_by: [summaryState.selectedGroup.field],
      group_by_calculation: summaryState.selectedCalc.code,
      order_by: [{ ...theOrder, method: mode }],
      primary_column: summaryState.selectedFields.code,
      unit_type_id: summaryState.selectedUnit.id,
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    tableSmrDispatch({
      type: GET_DATA_TABLE,
      payload: {
        dataTable: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA TABLE SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataSummary
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY TABLE", result);
        const DATATABLE = mapTable(result.results.data);

        const paginating = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
          totalRecords: result.results.total,
        };

        if (res.status === 200) {
          tableSmrDispatch({
            type: GET_FIELD_PRESET,
            payload: { fieldPreset: result.settings.field_preset },
          });

          setTimeout(() => {
            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                dataTable: DATATABLE,
                loading: false,
              },
            });
          }, 1000);

          tableSmrDispatch({
            type: SET_PAGINATING,
            payload: { paginating },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: false,
          },
        });
      });

    //============= Datat For Ovaerge Company =============
    const dataAvg = {
      ...dataSummary,
      report_format: "summary",
    };

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataAvg
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          summaryDispatch({
            type: GET_AVG_COMPANY,
            payload: {
              averages: result.results,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    //============= Datat For Bar Chart =============
    const dataBar = { ...dataSummary, report_format: "bar_chart" };

    barDispatch({
      type: GET_BAR_CHART,
      payload: {
        barData: [],
        barPreset: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataBar
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: result.results.data,
              barPreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });
      });

    //============= Datat For Pie Chart =============
    const dataPie = {
      ...dataSummary,
      report_format: "pie_chart",
      order_by: [],
    };

    pieDispatch({
      type: GET_PIE_CHART,
      payload: {
        pieDataGoal: [],
        pieDataMa: [],
        piePreset: [],
        loading: true,
      },
    });

    // // console.log("dataPie", dataPie);

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataPie
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);
        const pieGoal = result.results.filter((el) => el.type === "goal");
        const pieMa = result.results.filter((el) => el.type === "ma");

        if (res.status === 200) {
          reportDispatch({ type: TOGGLE_DRAWER_CHART, payload: false });
          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: pieGoal,
              pieDataMa: pieMa,
              piePreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: false,
          },
        });
      });

    tableSmrDispatch({
      type: SET_SUMMARY_TAB,
      payload: { summaryTab: 0 },
    });

    tableSmrDispatch({
      type: GET_DATA_DETAIL,
      payload: {
        loadDetail: false,
        dataDetail: {
          goals: [],
          mas: [],
        },
      },
    });

    handleCloseAnchorOrder();
  };

  return (
    <>
      <Button
        size="small"
        className={classes.configBtn}
        endIcon={<ExpandMoreIcon />}
        disabled={tableSmrState.loading}
        onClick={handleShowAnchorOrder}
      >
        Order By {theOrder.name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorOrder}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorOrder)}
        onClose={handleCloseAnchorOrder}
      >
        {orderBy.map((item, i) => (
          <MenuItem key={i} style={{ margin: 0, padding: "0 10px" }} dense>
            <ListItemOrderBy
              classes={classes}
              item={item}
              order={theOrder}
              onHandleOrder={handleOrderBy}
            />
          </MenuItem>
        ))}

        <Box p={2}>
          <Divider />
        </Box>

        {modes.map((item, i) => (
          <ModeSelect
            key={i}
            classes={classes}
            item={item}
            mode={mode}
            onMode={handleMode}
          />
        ))}

        <Box p={2}>
          <Divider />
        </Box>
        <Box mr={2} mb={1} display="flex" justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorOrder}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="outlined"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResults}
          >
            Show results
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default ConfigOrderBy;

const modes = [
  {
    name: "Ascending",
    method: "ASC",
  },
  {
    name: "Descending",
    method: "DESC",
  },
];
