import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  DialogTitle,
  Avatar,
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

import { useDropzone } from "react-dropzone";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

const DialogUploadFile = ({
  classes,
  openUploadFoto,
  setOpenUploadFoto,
  memberId,
  setTriggerFoto,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const theFile = acceptedFiles[0];
      setFile({ file: theFile, preview: URL.createObjectURL(theFile) });
    },

    maxFiles: 1,
  });

  const handleUploadFile = () => {
    setLoading(true);

    let data = new FormData();

    data.append("Member[photo_url]", file?.file);

    AxiosConfig.post(
      `${URL_API}/account-management/member/${memberId}/photo`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setTriggerFoto(Math.floor(Math.random() * 100));
          setOpenUploadFoto(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openUploadFoto}
      onClose={() => setOpenUploadFoto(false)}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box p={1}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>Upload Profile Photo</b>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 500 }}>
        <div className={classes.uploadContainer}>
          <div
            {...getRootProps()}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input {...getInputProps()} />
            <CloudUploadOutlinedIcon
              fontSize="large"
              style={{ color: "#E0E0E0" }}
            />
            <span className={classes.title}>Drag 'n' drop some files here</span>
            <span>or</span>
            <Button
              size="small"
              variant="outlined"
              className={classes.uploadBtn}
            >
              Choose File
            </Button>
          </div>
        </div>

        <Box mt={2} display="flex" justifyContent="center">
          {file !== null && (
            <Avatar
              variant="circle"
              style={{ width: 55, height: 55 }}
              src={file.preview}
            />
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Box p={2}>
          <Button
            variant={loading || file === null ? "contained" : "outlined"}
            size="medium"
            disabled={loading || file === null}
            className={classes.button1}
            onClick={handleUploadFile}
          >
            {loading ? "Uploading..." : "Upload"}
          </Button>
        </Box>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogUploadFile;
