import {
  GET_FIELD_PRESET,
  GET_DATA_TABLE,
  SET_PAGINATING,
  SET_SUMMARY_TAB,
  GET_DATA_DETAIL,
} from "../types";

// ~ Initial State for Table ~
export const initialTableSmr = {
  loading: false,
  fieldPreset: [],
  dataTable: [],
  paginating: {
    currentPage: 1,
    perPage: 10,
    lastPage: 0,
    totalRecords: 0,
  },
  summaryTab: 0,
  loadDetail: false,
  dataDetail: {
    goals: [],
    mas: [],
  },
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Table ~
export const tableSmrReducer = (state, { type, payload }) => {
  switch (type) {
    case GET_FIELD_PRESET:
      return {
        ...state,
        fieldPreset: payload.fieldPreset,
      };
    case GET_DATA_TABLE:
      return {
        ...state,
        dataTable: payload.dataTable,
        loading: payload.loading,
      };
    case SET_PAGINATING:
      return { ...state, paginating: payload.paginating };
    case SET_SUMMARY_TAB:
      return { ...state, summaryTab: payload.summaryTab };
    case GET_DATA_DETAIL:
      return {
        ...state,
        loadDetail: payload.loadDetail,
        dataDetail: payload.dataDetail,
      };
    default:
      return state;
  }
};
