import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  IconButton,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import RestorePageRoundedIcon from "@material-ui/icons/RestorePageRounded";

import moment from "moment";
import clsx from "clsx";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextGlobalDrive from "../../context/ContextDrive";

import pdf_icon from "../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../assets/images/icons_file/Text.png";
// import img_icon from "../../../../assets/images/icons_file/Image.png";

import DialogInfoRecycleBin from "../components/DialogInfoRecycleBin";
import DialogDeleteFileRB from "../components/DialogDeleteFileRB";
import DialogRestoreFile from "../components/DialogRestoreFile";

const RecycleBinFile = ({ item, classes, isFileSelected }) => {
  const context = useContext(ContextGlobalDrive);
  /* ====================================================
    LOCAL STATE
    ==================================================== */
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [anchorElFile, setAnchorElFile] = useState(null);
  const [typeFile, setTypeFile] = useState(null);

  const [openDialogInfoRecycleBin, setOpenDialogInfoRecycleBin] =
    useState(false);
  const [openDialogDeleteFileRB, setOpenDialogDeleteFileRB] = useState(false);
  const [openDialogRestoreFile, setOpenDialogRestoreFile] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`${URL_API}/drive/recycle-bin/file/${item.id}?mode=thumbnail`, {
        responseType: "blob",
      })
      .then((response) => {
        // // console.log("RB File Content", response);
        let ori_contetnt_type = response.headers["content-type"];
        let splited_content_type = ori_contetnt_type.split(",");
        let content_type = splited_content_type[0];

        // // console.log("CONTENT TYPE", content_type);

        if (response.status === 200) {
          setTypeFile(content_type);
          setImage(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);
      });
  }, []);

  const handleShowAnchorFile = (event) => {
    setAnchorElFile(event.currentTarget);
  };

  const handleCloseAnchorFile = () => {
    setAnchorElFile(null);
  };

  const handleInfoRecycleBin = () => {
    setOpenDialogInfoRecycleBin(true);
    setAnchorElFile(null);
  };

  const handleDeleteForever = () => {
    setOpenDialogDeleteFileRB(true);
    setAnchorElFile(null);
  };

  const handleRestoreFile = () => {
    setOpenDialogRestoreFile(true);
    setAnchorElFile(null);
  };

  const blobUrlImage = new Blob([image], {
    type: typeFile !== null && typeFile,
  });

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFile.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFile, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFile.slice(1));
    } else if (selectedIndex === context.selectedFile.length - 1) {
      newSelected = newSelected.concat(context.selectedFile.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFile.slice(0, selectedIndex),
        context.selectedFile.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected Folders", newSelected);

    context.setSelectedFile(newSelected);
  };

  // Check is Folder selected or not
  const isItemFileSelected = isFileSelected(item.id);

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#e5fbfb" }}>
        <TableCell padding="checkbox">
          {context.toggleSelect && (
            <Checkbox
              style={{ marginTop: -10 }}
              onClick={() => handleChangeCheckbox(item)}
              className={classes.checkboxRoot}
              checked={isItemFileSelected}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
          )}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {typeFile !== null && typeFile.includes("image") ? (
              <Avatar
                variant="rounded"
                src={image !== "" && URL.createObjectURL(blobUrlImage)}
                style={{
                  width: 30,
                  height: 30,
                }}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: "#d1354a" }} />
                )}
              </Avatar>
            ) : typeFile !== null && typeFile === "application/pdf" ? (
              <img
                src={pdf_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : (typeFile !== null && typeFile === "application/msword") ||
              typeFile ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
              <img
                src={word_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : (typeFile !== null &&
                typeFile === "application/vnd.ms-powerpoint") ||
              typeFile ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
              <img
                src={ppt_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : (typeFile !== null &&
                typeFile === "application/vnd.ms-excel") ||
              typeFile ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <img
                src={excel_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : typeFile !== null && typeFile.includes("video") ? (
              <img
                src={video_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : typeFile !== null && typeFile === "text/plain" ? (
              <img
                src={text_icon}
                style={{
                  width: 25,
                  margin: `0 2px`,
                }}
              />
            ) : (
              <InsertDriveFileIcon color="inherit" fontSize="default" />
            )}

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15 }}
            >
              {item.file_name}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {moment(item.executed_at).format("DD MMM YYYY, HH:mm")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            File
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            -
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {item.fileOwner !== null
              ? `${item.fileOwner.member.first_name} ${item.fileOwner.member.last_name}`
              : "User has been deleted"}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={handleShowAnchorFile}>
            <MoreVertIcon />
          </IconButton>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElFile}
            keepMounted
            open={Boolean(anchorElFile)}
            onClose={handleCloseAnchorFile}
          >
            <StyledMenuItem
              style={{ width: 200 }}
              onClick={handleInfoRecycleBin}
            >
              <ListItemIcon>
                <VisibilityOutlinedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Info File
                  </Typography>
                }
              />
            </StyledMenuItem>
            <StyledMenuItem onClick={handleDeleteForever}>
              <ListItemIcon>
                <DeleteRoundedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Delete File
                  </Typography>
                }
              />
            </StyledMenuItem>
            <StyledMenuItem onClick={handleRestoreFile}>
              <ListItemIcon>
                <RestorePageRoundedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Restore File
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>

      <DialogInfoRecycleBin
        item={item}
        classes={classes}
        origin="file"
        openDialogInfoRecycleBin={openDialogInfoRecycleBin}
        setOpenDialogInfoRecycleBin={setOpenDialogInfoRecycleBin}
      />

      <DialogDeleteFileRB
        item={item}
        classes={classes}
        openDialogDeleteFileRB={openDialogDeleteFileRB}
        setOpenDialogDeleteFileRB={setOpenDialogDeleteFileRB}
      />

      <DialogRestoreFile
        item={item}
        classes={classes}
        openDialogRestoreFile={openDialogRestoreFile}
        setOpenDialogRestoreFile={setOpenDialogRestoreFile}
      />
    </Fragment>
  );
};

export default RecycleBinFile;
