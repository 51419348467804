import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
} from "@material-ui/pickers";

import moment from "moment";
import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ListItemNotifSelect from "./ListItemNotifSelect";
import handleError from "../../../Report/components/global/handleError";
import SnackbarError from "../../../../components/SnackbarError";

const DialogCreateNotif = ({
  classes,
  openCreateNotif,
  setOpenCreateNotif,
  setTriggerNotif,
}) => {
  const [moduls, setModuls] = useState([]);
  const [entities, setEntities] = useState([]);
  const [events, setEvents] = useState([]);
  const [channels, setChannels] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [originaEvents, setOriginalEvents] = useState([]);
  const [grouping, setGrouping] = useState(
    "9b45c8d1-61cb-47e7-be63-1dfeda876ecb"
  );
  const [schedule, setSchedule] = useState(
    "8698bd84-99ec-4fec-b460-526c114b6792"
  );
  const [entityVal, setEntityVal] = useState("");
  const [entityChecked, setEntityChecked] = useState(null);
  const [days, setDays] = useState("0");
  const [selectedDueDate, handleChangeDueDate] = useState(moment());

  const [loading, setLoading] = useState(false);
  const [loadEntity, setLoadEntity] = useState(false);
  const [scheduleView, setScheduleView] = useState(0);

  //trigger
  const [triggerEntity, setTriggerEntity] = useState(null);

  // collections
  const [entityTypeCollections, setEntityTypeCollections] = useState([]);
  const [eventCollections, setEventCollections] = useState([]);
  const [recipientTypeCollections, setRecipientTypeCollections] = useState([]);
  const [categoryCollections, setCategoryCollections] = useState([]);
  const [coverageCollections, setCoverageCollections] = useState([]);
  const [scheduleTypeCollections, setScheduleTypeCollections] = useState([]);
  const [groupingTypeCollections, setGroupingTypeCollections] = useState([]);
  const [channelCollections, setChannelCollections] = useState([]);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openCreateNotif) {
      setLoadEntity(true);

      AxiosConfig.get(
        `${URL_API}/account-management/notification/setting/update`
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("Res form notif", res);

          if (res.status === 200) {
            let newEvents;
            let newCategory = result.categoryCollections.filter(
              (el) => el.name === "All"
            );

            if (entities.includes("4e095bf1-3c77-4fcd-833f-4830d7abba8f")) {
              newEvents = result.eventCollections;
            } else {
              newEvents = result.eventCollections.filter((el) =>
                entities.includes(el.entity_type_id)
              );
            }

            // // console.log("entities", entities);
            // // console.log("result.eventCollections", result.eventCollections);
            // // console.log("newEvents", newEvents);

            setEntityTypeCollections(result.entityTypeCollections);
            setEventCollections(newEvents);
            setRecipientTypeCollections(result.recipientTypeCollections);
            setCategoryCollections(newCategory);
            setCoverageCollections(result.coverageCollections);
            setScheduleTypeCollections(result.scheduleTypeCollections);
            setGroupingTypeCollections(result.groupingTypeCollections);
            setChannelCollections(result.channelCollections);
          }

          setLoadEntity(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoadEntity(false);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openCreateNotif, entities, triggerEntity]);

  useEffect(() => {
    if (openCreateNotif) {
      AxiosConfig.get(`${URL_API}/account-management/notification/setting`)
        .then((res) => {
          const result = res.data.data;
          // console.log("Res notif collections", res);

          const latest =
            result.length > 0
              ? result.reduce((a, b) => {
                  return new Date(a.created_at) > new Date(b.created_at)
                    ? a
                    : b;
                })
              : null;

          // // console.log("LATEST", latest);

          if (res.status === 200) {
            if (latest !== null) {
              const recipien = latest.recipient.map(
                (el) => el.recipientType?.id
              );
              const channel = latest.channel.map((el) => el.channel.id);
              const event = result.map((el) => el.event_type_id);
              const entity = result.map((el) => el.eventType.entityType?.id);
              const notifAndEventId = result.map((el) => {
                return { id: el.id, event_id: el.event_type_id };
              });

              const scheduleTypeId = latest?.schedule_type_id;
              const scheduleTypeVal = latest?.schedule_type_value;
              const day = scheduleTypeVal.substring(0, 2);
              const dayz = scheduleTypeVal.substring(0, 1);
              const hourminiute = scheduleTypeVal.substr(
                scheduleTypeVal.length - 5
              );
              const event_type = latest?.eventType;

              // // console.log("event_type", event_type);

              // "name": "Daily",
              if (scheduleTypeId === "ec133180-58a1-4c55-bda0-c86c73c4597d") {
                setScheduleView(0);
                handleChangeDueDate(
                  moment().format(`DD MMM YYYY ${scheduleTypeVal}`)
                );
              }
              // "name": "Instant",
              if (scheduleTypeId === "8698bd84-99ec-4fec-b460-526c114b6792") {
                setScheduleView(1);
              }
              // "name": "Monthly",
              if (scheduleTypeId === "21cd00ce-7193-4471-b787-31c2d659a3d6") {
                setScheduleView(2);
                handleChangeDueDate(
                  moment().format(`${day} MMM YYYY ${hourminiute}`)
                );
              }
              // "name": "Weekly",
              if (scheduleTypeId === "364c6276-05e5-4392-a885-388adbab1357") {
                setScheduleView(3);
                setDays(dayz);
                handleChangeDueDate(
                  moment().format(`DD MMM YYYY ${hourminiute}`)
                );
              }

              setEvents(event);
              setOriginalEvents(notifAndEventId);
              setGrouping(latest?.grouping_type_id);
              setSchedule(scheduleTypeId);
              setModuls([latest.category_id]);
              setEntities(entity);
              setRecipients(recipien);
              setChannels(channel);
              // setModuls(latest.category?.id);
            }
          }
        })
        .catch((error) => {
          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openCreateNotif]);

  // handel Select All ENtity
  useEffect(() => {
    if (openCreateNotif) {
      const newEntitiyIds = entityTypeCollections
        ?.filter((el) => el.id !== "4e095bf1-3c77-4fcd-833f-4830d7abba8f")
        ?.map((el) => el.id);

      const entitiyIds = entityTypeCollections?.map((el) => el.id);

      const newEntities = [...new Set(entities)];

      function areEqual(array1, array2) {
        if (array1.length === array2.length) {
          return array1.every((element) => {
            if (array2.includes(element)) {
              return true;
            }

            return false;
          });
        }

        return false;
      }

      if (areEqual(newEntitiyIds, newEntities)) {
        setEntities(entitiyIds);
      }
      // // console.log("entityTypeCollections In", entityTypeCollections);
      // // console.log("newEntities", newEntities);
      // // console.log("entitiyIds", entitiyIds);

      // for events checked
      if (!entityChecked === false) {
        const selectEvents = eventCollections
          .filter((el) => el.entity_type_id === entityVal)
          .map((el) => el.id);
        // // console.log("selectEvents c", selectEvents);
        // // console.log("eventCollections c", eventCollections);
        setEvents((prev) => prev.concat(selectEvents));
      }

      // // console.log("entityChecked", entityChecked);

      if (entityChecked === true) {
        const selectEvents = eventCollections.map((el) => el.id);
        // .filter((el) => el.entity_type_id === entityVal)
        // // console.log("selectEvents c", selectEvents);
        // // console.log("eventCollections c", eventCollections);
        setEvents((prev) => prev.concat(selectEvents));
      }
    }
  }, [openCreateNotif, triggerEntity, eventCollections, entityVal]);

  const handleGrouping = (e) => {
    setGrouping(e.target.value);
  };

  const handleDays = (e) => {
    setDays(e.target.value);
  };

  const handleSchedule = (e) => {
    const value = e.target.value;
    setSchedule(value);

    // "name": "Daily",
    if (value === "ec133180-58a1-4c55-bda0-c86c73c4597d") {
      setScheduleView(0);
    }
    // "name": "Instant",
    if (value === "8698bd84-99ec-4fec-b460-526c114b6792") {
      setScheduleView(1);
    }
    // "name": "Monthly",
    if (value === "21cd00ce-7193-4471-b787-31c2d659a3d6") {
      setScheduleView(2);
    }
    // "name": "Weekly",
    if (value === "364c6276-05e5-4392-a885-388adbab1357") {
      setScheduleView(3);
    }
  };

  const handleCheckModul = (e) => {
    let updatedList = [...moduls];
    if (e.target.checked) {
      updatedList = [...moduls, e.target.value];
    } else {
      updatedList.splice(moduls.indexOf(e.target.value), 1);
    }
    setModuls(updatedList);
  };

  const handleCheckEntity = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    const idAll = "4e095bf1-3c77-4fcd-833f-4830d7abba8f";
    const entitiyIds = entityTypeCollections?.map((el) => el.id);
    // const entitiyLength = entitiyIds?.length - 1;

    // // console.log("ENTYty VAL", value);

    setEntityVal(value);
    setEntityChecked(checked);

    if (value === idAll) {
      if (checked) {
        setEntities(entitiyIds);
      } else {
        setEntities([]);
      }
    } else {
      let updatedList = [...entities];
      if (checked) {
        // const tempEntities = [...entities, value];
        // if (tempEntities.length === entitiyLength && value !== idAll) {
        //   updatedList = entitiyIds;
        // } else {
        updatedList = [...entities, value];
        // }

        // for events checked
        // const selectEvents = eventCollections
        //   .filter((el) => el.entity_type_id === value)
        //   .map((el) => el.id);
        // // console.log("selectEvents c", selectEvents);
        // // console.log("eventCollections c", eventCollections);
        // setEvents(...events, ...selectEvents);
      } else {
        const cobi = updatedList.filter((el) => el !== value);
        updatedList = cobi;

        // for events unchecked
        const selectEvents = eventCollections
          .filter((el) => el.entity_type_id !== value)
          .map((el) => el.id);
        // // console.log("selectEvents unc", selectEvents);
        setEvents(selectEvents);
      }
      setEntities(updatedList);
      // // console.log("updatedList", updatedList);
    }

    setTriggerEntity(Math.floor(Math.random() * 100));
  };

  // // console.log("originaEvents", originaEvents);
  // // console.log("EVENTS", events);

  const handleCheckEvent = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    let updatedList = [...events];
    if (checked) {
      updatedList = [...events, value];
    } else {
      updatedList.splice(events.indexOf(value), 1);

      // for events unchecked
      // const selectEvents = eventCollections
      //   .filter((el) => el.id !== value)
      //   .map((el) => el.id);
      // setEvents(selectEvents);
    }
    setEvents(updatedList);
  };

  const handleCheckChannel = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    const idAll = "8f6263ca-dbcf-49a8-843b-47d0e62961fd";
    const channelIds = channelCollections?.map((el) => el.id);
    const channleLength = channelIds?.length - 1;
    // const channelCount = channelIds?.length;
    // // console.log("channels", channels);
    // // console.log("channels.length", _.isEqual(channels, notAll));

    if (value === idAll) {
      if (checked) {
        setChannels(channelIds);
      } else {
        setChannels([]);
      }
    } else {
      let updatedList = [...channels];
      if (checked) {
        const tempChannels = [...channels, value];
        if (tempChannels.length === channleLength && value !== idAll) {
          updatedList = channelIds;
        } else {
          updatedList = [...channels, value];
        }
      } else {
        updatedList.splice(channels.indexOf(value), 1);
      }
      setChannels(updatedList);
    }
  };

  // // console.log("channels", channels);

  const handleCheckRecipients = (e) => {
    let updatedList = [...recipients];
    if (e.target.checked) {
      updatedList = [...recipients, e.target.value];
    } else {
      updatedList.splice(recipients.indexOf(e.target.value), 1);
    }
    setRecipients(updatedList);
  };

  // // console.log("EVENTS", Object.assign({}, events));

  const handleUpdateNotify = () => {
    setLoading(true);

    let SCHEDULE_VALUE;
    let EVENT_IDs;

    if (scheduleView === 0)
      SCHEDULE_VALUE = moment(selectedDueDate).format("HH:mm");
    if (scheduleView === 1) SCHEDULE_VALUE = "instant";
    if (scheduleView === 2)
      SCHEDULE_VALUE = moment(selectedDueDate).format("DD HH:mm");
    if (scheduleView === 3)
      SCHEDULE_VALUE = `${days} ${moment(selectedDueDate).format("HH:mm")}`;

    // let data = {
    //   NotificationSetting: {
    //     grouping_type_id: grouping,
    //     coverage_id: "183518ae-3f25-425f-b235-0ca3991cde7a",
    //     channel_id: Object.assign({}, channels),
    //     schedule_type_id: schedule,
    //     schedule_type_value: SCHEDULE_VALUE,
    //     category_id: moduls,
    //     entity_type_id: entities,
    //     event_id:
    //       entities === "4e095bf1-3c77-4fcd-833f-4830d7abba8f"
    //         ? { 0: "*" }
    //         : Object.assign({}, events),
    //     event_value: { 0: "*" },
    //     recipient_type_id: Object.assign({}, recipients),
    //     is_enforce: "1",
    //   },
    // };

    // const params = new URLSearchParams();

    let prepareLoop = eventCollections.filter((el) => events.includes(el.id));

    // prepareLoop.map((item, index) => {
    //   params.append(`NotificationSetting[grouping_type_id]`, grouping);
    //   params.append(
    //     `NotificationSetting[coverage_id]`,
    //     "183518ae-3f25-425f-b235-0ca3991cde7a"
    //   );
    //   params.append(
    //     `NotificationSetting[channel_id]`,
    //     Object.assign({}, channels)
    //   );
    //   params.append(`NotificationSetting[schedule_type_id]`, schedule);
    //   params.append(`NotificationSetting[schedule_type_value]`, SCHEDULE_VALUE);
    //   params.append(
    //     `NotificationSetting[category_id]`,
    //     moduls.length > 0 ? moduls[0] : null
    //   );
    //   params.append(`NotificationSetting[entity_type_id]`, item.entity_type_id);
    //   params.append(`NotificationSetting[event_id][${index}]`, item.id);
    //   params.append(`NotificationSetting[event_value]`, { 0: "*" });
    //   params.append(
    //     `NotificationSetting[recipient_type_id]`,
    //     Object.assign({}, recipients)
    //   );
    //   params.append(`NotificationSetting[is_enforce]`, "1");
    // });

    const eventToDelete = originaEvents
      .filter((el) => !events.includes(el.event_id))
      .map((em) => em.id);

    const asignNotif = Object.assign({}, eventToDelete);

    const dataDeleteNotif = {
      id: asignNotif,
    };

    // console.log("dataDeleteNotif", dataDeleteNotif);

    if (eventToDelete.length > 0) {
      AxiosConfig.post(
        `${URL_API}/account-management/notification/setting/delete`,
        dataDeleteNotif
      )
        .then((res) => {
          if (res.status === 200) {
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error", error);

          setLoading(false);

          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }

    // // console.log("prepareLoop", prepareLoop);
    // // console.log("prepareLoop", prepareLoop);
    // // console.log("DDATAS", datas);
    // // console.log("Object.channels", Object.assign({}, channels));
    // // console.log("Object.recipients", Object.assign({}, recipients));

    return prepareLoop.map((item, idx) => {
      const data = {
        NotificationSetting: {
          grouping_type_id: grouping,
          coverage_id: "183518ae-3f25-425f-b235-0ca3991cde7a",
          channel_id: Object.assign({}, channels),
          schedule_type_id: schedule,
          schedule_type_value: "instant",
          category_id: moduls.length > 0 ? moduls[0] : null,
          entity_type_id: item.entity_type_id,
          event_id: {
            [idx]: item.id,
          },
          event_value: { 0: "*" },
          recipient_type_id: Object.assign({}, recipients),
          is_enforce: "1",
        },
      };

      // // console.log("data", data);

      AxiosConfig.put(
        `${URL_API}/account-management/notification/setting`,
        data
      )
        .then((res) => {
          // console.log("RES notif update", res);

          if (res.status === 200) {
            setLoading(false);
            setOpenCreateNotif(false);
            setTriggerNotif(Math.floor(Math.random() * 100));
          }
        })
        .catch((error) => {
          setLoading(false);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    });

    // AxiosConfig.put(
    //   `${URL_API}/account-management/notification/setting`,
    //   params
    // )
    //   .then((res) => {
    //     // console.log("RES notif update", res);

    //     if (res.status === 200) {
    //       setLoading(false);
    //       setOpenCreateNotif(false);
    //       setTriggerNotif(Math.floor(Math.random() * 100));
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);

    //     // Handle Error
    //     const { listError, dialogErr, resStatus, errorMessage } =
    //       handleError(error);
    //     // Error State
    //     setOpenDialogError(dialogErr);
    //     setTextErrorMessage(errorMessage);
    //     setListError(listError);
    //     setErrorStatus(resStatus);
    //   });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openCreateNotif}
        onClose={() => setOpenCreateNotif(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4">Buat Pengaturan Notifikasi</Typography>
        </DialogTitle>
        <DialogContent style={{ minWidth: 800 }}>
          <Box p={1} my={1}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Typography variant="subtitle1" className={classes.title888}>
                  Moduls
                </Typography>
                <Box my={1} />
                <Box
                  border="1px solid #ccc"
                  px={1}
                  borderRadius={5}
                  style={{
                    overflowY: "auto",
                    height: "180px",
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                  }}
                >
                  <List dense>
                    {categoryCollections.length > 0 &&
                      categoryCollections.map((item, i) => (
                        <ListItemNotifSelect
                          key={i}
                          classes={classes}
                          item={item}
                          onHandleChange={handleCheckModul}
                          events={moduls}
                          arr={[]}
                          isDisabled={false}
                        />
                      ))}
                  </List>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Typography variant="subtitle1" className={classes.title888}>
                  Entity
                </Typography>
                <Box my={1} />
                <Box
                  border="1px solid #ccc"
                  px={1}
                  borderRadius={5}
                  style={{
                    overflowY: "auto",
                    height: "180px",
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                  }}
                >
                  <List dense>
                    {entityTypeCollections.length > 0 &&
                      entityTypeCollections.map((item, i) => {
                        // let isCurrent = item.id === entities;
                        // const eventCheck = entities?.includes(item.id);

                        return (
                          <ListItemNotifSelect
                            key={i}
                            classes={classes}
                            item={item}
                            onHandleChange={handleCheckEntity}
                            events={entities}
                            arr={[]}
                            isDisabled={loadEntity}
                          />
                        );
                      })}
                  </List>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Typography variant="subtitle1" className={classes.title888}>
                  Event{" "}
                  {loadEntity ? (
                    <CircularProgress size={14} color="primary" />
                  ) : null}
                </Typography>
                <Box my={1} />
                <Box
                  border="1px solid #ccc"
                  px={1}
                  borderRadius={5}
                  style={{
                    overflowY: "auto",
                    height: "180px",
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                  }}
                >
                  <List dense>
                    {eventCollections?.length > 0 &&
                      eventCollections.map((item, i) => (
                        <ListItemNotifSelect
                          key={i}
                          classes={classes}
                          item={item}
                          onHandleChange={handleCheckEvent}
                          events={events}
                          arr={entities}
                          isDisabled={false}
                        />
                      ))}
                  </List>
                </Box>
              </Grid>
            </Grid>

            {/* <Box mb={3} mt={2}>
              <Typography variant="subtitle1" className={classes.title888}>
                Grouping
              </Typography>
              <Box my={0.5} />
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                hiddenLabel={false}
                style={{ width: 400 }}
              >
                <Select
                  native
                  labelWidth={0}
                  value={grouping}
                  onChange={handleGrouping}
                  disabled={events.length === 0}
                  inputProps={{
                    name: "selection",
                    id: "selection",
                  }}
                >
                  {groupingTypeCollections.length > 0 &&
                    groupingTypeCollections.map((item, i) => {
                      return (
                        <option key={i} aria-label="None" value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Box> */}

            {/* <Grid container spacing={2}>
              <Grid
                item
                sm={
                  scheduleView === 0
                    ? 6
                    : scheduleView === 1
                    ? 12
                    : scheduleView === 2
                    ? 6
                    : 4
                }
              >
                <Typography variant="subtitle1" className={classes.title888}>
                  Schedule
                </Typography>
                <Box my={0.5} />
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  hiddenLabel={false}
                >
                  <Select
                    native
                    labelWidth={0}
                    value={schedule}
                    onChange={handleSchedule}
                    disabled={events.length === 0}
                    inputProps={{
                      name: "selection",
                      id: "selection",
                    }}
                  >
                    {scheduleTypeCollections.length > 0 &&
                      scheduleTypeCollections.map((item, i) => {
                        return (
                          <option key={i} aria-label="None" value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              {scheduleView === 0 && (
                <Grid item sm={6}>
                  <Typography variant="subtitle1" className={classes.title888}>
                    Time
                  </Typography>
                  <Box my={0.5} />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      inputVariant="outlined"
                      size="small"
                      fullWidth
                      value={selectedDueDate}
                      onChange={handleChangeDueDate}
                      keyboardIcon={<WatchLaterIcon style={{ fontSize: 17 }} />}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      variant="dialog"
                      disabled={events.length === 0}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}

              {scheduleView === 2 && (
                <Grid item sm={6}>
                  <Typography variant="subtitle1" className={classes.title888}>
                    Date & Time
                  </Typography>
                  <Box my={0.5} />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={theme}>
                      <DateTimePicker
                        inputVariant="outlined"
                        size="small"
                        ampm={false}
                        fullWidth
                        value={selectedDueDate}
                        onChange={handleChangeDueDate}
                        disableToolbar={false}
                        format="dd MM yyyy HH:mm a"
                        disabled={events.length === 0}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Grid>
              )}

              {scheduleView === 3 && (
                <Grid item sm={4}>
                  <Typography variant="subtitle1" className={classes.title888}>
                    Day
                  </Typography>
                  <Box my={0.5} />
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    hiddenLabel={false}
                  >
                    <Select
                      native
                      labelWidth={0}
                      value={days}
                      onChange={handleDays}
                      inputProps={{
                        name: "selection",
                        id: "selection",
                      }}
                      disabled={events.length === 0}
                    >
                      {listDays.length > 0 &&
                        listDays.map((item, i) => {
                          return (
                            <option
                              key={i}
                              aria-label="None"
                              value={item.value}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {scheduleView === 3 && (
                <Grid item sm={4}>
                  <Typography variant="subtitle1" className={classes.title888}>
                    Time
                  </Typography>
                  <Box my={0.5} />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      inputVariant="outlined"
                      size="small"
                      fullWidth
                      value={selectedDueDate}
                      onChange={handleChangeDueDate}
                      keyboardIcon={<WatchLaterIcon style={{ fontSize: 17 }} />}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      variant="dialog"
                      disabled={events.length === 0}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
            </Grid> */}

            <Box mt={3}>
              <Typography variant="subtitle1" className={classes.title888}>
                Notify To
              </Typography>
              <Box my={0.5} />
              <Box display="flex" alignItems="center">
                {channelCollections.length > 0 &&
                  channelCollections.map((item, i) => {
                    const ischecked = channels.includes(item.id);

                    return (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={ischecked}
                            value={item.id}
                            onChange={handleCheckChannel}
                            size="small"
                            disabled={events.length === 0}
                          />
                        }
                        label={
                          <Typography
                            variant="subtitle2"
                            className={classes.title888}
                          >
                            {item.name}
                          </Typography>
                        }
                      />
                    );
                  })}
              </Box>
            </Box>

            <Box mt={3}>
              <Typography variant="subtitle1" className={classes.title888}>
                Recipients
              </Typography>
              <Box my={0.5} />
              <List dense>
                {recipientTypeCollections.length > 0 &&
                  recipientTypeCollections.map((item, i) => {
                    const ischecked = recipients.includes(item.id);

                    return (
                      <ListItem key={i} dense>
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <Checkbox
                            size="small"
                            edge="start"
                            disableRipple
                            checked={ischecked}
                            value={item.id}
                            onChange={handleCheckRecipients}
                            disabled={events.length === 0}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title555}
                            >
                              {item.name}
                            </Typography>
                          }
                          // secondary={
                          //   <Typography
                          //     variant="caption"
                          //     className={classes.title888}
                          //   >
                          //     {item.description}
                          //   </Typography>
                          // }
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              disabled={loading}
              onClick={() => setOpenCreateNotif(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant={loading ? "contained" : "outlined"}
              className={classes.button1}
              disabled={loading}
              onClick={handleUpdateNotify}
            >
              {loading ? "Menerapkan..." : "Terapkan"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      /> */}

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </ThemeProvider>
  );
};

export default DialogCreateNotif;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const listDays = [
  {
    name: "Monday",
    value: "0",
  },
  {
    name: "Tuesdat",
    value: "1",
  },
  {
    name: "Wednesday",
    value: "2",
  },
  {
    name: "Thursday",
    value: "3",
  },
  {
    name: "Friday",
    value: "4",
  },
  {
    name: "Saturday",
    value: "5",
  },
  {
    name: "Sunday",
    value: "6",
  },
];
