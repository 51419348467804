// import PictBackgroundImage from '../../../assets/images/Group_1922.png'
import PictBackgroundImage from "../../../../assets/images/Group_1922.png";

export const stylesStatusPending = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 2,
  },
  subRoot: {
    // background: 'url("../../../assets/images/reptile.jpg") no-repeat  fixed'
  },
  mediaThankyou: {
    width: 60,
  },
  description: {
    color: "#212529",
  },
  title: {
    color: "#5a5959",
    fontFamily: "Roboto",
  },
  kuotaUser: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    fontFamily: "Roboto",
  },
  masaBerlaku: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    textAlign: "right",
    color: "white",
    fontFamily: "Roboto",
  },
  statusAktif: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    textAlign: "right",
    marginRight: theme.spacing(3),
    color: "white",
    fontFamily: "Roboto",
  },
  buttonStatusActive: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "white",
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  titleMahkota: {
    marginTop: theme.spacing(12),
    width: 55,
    height: 77,
  },
  titleCompanyName: {
    color: "white",
    // marginTop: theme.spacing(12),
    fontFamily: "Roboto",
  },
  titleTrial: {
    color: "#f5d143",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontFamily: "Roboto",
  },

  /* SLIDER KUOTA USER */
  slider: {
    marginTop: theme.spacing(4.5),
  },
  thumbIcon: {
    borderRadius: "50%",
  },
  thumbIconWrapper: {
    // backgroundColor: '#fff',
    backgroundColor: "transparent",
  },
  trackSlider: {
    backgroundColor: "#19ce96",
    padding: "2px",
    borderRadius: "10%",
  },

  /* SLIDER MAHKOTA */
  sliderMahkota: {
    marginTop: theme.spacing(4.5),
    width: 300,
    textAlign: "center",
    display: "inline-block",
  },
  thumbIconWrapperMahkota: {
    backgroundColor: "#fff",
  },
  trackSliderMahkota: {
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    padding: "2px",
    borderRadius: "10%",
  },
  thumbIconMahkota: {
    width: "20px",
    height: "20px",
    marginBottom: "5px",
    position: "absolute",
  },
  popover: {
    pointerEvents: "none",
  },

  paperPopover: {
    padding: theme.spacing(1),
  },
  pilihRencana: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  buttonToggle: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    color: "white",
  },
  figureBoxPictMahkota: {
    width: 63,
    height: 63,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#f67070",
    display: "inline-block",
    margin: 5,
    textAlign: "center",
    cursor: "pointer",
  },
  figurePictMahkota: {
    display: "inline-block",
    margin: 5,
    textAlign: "center",
  },
  subRoot3: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  subRoot4: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  inputTextJumlahUser: {
    width: 40,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "grey",
    borderRadius: 3,
    fontSize: 17,
    textAlign: "center",
  },
  price: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  priceCurrency: {
    fontSize: 17,
    fontWeight: "bold",
  },
  discount: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    color: "#eb7d00",
  },
  buttonUpgrade: {
    // textTransform: 'capitalize',
    width: "200px",
    height: "72px",
    fontFamily: "Roboto",
  },
  kelolaKeanggotaan: {
    // marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    color: "white",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    marginBottom: theme.spacing(12),
  },
  successButton: {
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(4),
    backgroundColor: "#24d872",
    color: "white",
    borderColor: "transparent",
  },
  pendingButton: {
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(4),
    // backgroundColor: '#24d872',
    backgroundColor: "yellow",
    color: "grey",
    borderColor: "transparent",
  },
  successButtonDate: {
    marginLeft: theme.spacing(8),
    // marginTop: theme.spacing(4),
    // backgroundColor: '#24d872',
    color: "grey",
    fontFamily: "Roboto",
    // borderColor: 'transparent',
  },

  subRootCardRiwayat: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  nominal: {
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  langgananKeanggotaan: {
    // marginLeft: theme.spacing(8),
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  langgananInvoice: {
    // marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    color: "grey",
  },
  buttonLihatRiwayat: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    marginBottom: theme.spacing(16),
    marginTop: theme.spacing(8),
  },

  /* 
        ``````````````````````````````````
        DIALOG MODAL UPGRADE SAVE INVOICE
        
        ``````````````````````````````````
    */
  backgroundPaper: {
    // backgroundImage: 'url("../../../assets/images/Group_1922.png") no-repeat'
    backgroundImage: `url(${PictBackgroundImage}) no-repeat`,
  },
});
