import React, { useContext, useState } from "react";
import { Grid, Box, ButtonGroup, Button, Menu } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SyncIcon from "@material-ui/icons/Sync";

import moment from "moment";

import Table from "../../tables/TableMain";
import ContextReport from "../../../context/ContextReports";

import {
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SELECT_DATE_RANGE,
  SET_PAGINATION,
} from "../../../context/types";
import { URL_API } from "../../../../../constants/config-api";
import AxiosConfig from "../../../../../constants/config-axios";
import {
  previousToday,
  previousYesterday,
  previousThisWeek,
  previousLastWeek,
  previousLastSevenDays,
  previousPrevSevenDays,
  previousAnyDateOne,
  previousAnyWeek,
  previousAnyDateSeven,
  nextToday,
  nextYesterday,
  nextThisWeek,
  nextLastWeek,
  nextLastSevenDays,
  nextPrevSevenDays,
  nextAnyDateOne,
  nextAnyWeek,
  nextAnyDateSeven,
} from "./navigationDate";
import extractTable from "../../global/extractTable";
import DateRangeFilter from "./grouping/date-range/DateRangeFilter";
import handleError from "../../global/handleError";
import DialogError from "../../../../../components/DialogError";
import { Typography } from "@material-ui/core";

const DetailedType = ({ classes }) => {
  const {
    reportState,
    filterState,
    tableState,
    filterDispatch,
    tableDispatch,
  } = useContext(ContextReport);
  const [anchorDate, setAnchorDate] = useState(null);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const count = { one: 1, seven: 7 };
  let dataPost = {
    name: filterState.dateRange.title,
    platform: "web",
    field_preset_id: tableState.fieldPresets.id,
    report_format: "table",
    filter_by: {
      entity: [
        { name: "goal", query: "asdfasdfds" },
        { name: "ma", query: "asdfasdfds" },
      ],
      date_range: filterState.dateRange.code,
      user: tableState.usersSelected,
      unit: tableState.unitsSelected,
      status: tableState.status,
      achievement: {
        value_type: tableState.valueType,
        cal_method: tableState.calMethod,
      },
    },
    // filter_by_date_range_start: "",
    // filter_by_date_range_end: "",
    entities: tableState.entity,
    group_by: {
      entity: ["goal"],
      date_time: [],
      user: false,
      unit: ["division", "branch"],
      orientation: [],
    },
    group_by_calculation: "1",
    order_by: tableState.selectedOrder,
    primary_column: "name",
    limit_type: "desc",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  const date = new Date();

  const tomorow = moment(date).add(1, "days").startOf("day");
  const yesterday = moment(date).subtract(2, "days").startOf("day");
  const startNextWeek = moment(date).add(1, "weeks").startOf("week");
  const endNextWeek = moment(date).add(1, "weeks").endOf("week");
  const startPrevWeek = moment(date).subtract(2, "weeks").startOf("week");
  const endPrevWeek = moment(date).subtract(2, "weeks").endOf("week");
  const startB47Days = moment(date).subtract(21, "days").startOf("day");
  const endB47Days = moment(date).subtract(15, "days").endOf("day");
  const startNext7Days = moment(date).add(0, "days").startOf("day");
  const endNext7Days = moment(date).add(6, "days").startOf("day");

  // ===========================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~ PREVIOUS ~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================
  const togglePrev = () => {
    const { startDate, endDate } = filterState.dateRange;
    const { currentPage, perPage } = tableState.pagination;
    const { one, seven } = count;

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    switch (filterState.dateRange.code) {
      case "today":
        const { start_date, end_date } = previousToday(one, startDate, endDate);

        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: "Yesterday",
              code: "yesterday",
              startDate: start_date,
              endDate: end_date,
            },
          },
        });

        let dataToday = {
          ...dataPost,
          name: "Yesterday",
          filter_by: { ...dataPost.filter_by, date_range: "yesterday" },
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataToday
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "yesterday":
        const { start_date1, end_date1 } = previousYesterday(
          one,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: `${start_date1} - ${end_date1}`,
              code: "any_date_one",
              startDate: start_date1,
              endDate: end_date1,
            },
          },
        });

        let dataYesterday = {
          ...dataPost,
          name: "Any Date One",
          filter_by: { ...dataPost.filter_by, date_range: "any_date" },
          filter_by_date_range_start: moment(start_date1).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date1).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataYesterday
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "this_week":
        const { start_date2, end_date2 } = previousThisWeek(
          one,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: "Last Week",
              code: "last_week",
              startDate: start_date2,
              endDate: end_date2,
            },
          },
        });

        let dataThisWeek = {
          ...dataPost,
          name: "Last Week",
          filter_by: { ...dataPost.filter_by, date_range: "last_week" },
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataThisWeek
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "last_week":
        const { start_date3, end_date3 } = previousLastWeek(
          one,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: `${start_date3} - ${end_date3}`,
              code: "any_date_week",
              startDate: start_date3,
              endDate: end_date3,
            },
          },
        });

        let dataLstWeek = {
          ...dataPost,
          name: "Any Date Week",
          filter_by: { ...dataPost.filter_by, date_range: "any_date" },
          filter_by_date_range_start: moment(start_date3).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date3).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataLstWeek
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "last_seven_days":
        const { start_date4, end_date4 } = previousLastSevenDays(
          seven,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: "Previous Seven Days",
              code: "previous_seven_days",
              startDate: start_date4,
              endDate: end_date4,
            },
          },
        });

        let dataLst7Days = {
          ...dataPost,
          name: "Previous Seven Days",
          filter_by: {
            ...dataPost.filter_by,
            date_range: "previous_seven_days",
          },
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataLst7Days
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "previous_seven_days":
        const { start_date5, end_date5 } = previousPrevSevenDays(
          seven,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: `${start_date5} - ${end_date5}`,
              code: "any_date_seven",
              startDate: start_date5,
              endDate: end_date5,
            },
          },
        });

        let dataPrev7Days = {
          ...dataPost,
          name: "Any Date Seven",
          filter_by: {
            ...dataPost.filter_by,
            date_range: "any_date",
          },
          filter_by_date_range_start: moment(start_date5).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date5).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataPrev7Days
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "any_date_one":
        const { start_date8, end_date8 } = previousAnyDateOne(
          one,
          startDate,
          endDate
        );
        let isTomorow;
        const fnTomorow = (theDate) =>
          theDate.isSame(moment(tomorow, "DD MMM YYYY"));
        isTomorow = fnTomorow(moment(startDate, "DD MMM YYYY"));

        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: isTomorow ? "Today" : `${start_date8} - ${end_date8}`,
              code: isTomorow ? "today" : "any_date_one",
              startDate: start_date8,
              endDate: end_date8,
            },
          },
        });

        let dataAnyDateOne = {
          ...dataPost,
          name: isTomorow ? "Today" : "Any Date One",
          filter_by: {
            ...dataPost.filter_by,
            date_range: isTomorow ? "today" : "any_date",
          },
          filter_by_date_range_start: moment(start_date8).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date8).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataAnyDateOne
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "any_date_week":
        const { start_date9, end_date9 } = previousAnyWeek(
          one,
          startDate,
          endDate
        );
        let startWeek;
        let endWeek;
        let code_week;
        let txt_week;
        const fnToThisWeekStart = (theDate) =>
          theDate.isSame(moment(startNextWeek, "DD MMM YYYY"));
        const fnToThisWeekEnd = (theDate) =>
          theDate.isSame(moment(endNextWeek, "DD MMM YYYY"));

        startWeek = fnToThisWeekStart(moment(startDate, "DD MMM YYYY"));
        endWeek = fnToThisWeekEnd(moment(endDate, "DD MMM YYYY"));
        const isNextWeek = startWeek || endWeek;

        code_week = isNextWeek ? "this_week" : "any_date_week";
        txt_week = isNextWeek ? "This Week" : `${start_date9} - ${end_date9}`;

        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: txt_week,
              code: code_week,
              startDate: start_date9,
              endDate: end_date9,
            },
          },
        });

        let dataAnyDateWeek = {
          ...dataPost,
          name: isNextWeek ? "This Week" : "Any Date Week",
          filter_by: {
            ...dataPost.filter_by,
            date_range: isNextWeek ? "this_week" : "any_date",
          },
          filter_by_date_range_start: moment(start_date9).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date9).format("YYYY-MM-DD"),
        };
        // // console.log("dataAnyDateWeek", dataAnyDateWeek);

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataAnyDateWeek
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Result previous Any Week", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "any_date_seven":
        const { start_date10, end_date10 } = previousAnyDateSeven(
          seven,
          startDate,
          endDate
        );
        let startNext7;
        let endNext7;
        const fnToThisLast7Start = (theDate) =>
          theDate.isSame(moment(startNext7Days, "DD MMM YYYY"));
        const fnToThisLast7End = (theDate) =>
          theDate.isSame(moment(endNext7Days, "DD MMM YYYY"));

        startNext7 = fnToThisLast7Start(moment(startDate, "DD MMM YYYY"));
        endNext7 = fnToThisLast7End(moment(endDate, "DD MMM YYYY"));
        const isNext7Days = startNext7 || endNext7;

        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: isNext7Days
                ? "Last 7 Days"
                : `${start_date10} - ${end_date10}`,
              code: isNext7Days ? "last_seven_days" : "any_date_seven",
              startDate: start_date10,
              endDate: end_date10,
            },
          },
        });

        let dataPrevAnyDateSeven = {
          ...dataPost,
          name: isNext7Days ? "Last 7 Days" : "Any Date Seven",
          filter_by: {
            ...dataPost.filter_by,
            date_range: isNext7Days ? "last_seven_days" : "any_date",
          },
          filter_by_date_range_start: moment(start_date10).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date10).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataPrevAnyDateSeven
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      default:
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: filterState.dateRange.title,
              code: filterState.dateRange.code,
              startDate: filterState.dateRange.startDate,
              endDate: filterState.dateRange.endDate,
            },
          },
        });

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataPost
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);
            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
    }
  };

  // ===========================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~ NEXT ~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================
  const toggleNext = () => {
    const { startDate, endDate } = filterState.dateRange;
    const { currentPage, perPage } = tableState.pagination;
    const { one, seven } = count;

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    switch (filterState.dateRange.code) {
      case "today":
        const { start_date, end_date } = nextToday(one, startDate, endDate);
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: `${start_date} - ${start_date}`,
              code: "any_date_one",
              startDate: start_date,
              endDate: end_date,
            },
          },
        });

        let dataToday = {
          ...dataPost,
          name: "Any Date One",
          filter_by: { ...dataPost.filter_by, date_range: "any_date" },
          filter_by_date_range_start: moment(start_date).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataToday
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "yesterday":
        const { start_date1, end_date1 } = nextYesterday(
          one,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: `Today`,
              code: "today",
              startDate: start_date1,
              endDate: end_date1,
            },
          },
        });

        let dataYesterday = {
          ...dataPost,
          name: "Today",
          filter_by: { ...dataPost.filter_by, date_range: "today" },
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataYesterday
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "this_week":
        const { start_date2, end_date2 } = nextThisWeek(
          one,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: `${start_date2} - ${end_date2}`,
              code: "any_date_week",
              startDate: start_date2,
              endDate: end_date2,
            },
          },
        });

        let dataThisWeek = {
          ...dataPost,
          name: "Any Date Week",
          filter_by: { ...dataPost.filter_by, date_range: "any_date" },
          filter_by_date_range_start: moment(start_date2).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date2).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataThisWeek
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "last_week":
        const { start_date3, end_date3 } = nextLastWeek(
          one,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: "This Week",
              code: "this_week",
              startDate: start_date3,
              endDate: end_date3,
            },
          },
        });

        let dataLastWeek = {
          ...dataPost,
          name: "This Week",
          filter_by: { ...dataPost.filter_by, date_range: "this_week" },
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataLastWeek
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "last_seven_days":
        const { start_date4, end_date4 } = nextLastSevenDays(
          seven,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: `${start_date4} - ${end_date4}`,
              code: "any_date_seven",
              startDate: start_date4,
              endDate: end_date4,
            },
          },
        });

        let dataLst7Days = {
          ...dataPost,
          name: "Any Date Seven",
          filter_by: {
            ...dataPost.filter_by,
            date_range: "any_date",
          },
          filter_by_date_range_start: moment(start_date4).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date4).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataLst7Days
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "previous_seven_days":
        const { start_date5, end_date5 } = nextPrevSevenDays(
          seven,
          startDate,
          endDate
        );
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: "Last 7 Days",
              code: "last_seven_days",
              startDate: start_date5,
              endDate: end_date5,
            },
          },
        });

        let dataPrev7Days = {
          ...dataPost,
          name: "Last 7 Days",
          filter_by: {
            ...dataPost.filter_by,
            date_range: "last_seven_days",
          },
          filter_by_date_range_start: moment(start_date5).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date5).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataPrev7Days
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "any_date_one":
        const { start_date8, end_date8 } = nextAnyDateOne(
          one,
          startDate,
          endDate
        );
        let isYesterday;
        const fnYesterday = (theDate) =>
          theDate.isSame(moment(yesterday, "DD MMM YYYY"));
        isYesterday = fnYesterday(moment(startDate, "DD MMM YYYY"));

        // // console.log("YESTERDAY", isYesterday);

        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: isYesterday
                ? "Yesterday"
                : `${start_date8} - ${end_date8}`,
              code: isYesterday ? "yesterday" : "any_date_one",
              startDate: start_date8,
              endDate: end_date8,
            },
          },
        });

        let dataNextAnyDateOne = {
          ...dataPost,
          name: isYesterday ? "Yesterday" : "Any Date One",
          filter_by: {
            ...dataPost.filter_by,
            date_range: isYesterday ? "yesterday" : "any_date",
          },
          filter_by_date_range_start: moment(start_date8).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date8).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataNextAnyDateOne
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "any_date_week":
        const { start_date9, end_date9 } = nextAnyWeek(one, startDate, endDate);
        let startWeek;
        let endWeek;
        const fnToLastWeekStart = (theDate) =>
          theDate.isSame(moment(startPrevWeek, "DD MMM YYYY"));
        const fnToLastWeekEnd = (theDate) =>
          theDate.isSame(moment(endPrevWeek, "DD MMM YYYY"));

        startWeek = fnToLastWeekStart(moment(startDate, "DD MMM YYYY"));
        endWeek = fnToLastWeekEnd(moment(endDate, "DD MMM YYYY"));
        const isPrevWeek = startWeek || endWeek;

        // // console.log("isNextWeek", isPrevWeek);

        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: isPrevWeek ? "Last Week" : `${start_date9} - ${end_date9}`,
              code: isPrevWeek ? "last_week" : "any_date_week",
              startDate: start_date9,
              endDate: end_date9,
            },
          },
        });

        let dataNextAnyWeek = {
          ...dataPost,
          name: isPrevWeek ? "Last Week" : "Any Date Week",
          filter_by: {
            ...dataPost.filter_by,
            date_range: isPrevWeek ? "last_week" : "any_date",
          },
          filter_by_date_range_start: moment(start_date9).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date9).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataNextAnyWeek
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
        break;
      case "any_date_seven":
        const { start_date10, end_date10 } = nextAnyDateSeven(
          seven,
          startDate,
          endDate
        );

        let startB47;
        let endB47;
        const fnToLastB47DaysStart = (theDate) =>
          theDate.isSame(moment(startB47Days, "DD MMM YYYY"));
        const fnToLastB47DaysEnd = (theDate) =>
          theDate.isSame(moment(endB47Days, "DD MMM YYYY"));

        startB47 = fnToLastB47DaysStart(moment(startDate, "DD MMM YYYY"));
        endB47 = fnToLastB47DaysEnd(moment(endDate, "DD MMM YYYY"));
        const isPrevB47Days = startB47 || endB47;

        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: isPrevB47Days
                ? "Previous 7 Days"
                : `${start_date10} - ${end_date10}`,
              code: isPrevB47Days ? "previous_seven_days" : "any_date_seven",
              startDate: start_date10,
              endDate: end_date10,
            },
          },
        });

        let dataPrevAnyDateSeven = {
          ...dataPost,
          name: isPrevB47Days ? "Previous 7 Days" : "Any Date Seven",
          filter_by: {
            ...dataPost.filter_by,
            date_range: isPrevB47Days ? "previous_seven_days" : "any_date",
          },
          filter_by_date_range_start: moment(start_date10).format("YYYY-MM-DD"),
          filter_by_date_range_end: moment(end_date10).format("YYYY-MM-DD"),
        };

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataPrevAnyDateSeven
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results.data);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });

        break;
      default:
        filterDispatch({
          type: SELECT_DATE_RANGE,
          payload: {
            date_range: {
              title: filterState.dateRange.title,
              code: filterState.dateRange.code,
              startDate: filterState.dateRange.startDate,
              endDate: filterState.dateRange.endDate,
            },
          },
        });

        AxiosConfig.post(
          `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
          dataPost
        )
          .then((res) => {
            const result = res.data.data;
            const DATATABLE = extractTable(result.results);
            const pagination = {
              currentPage: result.results.current_page,
              perPage: result.results.per_page,
              lastPage: result.results.last_page,
            };
            // // console.log("Ori Result PRST", result);

            if (res.status === 200) {
              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: DATATABLE, load: false },
              });

              tableDispatch({
                type: SET_PAGINATION,
                payload: { pagination },
              });

              tableDispatch({
                type: HIT_TOTAL_RECORDS,
                payload: { totalRecords: result.results.total },
              });
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);

            // Handle Error
            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: [], load: false },
            });
          });
    }
  };

  // Show Dialog Date Range
  const handleShowAnchorDate = (event) => {
    setAnchorDate(event.currentTarget);
  };

  // Hide Dialog Date Range
  const handleCloseAnchorDate = () => {
    setAnchorDate(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box height={60} mt={3} display="flex" justifyContent="space-between">
          <ButtonGroup size="small" style={{ height: 35 }}>
            {/* <Button
              className={classes.btnGroup}
              size="small"
              disabled={filterState.dateRange.title === "Any Date"}
              onClick={togglePrev}>
              <ChevronLeftIcon fontSize="small" />
            </Button> */}
            <Button
              className={classes.btnGroupCenter}
              size="small"
              startIcon={<DateRangeIcon />}
              disabled={tableState.loadTable || filterState.loadFilter}
              onClick={handleShowAnchorDate}
            >
              {filterState.dateRange.title === undefined
                ? "Custom Range"
                : filterState.dateRange.title}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorDate}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorDate)}
              onClose={handleCloseAnchorDate}
            >
              <DateRangeFilter
                classes={classes}
                onCloseAnchorDate={handleCloseAnchorDate}
                setAnchorDate={setAnchorDate}
              />
            </Menu>
            {/* <Button
              className={classes.btnGroup}
              size="small"
              disabled={filterState.dateRange.title === "Any Date"}
              onClick={toggleNext}>
              <ChevronRightIcon fontSize="small" />
            </Button> */}
          </ButtonGroup>

          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography variant="subtitle2" className={classes.title333}>
              Last sync time :{" "}
              {reportState.lastSync.last_sync !== "" &&
                moment(
                  reportState.lastSync.last_sync !== "" &&
                    reportState.lastSync.last_sync
                ).format("DD/MM/YYYY")}{" "}
              at{" "}
              {reportState.lastSync.last_sync !== "" &&
                moment(reportState.lastSync.last_sync).format("hh:mm A")}
              <SyncIcon
                style={{ color: "#555", fontSize: 14, marginLeft: 5 }}
              />
            </Typography>
            <Typography variant="subtitle2" className={classes.title333}>
              Info Status :{" "}
              {reportState.lastSync.last_status !== null
                ? reportState.lastSync.last_status &&
                  reportState.lastSync.last_status.status
                : "latest"}
              <SyncIcon
                style={{ color: "#555", fontSize: 14, marginLeft: 5 }}
              />
            </Typography>
          </Box>
        </Box>
        <Table />
      </Grid>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Grid>
  );
};

export default DetailedType;
