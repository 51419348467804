import React, { useState, useCallback } from "react";
import {
	Box,
	DialogActions,
	Grid,
	IconButton,
	InputAdornment,
	InputBase,
	TextField,
} from "@material-ui/core";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import { Autocomplete } from "@material-ui/lab";
import { useDebouncedCallback } from "use-debounce/lib";
import PictREFRESH from "../../../../../assets/images/Group_2725.png";

function TableFilterUserRegistered({
	classes,
	masterCategory,
	dataRegisteredUser,
	setDataRegisteredUser,
	refreshListRegisteredUser
}) {
  	const [locale, setLocale] = useState("id");

	const [handleSearch] = useDebouncedCallback(
		useCallback((value) => {
			handleRefreshToFirstPage()
		}, []),
		1000,

		{ maxWait: 5000 }
	);

	const handleRefreshToFirstPage = () => {
	  refreshListRegisteredUser(null, 0, 0, null)
	}

  	return (
		<Box
		  display="flex"
		  justifyContent="space-between"
		  alignItems="center"
		>
			<Box display="flex" alignItems="center">
				<div className={classes.search}>
					<InputBase
					startAdornment={
						<SearchIcon
						style={{ color: "#C4C4C4", fontSize: 18, marginLeft: 12 }}
						/>
					}
					endAdornment={
						<IconButton
						size="small"
						onClick={() => {
							let dataListTemporary = dataRegisteredUser;
							dataListTemporary.options.filter.search = "";
							setDataRegisteredUser({...dataListTemporary});
							handleRefreshToFirstPage();
						}}
						>
						<CancelIcon
							style={{
							color: "#C4C4C4",
							fontSize: 18,
							marginRight: 12,
							}}
						/>
						</IconButton>
					}
					onChange={(e) => {
						let dataListTemporary = dataRegisteredUser;
						dataListTemporary.options.filter.search = e.target.value;
						setDataRegisteredUser({...dataListTemporary});
						handleSearch(e.target.value)
					}}
					value={dataRegisteredUser.options.filter.search}
					placeholder="Telusuri nama user..."
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					inputProps={{ "aria-label": "Search" }}
					/>
				</div>
				<div className={classes.searchAutocomplete}>
					<Autocomplete
						options={masterCategory.masterFilterGenderCollections}
						getOptionLabel={(option) => {
							if (option && option.name) {
							return option.name;
							} else {
							return "";
							}
						}}
						className={classes.autocompleteTextField}
						value={dataRegisteredUser.options.filter.sex_obj}
						onChange={(event, newValue) => {
							let dataTemporary = dataRegisteredUser;
							dataTemporary.options.filter.sex_obj = newValue;
							dataTemporary.options.filter.sex_id = newValue ? newValue.id : "";
							setDataRegisteredUser({...dataTemporary});
							refreshListRegisteredUser(null, 0, 0, null)
						}}
						noOptionsText="Tidak ada pilihan"
						popupIcon={<ArrowDropDownIcon />}
						renderInput={(params) => (
						  <TextField
							style={{ fontSize: 13 }}
							{...params}
							fullWidth
							variant="outlined"
							size="small"
							placeholder="Telusuri Jenis Kelamin"
						  />
						)}
					/>
				</div>
				<div className={classes.search}>
					<InputBase
						style={{paddingTop: "0.75px", paddingBottom: "0.75px"}}
						type="number"
						placeholder="Telusuri Umur"
						endAdornment={
							<IconButton
							size="small"
							onClick={() => {
								let dataListTemporary = dataRegisteredUser;
								dataListTemporary.options.filter.age = "";
								setDataRegisteredUser({...dataListTemporary});
								handleRefreshToFirstPage();
							}}
							>
							<CancelIcon
								style={{
								color: "#C4C4C4",
								fontSize: 18,
								marginRight: 12,
								}}
							/>
							</IconButton>
						}
						onChange={(event, newValue) => {
							let dataTemp = dataRegisteredUser;
							dataTemp.options.filter.age = event.target.value;
							setDataRegisteredUser({...dataTemp});
							handleSearch(event.target.value)
						}}
						value={dataRegisteredUser.options.filter.age}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						inputProps={{ "aria-label": "Search" }}
					/>
				</div>
				<Box mx={1} />
			</Box>

			<Box display="flex" alignItems="center">
				<IconButton style={{ padding: 4 }} 
                    onClick={() => {
                        refreshListRegisteredUser(null, 0, 0, null);
                    }}
				>
					<img
					src={PictREFRESH}
					style={{ width: 17, height: 17 }}
					alt="."
					/>
				</IconButton>
			</Box>
		</Box>
  	);
}

export default TableFilterUserRegistered;
