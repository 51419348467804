import React, { Fragment, useCallback, useRef, useState } from "react";
import {
  Box,
	IconButton,
	InputAdornment,
	InputBase,
  TextField,
} from "@material-ui/core";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";

import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PictREFRESH from "../../../../assets/images/Group_2725.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";
import { Button } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce/lib";
import { Autocomplete } from "@material-ui/lab";
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

function TableFilter({
  classes,
  masterCategory,
  dataRequestTimeOffList,
  setDataRequestTimeOffList,
  getDataRequestTimeOffList,
}) {
  const [locale, setLocale] = useState("id");
  let inputRefSearch = useRef(null);

  const [handleSearch] = useDebouncedCallback(
    useCallback((value) => {
      handleRefreshToFirstPage()
    }, []),
    1000,

    { maxWait: 5000 }
  );

  const handleRefresh = () => {
    getDataRequestTimeOffList();
  }

  const handleRefreshToFirstPage = () => {
    let dataTemp = dataRequestTimeOffList;
    dataTemp.options.paging.offset = 0;
    dataTemp.options.paging.page = 1;
    setDataRequestTimeOffList({...dataTemp});
    getDataRequestTimeOffList();
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      pr={3}
    >
      <Box display="flex" alignItems="center">
        <div className={classes.search}>
          <InputBase
            startAdornment={
              <SearchIcon
                style={{ color: "#C4C4C4", fontSize: 18, marginLeft: 12 }}
              />
            }
            endAdornment={
              <IconButton
                size="small"
                onClick={() => {
                  let dataTemp = dataRequestTimeOffList;
                  dataTemp.options.filter.search = "";
                  setDataRequestTimeOffList({...dataTemp});
                  handleRefreshToFirstPage();
                }}
              >
                <CancelIcon
                  style={{
                    color: "#C4C4C4",
                    fontSize: 18,
                    marginRight: 12,
                  }}
                />
              </IconButton>
            }
            onChange={(e) => {
              let dataTemp = dataRequestTimeOffList;
              dataTemp.options.filter.search = e.target.value;
              setDataRequestTimeOffList({...dataTemp});
              handleSearch(e.target.value)
            }}
            value={dataRequestTimeOffList.options.filter.search}
            placeholder="Telusuri nama user..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "Search" }}
          />
        </div>
				<div className={classes.searchAutocomplete}>
					<Autocomplete
						options={masterCategory.masterStatusCollections}
						getOptionLabel={(option) => {
							if (option && option.name) {
							return option.name;
							} else {
							return "";
							}
						}}
						className={classes.autocompleteTextField}
            value={dataRequestTimeOffList.options.filter.status_obj}
						onChange={(event, newValue) => {
              let dataTemp = dataRequestTimeOffList;
              dataTemp.options.filter.status = newValue ? newValue.id : "";
              dataTemp.options.filter.status_obj = newValue;
              setDataRequestTimeOffList({...dataTemp});
              handleRefreshToFirstPage();
						}}
						noOptionsText="Tidak ada pilihan"
						popupIcon={<ArrowDropDownIcon />}
						renderInput={(params) => (
						  <TextField
                style={{ fontSize: 13 }}
                {...params}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Telusuri Status"
						  />
						)}
					/>
				</div>
        <Box mx={1} />
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton style={{ padding: 4 }} onClick={handleRefresh}>
          <img
            src={PictREFRESH}
            style={{ width: 17, height: 17 }}
            alt="."
          />
        </IconButton>
      </Box>
    </Box>
  );
}

export default TableFilter;
