import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    color: "#888",
    textDecoration: "none",
  },
});

const useStyle = makeStyles(styles);

const ForMarketing = ({ img, title, benefits }) => {
  const classes = useStyle();

  return (
    <Box my={15} py={15} bgcolor="#FAFAFA">
      <Container>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={6}>
            <img
              src={img}
              width="100%"
              height={400}
              style={{ borderRadius: 10 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box mb={5}>
              <Typography variant="h5" className={classes.title333}>
                <b>{title}</b>
              </Typography>
            </Box>
            <Box mb={4}>
              {benefits.map((item, i) => (
                <Typography
                  key={i}
                  variant="subtitle1"
                  className={classes.title555}
                  style={{ marginBottom: 13, fontSize: 16 }}>
                  {item}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ForMarketing;
