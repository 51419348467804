/*
    ````````````````````````````````
    CREATE JENIS UNIT IN TAMBAH HEAD

    ````````````````````````````````
*/

import React, { useEffect, useState, useRef, useContext } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";

import ContextNewSO from "../Context/ContextNewSO";

import { styles } from "../Style/StyleDialogDetail";

import DialogError from "../../../components/DialogError";
import Capitalize from "../../../utilities/Capitalize";
import { URL_API } from "../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogCreateJenisUnit = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  let textInputReff = useRef(null);

  const [loader, setLoader] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  useEffect(() => {
    if (context.isOpenDialogCreateJenisUnit == true) {
      context.setOpenDialogTambahHead(false);

      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      //*
      context.setSuccessCreateJenisUnit(false);
    }
  }, [context.isOpenDialogCreateJenisUnit]);

  // const [ valueJenisUnitName, context.setValueJenisUnitName ] = useState('');
  const handleChange = (e) => {
    e.preventDefault();
    context.setValueJenisUnitName(e.target.value);
  };

  const handleBatal = () => {
    context.setOpenDialogTambahHead(true);
    context.setOpenDialogCreateJenisUnit(false);
  };

  const handleSubmitCreateJenisUnit = () => {
    setLoader(true);

    let data = {
      MasterStructureUnitType: {
        name: context.valueJenisUnitName,
      },
    };

    // console.log("Data SUBMIT : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + "/human-resource/master-structure-unit-type", data)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          if (response.status == 200) {
            context.setOpenDialogTambahHead(true);
            context.setOpenDialogCreateJenisUnit(false);

            context.setSuccessCreateJenisUnit(true);
          }
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setTextErrorInformationValue(".");
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                }
              }

              if (error.response.data.info.errors.hasOwnProperty("end_date")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(error.response.data.info.errors.end_date)
                );
              }

              if (
                error.response.data.info.errors.hasOwnProperty(
                  "calculation_method_id"
                )
              ) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(
                      error.response.data.info.errors.calculation_method_id
                    )
                );
              }

              if (error.response.data.info.errors.hasOwnProperty("owner_id")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(error.response.data.info.errors.owner_id)
                );
              }

              if (
                error.response.data.info.errors.hasOwnProperty("target_value")
              ) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 400 : " +
                    Capitalize(error.response.data.info.errors.target_value)
                );
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogCreateJenisUnit}
        // onClose={() => context.setOpenDialogCreateJenisUnit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>Tambah Jenis Unit</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={textInputReff}
            id="outlined-bare"
            className={classes.textField}
            onChange={handleChange}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            // placeholder = {goalDetailState.name}
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}
        >
          <Box marginRight={theme.spacing(0.5)}>
            <Button
              onClick={handleBatal}
              variant="outlined"
              size="small"
              className={classes.buttonOutlined}
            >
              Batal
            </Button>

            <Button
              // onClick={() => Redirect(ToCompletionProfile)}
              onClick={handleSubmitCreateJenisUnit}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
            >
              {loader == true ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Tambah"
              )}
            </Button>
          </Box>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogCreateJenisUnit);
