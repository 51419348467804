import React, { Component, useEffect, useState } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link, IconButton, InputBase, Divider,
    Tooltip, List, ListItem, ListItemText, Avatar, Table, TableBody, TableRow, TableCell,
    Chip, TablePagination, TableHead, TableSortLabel, Radio, FormLabel

} from '@material-ui/core';

import axios from 'axios';
import { URL_API } from '../../../../constants/config-api';

import Snackbeer from '../../../../components/Snackbeer';

const DialogEditAnggotaKeluarga = props => {

    const { 
        
            classes,
            modalEditAnggotaKeluarga,
            setModalEditAnggotaKeluarga,
            dataDetailEdit,
            handleChangeNamaAnggotaKeluarga,
            nameAnggotaKeluarga,
            hubunganKeluarga,
            handleChangeHubunganKeluarga,
            handleChangeTanggalLahir,
            tanggalLahir,

            handleSimpanPerubahan
        
        } = props;



    /*
        ```````````````````````
        HANDLE SIMPAN PERUBAHAN

        ````````````````````````
    */
    const [ isModalResponse200, setModalResponse200 ] = useState(false);

    return (

        <div>
            <Dialog
                open={modalEditAnggotaKeluarga}
                onClose={() => setModalEditAnggotaKeluarga(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{textAlign: "left"}}>
                    <Typography variant='subtitle1' className={classes.title}>
                        <b>Ubah Data Anggota Keluarga</b>
                    </Typography>
                </DialogTitle>
                <DialogContent>

                    {/* <List className={classes.list}> */}
                    <List>
                        <ListItem >  
                            <ListItemText 
                                primary={
                                    <Typography variant='subtitle1' className={classes.titleForm}>
                                        <b>Nama</b>
                                    </Typography>
                                } 
                            />
                            <TextField
                                id="outlined-bare"
                                onChange= {handleChangeNamaAnggotaKeluarga}
                                value={nameAnggotaKeluarga}
                                className={classes.textField}
                                placeholder={dataDetailEdit.name}
                                variant="outlined"
                                // error={infoError == true && npwp == '' ? true : false}
                                // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
                                // required={true} 
                                // name='nip'
                                // color='primary'
                                // onKeyDown={handleEnterPress}
                                // disabled= {isLockedStatusState == 1 ? true : false}
                                // fullWidth

                            />
                        </ListItem>

                        <ListItem >  
                            <ListItemText 
                                primary={
                                    <Typography variant='subtitle1' className={classes.titleForm}>
                                        <b>Hubungan</b>
                                    </Typography>
                                } 
                            />

                            <TextField
                                // label="Pilih Negara : "
                                // placeholder={dataDetailEdit !== '' ? dataDetailEdit.hubungan_keluarga.value : ''}
                                id="outlined-select-provinsi"
                                select
                                className={classes.textField}
                                value={hubunganKeluarga.name}
                                onChange={handleChangeHubunganKeluarga('name')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                            >
                                {
                                    hubunganKeluargas.map (

                                        option => (

                                            <MenuItem key={option.id} value={option}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )
                                }

                            </TextField>
                        </ListItem>

                        <ListItem>  
                            <ListItemText 
                                primary={
                                    <Typography variant='subtitle1' className={classes.titleForm}>
                                        <b>TTL</b>
                                    </Typography>
                                } 
                            />
                            <form className={classes.container} noValidate>
                                <TextField
                                    id="date"
                                    onChange={handleChangeTanggalLahir}
                                    value={tanggalLahir}
                                    placeholder={dataDetailEdit.date_of_birth}
                                    defaultValue="2019-05-24"
                                    type="date"
                                    // label="Birthday"
                                    variant="outlined"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                        </ListItem>
                    </List>

                    <DialogContentText id="alert-dialog-description">
                        <Typography variant='h6'>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{alignItems: "right", justifyContent:'flex-end'}}>
                    <Button 
                        onClick={() => setModalEditAnggotaKeluarga(false)} 
                        variant='outlined' 
                        size='small'
                        className={classes.buttonModalCancel}    
                    >
                        Batal
                    </Button>
                    
                    <Button 
                        onClick= { handleSimpanPerubahan }
                        variant='contained' 
                        color="primary" 
                        size='small'
                        className={classes.buttonModal}
                    >  
                        Simpan Perubahan
                    </Button>
                </DialogActions>
                <br />
                <br />
            </Dialog>

            <Snackbeer
                classes={classes}
                isModalResponse200= {isModalResponse200}
                setModalResponse200 = {setModalResponse200}
                messages = 'Perubahan data berhasil di simpan !'
            />
        </div>
    )   
};

export default DialogEditAnggotaKeluarga;

const hubunganKeluargas = [
    {
        id: 1,
        value: "Ayah Kandung",
        label: "Ayah Kandung",
    },
    {
        id: 2,
        value: "Ibu Kandung",
        label: "Ibu Kandung"
    },
    {
        id: 3,
        value: "Ayah Tiri",
        label: "Ayah Tiri"
    },
    {
        id: 4,
        value: "Ibu Tiri",
        label: "Ibu Tiri"
    },
    {
        id: 5,
        value: "Istri / Suami",
        label: "Istri / Suami"
    },
    {
        id: 6,
        value: "Saudara Kandung",
        label: "Saudara Kandung"        
    },
    {
        id: 7,
        value: "Saudara Tiri",
        label: "Saudara Tiri"        
    },
    {
        id: 8,
        value: "Anak Kandung",
        label: "Anak Kandung"                        
    }, 
    {
        id: 9,
        value: "Anak Tiri",
        label: "Anak Tiri"                        
    },
    {
        id: 10,
        value: 'Anak Angkat',
        label: 'Anak Angkat' 
    }
];
