import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Container } from "@material-ui/core";

import styles from "./Style/landingStyle";

import HeaderNew from "./ComponentViewLandingPage/home/HeaderNew";
import NavRight from "./ComponentViewLandingPage/NaviRight";
import NavLeft from "./ComponentViewLandingPage/NavLeft";
import ForMarketing from "./ComponentViewLandingPage/solution/ForMarketing";
import TheySaid from "./ComponentViewLandingPage/solution/TheySaid";
import TouchUs from "./ComponentViewLandingPage/home/TouchUs";
import Footer from "./ComponentViewLandingPage/Footer";
import FloatingWhatsapp from "./ComponentViewLandingPage/components/FloatingWhatsapp";

import bgTouch from "../../assets/images/bgTouch.png";
import marketing1 from "../../assets/images/marketing1.png";
import { navigate } from "hookrouter";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const ViewBusinessSolution = () => {
  const classes = useStyles();

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <div>
      <HeaderNew
        color="transparent"
        routes={dashboardRoutes}
        brand="Performate"
        rightLinks={<NavRight origin="2" />}
        leftLinks={<NavLeft origin="2" />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white",
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={20} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            className={classes.title}
            style={{ color: "#333" }}
          >
            <b>Tingkatkan konversi visitor website Anda bersama Performate</b>
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.heroDesc}
            style={{ color: "#333" }}
          >
            Monitor implementasi strategi setiap channel marketing anda dengan
            cepat
          </Typography>

          <Box mt={4} className={classes.btnHero}>
            <Button
              variant="outlined"
              className={classes.btnSales2}
              onClick={() => navigate("/contact")}
            >
              Hubungi Sales Kami
            </Button>
            <Box mx={0.8} />
            <Button
              variant="outlined"
              className={classes.btnFree}
              onClick={handleTryFree}
            >
              Coba Gratis Sekarang
            </Button>
          </Box>
        </Box>
      </Box>

      <ForMarketing
        img={marketing1}
        title="Bagaimana Tim Marketing memanfaatkan Performate untuk output maksimal"
        benefits={[
          "Performate.ID Membantu Tim Marketing Untuk Mengetahui Strategi Mana Yang Efektif",
          "Performate.ID Membantu Tim Marketing Untuk Menganalisa Aktivitas Mana Yang Memberikan Hasil Konversi Tertinggi",
          "Performate.ID Membantu Tim Marketing Untuk Mendokumentasikan Setiap Strategi Yang Pernah Dijalankan",
        ]}
      />
      {/* <TheySaid /> */}

      <Box
        style={{
          backgroundImage: "url(" + bgTouch + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Container>
          <TouchUs />
        </Container>
      </Box>

      <FloatingWhatsapp />

      <Footer />
    </div>
  );
};

export default ViewBusinessSolution;
