import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";

import { easeQuadInOut } from "d3-ease";

import { green, red, yellow } from "@material-ui/core/colors";
import AnimatedProgressProvider from "../../../../components/AnimatedProgressProvider";

import "react-circular-progressbar/dist/styles.css";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const numeral = require("numeral");

const CircleGoalDetailInPercentMATemporary = (props) => {
  const { goalDetailState } = props;

  if (goalDetailState !== null && goalDetailState !== undefined) {
    if (goalDetailState.id !== null) {
      // // console.log("MA Detail state : ", goalDetailState)
      // // console.log("MA target_value : ", goalDetailState.target_value);
      // console.log(
      //   "MA expected_result_value : ",
      //   goalDetailState.expected_result_value
      // );

      return (
        <Fragment>
          {/* 
                        ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                        # SCENARIO-00
                        - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" SAMA DENGAN NOL (0) ==> merah
                            
                        ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                    */}

          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 ===
            0 && (
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={
                goalDetailState.calculatedValue.resultOriented
                  .overall_result_achievement
              }
              duration={3}
              easingFunction={easeQuadInOut}
              // repeat
            >
              {(value) => {
                return (
                  <CircularProgressbar
                    value={100}
                    text={
                      Number(goalDetailState.expected_result_value) === 0 &&
                      Number(goalDetailState.target_value) > 0
                        ? "N/A"
                        : `${FormatDecimal(value)}%`
                      // : `${numeral(value).format("0.00")}%`
                    }
                    /* This is important to include, because if you're fully managing theanimation yourself, you'll want to disable the CSS animation. */
                    styles={buildStyles({
                      // pathTransitionDuration: 0.5,
                      // pathColor: '#2ef062', //*GREEN
                      pathColor: "#bbb",
                      // trailColor:
                      //   goalDetailState.has_measured_activity_result === true &&
                      //   red[400],
                      textColor: "white",
                      // strokeLinecap: 'butt',
                      pathTransition: "none",
                      textSize:
                        goalDetailState.has_measured_activity_result === false
                          ? 12
                          : 14,
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}
          {/* 
                        ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                        # SCENARIO-01
                        - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" KURANG DARI dari 85% ==> merah
                            
                        ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                    */}

          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 >
            0 &&
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement *
              100 <
              85 && (
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={
                  goalDetailState.calculatedValue.resultOriented
                    .overall_result_achievement * 100
                }
                duration={3}
                easingFunction={easeQuadInOut}
                // repeat
              >
                {(value) => {
                  return (
                    <CircularProgressbar
                      value={value}
                      text={
                        Number(goalDetailState.expected_result_value) === 0 &&
                        Number(goalDetailState.target_value) > 0
                          ? "N/A"
                          : `${FormatDecimal(value)}%`
                        // : `${numeral(value).format("0.00")}%`
                      }
                      /* This is important to include, because if you're fully managing theanimation yourself, you'll want to disable the CSS animation. */
                      styles={buildStyles({
                        // pathTransitionDuration: 0.5,
                        // pathColor: '#2ef062', //*GREEN
                        pathColor: red[800],
                        // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                        textColor: "white",
                        // strokeLinecap: 'butt',
                        pathTransition: "none",
                        textSize:
                          goalDetailState.has_measured_activity_result === false
                            ? 12
                            : 14,
                      })}
                    />
                  );
                }}
              </AnimatedProgressProvider>
            )}

          {/* 

                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                    # SCENARIO-02
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " >= 85% 

                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" < 100% 
                    
                         ==> YELLOWuntuk trail "OVERALL_ACHIEVEMENT"-nya

                         
                        
                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                */}

          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 >=
            85 &&
            //   goalDetailState.calculatedValue.overall_result_achievement * 100 < 99.5 && (

            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement *
              100 <
              100 && (
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={
                  goalDetailState.calculatedValue.resultOriented
                    .overall_result_achievement * 100
                }
                duration={3}
                easingFunction={easeQuadInOut}
                // repeat
              >
                {(value) => {
                  return (
                    <CircularProgressbar
                      value={value}
                      text={
                        Number(goalDetailState.expected_result_value) === 0 &&
                        Number(goalDetailState.target_value) > 0
                          ? "N/A"
                          : `${FormatDecimal(value)}%`
                        // : `${numeral(value).format("0.00")}%`
                      }
                      styles={buildStyles({
                        pathColor: yellow[400],
                        trailColor: "grey", //*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                        textColor: "white",
                        // strokeLinecap: 'butt',
                        pathTransition: "none",
                        textSize:
                          goalDetailState.has_measured_activity_result === false
                            ? 12
                            : 14,
                      })}
                    />
                  );
                }}
              </AnimatedProgressProvider>
            )}

          {/* 
                    `````````````````````````````````````````````````````````
                    # SCENARIO-03
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 100% 
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 200% 
                
                         ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

                         ==> GREEN untuk 100

                    ```````````````````````````````````````````````````````````
                */}
          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 !==
            200 &&
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement *
              100 >=
              100 &&
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement *
              100 <=
              200 && (
              <AnimatedProgressProvider
                valueStart={0}
                // valueEnd={numeral(goalDetailState.calculatedValue.overall_result_achievement * 100).format('0.00')}
                valueEnd={
                  goalDetailState.calculatedValue.resultOriented
                    .overall_result_achievement
                }
                duration={3}
                easingFunction={easeQuadInOut}
                // repeat
              >
                {(value) => {
                  return (
                    <CircularProgressbarWithChildren
                      value={100}
                      styles={buildStyles({
                        pathColor: green[500],
                        trailColor: "grey", //* Kalau di list goal pakai *#eee
                      })}
                    >
                      <CircularProgressbar
                        value={value * 100 - 100}
                        text={
                          Number(goalDetailState.expected_result_value) === 0 &&
                          Number(goalDetailState.target_value) > 0
                            ? "N/A"
                            : `${FormatDecimal(value * 100)}%`
                          // : `${numeral(value * 100).format("0.00")}%`
                        }
                        styles={buildStyles({
                          pathColor: green[800],
                          trailColor: "transparent", //*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                          textColor: "white",
                          pathTransition: "none",
                          textSize:
                            goalDetailState.has_measured_activity_result ===
                            false
                              ? 12
                              : 14,
                        })}
                      />
                    </CircularProgressbarWithChildren>
                  );
                }}
              </AnimatedProgressProvider>
            )}

          {/* 
                    ```````````````````````````````````````````````````````````
                    # SCENARIO-04
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " >= 200
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 999
                
                         ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

                    ```````````````````````````````````````````````````````````
                */}

          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 >=
            200 &&
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement *
              100 <
              999 && (
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={
                  goalDetailState.calculatedValue.resultOriented
                    .overall_result_achievement * 100
                }
                duration={3}
                easingFunction={easeQuadInOut}
                // repeat
              >
                {(value) => {
                  return (
                    <CircularProgressbar
                      value={value}
                      // text={`${TruncateNumber(numeral(value * 100).format('0.00'))}%`}//*Khusus yang ini ga pake numeral-an
                      text={
                        Number(goalDetailState.expected_result_value) === 0 &&
                        Number(goalDetailState.target_value) > 0
                          ? "N/A"
                          : `${FormatDecimal(value)}%`
                        // : `${numeral(value).format("0.00")}%`
                      }
                      styles={buildStyles({
                        pathColor: green[800], //lightGreen['A400'],
                        textColor: "white",
                        trailColor: "grey", //* Kalau di list goal pakai *#eee
                        pathTransition: "none",
                        // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                        // strokeLinecap: 'butt',
                        textSize:
                          goalDetailState.has_measured_activity_result === false
                            ? 12
                            : 14,
                      })}
                    />
                  );
                }}
              </AnimatedProgressProvider>
            )}
          {/* 
                    ```````````````````````````````````````````````````````````
                    # SCENARIO-04
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " >= 999
                
                         ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

                    ```````````````````````````````````````````````````````````
                */}

          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 >=
            999 && (
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={999}
              duration={3}
              easingFunction={easeQuadInOut}
              // repeat
            >
              {(value) => {
                return (
                  <CircularProgressbar
                    value={value}
                    // text={`${TruncateNumber(numeral(value * 100).format('0.00'))}%`}//*Khusus yang ini ga pake numeral-an
                    text={
                      Number(goalDetailState.expected_result_value) === 0 &&
                      Number(goalDetailState.target_value) > 0
                        ? "N/A"
                        : `> ${FormatDecimal(value)}%`
                      // : `> ${numeral(value).format("0.00")}%`
                    }
                    styles={buildStyles({
                      pathColor: green[800], //lightGreen['A400'],
                      textColor: "white",
                      trailColor: "grey", //* Kalau di list goal pakai *#eee
                      pathTransition: "none",
                      // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                      // strokeLinecap: 'butt',
                      textSize:
                        goalDetailState.has_measured_activity_result === false
                          ? 12
                          : 14,
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}

          {/* 
                    ```````````````````````````````````````````````````````````
                    # SCENARIO-06
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 0
                
                         ==> MERAH untuk trail "OVERALL_ACHIEVEMENT"-nya

                         ==> counterClockwise === true 

                         ==> Value "overall_achievement"-nya bernilai MINUS

                    ```````````````````````````````````````````````````````````
                */}

          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 <
            0 &&
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement *
              100 >=
              -999 && (
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={
                  goalDetailState.calculatedValue.resultOriented
                    .overall_result_achievement *
                    100 -
                  goalDetailState.calculatedValue.resultOriented
                    .overall_result_achievement *
                    100 *
                    2
                }
                duration={3}
                easingFunction={easeQuadInOut}
                // repeat
              >
                {(value) => {
                  return (
                    <CircularProgressbar
                      value={value}
                      text={
                        Number(goalDetailState.expected_result_value) === 0 &&
                        Number(goalDetailState.target_value) > 0
                          ? "N/A"
                          : `${FormatDecimal(
                              goalDetailState.calculatedValue.resultOriented
                                .overall_result_achievement * 100
                            )}%`
                        // : `${numeral(
                        //   goalDetailState.calculatedValue.resultOriented
                        //     .overall_result_achievement * 100
                        // ).format("0.00")}%`
                      }
                      counterClockwise={true}
                      styles={buildStyles({
                        // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                        pathColor: red[800],
                        textColor: "white",
                        pathTransition: "none",
                        // strokeLinecap: 'butt',
                        textSize:
                          goalDetailState.has_measured_activity_result === false
                            ? 12
                            : 14,
                      })}
                    />
                  );
                }}
              </AnimatedProgressProvider>
            )}

          {/* 
                    ```````````````````````````````````````````````````````````
                    # SCENARIO-06
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 0
                
                         ==> MERAH untuk trail "OVERALL_ACHIEVEMENT"-nya

                         ==> counterClockwise === true 

                         ==> Value "overall_achievement"-nya bernilai MINUS

                    ```````````````````````````````````````````````````````````
                */}

          {goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 <
            -1000 && (
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={-999}
              duration={3}
              easingFunction={easeQuadInOut}
              // repeat
            >
              {(value) => {
                return (
                  <CircularProgressbar
                    value={100}
                    text={
                      Number(goalDetailState.expected_result_value) === 0 &&
                      Number(goalDetailState.target_value) > 0
                        ? "N/A"
                        : `${FormatDecimal(
                            goalDetailState.calculatedValue.resultOriented
                              .overall_result_achievement * 100
                          )}%`
                      // : `${numeral(
                      //   goalDetailState.calculatedValue.resultOriented
                      //     .overall_result_achievement * 100
                      // ).format("0.00")
                    }
                    counterClockwise={true}
                    styles={buildStyles({
                      // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                      pathColor: red[800],
                      textColor: "white",
                      pathTransition: "none",
                      // strokeLinecap: 'butt',
                      textSize:
                        goalDetailState.has_measured_activity_result === false
                          ? 12
                          : 14,
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}
        </Fragment>
      );
    } else {
      return (
        <Grid container alignItems="center" justify="center">
          <CircularProgress size={20} styles={{ color: "red" }} />
        </Grid>
      );
    }
  }
};

export default CircleGoalDetailInPercentMATemporary;
