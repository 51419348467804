import React from "react";
import { LinearProgress, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { easeQuadInOut } from "d3-ease";
import numeral from "numeral";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import AnimatedProgressProvider from "../../../../components/AnimatedProgressProvider";

const ProjectBar = ({ percentage, gap, text, bold, light, dash, bg }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    progressLabel: {
      position: "absolute",
      // width: "100%",
      height: "100%",
      zIndex: 100,
      maxHeight: 15, // borderlinearprogress root.height
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 40,
      "& span": {
        width: "100%",
        fontSize: 11,
        color: "#555",
      },
    },

    progress: {
      height: 15,
      borderRadius: 5,
      width: "100%",
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: light,
        borderRight: `4px solid ${dash}`,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: bold,
        borderRight: `4px solid ${bg}`,
      },
      "& .MuiLinearProgress-dashedColorPrimary": {
        backgroundImage: "radial-gradient(#ddd 0%, #ddd 0%, #ddd 0%)",
        backgroundColor: "#ddd",
      },
      "&.MuiLinearProgress-buffer": {
        animation: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <span style={{ color: "#555", fontSize: 12 }}>
          {text === "N/A"
            ? "N/A"
            : isNaN(text)
            ? "0,00%"
            : `${FormatDecimal(text * 100)}%`}
        </span>
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={percentage * 100}
          duration={3}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const newVal = numeral(value).format("00");

            return (
              <LinearProgress
                className={classes.progress}
                variant="buffer"
                value={newVal > 100 ? 100 : newVal}
                valueBuffer={gap * 100}
              />
            );
          }}
        </AnimatedProgressProvider>
      </Box>
    </div>
  );
};

export default ProjectBar;
