import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
} from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  LinearProgress,
  Table,
  Tabs,
  Tab,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import queryString from 'query-string';

import axiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';
import ContextGoalDetail from '../../Goal/Context/ContextGoalDetail';

import PictEmptyStateGoalResult from '../ComponentsGoalResult/PictEmptyStateGoalResult';

import { STATUS_COMPLETED } from '../../../../constants/config-status-goal';

import DialogExportGoalResult from './DialogExportGoalResult';
import DialogAddResult from './DialogAddResult';

import DialogError from '../../../../components/DialogError';
import ListItemGoalResult from './ListItemGoalResult';
import ListHeaderGoalResult from './ListHeaderGoalResult';
import ListActivityLog from './ListActivityLog';
import ListItemTitleResult from './ListItemTitleResult';
// import MyLang from '../../../../utilities/MyLang';
// import { bahasa, english } from '../../../../constants/config-lang';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 70,
      width: '100%',
      backgroundColor: '#282560',
    },
  },
})((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

const ListCollectionGoalResult = (props) => {
  const {
    classes,
    collectionGoalResultList,
    goalResultLength,
    goalDetailState,
    userToken,
    onChangePage,
    onChangeRowsPerPage,
    onToggleGroup,
    loader,
    page,
    rowsPerPage,
    setFireGoalDetail,
  } = props;

  const context = useContext(ContextGoalDetail);
  const urlParams = window.location.search; // GET Query String
  const query = queryString.parse(urlParams); // Parse Query String Into Object(s)
  // const locationPathGoalId = window.location.pathname.split("/")[2]; // GET Goal ID
  const statusUserLogin = localStorage.getItem('status_user_login');
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === 'superadmin'
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  // // console.log("Context List Collection Goal Result : ", context)

  const [isStatusCompleted, setStatusCompleted] = useState(false);
  const [policy, setPolicy] = useState([]);
  // GR STATE
  const [openDialogAddResult, setOpenDialogAddResult] =
    useState(false);
  const [queryState, setQueryState] = useState({
    result: '',
    desc: '',
    open: '',
  });

  const [valueTab, setValueTab] = useState(0);

  // toggle result group
  const [toggleGroup, setToggleGroup] = useState(false);
  const [toggleNoData, setToggleNoData] = useState(true);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  // const [collectionGoalResultList, setCollectionGoalResultList] = useState([]);

  useEffect(() => {
    if (goalDetailState.status.code === STATUS_COMPLETED) {
      setStatusCompleted(true);
    }

    setQueryState({
      result: query.result,
      desc: query.desc,
      open: query.open,
    });

    if (query.open === '1') {
      setOpenDialogAddResult(true);
    }

    setPolicy(goalDetailState.policy);
  }, [goalDetailState]);

  /*
        ```````````````
        HANDLE COLLAPSE

        ```````````````
    */
  const [openCollapse, setOpenCollapse] = useState(true);

  function handleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  /*
        ```````````````````````````
        HANDLE EXPORT GOAL RESULT

        ```````````````````````````
    */
  const [isModalExportGoalResult, setModalExportGoalResult] =
    useState(false);

  // Handle status code 403 from create Goal Reesult For Disableing Crate Result
  const [disableCreateResult, setDisableCreateResult] =
    useState(false);

  useEffect(() => {
    if (goalDetailState.id !== null) {
      axiosConfig
        .get(
          `${URL_API}/swd/goal/${goalDetailState.id}/result/create`
        )
        .then((response) => {
          // // console.log("Response create GR", response);
        })
        .catch((error) => {
          // console.log("Error : ", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setDisableCreateResult(true);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage('Whoops, something went wrong !');
          }
        });
    }
  }, []);

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
  };

  const handleToggleGroup = (e) => {
    const toggle = e.target.checked;

    onToggleGroup(toggle);
    setToggleGroup(toggle);
  };

  // const handleToggleNoData = (e) => {
  //   setToggleNoData(e.target.checked);
  // };

  // // console.log("GOL DET ID", goalDetailState.id);

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
=============================================================================================== */

  let editAllowed = false;
  let deleteAllowed = false;

  policy.forEach((item) => {
    if (item.actionName === 'update') {
      editAllowed = item.result;
    } else if (item.actionName === 'delete') {
      deleteAllowed = item.result;
    }
  });

  // FLAT Goal Result Lists
  let COLLECTION_GROUP_RESULT = [];

  const collectionWithNoGroup = collectionGoalResultList
    .map((el) => {
      if (el.hasOwnProperty('results')) {
        return el.results;
      } else {
        return [];
      }
    })
    .flat();

  const collectionNoData = collectionGoalResultList.filter((el) => {
    return el.results?.length > 0;
  });

  if (toggleNoData) {
    COLLECTION_GROUP_RESULT = collectionNoData;
  } else {
    COLLECTION_GROUP_RESULT = collectionGoalResultList;
  }

  // if main goal, then result is empty
  if (goalDetailState?.is_main_goal) {
    COLLECTION_GROUP_RESULT = [];
  }

  // console.log('RESULT LIST', collectionGoalResultList);
  // // console.log("RESULT shrink", collectionWithNoGroup);
  // // console.log("RESULT collectionNoData", collectionNoData);
  // // console.log("RESULT COLLECTION_GROUP_RESULT", COLLECTION_GROUP_RESULT);
  // // console.log("goalDetailState", goalDetailState);
  // console.log("is_main_goal", goalDetailState.is_main_goal);

  return (
    <Fragment>
      <Box style={{ textAlign: 'right' }}>
        {context.listCollectionSubGoal.length > 0 && (
          <Button
            variant="outlined"
            className={classes.buttonOutlined}
            size="small"
            style={{
              borderColor: 'transparent',
              textTransform: 'capitalize',
              marginTop: theme.spacing(4),
            }}
            endIcon={
              context.modeRupiahOn === true ? (
                <CloseIcon style={{ color: 'grey' }} />
              ) : (
                <Avatar
                  variant="rounded"
                  style={{
                    width: theme.spacing(3),
                    height: theme.spacing(3),
                  }}
                >
                  <Typography
                    variant="caption"
                    className={classes.title}
                    style={{ color: 'white' }}
                  >
                    Rp
                  </Typography>
                </Avatar>
              )
            }
            onClick={
              context.modeRupiahOn === false
                ? () => context.toggleModeRupiahOn(true)
                : () => context.toggleModeRupiahOn(false)
            }
          >
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: 'grey' }}
            >
              {context.modeRupiahOn === true
                ? 'Kembali ke semula'
                : 'Aktifkan mode'}
            </Typography>
          </Button>
        )}
      </Box>

      <br />

      <Paper className={classes.paperColumnDuaRowFirst}>
        {loader === true && (
          <div className={classes.rootLinearProgress}>
            <LinearProgress style={{ height: 2 }} />
          </div>
        )}

        <Box display="flex" justifyContent="space-between" mb={2}>
          <StyledTabs value={valueTab} onChange={handleChangeTab}>
            <Tab
              classes={{ root: classes.widthTab }}
              label={
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{
                    marginBottom: -10,
                    textTransform: 'capitalize',
                  }}
                >
                  <b>Latest Goal Result</b>
                </Typography>
              }
            />
            <Tab
              classes={{ root: classes.widthTab }}
              label={
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{
                    marginBottom: -10,
                    textTransform: 'capitalize',
                  }}
                >
                  <b>Activity Log</b>
                </Typography>
              }
            />
          </StyledTabs>

          <Box display="flex" marginTop={theme.spacing(0.1)}>
            <Box ml={2}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={toggleGroup}
                    onChange={handleToggleGroup}
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    className={classes.title555}
                  >
                    Group Cycle
                  </Typography>
                }
              />
            </Box>

            {/* {toggleGroup && (
              <Box ml={2}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={toggleNoData}
                      onChange={handleToggleNoData}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                    >
                      Hide Result No Data
                    </Typography>
                  }
                />
              </Box>
            )} */}

            <Box ml={2}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: 'grey' }}
              >
                |
              </Typography>
            </Box>

            <Box ml={2}>
              <Button
                onClick={() => setModalExportGoalResult(true)}
                variant="outlined"
                className={classes.buttonOutlined}
                disabled={COLLECTION_GROUP_RESULT.length === 0}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: 'grey' }}
                >
                  Export
                </Typography>
              </Button>
            </Box>

            <Box ml={2}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: 'grey' }}
              >
                |
              </Typography>
            </Box>

            <Box ml={2}>
              {goalDetailState.goal_category === 'OPERATIONAL' && (
                <Button
                  variant="contained"
                  className={classes.button}
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenDialogAddResult(true)}
                  disabled={
                    isStatusCompleted === true
                      ? true
                      : goalDetailState.status_id === '2'
                      ? true
                      : context.listCollectionSubGoal.length > 0
                      ? true
                      : disableCreateResult
                  }
                >
                  Add Result
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        {valueTab === 0 && (
          <Table size="small" aria-label="a dense table">
            {collectionGoalResultList.length === 0 &&
              goalDetailState.status_id !== '4' && (
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                  >
                    <PictEmptyStateGoalResult />
                    Tidak ada Sub Goal yang dapat di tampilkan saat
                    ini ;(
                  </Typography>
                </Box>
              )}

            {collectionGoalResultList.length > 0 && (
              <ListHeaderGoalResult
                cycle={toggleGroup ? 'Cycle' : 'Date & Time'}
              />
            )}

            {toggleGroup &&
              goalDetailState?.status_id !== '2' &&
              collectionGoalResultList.length > 0 &&
              collectionGoalResultList.map((item, idx) => {
                if (item.hasOwnProperty('results')) {
                  return (
                    <ListItemTitleResult
                      key={idx}
                      item={item}
                      classes={classes}
                      goalDetailState={goalDetailState}
                      setFireGoalDetail={setFireGoalDetail}
                      userToken={userToken}
                    />
                  );
                } else {
                  return (
                    <ListItemGoalResult
                      key={idx}
                      item={item}
                      classes={classes}
                      currentCyle={true}
                      goalDetailState={goalDetailState}
                      setFireGoalDetail={setFireGoalDetail}
                      userToken={userToken}
                    />
                  );
                }
              })}

            {!toggleGroup &&
              goalDetailState?.status_id !== '2' &&
              collectionGoalResultList.length > 0 &&
              collectionGoalResultList.map((item, idx) => {
                return (
                  <ListItemGoalResult
                    key={idx}
                    item={item}
                    classes={classes}
                    currentCyle={true}
                    goalDetailState={goalDetailState}
                    setFireGoalDetail={setFireGoalDetail}
                    userToken={userToken}
                  />
                );
              })}

            {/* {goalDetailState?.status_id !== "2" &&
              COLLECTION_GROUP_RESULT.length > 0 &&
              COLLECTION_GROUP_RESULT.map((item, idx) => {
                return (
                  <ListItemGoalResult
                    key={idx}
                    item={item}
                    classes={classes}
                    goalDetailState={goalDetailState}
                    setFireGoalDetail={setFireGoalDetail}
                    userToken={userToken}
                  />
                );
              })} */}
          </Table>
        )}

        {valueTab === 0 && collectionGoalResultList.length > 0 && (
          <Box marginTop={theme.spacing(0.3)}>
            <Pagination
              count={goalResultLength}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={onChangePage}
            />
          </Box>
        )}

        {valueTab === 1 && <ListActivityLog classes={classes} />}
      </Paper>

      <DialogExportGoalResult
        isModalExportGoalResult={isModalExportGoalResult}
        setModalExportGoalResult={setModalExportGoalResult}
        goalDetailState={goalDetailState}
      />

      <DialogAddResult
        classes={classes}
        openDialogAddResult={openDialogAddResult}
        setOpenDialogAddResult={setOpenDialogAddResult}
        goalDetailState={goalDetailState}
        collectionGoalResultList={collectionGoalResultList}
        setCollectionGoalResultList={
          context.setCollectionGoalResultList
        }
        setFireGoalDetail={setFireGoalDetail}
        query={queryState}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default ListCollectionGoalResult;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cc0707', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff',
    },
  },
});
