export const globalBahasa = {
  // Button Text
  btnClose: "Tutup",
  btnCancel: "Batalkan",
  btnApply: "Terapkan",
  btnOk: "OK",
  btnSave: "Simpan",
  btnCreate: "Buat",
  btnUpload: "Unggah",
  btnCreating: "Membuat...",
  btnSaving: "Menyimpan...",
  btnUploading: "Mengunggah...",
  btnApplying: "Menerapkan...",
  btnUpdate: "Perbarui",
  btnUpdating: "Memperbarui...",

  // TERM
  add: "Tambah",
  period: "Periode",
  create: "Buat",
  edit: "Ubah",
  export: "Expor",
  search: "Cari",
  achievement: "Pencapaian",
  filter: "Saring",
  sort: "Urutkan",
  employee: "Karyawan",
  user: "Pengguna",
  setting: "Pengaturan",
  top: "Teratas",
  achieved: "Tercapai",
  warning: "peringatan",
  notAchieved: "TIdak tercapai",
  overallAchievement: "Pencapaian keseluruhan",
  generalSetting: "Pengaturan Umum",
  totalRecords: "Total tercatat",
  perPage: "Setiap baris",
  average: "Rata-rata",
  or: "atau",
  and: "dan",
  lastUpdate: "Update Terakhir",
  update: "Update",
  extend: "Perpanjang",
  duration: "Durasi",
  price: "Harga",
  bussines: "Bisnis",
  use: "Pakai",
  payment: "Pembayaran",
  discount: "Diskon",
  pay: "Bayar",
  choose: "Pilih",
  adjusment: "Penyesuaian",
  additional: "Tambahan",
  pending: "Tertunda",
  name: "Nama",
  role: "Peran",
  delete: "Hapus",
  upgrade: "Meningkatkan",
  available: "Tersedia",
  company: "Perusahaan",
  loading: "Memuat",
  noData: "Tidak ada Data",
  overall: "Keseluruhan",
  progress: "Kemajuan",
  created: "Dibuat",
  perform: "Performa",
  delimiter: "Pembatas",
  semicolon: "Titik koma",
  comma: "Koma",
  floating: "Mengambang",
  dot: "Titik",
  archive: "Arsip",
  setAsComplete: "Atur Selesai",
  marked: "Ditandai",
  duplicate: "Duplikasi",
  actual: "Aktual",
  default: "Bawaan",
  result: "Hasil",
  cumulative: "Kumulatif",
  discrete: "Diskrit",
  chart: "Bagan",
  range: "Jarak",
  direct: "Langsung",
  link: "Tautan",
  clear: "Hapus",
  stop: "Berhenti",
  lock: "Kunci",
  unlock: "Buka kunci",
  next: "Lanjut",

  // Date
  hour: "Jam",
  day: "Hari",
  week: "Minggu",
  month: "Bulan",
  year: "Tahun",
  hourly: "Per jam",
  daily: "Harian",
  weekly: "Mingguan",
  monthly: "Bulanan",
  yearly: "Tahunan",

  // dasboard
  notHavePeriod: "Belum memiliki Periode",
  companyProgress: "Kemjuan Perusahaan",
  youHaveReached: "Anda telah mencapai",
  fromAllGoal: "Dari keseluruhan goal anda",
  fromYourTarget: "Dari Target anda",
  noAchievements: "Tidak ada pencapaian",
  youDontHave: "Anda tidak memliki",

  // ma / goal
  startVal: "Nilai Awal",
  lastVal: "Nilai AKhir",
  targetVal: "Nilai Target",
  actualVal: "Nilai Terkini",
  resultVal: "Nilai Hasil",
  targetRangeVal: "Nilai Jarak Akhir",
  startDate: "Tanggal Mulai",
  endDate: "Tanggal Akhir",
  dueDate: "Tenggang Waktu",
  description: "Deskripsi",
  achievementType: "Tipe Pencapaian",
  valueType: "Tipe Nilai",
  calcMethod: "Metode Kalkulasi",
  targetType: "Tipe Target",
  owner: "Pemilik",
  status: "Status",
  approved: "Disetujui",
  active: "Aktif",
  overdue: "Terlambat",
  completed: "Selesai",
  eveidenceRequired: "Wajib lampirkan bukti",
  ora: "Pencapaian Hasil Keseluruhan",
  timeFrame: "Jangka Waktu",
  setDataZero: "Atur tidak ada data sebagai 0",
  searchOwner: "Cari pemilik",
  initialVal: "Berapa nilai awal anda?",
  finalScore: "Berapa nilai akhir anda?",
  placeholderDesc: "Masukan deskripsi Goal anda",
  chooseDate: "Pilih Tanggal",
  noPeriod: "Tidak ada period",
  noOwner: "Tidak ada owner",
  searchPeriod: "Cari period",
  placeholderTitle: "e.g Omset naik, Buka cabang...",
  setWeight: "Atur bobot",
  addWeightGoal: "Tambah Bobot Goal untuk",
  tooltipWeightGoal:
    "Maksimal nilai keseluruhan weight pada Goal adalah 100% . Setiap ada perubahan Goal maka akan diwajibkan untuk menyesuaikan kembali nilai weight.",
  currentWeight: "Bobot saat ini",
  noDisplayedGoal: "Tidak ada Goal yang dapat di tampilkan saat ini ;(",
  noDisplayedMA: "Tidak ada MA yang dapat di tampilkan saat ini ;(",
  overviewHistory: "Ringkasan Riwayat",
  gapToTV: "Kesenjangan Nilai Target",
  displayAllData: "Tampilkan Semua Data",
  showDate: "Tampilkan Tanggal",
  nextUpdate: "Perubahan Selanjutnya",
  evidence: "Bukti",
  groupResult: "Kelompokan Hasil",
  addResult: "Tambah Hasil",
  latestGoalResult: "Hasil Goal Terbaru",
  activityLog: "Catatan Aktifitas",
  goalResult: "Hasil Goal",
  explanation: "Keterangan",
  subGoals: "Sub Goal",
  noSubGoalDisplayed: "Tidak ada Sub Goal yang dapat di tampilkan saat ini ;(",
  directLink: "Tautan Langsung",
  dateAndTime: "Tanggal & Waktu",
  updateFreq: "Pembaruan Frekuensi",
  fromTotal: "dari total",
  row: "Baris",
  more: "Lebih",
  less: "Kurang",
  sum: "Penambahan",
  numeric: "Angka",
  increment: "Kenaikan",
  percentage: "Persentase",
  final: "Terakhir",
  structureUnit: "Unit Struktur",
  gap: "Celah",
  expectedVal: "Nilai Ekspektasi",
  remainingTime: "Sisa Waktu",
  overview: "Ringkasan",
  removeFrom: "Hapus dari",
  missMatchSubGoal: "Tidak cocok dengan Sub-Goal",
  toProject: "Ke Project",
  backToOri: "Kembali ke semula",
  activateMode: "Aktifkan mode",
  indirect: "Tak langsung",
  moreDetail: "Detil lebih",
  addEvidence: "Tambah Bukti",
  uploadEvidene: "Unggah Bukti",
  dragDropCopas: "Seret 'dan' Jatuhkan file ke sini, atau CTRL + V",
  fileDriveSelected: "File Drive dipilih",
  pasteLink: "Tempel tautan nya disini",
  voiceRecorder: "Perekam Suara",
  record: "Rekam",
  placeholderRecord: "Masukan judul audio",
  plainDocument: "Dokumen Kosong",
  listEvidence: "Baris bukti",
  addGoalResult: "Tambah Hasil Goal",
  insertValNum: "Masukan nilai angka",
  comment: "Komentar",
  placeholderAddComment: "Tambah komentar...",
  chooseDateTime: "Pilih tanggal & waktu",
  fileManager: "Pengelola File",
  chooseOwner: "Pilih Owner",
  therIsNoUser: "Belum ada User saat ini",
  createDirectSubGoal: "",
  hideResultNoData: "Sembunyikan hasil tanpa data",

  // More TERM
  showMore: "Tampilkan lebih banyak",
  showLess: "Tampilkan lebih sedikit",
  advencedOption: "Tampilkan Opsi Lanjutan",
  viewMore: "Lihat lebih banyak",

  // TASK
  priority: "Prioritas",
  HIGH: "TINGGI",
  MEDIUM: "MENENGAH",
  LOW: "RENDAH",

  // GENERAL SETTING
  descGeneralSetting:
    "Kelola konfiguras umum Goal Anda, pencapaian, periode, dan batas status",
  accSetting: "Pengaturan Akun",
  notifSetting: "Pengaturan Notifikasi",
  descNotif:
    "Kelola notifikasi Anda, sehingga sistem dapat memberi tahu Anda kapan saja",
  descAccSetting:
    "Kelola akun perusahaan, pengguna, dan langganan keanggotaan Anda",
  language: "Bahasa Indonesia",
  titleLang: "Bahasa",
  descLang: "Pilih bahasa yang ingin digunakan",
  descAchievement:
    "Informasi pencapaian ini akan ditampilkan berdasarkan pengaturan Anda",
  titleConfirmSetting: "Terapkan Pengaturan",
  descConfirmSetting:
    "Update Maks Pencapaian dan Min Pencapaian dari Goal/MA akan mengubah semua nilai achivement Goal/MA sesuai dengan nilai yang di set disini. Apakah anda yakin?",
  alertConfirmSetting: "Pengaturan baru berhasil diterapkan :)",
  min: "Min",
  max: "Maks",
  helperTextMin: "Nilai harus lebih kecil dari Maks nilai",
  helperTextMax: "Nilai harus lebih besar dari Min nilai",
  enable: "Diaktifkan",
  defaultPeriod: "Periode Bawaan",
  descDefaultPeriod: "Pilih periode untuk ditetapkan sebagai bawaan",
  overallPerform: "Performa Keseluruhan",
  descOverallPerform:
    "Tetapkan bobot Goal atau MA Anda. Nilai tertinggi harus lebih diperhatikan",
  factor: "Faktor",

  // ACCOUNT SETTING
  password: "Kata Sandi",
  profile: "Profil",
  changePwd: "Ubah Sandi",
  descChangePwd:
    "Disarankan untuk tidak tidak terlalu sering mengubah Password",
  changePhoto: "Ubah Foto",
  personal: "Pribadi",
  organization: "Organisasi",
  companyProfile: "Profil Perusahaan",
  membership: "Keanggotaan",
  // mpany profile
  companyName: "Nama Perusahaan",
  companyLogo: "Logo Perusahaan",
  totalEmployee: "Jumlah Karyawan",
  location: "Lokasi",
  address: "Alamat Lengkap",
  visionMission: "Visi & Misi",
  activateEditMode: "Aktifkan Mode Ubah",
  updateLocation: "Perbarui Lokasi",
  descLocation: "Anda belum memperbaharui lokasi alamat perusahaan Anda.",
  // membership
  manageMembership: "Kelola Keanggotaan",
  descMembership1: "Paket membership anda saat ini adalah",
  descMembership2: "dengan durasi maks",
  descMembership3: "Lisensi pengguna",
  deploySession: "Sesi Penyebaran (Seminar & Workshop)",
  detailpackage: "Detil Paket",
  detailDeploy: "Detil Penyebaran",
  userLicense: "Lisensi user",
  deployment: "Penyebaran",
  totalActiveTime: "Total masa aktif",
  runningTime: "Durasi berjalan",
  consultantVisit: "Kunjungan konsultan",
  remainingDuration: "Sisa durasi",
  extendMembership: "Perpanjang Keanggotaan",
  descExtend1: "Masa aktif akun anda akan habis pada",
  descExtend2: "Perpanjang membership untuk tetap berlangganan",
  updateMembership: "Perbarui Keanggotaan",
  descUpdateMember:
    "Ubah pilihan berlangganan anda dengan Upgrade & downgrade paket membership",
  cancelMembership: "Batalkan Keanggotaan",
  checkOrder: "Periksa pesanan anda",
  descCheckOrder:
    "Harap periksa kembali pesanan anda, pastikan anda memilih durasi membership sesuai dengan kebutuhan anda",
  addedDuration: "Durasi yang Ditambah",
  paymentMethod: "Metode Pembayaran",
  vaCheck: "Akun Virtual (Di cek otomatis)",
  totalPrice: "Total Harga",
  additionalDuration: "Tambahan durasi",
  totalPayment: "Total Pembayaran",
  choosePackage: "Pilih Paket",
  buyDeployTicket: "Beli Tiket Penyebaran",
  updateMembershipForm: "Perbarui Formulir Keanggotaan",
  descUpdateMemberForm1: "Paket membership terakhir anda adalah",
  descUpdateMemberForm2: "dengan siklus durasi",
  packageChanges: "Perubahan paket",
  totalLicense: "Total lisensi",
  changeOfCounsultantVisit: "Perubahan consultant visit",
  totalConsultantVisit: "Total kunjungan konsultan",
  changeDuration: "Perubahan durasi",
  updateFee: "Biaya Update",
  totalUpdateFee: "Total Biaya Update",
  // USer Setting
  userSetting: "Pengaturan User",
  inviteUser: "Undang Pengguna",
  jobTitle: "Judul Pekerjaan",
  descUserSetting1: "Anda telah menggunakan",
  descUserSetting2: "pengguna dari",
  //   Notofocation Setting
  createNotifSetting: "Buat Pengaturan Notifikasi",
  moduls: "Modul",
  entity: "Entitas",
  event: "Peristiwa",
  notifyTo: "Beritahu ke",
  recipients: "Penerima",
};
