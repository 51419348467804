import React, { useContext, useState, useEffect } from "react";
import {
  MuiThemeProvider,
  Grid,
  Box,
  Button,
  Breadcrumbs,
} from "@material-ui/core";

// ICONS
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { makeStyles } from "@material-ui/core/styles";
import { A, navigate } from "hookrouter";

import { theme, styles } from "./style/styleDrive";
import ContextGlobalDrive from "./context/ContextDrive";

import GridView from "./View/GridView/";
import ListView from "./View/ListView/";
import MenuSection from "./View/MenuSection";

import axiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import Snackbeer from "../../components/Snackbeer";
import SidebarSection from "./View/SidebarSection";

const useStyles = makeStyles(styles);

function ViewDrive() {
  const classes = useStyles();
  const context = useContext(ContextGlobalDrive);
  const currentLoc = window.location.pathname;

  // Super Admin  => role
  const user_login = localStorage.getItem("status_user_login");
  const superAdmin = JSON.parse(user_login);
  const role = superAdmin.userRole[0].name;

  // Directory Id at Local Storage
  const directory_id = localStorage.getItem("directory_id");
  let directoryId = directory_id ? directory_id.split(",") : [];

  /* ====================================================
    LOCAL STATE
  ==================================================== */

  const [pathLoc, setPathLoc] = useState("");

  /* ====================================================
    LifeCycle Hook
  ==================================================== */

  // GET Id Folder from pahtname
  useEffect(() => {
    const pathArray = currentLoc.split("/");
    const checkFolderId = pathArray[pathArray.length - 1];

    if (checkFolderId !== undefined && checkFolderId !== "") {
      setPathLoc(checkFolderId);
    }
  }, [currentLoc]);

  // ================= GET List Folders =================
  useEffect(() => {
    context.setListFolders([]);

    if (role === "superadmin") {
      context.setLoadFolders(true);

      axiosConfig
        .get(
          `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
            currentLoc === "/drive" ? "root" : pathLoc
          }&options[embedded][]=policy&options[embedded][]=owner`
        )
        .then((response) => {
          // console.log("List Folder", response);
          if (response.status === 200) {
            const formatedFolders = response.data.data
              .map((item) => {
                return {
                  ...item,
                  size: Number(item.size),
                };
              })
              .filter(
                (fldr) =>
                  fldr.status_id === "2caec8df-bc0c-4463-9887-9f9af6236bc9" // 33984fe3-3018-43a0-8d8b-5a64d23c4643 (restored)
              );

            if (
              pathLoc !== "gallery" ||
              pathLoc !== "recyclebin" ||
              pathLoc !== "recovered"
            ) {
              context.setListFolders(formatedFolders);
            } else {
              context.setListFolders([]);
            }
          }
          context.setLoadFolders(false);
        })
        .catch((error) => {
          context.setLoadFolders(false);
          // console.log("Error", error);
        });
    } else {
      context.setLoadFolders(true);

      axiosConfig
        .get(
          `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
            currentLoc === "/drive" ? "" : pathLoc
          }&options[embedded][]=policy&options[embedded][]=owner`
        )
        .then((response) => {
          // console.log("List Folder", response);

          if (response.status === 200) {
            if (currentLoc === "/drive") {
              const partedFolders = response.data.data
                .map((folder) => {
                  // Filter Check if id & folder_parent_id is equal
                  const filtering = response.data.data.filter(
                    (item) => item.id === folder.folder_parent_id
                  );

                  // Check if parent_id doesn't match with any id
                  if (filtering.length === 0) {
                    return { ...folder, folder_parent_id: null };
                  }
                })
                .filter((item) => item !== undefined) // if item undefined, then remove it!
                .map((item) => {
                  // separate the dir_id & dir_name
                  const splitDirId = item.directory_id.split("/");
                  const splitDirName = item.directory_name.split("/");

                  // Get the last id & the last name
                  const getLastDirId = splitDirId[splitDirId.length - 1];
                  const getLastDirName = splitDirName[splitDirName.length - 1];

                  return {
                    ...item,
                    size: Number(item.size),
                    directory_id: "/" + getLastDirId,
                    directory_name: "/" + getLastDirName,
                  };
                })
                .filter(
                  (fldr) =>
                    fldr.status_id === "2caec8df-bc0c-4463-9887-9f9af6236bc9" // 33984fe3-3018-43a0-8d8b-5a64d23c4643 (restored)
                );

              // // console.log("FORMATEDDDD", partedFolders);
              if (
                pathLoc !== "gallery" ||
                pathLoc !== "recyclebin" ||
                pathLoc !== "recovered"
              ) {
                context.setListFolders(partedFolders);
              } else {
                context.setListFolders([]);
              }
            } else {
              const formatedFolders = response.data.data.map((item) => {
                return {
                  ...item,
                  size: Number(item.size),
                };
              });

              if (pathLoc !== "gallery") {
                context.setListFolders(formatedFolders);
              } else {
                context.setListFolders([]);
              }
            }
          }
          context.setLoadFolders(false);
        })
        .catch((error) => {
          context.setLoadFolders(false);
          // console.log("Error", error);
        });
    }
  }, [context.successTriggerFolder, pathLoc]);

  // ================= GET List Files =================
  useEffect(() => {
    context.setListFiles([]);

    if (role === "superadmin") {
      context.setLoadFiles(true);

      axiosConfig
        .get(
          `${URL_API}/drive/file?options[filter][folder_id]=${
            currentLoc === "/drive" ? "root" : pathLoc
          }&options[embedded][]=owner&options[embedded][]=goalResultEvidence&options[embedded][]=measuredActivityResultEvidence`
        )
        .then((response) => {
          // console.log("List Files", response);

          if (response.status === 200) {
            const formatedFiles = response.data.data
              .map((item) => {
                return {
                  ...item,
                  size: Number(item.size),
                };
              })
              .filter(
                (file) =>
                  file.status_id === "a9821d66-e047-4a11-8c0e-beb316a01d87" // cbb1d326-8643-40ca-a808-07ffacba0422 (restored)
              );

            if (
              pathLoc !== "gallery" ||
              pathLoc !== "recyclebin" ||
              pathLoc !== "recovered"
            ) {
              context.setListFiles(formatedFiles);
            } else {
              context.setListFiles([]);
            }
          }
          context.setLoadFiles(false);
        })
        .catch((error) => {
          context.setLoadFiles(false);
          // console.log("Error", error);
        });
    } else {
      context.setLoadFiles(true);

      axiosConfig
        .get(
          `${URL_API}/drive/file?options[filter][folder_id]=${
            currentLoc === "/drive" ? "" : pathLoc
          }&options[embedded][]=owner&options[embedded][]=goalResultEvidence&options[embedded][]=measuredActivityResultEvidence`
        )
        .then((response) => {
          // console.log("List Files", response);

          if (response.status === 200) {
            // GET Folder Collection
            axiosConfig
              .get(
                `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
                  currentLoc === "/drive" ? "" : pathLoc
                }`
              )
              .then((result) => {
                if (result.status === 200) {
                  if (currentLoc === "/drive") {
                    const partedFiles = response.data.data
                      .map((file) => {
                        // Filter Check if id & folder_id is equal
                        const filtering = result.data.data.filter(
                          (item) => item.id === file.folder_id
                        );

                        // Check if folder_id doesn't match with any id of folders
                        if (filtering.length === 0) {
                          return { ...file, size: Number(file.size) };
                        }
                      })
                      .filter(
                        (file) =>
                          file.status_id ===
                          "a9821d66-e047-4a11-8c0e-beb316a01d87" // cbb1d326-8643-40ca-a808-07ffacba0422 (restored)
                      )
                      .filter((item) => item !== undefined); // if item undefined, then remove it!

                    if (
                      pathLoc !== "gallery" ||
                      pathLoc !== "recyclebin" ||
                      pathLoc !== "recovered"
                    ) {
                      context.setListFiles(partedFiles);
                    } else {
                      context.setListFiles([]);
                    }
                  } else {
                    const formatedFiles = response.data.data.map((item) => {
                      return {
                        ...item,
                        size: Number(item.size),
                      };
                    });

                    if (pathLoc !== "gallery") {
                      context.setListFiles(formatedFiles);
                    } else {
                      context.setListFiles([]);
                    }
                  }
                }
              });
          }
          context.setLoadFiles(false);
        })
        .catch((error) => {
          context.setLoadFiles(false);
          // console.log("Error", error);
        });
    }
  }, [context.successTriggerFile, pathLoc]);

  // ================= GET User For Gallery =================
  useEffect(() => {
    context.setLoadGalleryGR(true);

    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split(",") : [];

    axiosConfig
      .get(
        `${URL_API}/drive/gallery/goal-result-evidence/options?options[filter][status_id][]=${
          context.statusActive ? 3 : 0
        }&options[filter][status_id][]=${
          context.statusOverdue ? 6 : 0
        }&options[filter][status_id][]=${
          context.statusCompleted ? 4 : 0
        }&options[filter][status_id][]=${
          context.statusArchived ? 5 : 0
        }&options[filter][owner_id]=${breadcrumb.length === 2 && pathLoc}`
      )
      .then((response) => {
        // console.log("Owner/Goal glry", response);
        const result = response.data.data;

        if (response.status === 200) {
          if (pathLoc === "gallery") {
            context.setListGalleryGR(result.goalOwnerCollections);
          }

          if (breadcrumb.length === 2) {
            context.setListGoalGallery(result.goalCollections);
          }
        }
        context.setLoadGalleryGR(false);
      })
      .catch((error) => {
        context.setLoadGalleryGR(false);
        // console.log("Error", error);
      });
  }, [
    pathLoc,
    context.statusActive,
    context.statusOverdue,
    context.statusCompleted,
    context.statusArchived,
  ]);

  // ================= Gallery GR =================
  useEffect(() => {
    context.setLoadGalleryGR(true);

    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split(",") : [];

    let directory_id = localStorage.getItem("directory_id");
    let dir_id = directory_id ? directory_id.split(",") : [];

    axiosConfig
      .get(
        `${URL_API}/drive/gallery/goal-result-evidence?options[embedded][]=goal&options[embedded][]=owner&options[filter][goal_owner_id]=${dir_id[1]}`
      )
      .then((response) => {
        // console.log("Response Gallery GR", response);

        const result = response.data.data;

        if (response.status === 200) {
          if (breadcrumb.length === 3) {
            let dir_goal = dir_id[2];

            const filterByGoalId = result.filter(
              (item) => item.goal_id === dir_goal
            );

            context.setListGalleryEvidence(filterByGoalId);
          }
        }
        context.setLoadGalleryGR(false);
      })
      .catch((error) => {
        context.setLoadGalleryGR(false);
        // console.log("Error", error);
      });
  }, [pathLoc]);

  // ================= Gallery MAR =================
  useEffect(() => {
    context.setLoadGalleryMAR(true);

    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split(",") : [];

    let directory_id = localStorage.getItem("directory_id");
    let dir_id = directory_id ? directory_id.split(",") : [];

    axiosConfig
      .get(
        `${URL_API}/drive/gallery/measured-activity-result-evidence?options[embedded][]=owner&options[embedded][]=measuredActivity&options[embedded][]=owner`
      )
      .then((response) => {
        // console.log("Response Gallery MAR", response);

        const result = response.data.data;

        if (response.status === 200) {
          if (breadcrumb.length === 3) {
            let dir_goal = dir_id[2];

            const filterByGoalId = result.filter(
              (item) => item.goal_id === dir_goal
            );

            // For Foler MA Gallery List
            context.setListGalleryMAR(filterByGoalId);
          }

          if (breadcrumb.length === 4) {
            let dir_ma = dir_id[3];

            const filterByMAId = result.filter(
              (item) => item.measured_activity_id === dir_ma
            );

            context.setListGalleryEvidenceMAR(filterByMAId);
          }
        }
        context.setLoadGalleryMAR(false);
      })
      .catch((error) => {
        context.setLoadGalleryMAR(false);
        // console.log("Error", error);
      });
  }, [pathLoc]);

  // ================= Recycle Bin Folder =================
  useEffect(() => {
    context.setLoadRecycleBinFolder(true);

    axiosConfig
      .get(
        `${URL_API}/drive/recycle-bin/folder?options[embedded][]=folderOwner&options[embedded][]=executedBy`
      )
      .then((response) => {
        // console.log("Folder Recycle Bin", response);
        const result = response.data.data;

        if (response.status === 200) {
          if (pathLoc === "recyclebin") {
            context.setListRecycleBinFolder(result);
          }
        }

        context.setLoadRecycleBinFolder(false);
      })
      .catch((error) => {
        context.setLoadRecycleBinFolder(false);
        // console.log("Error :", error.response);
      });
  }, [pathLoc, context.successTriggerFolderRB]);

  // ================= Recycle Bin File =================
  useEffect(() => {
    context.setLoadRecycleBinFile(true);

    axiosConfig
      .get(
        `${URL_API}/drive/recycle-bin/file?options[embedded][]=fileOwner&options[embedded][]=executedBy`
      )
      .then((response) => {
        // console.log("File Recycle Bin", response);
        const result = response.data.data;

        if (response.status === 200) {
          if (pathLoc === "recyclebin") {
            context.setListRecycleBinFile(result);
          }
        }

        context.setLoadRecycleBinFile(false);
      })
      .catch((error) => {
        context.setLoadRecycleBinFile(false);
        // console.log("Error :", error.response);
      });
  }, [pathLoc, context.successTriggerFileRB]);

  // ================= Recovered Folders =================
  useEffect(() => {
    context.setLoadRecoveredFolder(true);

    axiosConfig
      .get(
        `${URL_API}/drive/folder?options[embedded][0]=owner&options[embedded][]=status`
      )
      .then((response) => {
        // // console.log("Folder Recovered", response);
        const result = response.data.data;

        if (response.status === 200) {
          const filterStatus = result.filter(
            (item) => item.status_id === "33984fe3-3018-43a0-8d8b-5a64d23c4643"
          );

          context.setFolderRestored(filterStatus);

          if (pathLoc === "recovered") {
            context.setListRecoveredFolder(filterStatus);
          }
        }

        context.setLoadRecoveredFolder(false);
      })
      .catch((error) => {
        context.setLoadRecoveredFolder(false);
        // console.log("Error :", error.response);
      });
  }, [
    pathLoc,
    context.successTriggerRecoveredFolder,
    context.successTriggerFolder,
  ]);

  // ================= Recovered Files =================
  useEffect(() => {
    context.setLoadRecoveredFile(true);

    axiosConfig
      .get(
        `${URL_API}/drive/file?options[embedded][0]=owner&options[embedded][]=status`
      )
      .then((response) => {
        // console.log("File Recoveres", response);
        const result = response.data.data;

        if (response.status === 200) {
          const filterStatus = result.filter(
            (item) => item.status_id === "cbb1d326-8643-40ca-a808-07ffacba0422"
          );

          context.setFileRestored(filterStatus);

          if (pathLoc === "recovered") {
            context.setListRecoveredFile(filterStatus);
          }
        }

        context.setLoadRecoveredFile(false);
      })
      .catch((error) => {
        context.setLoadRecoveredFile(false);
        // console.log("Error :", error.response);
      });
  }, [
    pathLoc,
    context.successTriggerRecoveredFile,
    context.successTriggerFile,
  ]);

  /* ====================================================
    LOCAL Functions / Methods
  ==================================================== */

  const handleBreadcrumbs = (data) => {
    context.setSelectedFolder([]); // Empty Selected Folder
    context.setSelectedFile([]); // Empty Selected File

    // // console.log("data breadcrumb", data);

    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split(",") : [];
    let slicedBC = breadcrumb.slice(0, data + 1);

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split(",") : [];
    let slicedDirId = directory_id.slice(0, data + 1);

    // // console.log("breadcrumb >", breadcrumb);
    localStorage.setItem("breadcrumb", slicedBC.toString());
    localStorage.setItem("directory_id", slicedDirId.toString());
  };

  let breadcrumb = localStorage.getItem("breadcrumb");
  breadcrumb = breadcrumb ? breadcrumb.split(",") : [];

  const handleBackButton = () => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    let brcumb = breadcrumb ? breadcrumb.split(",") : [];

    let directory_id = localStorage.getItem("directory_id");
    let dir_id = directory_id ? directory_id.split(",") : [];

    if (dir_id.length > 1) {
      // const selectedId = listFolderId[listFolderId.length - 2];
      const selectedId = dir_id[dir_id.length - 2];

      // console.log("Sel ID", selectedId);
      navigate(`/drive/${selectedId}`);
      // listFolderId.pop()

      brcumb.pop();
      dir_id.pop();
      localStorage.setItem("breadcrumb", brcumb.toString());
      localStorage.setItem("directory_id", dir_id.toString());
    } else {
      navigate("/drive");
      localStorage.removeItem("breadcrumb");
      localStorage.removeItem("directory_id");

      // // console.log("No Sel", noSelected);
    }
  };

  // // console.log("Folder ID", context.folderId);
  // // console.log("FIXED FOLDER", context.listFolders);

  return (
    <MuiThemeProvider theme={theme}>
      <Box className={classes.root}>
        <Box className={classes.layoutMarginLeftAndTop}>
          {/* Back Navigation */}
          <Grid container>
            <Grid item md={12} className={classes.backSection}>
              <Button
                className={classes.backButton}
                onClick={handleBackButton}
                startIcon={<NavigateBeforeIcon style={{ color: "#888" }} />}
              >
                Kembail
              </Button>
            </Grid>
          </Grid>

          {/* ====================================================
              Breadcrumbs Section
           ==================================================== */}
          <Box className={classes.driveContainer}>
            <Box className={classes.breadcrumbSection}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <A
                  className={classes.breadcrumbText}
                  href="/drive"
                  style={{ color: context.folderTitle.length === 0 && "#333" }}
                  // onClick={() => context.setFolderTitle([])}
                  onClick={() => {
                    localStorage.removeItem("breadcrumb");
                    localStorage.removeItem("directory_id");
                  }}
                >
                  My Drive
                </A>
                {breadcrumb.length > 0 &&
                  breadcrumb.map((item, idx) => {
                    const colorText = breadcrumb.length - 1 === idx && "#333";
                    let directory_id = localStorage.getItem("directory_id");
                    let dir_id = directory_id ? directory_id.split(",") : [];

                    return (
                      <A
                        key={idx}
                        className={classes.breadcrumbText}
                        style={{
                          color: colorText,
                        }}
                        href={`/drive/${dir_id[idx]}`}
                        onClick={() => handleBreadcrumbs(idx)}
                      >
                        {item}
                      </A>
                    );
                  })}
              </Breadcrumbs>
            </Box>

            {/* ====================================================
              Menu Section
             ==================================================== */}
            <Box className={classes.menuSection}>
              <MenuSection classes={classes} />
            </Box>

            {/* ====================================================
              Folder Section 
             ==================================================== */}
            <Box>
              <Grid container>
                {/* Folder & File */}
                <Grid item lg={10}>
                  {context.switchToListView && (
                    <GridView
                      theme={theme}
                      classes={classes}
                      listFolders={context.listFolders}
                      listFiles={context.listFiles}
                      listGalleryGR={context.listGalleryGR}
                      listGoalGallery={context.listGoalGallery}
                      listGalleryEvidence={context.listGalleryEvidence}
                      listMAGallery={context.listGalleryMAR}
                      listMAGalleryEvidence={context.listGalleryEvidenceMAR}
                      listFolderRecycleBin={context.listRecycleBinFolder}
                      listFileRecycleBin={context.listRecycleBinFile}
                      listRecoveredFolder={context.listRecoveredFolder}
                      listRecoveredFile={context.listRecoveredFile}
                    />
                  )}

                  {!context.switchToListView && (
                    <ListView
                      theme={theme}
                      classes={classes}
                      listFolders={context.listFolders}
                      listFiles={context.listFiles}
                      listGalleryGR={context.listGalleryGR}
                      listGoalGallery={context.listGoalGallery}
                      listGalleryEvidence={context.listGalleryEvidence}
                      listMAGallery={context.listGalleryMAR}
                      listMAGalleryEvidence={context.listGalleryEvidenceMAR}
                      listFolderRecycleBin={context.listRecycleBinFolder}
                      listFileRecycleBin={context.listRecycleBinFile}
                      listRecoveredFolder={context.listRecoveredFolder}
                      listRecoveredFile={context.listRecoveredFile}
                    />
                  )}
                </Grid>

                {/* Sidebar - Folder Info */}
                <Grid item lg={2} className={classes.sideBarDivider}>
                  <SidebarSection classes={classes} theme={theme} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Snackbeer
          setModalResponse200={context.setModalResponse200}
          isModalResponse200={context.isModalResponse200}
          messages={context.messages}
        />
      </Box>
    </MuiThemeProvider>
  );
}

export default ViewDrive;
