import React, { useState } from "react";
import { Box, IconButton, Menu } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import GridOnIcon from "@material-ui/icons/GridOn";
import ListItemConfig from "./ListItemConfig";
import ListItemSave from "./ListItemSave";

const ConfigReport = ({ classes }) => {
  const [anchorConfig, setAnchorConfig] = useState(null);
  const [anchorSave, setAnchorSave] = useState(null);

  const handleShowAnchorConfig = (event) => {
    setAnchorConfig(event.currentTarget);
  };

  const handleCloseAnchorConfig = () => {
    setAnchorConfig(null);
  };

  const handleShowAnchorSave = (event) => {
    setAnchorSave(event.currentTarget);
  };

  const handleCloseAnchorSave = () => {
    setAnchorSave(null);
  };

  return (
    <Box display="flex" gridGap={6}>
      <IconButton
        className={classes.btnConfigMini}
        size="small"
        onClick={handleShowAnchorConfig}>
        <SettingsIcon fontSize="small" style={{ color: "#8F8F8F" }} />
      </IconButton>
      {/* <IconButton
        className={classes.btnConfigMini}
        size="small"
        onClick={handleShowAnchorSave}>
        <GridOnIcon fontSize="small" style={{ color: "#8F8F8F" }} />
      </IconButton> */}

      <Menu
        id="simple-menu"
        anchorEl={anchorConfig}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorConfig)}
        onClose={handleCloseAnchorConfig}>
        <ListItemConfig classes={classes} />
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={anchorSave}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorSave)}
        onClose={handleCloseAnchorSave}>
        <ListItemSave classes={classes} />
      </Menu>
    </Box>
  );
};

export default ConfigReport;
