import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";

const DetailUsed = ({
  classes,
  txtClass,
  prop1,
  val1,
  prop2,
  val2,
  prop3,
  val3,
}) => (
  // <Box display="flex" justifyContent="space-between">
  <Grid container>
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Box display="flex">
        <Typography variant="subtitle2" className={txtClass}>
          {prop1}
        </Typography>
        <Typography variant="subtitle2" className={classes.txtUsed}>
          : {val1}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Box display="flex">
        <Typography variant="subtitle2" className={txtClass}>
          {prop2}
        </Typography>
        <Typography variant="subtitle2" className={classes.txtUsed}>
          : {val2}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Box display="flex">
        <Typography variant="subtitle2" className={txtClass}>
          {prop3}
        </Typography>
        <Typography variant="subtitle2" className={classes.txtUsed}>
          : {val3}
        </Typography>
      </Box>
    </Grid>
  </Grid>
  // </Box>
);

export default DetailUsed;
