import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import { SUBJECT_CATEGORY_ID_MEMBER_UNIT } from "../../../../constants/config-drive";
import ContextGlobalDrive from "../../context/ContextDrive";

import DialogError from "../../../../components/DialogError";

function DialogSetPermissionUnit2({
  classes,
  item,
  openDialogSetPermissionUnit2,
  setOpenDialogSetPermissionUnit2,
}) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
      LOCAL STATE
    ==================================================== */
  const [loading, setLoading] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [subjectCategoryCollections, setSubjectCategoryCollections] = useState(
    []
  );
  const [structurePositionCollections, setStructurePositionCollections] =
    useState([]);
  const [masterRoleCollections, setMasterRoleCollections] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [status403, setStatus403] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
    LifeCycle Hook
  ==================================================== */
  useEffect(() => {
    if (openDialogSetPermissionUnit2) {
      setLoading(true);

      axiosConfig
        .get(`${URL_API}/drive/folder/${item.id}/permission/create`)
        .then((response) => {
          // console.log("Permission Create", response);

          const result = response.data.data;

          if (response.status === 200) {
            setSubjectCategoryCollections(
              result.masterSubjectCategoryCollections.filter(
                (item) => item.id === SUBJECT_CATEGORY_ID_MEMBER_UNIT
              )
            );
            setMasterRoleCollections(result.masterRoleCollections);

            const units = [];
            result.structurePositionCollections.map((item) =>
              item.withAncestorUnit.map((unit) => {
                let unitFix = { ...unit, id: item.id };

                return units.push(unitFix);
              })
            );

            // // console.log("UNITS", units);
            setStructurePositionCollections(units);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);

          if (error.response.status) {
            setStatus403(true);
          }
        });
    }
  }, [openDialogSetPermissionUnit2]);

  /* ====================================================
    LOCAL Functions / Methods
  ==================================================== */
  // Choose Member Unit
  const handleChooseUnit = (e, unit) => {
    // // console.log("UNITO", unit);

    if (unit !== null) {
      setUnitId(unit.id);
    }
  };

  const handleSetPermission = () => {
    const data = {
      DriveFolderPermission: {
        subject_category_id: subjectCategoryCollections[0].id,
        role_id: masterRoleCollections[0].id,
        subject_id: unitId,
      },
    };

    setLoadingPermission(true);

    // // console.log("DATA SET PERM", data);

    axiosConfig
      .post(`${URL_API}/drive/folder/${item.id}/permission`, data)
      .then((response) => {
        // console.log("Response Set Persmission", response);

        if (response.status === 200) {
          context.setSuccessTriggerPermission(
            Math.floor(Math.random() * 1000 + 1)
          );

          setOpenDialogSetPermissionUnit2(false);
        }
        setLoadingPermission(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoadingPermission(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogSetPermissionUnit2}
      onClose={() => setOpenDialogSetPermissionUnit2(false)}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Set Permisson Member Unit</b>
        </Typography>
      </DialogTitle>

      <DialogContent
        style={{
          textAlign: "left",
          width: 350,
          marginBottom: 20,
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <Fragment>
          <Box textAlign="center" marginBottom={2}>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#333" }}
            >
              Pilih unit yang akan dijadikan <i>Member Unit</i> untuk folder{" "}
              <b>{item.name}</b>
            </Typography>
          </Box>

          <Box>
            <Autocomplete
              id="combo-box-demo"
              options={structurePositionCollections}
              getOptionLabel={(option) =>
                option.structure_unit_name !== null ||
                option.structure_unit_type_name !== null
                  ? `${option.structure_unit_type_name} - ${option.structure_unit_name}`
                  : "-"
              }
              className={classes.title}
              noOptionsText="No options"
              loading={loading}
              onChange={(e, unit) => handleChooseUnit(e, unit)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  style={{ fontSize: 12 }}
                  className={classes.title}
                  label="Search Unit"
                  variant="standard"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Fragment>
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: "center",
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        <Button
          variant="contained"
          className={classes.button}
          style={{ width: 200 }}
          disabled={status403}
          onClick={handleSetPermission}
        >
          {loadingPermission ? (
            <CircularProgress color="#fff" size={15} />
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogSetPermissionUnit2;
