const truncateTitle = (input) => {
  if (input?.length > 35) return input.substring(0, 35) + "...";
  else return input;
};

const truncateJob = (input) => {
  if (input?.length > 22) return input.substring(0, 22) + "...";
  else return input;
};

const truncateName = (input) => {
  if (input?.length > 23) return input.substring(0, 23) + "...";
  else return input;
};

export { truncateTitle, truncateJob, truncateName };
