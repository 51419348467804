import React, { Fragment } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  IconButton,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { navigate } from "hookrouter";

const FolderRecycleBin = ({ classes }) => {
  const folderRecycleBin = { name: "Recycle Bin", id: "recyclebin" };

  const handleNavigateRecycleBin = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    breadcrumb.push(data.name);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#e5fbfb" }}>
        <TableCell padding="checkbox" />
        <TableCell>
          <Box display="flex" alignItems="center">
            <Avatar
              style={{
                width: 34,
                height: 34,
                backgroundColor: "#6ed8fb",
              }}>
              <DeleteRoundedIcon style={{ fontSize: 22, color: "#3498db" }} />
            </Avatar>

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15 }}
              onClick={() => handleNavigateRecycleBin(folderRecycleBin)}>
              Recycle Bin
            </Typography>
          </Box>
        </TableCell>

        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />

        <TableCell align="right">
          <IconButton>
            <MoreVertIcon style={{ color: "transparent" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default FolderRecycleBin;
