import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

import { URL_API } from "../../../../constants/config-api";
import Snackbeer from "../../../../components/Snackbeer";

import DialogError from "../../../../components/DialogError";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";
import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";

const DialogSetAsComplete = (props) => {
  const {
    classes,
    isOpenDialogSetAsComplete,
    setOpenDialogSetAsComplete,
    maDetailState,
    setMeasuredActivityDetailShow,
  } = props;
  const context = useContext(ContextGoalDetail);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [userTokenState, setUserTokenState] = useState("");

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  useEffect(() => {
    if (isOpenDialogSetAsComplete == true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isOpenDialogSetAsComplete]);

  const [loader, setLoader] = useState(false);

  const handleSetAsComplete = () => {
    setLoader(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    if (userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .put(URL_API + `/swd/measured-activity/${maDetailState.id}:completed`)
        .then(function (response) {
          // console.log("Response Original : ", response);

          if (response.status === 200) {
            const dataLog = {
              user_activity_log: {
                module: "ma",
                event: "update",
                user_id: user?.user_id,
                user_email: user?.user_email,
                nama: `${user?.member_first_name} ${user?.member_last_name}`,
                position:
                  user?.member_position?.structure_position_title_name ===
                  undefined
                    ? ""
                    : user?.member_position?.structure_position_title_name,
                device_type: device_os,
                device_model: "",
                device_version: OSName,
                long: "",
                lat: "",
                entity_id: maDetailState?.id,
                entity_name: maDetailState?.name,
                remarks: "Status MA menjadi Completed 1",
                comment: "",
              },
            };

            setDataRemarks(dataLog);

            setModalResponse200(true);
            setMessage("Berhasil ubah status Goal menjadi complete");

            // setOpenDialogSetAsComplete(false);
            // setMeasuredActivityDetailShow(true);
            context.setTriggerMA(Math.floor(Math.random() * 1000 + 1));
            setOpenRemarksLog(true);
          }

          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          setOpenDialogSetAsComplete(false);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setErrorStatus(403);
              setTextErrorInformation(error.response.data.info.message);
              setOpenDialogError(true);
            }

            if (error.response.status === 422) {
              setErrorStatus(422);
              setTextErrorInformation(".");
              setListError(error.response.data.info.errors);
              setOpenDialogError(true);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation();
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
        ```````````````
        HANDLE SNACKBEER

        ```````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);
  const [message, setMessage] = useState("");

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogSetAsComplete}
        onClose={() => setOpenDialogSetAsComplete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}
        ></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}
            >
              <b>
                Apakah Anda yakin ingin mengubah status{" "}
                <i>"{maDetailState.name}"</i> menjadi <i>Completed </i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleSetAsComplete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>
      <Snackbeer
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={message}
      />

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenDialogSetAsComplete}
        onTriggerRemarks={context.setTriggerLogMa}
        editRemarks={true}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogSetAsComplete;
