import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Popover,
  Fab,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import StickyFooter from "react-sticky-footer";

import DialogCancelPembayaran from "./ComponentsMembershipStatus/DialogCancelPembayaran";

import Redirect from "../../../utilities/Redirect";
import { useGetHttp } from "../../../utilities-hook/useGetHttp";
import { URL_API } from "../../../constants/config-api";

import {
  ToMembershipStatusIncreaseQuotaSEMENTARA,
  ToMembershipStatusScenarioPENDING_SEMENTARA,
} from "../../../constants/config-redirect-url";

import bank_bca from "../../../assets/images/bank-bca.png";
// import bank_bni from '../../../../assets/images/bank-bni.png';
// import bank_bri from '../../../../assets/images/bank-bri.png';
// import bank_mandiri from '../../../../assets/images/bank-mandiri.png';

const numeral = require("numeral");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {},
});

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 2,
  },
  title: {
    color: "#5a5959",
    fontFamily: "Roboto",
  },
  successButton: {
    // INI HARUSNYA pendingButton
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(4),
    // backgroundColor: '#24d872',
    backgroundColor: "yellow",
    color: "grey",
    borderColor: "transparent",
  },
  successButtonDate: {
    marginLeft: theme.spacing(8),
    // marginTop: theme.spacing(4),
    // backgroundColor: '#24d872',
    color: "grey",
    fontFamily: "Roboto",
    // borderColor: 'transparent',
  },
  langgananKeanggotaan: {
    // marginLeft: theme.spacing(8),
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  langgananInvoice: {
    // marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    color: "grey",
  },
  nominal: {
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(4),
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  buttonLihat: {
    fontFamily: "Roboto",
    color: "grey",
    textTransform: "capitalize",
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  buttonCancel: {
    fontFamily: "Roboto",
    color: "grey",
    textTransform: "capitalize",
    marginTop: theme.spacing(4),
    // marginLeft: theme.spacing(1),
  },

  /*
        ```````````````````````````````
        DIALOG MODAL DETAIL PEMBAYARAN

        ```````````````````````````````
    */
  pendingChipInModalButton: {
    // backgroundColor: '#24d872',
    backgroundColor: "yellow",
    color: "grey",
    borderColor: "transparent",
  },

  titleModal: {
    fontFamily: "Roboto",
  },
  icon: {
    // marginTop: theme.spacing(2)
    // color: 'white',
    // position: 'absolute'
  },
  title: {
    fontFamily: "Roboto",
  },
  titleIconSave: {
    fontFamily: "Roboto",
    // color: 'white'
    marginLeft: theme.spacing(4),
  },
  buttonRounded: {
    borderRadius: 20,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
  },
  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
  },
});

const ViewPaymentAfterCountDown = (props) => {
  const { classes } = props;

  /*
        ````````````````````
        COMPONENT DID MOUNT 

        ```````````````````

    */

  const [loader, fetchedDataHistory, setFetchedDataHistory] = useGetHttp(
    URL_API +
      `/group/membership/payment/history?options[embedded][]=membershipPaymentTracking`,
    []
  );
  // console.log("fetchedDataHistory : ", fetchedDataHistory);

  let fetchedDataHistoryList = [];

  if (fetchedDataHistory !== null) {
    if (fetchedDataHistory.length > 0) {
      fetchedDataHistory.map((item) => {
        fetchedDataHistoryList.push(item);
      });
    }
  }

  /*
        ```````````````````````````````
        HANDLE DATA FROM LOCAL STORAGE 

        ```````````````````````````````
    */

  const [dataLocalStorage, setDataLocalStorage] = useState();

  useEffect(() => {
    // const invoice_number = localStorage.getItem('invoice_number');
    // const expired_date_payment = localStorage.getItem('expired_date_payment');
    // const nominal_after_discount = localStorage.getItem('nominal_after_discount');
    // // console.log("INVOICE : ", invoice_number);
    // const data = {
    //     invoice_number: invoice_number,
    //     expired_date_payment: expired_date_payment,
    //     nominal_after_discount : nominal_after_discount
    // };
    // setDataLocalStorage(data);
  }, []);

  /*
        ```````````````````````````````
        HANDLE DIALOG DETAIL PEMBAYARAN 

        ```````````````````````````````
    */

  const [isModalDetailPayment, setModalDetailPayment] = useState(false);

  const [groupName, setGroupName] = useState("");
  const [userTotal, setUserTotal] = useState("");
  const [jumlahMembershipYangDiBeli, setJumlahMembershipYangDiBeli] =
    useState("");
  const [hargaPerUser, setHargaPerUser] = useState("");

  const [totalPriceAfterUnique, setTotalPriceAfterUnique] = useState();
  const [paymentCategoryName, setPaymentCategoryName] = useState("");
  const [invoice, setInvoice] = useState("");
  const [tglPembelian, setTglPembelian] = useState("");

  const handleDetailModal = (data) => {
    setModalDetailPayment(true);
    // console.log("Detail data : ", data);

    setGroupName(data.group_name);
    setUserTotal(data.total_user);

    setJumlahMembershipYangDiBeli(data.membership_type_user_count);

    setTotalPriceAfterUnique(data.total_price_after_unique);
    setPaymentCategoryName(data.payment_category_name);

    setInvoice(data.invoice_number);
    setHargaPerUser(data.membership_type_price);
    setTglPembelian(data.created_at);
  };

  /*
        ```````````````````````````````
        HANDLE DIALOG CANCEL PEMBAYARAN

        ```````````````````````````````
    */

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [dataDetailCancel, setDataDetailCancel] = useState(null);

  const handleItemCancelPembayaran = (e, data) => {
    e.preventDefault();
    setOpenDialog(true);

    // console.log("data cancel pembayartan : ", data);
    setDataDetailCancel(data);
  };

  return (
    <MuiThemeProvider theme={theme}>
      {/* <Container maxWidth="sm"> */}
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              Pembayaran
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {fetchedDataHistoryList.length == 0 && (
        <Grid container>
          <Grid item sm={12} style={{ textAlign: "center" }}>
            <CircularProgress
              size={32}
              style={{ marginTop: 64, color: "#cc0707" }}
            />
          </Grid>
        </Grid>
      )}

      {fetchedDataHistoryList.length > 0 &&
        fetchedDataHistoryList.map((item, i) => (
          <Paper elevation={4} className={classes.root} key={i}>
            <Grid container>
              <Grid item xs={2}>
                <Chip
                  label={item.status_name}
                  className={classes.successButton}
                  variant="outlined"
                />

                <br />
                <br />
                <Typography
                  variant="subtitle1"
                  className={classes.successButtonDate}
                >
                  {moment(item.expiry_datetime).format("DD MMMM YYYY")}
                </Typography>
              </Grid>

              <Grid item xs={3} style={{ textAlign: "left" }}>
                <Typography
                  variant="h6"
                  className={classes.langgananKeanggotaan}
                >
                  {/* Upgrade Keanggotaan */}
                  {item.payment_category_name + " "}
                </Typography>
                <br />
                <Chip
                  label={
                    <Typography
                      variant="subtitle1"
                      className={classes.langgananInvoice}
                    >
                      INV/{item.invoice_number}
                    </Typography>
                  }
                  variant="outlined"
                  style={{ marginLeft: 8 }}
                />
              </Grid>

              <Grid item xs={3} style={{ textAlign: "left" }}>
                <Typography variant="h6" className={classes.nominal}>
                  Rp {numeral(item.total_price_after_unique).format("0,0")}
                </Typography>
              </Grid>

              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Button
                  // onClick={() => alert("API Cancel Upgrade still development !")}
                  onClick={(e) => handleItemCancelPembayaran(e, item)}
                  variant="outlined"
                  size="small"
                  className={classes.buttonCancel}
                >
                  Batal
                </Button>
                <Button
                  onClick={() => handleDetailModal(item)}
                  variant="contained"
                  size="small"
                  className={classes.buttonLihat}
                >
                  Lihat Detail
                </Button>
              </Grid>
            </Grid>
            <br />
            <br />
          </Paper>
        ))}

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}

      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={10}></Grid>

        <Grid item sm={2} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <StickyFooter bottomThreshold={50}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              style={{ margin: 14 }}
              onClick={() =>
                Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)
              }
            >
              Kembali
            </Button>
            <br />
            <br />
          </StickyFooter>
        </Grid>
        <Grid item sm={1}></Grid>
      </Grid>

      {/* 

                ``````````````````````````````
                DIALOG MODAL DETAIL PEMBAYARAN 

                ``````````````````````````````
                
            */}

      <Dialog
        open={isModalDetailPayment}
        onClose={() => setModalDetailPayment(false)}
        aria-labelledby="alert-dialog-title-payment"
        aria-describedby="alert-dialog-description-payment"
        maxWidth="sm"
        fullWidth={true}
        // fullScreen={true}
      >
        <DialogTitle
          id="alert-dialog-title-payment"
          style={{ textAlign: "center" }}
        >
          <Typography
            variant="h6"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>Detail Pembayaran</b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Grid container>
            <Grid item sm={6} style={{ textAlign: "left" }}>
              <Typography variant="subtitle1" className={classes.title}>
                Nama akun &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {groupName}
              </Typography>
              <Typography variant="subtitle1" className={classes.title}>
                Jumlah User &nbsp;&nbsp;&nbsp;&nbsp;: {userTotal}
              </Typography>
              <Typography variant="subtitle1" className={classes.title}>
                Harga
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                : Rp {numeral(totalPriceAfterUnique).format("0,0")}
                {/* Rp {dataLocalStorage !== undefined ? numeral(dataLocalStorage.nominal_after_discount).format('0,0') : ''} */}
              </Typography>

              <br />
              <br />
            </Grid>
            <Grid item sm={6}>
              <Typography variant="subtitle1" className={classes.title}>
                Status Pembayaran :{" "}
                <Chip
                  label="Pending"
                  className={classes.pendingChipInModalButton}
                  variant="outlined"
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={6} style={{ textAlign: "left" }}>
              {/* PEMBAYARAN :  */}
              <Typography variant="subtitle1" className={classes.title}>
                <b>Pembayaran &nbsp;&nbsp;&nbsp;: </b>
              </Typography>
              <Typography variant="subtitle1" className={classes.title}>
                &nbsp;&nbsp;&nbsp; {paymentCategoryName}
              </Typography>

              <Typography
                onClick={() => alert("Download PDF INVOICE !")}
                variant="subtitle1"
                className={classes.title}
                style={{ color: "#d13b4a", cursor: "pointer" }}
              >
                <b> &nbsp;&nbsp;&nbsp;INV/{invoice}</b>
              </Typography>
              <br />
              <br />
              <br />
              <Typography variant="subtitle1" className={classes.title}>
                <b>Rekening Tujuan : </b>
              </Typography>
              <br />
              <img
                src={bank_bca}
                alt="Bank BCA"
                className={classes.imageBank}
              />
              <Typography variant="subtitle2" className={classes.titleModal}>
                a/n Deny Darmawan <br />
                Pondok Kelapa, Jakarta Timur <br />
                <b>178 303 7878</b>
              </Typography>
            </Grid>

            <Grid item sm={6} style={{ textAlign: "left" }}>
              <br />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ marginTop: 5 }}
              >
                Tanggal Pembelian&nbsp;&nbsp;&nbsp;:{" "}
                {moment(tglPembelian).format("DD MMMM YYYY")}
              </Typography>
              <Typography variant="subtitle1" className={classes.title}>
                Jumlah
                User&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                {jumlahMembershipYangDiBeli}
              </Typography>
              <Typography variant="subtitle1" className={classes.title}>
                Harga per-<i>user</i>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: Rp{" "}
                {numeral(hargaPerUser).format("0,0")}
              </Typography>

              <br />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ marginTop: 14 }}
              >
                <b>Total harga yang harus dibayar &nbsp;&nbsp;&nbsp;: </b>
              </Typography>

              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "#d13b4a" }}
              >
                <b>Rp {numeral(totalPriceAfterUnique).format("0,0")}</b>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <DialogContentText
            id="alert-dialog-description-payment"
            style={{ textAlign: "center" }}
          >
            {/* still empty */}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={() => setModalDetailPayment(false)}
            // color="primary"
            size="medium"
            className={classes.buttonRounded}
            // fullWidth
          >
            {/* Lihat selengkapnya */}
            OK, mengerti !
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <DialogCancelPembayaran
        classes={classes}
        isOpenDialog={isOpenDialog}
        setOpenDialog={setOpenDialog}
        dataDetailCancel={dataDetailCancel}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewPaymentAfterCountDown);
