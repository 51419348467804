import React from "react";
import { Box, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PersonIcon from "@material-ui/icons/Person";

import { styles } from "../Style/StyleNewSO";

const useStyles = makeStyles(styles);

function AvatarIconWithoutLineInOrgChart(props) {
  const classes = useStyles();

  const { mainRoot } = props;

  return (
    // <Box marginLeft={32}>
    <Box
      className={
        mainRoot == "1" ? classes.horizontalLine : classes.horizontalWithoutLine
      }
      marginLeft={2}
      marginBottom={6}>
      <Avatar className={classes.small}>
        <PersonIcon style={{ fontSize: 17 }} />
      </Avatar>
    </Box>
  );
}

export default AvatarIconWithoutLineInOrgChart;
