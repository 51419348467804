import React, { useEffect, useState } from "react";
import { Paper, Box, Typography, Chip } from "@material-ui/core";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import SpeedIcon from "@material-ui/icons/Speed";

import numeral from "numeral";

import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";
import handleError from "../../../../../Report/components/global/handleError";

import CircularProgress from "../../../../../Report/components/report-type/summary-type/business-profile/CircularProgress";
import DialogError from "../../../../../../components/DialogError";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

const OverallProgress = ({
  classes,
  period,
  structureUnitTypeId,
  structureUnitTypeName,
  unitType,
  is_admin,
  is_full_access,
  is_superior,
  divisionData,
  period200,
  unit200,
  divisi200,
}) => {
  const [averageData, setAverageData] = useState(0);
  const [goalData, setGoalData] = useState({});
  const [maData, setMaData] = useState({});

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  let unit_type_id;
  let unit_type_name;
  const units = divisionData.length > 0 ? divisionData.map((el) => el.id) : [];
  const unitName =
    divisionData.length > 0
      ? divisionData.map((el) => el.structure_unit_name)[0]
      : "";

  if (is_admin || is_full_access) {
    unit_type_id = "";
    unit_type_name = "";
  }

  if (is_admin && !is_superior) {
    unit_type_id = "";
    unit_type_name = "";
  }

  if (is_superior && !is_admin && !is_full_access) {
    unit_type_id = "";
    unit_type_name = unitName;
  }

  if (!is_superior && !is_admin && !is_full_access) {
    unit_type_id = structureUnitTypeId;
    unit_type_name = structureUnitTypeName;
  }

  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const dataBar = {
    name: "Bar Chart",
    platform: "web",
    report_format: "summary",
    filter_by: {
      entity: [],
      date_range: "all_time",
      user: [],
      unit: is_admin || is_full_access ? [] : units,
      status: [3],
      period_id: period !== undefined ? period.id : "",
      achievement: [
        {
          field: "goal",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
        {
          field: "ma",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
      ],
    },
    entities: ["goal", "ma"],
    group_by:
      !is_admin && !is_full_access && !is_superior
        ? ["full_name"]
        : is_superior
        ? ["full_name"]
        : unit_type_id !== ""
        ? ["structure_unit_name"]
        : ["full_name"],
    group_by_calculation: "avg",
    order_by: [{ field: "full_name", method: "ASC", name: "User Name" }],
    primary_column: "overall_result_achievement",
    unit_type_id:
      !is_admin && !is_full_access && !is_superior
        ? ""
        : unit_type_id !== undefined || unit_type_id !== ""
        ? unit_type_id
        : "",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  useEffect(() => {
    if (period200 === 200 && unit200 === 200 && divisi200 === 200) {
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=1&per_page=8`,
        dataBar
      )
        .then((res) => {
          const result = res.data.data;
          const results = result.results !== null ? result.results : [];
          // console.log("RES SUMMARY ALL", result);

          const averages =
            results &&
            results.length > 0 &&
            results.reduce((acc, curr) => {
              return acc + curr.value;
            }, 0) / results.length;

          const goal =
            results.length > 0 && results.filter((el) => el.type === "goal")[0];
          const ma =
            results.length > 0 && results.filter((el) => el.type === "ma")[0];

          if (res.status === 200) {
            setAverageData(averages);
            setGoalData(goal);
            setMaData(ma);
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [period, unit_type_id]);

  let colorGoal;
  let colorMA;

  if (numeral(goalData.value).format("0.00") < 85) {
    colorGoal = "#e15367";
  } else if (
    numeral(goalData.value).format("0.00") >= 85 &&
    numeral(goalData.value).format("0.00") <= 100
  ) {
    colorGoal = "#fab445";
  } else if (numeral(maData.value).format("0.00") > 99) {
    colorGoal = "#34cc80";
  }

  if (numeral(maData.value).format("0.00") < 85) {
    colorMA = "#e15367";
  } else if (
    numeral(maData.value).format("0.00") >= 85 &&
    numeral(maData.value).format("0.00") <= 100
  ) {
    colorMA = "#fab445";
  } else if (numeral(maData.value).format("0.00") > 99) {
    colorMA = "#34cc80";
  }

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Typography
          className={classes.title333}
          variant="subtitle1"
          style={{ textAlign: "center" }}
        >
          <b>
            {MyLang() ? bahasa.progress : english.progress}{" "}
            {unit_type_name !== ""
              ? unit_type_name
              : MyLang()
              ? bahasa.company
              : english.company}
          </b>
        </Typography>

        <Box
          mt={4}
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ width: 120, height: 120 }}>
            <CircularProgress average={averageData} />
          </div>
        </Box>

        <Typography
          className={classes.title555}
          variant="subtitle2"
          style={{ textAlign: "center" }}
        >
          {MyLang() ? bahasa.youHaveReached : english.youHaveReached}{" "}
          <b>{numeral(averageData).format("0.00")}%</b>{" "}
          {MyLang() ? bahasa.fromAllGoal : english.fromAllGoal}
        </Typography>

        <Box
          mt={3}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Chip
              size="small"
              variant="outlined"
              label={`${numeral(goalData.value).format("0.00")}%`}
              icon={<TrackChangesIcon style={{ color: colorGoal }} />}
              style={{ marginRight: 5, fontWeight: "600", color: colorGoal }}
            />
            <Typography className={classes.title555} variant="caption">
              {MyLang() ? bahasa.average : english.average} Goal
            </Typography>
          </Box>
          <Box m={2} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Chip
              size="small"
              variant="outlined"
              label={`${numeral(maData.value).format("0.00")}%`}
              icon={<SpeedIcon style={{ color: colorMA }} />}
              style={{ marginRight: 5, fontWeight: "600", color: colorMA }}
            />
            <Typography className={classes.title555} variant="caption">
              {MyLang() ? bahasa.average : english.average} MA
            </Typography>
          </Box>
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default OverallProgress;
