import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState, useContext, Fragment } from 'react';
import ContextTimeOff from '../Context/ContextTimeOff';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/id';
import { URL_API } from '../../../../constants/config-api';

const DialogDetail = (props) => {
  const {
    classes,
    isModalDetail,
    setModalDetail,
    userLoginName,
    dataRequestTimeOff
  } = props;

  const context = useContext(ContextTimeOff);
  const statusUserLogin = localStorage.getItem('status_user_login');
  const userToken = localStorage.getItem('userToken');
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === 'superadmin' || el.name === 'admin'
  );
  const [loader, setLoader] = useState(false);

  return (
    <Fragment>
      <Dialog
        open={isModalDetail}
        onClose={() => setModalDetail(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        scroll="paper"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: '#fff',
            backgroundSize: 'cover',
            backgroundColor: '#282560'
          }}
        >
          <Typography variant="h4">Detail Time Off</Typography>
        </DialogTitle>
        <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Grid item xs={12} md={6}>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Nama Lengkap</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>
                      : {}
                      {dataRequestTimeOff.user && dataRequestTimeOff.user.member
                        ? dataRequestTimeOff.user.member.first_name +
                          ' ' +
                          dataRequestTimeOff.user.member.last_name
                        : ''}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Email</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>
                      : {}
                      {dataRequestTimeOff.user
                        ? dataRequestTimeOff.user.email
                        : ''}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Delegasi</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>
                      : {}
                      {dataRequestTimeOff.delegation_user &&
                      dataRequestTimeOff.delegation_user.member
                        ? dataRequestTimeOff.delegation_user.member.first_name +
                          ' ' +
                          dataRequestTimeOff.delegation_user.member.last_name
                        : ''}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Deskripsi</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b style={{ wordBreak: 'break-all' }}>
                      : {}
                      {dataRequestTimeOff.description}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Kategori Time Off</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>
                      : {}
                      {dataRequestTimeOff.leave_category
                        ? dataRequestTimeOff.leave_category.name
                        : ''}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Dari Tanggal</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>
                      : {}
                      {dataRequestTimeOff.schedule_in
                        ? moment(dataRequestTimeOff.schedule_in).format(
                            'DD-MM-YYYY'
                          )
                        : ''}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Sampai Tanggal</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>
                      : {}
                      {dataRequestTimeOff.schedule_out
                        ? moment(dataRequestTimeOff.schedule_out).format(
                            'DD-MM-YYYY'
                          )
                        : ''}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>Status</b>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    component={'span'}
                    className={classes.title}
                  >
                    <b>
                      : {}
                      {dataRequestTimeOff.status_approve_name
                        ? dataRequestTimeOff.status_approve_name
                        : ''}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            {dataRequestTimeOff.file && (
              <DialogContent style={{ textAlign: 'left' }}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography
                      variant="subtitle1"
                      component={'span'}
                      className={classes.title}
                    >
                      <b>Evidence</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span> : </span>
                    <a
                      href={encodeURI(
                        `${URL_API}${dataRequestTimeOff.file_url}?token=${userToken}`
                      )}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: 'rgb(215, 203, 203)',
                        borderRadius: '10px',
                        padding: '1px 10px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        marginLeft: '5px'
                      }}
                      title={dataRequestTimeOff.file}
                      target={'_blank'}
                    >
                      <InsertDriveFileIcon style={{ color: '#000000de' }} />
                      <Typography
                        variant="subtitle1"
                        component={'span'}
                        className={classes.title}
                        style={{
                          width: '150px',
                          marginLeft: '7px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          color: '#000000de'
                        }}
                      >
                        {dataRequestTimeOff.file}
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </DialogContent>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default DialogDetail;
