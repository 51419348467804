import React, { useEffect, useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Button,
  Checkbox,
} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";

import ListCardItemMeasureActivity from "../../../MeasureActivities/Components/ListCardItemMeasureActivityv2";
import ContextGoalDetail from "../../../Goal/Context/ContextGoalDetail";

import PictEmptyStateMA from "../../Components/PictEmptyStateMA";
import { STATUS_OVERDUE } from "../../../../../constants/config-status-goal";

import { styleSpecial } from "../../Style/StyleMaSPECIAL";
import DialogArchiveMultiple from "../../Components/DialogArchiveMultiple";
import ListCardHeaderItem from "../../../Goal/Components/ListCardHeaderItem";

const useStyles = makeStyles(styleSpecial);

const ListStatusMAOverdue = (props) => {
  const context = useContext(ContextGoalDetail);
  const classes = useStyles();
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  /*  
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOpenDialogArchiveMultiple, setOpenDialogArchiveMultiple] =
    useState(false);

  useEffect(() => {
    let finding = context.collectionMA.filter(function (element) {
      if (element.status.code == STATUS_OVERDUE) {
        return element;
      }
    });

    if (finding !== undefined) {
      if (finding !== null) {
        if (finding.length > 0) {
          if (finding[0] !== []) {
            setList(finding);
          }
        }
      }

      if (context.collectionMA.length == 0) {
        setList([]);
      }
    }
  }, [context.collectionMA]);

  /*  
        ``````````````````
        HANDLE GOAL DETAIL

        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    console.log(data);

    localStorage.setItem("MA_detail", JSON.stringify(data));
    context.setMeasuredActivityDetailShow(false);
    // Redirect(ToGoalDetail);
  };

  const handleOpenArchiveMultiple = () => {
    setOpenDialogArchiveMultiple(true);
  };

  // Length Of Collection Goal
  const itemCount = list.length;
  /* =========================
    selected ID
    ============================= */
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.id);
      setSelected(newSelecteds);
      // // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Fragment>
      {superior && selected.length > 0 && (
        <Box
          my={1}
          ml={1}
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            indeterminate={selected.length > 0 && selected.length < itemCount}
            checked={itemCount > 0 && selected.length === itemCount}
            onChange={handleSelectAllClick}
            size="small"
            checkedIcon={
              <CheckBoxRoundedIcon style={{ color: "#0F68D2", fontSize: 18 }} />
            }
            icon={
              <CheckBoxOutlineBlankRoundedIcon
                style={{ color: "#0F68D2", fontSize: 18 }}
              />
            }
          />
          <Typography
            color="inherit"
            variant="subtitle2"
            className={classes.title}
          >
            <b>{selected.length}</b> Measured Activity ditandai
          </Typography>
          {selected.length > 0 && (
            <Fragment>
              <Button
                onClick={handleOpenArchiveMultiple}
                disableElevation
                variant="contained"
                className={classes.button7}
                startIcon={<ArchiveIcon />}
                style={{ marginLeft: 24, paddingTop: 8, paddingBottom: 8 }}
              >
                <b>Archive</b>
              </Button>
            </Fragment>
          )}
        </Box>
      )}

      {context.loaderSpecialMa == true && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress
            size={20}
            style={{ marginRight: 10, color: "#d1354a" }}
          />
          <Typography className={classes.title555} variant="subtitle1">
            Loading...
          </Typography>
        </Box>
      )}

      {list.length == 0 && context.loaderSpecialMa == false && (
        <Paper elevation={1} className={classes.paperList}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey", marginTop: 16, marginBottom: 16 }}
              >
                <PictEmptyStateMA />
                <b>
                  Tidak ada <i>Overdue Measured Activity</i> yang dapat di
                  tampilkan saat ini ;(
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}

      {list.length > 0 && (
        <ListCardHeaderItem
          classes={classes}
          origin="Measured Activity"
          entity="1"
        />
      )}

      {list.length > 0 && <Box mt={2} />}

      {list.length > 0 &&
        list.map((item, i) => {
          return (
            <ListCardItemMeasureActivity
              key={i}
              classes={classes}
              item={item}
              handleDetail={handleDetail}
              userTokenState={context.userTokenStateGlobal}
              // lengthGoalCount = { lengthGoalCount }
              selected={selected}
              setSelected={setSelected}
              isSelected={isSelected}
            />
          );
        })}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <DialogArchiveMultiple
        classes={classes}
        isOpenDialogArchiveMultiple={isOpenDialogArchiveMultiple}
        setOpenDialogArchiveMultiple={setOpenDialogArchiveMultiple}
        selected={selected}
        list={list}
      />
    </Fragment>
  );
};

export default ListStatusMAOverdue;
