import React, { useContext, useEffect, useState, useRef } from "react";
import { styles } from "../Style/StyleTimeOff";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Input,
  Grid,
  InputAdornment,
  TextField,
  Card,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { BeachAccess } from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { id } from "date-fns/locale";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

import ContextTimeOff from "../Context/ContextTimeOff";

import { FileUploader } from "react-drag-drop-files";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CE354A", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const TimeOffRequestDialog = ({
  handleClose,
  open,
  verification,
  value,
  setValue,
  timeOffCategory,
  loader,
}) => {
  const classes = useStyles();
  const context = useContext(ContextTimeOff);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFileChange = (file) => {
    setValue({
      ...value,
      timeOffFile: file,
    });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          AJUKAN TIME OFF
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <InputLabel htmlFor="overtime-type">Jenis Time-off</InputLabel>
            <Select
              id="overtime-type"
              value={value.timeOffType}
              onChange={(e) =>
                setValue({
                  ...value,
                  timeOffType: e.target.value,
                })
              }
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <BeachAccess />
                </InputAdornment>
              }
            >
              <MenuItem value="">PILIH JENIS</MenuItem>
              {timeOffCategory.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={id}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="d MMMM yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Tanggal Mulai"
                  value={value.timeOffScheduleIn}
                  onChange={(date) =>
                    setValue({ ...value, timeOffScheduleIn: date })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                align="center"
                style={{
                  marginTop: "1rem",
                  fontFamily: "Nunito",
                  fontWeight: "bold",
                }}
              >
                s/d
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={id}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="d MMMM yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Tanggal Selesai"
                  value={value.timeOffScheduleOut}
                  onChange={(date) =>
                    setValue({ ...value, timeOffScheduleOut: date })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <TextField
              fullWidth
              label="Deskripsi"
              placeholder="Masukkan deskripsi (Opsional)"
              onChange={(e) =>
                setValue({ ...value, timeOffDescription: e.target.value })
              }
              value={value.timeOffDescription}
            />
          </FormControl>

          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              types={["doc", "docx", "pdf", "txt", "png", "jpg", "jpeg"]}
              label="Upload File Bukti"
              multiple={false}
            />
          </div>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "1rem",
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={
                  () => {
                    handleClose();
                  } /*handleCancel()*/
                }
                style={{
                  height: "3rem",
                }}
                disabled={loader}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={
                  () => {
                    verification();
                  } /*handleSubmit()*/
                }
                style={{
                  height: "3rem",
                }}
                disabled={loader}
              >
                Ajukan
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default TimeOffRequestDialog;
