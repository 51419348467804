import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import {
  Toolbar,
  Typography,
  Tooltip,
  Button,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogConfirmDelete from "../ComponentSubGoal/DialogConfirmDelete";
import DialogSetAsCompleteMultiple from "./DialogSetAsCompleteMultiple";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //   paddingRight: theme.spacing.unit,
    // paddingRight: 3,
  },
  highlight: {
    color: "grey",
    backround: "white",
  },
  spacer: {
    flex: "1 100%",
  },
  actions: {
    color: "grey",
  },
  title: {
    flex: "0 0 auto",
    // marginRight: 57,
    fontFamily: "Roboto",
  },
  buttonOutlined: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 1,
    "&:hover": {
      borderWidth: 1,
    },
  },
}));

const EnhancedDeleteToolbar = (props) => {
  const classes = useToolbarStyles();

  const {
    numSelected,
    goalId,
    subGoalId,
    listSubGoalId,
    setSelected,
    statusId,
    policy,
    rowCount,
    onSelectAllClick,
  } = props;

  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  /*
        `````````````````````````````
        HANDLE DIALOG CONFIRM DELETE

        `````````````````````````````
    */
  const [isOpenDialogConfirmDelete, seOpenDialogConfirmDelete] =
    useState(false);
  const [
    isOpenDialogSetAsCompleteMultiple,
    setOpenDialogSetAsCompleteMultiple,
  ] = useState(false);
  const [validGoal, setValidGoal] = useState([]);

  const handleGetValidGoal = () => {
    return Promise.all(
      listSubGoalId.length > 0 &&
        listSubGoalId.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`Goal[${index}][id]`, item);

          return axiosConfig.put(
            `${URL_API}/swd/goal/batch:completed/validate-only`,
            params
          );
        })
    )
      .then((response) => {
        // // console.log("ORI Response SAC", response);
        setValidGoal(response);
        setOpenDialogSetAsCompleteMultiple(true);
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });
  };

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
    =============================================================================================== */

  let directLinkRemoveAllowed = false;
  let completedAllowed = false;

  policy.forEach((item) => {
    if (item.actionName === "directLinkRemove") {
      directLinkRemoveAllowed = item.result;
    } else if (item.actionName === "completed") {
      completedAllowed = item.result;
    }
  });

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      style={{
        minHeight: 0,
        paddingLeft: 3,
        paddingTop: 8,
        marginLeft: 4,
        backgroundColor: "transparent",
      }}
    >
      {superior && (
        <>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
          <div className={classes.title}>
            <Typography
              color="inherit"
              variant="subtitle2"
              className={classes.title}
            >
              {numSelected} Sub-Goal di tandai
            </Typography>
          </div>
        </>
      )}

      <div className={classes.spacer} />

      <div className={classes.title}>
        {superior && numSelected > 0 ? (
          <Fragment>
            <Tooltip
              title="Hapus hubungan Sub-Goal & Main-Goal"
              placement="top"
            >
              <Button
                disabled={statusId === "6"}
                startIcon={
                  <DeleteIcon
                    style={{ color: statusId === "6" ? "#333" : "#d1354a" }}
                  />
                }
                className={classes.buttonOutlined}
                variant="outlined"
                size="small"
                onClick={() => seOpenDialogConfirmDelete(true)}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{
                    color: statusId === "6" ? "#333" : "#d1354a",
                    cursor: statusId === "6" ? "not-allowed" : "pointer",
                  }}
                >
                  <b>Remove Direct Link</b>
                </Typography>
              </Button>
            </Tooltip>

            <Tooltip
              title="Tandai sudah selesai"
              placement="top"
              style={{ marginLeft: 15 }}
            >
              <Button
                disabled={statusId === "6"}
                startIcon={
                  <CheckCircleIcon
                    style={{ color: statusId === "6" ? "#333" : "#d1354a" }}
                  />
                }
                className={classes.buttonOutlined}
                variant="outlined"
                size="small"
                onClick={handleGetValidGoal}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{
                    color: statusId === "6" ? "#333" : "#d1354a",
                    cursor: statusId === "6" ? "not-allowed" : "pointer",
                  }}
                >
                  <b>Set As Complete</b>
                </Typography>
              </Button>
            </Tooltip>
          </Fragment>
        ) : null}
      </div>

      <DialogConfirmDelete
        isOpenDialogConfirmDelete={isOpenDialogConfirmDelete}
        seOpenDialogConfirmDelete={seOpenDialogConfirmDelete}
        goalId={goalId}
        subGoalId={subGoalId}
        listSubGoalId={listSubGoalId}
        setSelected={setSelected}
      />

      <DialogSetAsCompleteMultiple
        isOpenDialogSetAsCompleteMultiple={isOpenDialogSetAsCompleteMultiple}
        setOpenDialogSetAsCompleteMultiple={setOpenDialogSetAsCompleteMultiple}
        goalId={goalId}
        subGoalId={subGoalId}
        listSubGoalId={listSubGoalId}
        setSelected={setSelected}
        validGoal={validGoal}
      />
    </Toolbar>
  );
};

export default EnhancedDeleteToolbar;
