import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

import ContextTask from "../../../Context/ContextTask";
import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import handleError from "../../../../Report/components/global/handleError";
import DialogError from "../../../../../components/DialogError";

const DialogDeleteTodo = ({
  classes,
  todo,
  openDeleteTodo,
  setOpenDeleteTodo,
}) => {
  const { setTriggerTodo } = useContext(ContextTask);

  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteTodo = () => {
    setLoading(true);

    AxiosConfig.delete(`${URL_API}/todolist/${todo.id}`)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTodo(Math.floor(Math.random() * 100));
          setOpenDeleteTodo(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDeleteTodo}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Delete To Do</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Apakah Anda yakin ingin hapus <b>{todo.title}</b> ?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenDeleteTodo(false)}
        >
          Cancel
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleDeleteTodo}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDeleteTodo;
