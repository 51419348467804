import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
} from "@material-ui/core";
import Paginating from "@material-ui/lab/Pagination";
import moment from "moment";

import ContextReports from "../../../../context/ContextReports";
import {
  GET_BAR_CHART,
  GET_DATA_DETAIL,
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  GET_PIE_CHART,
  SET_PAGINATING,
  SET_SUMMARY_TAB,
} from "../../../../context/types";
import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";
import handleError from "../../../global/handleError";
import DialogError from "../../../../../../components/DialogError";
import mapTable from "../../../global/mapTable";

const Pagination = ({ classes }) => {
  const {
    summaryState,
    tableSmrState,
    tableSmrDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
  const pagination = tableSmrState.paginating;
  const perPage = pagination.perPage;

  // Convet achievement
  const calMethodGoal =
    summaryState.selectedAchievements.length > 0
      ? summaryState.selectedAchievements.map((item) => {
          return {
            formula: item,
          };
        })
      : [];
  const calMethodMa =
    summaryState.selectedAchievementsMa.length > 0
      ? summaryState.selectedAchievementsMa.map((item) => {
          return {
            formula: item,
          };
        })
      : [];

  const achievementGoal = {
    field: "goal",
    cal_method: calMethodGoal,
  };
  const achievementMa = {
    field: "ma",
    cal_method: calMethodMa,
  };

  const refGoal = [].concat(
    achievementGoal.cal_method.length > 0 ? achievementGoal : []
  );
  const refMa = [].concat(
    achievementMa.cal_method.length > 0 ? achievementMa : []
  );

  let newAchievement = [];

  if (
    summaryState.selectedEntities.includes("goal") &&
    summaryState.selectedEntities.includes("ma")
  ) {
    newAchievement = [...refGoal, ...refMa];
  }

  if (
    summaryState.selectedEntities.includes("goal") &&
    !summaryState.selectedEntities.includes("ma")
  ) {
    newAchievement = [...refGoal];
  }

  if (
    summaryState.selectedEntities.includes("ma") &&
    !summaryState.selectedEntities.includes("goal")
  ) {
    newAchievement = [...refMa];
  }

  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const dataSummary = {
    name: "Table",
    platform: "web",
    report_format: "table",
    filter_by: {
      entity: [],
      date_range: summaryState.dateRange.code,
      user: summaryState.selectedUsers,
      unit: summaryState.selectedUnits,
      status: summaryState.selectedStatus,
      achievement: newAchievement,
      period_id: summaryState.selectedPeriod.id,
    },
    entities: summaryState.selectedEntities,
    group_by: [summaryState.selectedGroup.field],
    group_by_calculation: summaryState.selectedCalc.code,
    order_by: [summaryState.selectedOrder],
    primary_column: summaryState.selectedFields.code,
    unit_type_id: summaryState.selectedUnit.id,
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  // ============================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Handle Pagination ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ============================================================================
  const handlePaginating = (e, value) => {
    setPage(value);

    tableSmrDispatch({
      type: GET_DATA_TABLE,
      payload: {
        dataTable: [],
        loading: true,
      },
    });

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${value}&per_page=${perPage}`,
      dataSummary
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY TABLE", result);
        const DATATABLE = mapTable(result.results.data);

        const paginating = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
          totalRecords: result.results.total,
        };

        if (res.status === 200) {
          tableSmrDispatch({
            type: GET_FIELD_PRESET,
            payload: { fieldPreset: result.settings.field_preset },
          });

          setTimeout(() => {
            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                dataTable: DATATABLE,
                loading: false,
              },
            });
          }, 1000);

          tableSmrDispatch({
            type: SET_PAGINATING,
            payload: { paginating },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: false,
          },
        });
      });

    //============= Datat For Bar Chart =============
    const dataBar = { ...dataSummary, report_format: "bar_chart" };

    barDispatch({
      type: GET_BAR_CHART,
      payload: {
        barData: [],
        barPreset: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${value}&per_page=${perPage}`,
      dataBar
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: result.results.data,
              barPreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });
      });

    //============= Datat For Pie Chart =============
    const dataPie = {
      ...dataSummary,
      report_format: "pie_chart",
      order_by: [],
    };

    pieDispatch({
      type: GET_PIE_CHART,
      payload: {
        pieDataGoal: [],
        pieDataMa: [],
        piePreset: [],
        loading: true,
      },
    });

    // // console.log("dataPie", dataPie);

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${value}&per_page=${perPage}`,
      dataPie
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);
        const pieGoal = result.results.filter((el) => el.type === "goal");
        const pieMa = result.results.filter((el) => el.type === "ma");

        if (res.status === 200) {
          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: pieGoal,
              pieDataMa: pieMa,
              piePreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: false,
          },
        });
      });

    tableSmrDispatch({
      type: SET_SUMMARY_TAB,
      payload: { summaryTab: 0 },
    });

    tableSmrDispatch({
      type: GET_DATA_DETAIL,
      payload: {
        loadDetail: false,
        dataDetail: {
          goals: [],
          mas: [],
        },
      },
    });
  };

  // ============================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Handle Select ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ============================================================================
  const handleSelect = (e) => {
    const valuePerPage = e.target.value;
    setRowsPerPage(valuePerPage);

    tableSmrDispatch({
      type: GET_DATA_TABLE,
      payload: {
        dataTable: [],
        loading: true,
      },
    });

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${page}&per_page=${valuePerPage}`,
      dataSummary
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY TABLE", result);

        const paginating = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
          totalRecords: result.results.total,
        };

        const DATATABLE = mapTable(result.results.data);

        if (res.status === 200) {
          tableSmrDispatch({
            type: GET_FIELD_PRESET,
            payload: { fieldPreset: result.settings.field_preset },
          });

          setTimeout(() => {
            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                dataTable: DATATABLE,
                loading: false,
              },
            });
          }, 1000);

          tableSmrDispatch({
            type: SET_PAGINATING,
            payload: { paginating },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: false,
          },
        });
      });

    //============= Datat For Bar Chart =============
    const dataBar = { ...dataSummary, report_format: "bar_chart" };

    barDispatch({
      type: GET_BAR_CHART,
      payload: {
        barData: [],
        barPreset: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${page}&per_page=${valuePerPage}`,
      dataBar
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: result.results.data,
              barPreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });
      });

    //============= Datat For Pie Chart =============
    const dataPie = {
      ...dataSummary,
      report_format: "pie_chart",
      // group_by: ["ora_status"],
      order_by: [],
    };

    pieDispatch({
      type: GET_PIE_CHART,
      payload: {
        pieDataGoal: [],
        pieDataMa: [],
        piePreset: [],
        loading: true,
      },
    });

    // // console.log("dataPie", dataPie);

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${page}&per_page=${valuePerPage}`,
      dataPie
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);
        const pieGoal = result.results.filter((el) => el.type === "goal");
        const pieMa = result.results.filter((el) => el.type === "ma");

        if (res.status === 200) {
          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: pieGoal,
              pieDataMa: pieMa,
              piePreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: false,
          },
        });
      });

    tableSmrDispatch({
      type: SET_SUMMARY_TAB,
      payload: { summaryTab: 0 },
    });

    tableSmrDispatch({
      type: GET_DATA_DETAIL,
      payload: {
        loadDetail: false,
        dataDetail: {
          goals: [],
          mas: [],
        },
      },
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.title888} variant="caption">
        Records Per Page
      </Typography>
      <FormControl size="small" style={{ margin: "0 10px", minWidth: 50 }}>
        <NativeSelect
          defaultValue={10}
          onChange={handleSelect}
          value={rowsPerPage}
          inputProps={{
            name: "name",
            id: "uncontrolled-native",
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </NativeSelect>
      </FormControl>

      <Paginating
        variant="text"
        shape="rounded"
        count={tableSmrState.paginating && tableSmrState.paginating.lastPage}
        page={tableSmrState.paginating && tableSmrState.paginating.currentPage}
        onChange={handlePaginating}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default Pagination;
