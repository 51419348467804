import React from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import InsertDriveFileTwoToneIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import HelpTwoToneIcon from "@material-ui/icons/HelpTwoTone";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

import text_icon from "../../../../../assets/images/icons_file/Text.png";
import word_icon from "../../../../../assets/images/icons_file/Word.png";
import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
import video_icon from "../../../../../assets/images/icons_file/Video.png";

import FormatBytes from "../../../../../utilities/FormatBytes";
import TruncateTextMid from "../../../../../utilities/TruncateTextMid";

function ListFiles({ onRemoveFile, classes, file }) {
  return (
    <ListItem>
      <ListItemAvatar>
        {file?.type.includes("image") ? (
          <Avatar variant="square" src={file?.preview} />
        ) : file?.type.includes("video") ? (
          <Avatar variant="square" src={video_icon} />
        ) : file?.type.includes("pdf") ? (
          <Avatar variant="square" src={pdf_icon} />
        ) : file?.type.includes("text") ? (
          <Avatar variant="square" src={text_icon} />
        ) : file?.type === "application/msword" ||
          file?.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
          <Avatar variant="square" src={word_icon} />
        ) : file?.type === "application/vnd.ms-excel" ||
          file?.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
          <Avatar variant="square" src={excel_icon} />
        ) : file?.type === "application/vnd.ms-powerpoint" ||
          file?.type ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
          <Avatar variant="square" src={ppt_icon} />
        ) : (
          file?.type === "" && <HelpTwoToneIcon />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            style={{ fontSize: 13 }}
            className={classes.title}
            variant="subtitle1"
          >
            {`${TruncateTextMid(file?.name)} (${FormatBytes(
              Number(file?.size)
            )})`}
          </Typography>
        }
      />

      <ListItemSecondaryAction>
        <IconButton
          size="small"
          //   disabled={loading}
          onClick={() => onRemoveFile(file)}
        >
          <HighlightOffRoundedIcon fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default ListFiles;
