import React from "react";
import { Box, Avatar, Typography, Checkbox } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import clsx from "clsx";

import ImageSmallUser from "../../../../../../../components/ImageSmallUser";

const ListItemUser = ({
  classes,
  index,
  user,
  selected,
  setSelected,
  isSelected,
}) => {
  const isItemSelected = isSelected(user.id);

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected ALL MA", newSelected);

    setSelected(newSelected);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginLeft={1}
      marginRight={2}
      onClick={() => handleChangeCheckbox(user)}
    >
      <Checkbox
        className={classes.checkboxRoot}
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        checked={isItemSelected}
        disableRipple
        color="default"
        inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${index}` }}
      />
      <Avatar style={{ width: 30, height: 30, marginRight: 10 }}>
        {user.member !== null &&
          user.member.self !== null &&
          user.member.self.rel !== null &&
          user.member.self.rel.photo_url !== null &&
        user.member.self.rel.photo_url !== undefined ? (
          <ImageSmallUser item={user.member.self.rel.photo_url} index={1} />
        ) : (
          <AccountCircleIcon fontSize="small" style={{ color: "#999" }} />
        )}
      </Avatar>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" className={classes.title333}>
          {user.member && user.member.first_name ? user.member.first_name : ""}{" "}
          {user.member && user.member.last_name ? user.member.last_name : ""}
        </Typography>
        <Typography variant="caption" className={classes.title555}>
          {user.structure_position
            ? user.structure_position.structure_position_title_name
            : ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default ListItemUser;
