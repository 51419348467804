import { InputBase, withStyles } from "@material-ui/core";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    marginLeft: 10,
  },
  input: {
    borderRadius: 5,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    // color: "#333",
    fontSize: 13,
    padding: "2px 16px 4px 8px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
    ].join(","),
    "&:focus": {
      borderRadius: 5,
      borderColor: "#d1354a",
      boxShadow: "0 0 0 0.1rem rgba(209, 53, 74,.6)",
    },
  },
}))(InputBase);
