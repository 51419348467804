import React, { useContext } from "react";
import { Box, Typography, Checkbox } from "@material-ui/core";

import clsx from "clsx";
import ContextReports from "../../../../../context/ContextReports";
import { HIT_ACHIEVEMENTS } from "../../../../../context/types";

const ListItemAchievements = ({
  classes,
  index,
  achieve,
  selected,
  setSelected,
  isSelected,
}) => {
  const { summaryDispatch } = useContext(ContextReports);
  const isItemSelected = isSelected(achieve.formula);

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.formula);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.formula);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    summaryDispatch({
      type: HIT_ACHIEVEMENTS,
      payload: { selectedAchievements: newSelected },
    });

    setSelected(newSelected);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginLeft={1}
      marginRight={2}
      onClick={() => handleChangeCheckbox(achieve)}>
      <Checkbox
        className={classes.checkboxRoot}
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        checked={isItemSelected}
        disableRipple
        color="default"
        inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${index}` }}
      />
      <Typography variant="subtitle2" className={classes.title333}>
        {achieve.name}
      </Typography>
    </Box>
  );
};

export default ListItemAchievements;
