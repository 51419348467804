import React from "react";
import { MenuItem, Typography, Divider } from "@material-ui/core";

const ListItemSave = ({ classes }) => {
  return (
    <>
      <MenuItem style={{ width: 230, display: "flex" }} disabled>
        <Typography variant="subtitle2" className={classes.title}>
          List Save Configuration
        </Typography>
      </MenuItem>

      <Divider />

      <MenuItem style={{ width: 230 }}>
        <Typography variant="subtitle2" className={classes.title}>
          Pencapaian Pekan Lalu
        </Typography>
      </MenuItem>
      <MenuItem style={{ width: 230 }}>
        <Typography variant="subtitle2" className={classes.title}>
          Laporan Bulanan IT
        </Typography>
      </MenuItem>
      <MenuItem style={{ width: 230, display: "flex" }}>
        <Typography variant="subtitle2" className={classes.title}>
          Laporan Div Sales
        </Typography>
      </MenuItem>
    </>
  );
};

export default ListItemSave;
