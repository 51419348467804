// ========= Filter Types =========
export const SELECT_DATE_RANGE = "SELECT_DATE_RANGE";
export const GET_FILTER_DATA = "GET_FILTER_DATA";

// ========= Table Types =========
export const GET_DATA_FIELD = "GET_DATA_FIELD";
export const GET_GROUP = "GET_GROUP";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_FIELD_PRESETS = "GET_FIELD_PRESETS";
export const HIT_DATA_PRESET = "HIT_DATA_PRESET";
export const HIT_ENTITIES = "HIT_ENTITIES";
export const HIT_USER_SELECT = "HIT_USER_SELECT";
export const HIT_UNIT_SELECT = "HIT_UNIT_SELECT";
export const HIT_STATUS = "HIT_STATUS";
export const HIT_VALUE_TYPE = "HIT_VALUE_TYPE";
export const HIT_CAL_METHOD = "HIT_CAL_METHOD";
export const HIT_ORDER_BY = "HIT_ORDER_BY";
export const HIT_TOTAL_RECORDS = "HIT_TOTAL_RECORDS";
export const LOAD_DATA_PRESET = "LOAD_DATA_PRESET";
export const SET_PAGINATION = "SET_PAGINATION";

// ========= Comparison Types =========
export const GET_COMPARISONS = "GET_COMPARISONS";
export const HIT_COMPARE_ITEM = "HIT_COMPARE_ITEM";
export const HIT_COMPARE_FIELD = "HIT_COMPARE_FIELD";
export const GET_FIELD_COMPARE = "GET_FIELD_COMPARE";

// ========= Report Types =========
export const GET_REPORT_CATEGORY = "GET_REPORT_CATEGORY";
export const SET_VALUE_TAB = "SET_VALUE_TAB";
export const TOGGLE_DRAWER_CHART = "TOGGLE_DRAWER_CHART";
export const GET_SYNC = "GET_SYNC";

// ========= Summary Types =========
export const GET_SUMMARY_CONFIG = "GET_SUMMARY_CONFIG";
export const HIT_PRIMARY_FIELDS = "HIT_PRIMARY_FIELDS";
export const HIT_GROUP_BY = "HIT_GROUP_BY";
export const HIT_CACL_METHODS = "HIT_CACL_METHODS";
export const HIT_ORDERBY = "HIT_ORDERBY";
export const HIT_ENTITY = "HIT_ENTITY";
export const HIT_USERS = "HIT_USERS";
export const HIT_UNITS = "HIT_UNITS";
export const HIT_UNIT = "HIT_UNIT";
export const HIT_ACHIEVEMENTS = "HIT_ACHIEVEMENTS";
export const HIT_ACHIEVEMENTS_MA = "HIT_ACHIEVEMENTS_MA";
export const HIT_STATUSES = "HIT_STATUSES";
export const HIT_PERIOD = "HIT_PERIOD";
export const GET_SYNC_SUMMARY = "GET_SYNC_SUMMARY";
export const GET_STRUCTURES = "GET_STRUCTURES";

// ========= Table Summary Types =========
export const GET_FIELD_PRESET = "GET_FIELD_PRESET";
export const GET_DATA_TABLE = "GET_DATA_TABLE";
export const SET_PAGINATING = "SET_PAGINATING";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_SUMMARY_TAB = "SET_SUMMARY_TAB";
export const GET_DATA_DETAIL = "GET_DATA_DETAIL";

// ========= Bar Chart Summary Types =========
export const GET_BAR_CHART = "GET_BAR_CHART";

// ========= Bar Chart Summary Types =========
export const GET_PIE_CHART = "GET_PIE_CHART";

// ========= Info Box Summary Types =========
export const GET_AVG_COMPANY = "GET_AVG_COMPANY";
