export const styles = theme => ({
	root: {
		margin: '0 auto',
		marginTop: 100,
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: 768,
	},
	divPicture: {
		borderRadius: '50%',
		width: 60,
		height: 60,
		overflow: 'hidden',
		display: 'flex',
		position: 'relative',
	},
	picture: {
		height: '100%',
		position: 'absolute',
		left: '50%',
		transform: 'translate(-50%, 0%)',
	},
	listTile: {
		display: 'flex',
		gap: 20,
		borderBottom: '2px solid #cfd0d1',
		padding: '20px 30px',
		alignItems: 'center',
	},
});
