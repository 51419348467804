import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import { styles } from "../Style/StyleSubGoal";

import Snackbeer from "../../../../components/Snackbeer";
import IconWarningYellow from "../../../../assets/images/icon_warning_yellow.png";
import { URL_API } from "../../../../constants/config-api";

const useStyles = makeStyles(styles);

const DialogConfirmBeforePilihUser = (props) => {
  const context = useContext(ContextGoalDetail);

  const {
    isModalPilihUser,
    setModalPilihUser,
    isModalConfirmBeforePilihUser,
    setModalConfirmBeforePilihUser,
    collectionGoalResultList,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (isModalConfirmBeforePilihUser === true) {
      context.setSuccessDeleteSubGoal(false);
    }
  }, [isModalConfirmBeforePilihUser]);

  /*
        ``````````````````````
        HANDLE DELETE SUB GOAL

        ``````````````````````
    */
  const [loader, setLoader] = useState(false);
  const handleDeleteSubGoal = () => {
    // console.log("Wew HANDLE !");
  };

  return (
    <Fragment>
      <Dialog
        open={isModalConfirmBeforePilihUser}
        onClose={() => setModalConfirmBeforePilihUser(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img
            src={IconWarningYellow}
            alt="info"
            style={{ width: 48, height: 40 }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Goal ini sudah memiliki {collectionGoalResultList.length} Goal
              Results.{" "}
            </b>
          </Typography>

          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "grey" }}
          >
            Dengan membuat <i>Direct Sub-Goal</i>, perhitungan{" "}
            <i>Goal Results</i> akan berdasarkan hasil dari{" "}
            <i>Direct Sub Goal</i>, sehingga semua <i>Goal Results</i> akan
            dihapus !
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Box marginLeft={4}>
            {/* <Button 
                            onClick={handleDeleteSubGoal}
                            // onClick={() => setModalConfirmBeforePilihUser(false)}
                            variant='outline' 
                            className={classes.buttonOutlined}
                            // fullWidth
                        >  
                            {
                                loader !== true ? "Hapus" : <CircularProgress size={16} style={{color: 'red'}} />
                            }

                        </Button> */}

            <Button
              size="small"
              onClick={() => {
                setModalPilihUser(true);
                setModalConfirmBeforePilihUser(false);
              }}
              variant="contained"
              className={classes.buttonModalDelete}
              // fullWidth
            >
              Oke, mengerti.
            </Button>
          </Box>
        </DialogActions>
        <br />
      </Dialog>
    </Fragment>
  );
};

export default DialogConfirmBeforePilihUser;
