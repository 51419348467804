import React, { useEffect, useState } from "react";
import { Box, Button, List } from "@material-ui/core";

import AddRoundedIcon from "@material-ui/icons/AddRounded";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ListItemStructureUnitType from "./ListItemStructureUnitType";
import DialogCreateUnitType from "./DialogCreateUnitType";

const UnitSettings = ({ classes }) => {
  const [structureUnitTypes, setStructureUnitTypes] = useState([]);
  const [openCreateUnitType, setOpenCreateUnitType] = useState(false);
  const [triggerUnit, setTriggerUnit] = useState(null);

  useEffect(() => {
    AxiosConfig.get(`${URL_API}/human-resource/master-structure-unit-type`)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setStructureUnitTypes(result);
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
      });
  }, [triggerUnit]);

  return (
    <Box>
      <Box mb={1} px={1}>
        <List dense>
          {structureUnitTypes.length > 0 &&
            structureUnitTypes.map((item, i) => {
              return (
                <ListItemStructureUnitType
                  key={i}
                  item={item}
                  classes={classes}
                  onTriggerType={setTriggerUnit}
                />
              );
            })}
        </List>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          size="small"
          variant="outlined"
          className={classes.button2}
          startIcon={<AddRoundedIcon />}
          onClick={() => setOpenCreateUnitType(true)}
        >
          Tambah Unit
        </Button>
      </Box>

      <DialogCreateUnitType
        classes={classes}
        openCreateUnitType={openCreateUnitType}
        setOpenCreateUnitType={setOpenCreateUnitType}
        onTriggerType={setTriggerUnit}
      />
    </Box>
  );
};

export default UnitSettings;
