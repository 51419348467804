import React, { useState, Fragment, useContext } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Button,
  Tabs,
  Tab,
  TablePagination,
  LinearProgress,
  Table,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";

import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";

import PictLitleIconMA from "../../Goal/ComponentsGoalResult/PictEmptyStateGoalResult";

import DialogExportMaResult from "./DialogExportMaResult";

import DialogAddResultMA from "./DialogAddResultMA";
import ListHeaderMAResult from "../../AllMA/ComponentsMAResult/ListHeaderMAResult";
import ListItemMaResult from "./ListItemMaResult";
import ListActivityLog from "./ListActivityLog";
import ListItemTitleResult from "./ListItemTitleResult";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#282560",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const ListCollectionMaResult = (props) => {
  const {
    classes,
    collectionMaResultList,
    maResultLength,
    maDetailState,
    userTokenState,
    handleDropdownOpenMaResult,
    anchorElListMaResult,
    handleDropdownCloseMaResult,
    handleDialogDelete,
    loader,
    isMeasuredActivityDetailShow,
    setSuccessCreateMA,
  } = props;

  const theme = useTheme();
  const context = useContext(ContextGoalDetail);

  /*
        ```````````````
        HANDLE COLLAPSE

        ```````````````
    */
  const [openCollapse, setOpenCollapse] = useState(true);
  const [openDialogAddResultMA, setOpenDialogAddResultMA] = useState(false);

  function handleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  /*
        ```````````````````````
        HANDLE EXPORT MA RESULT

        ```````````````````````
    */
  const [isModalExportMaResult, setModalExportMaResult] = useState(false);

  const [valueTab, setValueTab] = useState(0);

  // toggle result group
  const [toggleGroup, setToggleGroup] = useState(false);
  const [toggleNoData, setToggleNoData] = useState(true);

  /*
    ===================================================================
      HANDLE PAGINATION //*Will be to global state
    ===================================================================
  */

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    context.setLoaderForPagingMAR(true);
    context.setPageMAR(newPage);
    context.setOffsetMAR((parseInt(newPage + 1) - 1) * context.rowsPerPageMAR);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    context.setLoaderForPagingMAR(true);

    // // console.log("Rows perpage : ", event.target.value);
    context.setRowsPerPageMAR(parseInt(event.target.value, 8));
    context.setPageMAR(0);
  };

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
  };

  const handleToggleGroup = (e) => {
    setToggleGroup(e.target.checked);
  };

  const handleToggleNoData = (e) => {
    setToggleNoData(e.target.checked);
  };

  // FLAT Goal Result Lists
  let COLLECTION_GROUP_RESULT = [];

  const collectionWithNoGroup = collectionMaResultList
    .map((el) => {
      if (el.hasOwnProperty("results")) {
        return el.results;
      } else {
        return [];
      }
    })
    .flat();

  const collectionNoData = collectionMaResultList.filter((el) => {
    return el.results?.length > 0;
  });

  if (toggleNoData) {
    COLLECTION_GROUP_RESULT = collectionNoData;
  } else {
    COLLECTION_GROUP_RESULT = collectionMaResultList;
  }

  // // console.log("PLISI FROM MAR LISt ORI", maDetailState.policy);

  return (
    <Fragment>
      <Paper className={classes.paperColumnDuaRowFirst}>
        {loader === true && (
          <div className={classes.rootLinearProgress}>
            <LinearProgress style={{ height: 2, color: "#d64253" }} />
          </div>
        )}

        <Box display="flex" justifyContent="space-between" mb={2}>
          <StyledTabs value={valueTab} onChange={handleChangeTab}>
            <Tab
              classes={{ root: classes.widthTab }}
              label={
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{
                    marginBottom: -10,
                    textTransform: "capitalize",
                  }}
                >
                  <b>Latest MA Result</b>
                </Typography>
              }
            />
            <Tab
              classes={{ root: classes.widthTab }}
              label={
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{
                    marginBottom: -10,
                    textTransform: "capitalize",
                  }}
                >
                  <b>Activity Log</b>
                </Typography>
              }
            />
          </StyledTabs>

          <Box display="flex" marginTop={theme.spacing(0.1)}>
            <Box ml={2}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={toggleGroup}
                    onChange={handleToggleGroup}
                  />
                }
                label={
                  <Typography variant="subtitle2" className={classes.title555}>
                    Group Cycle
                  </Typography>
                }
              />
            </Box>

            {toggleGroup && (
              <Box ml={2}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={toggleNoData}
                      onChange={handleToggleNoData}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                    >
                      Hide Result No Data
                    </Typography>
                  }
                />
              </Box>
            )}

            <Box marginLeft={theme.spacing(0.1)}>
              <Button
                onClick={() => setModalExportMaResult(true)}
                variant="outlined"
                size="small"
                className={classes.buttonOutlined}
                style={{ borderWidth: 1 }}
              >
                Export
              </Button>
            </Box>

            <Box ml={2}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                |
              </Typography>
            </Box>

            <Box ml={2}>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={() => setOpenDialogAddResultMA(true)}
                startIcon={<AddIcon />}
              >
                Add Result
              </Button>
            </Box>
          </Box>
        </Box>

        {valueTab === 0 && (
          <Table size="small" aria-label="a dense table">
            {COLLECTION_GROUP_RESULT.length === 0 &&
              maDetailState.status_id !== "4" &&
              loader === false && (
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle2" className={classes.title333}>
                    <PictLitleIconMA />
                    Anda belum memiliki <i>MA Result</i> ;(
                  </Typography>
                </Box>
              )}

            {COLLECTION_GROUP_RESULT.length > 0 && (
              <ListHeaderMAResult
                cycle={toggleGroup ? "Cycle" : "Date & Time"}
              />
            )}

            {toggleGroup && COLLECTION_GROUP_RESULT.length > 0
              ? COLLECTION_GROUP_RESULT.map((item, idx) => {
                  if (item.hasOwnProperty("results")) {
                    return (
                      <ListItemTitleResult
                        key={idx}
                        item={item}
                        classes={classes}
                        maDetailState={maDetailState}
                        userToken={userTokenState}
                      />
                    );
                  } else {
                    return (
                      <ListItemMaResult
                        key={idx}
                        item={item}
                        classes={classes}
                        maDetailState={maDetailState}
                        userToken={userTokenState}
                        collectionMaResultList={collectionWithNoGroup}
                        setSuccessCreateMA={setSuccessCreateMA}
                      />
                    );
                  }
                })
              : null}

            {!toggleGroup &&
              maDetailState?.status_id !== "2" &&
              collectionWithNoGroup.length > 0 &&
              collectionWithNoGroup.map((item, idx) => {
                return (
                  <ListItemMaResult
                    key={idx}
                    item={item}
                    classes={classes}
                    maDetailState={maDetailState}
                    userToken={userTokenState}
                    collectionMaResultList={collectionWithNoGroup}
                    setSuccessCreateMA={setSuccessCreateMA}
                  />
                );
              })}
          </Table>
        )}

        {valueTab === 0 && COLLECTION_GROUP_RESULT.length > 0 && (
          <Box marginTop={theme.spacing(0.3)}>
            <TablePagination
              classes={{
                toolbar: classes.toolbar,
                root: classes.noBorderBottom,
              }}
              rowsPerPageOptions={[8]}
              colSpan={3}
              count={maResultLength}
              rowsPerPage={context.rowsPerPageMAR}
              page={context.pageMAR}
              labelDisplayedRows={({ from, to, count }) => (
                <Typography variant="subtitle2" className={classes.title}>
                  <h4>
                    {`${from} - ${to === -1 ? count : to} dari total `}
                    <strong>{count}</strong> baris
                  </h4>
                </Typography>
              )}
              labelRowsPerPage="Baris setiap halaman"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}

        {valueTab === 1 && (
          <ListActivityLog classes={classes} maId={maDetailState?.id} />
        )}
      </Paper>

      <DialogAddResultMA
        openDialogAddResultMA={openDialogAddResultMA}
        setOpenDialogAddResultMA={setOpenDialogAddResultMA}
        maDetailState={maDetailState}
        classes={classes}
        setSuccessCreateMA={setSuccessCreateMA}
      />

      <DialogExportMaResult
        isModalExportMaResult={isModalExportMaResult}
        setModalExportMaResult={setModalExportMaResult}
        maDetailState={maDetailState}
      />
    </Fragment>
  );
};

export default ListCollectionMaResult;
