import React, { useContext, useState } from "react";
import { Grid, Box, ButtonGroup, Button, Menu, Divider, MenuItem, Typography } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";

import moment from "moment";
import ContextAttendanceReportAdmin from "../../../Context/ContextAttendanceReportAdmin";
import TableMain from "./TableMain";
import { DateRangePicker } from "react-date-range";

const SummaryType = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);
  const [anchorDate, setAnchorDate] = useState(null);

  const handleSelectDateRange = (e) => {
    let dataTemp            = context.dataSettingDetail;
    if (
      moment().startOf("months").format('YYYY-MM-DD') === moment(e.selection.startDate).format('YYYY-MM-DD') &&
      moment().format('YYYY-MM-DD') === moment(e.selection.endDate).format('YYYY-MM-DD')
    ) {
      dataTemp.date_range_obj.title = 'This Month';
      dataTemp.date_range_obj.code  = 'this_month';
    } else if (
      moment().subtract(1, "month").startOf("months").format('YYYY-MM-DD') === moment(e.selection.startDate).format('YYYY-MM-DD') &&
      moment().subtract(1, "month").endOf("months").format('YYYY-MM-DD') === moment(e.selection.endDate).format('YYYY-MM-DD')
    ) {
      dataTemp.date_range_obj.title = 'Last Month';
      dataTemp.date_range_obj.code  = 'last_month';
    } else {
      dataTemp.date_range_obj.title = 'Custom Date';
      dataTemp.date_range_obj.code  = 'custom_date';
    }
    dataTemp.date_range_obj.start_date  = e.selection.startDate;
    dataTemp.date_range_obj.end_date    = e.selection.endDate;
    context.setDataSettingDetail({...dataTemp});
  };

  // Show Dialog Date Range
  const handleShowAnchorDate = (event) => {
    setAnchorDate(event.currentTarget);
  };
  // Hide Dialog Date Range
  const handleCloseAnchorDate = () => {
    setAnchorDate(null);
  };

  const handleShowResult = () => {
    let dataTemporary                   = context.dataSettingDetail;
    dataTemporary.date_range.start_date = moment(context.dataSettingDetail.date_range_obj.start_date).format('YYYY-MM-DD');
    dataTemporary.date_range.end_date   = moment(context.dataSettingDetail.date_range_obj.end_date).format('YYYY-MM-DD');
    dataTemporary.paging.offset         = 0;
    dataTemporary.paging.page           = 1;
    context.setDataSettingDetail({...dataTemporary});
    context.getReportDetailList();
    handleCloseAnchorDate();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box height={60} mt={3} display="flex" justifyContent="space-between">
          <ButtonGroup size="small" style={{ height: 35 }}>
            <Button
              className={classes.btnGroupCenter}
              size="small"
              startIcon={<DateRangeIcon />}
              disabled={context.loader}
              onClick={handleShowAnchorDate}
            >
              {
                context.dataSettingDetail.date_range_obj.title === undefined
                  ? "Custom Range"
                  : context.dataSettingDetail.date_range_obj.title
              }
            </Button>
          </ButtonGroup>
          <Menu
            anchorEl={anchorDate}
            // keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorDate)}
            onClose={handleCloseAnchorDate}
          >
            <Grid container>
              <Grid item md={12} xs={12} >
                <DateRangePicker
                  ranges={[
                    {
                      startDate: context.dataSettingDetail.date_range_obj.start_date,
                      endDate: context.dataSettingDetail.date_range_obj.end_date,
                      key: "selection",
                    },
                  ]}
                  staticRanges={[
                    {
                      label: 'This Month',
                      code: "this_month",
                      range: () => ({
                        startDate: new Date(moment().startOf("months").format('YYYY-MM-DD HH:mm:ss')),
                        endDate: new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
                      }),
                      isSelected() {
                        if (context.dataSettingDetail.date_range_obj.code === 'this_month') {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    },
                    {
                      label: 'Last Month',
                      code: "last_month",
                      range: () => ({
                        startDate: new Date(moment().subtract(1, "month").startOf("months").format('YYYY-MM-DD HH:mm:ss')),
                        endDate: new Date(moment().subtract(1, "month").endOf("months").format('YYYY-MM-DD HH:mm:ss'))
                      }),
                      isSelected() {
                        if (context.dataSettingDetail.date_range_obj.code === 'last_month') {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  ]}
                  rangedCalendars={true}
                  onInit={handleSelectDateRange}
                  onChange={handleSelectDateRange}
                  months={2}
                  direction={"horizontal"}
                  inputRanges={[]}
                />
              </Grid>
            </Grid>
      
            <Box margin={1.5}>
              <Divider />
            </Box>
      
            <Box
              marginRight={2}
              marginBottom={1}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                size="small"
                variant="outlined"
                className={classes.button0}
                onClick={() => setAnchorDate(null)}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.button1}
                style={{ marginLeft: 7.5 }}
                onClick={handleShowResult}
              >
                Show results
              </Button>
            </Box>
          </Menu>

          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography variant="subtitle2" className={classes.title333}>
              Date :{" "}
              {context.dataSettingDetail.date_range_obj.start_date !== "" &&
                moment(
                  context.dataSettingDetail.date_range_obj.start_date !== "" &&
                    context.dataSettingDetail.date_range_obj.start_date
                ).format("DD/MM/YYYY")}{" "}
              {" "}-{" "}
              {context.dataSettingDetail.date_range_obj.end_date !== "" &&
                moment(
                  context.dataSettingDetail.date_range_obj.end_date !== "" &&
                    context.dataSettingDetail.date_range_obj.end_date
                ).format("DD/MM/YYYY")}{" "}
            </Typography>
          </Box>
        </Box>
        <TableMain />
      </Grid>
    </Grid>
  );
};

export default SummaryType;
