import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  Divider,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ListItemUnit from "./ListItemUnit";
import ListItemType from "./ListItemType";
import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";
import clsx from "clsx";
import ListItemPositionLevel from "./ListItemPositionLevel";
import ListItemPosition from "./ListItemPosition";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const UnitFilter = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  // ==============================================
  // ~~~~~~~~~~~~~~~~~ STATE ~~~~~~~~~~~~~~~~~
  // ==============================================
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [unitType, setUnitType] = useState({ id: "", name: "Pilih unit type" });
  const [anchorUnitType, setAnchorUnitType] = useState(null);
  const [anchorUnit, setAnchorUnit] = useState(null);
  const [anchorStructure, setAnchorStructure] = useState(null);
  const isSelected = (id) => selectedUnit.indexOf(id) !== -1;
  const [structureCollections, setStructureCollections] = useState([]);
  
  const handleShowAnchorUnitType = (event) => {
    setAnchorUnitType(event.currentTarget);
  };

  const handleCloseAnchorUnitType = () => {
    setAnchorUnitType(null);
  };

  
  const handleShowAnchorUnit = (event) => {
    setAnchorUnit(event.currentTarget);
  };

  const handleCloseAnchorUnit = () => {
    setAnchorUnit(null);
  };

  const handleShowAnchorStructure = (event) => {
    setAnchorStructure(event.currentTarget);
  };
  const handleCloseAnchorStructure = () => {
    setAnchorStructure(null);
  };

  const handleType = (data) => {
    setSelectedUnit([]);
    setUnitType(data);
    handleCloseAnchorUnitType();
  };

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {

      let newSelecteds = [];
      await context.masterFilterReportDetail.units.filter((item) => {
        if (unitType.id === item.structure_unit_type_id) {
          newSelecteds.push(item.id);
        }
      });
      setSelectedUnit(newSelecteds);
      return;
    }
    setSelectedUnit([]);
  }

  const handleShowResult = () => {
    setAnchorUnit(null);
    let dataTemporary             = context.dataSettingDetail;
    dataTemporary.filter.unit     = selectedUnit;
    let unitName       = [];
    context.masterFilterReportDetail.units.map((item) => {
      if (selectedUnit.includes(item.id)) {
        const name = `${item.structure_unit_name ? item.structure_unit_name : ""}`;
        unitName.push(name);
      }
      return 0;
    })
    dataTemporary.filter.unit_name  = unitName;
    dataTemporary.paging.offset         = 0;
    dataTemporary.paging.page           = 1;
    context.setDataSettingDetail({...dataTemporary});
    context.getReportDetailList();
    handleCloseAnchorUnit();
  };

  return (
    <>
      <StyledBadge
        badgeContent={selectedUnit.length}
        children={
          <Button
            variant="outlined"
            size="small"
            startIcon={<AccountTreeIcon style={{ fontSize: 15 }} />}
            className={classes.actionBtn}
            disabled={context.loader}
            onClick={handleShowAnchorUnit}
          >
            Unit
          </Button>
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorUnit}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorUnit)}
        onClose={handleCloseAnchorUnit}>
        <Box mx={2} mt={2} mb={3} style={{ width: 200 }}>
          <Typography variant="subtitle2" className={classes.title555}>
            <b>Unit Type</b>
          </Typography>

          <Button
            variant="outlined"
            className={classes.actionBtn}
            fullWidth
            endIcon={<ArrowDropDownIcon />}
            style={{ marginTop: 5 }}
            onClick={handleShowAnchorUnitType}>
            {unitType.name}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorUnitType}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorUnitType)}
            onClose={handleCloseAnchorUnitType}
          >
            {context.loader && (
              <Box m={2}>
                <Typography
                  variant="caption"
                  className={classes.title555}
                  style={{ textAlign: "center" }}>
                  Loading...
                </Typography>
              </Box>
            )}
            {context.masterFilterReportDetail.unit_types.length > 0 &&
              context.masterFilterReportDetail.unit_types.map((item, i) => (
                <MenuItem key={i} style={{ padding: 0 }} dense>
                  <ListItemType
                    classes={classes}
                    item={item}
                    type={unitType}
                    onType={handleType}
                  />
                </MenuItem>
              ))}
          </Menu>
        </Box>
        {unitType.id !== "" && (
          <Box mx={2} mb={2}>
            <Typography variant="subtitle2" className={classes.title555}>
              <b>
                Structure Unit {selectedUnit.length > 0 && `(${selectedUnit.length})`}
              </b>
            </Typography>

            <Button
              variant="outlined"
              className={classes.actionBtn}
              fullWidth
              endIcon={<ArrowDropDownIcon />}
              style={{ marginTop: 5 }}
              onClick={handleShowAnchorStructure}>
              Structure Unit
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorStructure}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorStructure)}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                ml={1}
                mr={2}
                mb={1}>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    className={classes.checkboxRoot}
                    checkedIcon={
                      <span className={clsx(classes.icon, classes.checkedIcon)} />
                    }
                    icon={<span className={classes.icon} />}
                    checked={context.masterFilterReportDetail.units.filter(function(item){ return unitType.id === item.structure_unit_type_id;}).length > 0 && selectedUnit.length === context.masterFilterReportDetail.units.filter(function(item){ return unitType.id === item.structure_unit_type_id;}).length}
                    disableRipple
                    color="default"
                    inputProps={{ "aria-label": "decorative checkbox" }}
                    onChange={handleSelectAllClick}
                  />
                  <Typography variant="subtitle2" className={classes.title555}>
                    All
                  </Typography>
                </Box>
                <Typography
                  className={classes.btnTxt}
                  onClick={() => setSelectedUnit([])}>
                  None
                </Typography>
              </Box>
              <Box>
                {context.loader && (
                  <Box m={2}>
                    <Typography
                      variant="caption"
                      className={classes.title555}
                      style={{ textAlign: "center" }}>
                      Loading...
                    </Typography>
                  </Box>
                )}
                {context.masterFilterReportDetail.units.length > 0 &&
                  context.masterFilterReportDetail.units.map((item, i) => {
                    if (unitType.id === item.structure_unit_type_id) {
                      return (
                        <MenuItem key={i} style={{ padding: 0 }} dense>
                          <ListItemUnit
                            classes={classes}
                            index={i}
                            unit={item}
                            selected={selectedUnit}
                            setSelected={setSelectedUnit}
                            isSelected={isSelected}
                          />
                        </MenuItem>
                      );
                    }
                  })}
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                my={1.5}
                mx={2}
                style={{ minWidth: 200 }}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button0}
                  onClick={() => {
                    handleCloseAnchorStructure();
                    setSelectedUnit([]);
                  }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 7.5 }}
                  size="small"
                  className={classes.button1}
                  onClick={handleCloseAnchorStructure}>
                  Select ({selectedUnit.length})
                </Button>
              </Box>
            </Menu>
          </Box>
        )}
        <Box>
          <Box m={1.5} mt={1}>
            <Divider />
          </Box>

          <Box
            marginRight={2}
            marginBottom={1}
            display="flex"
            justifyContent="flex-end">
            <Button
              size="small"
              variant="outlined"
              className={classes.button0}
              onClick={handleCloseAnchorUnit}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              className={classes.button1}
              style={{ marginLeft: 7.5 }}
              onClick={handleShowResult}
            >
              {context.loader ? "Showing..." : "Show results"}
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default UnitFilter;
