import React, { useContext } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";

import ContextArchive from "../../context/ContextArchive";
import ListItemArchiveEntity from "./ListItemArchiveGroup";

const Group = ({ classes }) => {
  const { archiveState } = useContext(ContextArchive);

  return (
    <Box>
      {archiveState.loadGroup && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress
            size={16}
            style={{ color: "#d64253", marginRight: 5 }}
          />
          <Typography variant="subtitle2" className={classes.title888}>
            Loading...
          </Typography>
        </Box>
      )}
      {!archiveState.loadGroup &&
        archiveState.archiveGroupList.length > 0 &&
        archiveState.archiveGroupList.map((item, i) => (
          <ListItemArchiveEntity key={i} classes={classes} item={item} />
        ))}
      {!archiveState.loadGroup && archiveState.archiveGroupList.length === 0 && (
        <Box mt={4} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="subtitle1" className={classes.title888}>
            No data archived
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Group;
