import React, { Component } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { navigate } from "hookrouter";

import logoPerformate from "../../../assets/images/performate-03.png";

import logoMadinahCare from "../../../assets/images/landing/logo-madinah-care.PNG";
import logoMadinahFlash from "../../../assets/images/landing/logo-madinah-flash.PNG";
import logoMadinahGroup from "../../../assets/images/landing/logo-madinah-group.PNG";
import logoMadinahMultimedia from "../../../assets/images/landing/logo-madinah-multimedia.PNG";
import logoMadinahStore from "../../../assets/images/landing/logo-madinah-store.PNG";

import img1 from "../../../assets/images/landing/scoreboard1.PNG";
import img2 from "../../../assets/images/landing/scoreboard2.PNG";
import img3 from "../../../assets/images/landing/scoreboard3.PNG";
import img4 from "../../../assets/images/landing/scoreboard4.PNG";

import "../Style/landing.css";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  alternateBackground: {
    backgroundColor: "#f5f5f5",
  },
  centerInDiv: {
    textAlign: "center",
    height: "100%",
    display: "table",
  },
  centerInDivCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  bottomBackground: {
    backgroundColor: "#d1364a",
    color: "#ffffff",
  },
  ClientAndPartner: {
    width: "80%",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: 0,
    margin: "0 auto",
  },
  paddingSection: {
    paddingTop: "60px",
    paddingBottom: "60px",
  },
  gridDescription: {
    display: "grid",
    padding: 0,
    margin: "0 auto",
    gridTemplateColumns: "50% 50%",
    width: "80%",
  },
});

const useStyles = makeStyles(styles);

const Scoreboard = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <div>
      <section style={{ paddingTop: "0px", textAlign: "center" }}>
        <div className="box-price">
          <div className="box-price-left-side" style={{ width: "70%" }}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={img1} width="30px" /> <span>Scoreboard</span>
            </div>
            <h2>Visualisasikan pencapaian anda dengan Scoreboard </h2>
            <p>
              Orang-orang bermain dengan cara berbeda ketika mereka mulai
              menghitung skor. Sebuah Scoreboard akan memudahkan anda untuk
              melihat representasi data yang lebih Iengkap dari target anda.
            </p>
            <Button
              onClick={handleTryFree}
              variant="contained"
              color="primary"
              disableElevation
              className={classes.textLowerCase}
              style={{ width: "150px", margin: "0 auto" }}
            >
              Coba Gratis
            </Button>
          </div>

          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
              <img src={img2} style={{ width: "90%" }} />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <h3 className="text-client-title">
            Your mate in performance monitoring
          </h3>
          <p>
            Dipercaya banyak perusahaan, UKM, dan organisasi untuk mencapai
            goal-goal mereka
          </p>
          <div className={classes.ClientAndPartner}>
            <div>
              <img src={logoMadinahGroup} width="50%" />
            </div>

            <div>
              <img src={logoMadinahStore} width="50%" />
            </div>

            <div>
              <img src={logoMadinahFlash} width="100%" />
            </div>

            <div>
              <img src={logoMadinahCare} width="70%" />
            </div>

            <div>
              <img src={logoMadinahMultimedia} width="80%" />
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>

      <section
        className={[classes.alternateBackground, classes.paddingSection].join(
          " "
        )}
      >
        <div className={classes.gridDescription}>
          <div>
            <h3>Lihat pencapaian harian .</h3>
            <p>
              Performate membantu anda untuk memonitor pencapaian harian sebua
              Goal dan Measured Activities menggunakan Barchart yang menampilkan
              informasi dari semua pencapaian harian anda .
            </p>
          </div>
          <div className={classes.centerInDiv}>
            <div className={classes.centerInDivCell}>
              <img src={img3} style={{ width: "80%" }} />
            </div>
          </div>
        </div>
      </section>

      <section className={classes.paddingSection}>
        <div className={classes.gridDescription}>
          <div className={classes.centerInDiv}>
            <div className={classes.centerInDivCell}>
              <img src={img4} style={{ width: "80%" }} />
            </div>
          </div>
          <div>
            <h3>Lihat total dari seluruh pencapaian .</h3>
            <p>
              SBanyaknya pencapaian dari Goal dan Measured Activities seringkali
              membuat anda kesulitan untuk mendapat gambaran besar pencapaian
              Goal atau Measured Activities.
              <br />
              <br />
              Dengan Performate melihat keseluruhan dari semua hasil pencapaian
              akan menjadi sangat mudah. Cukup dengan melihat Circle Chart, anda
              dapat mengetahui status pencapaian Goal dan Measured Activities
              dengan mudah dan cepat dari kode warna standar yang ditampilkan .
            </p>
          </div>
        </div>
      </section>

      <section className={classes.paddingSection}>
        <div
          className="box-tour"
          style={{ textAlign: "center", margin: "0 auto " }}
        >
          <h3>Ikuti Tur</h3>
          <p>
            Ingin mempelajari lebih banyak? Atur jadwal demo bersama kami untuk
            memandu dan menjawab pertanyaan anda dengan menggunakan platform
            secara langsung
          </p>
          <Button
            onClick={() => navigate("/demo-schedule")}
            variant="outlined"
            color="primary"
            disableElevation
            className={classes.textLowerCase}
          >
            Atur demo
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Scoreboard;
