import React, { useState, useContext } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  withStyles,
  InputBase,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";

import moment from "moment";

import { AntTabs, AntTab } from "./tabStyle";

import ContextReports from "../../context/ContextReports";
import {
  SET_VALUE_TAB,
  //comparison
  GET_COMPARISONS,
  // detailed
  GET_FILTER_DATA,
  GET_REPORT_CATEGORY,
  GET_FIELD_PRESETS,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
  // summary
  GET_SUMMARY_CONFIG,
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_PAGINATING,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
  GET_SYNC,
  HIT_PERIOD,
  GET_SYNC_SUMMARY,
} from "../../context/types";
import { entities, status, order_by } from "../global/dataFilterReport";

import ConfigReport from "./ConfigReport";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../global/handleError";
import DialogError from "../../../../components/DialogError";
import extractTable from "../global/extractTable";
import mapTable from "../global/mapTable";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    position: "relative",
    fontSize: 19,
    padding: "2px 16px 4px 8px",
    fontFamily: ["Roboto", '"Segoe UI"', "Roboto"].join(","),
  },
}))(InputBase);

const ReportType = ({ classes }) => {
  const {
    reportState,
    filterState,
    tableState,
    tableDispatch,
    reportDispatch,
    filterDispatch,
    summaryState,
    summaryDispatch,
    tableSmrState,
    tableSmrDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  const [reportType, setReportType] = useState("live_report");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleReportType = (event) => {
    setReportType(event.target.value);
  };

  // ===================================================================
  // ~ Pepare Body For Detailed Report ~
  // ~~~~~~~~~~~ BODY For Data Table ~~~~~~~~~~~
  const dataPost = {
    name: "Last Seven Days",
    platform: "web",
    field_preset_id: 1,
    report_format: "table",
    filter_by: {
      entity: [
        { name: "goal", query: "asdfasdfds" },
        { name: "ma", query: "asdfasdfds" },
      ],
      date_range: "last_seven_days",
      user: tableState.usersSelected,
      unit: [],
      status: tableState.status,
      achievement: {
        value_type: tableState.valueType,
        cal_method: tableState.calMethod,
      },
    },
    entities: ["goal", "ma"],
    group_by: {
      entity: ["goal"],
      date_time: [],
      user: false,
      unit: ["division", "branch"],
      orientation: [],
    },
    group_by_calculation: "1",
    order_by: tableState.selectedOrder,
    primary_column: "name",
    limit_type: "desc",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
    comparisons: {
      date_range: "previous_seven_days",
      fields: [
        "result_value",
        "overall_result_achievement",
        "expected_result_value",
      ],
    },
  };

  // ~~~~~~~~~~~ PAGINATION Data For Table ~~~~~~~~~~~
  const paginating = tableState.pagination;
  const page = paginating.currentPage;
  const perPage = paginating.perPage;

  const handleValueTab = (e, newVal) => {
    if (newVal === 0) {
      reportDispatch({
        type: SET_VALUE_TAB,
        payload: { valueTab: newVal, category: "summary" },
      });

      tableSmrDispatch({
        type: GET_DATA_TABLE,
        payload: {
          dataTable: [],
          loading: true,
        },
      });

      barDispatch({
        type: GET_BAR_CHART,
        payload: {
          barData: [],
          barPreset: [],
          loading: true,
        },
      });

      pieDispatch({
        type: GET_PIE_CHART,
        payload: {
          pieDataGoal: [],
          pieDataMa: [],
          piePreset: [],
          loading: true,
        },
      });

      // ================= GET ALL INITIAL DATA CREATION =================
      AxiosConfig.get(
        `${URL_API}/reporting/page/${reportState.group}/summary?compare_range=weekly&platform=web`
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("RES report Page", result);

          const settings = result.layout[0].widget.setting;
          // // console.log("SETTINS", settings);
          const primaryFields = settings.primary_column.default_value.filter(
            (el) => el.code === "overall_result_achievement"
          );
          const groupBy = settings.group_by.default_value.filter(
            (g) => g.field !== "ora_status"
          );
          const calcMethods =
            settings.group_by_calculation.default_value.filter(
              (el) => el.code === "avg"
            );
          const orderBy = settings.order_by.default_value.filter(
            (g) => g.field !== "title" && g.field !== "status_id"
          );
          // const modes = settings.order_by.default_value;
          const entities = settings.filter_by.default_value.entity;
          const users = settings.filter_by.default_value.user;
          const units = settings.filter_by.default_value.unit;
          const periods = settings.filter_by.default_value.period_id;
          const achievements =
            settings.filter_by.default_value.achievement.cal_method.filter(
              (el) => el.formula !== "no data"
            );
          const status = settings.filter_by.default_value.status.filter(
            (el) => el.id === "3" || el.id === "6" || el.id === "4"
          );

          // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
          const pagination = tableSmrState.paginating;
          const currentPage = pagination.currentPage;
          const perPage = pagination.perPage;

          // ~~~~~~~~~~~ Set Date Range in LS ~~~~~~~~~~~
          const date_range = {
            title: summaryState.dateRange.title,
            code: summaryState.dateRange.code,
            startDate: summaryState.dateRange.startDate,
            endDate: summaryState.dateRange.endDate,
          };
          localStorage.setItem("summary_range", JSON.stringify(date_range));
          // Set The user List to Local Storage
          localStorage.setItem("summary_user", JSON.stringify(users));

          summaryDispatch({
            type: GET_SUMMARY_CONFIG,
            payload: {
              primaryFields,
              groupBy,
              calcMethods,
              orderBy,
              entities,
              users,
              units,
              achievements,
              status,
              periods,
              // modes,
              // filters,
            },
          });

          let initPeriod = [];
          let PERIOD_ID = summaryState.selectedPeriod.id;

          if (periods.length > 0) {
            const filterSelect = periods.filter((el) => el.selected === true);

            if (filterSelect.length > 0) {
              initPeriod = [...filterSelect];
            } else {
              periods.forEach((el) => {
                const b4 = moment(el.start_date).isBefore(new Date(), "day");
                const a4 = moment(el.end_date).isAfter(new Date(), "day");

                if (b4 && a4) {
                  initPeriod.push(el);
                }
              });
            }
          } else {
            initPeriod = [];
          }

          if (initPeriod.length > 0) {
            summaryDispatch({
              type: HIT_PERIOD,
              payload: {
                selectedPeriod: initPeriod[0],
              },
            });

            PERIOD_ID = initPeriod[0].id;
          } else {
            summaryDispatch({
              type: HIT_PERIOD,
              payload: {
                selectedPeriod:
                  periods.length > 0 ? periods[0] : summaryState.selectedPeriod,
              },
            });
            PERIOD_ID =
              periods.length > 0
                ? periods[0].id
                : summaryState.selectedPeriod.id;
          }

          // // console.log("Summary Layout", layouts);
          // // console.log("Summary primaryFields", primaryFields);
          // // console.log("Summary periods", periods);

          // ===================================================================
          // ~ Pepare Body For Summary Report ~

          // Convet achievement
          const calMethodGoal =
            summaryState.selectedAchievements.length > 0
              ? summaryState.selectedAchievements.map((item) => {
                  return {
                    formula: item,
                  };
                })
              : [];
          const calMethodMa =
            summaryState.selectedAchievementsMa.length > 0
              ? summaryState.selectedAchievementsMa.map((item) => {
                  return {
                    formula: item,
                  };
                })
              : [];

          const achievementGoal = {
            field: "goal",
            cal_method: calMethodGoal,
          };
          const achievementMa = {
            field: "ma",
            cal_method: calMethodMa,
          };

          const refGoal = [].concat(
            achievementGoal.cal_method.length > 0 ? achievementGoal : []
          );
          const refMa = [].concat(
            achievementMa.cal_method.length > 0 ? achievementMa : []
          );

          let newAchievement = [];

          if (
            summaryState.selectedEntities.includes("goal") &&
            summaryState.selectedEntities.includes("ma")
          ) {
            newAchievement = [...refGoal, ...refMa];
          }

          if (
            summaryState.selectedEntities.includes("goal") &&
            !summaryState.selectedEntities.includes("ma")
          ) {
            newAchievement = [...refGoal];
          }

          if (
            summaryState.selectedEntities.includes("ma") &&
            !summaryState.selectedEntities.includes("goal")
          ) {
            newAchievement = [...refMa];
          }

          // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
          const dataSummary = {
            name: "Table",
            platform: "web",
            report_format: "table",
            filter_by: {
              entity: [],
              date_range: summaryState.dateRange.code,
              user: summaryState.selectedUsers,
              unit: summaryState.selectedUnits,
              status: summaryState.selectedStatus,
              achievement: newAchievement,
              period_id: PERIOD_ID,
            },
            entities: summaryState.selectedEntities,
            group_by: [summaryState.selectedGroup.field],
            group_by_calculation: summaryState.selectedCalc.code,
            order_by: [summaryState.selectedOrder],
            primary_column: summaryState.selectedFields.code,
            unit_type_id: summaryState.selectedUnit.id,
            limit_count_number: 0,
            week_start: "monday",
            date_format: "d m Y",
            summary_only: false,
          };

          // ================= GET INITIAL DATA TABLE SUMMARY =================
          AxiosConfig.post(
            `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
            dataSummary
          )
            .then((res) => {
              const result = res.data.data;
              // console.log("RES SUMMARY TABLE", res);
              const DATATABLE = mapTable(result.results.data);
              const lastSync = res.data.info.data;
              const infoStatus = res.data.info.data.last_status;

              // // console.log("RES DATATABLE", DATATABLE);

              const paginating = {
                currentPage: result.results.current_page,
                perPage: result.results.per_page,
                lastPage: result.results.last_page,
                totalRecords: result.results.total,
              };

              if (res.status === 200) {
                tableSmrDispatch({
                  type: GET_FIELD_PRESET,
                  payload: { fieldPreset: result.settings.field_preset },
                });

                summaryDispatch({
                  type: GET_SYNC_SUMMARY,
                  payload: { syncSummary: lastSync, infoStatus },
                });

                setTimeout(() => {
                  tableSmrDispatch({
                    type: GET_DATA_TABLE,
                    payload: {
                      // dataTable: result.results.data,
                      dataTable: DATATABLE,
                      // dataTable: result.results,
                      loading: false,
                    },
                  });
                }, 1000);

                tableSmrDispatch({
                  type: SET_PAGINATING,
                  payload: { paginating },
                });
              }
            })
            .catch((error) => {
              // console.log("Error : ", error);

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);

              tableSmrDispatch({
                type: GET_DATA_TABLE,
                payload: {
                  dataTable: [],
                  loading: false,
                },
              });
            });

          //============= Datat For Ovaerge Company =============
          const dataAvg = {
            ...dataSummary,
            report_format: "summary",
          };

          // ================= GET INITIAL DATA BAR CHART SUMMARY =================
          AxiosConfig.post(
            `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
            dataAvg
          )
            .then((res) => {
              const result = res.data.data;
              // // console.log("RES SUMMARY BAR", result);

              if (res.status === 200) {
                summaryDispatch({
                  type: GET_AVG_COMPANY,
                  payload: {
                    averages: result.results,
                  },
                });
              }
            })
            .catch((error) => {
              // console.log("Error : ", error);

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);
            });

          //============= Datat For Bar Chart =============
          const dataBar = { ...dataSummary, report_format: "bar_chart" };

          // ================= GET INITIAL DATA BAR CHART SUMMARY =================
          AxiosConfig.post(
            `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
            dataBar
          )
            .then((res) => {
              const result = res.data.data;
              // // console.log("RES SUMMARY BAR", result);

              if (res.status === 200) {
                barDispatch({
                  type: GET_BAR_CHART,
                  payload: {
                    barData: result.results.data,
                    barPreset: result.settings.field_preset,
                    loading: false,
                  },
                });
              }
            })
            .catch((error) => {
              // console.log("Error : ", error);

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);

              barDispatch({
                type: GET_BAR_CHART,
                payload: {
                  barData: [],
                  barPreset: [],
                  loading: false,
                },
              });
            });

          //============= Datat For Pie Chart =============
          const dataPie = {
            ...dataSummary,
            report_format: "pie_chart",
            order_by: [],
          };

          // ================= GET INITIAL DATA BAR CHART SUMMARY =================
          AxiosConfig.post(
            `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
            dataPie
          )
            .then((res) => {
              const result = res.data.data;
              // // console.log("RES SUMMARY BAR", result);
              const pieGoal = result.results.filter((el) => el.type === "goal");
              const pieMa = result.results.filter((el) => el.type === "ma");

              if (res.status === 200) {
                pieDispatch({
                  type: GET_PIE_CHART,
                  payload: {
                    pieDataGoal: pieGoal,
                    pieDataMa: pieMa,
                    piePreset: result.settings.field_preset,
                    loading: false,
                  },
                });
              }
            })
            .catch((error) => {
              // console.log("Error : ", error);

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);

              pieDispatch({
                type: GET_PIE_CHART,
                payload: {
                  pieDataGoal: [],
                  pieDataMa: [],
                  piePreset: [],
                  loading: false,
                },
              });
            });
        })
        .catch((error) => {
          // console.log("Error : ", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: [], load: false },
          });
        });
    } else if (newVal === 1) {
      reportDispatch({
        type: SET_VALUE_TAB,
        payload: { valueTab: newVal, category: "detailed" },
      });

      // ================= GET ALL INITIAL DATA CREATION =================
      AxiosConfig.get(
        `${URL_API}/reporting/page/${reportState.group}/detailed?compare_range=weekly&platform=web`
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("RES report Page", result);

          const settings = result.layout[0].widget.setting;
          const filterBy = settings.filter_by.default_value;
          const comparisons = settings.comparisons.default_value;
          const field_preset_id = settings.field_preset_id.default_value;

          // // console.log("Base Layout", settings);

          const byUser = filterBy.user;
          const byUnit = filterBy.unit;

          const defaultPreset = field_preset_id.filter(
            (item) => item.name === "Result Simple"
          );

          // Set The user List to Local Storage
          localStorage.setItem("filter_user", JSON.stringify(byUser));

          // Just GET presets
          tableDispatch({
            type: GET_FIELD_PRESETS,
            payload: {
              fieldPresets: defaultPreset[0],
            },
          });

          // Load All data For Filter
          filterDispatch({
            type: GET_FILTER_DATA,
            payload: {
              tablePresets: field_preset_id,
              entities,
              users: byUser,
              units: byUnit,
              status: status,
              orderBy: order_by,
              filterRanges: filterState.filterRanges,
            },
          });

          // GET Comparison initial Data
          filterDispatch({
            type: GET_COMPARISONS,
            payload: {
              comparisonList: comparisons.date_range,
              comparisonFields: comparisons.fields,
            },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: [], load: true },
          });

          // ================= GET INITIAL DATA TABLE DETAILED =================
          AxiosConfig.post(
            `${URL_API}/reporting/widget/detail?page=${page}&per_page=${perPage}`,
            dataPost
          )
            .then((res) => {
              const result = res.data.data;
              const lastSync = res.data.info.data;

              const DATATABLE = extractTable(result.results.data);
              const pagination = {
                currentPage: result.results.current_page,
                perPage: result.results.per_page,
                lastPage: result.results.last_page,
              };

              // // console.log("Data First Load", DATATABLE);
              // // console.log("Detailed First Load", res);

              if (res.status === 200) {
                reportDispatch({
                  type: GET_SYNC,
                  payload: { lastSync },
                });

                tableDispatch({
                  type: HIT_DATA_PRESET,
                  payload: { dataPresets: DATATABLE, load: false },
                });

                tableDispatch({
                  type: SET_PAGINATION,
                  payload: { pagination },
                });

                tableDispatch({
                  type: HIT_TOTAL_RECORDS,
                  payload: { totalRecords: result.results.total },
                });
              }
            })
            .catch((error) => {
              // console.log("Error : ", error);

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);

              tableDispatch({
                type: HIT_DATA_PRESET,
                payload: { dataPresets: [], load: false },
              });
            });
        })
        .catch((error) => {
          // console.log("Error : ", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: [], load: false },
          });
        });
    } else if (newVal === 2) {
      reportDispatch({
        type: SET_VALUE_TAB,
        payload: { valueTab: newVal, category: "progress" },
      });
    }
  };

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
      >
        <Box display="flex">
          <FormControl style={{ marginRight: 18 }}>
            <Select
              value={reportType}
              label={<Typography variant="h6">{reportType}</Typography>}
              onChange={handleReportType}
              input={<BootstrapInput />}
            >
              <MenuItem dense value="live_report" className={classes.hoverItem}>
                <Typography variant="h6">Live Report</Typography>
              </MenuItem>
              <MenuItem
                dense
                value="saved_report"
                className={classes.hoverItem}
              >
                <Typography variant="h6">Saved Report</Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <Divider orientation="vertical" flexItem />
          <Box marginLeft={3}>
            <AntTabs
              value={reportState.valueTab}
              onChange={handleValueTab}
              aria-label="ant example"
            >
              {reportState.reportCategories.length > 0 &&
                reportState.reportCategories
                  .filter((fltr) => fltr.id !== "progress")
                  .map((tab, i) => <AntTab key={i} label={tab.name} />)}
            </AntTabs>
          </Box>
        </Box>
        <Box pr={5}>
          <ConfigReport classes={classes} />
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
      {/* </ThemeProvider> */}
    </>
  );
};

export default ReportType;
