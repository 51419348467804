import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

const useStyles = makeStyles((theme) => ({
  title333: {
    color: "#333",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
}));

// COnvert string to whatsapp text format
function WhatsAppFormat(msg) {
  if (!msg) return;

  return msg
    .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
    .replace(/_(_*[^_\n]+_*)_/g, "<em>$1</em>")
    .replace(/#(#*[^_\n]+#*)#/g, "<u>$1</u>")
    .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>");
}

const DialogPreviewText = ({ openText, setOpenText, file }) => {
  const classes = useStyles();
  const inputEl = useRef(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openText) {
      setLoading(true);
      AxiosConfig.get(
        `${URL_API}/todolist/${file.todolist_id}/attachment/${file.id}`,
        {
          responseType: "blob",
        }
      )
        .then((response) => {
          // // console.log("Response Thumbnail", response);

          if (response.status === 200) {
            const file = new Blob([response.data], { type: "text/plain" });

            const reader = new FileReader();

            reader.onload = (event) => {
              let text = event.srcElement.result;

              const formatedText = WhatsAppFormat(text);
              inputEl.current.innerHTML = formatedText;
            };

            reader.readAsText(file);
            // window.open(obj_url);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoading(false);
        });
    }
  }, [openText]);

  const handleClosePreview = () => {
    setLoading(false);
    setOpenText(false);
  };

  return (
    <div>
      {loading ? (
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={handleClosePreview}
        >
          <CircularProgress color="#f28181" />
        </Backdrop>
      ) : (
        <Dialog
          open={openText}
          aria-labelledby="customized-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg" //*Default "md"
          style={{ height: "auto", overflowX: "hidden" }}
        >
          <DialogTitle id="customized-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5" className={classes.title}>
                {file.label}
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setOpenText(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ width: 700, maxHeight: 1000 }}>
            <Typography
              className={classes.title333}
              variant="subtitle2"
              style={{
                whiteSpace: "pre-line",
              }}
              ref={inputEl}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default DialogPreviewText;
