import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Button } from "@material-ui/core";

// import bgTouch from "../../../../assets/images/bgTouch.png";

import { title } from "../../Style/styleKIt";
import { navigate } from "hookrouter";

const styles = (theme) => ({
  titlefff: {
    ...title,
    color: "#fff",
    textDecoration: "none",
  },
  btnHero: {
    display: "flex",
    justifyContent: "center",
    // flexDirection: "column",
    // "@media (min-width: 768px)": {
    //   flexDirection: "row",
    // },
  },
  btnFree: {
    zIndex: "20",
    color: "#fff",
    position: "relative",
    padding: "10px 20px",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "capitalize",
    borderRadius: "5px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "7px",
    display: "inline-flex",
    backgroundColor: "#d64253",
    "&:hover,&:focus": {
      background: "rgba(209, 53, 74,.6)",
    },
  },
  btnSales: {
    zIndex: "20",
    color: "#fff",
    position: "relative",
    padding: "10px 20px",
    border: "1px solid #fff",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "capitalize",
    borderRadius: "5px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "7px",
    display: "inline-flex",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      background: "rgba(0, 0, 0, 0.1)",
    },
  },
});

const useStyle = makeStyles(styles);

const TouchUs = () => {
  const classes = useStyle();

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <Box py={15}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h4" className={classes.titlefff}>
          <b>Kembangkan bisnis lebih jauh bersama performate</b>
        </Typography>

        <Typography variant="subtitle1" className={classes.titlefff}>
          Tingkatkan produktifitas, kinerja serta pencapaian karyawan / tim Anda
          mulai saat ini
        </Typography>
      </Box>
      <Box mt={4} className={classes.btnHero}>
        <Button
          variant="outlined"
          className={classes.btnFree}
          onClick={handleTryFree}
        >
          Coba Gratis Sekarang
        </Button>
        <Box mx={0.8} />
        <Button
          variant="outlined"
          className={classes.btnSales}
          onClick={() => navigate("/contact")}
        >
          Hubungi Sales Kami
        </Button>
      </Box>
    </Box>
  );
};

export default TouchUs;
