import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
  fileText: {
    fontFamily: "Roboto",
    fontWeight: "600",
  },
}));

const DialogPreviewAttachment = ({
  openAttachment,
  setOpenAttachment,
  file,
}) => {
  const classes = useStyles();

  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openAttachment) {
      setLoading(true);
      AxiosConfig.get(
        `${URL_API}/todolist/${file.todolist_id}/attachment/${file.id}`,
        {
          responseType: "blob",
        }
      )
        .then((response) => {
          // // console.log("Response Thumbnail", response);

          if (response.status === 200) {
            setImagePreview(response.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoading(false);
        });
    }
  }, [openAttachment]);

  const blobUrlImage = new Blob([imagePreview], { type: file.mime_type });

  const handleClosePreview = () => {
    setLoading(false);
    setOpenAttachment(false);
  };

  return (
    <div>
      {loading ? (
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={handleClosePreview}
        >
          <CircularProgress color="#f28181" />
        </Backdrop>
      ) : (
        <Dialog
          open={openAttachment}
          aria-labelledby="customized-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl" //*Default "md"
          style={{ height: "auto", width: "100%" }}
        >
          <DialogTitle id="customized-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" className={classes.fileText}>
                {file.label}
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setOpenAttachment(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <img
              src={imagePreview !== "" && URL.createObjectURL(blobUrlImage)}
              style={{ maxHeight: 700 }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default DialogPreviewAttachment;
