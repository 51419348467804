import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import CompareArrowIcon from "@material-ui/icons/CompareArrows";
import { navigate } from "hookrouter";

import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";
import ListItemTopGoals from "./ListItemTopGoals";
import ListItemTopMas from "./ListItemTopMas";
import handleError from "../../../../../Report/components/global/handleError";
import DialogError from "../../../../../../components/DialogError";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

const TopGoals = ({
  classes,
  period,
  structureUnitTypeId,
  structureUnitTypeName,
  unitType,
  is_admin,
  is_full_access,
  is_superior,
  divisionData,
  period200,
  unit200,
  divisi200,
}) => {
  const [top, setTop] = useState("goal");
  const [topGoalData, setTopGoalData] = useState([]);
  const [topMaData, setTopMaData] = useState([]);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  let unit_type_id;
  let unit_type_name;
  const units = divisionData.length > 0 ? divisionData.map((el) => el.id) : [];
  const unitName =
    divisionData.length > 0
      ? divisionData.map((el) => el.structure_unit_name)[0]
      : "";

  if (is_admin || is_full_access) {
    unit_type_id = unitType !== undefined ? unitType.id : "";
    unit_type_name = unitType !== undefined ? unitType.name : "";
  }

  if ((is_admin || is_full_access) && !is_superior) {
    unit_type_id = unitType !== undefined ? unitType.id : "";
    unit_type_name = unitType !== undefined ? unitType.name : "";
  }

  if (is_superior && !is_admin && !is_full_access) {
    unit_type_id = "";
    unit_type_name = unitName;
  }

  if (!is_superior && !is_admin && !is_full_access) {
    unit_type_id = structureUnitTypeId;
    unit_type_name = structureUnitTypeName;
  }

  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const dataTopGoal = {
    name: "Bar Chart",
    platform: "web",
    report_format: "table",
    filter_by: {
      entity: [],
      date_range: "all_time",
      user: [],
      unit: is_admin || is_full_access ? [] : units,
      status: [3],
      period_id: period !== undefined ? period.id : "",
      achievement: [
        {
          field: "goal",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
      ],
    },
    entities: ["goal"],
    group_by: ["full_name"],
    group_by_calculation: "avg",
    order_by: [
      {
        field: "goal",
        name: "Goal",
        method: "DESC",
        selected: false,
      },
    ],
    primary_column: "overall_result_achievement",
    unit_type_id:
      !is_admin && !is_full_access && !is_superior
        ? ""
        : unit_type_id !== undefined || unit_type_id !== ""
        ? unit_type_id
        : "",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  const dataTopMa = {
    name: "Table",
    platform: "web",
    report_format: "table",
    filter_by: {
      entity: [],
      date_range: "all_time",
      user: [],
      unit: is_admin || is_full_access ? [] : units,
      status: [3],
      period_id: period !== undefined ? period.id : "",
      achievement: [
        {
          field: "ma",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
      ],
    },
    entities: ["ma"],
    group_by: ["full_name"],
    group_by_calculation: "avg",
    order_by: [
      {
        field: "ma",
        name: "MA",
        method: "DESC",
        selected: false,
      },
    ],
    primary_column: "overall_result_achievement",
    unit_type_id:
      !is_admin && !is_full_access && !is_superior
        ? ""
        : unit_type_id !== undefined || unit_type_id !== ""
        ? unit_type_id
        : "",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  useEffect(() => {
    setLoading(true);

    if (period200 === 200 && unit200 === 200 && divisi200 === 200) {
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=1&per_page=5`,
        dataTopGoal
      )
        .then((res) => {
          const result = res.data.data.results.data;
          // // console.log("RES SUMMARY TOP GOAL", res);

          if (res.status === 200) {
            setTopGoalData(result);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });

      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=1&per_page=5`,
        dataTopMa
      )
        .then((res) => {
          const result = res.data.data.results.data;
          // // console.log("RES SUMMARY TOP MA", res);

          if (res.status === 200) {
            setTopMaData(result);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [period, unit_type_id]);

  const handleTop = () => {
    if (top === "goal") setTop("ma");
    if (top === "ma") setTop("goal");
  };

  const highest = MyLang() ? bahasa.top : english.top;

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={classes.title333} variant="subtitle1">
            <b>
              {top === "goal"
                ? `Goal ${highest}`
                : `Measured Activity ${highest}`}
            </b>
          </Typography>

          <Button
            variant="outlined"
            size="small"
            className={classes.button0}
            endIcon={<CompareArrowIcon style={{ color: "#d64253" }} />}
            onClick={handleTop}
          >
            {top === "goal" ? "MA" : "Goal"}
          </Button>
        </Box>

        {top === "goal" && loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              size={14}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography className={classes.title888} variant="caption">
              {MyLang() ? bahasa.loading : english.loading}...
            </Typography>
          </Box>
        ) : (
          top === "goal" &&
          topGoalData.length > 0 &&
          topGoalData.map((item, i) => (
            <ListItemTopGoals key={i} classes={classes} item={item} />
          ))
        )}

        {top === "ma" && loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              size={14}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography className={classes.title888} variant="caption">
              {MyLang() ? bahasa.loading : english.loading}...
            </Typography>
          </Box>
        ) : (
          top === "ma" &&
          topMaData.length > 0 &&
          topMaData.map((item, i) => (
            <ListItemTopMas key={i} classes={classes} item={item} />
          ))
        )}

        {top === "goal" && !loading && topGoalData.length === 0 && (
          <Typography className={classes.title333} variant="subtitle2">
            {MyLang() ? bahasa.youDontHave : english.youDontHave}
            Goal
          </Typography>
        )}

        {top === "ma" && !loading && topMaData.length === 0 && (
          <Typography className={classes.title333} variant="subtitle2">
            {MyLang() ? bahasa.youDontHave : english.youDontHave}
            Measured Activity
          </Typography>
        )}

        <Box mt={2} display="flex" justifyContent="center">
          <Typography
            className={classes.txtViewMore}
            variant="subtitle2"
            onClick={() => navigate(top === "goal" ? "/goal" : "/all-ma")}
          >
            {MyLang() ? bahasa.viewMore : english.viewMore}
          </Typography>
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default TopGoals;
