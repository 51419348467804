import { Box, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { URL_API } from '../../../constants/config-api';
import AxiosConfig from '../../../constants/config-axios';
import PrerequestAttendance from '../HOC/PrerequestAttendance';
import { styles } from './Style/StyleTeam';

const useStyles = makeStyles(styles);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && <Box style={{ padding: '0px 25px' }}>{children}</Box>}
		</div>
	);
}

function ViewTeam() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(0);
	const [data, setData] = useState([]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const fetchTeam = () => {
		setLoading(true);
		AxiosConfig.get(`${URL_API}/human-resource/team`)
			.then(res => {
				const result = res.data.data;
				console.log('RES team ', result);
				if (res.status === 200) {
					setData(result);
				}
			})
			.catch(error => console.log('Error', error))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchTeam();
	}, []);

	return (
			<div className={classes.root}>
				<Typography variant="h4" align="center" style={{ color: '#262261' }}>
					<b>Team</b>
				</Typography>
				<Box sx={{ width: '100%' }}>
					<Box>
						<Tabs
							style={{ textColor: '#AAB2BD', maxWidth: 500, margin: 'auto', marginBottom: 20 }}
							textColor="primary"
							indicatorColor="primary"
							value={value}
							onChange={handleChange}
							aria-label="team-tab"
							variant="fullWidth"
							TabIndicatorProps={{ style: { borderBottom: `3px solid #262261`, borderRadius: '20px' } }}
						>
							<Tab label="Masuk" />
							<Tab label="Tidak Masuk" />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<div style={{ borderBottom: '2px solid #cfd0d1' }}></div>
						{data
							.filter(item => item.clockin === true)
							.map(item => (
								<div className={classes.listTile}>
									<div className={classes.divPicture}>
										<img
											src={item.photo_url ?? require('../../../assets/images/user-white.png')}
											className={classes.picture}
											alt=""
										/>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
										<Typography variant="h5" style={{ color: '#262261' }}>
											<b>
												{item.first_name} {item.last_name}
											</b>
										</Typography>
										<Typography variant="subtitle1" style={{ color: '#AAB2BD' }}>
											{item.structur_position_title_name}
										</Typography>
									</div>
								</div>
							))}
					</TabPanel>
					<TabPanel value={value} index={1}>
						<div style={{ borderBottom: '2px solid #cfd0d1' }}></div>
						{data
							.filter(item => item.clockin === false)
							.map(item => (
								<div className={classes.listTile}>
									<div className={classes.divPicture}>
										<img
											src={item.photo_url ?? require('../../../assets/images/user-white.png')}
											className={classes.picture}
											alt=""
										/>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
										<Typography variant="h5" style={{ color: '#262261' }}>
											<b>
												{item.first_name} {item.last_name}
											</b>
										</Typography>
										<Typography variant="subtitle1" style={{ color: '#AAB2BD' }}>
											{item.structur_position_title_name}
										</Typography>
									</div>
								</div>
							))}
					</TabPanel>
				</Box>
			</div>
	);
}

export default ViewTeam;
