import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  Fragment,
  useRef,
} from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Button,
  IconButton,
  InputBase,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Chip,
} from "@material-ui/core";

import axiosConfig from "../../../../constants/config-axios";
import { useDebouncedCallback } from "use-debounce";

import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ContextGoal from "../Context/ContextGoal";

// import ListStatusGoalDraft from "../ComponentsStatusGoal/Draft/ListStatusGoalDraft";
import ListStatusGoalApproved from "../ComponentsStatusGoal/Approved/ListStatusGoalApproved";
import ListStatusGoalActive from "../ComponentsStatusGoal/Active/ListStatusGoalActive";
import ListStatusGoalOverdue from "../ComponentsStatusGoal/Overdue/ListStatusGoalOverdue";
import ListStatusGoalComplete from "../ComponentsStatusGoal/Complete/ListStatusGoalComplete";

import DialogBiggerPictInAllAndYou from "../Components/DialogBiggerPictInAllAndYou";
import DialogExportGoal from "../Components/DialogExportGoal";

import Snackbeer from "../../../../components/Snackbeer";

import { styles } from "../Style/StyleGoal";
import DialogError from "../../../../components/DialogError";
import { URL_API } from "../../../../constants/config-api";
import {
  // STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_ACTIVE,
  STATUS_OVERDUE,
  STATUS_COMPLETED,
} from "../../../../constants/config-status-goal";

import PictREFRESH from "../../../../assets/images/Group_2725.png";

import "react-circular-progressbar/dist/styles.css";
import "../Style/custom.css";
import DialogSetWeightGoal from "../You/components/DialogSetWeightGoal2";
import AnchorFilterGoal from "./AnchorFilterGoal";
import DialogCreateGoal from "../Components/DialogCreateGoal";
import PaginationGoalv2 from "../Components/PaginationGoalv2";

const useStyle = makeStyles(styles);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#282560",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const ViewAll = (props) => {
  const context = useContext(ContextGoal);
  const classes = useStyle();

  let inputRefSearch = useRef(null);

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  //*
  const userToken = localStorage.getItem("userToken");
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  const [userTokenState, setUserTokenState] = useState("");

  // Anchor BTN GOAL
  const [anchorAddGoal, setAnchorAddGoal] = useState(null);
  // Weight Goal
  const [openDialogSetWeightGoal, setOpenDialogSetWeightGoal] = useState(false);

  const [periodCollection, setPeriodCollection] = useState([]);
  const [periodData, setPeriodData] = useState(null);
  const [weightValue, setWeightValue] = useState([0, 100]);
  const [category, setCategory] = useState({
    operational: true,
    project: false,
  });
  const [type, setType] = useState({
    team: false,
    personal: false,
  });

  const [tabVal, setTabVal] = useState(1);

  // open create goal
  const [openCreateGoal, setOpenCreateGoal] = useState(false);

  useEffect(() => {
    if (tabVal === 0) {
      context.setStatusGoalId(2); //*Approved
      localStorage.setItem("status_goal_id", "2");
    }

    if (tabVal === 1) {
      context.setStatusGoalId(3); //*Active
      localStorage.setItem("status_goal_id", "3");
    }

    if (tabVal === 2) {
      context.setStatusGoalId(6); //*Overdue
      localStorage.setItem("status_goal_id", "6");
    }

    if (tabVal === 3) {
      context.setStatusGoalId(4); //*Completed
      localStorage.setItem("status_goal_id", "4");
    }
  }, [tabVal]);

  useState(() => {
    const valueSearch = localStorage.getItem("value_search");

    context.setValueSearch(valueSearch);

    axiosConfig
      .get(`${URL_API}/swd/master-period`)
      .then((res) => {
        setPeriodCollection(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /*
        ```````````````````````````
        HANDLE REFRES LIST ALL GOAL
        
        ```````````````````````````
    */
  const handleRefresh = () => {
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  /*
        ````````````
        Search  GOAL

        ````````````
    */

  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      localStorage.setItem("value_search", value);
      context.setValueSearch(value);
      context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
    }, []),
    1000,

    { maxWait: 5000 }
  );

  /*
        ````````````````````````````
        HANDLE BIGGER PICT IN DETAIL
        
        ````````````````````````````
    */
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [listPictState, setListPictState] = useState([]);

  /*
        ``````````````
        HANDLE NEW TAB

        ``````````````
    */
  // const [valueTab, setValueTab] = useState(2);
  const handleChangeTab = (event, newValue) => {
    context.setValueTab(newValue);
    setTabVal(newValue);
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));

    if (newValue === 0) {
      context.setStatusGoalId(2); //*Approved
      localStorage.setItem("status_goal_id", "2");
    }

    if (newValue === 1) {
      context.setStatusGoalId(3); //*Active
      localStorage.setItem("status_goal_id", "3");
    }

    if (newValue === 2) {
      context.setStatusGoalId(6); //*Overdue
      localStorage.setItem("status_goal_id", "6");
    }

    if (newValue === 3) {
      context.setStatusGoalId(4); //*Completed
      localStorage.setItem("status_goal_id", "4");
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  /**
   ==================================================================================
    Show Goal Counter Status
   ==================================================================================
   */
  const [goalCounter, setGoalCounter] = useState([]);

  useEffect(() => {
    axiosConfig
      .get(`${URL_API}/swd/goal/status-count`)
      .then((response) => {
        const result = response.data.data;

        if (response.status === 200) {
          const filterDraft = result.filter((item) => item.id !== "1");
          setGoalCounter(filterDraft);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });
  }, [
    context.isOpenSnackbeer,
    context.successComplete,
    context.successCreateGoal,
    context.triggerGoals,
  ]);

  /*
        ````````````````````
        HANDLE DIALOG ERRROR

        ````````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  // ==============================================
  // BUTTON GOAL
  // ==============================================
  const handleShowAnchorGoal = (event) => {
    setAnchorAddGoal(event.currentTarget);
  };

  const handleCloseAnchorGoal = (event) => {
    setAnchorAddGoal(null);
  };

  const handleRemovePeriod = () => {
    setPeriodData(null);
    context.setPeriod({ id: "", name: "" });
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handleRemoveWeight = () => {
    setWeightValue([0, 100]);
    context.setWeightValueAll({ min: 0, max: 100 });
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handleRemoveCategory = () => {
    setCategory({ operational: false, project: false });
    setType({ personal: false, team: false });
    context.setGoalCategory("");
    context.setProjectType("");
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  return (
    <Fragment>
      <Box
        bgcolor="#fafafa"
        position="fixed"
        zIndex={100}
        width="100%"
        mt={6}
        pt={3}
        pb={1.2}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr={9}
          pl={2}
        >
          <StyledTabs value={context.valueTab} onChange={handleChangeTab}>
            {listTabs.length > 0 &&
              listTabs.map((item, i) => {
                let counter = 0;

                if (goalCounter.length > 0) {
                  if (goalCounter[i].code === item.code) {
                    counter = goalCounter[i].count;
                  }
                }

                return (
                  <Tab
                    key={i}
                    classes={{ root: classes.widthTab }}
                    label={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{
                          textTransform: "capitalize",
                          marginBottom: -10,
                        }}
                      >
                        {item.label}
                        <span
                          style={{
                            color: "#1D80DB",
                            backgroundColor: "#D7E4F7",
                            paddingLeft: 4,
                            paddingRight: 4,
                            fontSize: 14,
                            marginLeft: 5,
                            borderRadius: 5,
                          }}
                        >
                          {counter}
                        </span>
                      </Typography>
                    }
                    {...a11yProps(i)}
                  />
                );
              })}
          </StyledTabs>

          <Box>
            {context.statusGoalId === 3 && ( //*Default Aktif === 3
              <>
                {superior && (
                  <Button
                    onClick={handleShowAnchorGoal}
                    variant="outlined"
                    className={classes.button}
                    // disabled={disableCreateGoal}
                    endIcon={<ArrowDropDownIcon />}
                    style={{ borderRadius: 10 }}
                  >
                    Buat Goal
                  </Button>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorAddGoal}
                  keepMounted
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  getContentAnchorEl={null}
                  open={Boolean(anchorAddGoal)}
                  onClose={handleCloseAnchorGoal}
                >
                  <MenuItem
                    onClick={() => {
                      setOpenCreateGoal(true);
                      handleCloseAnchorGoal();
                    }}
                    style={{ width: 150 }}
                  >
                    <Typography variant="subtitle2" className={classes.title}>
                      Buat Goal Baru
                    </Typography>
                  </MenuItem>
                  {superior && (
                    <MenuItem
                      disabled={
                        context.statusGoalId === 1 || context.statusGoalId === 2
                      }
                      onClick={() => {
                        setOpenDialogSetWeightGoal(true);
                        handleCloseAnchorGoal();
                      }}
                    >
                      <Typography variant="subtitle2" className={classes.title}>
                        Atur Bobot Goal
                      </Typography>
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
            {context.statusGoalId === 2 && ( //*Default Approved === 2
              <Button
                onClick={() => context.setModalCreate(true)}
                variant="outlined"
                className={classes.button}
                // disabled={disableCreateGoal}
              >
                Buat Goal baru
              </Button>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr={9}
          pl={3}
          mt={3}
        >
          <Box display="flex" alignItems="center">
            <div className={classes.search}>
              <InputBase
                startAdornment={
                  <SearchIcon
                    style={{ color: "#C4C4C4", fontSize: 18, marginLeft: 12 }}
                  />
                }
                endAdornment={
                  <IconButton
                    size="small"
                    onClick={() => {
                      context.setTriggerGoals(
                        Math.floor(Math.random() * 1000 + 1)
                      );
                      context.setValueSearch("");
                      context.setSearchRun(false);
                      context.setClearSearchResult(true);
                      localStorage.removeItem("value_search");

                      setTimeout(() => {
                        inputRefSearch.current.value = "";
                      }, 200);
                    }}
                  >
                    <CancelIcon
                      style={{
                        color: "#C4C4C4",
                        fontSize: 18,
                        marginRight: 12,
                      }}
                    />
                  </IconButton>
                }
                onChange={(e) => handleSearch(e.target.value)}
                inputRef={inputRefSearch}
                placeholder={
                  context.valueSearch !== ""
                    ? context.valueSearch
                    : "Search Goal..."
                }
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "Search" }}
              />
            </div>

            {/* <Button
              size="small"
              variant="outlined"
              className={classes.btnFilter}
              endIcon={<ExpandMoreIcon style={{ color: "#8F8F8F" }} />}
              startIcon={<FilterListIcon style={{ color: "#8F8F8F" }} />}
            >
              Sort by
            </Button>
            <Box mx={1} /> */}
            <AnchorFilterGoal
              classes={classes}
              periodCollection={periodCollection}
              onPeriodData={setPeriodData}
              weightValue={weightValue}
              onWeightValue={setWeightValue}
              type={type}
              setType={setType}
              category={category}
              setCategory={setCategory}
            />
            <Box mx={1} />
            {(category.project === true || category.operational === true) && (
              <Chip
                variant="outlined"
                size="small"
                className={classes.chipFilterSort}
                label={
                  <Typography variant="caption">
                    {category.project && category.operational
                      ? `Operational & Project (${
                          type.team && type.personal
                            ? "Team & Personal"
                            : type.team && !type.personal
                            ? "Team"
                            : !type.team && type.personal
                            ? "Personal"
                            : ""
                        })`
                      : category.project && !category.operational
                      ? `Project (${
                          type.team && type.personal
                            ? "Team & Personal"
                            : type.team && !type.personal
                            ? "Team"
                            : !type.team && type.personal
                            ? "Personal"
                            : ""
                        })`
                      : "Operational"}
                  </Typography>
                }
                onDelete={handleRemoveCategory}
              />
            )}
            {context.period?.id !== "" && (
              <Chip
                variant="outlined"
                size="small"
                className={classes.chipFilterSort}
                label={
                  <Typography variant="caption">{periodData?.name}</Typography>
                }
                onDelete={handleRemovePeriod}
                deleteIcon={<CancelIcon style={{ color: "#fff" }} />}
                style={{ marginLeft: 8 }}
              />
            )}
            {(weightValue[0] > 0 || weightValue[1] < 100) && (
              <Chip
                variant="outlined"
                size="small"
                className={classes.chipFilterSort}
                label={
                  <Typography variant="caption">{`${weightValue[0]} - ${weightValue[1]}`}</Typography>
                }
                onDelete={handleRemoveWeight}
                deleteIcon={<CancelIcon style={{ color: "#fff" }} />}
                style={{ marginLeft: 8 }}
              />
            )}
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton style={{ padding: 4 }} onClick={handleRefresh}>
              <img
                src={PictREFRESH}
                style={{ width: 17, height: 17 }}
                alt="."
              />
            </IconButton>
            &nbsp;&nbsp;
            <Button
              onClick={() => context.setModalExportGoal(true)}
              variant="outlined"
              className={classes.btnFilter}
              style={{ borderWidth: 1 }}
            >
              Export CSV
            </Button>
          </Box>
        </Box>
      </Box>

      <Box pt={20} bgcolor="#fafafa">
        <TabPanel value={context.valueTab} index={0}>
          <ListStatusGoalApproved mt={40} />
        </TabPanel>
        <TabPanel value={context.valueTab} index={1}>
          <ListStatusGoalActive mt={40} />
        </TabPanel>
        <TabPanel value={context.valueTab} index={2}>
          <ListStatusGoalOverdue mt={40} />
        </TabPanel>
        <TabPanel value={context.valueTab} index={3}>
          <ListStatusGoalComplete mt={40} />
        </TabPanel>
      </Box>

      <Box ml={4} mr={1}>
        <PaginationGoalv2 classes={classes} />
      </Box>

      <DialogBiggerPictInAllAndYou
        isOpenDialog={isOpenDialog}
        setOpenDialog={setOpenDialog}
        listPictState={listPictState}
        userToken={userTokenState}
      />

      <DialogExportGoal
        isModalExportGoal={context.isModalExportGoal}
        setModalExportGoal={context.setModalExportGoal}
        statusGoalId={context.statusGoalId}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        isModalResponse200={context.isOpenSnackbeer}
        setModalResponse200={context.setOpenSnackbeer}
        messages={context.messages}
      />

      <DialogSetWeightGoal
        classes={classes}
        openDialogSetWeightGoal={openDialogSetWeightGoal}
        setOpenDialogSetWeightGoal={setOpenDialogSetWeightGoal}
      />

      <DialogCreateGoal
        classes={classes}
        openCreateGoal={openCreateGoal}
        setOpenCreateGoal={setOpenCreateGoal}
      />
    </Fragment>
  );
};

export default ViewAll;

const listTabs = [
  {
    id: 2,
    label: "Approved",
    code: STATUS_APPROVED,
    status_id: 2,
  },

  {
    id: 3,
    label: "Active",
    code: STATUS_ACTIVE,
    status_id: 3,
  },

  {
    id: 4,
    label: "Overdue",
    code: STATUS_OVERDUE,
    status_id: 6,
  },

  {
    id: 5,
    label: "Complete",
    code: STATUS_COMPLETED,
    status_id: 4,
  },
];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
