import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Menu,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";

import ListTimeRange from "./ListTimeRange";
import ListItemRemindInWeek from "./ListItemRemindInWeek";

const FreqHourly = ({
  classes,
  goalDetail,
  onHourlyFreq,
  hourlyFreq,
  remindList,
  dayList,
  timeList,
  timeErrMessage,
  autoInsert,
  onTrigger,
}) => {
  // =========================================================
  // Set Day Exist
  const defaultDays = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
  };
  const days =
    dayList?.length > 0 &&
    dayList?.reduce((a, v) => ({ ...a, [v.day]: v.selected }), {});

  const is_locked =
    goalDetail?.update_frequency?.result_locked === null
      ? false
      : goalDetail?.update_frequency?.result_locked === 0
      ? false
      : true;

  const auto_insert = autoInsert === "1" ? true : false;

  const [minute, setMinute] = useState("00");
  const [selectedTimes, setSelectedTimes] = useState([]);

  const [locking, setLocking] = useState(is_locked);
  const [autoInsertResult, setAutoInsertResult] = useState(auto_insert);
  const [reminder, setReminder] = useState(false);
  const [remindMe, setRemindMe] = useState({
    id: "",
    name: "",
    selected: false,
    self: {
      rel: null,
    },
  });
  const [daily, setDaily] = useState(defaultDays);
  const [anchorRemind, setAnchorRemind] = useState(null);

  useEffect(() => {
    setDaily(days);
  }, [dayList]);

  useEffect(() => {
    setSelectedTimes(timeList);
  }, [dayList]);

  useEffect(() => {
    const configTimes = selectedTimes?.map((time) => {
      return {
        start: moment(time.start).format("HH:mm"),
        finish: moment(time.finish).format("HH:mm"),
      };
    });

    const getDayTrue = Object.entries(daily).filter(
      ([key, value]) => value === true
    );
    const configDays =
      getDayTrue.length > 0
        ? getDayTrue.map((day) => {
            return day[0];
          })
        : [];

    onHourlyFreq({
      ...hourlyFreq,
      scheduled_at: minute,
      result_locked: locking ? 1 : 0,
      is_locked: locking,
      auto_insert_result: autoInsertResult ? "1" : "0",
      reminder: remindMe.id,
      hourly_details: {
        selected_days: configDays,
        selected_times: configTimes,
      },
    });
  }, [minute, selectedTimes, remindMe, daily, locking, autoInsertResult]);

  const handleShowAnchorRemind = (e) => {
    setAnchorRemind(e.currentTarget);
  };
  const handleCloseAnchorRemind = () => {
    setAnchorRemind(null);
  };

  const handleSelectedTimes = (index, name, value) => {
    const values = [...selectedTimes];

    values[index][name] = value;
    setSelectedTimes(values);

    // // console.log("values", values);
  };

  const handleAddTime = () => {
    const lastTime = selectedTimes[selectedTimes?.length - 1];
    const lastStart = lastTime?.start;
    const lastFinish = lastTime?.finish;

    const dif = moment(lastFinish).diff(moment(lastStart), "hours");

    const start = moment(lastFinish).add(1, "hours").format("HH:00");
    const finish = moment(lastFinish)
      .add(dif + 1, "hours")
      .format("HH:00");

    // // console.log("SART", moment(lastStart).add(1, "hours").format("hh:00"));
    // // console.log("dif", dif);
    // // console.log("lastStart", lastStart);
    // // console.log("selectedTimes", selectedTimes);

    const addTime = {
      start: moment(start, [moment.ISO_8601, "HH:mm"]),
      finish: moment(finish, [moment.ISO_8601, "HH:mm"]),
    };

    setSelectedTimes([...selectedTimes, addTime]);
  };

  const handleRemoveTime = (index) => {
    const times = [...selectedTimes].filter((el, i) => i !== index);
    setSelectedTimes(times);
  };

  const handleLocking = (e) => {
    setLocking(e.target.checked);
  };

  const handleReminder = (e) => {
    setReminder(e.target.checked);
  };

  const handleAutoInsert = (e) => {
    setAutoInsertResult(e.target.checked);
  };

  const handleRemind = (data) => {
    setRemindMe(data);
    handleCloseAnchorRemind();
  };

  const handleDaily = (e) => {
    setDaily({ ...daily, [e.target.name]: e.target.checked });
  };

  return (
    <>
      <Box display="flex" mt={4}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Time range</b>
        </Typography>

        <Box ml={11}>
          {selectedTimes?.map((item, i) => {
            const lastIdx = selectedTimes.length - 1;

            return (
              <ListTimeRange
                key={i}
                classes={classes}
                item={item}
                index={i}
                lastIdx={lastIdx}
                onSelectedTimes={handleSelectedTimes}
                onAddTime={handleAddTime}
                onRemoveTime={handleRemoveTime}
              />
            );
          })}

          <Box>
            <Typography
              variant="caption"
              style={{ color: "#d1354a", fontFamily: "Lato" }}
            >
              {timeErrMessage}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Schedule at</b>
        </Typography>

        <Box ml={9} display="flex" flexWrap="wrap" width={400}>
          {dayList?.length > 0 &&
            dayList?.map((day, i) => {
              // const hadnleChec = () => {}

              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={daily[`${day.day}`]}
                      onChange={handleDaily}
                      name={day.day}
                      className={classes.checkDaily}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{
                        backgroundColor: daily[`${day.day}`] && "#282560",
                        color: daily[`${day.day}`] ? "#fff" : "#888",
                        border: !daily[`${day.day}`] && "1px solid #888",
                        borderRadius: 50,
                        padding: "3px 10px",
                        marginBottom: 7.5,
                        marginRight: 2.5,
                      }}
                    >
                      {day.day_name}
                    </Typography>
                  }
                />
              );
            })}
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Locking</b>
        </Typography>

        <Box ml={10}>
          <Switch checked={locking} onChange={handleLocking} color="primary" />
        </Box>

        {!locking && (
          <Typography variant="caption" className={classes.title}>
            <i>No update is allowed after schduled update is passed</i>
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Auto Insert</b>
        </Typography>

        <Box ml={7}>
          <Switch
            checked={autoInsertResult}
            onChange={handleAutoInsert}
            color="primary"
          />
        </Box>

        {!autoInsertResult && (
          <Typography variant="caption" className={classes.title}>
            <i>
              When user not inserted the result before frequency time, system
              will automatically insert result to 0.
            </i>
          </Typography>
        )}
      </Box>

      {/* <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Add Reminder</b>
        </Typography>

        <Box ml={7}>
          <Switch
            checked={reminder}
            onChange={handleReminder}
            color="primary"
          />
        </Box>
      </Box> */}

      {reminder && (
        <Box display="flex" alignItems="center" mt={4}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>Reminder me</b>
          </Typography>

          <Box ml={9}>
            <Button
              variant="outlined"
              size="small"
              className={classes.actionBtn}
              endIcon={<ArrowDropDownIcon style={{ fontSize: 25 }} />}
              onClick={handleShowAnchorRemind}
            >
              {remindMe.name}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorRemind}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorRemind)}
              onClose={handleCloseAnchorRemind}
            >
              {remindList.length > 0 &&
                remindList.map((item, i) => {
                  const isCurrent = item.name === remindMe.name;

                  return (
                    <ListItemRemindInWeek
                      key={i}
                      classes={classes}
                      isCurrent={isCurrent}
                      remind={remindMe}
                      item={item}
                      handleRemind={handleRemind}
                    />
                  );
                })}
            </Menu>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FreqHourly;
