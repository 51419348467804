import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleTimeOff";

import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  Paper,
  TableBody,
  LinearProgress,
  TextField,
  InputAdornment,
  IconButton,
  TableRow,
  TableCell,
  ListItemText,
  TableFooter,
  TablePagination,
  CircularProgress,
} from "@material-ui/core";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import ContextTimeOff from "./Context/ContextTimeOff";
import EnhancedTableToolbar from "./Components/EnhancedTableToolbar";
import TableItem from "./Components/TableItem";
import TimeOffListMenu from "./Components/TimeOffListMenu";
import { Table } from "react-bootstrap";
import EnhancedTableHead from "./Components/EnhancedTableHead";
import TableFilter from "./Components/TableFilter";
import moment from "moment";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import DialogProgress from "./Components/DialogProgress";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";
import DialogDetail from "./Components/DialogDetail";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";
import ListCardItemTimeOff from "./Components/ListCardItemTimeOff";
import PaginationTimeOff from "./Components/PaginationTimeOff";
import DialogDefaultNotFound from "./Components/DialogDefaultNotFound";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewTimeOff = () => {
  const classes = useStyles();
  const context = useContext(ContextTimeOff);
  const [dataAttendanceDetail, setDataAttendanceDetail] = useState({
    attendance_member_id: "",
    clock_in: "",
    clock_in_coordinates_lat: "",
    clock_in_coordinates_long: "",
    clock_in_photo_path: "",
    clock_out: "",
    clock_out_coordinates_lat: "",
    clock_out_coordinates_long: "",
    clock_out_photo_path: "",
    created_at: "",
    created_by: "",
    deleted_at: "",
    deleted_by: "",
    id: "",
    member: {},
    parent_id: "",
    schedule: {},
    schedule_id: "",
    status_approve: "",
    status_attendance: "",
    updated_at: "",
    updated_by: "",
    workday: "",
  });
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const [roleName, setRoleName] = useState([]);
  const [userID, setUserID] = useState([]);

  useEffect(() => {
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      // setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);
      setUserID(statusUserLoginAfterParse.user_id);
      if (statusUserLoginAfterParse.member_position && statusUserLoginAfterParse.member_position.is_superior) {
        setRoleName('manage');
      }

      if (statusUserLoginAfterParse.userRole.length > 0) {
        if (statusUserLoginAfterParse.userRole[0].name === "HR") {
          setRoleName('hr');
        }
        if (statusUserLoginAfterParse.userRole[0].name === "superadmin") {
          setRoleName(statusUserLoginAfterParse.userRole[0].name);
        }
        if (statusUserLoginAfterParse.userRole.length > 1) {
          if (statusUserLoginAfterParse.userRole[1].name === "admin") {
            setRoleName(statusUserLoginAfterParse.userRole[1].name);
          }
        }
      } else {
        // console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
      }
    }
    checkTimeOffDefault();
  }, []);

  const [masterCategory, setMasterCategory] = useState({
    masterStatusCollections: [
      {
        id: "0",
        name: "Rejected",
      },
      {
        id: "1",
        name: "Waiting Approve by SPV",
      },
      {
        id: "2",
        name: "Waiting Approve by HR",
      },
      {
        id: "99",
        name: "Approved",
      },
    ],
  });

  // Request Time Off
  const [dataRequestTimeOff, setDataRequestTimeOff] = useState({
    id: "",
    category_id: "",
    schedule_in: "",
    schedule_out: "",
    delegation_user_id: "",
    delegation_user: {},
    is_delegation: "",
    status_approve: "",
    description: "",
    created_by: "",
    updated_by: "",
    deleted_by: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    user_id: "",
    file: "",
    file_url: "",
    status_approve_name: "",
    user: {},
    leave_category: {},
  });

  // Request Time Off List
  const [dataRequestTimeOffList, setDataRequestTimeOffList] = useState({
    options: {
      filter: {
        search: "",
        status: "",
        status_obj: null,
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 1,
      },
    },
    list: [],
  });

  const getDataRequestTimeOffList = () => {
    context.setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/request-timeoff/list` +
          `?options[filter][search]=${dataRequestTimeOffList.options.filter.search}` +
          `&options[filter][status]=${dataRequestTimeOffList.options.filter.status}` +
          `&options[paging][limit]=${dataRequestTimeOffList.options.paging.limit}` +
          `&options[paging][offset]=${dataRequestTimeOffList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        context.setLoader(false);
        let dataRequestTimeOffListTemp = dataRequestTimeOffList;
        dataRequestTimeOffListTemp.list = response.data.data;
        dataRequestTimeOffListTemp.options.paging.count =
          response.data.info.count;
        setDataRequestTimeOffList({ ...dataRequestTimeOffListTemp });
      })
      .catch(function (error) {
        context.setLoader(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataRequestTimeOffList.options.paging.limit * (newPage - 1),
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 1, parseInt(event.target.value));
  };

  const refreshList = (search, offset, page, limit) => {
    let dataRequestTimeOffListTemporary = dataRequestTimeOffList;
    dataRequestTimeOffListTemporary.options.filter.search =
      search !== null
        ? search
        : dataRequestTimeOffListTemporary.options.filter.search;
    dataRequestTimeOffListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataRequestTimeOffListTemporary.options.paging.offset;
    dataRequestTimeOffListTemporary.options.paging.page =
      page !== null
        ? page
        : dataRequestTimeOffListTemporary.options.paging.page;
    dataRequestTimeOffListTemporary.options.paging.limit =
      limit !== null
        ? limit
        : dataRequestTimeOffListTemporary.options.paging.limit;
    setDataRequestTimeOffList({ ...dataRequestTimeOffListTemporary });
    getDataRequestTimeOffList();
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleApprove = (item) => {
    context.setModalProgress(true);
    axiosConfig
      .post(`${URL_API}/human-resource/request-timeoff/${item.id}/approve`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setAnchorEl(null);
        setAnchorIn(null);
        context.setModalProgress(false);
        context.setMessages("Approve berhasil");
        context.setSnackbarResponse200(true);
        refreshList(null, 0, 1, null);
      })
      .catch(function (error) {
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.message
          ) {
            context.setErrorStatus(error.response.data.info.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleReject = (item) => {
    context.setModalProgress(true);
    axiosConfig
      .post(`${URL_API}/human-resource/request-timeoff/${item.id}/reject`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setAnchorEl(null);
        setAnchorIn(null);
        context.setModalProgress(false);
        context.setMessages("Reject berhasil");
        context.setSnackbarResponse200(true);
        refreshList(null, 0, 1, null);
      })
      .catch(function (error) {
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.message
          ) {
            context.setErrorStatus(error.response.data.info.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const showDetail = (item) => {
    let dataTemp = {
      id: item.id,
      category_id: item.category_id,
      schedule_in: item.schedule_in,
      schedule_out: item.schedule_out,
      delegation_user_id: item.delegation_user_id,
      delegation_user: item.delegation_user,
      is_delegation: item.is_delegation,
      status_approve: item.status_approve,
      description: item.description,
      created_by: item.created_by,
      updated_by: item.updated_by,
      deleted_by: item.deleted_by,
      created_at: item.created_at,
      updated_at: item.updated_at,
      deleted_at: item.deleted_at,
      user_id: item.user_id,
      file: item.file,
      file_url: item.file_url,
      status_approve_name: item.status_approve_name,
      self: item.self,
      user: item.user,
      leave_category: item.leave_category,
    };
    setDataRequestTimeOff({ ...dataTemp });
    context.setModalDetail(true);
  };

  const checkTimeOffDefault = () => {
    context.setModalProgress(true);
    context.setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/timeoff-category` +
          `?options[filter][search]=` +
          `&options[filter][type]=default` +
          `&options[paging][limit]=1` +
          `&options[paging][offset]=0`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        context.setLoader(false);
        context.setModalProgress(false);
        let totalDefault = response.data.info.count;
        if (totalDefault > 0) {
          getDataRequestTimeOffList();
        } else {
          context.setModalDefaultNotFound(true);
        }
      })
      .catch(function (error) {
        context.setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        {roleName === "superadmin" && (
          <TimeOffListMenu classes={classes} />
        )}

        <TableFilter
          classes={classes}
          masterCategory={masterCategory}
          dataRequestTimeOffList={dataRequestTimeOffList}
          setDataRequestTimeOffList={setDataRequestTimeOffList}
          getDataRequestTimeOffList={getDataRequestTimeOffList}
        />
        <EnhancedTableHead classes={classes} />

        {context.loader ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={15}
          >
            <CircularProgress
              size={20}
              style={{ marginRight: 10, color: "#d64253" }}
            />
            <Typography className={classes.title555} variant="subtitle1">
              Loading...
            </Typography>
          </Box>
        ) : (
          <Table
            className={classes.table}
            aria-label="enhanced table"
            style={{ marginTop: "16px", paddingRight: "24px" }}
          >
            <TableBody mt={2}>
              {dataRequestTimeOffList &&
                dataRequestTimeOffList.list &&
                dataRequestTimeOffList.list.length > 0 &&
                dataRequestTimeOffList.list.map((item, i) => {
                  return (
                    <ListCardItemTimeOff
                      keyItem={i}
                      key={i}
                      classes={classes}
                      item={item}
                      dataRequestTimeOffList={dataRequestTimeOffList}
                      handleApprove={handleApprove}
                      handleReject={handleReject}
                      handleShowModalDetail={showDetail}
                      userTokenState={context.userTokenStateGlobal}
                      roleName={roleName}
                      userID={userID}
                    />
                  );
                })}
            </TableBody>
          </Table>
        )}

        {dataRequestTimeOffList.list.length === 0 && context.loader !== true && (
          <Box width="100%" style={{ paddingRight: 24 }}>
            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                <IconButton disabled>
                  <EventBusyIcon style={{ color: "#d64253" }} />
                </IconButton>
                <b>
                  Tidak ada Request Time Off yang dapat di tampilkan saat ini ;(
                </b>
              </Typography>
            </Paper>
          </Box>
        )}

        <Box ml={1} mr={2}>
          <PaginationTimeOff
            classes={classes}
            dataRequestTimeOffList={dataRequestTimeOffList}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <DialogDetail
        classes={classes}
        isModalDetail={context.isModalDetail}
        setModalDetail={context.setModalDetail}
        dataRequestTimeOff={dataRequestTimeOff}
      />
      <DialogDefaultNotFound
        classes={classes}
        isModalDefaultNotFound={context.isModalDefaultNotFound}
        setModalDefaultNotFound={context.setModalDefaultNotFound}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
    </MuiThemeProvider>
  );
};

export default ViewTimeOff;
