import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleHistoryCheckInOut";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

import ContextHistoryCheckInOut from "./Context/ContextHistoryCheckInOut";

import { isAuthenticated } from "../../MyAttendance/Hook/isAuthenticated";
import FilterDate from "./Components/FilterDate";
import HistoryRow from "./Components/HistoryRow";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ViewHistoryCheckInOut = () => {
  const classes = useStyles();
  const context = useContext(ContextHistoryCheckInOut);

  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [isCheckIn, setIsCheckIn] = useState(true);
  const [data, setData] = useState([]);
  const [dataRaw, setDataRaw] = useState([]);
  const [verificationMethod, setVerificationMethod] = useState([]);

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      fetchHistory();
      getVerificationMethod();
    } /* eslint-disable-line */,
    [date]
  );

  useEffect(
    () => {
      filterCheckIn();
    } /* eslint-disable-line */,
    [date, isCheckIn, dataRaw]
  );

  const getVerificationMethod = async () => {
    try {
      const { data } = await axiosConfig
        .get(`${URL_API}/human-resource/attendance/verification-method`)
        .then((res) => {
          return res.data;
        });
      setVerificationMethod(data);
    } catch (error) {
      console.log(error);
    }
  };

  const filterCheckIn = () => {
    let dataArr = [...dataRaw];
    dataArr = dataArr.map((date, index) => {
      let log = date.log.filter((item) => {
        return isCheckIn ? item.clock_in != null : item.clock_out != null;
      });
      // untuk memfilter check in atau check out
      return { ...date, log };
    });
    setData(dataArr);
  };

  const fetchHistory = async () => {
    let response;
    try {
      setLoader(true);
      response = await axiosConfig.get(
        `${URL_API}/human-resource/attendance/history/check-in`,
        {
          params: {
            "options[filter][search]": date,
          },
        }
      );
      setData(response.data.data);
      setDataRaw(response.data.data);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoader(false);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", color: "#262261" }}
          >
            History Check In &amp; Out
          </Typography>
        </div>

        <FilterDate date={date} setDate={setDate} />

        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 20,
            textAlign: "center",
          }}
        >
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="history check in out table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    <Select
                      value={isCheckIn}
                      style={{
                        fontWeight: "bold",
                        color: "#221E5B",
                        fontSize: "1rem",
                      }}
                      onChange={(e) => setIsCheckIn(e.target.value)}
                      disableUnderline
                    >
                      <MenuItem value={true}>Check In</MenuItem>
                      <MenuItem value={false}>Check Out</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Lokasi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((date) =>
                  date?.log.map((row, indexRow) => (
                    <HistoryRow
                      key={indexRow}
                      row={row}
                      isCheckIn={isCheckIn}
                      verificationMethod={verificationMethod}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default ViewHistoryCheckInOut;
