export const FORMAT_TYPE_VIDEO = "61af6f20-387e-419a-af53-cdae41629031";
export const FORMAT_TYPE_IMAGE = "1637950c-ca0e-4f52-ab74-a3e0ff1c22a3";
export const FORMAT_TYPE_DOC = "761627aa-8693-4669-97e4-8370d65cfab3";
export const FORMAT_TYPE_AUDIO = "c87d665e-005a-4419-a6d2-1fa9a620c78e";

export const FORMAT_TYPE_VIDEO_IN_MA_RESULT =
  "f257a364-dba0-4e1e-b72e-22ca08be737c";
export const FORMAT_TYPE_IMAGE_IN_MA_RESULT =
  "a2e6fc0d-ce3d-4c7f-bdc1-a779dd521459";
export const FORMAT_TYPE_DOC_IN_MA_RESULT =
  "042c78ff-230b-4b30-92cf-914a3c20c0ce";
export const FORMAT_TYPE_AUDIO_IN_MA_RESULT =
  "c87d665e-005a-4419-a6d2-1fa9a620c78e";
