import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography } from "@material-ui/core";

import moment from "moment";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import OverallProgress from "./overall";
import YourGoal from "./your-goal";
import TodoList from "./todo-list";
import BarChartDashboard from "./bar-chart";
import TopGoals from "./top-goals";
import PieChart from "./pie-chart";
import { navigate } from "hookrouter";
import MyLang from "../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../constants/config-lang";

const Company = ({ classes }) => {
  // =======================================================================
  // ~~~~~~~ VARS ~~~~~~~
  // =======================================================================
  const getUserLogin = localStorage.getItem("status_user_login");
  const statusUserLogin = JSON.parse(getUserLogin);
  const structureUnitTypeId =
    statusUserLogin.member_position !== null &&
    statusUserLogin.member_position.structure_unit_type_id !== null
      ? statusUserLogin.member_position.structure_unit_type_id
      : "";
  const structureUnitId =
    statusUserLogin.member_position !== null &&
    statusUserLogin.member_position.structure_unit_id !== null
      ? statusUserLogin.member_position.structure_unit_id
      : "";
  const structureUnitTypeName =
    statusUserLogin.member_position !== null &&
    statusUserLogin.member_position.structure_unit_type_name !== null
      ? statusUserLogin.member_position.structure_unit_type_name
      : "";
  const structurePositionTitleId =
    statusUserLogin.member_position !== null &&
    statusUserLogin.member_position.id !== null
      ? statusUserLogin.member_position.id
      : "";
  const roles = statusUserLogin.userRole;
  const superadmin = roles?.find((el) => el.name === "superadmin");
  const fullaccess = roles?.find((el) => el.name === "FULL_ACCESS_READ_ONLY");
  const is_admin = superadmin !== undefined ? true : false;
  const is_full_access = fullaccess !== undefined ? true : false;
  const is_superior =
    statusUserLogin.member_position !== null
      ? statusUserLogin.member_position.is_superior
      : false;

  // =======================================================================
  // ~~~~~~~ STATE ~~~~~~~
  // =======================================================================
  const [period, setPeriod] = useState(undefined);
  const [unitType, setUnitType] = useState(undefined);
  const [divisionData, setDivisionData] = useState([]);

  const [period200, setPeriod200] = useState(0);
  const [unit200, setUnit200] = useState(0);
  const [divisi200, setDivisi200] = useState(0);

  // =======================================================================
  // ~~~~~~~ SIDE EFFECT ~~~~~~~
  // =======================================================================
  useEffect(() => {
    AxiosConfig.get(
      `${URL_API}/human-resource/master-structure-unit/list/from-so/create`
    )
      .then((res) => {
        const result =
          res.data.data.fields.structure_unit_type_id.default_value;

        const getRandomIndex = Math.floor(Math.random() * result.length);

        setUnit200(res.status);

        if (res.status === 200) {
          if (result.length > 0) {
            setUnitType(result[getRandomIndex]);
          } else {
            setUnitType(undefined);
          }
        }
      })
      .catch((err) => {
        // console.log("error", err);
      });

    AxiosConfig.post(
      `${URL_API}/human-resource/master-structure-unit/list/from-so/list`,
      {
        structure_unit_type_id: structureUnitTypeId,
        structure_unit_id: structureUnitId,
      }
    )
      .then((res) => {
        const result = res.data.data;

        setDivisi200(res.status);

        if (res.status === 200) {
          setDivisionData(result);
        }
      })
      .catch((err) => {
        // console.log("error", err);
      });

    AxiosConfig.get(`${URL_API}/swd/master-period`)
      .then((res) => {
        const result = res.data.data;
        let getPeriod = [];

        setPeriod200(res.status);

        if (result.length > 0) {
          const filterPeriod = result.filter((el) => el.selected === true);

          if (filterPeriod.length > 0) {
            getPeriod = [...filterPeriod];
          } else {
            result.forEach((el) => {
              const b4 = moment(el.start_date).isBefore(new Date(), "day");
              const a4 = moment(el.end_date).isAfter(new Date(), "day");

              if (b4 && a4) {
                getPeriod.push(el);
              }
            });
          }
        } else {
          getPeriod = undefined;
        }

        // // console.log("RES PERx", result);
        // // console.log("GET PERx", getPeriod);

        if (res.status === 200) {
          if (getPeriod === undefined) {
            setPeriod(undefined);
          } else {
            setPeriod(getPeriod[0]);
          }
        }
      })
      .catch((err) => {
        // console.log("error", err);
      });
  }, []);

  return (
    <Box p={4} pt={0}>
      <Box
        p={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box minWidth={200} display="flex" alignItems="center">
          <Typography
            variant="subtitle2"
            className={classes.title555}
            style={{ marginRight: 5 }}
          >
            {MyLang() ? bahasa.period : english.period} :{" "}
          </Typography>
          {period === undefined ? (
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{ marginRight: 5 }}
              >
                {MyLang() ? bahasa.notHavePeriod : english.notHavePeriod}
              </Typography>
              {is_admin && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => navigate("/7wd-period")}
                  className={classes.btnLink}
                >
                  {MyLang() ? bahasa.create : english.create}{" "}
                  {MyLang() ? bahasa.period : english.period}
                </Button>
              )}
            </Box>
          ) : (
            <Typography variant="subtitle2" className={classes.txtPeriod}>
              {period.name}
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          {/* <InfoRoundedIcon
            style={{ fontSize: 16, color: "#888", marginRight: 4 }}
          />
          <Typography variant="subtitle2" className={classes.title888}>
            Data di Dashboard sedang dalam perbaikan
          </Typography> */}
        </Box>
      </Box>
      <Box py={0.5} />
      <Grid container spacing={3}>
        {/* Overall Performance Company - Circular Progress */}
        <Grid item md={3}>
          <OverallProgress
            classes={classes}
            period={period}
            structureUnitTypeId={structureUnitTypeId}
            structureUnitTypeName={structureUnitTypeName}
            unitType={unitType}
            is_admin={is_admin}
            is_full_access={is_full_access}
            is_superior={is_superior}
            divisionData={divisionData}
            period200={period200}
            unit200={unit200}
            divisi200={divisi200}
          />
        </Grid>
        {/* Goal & MA Per Divisi - Bar Chart */}
        <Grid item md={6}>
          <BarChartDashboard
            classes={classes}
            period={period}
            structureUnitTypeId={structureUnitTypeId}
            structureUnitTypeName={structureUnitTypeName}
            unitType={unitType}
            is_admin={is_admin}
            is_full_access={is_full_access}
            is_superior={is_superior}
            divisionData={divisionData}
            period200={period200}
            unit200={unit200}
            divisi200={divisi200}
          />
        </Grid>
        {/* Top Goals Company */}
        <Grid item md={3}>
          <TopGoals
            classes={classes}
            period={period}
            structureUnitTypeId={structureUnitTypeId}
            structureUnitTypeName={structureUnitTypeName}
            unitType={unitType}
            is_admin={is_admin}
            is_full_access={is_full_access}
            is_superior={is_superior}
            divisionData={divisionData}
            period200={period200}
            unit200={unit200}
            divisi200={divisi200}
          />
        </Grid>
      </Grid>

      <Box my={1.5} />

      <Grid container spacing={3}>
        {/* Overall Achievement (Goal & MA) - Pie Chart */}
        <Grid item md={4}>
          <PieChart
            classes={classes}
            period={period}
            structureUnitTypeId={structureUnitTypeId}
            structureUnitTypeName={structureUnitTypeName}
            unitType={unitType}
            is_admin={is_admin}
            is_full_access={is_full_access}
            is_superior={is_superior}
            divisionData={divisionData}
            period200={period200}
            unit200={unit200}
            divisi200={divisi200}
          />
        </Grid>
        {/* Your Goals / MA - Tab Switch */}
        <Grid item md={4}>
          <YourGoal
            classes={classes}
            structurePositionTitleId={structurePositionTitleId}
            period={period}
            period200={period200}
          />
        </Grid>
        {/* To Do List */}
        <Grid item md={4}>
          <TodoList classes={classes} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Company;
