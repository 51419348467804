import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import moment from "moment";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const ListItemArchiveEntity = ({ classes, item }) => {
  let startDate = item.details[0].start_date;
  let endDate = item.details[0].end_date;
  let ora = item.details[0].overall_result_achievement;
  let entity;

  if (item.details[0].entity_type === "goal") entity = "Goal";
  if (item.details[0].entity_type === "ma") entity = "Measured Activity";

  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{moment(item.archived_at).format("DD MMM YYYY")}</TableCell>
      <TableCell>{item.member_name}</TableCell>
      <TableCell>{entity}</TableCell>
      <TableCell>{moment(startDate).format("DD MMM YYYY")}</TableCell>
      <TableCell>{moment(endDate).format("DD MMM YYYY")}</TableCell>
      <TableCell>{FormatDecimal(ora)}%</TableCell>
    </TableRow>
  );
};

export default ListItemArchiveEntity;
