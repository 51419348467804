import React, { useState, useContext } from "react";
import {
  Box,
  Slider,
  Divider,
  Button,
  Input,
  Typography,
  InputAdornment,
  Menu,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  FilterList as FilterListIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextAllMA from "../context/AllMaContext";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const AnchorFilterWeight = ({ classes, onWeightValue, weightValue }) => {
  const context = useContext(ContextAllMA);

  const handleChange = (event, newValue) => {
    onWeightValue(newValue);
    context.setWeightValueAll({ min: newValue[0], max: newValue[1] });
  };

  function valuetext(value) {
    return `${weightValue}%`;
  }

  const handleBlur = () => {
    if (weightValue < 0) {
      onWeightValue([0, 100]);
      context.setWeightValueAll({ min: 0, max: 100 });
    } else if (weightValue > 100) {
      context.setWeightValueAll({ min: 0, max: 100 });
    }
  };

  const handleInputStart = (event) => {
    const val = Number(event.target.value);
    onWeightValue([val, weightValue[1]]);
    context.setWeightValueAll({ min: val, max: weightValue[1] });
  };
  const handleInputEnd = (event) => {
    const val = Number(event.target.value);
    onWeightValue([weightValue[0], val]);
    context.setWeightValueAll({ min: weightValue[0], max: val });
  };

  // const handleShowResult = () => {
  //   context.setLoader(true);
  //   setLoading(true);

  //   let URL = "";
  //   const targetURL = `${URL_API}/swd/measured-activity?options[filter][search]=${
  //     context.valueSearch
  //   }&options[filter][status_id]=${
  //     context.statusAllMAIdAll
  //   }&options[paging][offset]=${
  //     context.offset === -10 ? 0 : context.offset
  //   }&options[paging][limit]=${context.rowsPerPage}`;
  //   const minWeight = `&options[filter][weighting][from]=${value[0]}`;
  //   const maxWeight = `&options[filter][weighting][to]=${value[1]}`;

  //   if (value[0] === 0 && value[1] === 100) {
  //     URL = targetURL;
  //   } else {
  //     URL = `${targetURL}${minWeight}${maxWeight}`;
  //   }

  //   AxiosConfig.get(URL)
  //     .then((response) => {
  //       // console.log("Res COLLECTION MA weight : ", response);

  //       if (response.status === 200) {
  //         if (response.data.data !== null) {
  //           context.setCollectionMAList(response.data.data);
  //           context.setCollectionMALength(response.data.info.count);

  //           context.setWeightValueAll({ min: value[0], max: value[1] });

  //           handleCloseFilterMA();
  //         }
  //       }
  //       context.setLoader(false);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       context.setLoader(false);
  //       // console.log("Error : ", error);
  //       setLoading(false);
  //     });
  // };

  return (
    <Box minWidth={200}>
      <Slider
        value={weightValue}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
      />

      <Box m={1} display="flex" justifyContent="space-between">
        <Input
          className={classes.input}
          value={weightValue[0]}
          margin="dense"
          onChange={handleInputStart}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
        <Input
          className={classes.input}
          value={weightValue[1]}
          margin="dense"
          onChange={handleInputEnd}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </Box>
    </Box>
  );
};

export default AnchorFilterWeight;
