import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";

import hr_icon from "../../../../assets/images/field_icon.png";
import marketing_icon from "../../../../assets/images/Marketing.png";
import sales_icon from "../../../../assets/images/Sales.png";
import operation_icon from "../../../../assets/images/Operation.png";
import logistik_icon from "../../../../assets/images/Logistik.png";
import it_icon from "../../../../assets/images/IT.png";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#888",
    textDecoration: "none",
  },
  cardField: {
    borderRadius: 15,
    border: "3px solid #F3F2F2",
    padding: "7px 30px",
    margin: "0px 0px 30px 0px",
    maxWidth: "80%",
  },
});

const useStyle = makeStyles(styles);

const AllField = () => {
  const classes = useStyle();

  return (
    <Box pb={15}>
      <Box textAlign="center" mb={10}>
        <Typography variant="h5" className={classes.title333}>
          <b>Untuk Semua Bidang Pekerjaan</b>
        </Typography>
      </Box>

      <Grid container>
        <Grid xs={12} sm={12} md={6} justify="center">
          {listFieldLeft.map((item, i) => (
            <Box
              key={i}
              display="flex"
              alignItems="center"
              className={classes.cardField}>
              <Box>
                <img src={item.icon} height={50} width={50} />
              </Box>
              <Box ml={5}>
                <Typography variant="h6" className={classes.title555}>
                  <b>{item.name}</b>
                </Typography>
                <Typography variant="subtitle1" className={classes.title888}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid xs={12} sm={12} md={6} justify="center">
          {listFieldRight.map((item, i) => (
            <Box
              key={i}
              display="flex"
              alignItems="center"
              className={classes.cardField}>
              <Box>
                <img src={item.icon} height={50} width={50} />
              </Box>
              <Box ml={5}>
                <Typography variant="h6" className={classes.title555}>
                  <b>{item.name}</b>
                </Typography>
                <Typography variant="subtitle1" className={classes.title888}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllField;

const listFieldLeft = [
  {
    name: "Marketing",
    description:
      " Monitor implementasi strategi setiap channel marketing anda dengan cepat",
    icon: marketing_icon,
  },
  {
    name: "HR",
    description:
      "Pantau kedisiplinan divisi HRD dalam menjalankan program yang mendukung ‘employee satisfaction’",
    icon: hr_icon,
  },
  {
    name: "Logistik",
    description:
      "Pantau semua aktivitas yang mendukung workflow pengiriman barang anda",
    icon: logistik_icon,
  },
];

const listFieldRight = [
  {
    name: "Sales",
    description:
      "Monitor kualitas eksekusi tim sales anda melakukan canvassing di lapangan",
    icon: sales_icon,
  },
  {
    name: "Operasional",
    description:
      "Temukan karyawan atau kendala yang menjadi penghalang produksi anda dengan cepat",
    icon: operation_icon,
  },
  {
    name: "IT",
    description:
      "Evaluasi kinerja server, kestabilan aplikasi, response time dan resolution time tim IT anda.",
    icon: it_icon,
  },
];
