import React, { useState, useEffect } from "react";
import { styles } from "../Style/StyleHistoryCheckInOut";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const HistoryRow = ({ row, isCheckIn, verificationMethod }) => {
  const [location, setLocation] = useState(null);

  const getLocationByLatLng = () => {
    const lat = row.clock_out
      ? row.clock_out_coordinates_lat
      : row.clock_in_coordinates_lat;
    const lng = row.clock_out
      ? row.clock_out_coordinates_long
      : row.clock_in_coordinates_long;

    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=AIzaSyBcNgwF6ypP2rL1Ma4FlmH4JjPIGBDI9Ds"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        setLocation(responseJson.results[0].formatted_address);
      });
  };

  useEffect(() => {
    getLocationByLatLng();
  }, [row]);

  return (
    <MuiThemeProvider theme={theme}>
      <TableRow>
        <TableCell align="center">
          <p
            style={{
              color: "#262261",
              fontWeight: "bold",
            }}
          >
            {isCheckIn
              ? moment(row.clock_in).format("HH:mm")
              : moment(row.clock_out).format("HH:mm")}
          </p>
        </TableCell>
        <TableCell align="center">
          <p
            style={{
              color: "#262261",
              fontWeight: "bold",
            }}
          >
            {location}
          </p>
        </TableCell>
        <TableCell align="center">
          {row.status_attendance === "1" ? (
            <p
              style={{
                color: "#262261",
                fontWeight: "bold",
              }}
            >
              Berhasil
            </p>
          ) : (
            <p
              style={{
                color: "#CE354A",
                fontWeight: "bold",
              }}
            >
              Gagal
            </p>
          )}
        </TableCell>
      </TableRow>
    </MuiThemeProvider>
  );
};

export default HistoryRow;
