/*
    `````````````````````````````````````````````
    NOW, 05 Desember 2019, 13.30. 
    
    Term "Employee" di ubah menjadi "User"
    
    `````````````````````````````````````````````
*/
import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  IconButton,
  Divider,
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import { makeStyles, useTheme, createMuiTheme } from "@material-ui/core/styles";

import axios from "axios";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";
import DialogError from "../../../components/DialogError";

// import Redirect from '../../../utilities/Redirect';
// import {  ToNewSO } from '../../../constants/config-redirect-url';

import Capitalize from "../../../utilities/Capitalize";

import { URL_API } from "../../../constants/config-api";
import { styles } from "../Style/StyleDialog";

import DialogDeletePositionTitle from "../ComponentsDialogDetail/DialogDeletePositionTitle";
import DialogDeleteJenisUnit from "../ComponentsDialogDetail/DialogDeleteJenisUnit";
import DialogDeleteNamaUnit from "../ComponentsDialogDetail/DialogDeleteNamaUnit";
import DialogDeleteEmployeeMember from "../ComponentsDialogDetail/DialogDeleteEmployeeMember";

import ContextNewSO from "../Context/ContextNewSO";

const useStyles = makeStyles(styles);

const DialogTambahHead = (props) => {
  // const {
  //
  //      *Sebagian besar state di component ini di pindahkan ke Global State
  // } = props;

  /**
   * ``````````````````````````````````````````````````
   *  context : {
   *
   *      setTreeItems : Array
   *      setOpenDialogTambahHead : Boolean
   *      isOpenDialogTambahHead : Boolean
   *
   *      isOpenDialogCreatePositionTitle : Boolean
   *      setOpenDialogCreatePositionTitle : Boolean,
   *
   *      setOpenDialogCreateEmployee: Boolean,
   *      setOpenDialogCreateJenisUnit : Boolean
   *
   *
   *  }
   *
   * ``````````````````````````````````````````````````
   */

  const context = useContext(ContextNewSO);

  // const focusInputElPanel2 = () => {

  //     inputElPanel2.current.focus();
  //     handleChange('panel2')
  //  }

  //  const clickSummary = event => {
  //     event.stopPropagation();
  //   };

  const classes = useStyles();

  const [userTokenState, setUserTokenState] = useState("");

  const [listJenisUnit, setListJenisUnit] = useState([]);
  const [listNamaUnit, setListNamaUnit] = useState([]);

  const [listPositionTitle, setListPositionTitle] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);

  useEffect(() => {
    if (context.isOpenDialogTambahHead == true) {
      context.setTreeItems([
        {
          id: null,
          parent_id: null,
          structure_position_title_id: null,
          structure_position_title_name: null,

          structure_unit_id: null,
          structure_unit_name: null,

          structure_unit_type_id: null,
          structure_unit_type_name: null,

          member_first_name: null,
          member_last_name: null,
          self: null,

          classification: {
            id: null,
          },
          child: [],
        },
      ]);

      // context.setPositionTitleChoose(false);
      // setNamaJenisUnitChoosed('');
      // context.setNamaUnitChoosed('');
      // context.setPositionTitleName('');
      // context.setFirstName('');

      handleCloseDropdownEditAndDeleteJenisUnit();
      context.setSuccessTambahHEAD(false);
    }
  }, [context.isOpenDialogTambahHead]);

  /*  
        ````````````````````````````````````````````````````````````````````````````
        HANDLE DELETE POSITION TITLE && HANDLE DROPDOWN EDIT & DELETE POSITION TITLE

        ````````````````````````````````````````````````````````````````````````````
    */
  // const [ isOpenDialogDeletePositionTitle,setOpenDialogDeletePositionTitle ] = useState(false); //*Moved to global state
  // const [ isSuccessDeletePositionTitle, setSuccessDeletePositionTitle ] = useState(false); //* Moved to global state

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdownEditAndDeletePositionTitle = (e, data) => {
    setAnchorEl(e.currentTarget);
    // console.log("Data from 'more_horizon' : ", data);

    context.setDataDetail(data);
  };

  const handleCloseDropdownEditAndDelete = () => {
    setAnchorEl(null);
  };

  /*  
        ````````````````````````````````````````````````````````````````````
        HANDLE DELETE JENIS UNIT && HANDLE DROPDOWN EDIT & DELETE JENIS UNIT

        ````````````````````````````````````````````````````````````````````
    */
  const [isOpenDialogDeleteJenisUnit, setOpenDialogDeleteJenisUnit] =
    useState(false);
  const [isSuccessDeleteJenisUnit, setSuccessDeleteJenisUnit] = useState(false);

  const [anchorElJenisUnit, setAnchorElJenisUnit] = useState(null);

  const handleOpenDropdownEditAndDeleteJenisUnit = (e, data) => {
    setAnchorElJenisUnit(e.currentTarget);
    // console.log("Data from 'more_horizon' Jenis Unit : ", data);

    context.setDataDetail(data);

    setSuccessDeleteJenisUnit(false);
  };

  const handleCloseDropdownEditAndDeleteJenisUnit = () => {
    setAnchorElJenisUnit(null);
  };

  /*  
        ``````````````````````````````````````````````````````````````````
        HANDLE DELETE NAMA UNIT && HANDLE DROPDOWN EDIT & DELETE NAMA UNIT

        ``````````````````````````````````````````````````````````````````
    */
  const [isOpenDialogDeleteNamaUnit, setOpenDialogDeleteNamaUnit] =
    useState(false);
  const [isSuccessDeleteNamaUnit, setSuccessDeleteNamaUnit] = useState(false);

  const [anchorElNamaUnit, setAnchorElNamaUnit] = useState(null);

  const handleOpenDropdownEditAndDeleteNamaUnit = (e, data) => {
    setAnchorElNamaUnit(e.currentTarget);
    // console.log("Data from 'more_horizon' Nama Unit : ", data);

    context.setDataDetail(data);

    setSuccessDeleteNamaUnit(false);
    context.setSuccessEditNamaUnit(false);
  };

  const handleCloseDropdownEditAndDeleteNamaUnit = () => {
    setAnchorElNamaUnit(null);
  };

  /*  
        `````````````````````````````````````````````````````````````````````````````````
           HANDLE DELETE EMPLOYEE MEMBER && HANDLE DROPDOWN EDIT & DELETE EMPLOYEE MEMBER

        `````````````````````````````````````````````````````````````````````````````````
    */

  const [isSuccessDeleteMemberEmployee, setSuccessDeleteMemberEmployee] =
    useState(false);

  const [anchorElMemberEmployee, setAnchorElMemberEmployee] = useState(null);

  const handleOpenDropdownEditAndDeleteMemberEmployee = (e, data) => {
    setAnchorElMemberEmployee(e.currentTarget);
    // console.log("Data from 'more_horizon' Member Employee : ", data);

    context.setDataDetail(data);

    setSuccessDeleteMemberEmployee(false);
    // context.setSuccessEditNamaUnit(false)
  };

  const handleCloseDropdownEditAndDeleteMemberEmployee = () => {
    setAnchorElMemberEmployee(null);
  };

  /*
        ```````
        EXPAND

        ```````
    */
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  useEffect(() => {
    if (
      expanded !== "" ||
      context.isSucessCreateEmployee == true ||
      context.isSuccessCreatePositionTitle == true ||
      context.isSuccessDeletePositionTitle == true ||
      isSuccessDeleteJenisUnit == true ||
      context.isSuccessEditJenisUnit == true ||
      context.isSuccessEditPositionTitle == true ||
      context.isSuccessDeleteEmployeeMember == true ||
      context.isSuccessEditMemberEmployee == true ||
      context.isSuccessCreateJenisUnit == true ||
      context.isSuccessCreateNamaUnit == true
    ) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      let data = {
        structure_unit_type_id: "",
      };

      if (userToken !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userToken,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/structure-position/create?classification=1&options[filter][structure_unit_type_id]=${data.structure_unit_type_id}`
          )
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data.structureUnitCollections !== null) {
                if (response.data.data.structureUnitCollections.length > 0) {
                  setListNamaUnit(response.data.data.structureUnitCollections);
                }
              }

              if (response.data.data.structureUnitTypeCollections !== null) {
                if (
                  response.data.data.structureUnitTypeCollections !== undefined
                ) {
                  if (
                    response.data.data.structureUnitTypeCollections.length > 0
                  ) {
                    setListJenisUnit(
                      response.data.data.structureUnitTypeCollections
                    );
                  }
                }
              }

              if (
                response.data.data.structurePositionTitleCollections !== null
              ) {
                if (
                  response.data.data.structurePositionTitleCollections !==
                  undefined
                ) {
                  if (
                    response.data.data.structurePositionTitleCollections
                      .length > 0
                  ) {
                    setListPositionTitle(
                      response.data.data.structurePositionTitleCollections
                    );
                  }
                }
              }

              if (response.data.data.userCollections !== null) {
                if (response.data.data.userCollections !== undefined) {
                  if (response.data.data.userCollections.length > 0) {
                    // setListEmployee(response.data.data.memberCollections);
                    setListEmployee(response.data.data.userCollections);
                  }
                }
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }

      handleCloseDropdownEditAndDelete(); //* Close dropdown edit& delete in position title
      handleCloseDropdownEditAndDeleteJenisUnit();
      handleCloseDropdownEditAndDeleteNamaUnit();
      handleCloseDropdownEditAndDeleteMemberEmployee();
    }
  }, [
    expanded,
    context.isSucessCreateEmployee,
    context.isSuccessCreatePositionTitle,
    context.isSuccessEditJenisUnit,
    context.isSuccessEditPositionTitle,

    context.isSuccessDeletePositionTitle,
    isSuccessDeleteJenisUnit,
    context.isSuccessDeleteEmployeeMember,

    context.isSuccessEditMemberEmployee,
    context.isSuccessCreateJenisUnit,
    context.isSuccessCreateNamaUnit,
  ]);

  /*
        ````````````````````````
        DIALOG CREATE JENIS UNIT

        ````````````````````````
    */
  const handleChooseJenisUnit = (e, item) => {
    e.preventDefault();

    context.setJenisUnitChoosed(true);
    context.setNamaJenisUnitChoosed(item.name);
    context.setStructureUnitTypeId(item.id);
  };

  const handleChooseNamaUnit = (e, item) => {
    e.preventDefault();

    // console.log("Nama Unit : ", item);

    context.setNamaUnitChoosedNow(true);

    context.setNamaUnitChoosed(item.name);
    context.setStructureUnitId(item.id);

    context.setOpenDialogTambahHead(true);
    context.setJenisUnitChoosed(false);

    //*Set to Panel selanjutnya yaitu "Panel2"
    setExpanded("panel2");
    handleChange("panel2");
  };

  /*
        ``````````````````
        GET LIST NAMA UNIT

        ``````````````````
    */
  useEffect(() => {
    if (
      context.isJenisUnitChoosed == true ||
      context.isSuccessCreateNamaUnit == true ||
      isSuccessDeleteNamaUnit == true
      // context.isSuccessEditNamaUnit == true
    ) {
      if (userTokenState !== "") {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/master-structure-unit?options[filter][structure_unit_type_id]=${context.structureUnitTypeId}`
          )
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data !== null) {
                setListNamaUnit(response.data.data);
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }
    }
  }, [
    context.isJenisUnitChoosed,
    context.isSuccessCreateNamaUnit,
    isSuccessDeleteNamaUnit,
    // context.isSuccessEditNamaUnit
  ]);

  /*
            ````````````````````````````
            DIALOG CREATE POSITION TITLE

            ````````````````````````````
        */
  const handleTambahJabatan = () => {
    context.setOpenDialogTambahHead(false);
    context.setOpenDialogCreatePositionTitle(true);
  };

  const handleChoosePositionTitle = (e, data) => {
    e.preventDefault();

    // console.log("Wew Position Title : ", data);
    context.setPositionTitleId(data.id);
    context.setPositionTitleName(data.name);
    context.setPositionTitleChoose(true);

    //*Set to Panel selanjutnya yaitu "Panel3"
    setExpanded("panel3");
    handleChange("panel3");
  };

  /*
        ``````````````````````
        DIALOG CREATE KARYAWAN

        ``````````````````````
    */
  const handleChooseEmployee = (e, data) => {
    e.preventDefault();

    // console.log("Wew Employee : ", data);
    context.setEmployeeId(data.id);
    context.setFirstName(data.member?.first_name);
    context.setLastName(data.member?.last_name);

    //*Set to Panel selanjutnya yaitu "PanelX"
    setExpanded("panelX");
    handleChange("panelX");
  };

  /*
        `````````````````````````
        HANDLE SUBMIT TAMBAH HEAD

        `````````````````````````
    */
  const [loader, setLoader] = useState(false);
  const handleSubmitTambahHead = () => {
    setLoader(true);

    let data = {
      StructurePosition: {
        parent_id: context.dataDetail.id,
        structure_unit_type_id: context.structureUnitTypeId,
        structure_unit_id: context.structureUnitId,
        structure_position_title_id: context.positionTitleId,
        user_id: context.employeeId,
      },
    };

    // console.log("Data : ", data);

    if (userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(
          URL_API + "/human-resource/structure-position?classification=1",
          data
        ) //*Classification 1 itu adalah ID HEAD
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          if (response.status == 200) {
            context.setSuccessTambahHEAD(true);
            context.setOpenDialogTambahHead(false); //*Letak code ini HARUS di letakan paling bawah.
          }
        })
        .catch(function (error) {
          setLoader(false);
          context.setOpenDialogTambahHead(false);
          context.setPositionTitleName("");

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(error.response.status);
              if (
                error.response.data.info.errors !== undefined &&
                error.response.data.info.errors !== null
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                  // if(error.response.data.info.errors[0].code == "VALIDATION_ERROR" ){

                  //     setTextErrorInformationValue('Error 400 : ' + Capitalize(error.response.data.info.errors[0].description))

                  //     setOpenDialogError(true);
                  // };
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(error.response.status);
              setTextErrorInformationValue(
                "Error 500 : " + Capitalize(error.response.statusText)
              );
              setOpenDialogError(true);
            }
          } else {
            setTextErrorInformationValue("Whoops, something went wrong !");
            setOpenDialogError(true);
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*  
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState();
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  /*  
        ``````````````````````````
        HANDLE EDIT POSITION TITLE

        ``````````````````````````
    */
  const [isOpenDialogEditPositionTitle, setOpenDialogEditPositionTitle] =
    useState(false);

  return (
    <Fragment>
      <Dialog
        open={context.isOpenDialogTambahHead}
        onClose={() => context.setOpenDialogTambahHead(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Tambah <i>Head</i>
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <div className={classes.root}>
            {/* 
                        ````````````````
                        PILIH JENIS UNIT

                        ````````````````
                    */}

            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {context.isJenisUnitChoosed !== true ? (
                  <Typography variant="subtitle2" className={classes.title}>
                    <IconButton style={{ padding: 4, marginBottom: 1 }}>
                      {expanded !== "panel1" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>
                    {context.namaUnitChoosed !== "" ? ( //&& namaJenisUnitChoosed !== ''
                      <Fragment>
                        <b>
                          {"Terpilih " + context.namaJenisUnitChoosed + " "}{" "}
                          {context.namaUnitChoosed}
                        </b>
                        <IconButton size="small">
                          <CheckCircleIcon
                            style={{ color: "green", fontSize: 14 }}
                          />
                        </IconButton>
                      </Fragment>
                    ) : (
                      <Fragment>
                        Pilih <i>Jenis Unit</i>{" "}
                        {expanded !== "panel1" ? "" : " :"}
                      </Fragment>
                    )}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" className={classes.title}>
                    <IconButton style={{ padding: 4, marginBottom: 1 }}>
                      {expanded !== "panel1" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>
                    {/* Pilih <i>Nama Unit</i> untuk <i>{context.namaJenisUnitChoosed}</i>  {expanded !== 'panel1' ? "" : " :" } */}
                    Pilih <i>{context.namaJenisUnitChoosed}</i>{" "}
                    {expanded !== "panel1" ? "" : " :"}
                  </Typography>
                )}
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <List className={classes.rootList}>
                  {listJenisUnit.length > 0 &&
                    context.isJenisUnitChoosed !== true &&
                    listJenisUnit.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            id="label-position"
                            primary={
                              <Button
                                onClick={(e) => handleChooseJenisUnit(e, item)}
                                variant="outlined"
                                className={classes.buttonOutlined}
                                endIcon={
                                  <ArrowForwardIosIcon
                                    style={{
                                      fontSize: "12px",
                                      marginBottom: 1,
                                    }}
                                  />
                                }
                              >
                                <b>{item.name}</b>
                              </Button>
                            }
                          />

                          <ListItemSecondaryAction>
                            <IconButton
                              size="small"
                              onClick={(e) =>
                                handleOpenDropdownEditAndDeleteJenisUnit(
                                  e,
                                  item
                                )
                              }
                              // onClick={() => // console.log("Open drop down edit and delete Jenis Unit")}
                            >
                              <MoreHorizIcon
                                style={{ color: "grey", opacity: 0.7 }}
                              />
                            </IconButton>

                            <StyledMenu
                              id="customized-menu-goal-result"
                              anchorEl={anchorElJenisUnit}
                              keepMounted
                              open={Boolean(anchorElJenisUnit)}
                              onClose={
                                handleCloseDropdownEditAndDeleteJenisUnit
                              }
                            >
                              <StyledMenuItem
                                onClick={() =>
                                  context.setOpenDialogEditJenisUnit(true)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                      style={{ color: "grey" }}
                                    >
                                      <IconButton size="small">
                                        <EditIcon style={{ color: "green" }} />
                                      </IconButton>
                                      <b>Edit</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                              <StyledMenuItem
                                onClick={() =>
                                  setOpenDialogDeleteJenisUnit(true)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                      style={{ color: "grey" }}
                                    >
                                      <IconButton size="small">
                                        <DeleteForeverIcon
                                          style={{ color: "red" }}
                                        />
                                      </IconButton>
                                      <b>Delete</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                            </StyledMenu>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}

                  {listJenisUnit.length == 0 && (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        id="label-position"
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: "red" }}
                          >
                            <IconButton
                              size="small"
                              style={{ marginBottom: 1 }}
                            >
                              <ErrorIcon style={{ color: "red" }} />
                            </IconButton>
                            <b>
                              Belum ada <i>Jenis Unit</i>{" "}
                            </b>
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {listNamaUnit.length == 0 &&
                    context.isJenisUnitChoosed == true && (
                      <ListItem style={{ padding: 0 }}>
                        <ListItemText
                          id="label-position"
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                              style={{ color: "red" }}
                            >
                              <IconButton
                                size="small"
                                style={{ marginBottom: 1 }}
                              >
                                <ErrorIcon style={{ color: "red" }} />
                              </IconButton>
                              {/* <b >Belum ada <i>Nama Unit</i> </b> */}
                              <b>
                                Belum ada{" "}
                                <i>
                                  {context.namaJenisUnitChoosed} yang di
                                  tentukan
                                </i>
                              </b>
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}

                  {listNamaUnit.length > 0 &&
                    context.isJenisUnitChoosed == true &&
                    listNamaUnit.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem style={{ padding: 0 }} key={i}>
                          <ListItemText
                            id="label-position"
                            primary={
                              <Button
                                onClick={(e) => handleChooseNamaUnit(e, item)}
                                variant="outlined"
                                className={classes.buttonOutlined}
                                endIcon={
                                  <ArrowForwardIosIcon
                                    style={{
                                      fontSize: "12px",
                                      marginBottom: 1,
                                    }}
                                  />
                                }
                              >
                                <b>{item.name}</b>
                              </Button>
                            }
                          />

                          <ListItemSecondaryAction>
                            <IconButton
                              size="small"
                              onClick={(e) =>
                                handleOpenDropdownEditAndDeleteNamaUnit(e, item)
                              }
                              // onClick={() => // console.log("Open drop down edit and delete Nama Unit")}
                            >
                              <MoreHorizIcon
                                style={{ color: "grey", opacity: 0.7 }}
                              />
                            </IconButton>

                            <StyledMenu
                              id="customized-menu-goal-result"
                              anchorEl={anchorElNamaUnit}
                              keepMounted
                              open={Boolean(anchorElNamaUnit)}
                              onClose={handleCloseDropdownEditAndDeleteNamaUnit}
                            >
                              <StyledMenuItem
                                onClick={() =>
                                  context.setOpenDialogEditNamaUnit(true)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                      style={{ color: "grey" }}
                                    >
                                      <IconButton size="small">
                                        <EditIcon style={{ color: "green" }} />
                                      </IconButton>
                                      <b>Edit</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                              <StyledMenuItem
                                onClick={() =>
                                  setOpenDialogDeleteNamaUnit(true)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                      style={{ color: "grey" }}
                                    >
                                      <IconButton size="small">
                                        <DeleteForeverIcon
                                          style={{ color: "red" }}
                                        />
                                      </IconButton>
                                      <b>Delete</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                            </StyledMenu>
                          </ListItemSecondaryAction>
                        </ListItem>

                        <Divider />
                      </Fragment>
                    ))}
                </List>
              </ExpansionPanelDetails>
              <Divider />

              <ExpansionPanelActions>
                {context.isJenisUnitChoosed == true ? (
                  <Fragment>
                    <Button
                      onClick={() => context.setJenisUnitChoosed(false)}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.buttonOutlined}
                      style={{ color: "grey" }}
                    >
                      Kembali ke pilihan &nbsp; <i>Jenis Unit</i>
                    </Button>
                    <Button
                      onClick={() => context.setOpenDialogCreateNamaUnit(true)}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.buttonOutlined}
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      {/* Tambah &nbsp;<i> Nama Unit</i>  */}
                      Tambah &nbsp; <i>{context.namaJenisUnitChoosed}</i>
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    onClick={() => context.setOpenDialogCreateJenisUnit(true)}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.buttonOutlined}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Tambah &nbsp;
                    <i>
                      {" "}
                      Jenis Unit{" "}
                      {listJenisUnit.length == 0 ? " sekarang" : "lagi"}
                    </i>
                  </Button>
                )}
              </ExpansionPanelActions>
            </ExpansionPanel>

            {/* 
                        ``````````````````
                        PILIH NAMA JABATAN

                        ``````````````````
                    */}
            <ExpansionPanel
              // ref={inputElPanel2}
              // onClick={clickSummary}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle2" className={classes.title}>
                  <IconButton style={{ padding: 4, marginBottom: 1 }}>
                    {expanded !== "panel2" ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                  {context.isPositionTitleChoosed == true ? (
                    <Fragment>
                      <b>Terpilih {context.positionTitleName}</b>
                      <IconButton size="small">
                        <CheckCircleIcon
                          style={{ color: "green", fontSize: 14 }}
                        />
                      </IconButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      Pilih <i>Nama Jabatan</i>{" "}
                      {expanded !== "panel2" ? "" : " :"}
                    </Fragment>
                  )}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <List className={classes.rootList}>
                  {listPositionTitle.length == 0 && (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        id="label-position"
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: "red" }}
                          >
                            <IconButton
                              size="small"
                              style={{ marginBottom: 1 }}
                            >
                              <ErrorIcon style={{ color: "red" }} />
                            </IconButton>
                            <b>
                              Belum ada <i>Nama Jabatan</i>
                            </b>
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {listPositionTitle.length > 0 &&
                    listPositionTitle.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            id="label-employee"
                            primary={
                              <Button
                                onClick={(e) =>
                                  handleChoosePositionTitle(e, item)
                                }
                                variant="outlined"
                                className={classes.buttonOutlined}
                              >
                                <b>{item.name}</b>
                              </Button>
                            }
                          />

                          <ListItemSecondaryAction>
                            <IconButton
                              size="small"
                              onClick={(e) =>
                                handleOpenDropdownEditAndDeletePositionTitle(
                                  e,
                                  item
                                )
                              }
                            >
                              <MoreHorizIcon />
                            </IconButton>

                            <StyledMenu
                              id="customized-menu-goal-result"
                              anchorEl={anchorEl} //*Position Title
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleCloseDropdownEditAndDelete}
                            >
                              <StyledMenuItem
                                onClick={() =>
                                  context.setOpenDialogEditPositionTitle(true)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                      style={{ color: "grey" }}
                                    >
                                      <IconButton size="small">
                                        <EditIcon style={{ color: "green" }} />
                                      </IconButton>
                                      <b>Edit</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                              <StyledMenuItem
                                onClick={() =>
                                  context.setOpenDialogDeletePositionTitle(true)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                      style={{ color: "grey" }}
                                    >
                                      <IconButton size="small">
                                        <DeleteForeverIcon
                                          style={{ color: "red" }}
                                        />
                                      </IconButton>
                                      <b>Delete</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                            </StyledMenu>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}
                </List>
              </ExpansionPanelDetails>
              <Divider />

              <ExpansionPanelActions>
                <Button
                  onClick={handleTambahJabatan}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp;<i>Jabatan</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>

            {/* 
                        ``````````
                        PILIH USER

                        ``````````
                    */}
            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-control
                s="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle2" className={classes.title}>
                  <IconButton style={{ padding: 4, marginBottom: 1 }}>
                    {expanded !== "panel3" ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                  {context.firstName !== "" ? (
                    <Fragment>
                      <b>
                        Terpilih {context.firstName + " "} {context.lastName}
                      </b>
                      <IconButton size="small">
                        <CheckCircleIcon
                          style={{ color: "green", fontSize: 14 }}
                        />
                      </IconButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      Pilih <i>User</i> {expanded !== "panel3" ? "" : " :"}
                    </Fragment>
                  )}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <List className={classes.rootList}>
                  {listEmployee.length > 0 &&
                    listEmployee.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem
                          onClick={(e) => handleChooseEmployee(e, item)}
                          button
                          style={{ padding: 0 }}
                        >
                          <IconButton>
                            <AccountCircleIcon fontSize="large" />
                          </IconButton>

                          <ListItemText
                            id="label-employee"
                            primary={
                              <Typography
                                variant="subtitle1"
                                className={classes.title}
                              >
                                <b>
                                  {item.member?.first_name + " "}{" "}
                                  {item.member?.last_name}
                                </b>
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                                style={{ color: "grey" }}
                              >
                                {item.email}
                              </Typography>
                            }
                          />

                          <ListItemSecondaryAction>
                            <Button
                              size="small"
                              variant="outlined"
                              className={classes.buttonOutlined}
                              style={
                                item.status.code ===
                                "user_status_id_enum_pending"
                                  ? {
                                      backgroundColor: "#ffd34f", //*yellow
                                      padding: 0,
                                    }
                                  : {
                                      backgroundColor: "#96ff96", //*green
                                      padding: 0,
                                    }
                              }
                            >
                              <Typography
                                variant="caption"
                                className={classes.title}
                                style={{ color: "white", marginLeft: 0 }}
                              >
                                {item.status.name}
                              </Typography>
                            </Button>
                          </ListItemSecondaryAction>

                          {/* <ListItemSecondaryAction>
                                                    <IconButton 
                                                        size='small'
                                                        onClick={(e) => handleOpenDropdownEditAndDeleteMemberEmployee(e, item)}
                                                    >
                                                        <MoreHorizIcon/>
                                                    </IconButton>

                                                    <StyledMenu
                                                        id="customized-menu-goal-result"
                                                        anchorEl={anchorElMemberEmployee} //*Position Title
                                                        keepMounted
                                                        open={Boolean(anchorElMemberEmployee)}
                                                        onClose={handleCloseDropdownEditAndDeleteMemberEmployee}
                                                    >
                                                            <StyledMenuItem 
                                                                onClick= {() => context.setOpenDialogEditMemberEmployee(true)}
                                                            >                         
                                                                <ListItemText 
                                                                    
                                                                    primary = {

                                                                        <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                            <IconButton  size='small'>
                                                                                <EditIcon style={{color: 'green'}} />
                                                                            </IconButton>
                                                                            <b>Edit</b>
                                                                        </Typography>
                                                                    }  
                                                                />

                                                            </StyledMenuItem>
                                                            <StyledMenuItem
                                                                onClick= {() => context.setOpenDialogDeleteEmployeeMember(true)}
                                                            >                         
                                                                <ListItemText 
                                                                    primary = {

                                                                        <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                            <IconButton size='small'>
                                                                                <DeleteForeverIcon style={{color: 'red'}} />
                                                                            </IconButton>
                                                                            <b>Delete</b>
                                                                        </Typography>
                                                                    }  
                                                                />
                                                            </StyledMenuItem>
                                                        </StyledMenu>

                                                    </ListItemSecondaryAction> */}
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}

                  {listEmployee.length == 0 && (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        id="label-employee"
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: "red" }}
                          >
                            <IconButton
                              size="small"
                              style={{ marginBottom: 1 }}
                            >
                              <ErrorIcon style={{ color: "red" }} />
                            </IconButton>
                            <b>
                              Belum ada <i>User</i>
                            </b>
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  onClick={() => {
                    handleChange("panelX");
                    setExpanded("panelX");

                    context.setFirstName("Vacant");
                    context.setLastName("");
                    context.setEmployeeId(null);
                  }}
                  variant="outlined"
                  size="small"
                  className={classes.buttonOutlined}
                >
                  <i style={{ color: "black" }}>Set as vacant</i>
                </Button>

                <Button
                  onClick={() => context.setOpenDialogCreateEmployee(true)}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp; <i>User</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </div>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleSubmitTambahHead}
            variant="contained"
            className={classes.buttonDialog}
            disabled={context.positionTitleName == "" ? true : false}
          >
            {loader !== true ? (
              "Tambah sekarang"
            ) : (
              <CircularProgress size={24} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      {/* 
                ``````````````````````
                DIALOG EDIT JENIS UNIT //*Dialog ini di pindahkan karena kalau di letakan di sini NOT WORK

                ``````````````````````
            */}

      {/* 
                ```````````````````````````
                DIALOG EDIT MEMBER EMPLOYEE //*Dialog ini di pindahkan karena kalau di letakan di sini NOT WORK

                ```````````````````````````
            */}

      {/* 
                ````````````````````````
                DIALOG DELETE JENIS UNIT

                ````````````````````````
            */}
      <DialogDeleteJenisUnit
        setOpenDialogDeleteJenisUnit={setOpenDialogDeleteJenisUnit}
        isOpenDialogDeleteJenisUnit={isOpenDialogDeleteJenisUnit}
        setSuccessDeleteJenisUnit={setSuccessDeleteJenisUnit}
      />

      {/* 
                ```````````````````````
                DIALOG DELETE NAMA UNIT

                ```````````````````````
            */}
      <DialogDeleteNamaUnit
        setOpenDialogDeleteNamaUnit={setOpenDialogDeleteNamaUnit}
        isOpenDialogDeleteNamaUnit={isOpenDialogDeleteNamaUnit}
        setSuccessDeleteNamaUnit={setSuccessDeleteNamaUnit}
      />

      {/* 
                ```````````````````````````` 
                DIALOG DELETE POSITION TITLE

                ````````````````````````````
            */}

      <DialogDeletePositionTitle
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      {/* 
                `````````````````````````````
                DIALOG DELETE MEMBER EMPLOYEE

                `````````````````````````````
            */}

      <DialogDeleteEmployeeMember
      //*Sebagian besar state di component ini di pindahkan ke Global State
      />

      {/* 
                ````````````
                DIALOG ERROR

                ````````````
            */}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogTambahHead;
