import React, { useState, forwardRef } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  Slide,
  AppBar,
  Container,
  CircularProgress,
  DialogContent,
} from "@material-ui/core";
import SentimentDissatisfiedRoundedIcon from "@material-ui/icons/SentimentDissatisfiedRounded";

import { navigate } from "hookrouter";
import { ToLogin } from "../../../../../constants/config-redirect-url";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSayGoodbye = ({
  classes,
  openDialogSayGoodbye,
  setOpenDialogSayGoodbye,
  setOpenDialogUnsubscribe,
}) => {
  const [loading, setLoading] = useState(false);

  const handleKeepSubscribing = () => {
    setOpenDialogSayGoodbye(false);
    setOpenDialogUnsubscribe(false);
  };

  const handleUnsubscribe = () => {
    setLoading(true);

    Axios.delete(`${URL_API}/account-management/membership:cancel`)
      .then((response) => {
        // // console.log("Unsubs Res", response);

        if (response.status === 200) {
          setTimeout(() => {
            navigate(ToLogin);
            setOpenDialogSayGoodbye(false);
            setOpenDialogUnsubscribe(false);
            window.location.reload();
          }, 2000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("Error >", error.response);
      });
  };

  return (
    <Dialog
      fullScreen
      open={openDialogSayGoodbye}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}
      />

      <Container maxWidth="md" style={{ marginTop: 100 }}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <SentimentDissatisfiedRoundedIcon
              style={{ color: "#eb4d4b", fontSize: 65, marginBottom: 20 }}
            />

            <Typography variant="h5" className={classes.title}>
              <b>Berat rasanya jika harus berpisah</b>
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{
                color: "#888",
                width: 550,
                marginTop: 15,
                textAlign: "center",
              }}
            >
              Kami sangat senang jika anda bersedia membantu kami menjadi lebih
              baik dengan memberikan umpan balik (feedback) ke email kami{" "}
              <b>cs@performate.id</b>
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop={10}
          >
            <Button
              variant="outlined"
              className={classes.button3}
              onClick={handleUnsubscribe}
              style={{ marginRight: 10 }}
            >
              {loading ? (
                <CircularProgress size={15} style={{ color: "#eb4d4b" }} />
              ) : (
                "Unsubscribe"
              )}
            </Button>
            <Button
              variant="outlined"
              className={classes.button2}
              onClick={handleKeepSubscribing}
              style={{ marginLeft: 10 }}
            >
              Tetap subscribe
            </Button>
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default DialogSayGoodbye;
