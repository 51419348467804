import React, { useState, useContext } from "react";
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContentText,
  DialogContent,
  Box,
  CircularProgress,
  Button,
} from "@material-ui/core";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogDeleteMultiple({
  openDialogDeleteMultiple,
  setOpenDialogDeleteMultiple,
  classes,
}) {
  const context = useContext(ContextGlobalDrive);

  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteMultiple = () => {
    //Check If selected Files more than 0
    if (context.selectedFile.length > 0) {
      return Promise.all(
        context.selectedFile.map((item, index) => {
          setLoading(true);

          const params = new URLSearchParams();
          params.append(`DriveFile[${index}][id]`, item);

          return axiosConfig.delete(`${URL_API}/drive/file/batch`, {
            data: params,
          });
        })
      )
        .then((response) => {
          // console.log("Response Delete Multiple File", response);

          // If No Folder Selected, Finish The Request
          if (context.selectedFolder.length === 0) {
            context.setSuccessTriggerFile(Math.floor(Math.random() * 1000 + 1));
            context.setModalResponse200(true);
            context.setMessages("Deleted multiple successfully");

            context.setSelectedFile([]); // Empty Selected File
            setOpenDialogDeleteMultiple(false);

            setLoading(false);

            // If There is/are folder(s) selected then rquest to delete it
          } else if (context.selectedFolder.length > 0) {
            return Promise.all(
              context.selectedFolder.map((item, index) => {
                setLoading(true);

                const params = new URLSearchParams();
                params.append(`DriveFolder[${index}][id]`, item);

                return axiosConfig.delete(`${URL_API}/drive/folder/batch`, {
                  data: params,
                });
              })
            )
              .then((response) => {
                // console.log("Response Delete Multiple Folder", response);

                context.setSuccessTriggerFolder(
                  Math.floor(Math.random() * 1000 + 1)
                );
                context.setSuccessTriggerFile(
                  Math.floor(Math.random() * 1000 + 1)
                );
                context.setModalResponse200(true);
                context.setMessages("Deleted multiple successfully");

                context.setSelectedFolder([]); // Empty Selected Folder
                context.setSelectedFile([]); // Empty Selected File
                setOpenDialogDeleteMultiple(false);

                setLoading(false);
              })
              .catch((error) => {
                // console.log("Error : ", error);
                setLoading(false);

                // error response
                if (error.response !== undefined) {
                  let responseStatus = error.response.status;

                  setErrorStatus(responseStatus);
                  setOpenDialogError(true);

                  if ([422, 400].includes(responseStatus)) {
                    if (
                      error.response.data.info.errors !== null &&
                      error.response.data.info.errors !== undefined
                    ) {
                      if (error.response.data.info.errors.length > 0) {
                        setOpenDialogError(true);
                        setListError(error.response.data.info.errors);
                      }
                    }
                  }

                  if (
                    [503, 500, 403, 429, 405, 404, 401].includes(responseStatus)
                  ) {
                    if (error.response.data.info.hasOwnProperty("message")) {
                      setTextErrorMessage(
                        `Error ${responseStatus} : ` +
                          error.response.data.info.message
                      );
                    }
                  }
                } else {
                  setOpenDialogError(true);
                  setTextErrorMessage("Whoops, something went wrong !");
                }
              });
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });

      // IF No Files Selected & Folder Selected > 0, Do This!
    } else if (context.selectedFolder.length > 0) {
      return Promise.all(
        context.selectedFolder.map((item, index) => {
          setLoading(true);

          const params = new URLSearchParams();
          params.append(`DriveFolder[${index}][id]`, item);

          return axiosConfig.delete(`${URL_API}/drive/folder/batch`, {
            data: params,
          });
        })
      )
        .then((response) => {
          // console.log("Response Delete Multiple Folder", response);

          context.setSuccessTriggerFolder(Math.floor(Math.random() * 1000 + 1));
          context.setModalResponse200(true);
          context.setMessages("Deleted multiple successfully");

          context.setSelectedFolder([]); // Empty Selected Folder
          context.setSelectedFile([]); // Empty Selected File
          setOpenDialogDeleteMultiple(false);

          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  };

  return (
    <Dialog
      open={openDialogDeleteMultiple}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ textAlign: "center" }}>
        <Box marginTop={2} marginBottom={2}>
          <HelpOutlineRoundedIcon
            style={{ fontSize: 30, color: "#d64253", marginBottom: 12 }}
          />
        </Box>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#333" }}
          >
            Apakah Anda yakin ingin menghapus{" "}
            <strong>{context.selectedFolder.length}</strong> Folder dan{" "}
            <strong>{context.selectedFile.length}</strong> File yang ditandai ?
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          marginTop: 20,
          marginBottom: 25,
        }}
      >
        <Button
          variant="contained"
          disableElevation
          className={classes.button2}
          onClick={() => setOpenDialogDeleteMultiple(false)}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className={classes.button}
          disabled={
            context.selectedFolder.length === 0 &&
            context.selectedFile.length === 0
          }
          onClick={handleDeleteMultiple}
        >
          {loading ? <CircularProgress color="#fff" size={15} /> : "Delete"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogDeleteMultiple;
