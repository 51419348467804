import React, { useContext } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import ContextAllMA from "./context/AllMaContext";
import { theme } from "./style/StyleAllMA";

import ViewAll from "./All/ViewAll";
import DialogExportAllMA from "./components/DialogExportAllMA";
import Snackbeer from "../../../components/Snackbeer";

function ViewAllMA() {
  const context = useContext(ContextAllMA);

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        bgcolor="#fafafa"
        position="fixed"
        zIndex={110}
        width="100%"
        pb={3}
      ></Box>

      <Box ml={7} marginTop={theme.spacing(0.1)}>
        <ViewAll />
      </Box>

      <Snackbeer
        setModalResponse200={context.setModalResponse200}
        isModalResponse200={context.isModalResponse200}
        messages={context.messages}
      />

      <DialogExportAllMA
        isModalExportMA={context.isModalExportMA}
        setModalExportMA={context.setModalExportMA}
      />
      {/* </Container> */}
    </MuiThemeProvider>
  );
}

export default ViewAllMA;
