import { createMuiTheme, fade } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Nunito"].join(","),
  },
});

export const styles = () => ({
  // Typogrtaphy
  title: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
  },
  title333: {
    // fontFamily: "Roboto",
    color: "#333",
    fontWeight: "normal",
  },
  title555: {
    // fontFamily: "Roboto",
    color: "#555",
    fontWeight: "normal",
  },
  title888: {
    // fontFamily: "Roboto",
    color: "#888",
    fontWeight: "normal",
  },
  btnTxt: {
    // fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "#d64253",
    fontSize: 14,
    "&:hover": {
      color: "#DE6775",
      cursor: "pointer",
    },
  },

  // Button
  actionBtn: {
    // fontFamily: "Roboto",
    textTransform: "capitalize",
    fontSize: 12,
    padding: `2px 6px`,
    borderWidth: 0,
    color: "#10266F", // #0F256B
    backgroundColor: "#FBEAEA",
    "&:hover": {
      backgroundColor: "#f7d5d5",
    },
  },
  groupBtn: {
    // fontFamily: "Roboto",
    textTransform: "capitalize",
    fontSize: 12,
    padding: `2px 6px`,
    borderWidth: 0,
    color: "#10266F",
    backgroundColor: "#ECECEC",
    "&:hover": {
      backgroundColor: "#d4d4d4",
    },
  },
  button0: {
    // fontFamily: "Roboto",
    textTransform: "capitalize",
    fontSize: 11,
    // fontWeight: "700",
    padding: `3px 6px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    // fontFamily: "Roboto",
    textTransform: "capitalize",
    fontSize: 11,
    // fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  search: {
    marginBottom: 20,
  },
  inputRoot: {
    color: "inherit",
    // fontFamily: "Roboto",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: 12,
    color: "#222",
  },
  badgeCount: {
    margin: theme.spacing.unit * 2,
    width: "100%",
  },
  fieldPreset: {
    width: 180,
    // fontFamily: "Roboto",
    padding: "8px 16px",
    borderRadius: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  hoverItem: {
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#FDDBD8",
      color: "#CF364A",
    },
  },
  listItemMenu: {
    padding: "8px 0 0 0",
    "&:hover": {
      cursor: "pointer",
    },
  },
  // ========================================
  // Styled Checkbox
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3.5,
    width: 15,
    height: 15,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d1354a", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 15,
      height: 15,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a", // #106ba3
    },
  },

  // ========================================
  // Set Permission Tabs
  // ========================================
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconRadio: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    padding: 0,
  },
  checkedIconRadio: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1354a",
    },
  },
});
