import React, { useContext } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import PictResultValue from "../../../../assets/images/Group_2620.png";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";
import ConvertExponentialToDecimal from "../../../../utilities/ConvertExponentialToDecimal";
import ConvertExponentialToSubstring from "../../../../utilities/ConvertExponentialToSubstring";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const TextOverDotLineChartSecond = (props) => {
  const { classes, goalDetailState, isExponent } = props;

  const context = useContext(ContextGoalDetail);

  return (
    <List>
      <ListItem>
        <ListItemIcon
          style={{
            marginLeft: 8,
          }}
        >
          <img
            src={PictResultValue}
            style={{ width: 40, height: 40 }}
            alt="."
          />
        </ListItemIcon>

        <ListItemText
          className={classes.listItemtext}
          primary={
            <Typography variant="subtitle2" className={classes.title}>
              {goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE ? (
                isExponent === true ? (
                  <b>
                    {FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value
                    )}
                    % &nbsp; (
                    {ConvertExponentialToDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value_achievement * 100
                    )}
                    %)
                  </b>
                ) : (
                  <b>
                    {FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value
                    )}
                    % &nbsp; (
                    {FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .result_value_achievement * 100
                    )}
                    %)
                  </b>
                )
              ) : (
                <b>
                  {context.modeRupiahOn === true
                    ? `Rp ${FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .result_value
                      )}`
                    : FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .result_value
                      )}
                  &nbsp; (
                  {goalDetailState.calculatedValue.resultOriented.result_value_achievement
                    .toString()
                    .indexOf("e") !== -1
                    ? FormatDecimal(
                        ConvertExponentialToSubstring(
                          goalDetailState.calculatedValue.resultOriented
                            .result_value_achievement
                        )
                      )
                    : FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .result_value_achievement * 100
                      )}
                  %)
                </b>
              )}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}
            >
              {MyLang() ? bahasa.resultVal : english.resultVal}
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
};

export default TextOverDotLineChartSecond;
