import React from "react";
import { navigate } from "hookrouter";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";

import styles from "../Style/navStyle";

const useStyles = makeStyles(styles);

const NavRight = ({ origin }) => {
  const classes = useStyles();
  const navClass = origin === "1" ? classes.navLink : classes.navLink2;

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button className={navClass} onClick={() => navigate("/login")}>
          Login
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button className={classes.navReg} onClick={handleTryFree}>
          Coba Gratis
        </Button>
      </ListItem>
    </List>
  );
};

export default NavRight;
