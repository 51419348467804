import React from "react";
import { navigate } from "hookrouter";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import styles from "../Style/navStyle";
import CustomDropdown from "./CustomDropdown";

const useStyles = makeStyles(styles);

const NavLeft = ({ origin }) => {
  const classes = useStyles();
  const navClass = origin === "1" ? classes.navLink : classes.navLink2;

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Solusi Bisnis"
          buttonProps={navClass}
          dropdownList={[
            <Box
              className={classes.dropdownLink}
              onClick={() => navigate("/hrd")}>
              HR
            </Box>,
            <Box
              className={classes.dropdownLink}
              onClick={() => navigate("/marketing")}>
              Marketing
            </Box>,
            <Box
              className={classes.dropdownLink}
              onClick={() => navigate("/sales")}>
              Sales
            </Box>,
            <Box
              className={classes.dropdownLink}
              onClick={() => navigate("/logistics")}>
              Logistik
            </Box>,
            <Box
              className={classes.dropdownLink}
              onClick={() => navigate("/operational")}>
              Operasional
            </Box>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button className={navClass} onClick={() => navigate("/testimoni")}>
          Testimonial
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button className={navClass} onClick={() => navigate("/price")}>
          Harga
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button className={navClass} onClick={() => navigate("/contact")}>
          Kontak
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Button className={navClass} onClick={() => navigate("/support")}>
          Support
        </Button>
      </ListItem> */}
    </List>
  );
};

export default NavLeft;
