import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";

import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogCopyFile({
  openDialogCopyFile,
  setOpenDialogCopyFile,
  classes,
  item,
}) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
          LOCAL STATE
      ==================================================== */
  const [loading, setLoading] = useState(false);
  const [loadingTree, setLoadingTree] = useState(false);
  const [listFolders, setListFolders] = useState({});
  const [folderParentId, setFolderParentId] = useState("");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
          SIDE EFFECT
      ==================================================== */
  useEffect(() => {
    if (openDialogCopyFile === true) {
      setLoadingTree(true);

      axiosConfig
        .get(`${URL_API}/drive/folder`)
        .then((response) => {
          // console.log("Folder Collection", response);

          if (response.status === 200) {
            // add property child_folder
            const folders = response.data.data.map((folder) => {
              folder.child_folder = [];
              return folder;
            });

            // push child folder to parent folder
            const folders2 = folders.map((parent) => {
              folders.forEach((folder) => {
                if (parent.id === folder.folder_parent_id) {
                  parent.child_folder.push(folder);
                }

                return folder;
              });

              return parent;
            });

            // filter for parent folders
            const newFolder = folders2.filter(
              (item) => item.folder_parent_id === null
            );

            // add list folder to rot object
            const newFolderList = {
              id: "",
              name: "Drive",
              child_folder: newFolder,
            };

            // // console.log("Child Parent", newFolderList);
            setListFolders(newFolderList);
            setLoadingTree(false);
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoadingTree(false);
        });
    }
  }, [openDialogCopyFile]);
  /* ====================================================
      FUNCTION / LOCAL METHODS
    ==================================================== */
  // GET paren folder ID
  const handleTreeFolder = (id) => {
    // console.log("THE parent ID", id);
    setFolderParentId(id);
  };

  // render Tree Item
  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      className={classes.title}
      label={
        <Typography
          variant="subtitle2"
          className={classes.title}
          style={{ color: "#555", fontWeight: "600" }}
        >
          {nodes.name}
        </Typography>
      }
      onClick={() => handleTreeFolder(nodes.id)}
    >
      {Array.isArray(nodes.child_folder)
        ? nodes.child_folder.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const handleCopyFile = () => {
    setLoading(true);

    const params = new URLSearchParams();

    params.append(`DriveFile[0][id]`, item.id);
    params.append(`DriveFile[0][folder_id]`, folderParentId);

    console.log(`Prent : ${folderParentId}, Id : ${item.id}`);

    axiosConfig
      .post(`${URL_API}/drive/file/batch:copy`, params)
      .then((response) => {
        // console.log("Response Copy File Folder", response);

        if (response.status === 200) {
          context.setSuccessTriggerFile(Math.floor(Math.random() * 1000 + 1));
          context.setModalResponse200(true);
          context.setMessages("File moved successfully");

          context.setSelectedFolder([]); // Empty Selected Folder
          context.setSelectedFile([]); // Empty Selected File
          setOpenDialogCopyFile(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error.response);
        setLoading(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogCopyFile}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Copy File</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 375 }}>
        <Box marginBottom={2} textAlign="center">
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#333" }}
          >
            Pilih folder tujuan untuk <i>Copy File</i>
          </Typography>
        </Box>

        {loadingTree ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={15} />
          </Box>
        ) : (
          <TreeView
            className={classes.rootTreeView}
            defaultExpanded={[""]}
            defaultCollapseIcon={
              <FolderOpenIcon style={{ color: "#888" }} fontSize="small" />
            }
            defaultExpandIcon={
              <CreateNewFolderIcon style={{ color: "#888" }} fontSize="small" />
            }
            defaultEndIcon={
              <FolderIcon style={{ color: "#888" }} fontSize="small" />
            }
          >
            {renderTree(listFolders)}
          </TreeView>
        )}

        <DialogContentText id="alert-dialog-description">
          <DialogActions
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              className={classes.button2}
              onClick={() => setOpenDialogCopyFile(false)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              onClick={handleCopyFile}
            >
              {loading ? <CircularProgress color="#fff" size={15} /> : "Copy"}
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogCopyFile;
