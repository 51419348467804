import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  fade,
} from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

export const styles = (theme) => ({
  title: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },

  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },

  buttonOutlined: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },

  buttonDialog: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
  },

  textField: {
    minWidth: 425,
    [theme.breakpoints.only("xs")]: {
      minWidth: 200,
    },
  },
});
