import React, { useState, Fragment, useContext, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

import HelpIcon from "@material-ui/icons/Help";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseIcon from "@material-ui/icons/Close";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";
import DialogError from "../../../../components/DialogError";
import DialogFeedbackSetAsCompleteMA from "./DialogFeedbackSetAsCompleteMA";
import handleError from "../../../Report/components/global/handleError";

function DialogSetAsCompleteMultipleMA({
  isOpenDialogSetAsCompleteMultiple,
  setOpenDialogSetAsCompleteMultiple,
  selected,
  classes,
  validMA,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingMA, setLoadingMA] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [listMA, setListMA] = useState([]);

  const [responseSetAsComplete, setResponseSetAsComplete] = useState([]);
  const [openDialogFeedbackSetAsComplete, setOpenDialogFeedbackSetAsComplete] =
    useState(false);

  useEffect(() => {
    if (isOpenDialogSetAsCompleteMultiple === true) {
      function getDataGoal() {
        setLoadingMA(true);
        return Promise.all(
          selected.map((item) => {
            return axiosConfig.get(`${URL_API}/swd/measured-activity/${item}`);
          })
        )
          .then((response) => {
            // // console.log("ORI Response SAC", response);
            setListMA(response);

            setLoadingMA(false);
          })
          .catch((error) => {
            setLoadingMA(false);
            // console.log("Error : ", error);
          });
      }

      getDataGoal();
    }
  }, [isOpenDialogSetAsCompleteMultiple]);

  /* ==========================
   Check Validation MA
    If True (success.length > 0) = Valid
    If False (success.length is empty : []) = Not Valid 
    =========================== */
  const checkValidMA = validMA.map((item) => item.data.data.success.length > 0);

  // // console.log("check valid MA", checkValidMA);

  // HANDLE Function Set As Complete Multiple MA
  const handleSetAsCompleteMultiple = () => {
    setLoading(true);

    const params = new URLSearchParams();

    selected.length > 0 &&
      selected.map((item, index) => {
        params.append(`MeasuredActivity[${index}][id]`, item);
      });

    axiosConfig
      .put(`${URL_API}/swd/measured-activity/batch:completed`, params, {
        timeout: 20000,
      })
      .then((response) => {
        // console.log("RESponsE SEt aS coMPLeTe", response);

        setResponseSetAsComplete(response.data.data);
        setOpenDialogFeedbackSetAsComplete(true);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        // console.log("Error : ", error);
        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;
          setErrorStatus(responseStatus);
          setOpenDialogError(true);
          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }
          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorInformationValue(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorInformationValue("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogSetAsCompleteMultiple}
        onClose={() => setOpenDialogSetAsCompleteMultiple(false)}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <HelpIcon
            style={{ fontSize: 36, color: "#FFAC9C", marginBottom: 12 }}
          />
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}
            >
              <b>
                Apakah Anda yakin ingin mengubah status{" "}
                <i style={{ fontSize: 16 }}>
                  <span style={{ color: "#d1354a" }}>{selected.length}</span>{" "}
                  Measured Activity
                </i>{" "}
                menjadi <i>Completed </i> ?
                <br />
                {loadingMA ? (
                  <CircularProgress
                    style={{ color: "#d1354a", fontSize: 14 }}
                  />
                ) : (
                  <List>
                    {listMA.length > 0 &&
                      listMA.map((item, i) => (
                        <ListItem key={i}>
                          <ListItemAvatar>
                            {checkValidMA[i] ? (
                              <CheckSharpIcon style={{ color: "#1EBC61" }} />
                            ) : (
                              <CloseIcon style={{ color: "#d1354a" }} />
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <span
                                style={{
                                  color: "#333",
                                  fontStyle: "normal",
                                }}
                              >
                                <b>{item.data.data.name}</b>{" "}
                                <span
                                  className={
                                    checkValidMA[i]
                                      ? classes.validGoal
                                      : classes.notValidGoal
                                  }
                                >
                                  {checkValidMA[i] ? "valid" : "tidak valid"}
                                </span>
                              </span>
                            }
                            secondary={
                              <span
                                style={{
                                  fontStyle: "normal",
                                }}
                              >
                                {item.data.data.member &&
                                  item.data.data.member.first_name}{" "}
                                {item.data.data.member &&
                                  item.data.data.member.last_name}
                              </span>
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                )}
              </b>
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}
            >
              <i>
                Hanya Measured Activity yang Valid yang dapat diubah statusnya
                menjadi Completed
              </i>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            marginLeft: 15,
            justifyContent: "center",
            marginBottom: 15,
          }}
        >
          <Button
            onClick={handleSetAsCompleteMultiple}
            variant="contained"
            className={classes.buttonModalDelete}
            style={{ paddingRight: 25, paddingLeft: 25 }}
          >
            {loading !== true ? (
              "Ya"
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogFeedbackSetAsCompleteMA
        classes={classes}
        openDialogFeedbackSetAsComplete={openDialogFeedbackSetAsComplete}
        setOpenDialogFeedbackSetAsComplete={setOpenDialogFeedbackSetAsComplete}
        responseSetAsComplete={responseSetAsComplete}
        listMA={listMA}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default DialogSetAsCompleteMultipleMA;
