import React from "react";
import { Box, FormControl, Select, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const PaginationAttendanceRequest = ({ 
  classes, 
  dataRequestList,
  handleChangePage,
  handleChangeRowsPerPage 
}) => {

  return (
    <Box
      my={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography className={classes.title888} variant="caption">
          Total Records{" "}
          <span
            style={{
              backgroundColor: "#aaa",
              color: "#fff",
              padding: "0 5px",
              borderRadius: 4,
            }}
          >
            <b>{dataRequestList.options.paging.count}</b>
          </span>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography className={classes.title888} variant="caption">
          Datas Per Page
        </Typography>
        <FormControl size="small" style={{ margin: "0 10px", minWidth: 50 }}>
          <Select native value={dataRequestList.options.paging.limit} onChange={handleChangeRowsPerPage}>
            <option value={1}>1</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
        </FormControl>

        <Pagination
          variant="text"
          shape="rounded"
          page={dataRequestList.options.paging.page}
          count={Math.ceil(dataRequestList.options.paging.count / dataRequestList.options.paging.limit)}
          onChange={handleChangePage}
        />
      </Box>
    </Box>
  );
};

export default PaginationAttendanceRequest;
