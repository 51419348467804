import React, { useState, useContext } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ClickAwayListener,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import classNames from "classnames";

import ContextGoalDetail from "../../Goal/Context/ContextGoal";
import filterSvg from "../../../../assets/images/SVG/Filter.svg";
import AnchorFilterPeriod from "./AnchorFilterPeriod";
import AnchorFilterWeight from "./AnchorFilterWeight";
import AnchorFilterProject from "./AnchorFilterProject";

const AnchorFilterGoal = ({
  classes,
  periodCollection,
  onPeriodData,
  weightValue,
  onWeightValue,
  category,
  setCategory,
  type,
  setType,
}) => {
  const context = useContext(ContextGoalDetail);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);

  const [openPeriod, setOpenPeriod] = useState(false);
  const [openWeight, setOpenWeight] = useState(false);
  const [openProject, setOpenProject] = useState(false);

  const ExpandTrue = () => (
    <ExpandMore style={{ color: "#8F8F8F" }} fontSize="small" />
  );
  const ExpandFalse = ({ color }) => (
    <ExpandLess style={{ color }} fontSize="small" />
  );

  const handlePeriod = () => setOpenPeriod((s) => !s);
  const handleWeight = () => setOpenWeight((s) => !s);
  const handleProject = () => setOpenProject((s) => !s);

  const handleShow = (event) => {
    if (anchorEl && anchorEl?.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseAway = (event) => {
    if (anchorEl?.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        size="small"
        variant="outlined"
        className={classes.btnFilter}
        onClick={handleShow}
      >
        <img width={20} src={filterSvg} />
        <span style={{ marginLeft: 8 }}>Filter</span>
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow in={Boolean(anchorEl)} id="menu-list">
            <Paper className={classes.dropdown} style={{ zIndex: 30 }}>
              <ClickAwayListener
                disableReactTree={loading}
                onClickAway={handleCloseAway}
              >
                <div>
                  <List dense>
                    <ListItem
                      button
                      onClick={handlePeriod}
                      style={{
                        backgroundColor:
                          openPeriod && "rgba(251, 234, 234, 0.5)",
                        color: openPeriod && "#C8203C",
                      }}
                    >
                      <ListItemText primary="Period" />
                      {openPeriod ? (
                        <ExpandFalse color="#C8203C" />
                      ) : (
                        <ExpandTrue />
                      )}
                    </ListItem>
                    <Collapse in={openPeriod} timeout="auto" unmountOnExit>
                      <Box px={3} py={2}>
                        <AnchorFilterPeriod
                          periodCollection={periodCollection}
                          classes={classes}
                          onPeriodData={onPeriodData}
                        />
                      </Box>
                    </Collapse>

                    <ListItem
                      button
                      onClick={handleWeight}
                      style={{
                        backgroundColor:
                          openWeight && "rgba(251, 234, 234, 0.5)",
                        color: openWeight && "#C8203C",
                      }}
                    >
                      <ListItemText primary="Weight" />
                      {openWeight ? (
                        <ExpandFalse color="#C8203C" />
                      ) : (
                        <ExpandTrue />
                      )}
                    </ListItem>
                    <Collapse in={openWeight} timeout="auto" unmountOnExit>
                      <Box px={3} py={2}>
                        <AnchorFilterWeight
                          classes={classes}
                          weightValue={weightValue}
                          onWeightValue={onWeightValue}
                        />
                      </Box>
                    </Collapse>

                    <ListItem
                      button
                      onClick={handleProject}
                      style={{
                        backgroundColor:
                          openProject && "rgba(251, 234, 234, 0.5)",
                        color: openProject && "#C8203C",
                      }}
                    >
                      <ListItemText primary="Project" />
                      {openProject ? (
                        <ExpandFalse color="#C8203C" />
                      ) : (
                        <ExpandTrue />
                      )}
                    </ListItem>
                    <Collapse in={openProject} timeout="auto" unmountOnExit>
                      <Box px={3} py={2}>
                        <AnchorFilterProject
                          classes={classes}
                          type={type}
                          setType={setType}
                          category={category}
                          setCategory={setCategory}
                        />
                      </Box>
                    </Collapse>
                  </List>

                  {/* <Box px={2} pb={2} minWidth={200}>
                    <Box mt={2} mb={1.5}>
                      <Divider />
                    </Box>

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.button0}
                        disabled={loading}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.button1}
                        style={{ marginLeft: 7.5 }}
                        onClick={handleShowResult}
                      >
                        {loading ? "Loading..." : "Show results"}
                      </Button>
                    </Box>
                  </Box> */}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default AnchorFilterGoal;
