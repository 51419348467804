import React from "react";
import { Box, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PersonIcon from "@material-ui/icons/Person";

import { styles } from "../Style/StyleNewSO";

const useStyles = makeStyles(styles);

function AvatarIconInOrgChart(props) {
  const classes = useStyles();

  const { mainRoot } = props;

  return (
    <Box
      className={
        mainRoot !== "1"
          ? classes.horizontalLine
          : classes.horizontalWithoutLine
      }
      marginLeft={2}
      marginBottom={7}>
      <Avatar className={classes.small}>
        <PersonIcon style={{ fontSize: 17 }} />
      </Avatar>
    </Box>
  );
}

export default AvatarIconInOrgChart;
