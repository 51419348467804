import React, { useState, Fragment, useContext, useEffect } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Tabs,
  Tab,
  TablePagination,
  LinearProgress,
  Table,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import Add from "@material-ui/icons/Add";

import ContextAllMA from "../context/AllMaContext";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import PictLitleIconMA from "../../Goal/ComponentsGoalResult/PictEmptyStateGoalResult";

import DialogExportMaResult from "../../MeasureActivities/ComponentResultMA/DialogExportMaResult";

import { useIndexedDB } from "react-indexed-db";
import DialogAddResultMA from "./DialogAddResultMA";
import ListHeaderMAResult from "./ListHeaderMAResult";
import ListItemMaResult from "./ListItemMaResult";
import ListActivityLog from "./ListActivityLog";
import ListItemTitleResult from "./ListItemTitleResult";

// const SWD_MA_INPUT_METHOD_INCREMENT = "swd_ma_input_method_increment";
// const SWD_MA_INPUT_METHOD_LAST_VALUE = "swd_ma_input_method_last_value";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#282560",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const ListCollectionAllMAResult = (props) => {
  const locationPathMaId = window.location.pathname.split("/")[2];
  const {
    classes,
    collectionMaResultList,
    maDetailState,
    userTokenState,
    loader,
  } = props;

  const theme = useTheme();
  const { clear } = useIndexedDB("video");
  const context = useContext(ContextAllMA);

  /*
        ```````````````
        HANDLE COLLAPSE

        ```````````````
    */
  const [openCollapse, setOpenCollapse] = useState(true);
  const [disableCreateResult, setDisableCreateResult] = useState(false);
  const [openDialogAddResultMA, setOpenDialogAddResultMA] = useState(false);

  // toggle result group
  const [toggleGroup, setToggleGroup] = useState(false);
  const [toggleNoData, setToggleNoData] = useState(true);

  const [valueTab, setValueTab] = useState(0);

  function handleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  const handleDialogModalResultMA = () => {
    setOpenDialogAddResultMA(true);

    //*
    clear().then(() => {
      // console.log("Clear all inside IndexedDB !");
    });

    localStorage.removeItem("video_count");
  };

  // Handle status code 403 from create MA Reesult For Disableing Crate Result
  useEffect(() => {
    if (userTokenState !== undefined) {
      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity/${locationPathMaId}/result/create`
        )
        .then((response) => {
          // // console.log("Response create GR", response);
        })
        .catch((error) => {
          // console.log("Error : ", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              context.setDisableCreateMAResult(true);
            }
          }
        });
    } else {
      // console.log("Usertoken not exist");
    }
  }, []);

  /*
        ```````````````````````
        HANDLE EXPORT MA RESULT

        ```````````````````````
    */
  const [isModalExportMaResult, setModalExportMaResult] = useState(false);

  /*
    ===================================================================
      HANDLE PAGINATION //*Will be to global state
    ===================================================================
  */

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    context.setLoaderForPaging(true);
    context.setPage(newPage);
    context.setOffset((parseInt(newPage + 1) - 1) * context.rowsPerPageResult);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    context.setLoaderForPaging(true);

    // // console.log("Rows perpage : ", event.target.value);
    context.setRowsPerPageResult(parseInt(event.target.value, 8));
    context.setPage(0);
  };

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
  };

  const handleToggleGroup = (e) => {
    setToggleGroup(e.target.checked);
  };

  const handleToggleNoData = (e) => {
    setToggleNoData(e.target.checked);
  };

  // FLAT Goal Result Lists
  let COLLECTION_GROUP_RESULT = [];

  const collectionWithNoGroup = collectionMaResultList
    .map((el) => {
      if (el.hasOwnProperty("results")) {
        return el.results;
      } else {
        return [];
      }
    })
    .flat();

  const collectionNoData = collectionMaResultList.filter((el) => {
    return el.results?.length > 0;
  });

  if (toggleNoData) {
    COLLECTION_GROUP_RESULT = collectionNoData;
  } else {
    COLLECTION_GROUP_RESULT = collectionMaResultList;
  }

  return (
    <Fragment>
      <Paper className={classes.paperColumnDuaRowFirst}>
        {loader === true && (
          <div className={classes.rootLinearProgress}>
            <LinearProgress style={{ height: 2, color: "#d64253" }} />
          </div>
        )}

        <Box display="flex" justifyContent="space-between" mb={2}>
          <StyledTabs value={valueTab} onChange={handleChangeTab}>
            <Tab
              classes={{ root: classes.widthTab }}
              label={
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{
                    marginBottom: -10,
                    textTransform: "capitalize",
                  }}
                >
                  <b>Latest MA Result</b>
                </Typography>
              }
            />
            <Tab
              classes={{ root: classes.widthTab }}
              label={
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{
                    marginBottom: -10,
                    textTransform: "capitalize",
                  }}
                >
                  <b>Activity Log</b>
                </Typography>
              }
            />
          </StyledTabs>
          <Box display="flex" marginTop={theme.spacing(0.1)}>
            <Box ml={2}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={toggleGroup}
                    onChange={handleToggleGroup}
                  />
                }
                label={
                  <Typography variant="subtitle2" className={classes.title555}>
                    Group Cycle
                  </Typography>
                }
              />
            </Box>

            {toggleGroup && (
              <Box ml={2}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={toggleNoData}
                      onChange={handleToggleNoData}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                    >
                      Hide Result No Data
                    </Typography>
                  }
                />
              </Box>
            )}

            <Box marginLeft={theme.spacing(0.1)}>
              <Button
                onClick={() => setModalExportMaResult(true)}
                variant="outlined"
                className={classes.buttonOutlined}
                style={{ borderWidth: 1 }}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey", textTransform: "capitalize" }}
                >
                  Export
                </Typography>
              </Button>
            </Box>

            <Box ml={2}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                |
              </Typography>
            </Box>

            <Box ml={2}>
              <Button
                size="small"
                disabled={
                  maDetailState.status_id === "2"
                    ? true
                    : maDetailState.status_id === "4"
                    ? true
                    : disableCreateResult
                }
                variant="outlined"
                className={classes.button}
                startIcon={<Add />}
                onClick={handleDialogModalResultMA}
              >
                Add Result
              </Button>
            </Box>
          </Box>
        </Box>

        {valueTab === 0 && (
          <Table size="small" aria-label="a dense table">
            {COLLECTION_GROUP_RESULT.length === 0 &&
              maDetailState.status_id !== "4" && (
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle2" className={classes.title333}>
                    <PictLitleIconMA />
                    Anda belum memiliki <i>MA Result</i> ;(
                  </Typography>
                </Box>
              )}

            {COLLECTION_GROUP_RESULT.length > 0 && (
              <ListHeaderMAResult
                cycle={toggleGroup ? "Cycle" : "Date & Time"}
              />
            )}

            {toggleGroup && COLLECTION_GROUP_RESULT.length > 0
              ? COLLECTION_GROUP_RESULT.map((item, idx) => {
                  if (item.hasOwnProperty("results")) {
                    return (
                      <ListItemTitleResult
                        key={idx}
                        item={item}
                        classes={classes}
                        maDetailState={maDetailState}
                        userToken={userTokenState}
                      />
                    );
                  } else {
                    return (
                      <ListItemMaResult
                        key={idx}
                        item={item}
                        classes={classes}
                        maDetailState={maDetailState}
                        userToken={userTokenState}
                      />
                    );
                  }
                })
              : null}

            {!toggleGroup &&
              maDetailState?.status_id !== "2" &&
              collectionWithNoGroup.length > 0 &&
              collectionWithNoGroup.map((item, idx) => {
                return (
                  <ListItemMaResult
                    key={idx}
                    item={item}
                    classes={classes}
                    maDetailState={maDetailState}
                    userToken={userTokenState}
                  />
                );
              })}
          </Table>
        )}

        {valueTab === 0 && COLLECTION_GROUP_RESULT.length > 0 && (
          <Box marginTop={theme.spacing(0.3)}>
            <TablePagination
              classes={{
                toolbar: classes.toolbar,
                root: classes.noBorderBottom,
              }}
              rowsPerPageOptions={[8]}
              colSpan={3}
              count={context.collectionMAResultLength}
              rowsPerPage={context.rowsPerPageResult}
              page={context.page}
              labelDisplayedRows={({ from, to, count }) => (
                <Typography variant="subtitle2" className={classes.title}>
                  <h4>
                    {`${from} - ${to === -1 ? count : to} dari total `}
                    <strong>{count}</strong> baris
                  </h4>
                </Typography>
              )}
              labelRowsPerPage="Baris setiap halaman"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}

        {valueTab === 1 && <ListActivityLog classes={classes} />}
      </Paper>

      <DialogAddResultMA
        openDialogAddResultMA={openDialogAddResultMA}
        setOpenDialogAddResultMA={setOpenDialogAddResultMA}
        maDetailState={maDetailState}
        classes={classes}
      />

      <DialogExportMaResult
        isModalExportMaResult={isModalExportMaResult}
        setModalExportMaResult={setModalExportMaResult}
        maDetailState={maDetailState}
      />
    </Fragment>
  );
};

export default ListCollectionAllMAResult;
