import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Radio,
  Chip,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeIcon from "@material-ui/icons/DateRange";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import clsx from "clsx";
import classNames from "classnames";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";
import SliderTwoSide from "../global/SliderTwoSide";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";

import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

import DialogRemarksLog from "../../../../components/DialogRemarksLog";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#3498db",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#3498db",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogCretaeProject = ({
  classes,
  openCreateProject,
  setOpenCreateProject,
}) => {
  const { setTriggerProject } = useContext(ContextProject);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  // main input
  const [project, setproject] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [target, setTarget] = useState(100);
  const [start, setStart] = useState(0);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scope, setScope] = useState(50);
  const [schedule, setSchedule] = useState(50);
  // collections
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [members, setMembers] = useState([]);
  const [selected, setSelected] = useState([]);
  // conditional
  const [isExclude, setIsExclude] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [status403, setStatus403] = useState(false);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openCreateProject) {
      axiosConfig
        .get(`${URL_API}/swd/goal/create`)
        .then((res) => {
          const result = res.data.data;

          // // console.log("proj create", result);

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
            setMembers(result.projectMemberCollections);
          }
        })
        .catch((error) => {
          // console.log("ERROR", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setStatus403(true);
            }
          }

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openCreateProject]);

  const projectMembersId =
    selected.length > 0 ? selected.map((el) => el.id) : null;

  const handleShow = (event) => {
    if (anchor && anchor.contains(event.target)) {
      setAnchor(null);
    } else {
      setAnchor(event.currentTarget);
    }
  };

  const handleCloseAway = (event) => {
    if (anchor.contains(event.target)) {
      return;
    }
    setAnchor(null);
  };

  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setOwnerId(data.id);
    }
  };

  const handleChooseMembers = (e, data) => {
    if (data !== null) {
      setSelected((prev) => [...prev, data]);
    }
  };

  const handleDeleteMember = (id) => {
    setSelected((prev) => prev.filter((el) => el.id !== id));
  };

  const handleExcludeWeekend = (e) => {
    setIsExclude(e.target.checked);
  };

  const handleStartDate = (data) => {
    setStartDate(data);

    const isAfter = moment(data).isAfter(endDate, "days");

    if (isAfter) {
      setEndDate(moment(data).add(1, "days"));
    }

    if (endDate === null) {
      setEndDate(data);
    }
  };

  const handleCreateProject = () => {
    setLoading(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data = {
      Goal: {
        name: project,
        owner_id: ownerId,
        input_method_id: "1",
        achievement_type_id: "1",
        calculation_method_id: "3",
        value_type_id: "2",
        period_id: null,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        start_value: start,
        target_value: target,
        target_range_value: null,
        description: description,
        time_frame_id: "2",
        target_type_id: "1",
        is_evidence_required: "0",
        goal_category: "PROJECT",
        achievement_calculation_method:
          Number(scope) === 0
            ? "SCHEDULE"
            : Number(schedule) === 0
            ? "SCOPE"
            : "SCOPE SCHEDULE",
        project_members: Object.assign({}, projectMembersId),
        project_scope_weighting: scope,
        project_schedule_weighting: schedule,
        exclude_weekend: isExclude ? 1 : 0,
        scope_result_source: "normal",
      },
    };

    // console.log("DATA Project", data);

    axiosConfig
      .post(`${URL_API}/swd/goal`, data)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: "goal",
              event: "create",
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ""
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: "",
              device_version: OSName,
              long: "",
              lat: "",
              entity_id: result?.id,
              entity_name: result?.name,
              remarks: `Project Goal (${result?.name}) 1`,
              comment: "",
            },
          };

          setDataRemarks(dataLog);

          // setOpenCreateProject(false);

          setOpenRemarksLog(true);

          setproject("");
          setStartDate(null);
          setEndDate(null);
          setStart(0);
          setTarget(0);
          setOwnerId("");
          setDescription("");
          setMembers([]);
          setOwnerCollections([]);
          setSelected([]);
          setScope(50);
          setSchedule(50);

          // setTriggerProject(Math.floor(Math.random() * 100));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openCreateProject}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{}}>
        <Box p={2} pb={0}>
          <Typography variant="h5" className={classes.title333}>
            Create a New Project
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box p={2} pt={0}>
          <Typography variant="subtitle2" className={classes.title333}>
            Nama Project
          </Typography>
          <Box my={0.5} />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Add a title"
            onChange={(e) => setproject(e.target.value)}
            disabled={status403}
          />

          <Box my={2} />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title333}>
                Owner
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                id="combo-box-demo"
                options={ownerCollections}
                getOptionLabel={(option) =>
                  `${option.member.first_name} ${option.member.last_name}`
                }
                onChange={(e, owner) => handleChooseOwner(e, owner)}
                noOptionsText="Tidak ada owner"
                popupIcon={
                  <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
                }
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    label="Select owner"
                    variant="outlined"
                    size="small"
                  />
                )}
                disabled={status403}
              />
            </Grid>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title333}>
                Project Member
              </Typography>
              <Box my={0.5} />
              <Box
                width="100%"
                minHeight={30}
                border="1.5px solid #C4C4C4"
                borderRadius={5}
                padding={0.8}
                display="flex"
                justifyContent={
                  selected.length === 0 ? "flex-end" : "space-between"
                }
                alignItems="center"
              >
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {selected.length > 0 &&
                    selected.map((item, i) => (
                      <Tooltip
                        title={`${item.member.first_name} ${item.member.last_name}`}
                      >
                        <Chip
                          key={i}
                          label={TruncateTextCustom(item.member.first_name, 8)}
                          size="small"
                          style={{ margin: "0 5px 5px 0" }}
                          onDelete={() => handleDeleteMember(item.id)}
                        />
                      </Tooltip>
                    ))}
                </Box>
                <IconButton size="small" onClick={handleShow}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Box>
              <Popper
                open={Boolean(anchor)}
                anchorEl={anchor}
                transition
                disablePortal
                placement="bottom-end"
                className={classNames({
                  [classes.popperClose]: !anchor,
                  [classes.popperResponsive]: true,
                })}
              >
                {({ placement }) => (
                  <Grow
                    in={Boolean(anchor)}
                    id="menu-list"
                    tyle={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <ClickAwayListener onClickAway={handleCloseAway}>
                      <Paper className={classes.dropdown}>
                        <Box p={1.5} width={400}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            Members
                          </Typography>
                          <Box my={1} />
                          <Autocomplete
                            id="combo-box-demo"
                            options={members}
                            getOptionLabel={(option) =>
                              `${option.member.first_name} ${option.member.last_name}`
                            }
                            onChange={(e, owner) =>
                              handleChooseMembers(e, owner)
                            }
                            noOptionsText="Tidak ada owner"
                            popupIcon={
                              <KeyboardArrowDownIcon
                                style={{ color: "#C8203C" }}
                              />
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Search"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Paper>
                    </ClickAwayListener>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>

          <Box my={2} />

          <Typography variant="subtitle2" className={classes.title333}>
            Exclude Weekend
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch checked={isExclude} onChange={handleExcludeWeekend} />
            }
          />

          <Box my={2} />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title333}>
                Start Date
              </Typography>
              <Box my={0.5} />
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale="id">
                <DatePicker
                  autoOk
                  value={startDate}
                  onChange={handleStartDate}
                  animateYearScrolling
                  variant="inline" // dialog, static, inline
                  disableToolbar={false}
                  format="DD MMMM YYYY"
                  placeholder="Pick your start date"
                  inputVariant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small">
                          <DateRangeIcon style={{ color: "#C8203C" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={status403}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title333}>
                End Date
              </Typography>
              <Box my={0.5} />
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale="id">
                <DatePicker
                  autoOk
                  value={endDate}
                  onChange={setEndDate}
                  animateYearScrolling
                  variant="inline" // dialog, static, inline
                  disableToolbar={false}
                  format="DD MMMM YYYY"
                  placeholder="Pick your end date"
                  inputVariant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small">
                          <DateRangeIcon style={{ color: "#C8203C" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={status403}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Box my={2} />

          <Typography variant="subtitle2" className={classes.title333}>
            Description
          </Typography>
          <Box my={0.5} />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="What is your task description"
            onChange={(e) => setDescription(e.target.value)}
            disabled={status403}
          />

          <Box my={2} />

          <FormControlLabel
            label={
              <Typography variant="subtitle2" className={classes.title333}>
                Show Advanced Option
              </Typography>
            }
            control={
              <Checkbox
                className={classes.checkboxRoot}
                disableRipple
                color="default"
                checked={advanced}
                onChange={() => setAdvanced((prev) => !prev)}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                disabled={status403}
              />
            }
          />

          {advanced && (
            <>
              <Box my={2} />

              {/* <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    Start Value
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    disabled
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    Target Value
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={target}
                    onChange={(e) => setTarget(e.target.value)}
                    disabled
                  />
                </Grid>
              </Grid> */}

              <Box my={2} />

              <Typography variant="subtitle2" className={classes.title333}>
                Achievement Factor
              </Typography>
              <SliderTwoSide
                classes={classes}
                scope={scope}
                schedule={schedule}
                onScope={setScope}
                onSchedule={setSchedule}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={() => setOpenCreateProject(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            size="small"
            variant={loading || status403 ? "contained" : "outlined"}
            className={classes.button1}
            disabled={loading || status403}
            onClick={handleCreateProject}
          >
            {loading ? "Creating Project..." : "Create"}
          </Button>
        </Box>
      </DialogActions>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenCreateProject}
        onTriggerRemarks={setTriggerProject}
        editRemarks={true}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogCretaeProject;
