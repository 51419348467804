import React, { useContext, useEffect, useState } from "react";
import { styles } from "../Style/StyleAttendanceAccount";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Room } from "@material-ui/icons";
import PinInput from "react-pin-input";

import ContextAttendanceAccount from "../Context/ContextAttendanceAccount";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ChangePinDialog = ({
  handleClose,
  open,
  verification,
  pinValue,
  setPinValue,
}) => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceAccount);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {"Change Pin"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            {"Enter your current PIN"}
          </DialogContentText>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{ borderRadius: "10px", border: "2px solid grey" }}
            onComplete={(value, index) => {
              setPinValue({
                ...pinValue,
                oldPin: value,
              });
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setPinValue({
                  ...pinValue,
                  oldPin: "",
                });
              }
            }}
            type="numeric"
          />
          <DialogContentText
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            {"Enter your new PIN"}
          </DialogContentText>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{ borderRadius: "10px", border: "2px solid grey" }}
            onComplete={(value, index) => {
              setPinValue({
                ...pinValue,
                newPin: value,
              });
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setPinValue({
                  ...pinValue,
                  newPin: "",
                });
              }
            }}
            type="numeric"
          />
          <DialogContentText
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            {"Enter your new PIN again"}
          </DialogContentText>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{ borderRadius: "10px", border: "2px solid grey" }}
            onComplete={(value, index) => {
              setPinValue({
                ...pinValue,
                confirmPin: value,
              });
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setPinValue({
                  ...pinValue,
                  confirmPin: "",
                });
              }
            }}
            type="numeric"
          />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            className={classes.fixedBottomButton}
            style={{
              backgroundColor:
                pinValue.oldPin === "" &&
                pinValue.newPin === "" &&
                pinValue.confirmPin === ""
                  ? "#808080"
                  : "#262261",
              color: "#ffffff",
            }}
            onClick={() =>
              pinValue.newPin !== "" &&
              pinValue.confirmPin !== "" &&
              verification()
            }
          >
            <b>Input</b>
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default ChangePinDialog;
