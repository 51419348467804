import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import DialogCreateText from "./DialogCreateText";

function DialogCreatePlainDoc({
  openDialogCreatePlainDoc,
  setOpenDialogCreatePlainDoc,
  classes,
}) {
  const [fileName, setFileName] = useState("");
  const [openDialogCreateText, setOpenDialogCreateText] = useState(false);

  const handleCreatePlainDoc = () => {
    // // console.log("Plain Text Name", fileName);
    setOpenDialogCreateText(true);
  };

  return (
    <Dialog
      open={openDialogCreatePlainDoc}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Create Plain Document</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 400 }}>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2" className={classes.title}>
            File Name
          </Typography>
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => setFileName(e.target.value)}
            // color='primary'
            // onKeyDown={handleEnterPress}
            value={fileName}
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            inputProps={{
              className: classes.title,
            }}
          />

          <DialogActions
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              className={classes.button2}
              onClick={() => setOpenDialogCreatePlainDoc(false)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              onClick={handleCreatePlainDoc}
            >
              Create
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>

      <DialogCreateText
        classes={classes}
        fileName={fileName}
        setFileName={setFileName}
        openDialogCreateText={openDialogCreateText}
        setOpenDialogCreateText={setOpenDialogCreateText}
        setOpenDialogCreatePlainDoc={setOpenDialogCreatePlainDoc}
      />
    </Dialog>
  );
}

export default DialogCreatePlainDoc;
