import React, { Component, useEffect, useState, useCallback } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
  Snackbar,
  Fab,
} from "@material-ui/core";

const DialogEditUrgentContacts = (props) => {
  const {
    classes,
    setModalEdit,
    modalEdit,
    dataDetailEdit,
    nameAnggotaKeluarga,
    handleChangeHubunganKeluarga,
    hubunganKeluarga,
    handleChangeNoHandphone,
    nomorHandphone,
    // handleSimpanDataAnggotaKeluarga,
    handleChangeNamaAnggotaKeluarga,
    handleSimpanPerubahan,
  } = props;

  // // console.log("dataDetailEdit : ", dataDetailEdit);

  // const handleSimpanPerubahanAnggotaKeluarga = () => {

  //     let dataPerubahan = {

  //         name_anggota_keluarga: nameAnggotaKeluarga !== '' ? nameAnggotaKeluarga : dataDetailEdit.name_anggota_keluarga,
  //         hubungan_keluarga : hubunganKeluarga !== '' ? hubunganKeluarga : dataDetailEdit.hubungan_keluarga,
  //         no_hp: nomorHandphone !== '' ? nomorHandphone : dataDetailEdit.no_hp
  //     };

  //     // console.log("dataPerubahan : ", dataPerubahan)
  // };

  return (
    <Dialog
      open={modalEdit}
      onClose={() => setModalEdit(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Ubah Data Anggota Keluarga</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="subtitle1" className={classes.titleForm}>
                  <b>Nama</b>
                </Typography>
              }
            />
            <TextField
              id="outlined-bare"
              onChange={handleChangeNamaAnggotaKeluarga}
              value={nameAnggotaKeluarga}
              placeholder={dataDetailEdit.name_anggota_keluarga}
              className={classes.textField}
              // placeholder={'NIP'}
              variant="outlined"
              // error={infoError == true && npwp == '' ? true : false}
              // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
              // required={true}
              // name='nip'
              // color='primary'
              // onKeyDown={handleEnterPress}
              // disabled= {isLockedStatusState == 1 ? true : false}
              // fullWidth
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <Typography variant="subtitle1" className={classes.title}>
                  <b>Hubungan</b>
                </Typography>
              }
            />
            {/* <TextField
                        id="outlined-bare"
                        onChange= {handleChangeHubunganKeluarga}
                        value={hubunganKeluarga}
                        placeholder={dataDetailEdit.hubungan_keluarga}
                        className={classes.textField}
                        // placeholder={'NIP'}
                        variant="outlined"
                    /> */}

            <TextField
              id="outlined-select-provinsi"
              select
              // label="Pilih Negara : "
              className={classes.textField}
              value={hubunganKeluarga.name}
              placeholder={
                dataDetailEdit !== ""
                  ? dataDetailEdit.hubungan_keluarga.value
                  : ""
              }
              onChange={handleChangeHubunganKeluarga("name")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
              // error={infoError == true && kotaKabInKtp.name == '' ? true : false}
              // helperText={infoError == true && kotaKabInKtp.name == '' ? "Wajib di isi" : ' '}
            >
              {hubunganKeluargas.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <Typography variant="subtitle1" className={classes.titleForm}>
                  <b>Nomor Telepon</b>
                </Typography>
              }
            />
            <TextField
              id="outlined-bare"
              onChange={handleChangeNoHandphone}
              value={nomorHandphone}
              placeholder={dataDetailEdit.no_hp}
              className={classes.textField}
              // placeholder={'NIP'}
              variant="outlined"
            />

            {/* 
                        <form className={classes.container} noValidate>
                            <TextField
                                id="date"
                                onChange={handleChangeNoHandphone}
                                value={nomorHandphone}
                                defaultValue="2019-05-24"
                                type="date"
                                // label="Birthday"
                                variant="outlined"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form> 
                    */}
          </ListItem>
        </List>

        <DialogContentText id="alert-dialog-description">
          <Typography variant="h6">
            {/* {infoResponse500 != '' ? infoResponse500 : 'Oops, something went wrong !'} */}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ alignItems: "right", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => setModalEdit(false)}
          variant="outlined"
          size="small"
          className={classes.buttonModalCancel}
        >
          Batal
        </Button>

        <Button
          // onClick={() => Redirect(ToHrEmployeeKepegawaianInfoPegawai)}
          onClick={handleSimpanPerubahan}
          variant="contained"
          color="primary"
          size="small"
          className={classes.buttonModal}
        >
          Simpan perubahan
        </Button>
      </DialogActions>
      <br />
      <br />
    </Dialog>
  );
};

export default DialogEditUrgentContacts;

const hubunganKeluargas = [
  {
    id: 1,
    value: "Ayah Kandung",
    label: "Ayah Kandung",
  },
  {
    id: 2,
    value: "Ibu Kandung",
    label: "Ibu Kandung",
  },
  {
    id: 3,
    value: "Ayah Tiri",
    label: "Ayah Tiri",
  },
  {
    id: 4,
    value: "Ibu Tiri",
    label: "Ibu Tiri",
  },
  {
    id: 5,
    value: "Istri / Suami",
    label: "Istri / Suami",
  },
  {
    id: 6,
    value: "Saudara Kandung",
    label: "Saudara Kandung",
  },
  {
    id: 7,
    value: "Saudara Tiri",
    label: "Saudara Tiri",
  },
  {
    id: 8,
    value: "Anak Kandung",
    label: "Anak Kandung",
  },
  {
    id: 9,
    value: "Anak Tiri",
    label: "Anak Tiri",
  },
  {
    id: 10,
    value: "Anak Angkat",
    label: "Anak Angkat",
  },
];
