import React from "react";
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

function CardSixTargetType({ classes, i, targetType, handleSelectTargetType }) {
  // // console.log("LIST TType", targetType);

  return (
    <Grid container justify="space-between" key={i}>
      <Typography
        variant="subtitle2"
        className={classes.title}
        style={{ color: "grey", marginTop: 16, cursor: "pointer" }}
      >
        <b>Pilih Target Type</b>
      </Typography>

      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="targetType"
          name="targetType"
          value={targetType[i]}
          onChange={(e) => handleSelectTargetType(e, i)}
        >
          {/* <FormControlLabel
            value="1"
            control={<Radio checked={targetType.id === "1"} />}
            label="Daily"
          />
          <FormControlLabel
            value="2"
            control={<Radio checked={targetType.id === "2"} />}
            label="Final"
          /> */}
          <FormControlLabel
            value={targetType?.id}
            control={<Radio checked />}
            label={targetType?.name}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export default CardSixTargetType;
