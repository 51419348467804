import React, { useState } from "react";
import { Box, Paper, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DialogCretaeProject from "./components/dialogs/DialogCretaeProject";

const ProjectEmpty = ({ classes }) => {
  const [openCreateProject, setOpenCreateProject] = useState(false);

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Paper className={classes.shadowSection}>
        <Box p={4} pb={3}>
          <Box m={1.5} mb={3} display="flex" alignItems="center">
            <Box
              height={7}
              width={99}
              bgcolor="#C4C4C4"
              mr={3}
              borderRadius={2}
            />
            <Box height={7} width={25} bgcolor="#C4C4C4" borderRadius={2} />
          </Box>

          {contents.map((content, i) => (
            <Paper
              key={i}
              variant="outlined"
              style={{
                width: 350,
                padding: 5,
                borderLeft: `6px solid ${content.color}`,
                marginBottom: content.mb ? 30 : 10,
              }}
            >
              <Box
                m={1.5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  height={7}
                  width={content.width}
                  bgcolor="#C4C4C4"
                  borderRadius={2}
                />
                <Box height={7} width={25} bgcolor="#C4C4C4" borderRadius={2} />
              </Box>
            </Paper>
          ))}
        </Box>
      </Paper>

      <Box
        mt={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="subtitle2" className={classes.title333}>
          No projects yet , Create project to get started
        </Typography>
        <Box my={1} />
        <Button
          variant="outlined"
          className={classes.button1}
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateProject(true)}
        >
          Create Project
        </Button>
      </Box>

      <DialogCretaeProject
        classes={classes}
        openCreateProject={openCreateProject}
        setOpenCreateProject={setOpenCreateProject}
      />
    </Box>
  );
};

export default ProjectEmpty;

const contents = [
  {
    color: "#10266F",
    width: 100,
    mb: false,
  },
  {
    color: "#10266F",
    width: 140,
    mb: true,
  },
  {
    color: "#1E874D",
    width: 100,
    mb: false,
  },
  {
    color: "#1E874D",
    width: 140,
    mb: false,
  },
];
