import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";

import StickyFooter from "react-sticky-footer";
import DoneIcon from "@material-ui/icons/Done";

// import ButtonSpecialSimpanToDB from './ComponentPengalamanKerja/ButtonSpecialSimpanToDB';

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeRiwayatUrgentContact } from "../../../constants/config-redirect-url";
import { extractImageFileExtensionFromBase64 } from "../../../utilities/ReusableUtils";
import PictPlusUploadDocument from "../../../assets/images/Group_2268.png";

import { styles } from "./Style/StyleRiwayat"; // NEXT GANTI TARO DI COMPONENT INFO PEGAWAI SENDIRI !

// import Snackber from '../Components/Snackber';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewRiwayatPengalamanKerja = (props) => {
  const { classes } = props;

  /*
        ````````````````````````````````````
        COMPONENT DID MOUNT

        ````````````````````````````````````
    */
  const [listDataRiwayatPengalaman, setListDataRiwayatPengalaman] =
    useState("");

  let dataList = [];
  const employeeDataRiwayatExperience = localStorage.getItem(
    "employee_data_riwayat_experience"
  );
  let employeeDataRiwayatExperienceAfterParse =
    employeeDataRiwayatExperience !== null
      ? JSON.parse(employeeDataRiwayatExperience)
      : [];

  useEffect(() => {
    console.log(
      "employeeDataRiwayatExperienceAfterParse : ",
      employeeDataRiwayatExperienceAfterParse
    );

    setListDataRiwayatPengalaman(employeeDataRiwayatExperienceAfterParse);
  }, []);

  /*
        ``````````````````````````````
        MODAL TAMBAH PENGALAMAN KERJA

        ```````````````````````````````
    */

  const [modalTambahPosition, setModalTambahPosition] = useState(false);

  /*
        `````````
        FORM DATA

        `````````
    */

  const [namaJabatan, setNamaJabatan] = useState("");
  const [namaPerusahaan, setNamaPerusahaan] = useState("");

  const [bulanIN, setBulanIN] = useState({
    name: "",
  });

  const [tahunIN, setTahunIN] = useState({
    name: "",
  });

  const [bulanOUT, setBulanOUT] = useState({
    name: "",
  });

  const [tahunOUT, setTahunOUT] = useState({
    name: "",
  });

  const [alamatPerusahaan, setAlamatPerusahaan] = useState(""); // Text field row
  const [penghargaan, setPenghargaan] = useState(""); // Text field row
  const [telpPerusahaan, setTelpPerusahaan] = useState("");
  const [alasanBerhenti, setAlasanBerhenti] = useState(""); // Text field row

  const handleChangeNamaJabatan = (e) => setNamaJabatan(e.target.value);
  const handleChangeNamaPerusahaan = (e) => setNamaPerusahaan(e.target.value);

  const handleChangeBulanIN = (name) => (event) => {
    setBulanIN({ ...bulanIN, [name]: event.target.value });
  };

  const handleChangeTahunIN = (name) => (event) => {
    setTahunIN({ ...tahunIN, [name]: event.target.value });
  };

  const handleChangeBulanOUT = (name) => (event) => {
    setBulanOUT({ ...bulanOUT, [name]: event.target.value });
  };

  const handleChangeTahunOUT = (name) => (event) => {
    setTahunOUT({ ...tahunOUT, [name]: event.target.value });
  };

  const handleChangeAlamatPerusahaan = (e) =>
    setAlamatPerusahaan(e.target.value);
  const handleChangePenghargaan = (e) => setPenghargaan(e.target.value);
  const handleChangeNoTelpPerusahaan = (e) => setTelpPerusahaan(e.target.value);
  const handleChangeAlasanBerhenti = (e) => setAlasanBerhenti(e.target.value);

  /* 
       `````````````````````````````````
       Feature Upload SURAT REKOMENDASI
       
       `````````````````````````````````
    */

  // const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState(dataIdentitasInfoDasarAfterParse.foto);
  const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState("");
  const [imgSrcExt, setImgSrcExt] = useState();

  const [nameFile, setNameFile] = useState("");

  // const [imgSrc, setImgSrc ] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log("acceptedFiles : ", acceptedFiles);
    setNameFile(acceptedFiles[0].name);

    //*
    const reader = new FileReader();

    reader.onabort = () =>
      // console.log("file reading was aborted");
      (reader.onerror = () =>
        // console.log("file reading has failed");
        (reader.onload = () => {
          // Do whatever you want with the file contents
          //   // console.log("Reader : ", reader)
          const binaryStr = reader.result;
          // console.log("Binary String : ", binaryStr);

          setImageBinaryPreviewUrl(binaryStr);
          setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));
        }));

    // acceptedFiles.forEach(file => reader.readAsBinaryString(file))
    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  /*
        ```````````````````
        HANDLE SIMPAN DATA

        ```````````````````
    */

  const handleSimpanDataPendidikanFormal = () => {
    let paramsData = {
      nama_jabatan: namaJabatan,
      nama_perusahaan: namaPerusahaan,
      bulan_masuk: bulanIN.name,
      tahun_masuk: tahunIN.name,
      bulan_keluar: bulanOUT.name,
      tahun_keluar: tahunOUT.name,
      alamat_perusahaan: alamatPerusahaan,
      penghargaan: penghargaan,
      no_telp_perusahaan: telpPerusahaan,
      alasan_berhenti: alasanBerhenti,
      name_file: nameFile,
      ijazah: imageBinaryPreviewUrl,

      // tahun_selesai: masaBelajarOUT.name,
      // status_pelajar: statusPelajarChecked.name == true ? 'Lulus' : 'Belum Lulus',
      // name_file: nameFile
    };

    if (listDataRiwayatPengalaman.length > 0) {
      // console.log("Run v1");

      const newList = [...listDataRiwayatPengalaman, paramsData];

      // console.log("newList : ", newList);

      localStorage.setItem(
        "employee_data_riwayat_experience",
        JSON.stringify(newList)
      );
      setListDataRiwayatPengalaman([...listDataRiwayatPengalaman, paramsData]);
    } else {
      // console.log("Run v2");

      dataList.push(paramsData);
      localStorage.setItem(
        "employee_data_riwayat_experience",
        JSON.stringify(dataList)
      );
      window.location.reload();
    }

    setModalTambahPosition(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}
          >
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Riwayat</b>
            </Typography>
            <ul>
              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Kesehatan</b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Pendidikan formal </b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Pendidikan Informal </b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Organisasi</b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>
              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Pengalaman Kerja</b>
                </Typography>
              </li>
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: "left " }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}
          >
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Pengalaman Kerja</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              onClick={() => setModalTambahPosition(true)}
            >
              <b>+ Tambah Pengalaman Kerja </b>
            </Typography>
            {listDataRiwayatPengalaman.length > 0 &&
              listDataRiwayatPengalaman.map((item, i) => {
                return (
                  <Grid container key={i}>
                    <Grid item xs={5}>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <b>
                          Bekerja di {item.nama_perusahaan + " sebagai "}{" "}
                          {item.nama_jabatan}.
                        </b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        {item.tahun_masuk + " -"} {item.tahun_keluar}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        variant="subtitle1"
                        className={classes.titleHeader}
                      >
                        <b>
                          {item.alamat_perusahaan + ",  "}{" "}
                          {item.no_telp_perusahaan}{" "}
                        </b>{" "}
                        &nbsp;&nbsp;
                        <span
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}
                        >
                          Edit
                        </span>{" "}
                        &nbsp;&nbsp;- &nbsp;&nbsp;
                        <span
                          // onClick={() => setModalDeleteAnggotaKeluarga(true)}
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}
                        >
                          Hapus
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        {item.penghargaan}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        {item.name_file}
                      </Typography>
                      <br />
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <i>{item.alasan_berhenti}</i>
                      </Typography>

                      <br />
                    </Grid>
                  </Grid>
                );
              })}
          </Paper>
        </Grid>
      </Grid>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />

          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{ margin: 14 }}
            disabled={false}
            onClick={() => Redirect(ToHrEmployeeRiwayatUrgentContact)}
            // onClick={handleSimpanDataToDB}
          >
            Lanjut
          </Button>
          {/* <ButtonSpecialSimpanToDB 
                        classes={classes}
                    /> */}
        </Grid>
      </Grid>

      <Dialog
        open={modalTambahPosition}
        onClose={() => setModalTambahPosition(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography variant="subtitle1" className={classes.title}>
            <b>Tambah Pengalaman Kerja</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {" "}
            {/* className={classes.list}  */}
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Nama Jabatan</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeNamaJabatan}
                value={namaJabatan}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
                // error={infoError == true && npwp == '' ? true : false}
                // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
                // required={true}
                // name='nip'
                // color='primary'
                // onKeyDown={handleEnterPress}
                // disabled= {isLockedStatusState == 1 ? true : false}
                // fullWidth
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Nama Perusahaan</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeNamaPerusahaan}
                value={namaPerusahaan}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
              />
            </ListItem>
            {/* 
                            ```````````
                            TAHUN MASUK

                            ```````````
                        */}
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Tahun Masuk</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-tahun-in"
                select
                label="Bulan"
                className={classes.textFieldDropDown}
                value={bulanIN.name}
                onChange={handleChangeBulanIN("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {bulans.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-tahun-out"
                select
                label="Tahun"
                className={classes.textFieldDropDown}
                value={tahunIN.name}
                onChange={handleChangeTahunIN("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
            {/* 
                            `````````````
                            TAHUN KELUAR

                            `````````````
                        */}
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Tahun Keluar</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-tahun-in"
                select
                label="Bulan"
                className={classes.textFieldDropDown}
                value={bulanOUT.name}
                onChange={handleChangeBulanOUT("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {bulans.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-tahun-out"
                select
                label="Tahun"
                className={classes.textFieldDropDown}
                value={tahunOUT.name}
                onChange={handleChangeTahunOUT("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Alamat Perusahaan</b>
                  </Typography>
                }
              />
              <TextField
                onChange={handleChangeAlamatPerusahaan}
                value={alamatPerusahaan}
                style={{ marginTop: 0 }}
                id="outlined-multiline-static"
                // label="Alamat Perusahaan"
                multiline
                rows="3"
                // defaultValue="Default Value"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                // fullWidth
                // helperText='Masukan alamat lengkap'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Penghargaan</b>
                  </Typography>
                }
              />
              <TextField
                onChange={handleChangePenghargaan}
                value={penghargaan}
                style={{ marginTop: 0 }}
                id="outlined-multiline-static"
                // label="Alamat Perusahaan"
                multiline
                rows="3"
                // defaultValue="Default Value"
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>No. Telp Perusahaan </b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeNoTelpPerusahaan}
                value={telpPerusahaan}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Alasan Berhenti</b>
                  </Typography>
                }
              />
              <TextField
                onChange={handleChangeAlasanBerhenti}
                value={alasanBerhenti}
                style={{ marginTop: 0 }}
                id="outlined-multiline-static"
                // label="Alamat Perusahaan"
                multiline
                rows="3"
                // defaultValue="Default Value"
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Surat Rekomendasi</b>
                  </Typography>
                }
              />

              <div {...getRootProps()}>
                <input {...getInputProps()} />

                {imageBinaryPreviewUrl ? (
                  <Typography variant="caption" className={classes.title}>
                    <i>{nameFile}</i>
                  </Typography>
                ) : (
                  <img
                    src={PictPlusUploadDocument}
                    className={classes.pictUpload}
                  />
                )}
              </div>
            </ListItem>
          </List>

          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}
        >
          <Button
            onClick={() => setModalTambahPosition(false)}
            variant="outlined"
            size="small"
            className={classes.buttonModalCancel}
          >
            Batal
          </Button>

          <Button
            onClick={handleSimpanDataPendidikanFormal}
            // onClick= {() => setModalTambahPosition(false)}
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttonModal}
          >
            Simpan
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewRiwayatPengalamanKerja);

const bulans = [
  {
    id: "01",
    value: "January",
    label: "January",
  },
  {
    id: "02",
    value: "Februari",
    label: "Februari",
  },
  {
    id: "03",
    value: "Maret",
    label: "Maret",
  },
  {
    id: "04",
    value: "April",
    label: "April",
  },
  {
    id: "05",
    value: "Mei",
    label: "Mei",
  },
  {
    id: "06",
    value: "Juni",
    label: "Juni",
  },
  {
    id: "07",
    value: "Juli",
    label: "Juli",
  },
  {
    id: "08",
    value: "Agustus",
    label: "Agustus",
  },
  {
    id: "09",
    value: "September",
    label: "September",
  },
  {
    id: "10",
    value: "Oktober",
    label: "Oktober",
  },
  {
    id: "11",
    value: "November",
    label: "November",
  },
  {
    id: "12",
    value: "Desember",
    label: "Desember",
  },
];

const tahuns = [
  {
    value: 1991,
    label: 1991,
  },
  {
    value: 1992,
    label: 1992,
  },
  {
    value: 1993,
    label: 1993,
  },
  {
    value: 1994,
    label: 1994,
  },
  {
    value: 1995,
    label: 1995,
  },
  {
    value: 1996,
    label: 1996,
  },
  {
    value: 1997,
    label: 1997,
  },
  {
    value: 1998,
    label: 1998,
  },
  {
    value: 1999,
    label: 1999,
  },
  {
    value: 2005,
    label: 2005,
  },
  {
    value: 2006,
    label: 2006,
  },
  {
    value: 2007,
    label: 2007,
  },
  {
    value: 2008,
    label: 2009,
  },
  {
    value: 2010,
    label: 2010,
  },
  {
    value: 2011,
    label: 2011,
  },
  {
    value: 2012,
    label: 2012,
  },
  {
    value: 2013,
    label: 2013,
  },
  {
    value: 2014,
    label: 2014,
  },
  {
    value: 2015,
    label: 2015,
  },
  {
    value: 2016,
    label: 2016,
  },
  {
    value: 2017,
    label: 2017,
  },
  {
    value: 2018,
    label: 2018,
  },
  {
    value: 2019,
    label: 2019,
  },
  {
    value: 2020,
    label: 2020,
  },
  {
    value: 2021,
    label: 2021,
  },
  {
    value: 2022,
    label: 2022,
  },
  {
    value: 2023,
    label: 2023,
  },
  {
    value: 2024,
    label: 2024,
  },
  {
    value: 2025,
    label: 2025,
  },
  {
    value: 2026,
    label: 2026,
  },
  {
    value: 2027,
    label: 2027,
  },
];
