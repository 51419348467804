import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import PictIconGoal from "../../../../assets/images/Group_3017.png";

const NewIconGoal = (props) => {
  return (
    <Box>
      <IconButton
        style={{
          marginLeft: 8,
        }}>
        <img src={PictIconGoal} />
      </IconButton>
    </Box>
  );
};

export default NewIconGoal;
