import React, { useContext, useState } from "react";
import {
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Box,
  Divider,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";

import classNames from "classnames";

import { DRAWER_OPEN, SET_TASK_DETAILS } from "../../context/types";
import ContextProject from "../../context/ContextProject";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogDeleteTask from "../dialogs/DialogDeleteTask";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import moment from "moment";
import DialogDuplicateTask from "../dialogs/DialogDuplicateTask";

const AnchorTaskitem = ({ classes, task, project }) => {
  const { projectTDispatch, setTriggerTask } = useContext(ContextProject);
  const checked = task.is_complete.id === "2" ? true : false;
  const todayB4Start = moment(new Date()).isAfter(task?.start_date, "day");

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteTask, setOpenDeleteTask] = useState(false);
  const [openDuplicateTask, setOpenDuplicateTask] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    projectTDispatch({
      type: DRAWER_OPEN,
      payload: { drawerTask: true, actionTask: "update" },
    });
    projectTDispatch({
      type: SET_TASK_DETAILS,
      payload: { taskDetails: task },
    });
  };

  const handleComplete = () => {
    const actual = task.actual_result === null ? "0" : task.actual_result;
    const remarks = task.remarks === null ? "" : task.remarks;

    const data = {
      Todolist: {
        actual_result: actual,
        remarks,
        status: "DONE",
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${task.id}:completed`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          handleClose();
        }
      })
      .catch((error) => {
        // console.log("error", error);
        handleClose();
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleIncomplete = () => {
    AxiosConfig.put(`${URL_API}/todolist/${task.id}:incompleted`)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          handleClose();
        }
      })
      .catch((error) => {
        // console.log("error", error);
        handleClose();
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleDuplicateTask = () => {
    setOpenDuplicateTask(true);
    handleClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleShow}>
        <MoreVertIcon fontSize="small" style={{ color: "#555555" }} />
      </IconButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  {!checked && (
                    <MenuItem
                      onClick={handleViewDetails}
                      className={classes.dropdownItem}
                    >
                      <Box display="flex" alignItems="center">
                        <VisibilityIcon
                          style={{
                            color: "#555",
                            fontSize: 14,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#555" }}>View Details</span>
                      </Box>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handleDuplicateTask}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <FileCopyRoundedIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>Duplicate</span>
                    </Box>
                  </MenuItem>
                  {!checked && project?.status_id !== "2" && todayB4Start && (
                    <MenuItem
                      onClick={handleComplete}
                      className={classes.dropdownItem}
                    >
                      <Box display="flex" alignItems="center">
                        <CheckRoundedIcon
                          style={{
                            color: "#555",
                            fontSize: 14,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#555" }}>Set Complete</span>
                      </Box>
                    </MenuItem>
                  )}
                  {checked && project?.status_id !== "2" && (
                    <MenuItem
                      onClick={handleIncomplete}
                      className={classes.dropdownItem}
                    >
                      <Box display="flex" alignItems="center">
                        <CloseOutlinedIcon
                          style={{
                            color: "#555",
                            fontSize: 14,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#555" }}>Set Incomplete</span>
                      </Box>
                    </MenuItem>
                  )}
                  {project?.project_type === "TEAM" && (
                    <Divider className={classes.dropdownDividerItem} />
                  )}
                  {project?.project_type === "TEAM" && (
                    <MenuItem
                      onClick={() => {
                        setOpenDeleteTask(true);
                        handleClose();
                      }}
                      className={classes.dropdownItem}
                    >
                      <Box display="flex" alignItems="center">
                        <DeleteIcon
                          style={{
                            color: "#C8203C",
                            fontSize: 14,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#C8203C" }}>Delete</span>
                      </Box>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogDeleteTask
        classes={classes}
        openDeleteTask={openDeleteTask}
        setOpenDeleteTask={setOpenDeleteTask}
        task={task}
      />

      <DialogDuplicateTask
        classes={classes}
        openDuplicateTask={openDuplicateTask}
        setOpenDuplicateTask={setOpenDuplicateTask}
        task={task}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default AnchorTaskitem;
