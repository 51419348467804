import React, { useState } from "react";
import { Box, Typography, Paper, IconButton, Menu } from "@material-ui/core";
// ICONS
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import BarChart from "./BarChart";
import ListItemMenu from "./ListItemMenu";

const BarChartSection = ({ classes }) => {
  // const { reportState, reportDispatch } = React.useContext(ContextReport);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleShowAnchorMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseAnchorMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <Paper className={classes.shadowSection}>
      <Box padding={3}>
        {/* ====== Title & Icon More ====== */}
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title333} variant="subtitle1">
            <b>Chart View</b>
          </Typography>
          {/* <IconButton size="small" onClick={handleShowAnchorMenu}>
            <MoreHorizIcon fontSize="small" style={{ color: "#888" }} />
          </IconButton> */}

          <Menu
            id="simple-menu"
            anchorEl={anchorMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorMenu)}
            onClose={handleCloseAnchorMenu}>
            <ListItemMenu classes={classes} setAnchorMenu={setAnchorMenu} />
          </Menu>
        </Box>

        {/* ====== Bar Chart ====== */}
        <BarChart classes={classes} />
      </Box>
    </Paper>
  );
};

export default BarChartSection;
