import React, { useContext, useState } from "react";
import { Box, Chip, CircularProgress, ClickAwayListener, Paper, Popper, Typography } from "@material-ui/core";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";

import { styles } from "../../../../Style/StyleAttendanceReportAdmin";
import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      clickableColorSecondary: {
        "&:hover, &:focus": {
          backgroundColor: "#9b9baf",
        },
        "&:active": {
          backgroundColor: "#83829C",
        },
      },
    },
  },
});

const ChipSchedule = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceReportAdmin);
  const [anchorGroupBy, setAnchorGroupBy] = useState(null);

  function TruncateTextCustom(text, long) {
    if (text !== null && text !== undefined) {
      if (text.length > long) return text.substring(0, long) + "...";
      else return text;
    }
  }

  const handleOpenGroupBy = (event) => {
    setAnchorGroupBy(event.currentTarget);
  };

  const handleCloseAwayGroupBy = (event) => {
    if (anchorGroupBy.contains(event.target)) {
      return;
    }
    setAnchorGroupBy(null);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Chip
          variant="outlined"
          size="small"
          className={classes.chipFilterSort}
          label={
            <Typography variant="caption">
            {TruncateTextCustom("Schedule : " + context.dataSettingDetail.filter.schedule_name.join(", "), 15)}
            </Typography>
          }
          style={{ marginRight: 8 }}
          onClick={handleOpenGroupBy}
        />

        <Popper
          open={Boolean(anchorGroupBy)}
          anchorEl={anchorGroupBy}
          transition
          disablePortal
          placement="top" style={{ zIndex: 9 }}
        >
          <ClickAwayListener onClickAway={handleCloseAwayGroupBy}>
            <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
              <Box px={2} py={1} display="flex" flexDirection="column">
                <Typography
                  variant="caption"
                  style={{
                    color: "#555",
                  }}
                >
                  <b>
                    Schedule :
                  </b>
                </Typography>
                {context.dataSettingDetail.filter.schedule_name.map((el, i) => {
                  return (
                    <Typography
                      key={i}
                      variant="caption"
                      style={{
                        color: "#555",
                      }}
                    >
                      - {el}
                    </Typography>
                  );
                })}
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    </MuiThemeProvider>
  );
};

export default ChipSchedule;
