import moment from "moment";

const mapGroupGantt = (arr, project) => {
  // // console.log("ARRR", arr);

  const configGroups =
    arr !== undefined &&
    arr?.length > 0 &&
    arr.map((item) => {
      const todolists =
        item.todolists.length > 0
          ? item.todolists.map((task) => {
              const complete = task.is_complete.id;
              const status = task.status.id;
              let percentage;
              //   let color;
              //   let selectColor;

              // // console.log("TASK", task);

              if (complete === "2" || status === "DONE") {
                percentage = Number(task.schedule_result) * 100;
                //   color = "#d34c62";
                //   selectColor = "#c8203c";
              }
              if (complete !== "2" || status !== "DONE") {
                percentage = Number(task.scope_result) * 100;
                //   color = "#0179bf";
                //   selectColor = "#006098";
              }

              // passed due date
              const dateAfter = moment(
                task?.end_date === null ? new Date() : task?.end_date
              ).isAfter(task?.due_date, "day");

              // Color Bar
              let bgBar;
              let bgSelectedBar;
              let progressBar;
              let progressSelectedBar;

              const status_id = task.status.id;

              if (!dateAfter && status_id !== "DONE") {
                if (status_id === "TO DO") {
                  bgBar = "#aaafb3";
                  bgSelectedBar = "#999da1";
                  progressBar = "#898d90          ";
                  progressSelectedBar = "#7a7d80";
                }
                if (status_id === "DOING") {
                  bgBar = "#91d8e5";
                  bgSelectedBar = "#82c2ce";
                  progressBar = "#75aeb9";
                  progressSelectedBar = "#689ba4";
                }
                if (status_id === "ON HOLD") {
                  bgBar = "#cbab59";
                  bgSelectedBar = "#b69950";
                  progressBar = "#a38948";
                  progressSelectedBar = "#917a40";
                }
                if (status_id === "WAITING") {
                  bgBar = "#b29dbb";
                  bgSelectedBar = "#a08da8";
                  progressBar = "#907e97";
                  progressSelectedBar = "#807086";
                }
              } else {
                bgBar = "#ff5e5e";
                bgSelectedBar = "#e55454";
                progressBar = "#ce4b4b";
                progressSelectedBar = "#b74343";
              }

              if (status_id === "DONE") {
                bgBar = "#8ec3a6";
                bgSelectedBar = "#7faf95";
                progressBar = "#729d86";
                progressSelectedBar = "#658c77";
              }

              return {
                type: "task",
                project: item.id,
                start: new Date(moment(task.start_date).format("YYYY-MM-DD")),
                end: new Date(moment(task.due_date).format("YYYY-MM-DD")),
                title: task.title,
                name: `${task?.owner?.member?.first_name} ${task?.owner?.member?.last_name}`,
                description: task.description,
                remarks: task.remarks,
                status: task.status,
                priority: task.priority,
                tags: task.tags,
                actual_result: task.actual_result,
                target_result: task.target_result,
                project_id: task.project_id,
                task_group_id: task.task_group_id,
                predecessor_id: task.dependencies,
                owner_id: task.owner_id,
                id: task.id,
                progress: percentage > 100 ? 100 : percentage,
                isDisabled: false,
                dependencies: task.dependencies,
                styles: {
                  // backgroundColor:
                  //   task.status.id === "DONE" ? "#7bed9f" : "#4bcffa",
                  // backgroundSelectedColor:
                  //   task.status.id === "DONE" ? "#2ed573" : "#0fbcf9",
                  // progressColor:
                  //   task.status.id === "DONE" ? "#6ed58f" : "#3498db",
                  // progressSelectedColor:
                  //   task.status.id === "DONE" ? "#29bf67" : "#2e88c5",
                  backgroundColor: bgBar,
                  backgroundSelectedColor: bgSelectedBar,
                  progressColor: progressBar,
                  progressSelectedColor: progressSelectedBar,
                  // textColor: "#555",
                },
              };
            })
          : [];

      const percentage = Number(item.project_scope_results) * 100;

      return {
        name: item.name,
        title: item.name,
        id: item.id,
        type: "project",
        progress: percentage > 100 ? 100 : percentage,
        start: new Date(moment(item?.start_date).format("YYYY-MM-DD")),
        end: new Date(moment(item?.due_date).format("YYYY-MM-DD")),
        isDisabled: false,
        hideChildren: false,
        todolists,
        styles: {
          backgroundColor: "#9ccddc",
          backgroundSelectedColor: "#8cb8c6",
          progressColor: "#4e9ebf",
          progressSelectedColor: "#468eab",
        },
      };
    });

  // ======================== | Populate Groups | ========================
  const groupdata = (arr) => {
    let todolists = [];

    if (arr.length > 0) {
      arr.forEach((grup) => {
        if (grup.todolists.length > 0) {
          todolists.push(grup);
          grup.todolists.forEach((todo) => {
            todolists.push(todo);
          });
        }
      });
    }

    return todolists.map((el) => {
      delete el.todolists;
      return el;
    });
  };

  // ======================== | PRODUCE Groups | ========================
  const group_tasks = groupdata(configGroups);

  return group_tasks.length > 0 ? group_tasks : [];
};

export default mapGroupGantt;
