import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Lato", "Roboto"].join(","),
  },
});

export const styles = () => ({
  root: {
    // marginTop: 56,
    background: "#F9F9F9",
  },
  layoutMarginLeftAndTop: {
    marginLeft: 80,
    marginTop: 65,
  },
  layoutMarginLeft: {
    marginLeft: 95,
  },
  title333: {
    fontWeight: "normal",
    color: "#333",
    fontFamily: "'Lato', sans-serif",
  },
  title555: {
    fontWeight: "normal",
    color: "#555",
    fontFamily: "'Lato', sans-serif",
  },
  title888: {
    fontWeight: "normal",
    color: "#888",
    fontFamily: "'Lato', sans-serif",
  },
  justHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  statusItem: {
    fontSize: 12,
    borderRadius: 15,
    padding: "3px 7px",
    minWidth: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Lato', sans-serif",
  },
  tableHeader: {
    backgroundColor: "#fff",
    borderRadius: 8,
    fontFamily: "'Lato', sans-serif",
  },
  txtHeader: {
    fontWeight: "600",
    color: "#333",
    fontFamily: "'Lato', sans-serif",
  },
  txtBody: {
    color: "#4D4D4D",
    fontFamily: "'Lato', sans-serif",
  },
  txtLink: {
    color: "#d64253",
    "&:hover": {
      textDecoration: "underline",
      color: "#DE6775",
      cursor: "pointer",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
  shadowSection: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  shadowSection2: {
    borderTopLeftRadius: 8.5,
    borderTopRightRadius: 8.5,
    boxShadow: "0px 0px 0px -4px rgba(0,0,0,0.05)",
  },
  button0: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    padding: `4px 16px`,
    color: "#333",
  },
  button: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    padding: `4px 16px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 5,
    textTransform: "capitalize",
    padding: `4px 16px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  button2: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 5,
    textTransform: "capitalize",
    padding: `4px 16px`,
    backgroundColor: "#fff",
    border: "1px solid #B1B1B1",
    color: "#5B5B5B",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  uploadBtn: {
    fontFamily: "'Lato', sans-serif",
    color: "#00ACE6",
    backgroundColor: "rgba(0, 172, 230, 0.1)",
    borderRadius: 5,
    padding: "10px, 3px",
    border: "none",
    fontSize: 13,
    fontWeight: "500",
    textTransform: "capitalize",
    "&:hover": {
      opacity: 0.9,
    },
  },
  btnCancelUpload: {
    borderRadius: 3,
    backgroundColor: "#ffcccc",
    border: 0,
    color: "#d1354a",
    fontFamily: "'Lato', sans-serif",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: "700",
    padding: `1px 0`,
    "&:hover": {
      backgroundColor: "#ffe5e5",
    },
  },
  btnMultiTask: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 10,
    border: "none",
    textTransform: "capitalize",
    padding: `2px 10px`,
    color: "#4194AC",
    backgroundColor: "#CFEAF1",
    "&:hover": {
      backgroundColor: "#ebf6f9",
    },
  },
  btnIcon: {
    padding: `4px 4px`,
    borderRadius: 5,
    minWidth: 20,
    textTransform: "capitalize",
  },
  btnSmallTrans: {
    backgroundColor: "transparent",
    color: "#888",
    fontFamily: "Roboto",
    border: 0,
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  // ===========================================
  // CHECKBOX
  // ===========================================

  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 5,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#b1354a", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a", // #106ba3 #b1354a
    },
  },
  iconSelect: {
    fill: "#C8203C",
  },

  // ========================================
  // Radio Styled
  // ========================================
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconRadio: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    padding: 0,
  },
  checkedIconRadio: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1354a",
    },
  },

  itemAchievement: {
    borderRadius: 5,
    padding: "5px 15px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  popperClose: {
    pointerEvents: "none",
  },
  menuList: {
    padding: "0",
  },
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    top: "100%",
    zIndex: 1000,
    minWidth: 140,
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundClip: "padding-box",
  },
  dropdownItem: {
    fontWeight: "300",
    lineHeight: "1.5em",
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "fit-content",
    color: "#333",
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    height: "1px",
    overflow: "hidden",
  },
  popperResponsive: {
    zIndex: "1200",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black",
    },
  },
  tableRow: {
    backgroundColor: "#ffffff",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#f1f8ff",
    },
  },
  tableRowGrey: {
    backgroundColor: "#f8f8f8",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#daf7fe",
    },
  },
  projectName: {
    fontFamily: "'Lato', sans-serif",
    color: "#333",
    fontWeight: "normal",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      textDecoration: "underline",
    },
  },
  titleHead: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "500",
    color: "#333",
    fontSize: 14,
    borderBottom: "1.3px solid #aaa",
  },
  chipFilterSort: {
    backgroundColor: "#83829C",
    color: "#fff",
    borderRadius: 9,
    "&:hover, &:focus": {
      backgroundColor: "#9b9baf",
      color: "#5b5b6d",
    },
  },

  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: "center",
    padding: 15,
    border: `2px dashed #DFDFDF`,
    color: "#808080",

    marginBottom: 20,
    marginTop: 10,

    "&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },

  // FREQ
  checkDaily: {
    visibility: "hidden",
    display: "none",
    height: 0,
    width: 0,
    opacity: 0,
  },
});
