import React, { useState } from "react";
import { Box, Typography, Paper, CircularProgress } from "@material-ui/core";

import useSWR from "swr";

import { navigate } from "hookrouter";

import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";

import ListItemTodoList from "./ListItemTodoList";
import handleError from "../../../../../Report/components/global/handleError";
import DialogError from "../../../../../../components/DialogError";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

const fetcher = (url) => AxiosConfig.get(url).then((r) => r.data.data);

const TodoList = ({ classes }) => {
  const [loadCompleted, setLoadCompleted] = useState(false);
  const [loadIncompleted, setLoadIncompleted] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const { data } = useSWR(
    `${URL_API}/todolist?options[paging][limit]=5&options[paging][offset]=0&options[filter][status_id]=1&options[sort][0][field]=created_at&options[sort][0][method]=desc&options[filter][fields][status]=TO DO`,
    fetcher
  );

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.title333} variant="subtitle1">
            <b>To Do List</b>
          </Typography>
          {(loadCompleted || loadIncompleted) && (
            <Box ml={2} display="flex" alignItems="center">
              <CircularProgress
                size={14}
                style={{ color: "#d64253", marginRight: 5 }}
              />
              <Typography className={classes.title888} variant="caption">
                {MyLang() ? bahasa.btnUpdating : english.btnUpdating}
              </Typography>
            </Box>
          )}
        </Box>

        <Box mt={2}>
          {!data && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress
                size={14}
                style={{ color: "#d64253", marginRight: 5 }}
              />
              <Typography className={classes.title888} variant="caption">
                {MyLang() ? bahasa.loading : english.loading}...
              </Typography>
            </Box>
          )}

          {data &&
            data.map((item, i) => (
              <ListItemTodoList key={i} item={item} classes={classes} />
            ))}

          <Box mt={2} display="flex" justifyContent="center">
            <Typography
              className={classes.txtViewMore}
              variant="subtitle2"
              onClick={() => navigate("/task")}
            >
              {" "}
              {MyLang() ? bahasa.viewMore : english.viewMore} View More
            </Typography>
          </Box>
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default TodoList;
