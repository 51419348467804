import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleHistoryClockInOut";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

import ContextHistoryClockInOut from "./Context/ContextHistoryClockInOut";
import FilterDate from "./Components/FilterDate";
import DetailDialog from "./Components/DetailDialog";

import { isAuthenticated } from "../../MyAttendance/Hook/isAuthenticated";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ViewHistoryClockInOut = () => {
  const classes = useStyles();
  const context = useContext(ContextHistoryClockInOut);
  const [loader, setLoader] = useState(false);
  const [bulan, setBulan] = useState(moment().format("MM"));
  const [tahun, setTahun] = useState(moment().format("YYYY"));
  const [isClockIn, setIsClockIn] = useState(true);
  const [status, setStatus] = useState({});
  const [data, setData] = useState([]);
  const [dataRaw, setDataRaw] = useState([]);
  const [detailDialog, setDetailDialog] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [verificationMethod, setVerificationMethod] = useState([]);

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      fetchHistory();
      getVerificationMethod();
    } /* eslint-disable-line */,
    [bulan, tahun]
  );

  useEffect(
    () => {
      filterClockIn();
    } /* eslint-disable-line */,
    [bulan, tahun, isClockIn, dataRaw]
  );

  const getVerificationMethod = async () => {
    try {
      const { data } = await axiosConfig
        .get(`${URL_API}/human-resource/attendance/verification-method`)
        .then((res) => {
          return res.data;
        });
      setVerificationMethod(data);
    } catch (error) {
      console.log(error);
    }
  };

  const filterClockIn = () => {
    let dataArr = [...dataRaw];
    dataArr = dataArr.map((date, index) => {
      let log = date.log.filter((item) => {
        return isClockIn ? item.clock_in != null : item.clock_out != null;
      });
      // untuk memfilter clock in atau clock out
      return { ...date, log };
    });
    setData(dataArr);
  };

  const fetchHistory = async () => {
    let response;
    try {
      setLoader(true);
      response = await axiosConfig.get(
        `${URL_API}/human-resource/attendance/history/clock-in`,
        {
          params: {
            "options[filter][year]": tahun,
            "options[filter][month]": bulan,
          },
        }
      );
      setData(response.data.data);
      setDataRaw(response.data.data);
      setStatus({ status: "success", message: response.data.info.message });
    } catch (error) {
      setStatus({
        status: "error",
        message: error.response?.data?.info.message,
      });
      console.log(error.response);
    } finally {
      setLoader(false);
    }
  };

  const handleDialogClose = () => {
    setDetailDialog(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", color: "#262261" }}
          >
            History Clock In &amp; Out
          </Typography>
        </div>

        <FilterDate
          bulan={bulan}
          tahun={tahun}
          setBulan={setBulan}
          setTahun={setTahun}
        />

        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 20,
            textAlign: "center",
          }}
        >
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="history clock in out table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Tanggal
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Clock In
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Clock In Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Clock In Remark
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Clock Out
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Clock Out Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#262261",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    Clock Out Remark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((date, index) =>
                  isEmpty(date.log) ? (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {moment(date.date)
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      </TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={index}
                      onClick={() => {
                        setDetailData({
                          ...date,
                          date: moment(date.date)
                            .locale("id")
                            .format("DD MMMM YYYY"),
                          verificationMethod: verificationMethod,
                          clock_in: date.clock_in,
                          clock_out: date.clock_out,
                          clock_in_status: date.clock_in_status,
                          clock_out_status: date.clock_out_status,
                          clock_in_remark: date.remarks_clock_in,
                          clock_out_remark: date.remarks_clock_out,
                          deskripsi_clock_in: date.deskripsi_clock_in,
                          deskripsi_clock_out: date.deskripsi_clock_out,
                        });
                        setDetailDialog(true);
                      }}
                    >
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {moment(date.date)
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {date.clock_in}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {date.clock_in_status}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {date.remarks_clock_in}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {date.clock_out}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {date.clock_out_status}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color: "#262261",
                            fontWeight: "bold",
                          }}
                        >
                          {date.remarks_clock_out}
                        </p>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <DetailDialog
          handleClose={handleDialogClose}
          open={detailDialog}
          data={detailData}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewHistoryClockInOut;
