import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import Carousel from "react-material-ui-carousel";

import helpMobile from "../../../../assets/images/help-mobile.png";
import helpWeb from "../../../../assets/images/help-web.png";

import helpControl from "../../../../assets/images/help-control.png";
import helpGoalSetting from "../../../../assets/images/help-goal-setting.png";
import helpStrategic from "../../../../assets/images/help-strategic.png";

import helpGoal from "../../../../assets/images/help-goal.png";
import helpMa from "../../../../assets/images/help-ma.png";
import helpMeeting from "../../../../assets/images/help-meeting.png";
import helpReward from "../../../../assets/images/help-reward.png";
import helpScoreboard from "../../../../assets/images/help-scoreboard.png";
import helpTeambuild from "../../../../assets/images/help-teambuild.png";
import helpTimeManage from "../../../../assets/images/help-time-manage.png";

import styles from "../../Style/weDoStyle";

const useStyle = makeStyles(styles);

const WhatWeDo = () => {
  const classes = useStyle();
  const [helpOption, setHelpOption] = useState({
    title: "Tools (Desktop & Mobile)",
    id: 1,
  });

  const handleHelp = (data) => {
    setHelpOption(data);
  };

  return (
    <Box mt={16}>
      <Box display="flex" justifyContent="center" mb={8}>
        <Typography variant="h5" className={classes.title333}>
          <b>Cara kami membantu anda</b>
        </Typography>
      </Box>

      <Grid container className={classes.headTitle}>
        {listTitleHelp.map((item, i) => {
          const isCurrent = item.id === helpOption.id;

          return (
            <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="h6"
                  className={
                    isCurrent ? classes.titleActive : classes.titleHelp
                  }
                  onClick={() => handleHelp(item)}>
                  {item.title}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 55 }}>
        {helpOption.id === 0 &&
          listBussinessAssist.map((item, i) => (
            <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
              <Box className={classes.cardWeDo}>
                <Box textAlign="center" height={90}>
                  <Typography variant="h6" className={classes.title333}>
                    <b>{item.title}</b>
                  </Typography>
                </Box>

                <Box mt={3} display="flex" justifyContent="center">
                  <img src={item.img} height={190} width="100%" />
                </Box>

                <Box mt={2}>
                  <Button
                    className={classes.btnLearn}
                    variant="outlined"
                    size="small"
                    endIcon={<ArrowForwardIcon />}>
                    Pelajari caranya
                  </Button>
                </Box>

                <Box mt={3} textAlign="left" height={150}>
                  <Typography variant="subtitle1" className={classes.title888}>
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}

        {helpOption.id === 1 &&
          listDesktopMobile.map((item, i) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
              <Box className={classes.cardWeDo}>
                <Box textAlign="center" height={90}>
                  <Typography variant="h6" className={classes.title333}>
                    <b>{item.title}</b>
                  </Typography>
                </Box>

                <Box mt={3} display="flex" justifyContent="center">
                  <img src={item.img} height={260} width="100%" />
                </Box>

                <Box mt={2}>
                  <Button
                    className={classes.btnLearn}
                    variant="outlined"
                    size="small"
                    endIcon={<ArrowForwardIcon />}>
                    Pelajari caranya
                  </Button>
                </Box>

                <Box mt={3} textAlign="left" height={150}>
                  <Typography variant="subtitle1" className={classes.title888}>
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}

        {helpOption.id === 2 && (
          <Carousel animation="slide">
            {listTrackReport.map((item, i) => (
              <Grid container key={i}>
                {item.frameworks.map((framework, j) => (
                  <Grid item xs={12} sm={12} md={4} lg={4} key={j}>
                    <Box className={classes.cardWeDo}>
                      <Box textAlign="center" height={90}>
                        <Typography variant="h6" className={classes.title333}>
                          <b>{framework.title}</b>
                        </Typography>
                      </Box>

                      <Box mt={3} display="flex" justifyContent="center">
                        <img src={framework.img} height={190} width="100%" />
                      </Box>

                      <Box mt={2}>
                        <Button
                          className={classes.btnLearn}
                          variant="outlined"
                          size="small"
                          endIcon={<ArrowForwardIcon />}>
                          Pelajari caranya
                        </Button>
                      </Box>

                      <Box mt={3} textAlign="left" height={150}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title888}>
                          {framework.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Carousel>
        )}
      </Grid>
    </Box>
  );
};

export default WhatWeDo;

const listTitleHelp = [
  {
    title: "Tools",
    id: 1,
  },
  {
    title: "Pendampingan Bisnis",
    id: 0,
  },
  {
    title: "Framework",
    id: 2,
  },
];

const listTrackReport = [
  {
    frameworks: [
      {
        title: "Goal",
        description:
          "Target yang menjadi tujuan bersama sebuah perusahan/divisi/individu yang biasanya merupakan turunan dari visi dan misinya.",
        img: helpGoal,
      },
      {
        title: "Measured Activity",
        description:
          "Untuk bisa mencapai target yang ingin dicapai, adalah vital untuk menentukan strategi yang paling sesuai untuk bisa mencapai target tersebut.",
        img: helpMa,
      },
      {
        title: "Regular Meeting",
        description:
          "Pertemuan berkala yang dihadiri oleh manajemen dan semua karyawan terkait untuk mengetahui pencapaian dan problem yang muncul, dan merencanakan perbaikan ke depannya.",
        img: helpMeeting,
      },
    ],
  },
  {
    frameworks: [
      {
        title: "Reward & Punishment",
        description:
          "Berfungsi sebagai pendorong motivasi karyawan agar dapat mencapai targetnya masing-masing dengan lebih semangat lagi.",
        img: helpReward,
      },
      {
        title: "Scoreboard",
        description:
          "Sebuah media atau tempat untuk melihat status pencapaian goal dan aktivitas di dalam sebuah perusahaan atau organisasi.",
        img: helpScoreboard,
      },
      {
        title: "Team Building",
        description:
          "Membangun sebuah tim yang dapat bekerja secara profesional dan menjalankan pekerjaannya dengan semangat dan bahagia adalah kunci bagi suksesnya eksekusi strategi perusahaan.",
        img: helpTeambuild,
      },
    ],
  },
  {
    frameworks: [
      {
        title: "Time Management",
        description:
          "Optimasi penggunaan waktu karyawan sehingga dapat memberikan hasil terbaik baik perusahaan.",
        img: helpTimeManage,
      },
      {
        title: "Goal",
        description:
          "Target yang menjadi tujuan bersama sebuah perusahan/divisi/individu yang biasanya merupakan turunan dari visi dan misinya.",
        img: helpGoal,
      },
      {
        title: "Measured Activity",
        description:
          "Untuk bisa mencapai target yang ingin dicapai, adalah vital untuk menentukan strategi yang paling sesuai untuk bisa mencapai target tersebut.",
        img: helpMa,
      },
    ],
  },
];

const listDesktopMobile = [
  {
    title: "Mobile Apps",
    description:
      "Aplikasi mobile performate.id memudahkan Anda untuk bisa mengakses, memonitor, dan mengupdate kinerja karyawan / tim Anda dimana saja dan kapan saja melalui perangkat smartphone",
    img: helpMobile,
  },
  {
    title: "Desktop Website",
    description:
      "Performate.id tersedia dalam bentuk desktop website yang membuat Anda tidak perlu menginstall aplikasi baru untuk bisa memonitor serta mendapatkan update terkait kinerja karyawan / tim Anda di setiap perangkat desktop yang Anda miliki",
    img: helpWeb,
  },
];

const listBussinessAssist = [
  {
    title: "Goal Setting",
    description:
      "Mendampingi dalam penyusunan goal yang baik dan berdampak untuk organisasi dan setiap individu yang ada di organisasi",
    img: helpGoalSetting,
  },
  {
    title: "Strategic Activity",
    description:
      "Mendampingi penyusunan seluruh strategi yang dijabarkan kedalam aktivitas terukur untuk seluruh tim untuk mencapai goal yang sudah ditetapkan",
    img: helpStrategic,
  },
  {
    title: "Control",
    description:
      "Mengaudit kinerja dan memastikan aktivitas berjalan sesuai dengan strategi yang telah direncanakan, serta memfasilitasi problem solving secara berkala",
    img: helpControl,
  },
];
