import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Axios from "axios";
import { URL_API } from "../../../../constants/config-api";

import Group_724 from "../../../../assets/images/Group-724.png";
import DialogError from "../../../../components/DialogError";
import { navigate } from "hookrouter";
import { ToCompletionProfile } from "../../../../constants/config-redirect-url";
import { ACCOUNT_TYPE_PERSONAL, PACKAGE_PERSONAL } from "../constant-data";

const DialogFreeTrialOneMonth = ({
  isModalTrialConfirmation,
  setModalTrialConfirmation,
  classes,
  userToken,
}) => {
  const [loader, setLoader] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleFreeTrial = () => {
    setLoader(true);

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      };

      const data = {
        Membership: {
          membership_account_type_id: ACCOUNT_TYPE_PERSONAL,
          membership_package_id: PACKAGE_PERSONAL,
        },
      };

      Axios.defaults.headers.common = header;

      Axios.post(
        `${URL_API}/account-management/membership:subscribe-trial`,
        data
      )
        .then(function (response) {
          // closeModalAnggota();
          // console.log("Res sbcrb TRIAL : ", response); // 2019-07-18 01:45:20

          if (response.status === 200) {
            // setTrialExpiryTimestamp(response.data.data.trial_expiry_timestamp);
            navigate(ToCompletionProfile);
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          // console.log("Error : ", error);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  };

  return (
    <Dialog
      open={isModalTrialConfirmation}
      aria-labelledby="alert-dialog-title-trial-confirmation"
      aria-describedby="alert-dialog-description-trial-confirmation"
    >
      <DialogTitle
        id="alert-dialog-title-trial-confirmation"
        style={{ textAlign: "center" }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setModalTrialConfirmation(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <img
          src={Group_724}
          className={classes.media}
          alt="Pict Trial Modal Confirmation"
        />
      </DialogTitle>
      <DialogContent style={{ textAlign: "center", width: 400 }}>
        <DialogContentText id="alert-dialog-description-trial-confirmation">
          <Typography variant="h6" className={classes.title}>
            <b>Coba gratis paket Personal !</b>
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ marginTop: 15, marginLeft: 25, marginRight: 25 }}
          >
            Gunakan Performate gratis 30 hari tanpa limit lisensi user dan
            dapatkan gratis 1 lisensi selamanya setelah waktu promo habis
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={handleFreeTrial}
          variant="contained"
          style={{ width: 150 }}
          className={classes.button}
        >
          {loader ? (
            <CircularProgress color="#fff" size={15} />
          ) : (
            "Ya, Lanjutkan"
          )}
        </Button>
      </DialogActions>
      <br />
      <br />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogFreeTrialOneMonth;
