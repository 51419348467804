import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
} from "@material-ui/core";
import Paginating from "@material-ui/lab/Pagination";
import moment from "moment";

import ContextAttendanceReportAdmin from "../../../Context/ContextAttendanceReportAdmin";

const Pagination = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  const handleSelect = (e) => {
    const valuePerPage = e.target.value;
    let dataTemp            = context.dataSettingDetail;
    dataTemp.paging.limit   = parseInt(valuePerPage);
    dataTemp.paging.page    = 1;
    dataTemp.paging.offset  = 0;
    context.setDataSettingDetail({...dataTemp});
    context.getReportDetailList();
  }

  const handlePaginating = (e, value) => {
    let dataTemp            = context.dataSettingDetail;
    dataTemp.paging.page    = value;
    dataTemp.paging.offset  = dataTemp.paging.limit * (value - 1);
    context.setDataSettingDetail({...dataTemp});
    context.getReportDetailList();
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.title888} variant="caption">
        Records Per Page
      </Typography>
      <FormControl size="small" style={{ margin: "0 10px", minWidth: 50 }}>
        <NativeSelect
          onChange={handleSelect}
          value={context.dataSettingDetail.paging.limit}
          inputProps={{
            name: "name",
            id: "uncontrolled-native",
          }}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </NativeSelect>
      </FormControl>

      <Paginating
        className={classes.paginating}
        variant="text"
        shape="rounded"
        count={
          context.dataSettingDetail.paging.count > 0 ?
          Math.ceil(context.dataSettingDetail.paging.count / context.dataSettingDetail.paging.limit) : 0
        }
        page={context.dataSettingDetail.paging.page}
        onChange={handlePaginating}
      />
    </Box>
  );
};

export default Pagination;
