/*
  ````````````````````````````````````````````````````````````````````````````````````````````````
  NEXT GANTI INI LINECHART-nya : 
  
    - https://apexcharts.com/react-chart-demos/line-charts/brush-chart/

    - https://codesandbox.io/s/q8o7zqw7lw

  ````````````````````````````````````````````````````````````````````````````````````````````````
*/

import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
} from 'react';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import TimerRoundedIcon from '@material-ui/icons/TimerRounded';

import moment from 'moment';
import clsx from 'clsx';

import ContextGoalDetail from '../Context/ContextGoalDetail';

import axiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';

import 'moment/locale/id';
import { FormatDecimal } from '../../../../utilities/FormatDecimal';
import CycleLineIndicator from './frequency/CycleLineIndicator';
import MyLang from '../../../../utilities/MyLang';
import { bahasa, english } from '../../../../constants/config-lang';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#DA5D6E',
    '&$checked': {
      color: '#d1354a',
    },
    '&$checked + $track': {
      backgroundColor: '#d1354a',
    },
  },
  checked: {},
  track: {},
})(Switch);

const DotLineChartGoalDetailComplete = (props) => {
  const {
    classes,
    isModalCreate, //*Ini state from GOAL RESULT
    isModalEdit,
    isModalDelete,
    goalDetailState,
  } = props;

  const context = useContext(ContextGoalDetail);

  const [anchorChart, setAnchorChart] = useState(null);
  const [anchorInterval, setAnchorInterval] = useState(null);
  const [chartType, setChartType] = useState({
    name: 'Result (descrete)',
    code: 'discrete',
  });
  const [intervalFilter, setIntervalFilter] = useState({
    code: 'today',
    caption: 'Today',
    selected: true,
  });
  const [activeHours, setActiveHours] = useState(false);
  const [dataResult, setDataResult] = useState(false);
  const [interval, setIntervals] = useState(
    goalDetailState?.update_frequency?.interval
  );
  const [listIntervalFilter, setListIntervalFilter] = useState([]);
  const [showDate, setShowDate] = useState(true);
  const [loading, setLoading] = useState(false);

  const [dataGoalResult, setDataGoalResult] = useState([]);
  const [collectionGoalResultList, setCollectionGoalResultList] =
    useState([]);
  const [dataResultList, setDataResultList] = useState([]);

  // STATE For TIMER Countdown
  const [countDown, setCountDown] = useState(0);
  const [gapMinute, setGapMinute] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [nextUpdateHourly, setNextUpdateHourly] = useState(null);
  const [nextUpdateDaily, setNextUpdateDaily] = useState(null);
  const [nextUpdateWeekly, setNextUpdateWeekly] = useState(null);
  const [nextUpdateMonthly, setNextUpdateMonthly] = useState(null);

  const locationPathGoalId = window.location.pathname.split('/')[2];

  // // console.log("GDST", goalDetailState);

  useEffect(() => {
    if (interval !== null && interval !== undefined) {
      setLoading(true);

      axiosConfig
        .get(
          URL_API +
            `/swd/goal/${locationPathGoalId}/result-value-graph?option=discrete&interval=${interval}&interval_filter=all_time&break_time=${activeHours}`
        )
        .then(function (res) {
          // // console.log("res Line Chart: ", res);

          // // console.log("Data Goal", dataGoal);

          if (res.status === 200) {
            if (res.data.data.dataPoints.length > 0) {
              const dataPoints = res.data.data.dataPoints.map(
                (item) => {
                  return {
                    x: item.x,
                    y: item.y,
                    tv: item.tv,
                    tooltip: item.tooltip,
                  };
                }
              );

              // setDataGoalResult(dataPoints);
              setCollectionGoalResultList(dataPoints);
            }
          }
          setLoading(false);
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          setLoading(false);
        });
    }
  }, [
    isModalCreate,
    isModalEdit,
    isModalDelete,
    context.triggerGR,
    context.triggerGoalDetail,
    chartType,
    intervalFilter,
    interval,
    activeHours,
  ]);

  useEffect(() => {
    // if (dataResult) {
    axiosConfig
      .get(
        `${URL_API}/swd/goal/${locationPathGoalId}/result?options[paging][offset]=${
          context.offset === -8 ? 0 : context.offset
        }&options[paging][limit]=${
          context.rowsPerPage
        }&options[paging][page]=${context.page}`
      )
      .then((res) => {
        if (res.status === 200) {
          const result = res.data?.data;

          const dataPoints = result?.map((item) => {
            return {
              Pencapaian: Number(item?.result_value).toFixed(2),
              name: moment(item?.result_date).format('DD MMM YYYY'),
              Target: 0,
              tooltip: null,
            };
          });

          setDataResultList(dataPoints);
        }
      });
    // }
  }, []);

  // // console.log("GDS", goalDetailState?.update_frequency?.interval);

  useEffect(() => {
    if (
      goalDetailState?.update_frequency !== null &&
      goalDetailState?.update_frequency !== undefined
    ) {
      setIntervals(goalDetailState?.update_frequency?.interval);
    }
  }, [goalDetailState]);

  useEffect(() => {
    let dataGoalResultLet = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item) => {
        let items = {
          name: item.x,
          Pencapaian: Number(item.y).toFixed(2),
          Target: item.tv,
          tooltip: item.tooltip,
        };

        dataGoalResultLet.push(items);
      });
    }

    if (dataResult) {
      const setTargetVal = dataResultList?.map((item) => {
        return {
          ...item,
          Target: goalDetailState?.target_value,
        };
      });

      setDataGoalResult(setTargetVal.reverse());
    } else {
      setDataGoalResult(dataGoalResultLet);
    }
  }, [collectionGoalResultList, dataResult]);

  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);

  useEffect(() => {
    let list = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item, i) => {
        list.push(parseInt(item.y));
      });

      // // console.log("List in", list)

      setMax(Math.max(...list));
      setMin(Math.min(...list));
    }
  }, [collectionGoalResultList]);

  const handleCloseAnchorChart = () => {
    setAnchorChart(null);
  };

  const handleShowAnchoeChart = (event) => {
    setAnchorChart(event.currentTarget);
  };

  const handleCloseAnchorInterval = () => {
    setAnchorInterval(null);
  };

  const handleShowAnchoeInterval = (event) => {
    setAnchorInterval(event.currentTarget);
  };

  const handleShowDate = () => {
    setShowDate((prev) => !prev);
  };

  const handleActiveHours = (e) => {
    setActiveHours(e.target.checked);
  };

  const handleDataResult = (e) => {
    setDataResult(e.target.checked);
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          style={{ fontSize: 14 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // // console.log("paypaY", payload);

      return (
        <Box
          bgcolor="#fff"
          border="1px solid #cfcfcf"
          borderRadius={5}
          p={2}
          display="flex"
          flexDirection="column"
        >
          {payload[0]?.payload?.tooltip !== null && (
            <Typography variant="subtitle1" className="label">
              <b>{payload[0]?.payload?.tooltip}</b>
            </Typography>
          )}
          <Typography variant="subtitle2" className="intro">
            {MyLang() ? bahasa.achievement : english.achievement} :{' '}
            {FormatDecimal(payload[0]?.value)}
          </Typography>
          <Typography variant="subtitle2" className="intro">
            Target : {FormatDecimal(payload[1]?.value)}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  // ===================================================================
  // ~~~~~~~~~~~~~~~~ Handle Next Update ~~~~~~~~~~~~~~~~
  // ===================================================================
  let DAY_FOR_WEEKLY;

  const scheduled_at =
    goalDetailState?.update_frequency?.scheduled_at;
  const dayWeekly =
    goalDetailState?.update_frequency?.scheduled_at.split('-')[0];
  const hourWeekly =
    goalDetailState?.update_frequency?.scheduled_at.split('-')[1];
  const date = new Date();
  const getHourInterval = moment.duration(scheduled_at, 'm');
  const getDayInterval = moment(date)
    .add(1, 'd')
    .format(`dddd, ${scheduled_at}`);
  const getWeekInterval = moment(date)
    .add(1, 'w')
    .format(`DD-MMM, ${hourWeekly}`);

  const hourInterval = moment(date).format(
    `HH:${getHourInterval.minutes()}`
  );
  const dayInerval = moment(date).format(`${scheduled_at}`);
  const weekInterval = moment(date).format(`${hourWeekly}`);
  // const dayInterval = moment(date).format(`dddd HH:${getDayInterval.hours()}`);
  // const subtractMinutes = moment.duration(15, "m");
  // const minuteToCountdown = moment(hourInterval, "HH:mm")
  //   .subtract(subtractMinutes)
  //   .format("HH:mm");

  const hour = moment(date).format(
    `YYYY-MM-DD HH:${getHourInterval.minutes()}`
  ); // Target Hour
  const daily = moment(date).format(`YYYY-MM-DD ${scheduled_at}`);
  const weekly = moment(date).format(`YYYY-MM-DD ${hourWeekly}`);

  // // console.log("hourInterval", hourInterval);
  // // console.log("dayInterval", dayInerval);
  // // console.log("weekInterval", weekInterval);
  // // console.log("getHourInterval", getHourInterval.minutes());
  // // console.log("getDayInterval", getDayInterval);
  // // console.log("getWeekInterval", getWeekInterval);
  // // console.log("minuteToCountdown", minuteToCountdown);
  // // console.log("hour", hour);
  // // console.log("daily", daily);
  // // console.log("compare", compare);
  // // console.log("diff", dif);
  // // console.log("isCountdownHour", isCountdownHour);
  // // console.log("difHour", difHour);
  // // console.log("timeFinish", timeFinish);
  // // console.log("timeStart", timeStart);
  // // console.log("scheduled_at", scheduled_at);

  // Convert to IDN
  if (dayWeekly === 'monday') DAY_FOR_WEEKLY = 'Senin';
  if (dayWeekly === 'tuesday') DAY_FOR_WEEKLY = 'Selasa';
  if (dayWeekly === 'wednesday') DAY_FOR_WEEKLY = 'Rabu';
  if (dayWeekly === 'thursday') DAY_FOR_WEEKLY = 'Kamis';
  if (dayWeekly === 'friday') DAY_FOR_WEEKLY = 'Jumat';
  if (dayWeekly === 'saturday') DAY_FOR_WEEKLY = 'Sabtu';
  if (dayWeekly === 'sunday') DAY_FOR_WEEKLY = 'Minggu';

  const weeklyNow = moment().isoWeekday(DAY_FOR_WEEKLY);
  const isDayInWeekly = moment(weeklyNow).isSame(new Date(), 'days');

  // ================================== | NEW Config next Update | ==================================
  const next_update = goalDetailState?.update_frequency_next_period;
  const _gr = next_update?.gr;
  const _max = next_update?.max;
  const _min = next_update?.min;

  // // console.log("next_update", next_update);
  // // console.log("GR", _gr);
  // // console.log("MAX", _max);

  // ================================== | For Countdown | ==================================

  useEffect(() => {
    let secTimer = setInterval(() => {
      if (scheduled_at !== undefined) {
        var date = moment().format('YYYY-MM-DD HH:mm');
        let compare;

        if (interval === 'hourly') {
          // if (moment(_max).diff(moment(date), "m") < 1) {
          const addHour = moment(_max).add(1, 'hours');
          compare = moment(addHour).diff(moment(date), 'm');

          setNextUpdateHourly(moment(_max).format('HH:mm')); // next
          // } else {
          //   compare = moment(hour).diff(moment(date), "m");
          //   setNextUpdateHourly(hourInterval); // now
          // }
        }

        if (interval === 'daily') {
          compare = moment(_max).diff(moment(date), 'm');

          // set next update
          // if (moment(daily).diff(moment(date), "m") < 1) {
          setNextUpdateDaily(moment(_max).format('dddd HH:mm')); // next
          // setNextUpdateDaily(getDayInterval); // next
          // } else {
          //   setNextUpdateDaily(dayInerval); // now
          // }
        }

        if (interval === 'weekly') {
          compare = moment(_max).diff(moment(date), 'm');

          // set next update
          // if (moment(weekly).diff(moment(date), "m") < 1) {
          setNextUpdateWeekly(
            moment(_max).format('dddd, DD/MMM HH:mm')
          ); // next
          // setNextUpdateWeekly(getWeekInterval); // next
          // } else {
          //   setNextUpdateWeekly(weekInterval); // now
          // }
        }

        if (interval === 'monthly') {
          compare = moment(_max).diff(moment(date), 'm');

          // set next update
          // if (moment(monthly).diff(moment(date), "m") < 1) {
          setNextUpdateMonthly(moment(_max).format('DD/MMM HH:mm')); // next
        }

        // // console.log("COMPARE", compare);
        // // console.log("INTRVL", interval);
        // // console.log("DATE", date);
        // // console.log("hour", hour);

        setGapMinute(compare);

        if (compare <= 15 && compare > 0) {
          setRunTimer(true);
        } else {
          setRunTimer(false);
        }
      }
    }, 1000);

    return () => clearInterval(secTimer);
  }, [interval, hour, daily, weekly]);

  useEffect(() => {
    if (scheduled_at !== undefined) {
      let timerId;

      if (runTimer) {
        setCountDown(60 * gapMinute);
        timerId = setInterval(() => {
          setCountDown((countDown) => countDown - 1);
        }, 1000);
      } else {
        clearInterval(timerId);
        setCountDown(0);
      }

      return () => clearInterval(timerId);
    }
  }, [runTimer, gapMinute]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  // // console.log("INTERVAL", interval);

  return (
    <Fragment>
      <Box
        mb={2}
        ml={3}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <PurpleSwitch
              checked={showDate}
              onChange={handleShowDate}
              name="showDate"
              size="small"
            />
          }
          style={{ marginRight: 0 }}
          label={
            <Typography variant="caption" className={classes.title}>
              {MyLang() ? bahasa.showDate : english.showDate}
            </Typography>
          }
        />
        {/* {(goalDetailState.calculation_method_id === "1" ||
          goalDetailState.calculation_method_id === "3") && (
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Button
              className={classes.btnWhite}
              variant="outlined"
              size="small"
              endIcon={<ArrowDropDownIcon />}
              onClick={handleShowAnchoeChart}
            >
              {chartType.name}
            </Button>
          </>
        )} */}
        {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
        {/* <Button
          className={classes.btnWhite}
          variant="outlined"
          size="small"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleShowAnchoeInterval}
        >
          {intervalFilter.caption}
        </Button> */}
        <Divider orientation="vertical" variant="middle" flexItem />
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkboxRoot}
              icon={<span className={classes.icon} />}
              checkedIcon={
                <span
                  className={clsx(classes.icon, classes.checkedIcon)}
                />
              }
              checked={activeHours}
              onChange={handleActiveHours}
            />
          }
          label={
            <Typography
              variant="subtitle2"
              className={classes.title888}
            >
              {MyLang()
                ? bahasa.displayAllData
                : english.displayAllData}
            </Typography>
          }
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkboxRoot}
              icon={<span className={classes.icon} />}
              checkedIcon={
                <span
                  className={clsx(classes.icon, classes.checkedIcon)}
                />
              }
              checked={dataResult}
              onChange={handleDataResult}
            />
          }
          label={
            <Typography
              variant="subtitle2"
              className={classes.title888}
            >
              Data from Result
            </Typography>
          }
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorChart}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorChart)}
          onClose={handleCloseAnchorChart}
        >
          <MenuItem
            onClick={() => {
              setAnchorChart(null);
              setChartType({
                name: 'Actual (default)',
                code: 'actual',
              });
            }}
            style={{ width: 150 }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              {MyLang() ? bahasa.actual : english.actual} (
              {MyLang() ? bahasa.default : english.default})
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorChart(null);
              setChartType({
                name: 'Result (cumulative)',
                code: 'cumulative',
              });
            }}
            style={{ width: 150 }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              {MyLang() ? bahasa.result : english.result} (
              {MyLang() ? bahasa.cumulative : english.cumulative})
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorChart(null);
              setChartType({
                name: 'Result (descrete)',
                code: 'discrete',
              });
            }}
            style={{ width: 150 }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              {MyLang() ? bahasa.result : english.result} (
              {MyLang() ? bahasa.discrete : english.discrete})
            </Typography>
          </MenuItem>
        </Menu>

        <Menu
          id="simple-menu"
          anchorEl={anchorInterval}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorInterval)}
          onClose={handleCloseAnchorInterval}
        >
          {listIntervalFilter.length > 0 &&
            listIntervalFilter.map((item, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  setAnchorInterval(null);
                  setIntervalFilter(item);
                }}
                style={{ width: 150 }}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                >
                  {item?.caption}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      </Box>

      {loading ? (
        <Box display="flex" alignItems="center" ml={4} mb={2}>
          <CircularProgress
            size={18}
            style={{ color: '#d1354a', marginRight: 7 }}
          />
          <Typography
            variant="subtitle2"
            className={classes.title888}
          >
            {MyLang() ? bahasa.loading : english.loading}{' '}
            {MyLang() ? bahasa.chart : english.chart}...
          </Typography>
        </Box>
      ) : (
        <ResponsiveContainer width="98%" height={300}>
          <LineChart
            data={dataGoalResult}
            margin={{ top: 8, right: 0, left: 24, bottom: 8 }}
          >
            <XAxis
              dataKey="name"
              height={
                dataGoalResult.length === 0 ? 0 : showDate ? 60 : 5
              }
              tick={<CustomizedAxisTick />}
            />
            {/* <YAxis /> */}

            <YAxis
              domain={[min, max]}

              /*
                        ``````````````````````````````````````````````````````````````````````
                        https://stackoverflow.com/questions/50078787/recharts-set-y-axis-range

                        ``````````````````````````````````````````````````````````````````````
                      */
            />

            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="linear"
              dataKey="Pencapaian"
              stroke="#d1354a"
            />
            <Line type="linear" dataKey="Target" stroke="#00b894" />
          </LineChart>
        </ResponsiveContainer>
      )}

      {/* {collectionGoalResultList.length === 0 && !loading && (
        <Box ml={4} mb={2}>
          <Typography variant="subtitle2" className={classes.title555}>
            Data tidak ditemukan
          </Typography>
        </Box>
      )} */}

      {interval === 'hourly' ? (
        <Box
          ml={4}
          my={2}
          display="flex"
          justifyContent="space-between"
        >
          <Box>
            {goalDetailState?.update_frequency !== null && (
              <Typography
                variant="subtitle2"
                className={classes.title555}
              >
                <b>{MyLang() ? bahasa.day : english.day}</b> :{' '}
                {
                  goalDetailState?.update_frequency
                    ?.selected_days_sorted
                }
              </Typography>
            )}
            <Box display="flex" flexWrap="wrap">
              <Typography
                variant="subtitle2"
                className={classes.title555}
              >
                <b>{MyLang() ? bahasa.range : english.range}</b> :
              </Typography>
              <Box mx={0.5} />
              {goalDetailState?.update_frequency?.hourly_details
                ?.length > 0
                ? goalDetailState?.update_frequency?.hourly_details?.map(
                    (item, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="subtitle2"
                          className={classes.title555}
                          style={{ marginRight: 8 }}
                        >
                          {item?.time_start} - {item?.time_finish},
                        </Typography>
                      );
                    }
                  )
                : null}
            </Box>
          </Box>

          <Box mr={2} textAlign="right">
            {goalDetailState?.update_frequency_next_period?.gr ===
              null && (
              <Typography
                variant="subtitle2"
                className={classes.title555}
              >
                <b>
                  {MyLang() ? bahasa.nextUpdate : english.nextUpdate}{' '}
                  (
                  {moment(
                    goalDetailState?.update_frequency_next_period?.max
                  ).format('HH:mm')}
                  )
                </b>
              </Typography>
            )}

            {runTimer && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <TimerRoundedIcon
                  style={{ fontSize: 25, color: '#555' }}
                />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      {interval === 'daily' ? (
        <Box
          ml={4}
          my={2}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex" flexWrap="wrap">
            <Typography
              variant="subtitle2"
              className={classes.title555}
            >
              <b>{MyLang() ? bahasa.day : english.day}</b> :
              {
                goalDetailState?.update_frequency
                  ?.selected_days_sorted
              }
            </Typography>
            <Box mx={0.5} />
          </Box>

          <Box mr={2} textAlign="right">
            {goalDetailState?.update_frequency_next_period?.gr ===
              null && (
              <Typography
                variant="subtitle2"
                className={classes.title555}
              >
                <b>
                  {MyLang() ? bahasa.nextUpdate : english.nextUpdate}{' '}
                  (
                  {moment(
                    goalDetailState?.update_frequency_next_period?.max
                  ).format('dddd - HH:mm')}
                  )
                </b>{' '}
              </Typography>
            )}

            {runTimer && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <TimerRoundedIcon
                  style={{ fontSize: 25, color: '#555' }}
                />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      {interval === 'weekly' ? (
        <Box
          ml={4}
          my={2}
          display="flex"
          justifyContent="space-between"
        >
          <Box>
            {/* <Box display="flex" flexWrap="wrap">
              <Typography variant="subtitle2" className={classes.title555}>
                <b>Days</b> :
                {goalDetailState?.update_frequency?.selected_days_sorted}
              </Typography>
              <Box mx={0.5} />
            </Box> */}
          </Box>

          <Box mr={2} textAlign="right">
            {goalDetailState?.update_frequency_next_period?.gr ===
              null && (
              <Typography
                variant="subtitle2"
                className={classes.title555}
              >
                <b>
                  {MyLang() ? bahasa.nextUpdate : english.nextUpdate}{' '}
                  (
                  {moment(
                    goalDetailState?.update_frequency_next_period?.max
                  ).format('dddd, (DD) MMM - HH:mm')}
                  )
                </b>
              </Typography>
            )}

            {runTimer && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <TimerRoundedIcon
                  style={{ fontSize: 25, color: '#555' }}
                />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      {interval === 'monthly' ? (
        <Box
          ml={4}
          my={2}
          display="flex"
          justifyContent="space-between"
        >
          <Box></Box>

          <Box mr={2} textAlign="right">
            {goalDetailState?.update_frequency_next_period?.gr ===
              null && (
              <Typography
                variant="subtitle2"
                className={classes.title555}
              >
                <b>
                  {MyLang() ? bahasa.nextUpdate : english.nextUpdate}{' '}
                  (
                  {moment(
                    goalDetailState?.update_frequency_next_period?.max
                  ).format('DD/MMM - HH:mm')}
                  )
                </b>
              </Typography>
            )}

            {runTimer && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <TimerRoundedIcon
                  style={{ fontSize: 25, color: '#555' }}
                />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      {/* ================================================================ */}
      {/* CYCLE LINE INDICATOR */}
      {/* ================================================================ */}
      <CycleLineIndicator
        classes={classes}
        interval={interval}
        goalData={goalDetailState}
        graphLength={collectionGoalResultList}
      />
    </Fragment>
  );
};

export default DotLineChartGoalDetailComplete;

// https://github.com/recharts/recharts/issues/1849
// https://github.com/recharts/recharts/issues/1139
