import React from "react";

import { FormControl } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { id } from "date-fns/locale";
import moment from "moment";

const FilterDate = ({ bulan, tahun, setBulan, setTahun }) => {
  return (
    <div
      style={{
        marginLeft: 100,
        marginRight: 100,
        marginTop: 20,
        textAlign: "center",
      }}
    >
      <FormControl
        style={{
          width: "40%",
        }}
      >
        <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
          <KeyboardDatePicker
            views={["year", "month"]}
            variant="outlined"
            openTo="year"
            value={moment(tahun + "-" + bulan, "YYYY-MM")}
            onChange={(v) => {
              setBulan(moment(v).format("MM"));
              setTahun(moment(v).format("YYYY"));
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </div>
  );
};

export default FilterDate;
