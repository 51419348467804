import React, { useContext, useEffect, useState, useRef } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { styles } from "../Style/StyleRequestAttendance";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { id } from "date-fns/locale";

import ContextRequestAttendance from "../Context/ContextRequestAttendance";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CE354A", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const RequestAttendanceDialog = ({
  handleClose,
  open,
  verification,
  value,
  setValue,
}) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          AJUKAN ATTENDANCE
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date"
                label="Kapan"
                format="d, MMMM, yyyy"
                maxDate={new Date()}
                value={value.workday}
                onChange={(v) => {
                  setValue({ ...value, workday: v });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                margin="normal"
                id="clockin"
                label="Clock In"
                format="HH:mm"
                value={value.clock_in}
                onChange={(v) => {
                  setValue({ ...value, clock_in: v });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time clock in",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                margin="normal"
                id="clockout"
                label="Clock Out"
                format="HH:mm"
                value={value.clock_out}
                onChange={(v) => {
                  setValue({ ...value, clock_out: v });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time clock out",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <TextField
              fullWidth
              label="Deskripsi"
              placeholder="Masukkan deskripsi (Opsional)"
              onChange={(e) => {
                setValue({ ...value, description: e.target.value });
              }}
              value={value.description}
            />
          </FormControl>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "1rem",
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={
                  () => {
                    handleClose();
                  } /*handleCancel()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={
                  () => {
                    verification();
                  } /*handleSubmit()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Ajukan
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default RequestAttendanceDialog;
