import React, { useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Table,
  TableBody,
  Checkbox,
  Button,
} from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";

import ListCardItemMA from "../components/ListCardItemMAv2";
import PictEmptyStateMA from "../../MeasureActivities/Components/PictEmptyStateMA";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextAllMA from "../context/AllMaContext";
import { theme, styles } from "../style/StyleAllMA";
import DialogDeleteMultiple from "../components/DialogDeleteMultiple";
import PaginationMA from "../components/PaginationMA";
import ListCardHeaderItem from "../../Goal/Components/ListCardHeaderItem";

const useStyle = makeStyles(styles);

function ListStatusAllMADraft() {
  const classes = useStyle();
  const context = useContext(ContextAllMA);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  const [selected, setSelected] = useState([]);
  const [isOpenDialogDeleteMultiple, setOpenDialogDeleteMultiple] =
    useState(false);
  const [checkValidDelete, setCheckValidDelete] = useState([]);

  const handleGetValidMADelete = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`MeasuredActivity[${index}][id]`, item);

          return axiosConfig.delete(
            `${URL_API}/swd/measured-activity/batch?validateOnly=true`,
            { data: params }
          );
        })
    )
      .then((response) => {
        // // console.log("ORI Validate DEL", response);
        setCheckValidDelete(response);
        setOpenDialogDeleteMultiple(true);
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });
  };

  /*  
        ``````````````````
        HANDLE ALL MA DETAIL
        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    if (e.type === "click") {
      // console.log("Left click");
    } else if (e.type === "contextmenu") {
      // console.log("Right click");
    }

    console.log(data);
    // localStorage.setItem("allma_detail", JSON.stringify(data));
    // navigate(ToGoalDetail, false, {id: data.id})
  };

  /*
    ===================================================================
      HANDLE PAGINATION //*Will be to global state
    ===================================================================
  */
  const handleChangePage = (event, newPage) => {
    context.setPage(newPage);
    context.setLoaderForPaging(true);
    context.setOffset((parseInt(newPage + 1) - 1) * context.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    context.setLoaderForPaging(true);
    context.setRowsPerPage(parseInt(event.target.value, 10));
    context.setPage(0);
  };

  // Length Of Collection Goal
  const itemCount = context.collectionMAList.length;

  /* =========================
            selected ID
            ============================= */
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /* =========================
            selected All List Item
            ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.collectionMAList.map((n) => n.id);
      setSelected(newSelecteds);
      // // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Fragment>
      <Grid container style={{ backgroundColor: "#fafafa" }}>
        <Grid item xs={12} md={12}>
          <Box position="fixed" width="100%" zIndex={10} pr={13}>
            {superior && selected.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                bgcolor="#fafafa"
                py={2}
                pl={1}
              >
                <Checkbox
                  size="small"
                  indeterminate={
                    selected.length > 0 && selected.length < itemCount
                  }
                  checked={itemCount > 0 && selected.length === itemCount}
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all desserts" }}
                  checkedIcon={
                    <CheckBoxRoundedIcon
                      style={{ color: "#0F68D2", fontSize: 18 }}
                    />
                  }
                  icon={
                    <CheckBoxOutlineBlankRoundedIcon
                      style={{ color: "#0F68D2", fontSize: 18 }}
                    />
                  }
                />
                <Typography variant="subtitle2" className={classes.title}>
                  <b>{selected.length}</b> Measured Activity ditandai
                </Typography>
                {selected.length > 0 && (
                  <Fragment>
                    <Button
                      onClick={handleGetValidMADelete}
                      disableElevation
                      variant="contained"
                      className={classes.button5}
                      startIcon={<DeleteRoundedIcon />}
                      style={{ marginLeft: 8 }}
                    >
                      <b>Delete</b>
                    </Button>
                  </Fragment>
                )}
              </Box>
            )}
            {context.collectionMAList.length > 0 && (
              <ListCardHeaderItem
                classes={classes}
                origin="Measured Activity"
              />
            )}
          </Box>

          {context.loader ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={15}
            >
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "#d1354a" }}
              />
              <Typography className={classes.title555} variant="subtitle1">
                Loading...
              </Typography>
            </Box>
          ) : (
            <Table
              className={classes.table}
              style={{ marginTop: selected.length > 0 ? 90 : 30 }}
              aria-label="sticky table"
            >
              <Box my={3} />
              <TableBody>
                {context.collectionMAList.length > 0 &&
                  context.collectionMAList.map((item, i) => {
                    return (
                      <ListCardItemMA
                        key={i}
                        classes={classes}
                        item={item}
                        selected={selected}
                        setSelected={setSelected}
                        handleDetail={handleDetail}
                        userTokenState={context.userTokenStateGlobal}
                        isSelected={isSelected}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </Grid>

        {context.collectionMAList.length === 0 && context.loader !== true && (
          <Box width="100%">
            <Paper elevation={1} className={classes.paperList}>
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                <PictEmptyStateMA />
                <b>
                  Tidak ada Measure Activity yang dapat di tampilkan saat ini ;(
                </b>
              </Typography>
            </Paper>
          </Box>
        )}

        {context.collectionMAList.length > 0 &&
          context.dataTabActive.id !== 2 && (
            <PaginationMA
              theme={theme}
              classes={classes}
              loader={context.loader}
              collectionMALength={context.collectionMALength}
              page={context.page}
              rowsPerPage={context.rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
      </Grid>

      <DialogDeleteMultiple
        classes={classes}
        isOpenDialogDeleteMultiple={isOpenDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
        selected={selected}
        checkValidDelete={checkValidDelete}
      />
    </Fragment>
  );
}

export default ListStatusAllMADraft;
