import React, { useState, useContext } from "react";
import { Box, FormControl, Select, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ContextGoal from "../Context/ContextGoal";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const PaginationGoalv2 = ({ classes }) => {
  const context = useContext(ContextGoal);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handlePagination = (e, value) => {
    setPage(value);
    const limitTask = context.limitGoalCount;
    const offsetTask = (value - 1) * context.limitGoalCount;

    context.setLimitGoalCount(limitTask);
    context.setOffsetGoalCount(offsetTask);
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handleSelect = (e) => {
    setPerPage(e.target.value);

    // // console.log("VAL SEL", e.target.value);

    context.setLimitGoalCount(e.target.value);
    context.setOffsetGoalCount(0);
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  // // console.log("PERPAGE", perPage);

  return (
    <Box
      my={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography className={classes.title888} variant="caption">
          {MyLang() ? bahasa.totalRecords : english.totalRecords}{" "}
          <span
            style={{
              backgroundColor: "#aaa",
              color: "#fff",
              padding: "0 5px",
              borderRadius: 4,
            }}
          >
            <b>{context.lengthGoalCount}</b>
          </span>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography className={classes.title888} variant="caption">
          Goal {MyLang() ? bahasa.perPage : english.perPage}
        </Typography>
        <FormControl size="small" style={{ margin: "0 10px", minWidth: 50 }}>
          <Select native value={perPage} onChange={handleSelect}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
        </FormControl>

        <Pagination
          variant="text"
          shape="rounded"
          page={page}
          count={Math.ceil(context.lengthGoalCount / context.limitGoalCount)}
          onChange={handlePagination}
        />
      </Box>
    </Box>
  );
};

export default PaginationGoalv2;
