import React, { useState, useContext } from "react";
import ContextProject from "../../context/ContextProject";

import ListTableTask from "./ListTableTask";
import RowTaskGroup from "./RowTaskGroup";

const ListTableGroups = ({ classes, row, project }) => {
  const { projectTState } = useContext(ContextProject);
  const [open, setOpen] = useState(true);

  const handleCollapse = () => {
    setOpen((prev) => !prev);
  };

  // // console.log("ROWZ", row);

  return (
    <>
      {!projectTState.hideTaskGroup && (
        <RowTaskGroup
          classes={classes}
          row={row}
          onClose={handleCollapse}
          open={open}
          project={project}
        />
      )}
      {open &&
        row.todolists.length > 0 &&
        row.todolists.map((task, i) => {
          return (
            <ListTableTask
              key={i}
              classes={classes}
              row={task}
              open={open}
              project={project}
              taskGroupName={row?.name}
            />
          );
        })}
    </>
  );
};

export default ListTableGroups;
