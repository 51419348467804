import React, { useContext, useState } from "react";
import DialogCreate from "./DialogCreate";
import ContextTimeOffSetting from "../../Context/ContextTimeOffSetting";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../../components/StyledMenuDropdown";
import DialogDelete from "./DialogDelete";
import DialogEdit from "./DialogEdit";
import DialogDetail from "./DialogDetail";
import { navigate } from "hookrouter";
import { ToTimeOffCategory } from "../../../../../constants/config-redirect-url";
import DialogCreateDefault from "./DialogCreateDefault";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
function ViewTabCategory({
  classes,
  isModalProgress,
  setModalProgress,
  dataCategoryList,
  setDataCategoryList,
  getCategoryList,
}) {
  const context = useContext(ContextTimeOffSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterCategory, setMasterCategory] = useState({
    fields: {},
    masterTypeCollections: [
      {
        id: "izin",
        name: "Izin",
      },
      {
        id: "cuti",
        name: "Cuti",
      },
      {
        id: "sakit",
        name: "Sakit",
      },
    ],
    masterEvidenceCollections: [
      {
        id: "mandatory",
        name: "Mandatory",
      },
      {
        id: "optional",
        name: "Optional",
      },
    ],
    masterDeductionTypeCollections: [
      {
        id: "leave",
        name: "Jatah Cuti",
      },
      {
        id: "salary",
        name: "Gaji",
      },
    ],
    masterIntervalUnitCollections: [
      {
        id: "year",
        name: "Tahunan",
      },
      {
        id: "month",
        name: "Bulanan",
      },
    ],
    masterStartDateCollections: [
      {
        id: "joining_date",
        name: "Tanggal Bergabung",
      },
      {
        id: "custom",
        name: "Custom",
      },
    ],
    masterFilterGenderCollections: [
      {
        id: "1",
        name: "Laki-Laki",
      },
      {
        id: "2",
        name: "Perempuan",
      },
    ],
    masterDateCollections: [
      { id: "01", name: "01" },
      { id: "02", name: "02" },
      { id: "03", name: "03" },
      { id: "04", name: "04" },
      { id: "05", name: "05" },
      { id: "06", name: "06" },
      { id: "07", name: "07" },
      { id: "08", name: "08" },
      { id: "09", name: "09" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
    masterMonthCollections: [
      {
        id: "01",
        name: "Januari",
      },
      {
        id: "02",
        name: "Februari",
      },
      {
        id: "03",
        name: "Maret",
      },
      {
        id: "04",
        name: "April",
      },
      {
        id: "05",
        name: "Mei",
      },
      {
        id: "06",
        name: "Juni",
      },
      {
        id: "07",
        name: "Juli",
      },
      {
        id: "08",
        name: "Agustus",
      },
      {
        id: "09",
        name: "September",
      },
      {
        id: "10",
        name: "Oktober",
      },
      {
        id: "11",
        name: "November",
      },
      {
        id: "12",
        name: "Desember",
      },
    ],
  });

  const [dataCategory, setDataCategory] = useState({
    id: "",
    type: "",
    type_obj: "",
    name: "",
    interval_unit: "", //year,month
    interval_unit_obj: {}, //year,month
    cycle_duration: 0,
    start_date: "",
    start_after: "",
    remarks: "",
    evidence: "mandatory",
    evidence_obj: null,
    deduction_type: "leave",
    deduction_type_obj: null,
    amount_of_deduction: "",
    allowance: 1,
    leave_setting_id: null,
    status: 1,
    prior_approval: 1,
    assign_to_all_employee: 0,
    start_date_input_date: masterCategory.masterDateCollections[0],
    start_date_input_month: masterCategory.masterMonthCollections[0],
    start_date_input_type: masterCategory.masterStartDateCollections[0],
    carry_over_setting: 1,
    total_carry_over: 1,
    hasUser: [],
  });

  const resetDataCategory = () => {
    let dataCategoryTemporary = dataCategory;
    dataCategoryTemporary.id = "";
    dataCategoryTemporary.type = "";
    dataCategoryTemporary.type_obj = "";
    dataCategoryTemporary.name = "";
    dataCategoryTemporary.leave_setting_id = null;
    dataCategoryTemporary.interval_unit = "";
    dataCategoryTemporary.interval_unit_obj = {};
    dataCategoryTemporary.cycle_duration = 0;
    dataCategoryTemporary.start_date = "";
    dataCategoryTemporary.start_after = "";
    dataCategoryTemporary.remarks = "";
    dataCategoryTemporary.evidence = "mandatory";
    dataCategoryTemporary.evidence_obj = null;
    dataCategoryTemporary.deduction_type = "leave";
    dataCategoryTemporary.deduction_type_obj = null;
    dataCategoryTemporary.amount_of_deduction = "";
    dataCategoryTemporary.allowance = 1;
    dataCategoryTemporary.status = 1;
    dataCategoryTemporary.prior_approval = 1;
    dataCategoryTemporary.assign_to_all_employee = 0;
    dataCategoryTemporary.start_date_input_date =
      masterCategory.masterDateCollections[0];
    dataCategoryTemporary.start_date_input_month =
      masterCategory.masterMonthCollections[0];
    dataCategoryTemporary.start_date_input_type =
      masterCategory.masterStartDateCollections[0];
    dataCategoryTemporary.carry_over_setting = 1;
    dataCategoryTemporary.total_carry_over = 1;
    dataCategoryTemporary.hasUser = [];
    setDataCategory({ ...dataCategoryTemporary });
  };

  const handleShowModalCreate = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/timeoff-category/create`)
      .then(function (response) {
        resetDataCategory();
        // console.log("Response Original : ", response.data);
        let masterData = masterCategory;
        masterData.fields = response.data.data.fields;
        setMasterCategory(masterData);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const createCategory = () => {
    context.setModalProgress(true);
    setLoader(true);
    let dataCategoryTemp = dataCategory;
    if (
      dataCategoryTemp.start_date_input_type &&
      dataCategoryTemp.start_date_input_type.id === "custom"
    ) {
      dataCategoryTemp.start_date =
        dataCategoryTemp.start_date_input_date.id +
        "/" +
        dataCategoryTemp.start_date_input_month.id;
    } else {
      dataCategoryTemp.start_date = "joining_date";
    }
    let data = {
      LeaveCategory: dataCategory,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/timeoff-category/create`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(false);
        context.setMessages("Data kategori time off berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetDataCategory();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const createCategoryDefault = () => {
    context.setModalProgress(true);
    setLoader(true);
    let dataCategoryTemp = dataCategory;
    if (
      dataCategoryTemp.start_date_input_type &&
      dataCategoryTemp.start_date_input_type.id === "custom"
    ) {
      dataCategoryTemp.start_date =
        dataCategoryTemp.start_date_input_date.id +
        "/" +
        dataCategoryTemp.start_date_input_month.id;
    } else {
      dataCategoryTemp.start_date = "joining_date";
    }
    dataCategory.type = masterCategory.masterTypeCollections[1].id;
    dataCategory.type_obj = masterCategory.masterTypeCollections[1];
    dataCategory.name = "Cuti Tahunan";

    let data = {
      LeaveCategory: dataCategory,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/timeoff-category/create-default`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreateDefault(false);
        context.setMessages("Data kategori time off berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetDataCategory();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataCategoryList.options.paging.limit * newPage,
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 0, parseInt(event.target.value));
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDelete = (e, item) => {
    handleDropdownClose();
    let dataCategoryTemporary = dataCategory;
    dataCategoryTemporary.id = item.id;
    dataCategoryTemporary.name = item.name;
    setDataCategory({ ...dataCategoryTemporary });
    context.setModalDelete(true);
  };

  const handleDelete = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/human-resource/timeoff-category/${dataCategory.id}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalDelete(false);
          context.setMessages("Berhasil di hapus !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, 0, 0, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleShowModalEdit = (e, item) => {
    handleDropdownClose();
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/timeoff-category/${item.id}/update`)
      .then(function (response) {
        resetDataCategory();
        // console.log("Response Original : ", response.data);
        let masterData = masterCategory;
        masterData.fields = response.data.data.fields;
        setMasterCategory(masterData);
        getCategory(item);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleShowModalUser = async (e, item) => {
    context.setModalProgress(true);
    setLoader(true);
    context.setModalDetail(true);
    let dataTemp = dataCategory;
    dataTemp.id = item.id;
    dataTemp.name = item.name;
    setDataCategory({ ...dataTemp });
    await refreshListUnregisteredUser(null, 0, 0, null);
    await refreshListRegisteredUser(null, 0, 0, null);
    context.setModalProgress(false);
    setLoader(false);
  };

  const getCategory = (item) => {
    axiosConfig
      .get(`${URL_API}/human-resource/timeoff-category/${item.id}`)
      .then(function (response) {
        resetDataCategory();
        // console.log("Response Original : ", response.data);
        let dataCategoryTemporary = dataCategory;
        dataCategoryTemporary.id = response.data.data.id;
        dataCategoryTemporary.type = response.data.data.type;
        if (dataCategoryTemporary.type) {
          masterCategory.masterTypeCollections.map((item) => {
            if (item.id === dataCategoryTemporary.type) {
              dataCategoryTemporary.type_obj = item;
            }
          });
        }
        dataCategoryTemporary.name = response.data.data.name;
        dataCategoryTemporary.interval_unit = response.data.data.interval_unit;
        if (dataCategoryTemporary.type) {
          masterCategory.masterIntervalUnitCollections.map((item) => {
            if (item.id === dataCategoryTemporary.interval_unit) {
              dataCategoryTemporary.interval_unit_obj = item;
            }
          });
        }
        dataCategoryTemporary.cycle_duration =
          response.data.data.cycle_duration;
        if (response.data.data.start_date === "joining_date") {
          dataCategoryTemporary.start_date = response.data.data.start_date;
          dataCategoryTemporary.start_date_input_type =
            masterCategory.masterStartDateCollections[0];
          dataCategoryTemporary.start_date_input_date =
            masterCategory.masterDateCollections[0];
          dataCategoryTemporary.start_date_input_month =
            masterCategory.masterMonthCollections[0];
        } else {
          dataCategoryTemporary.start_date = response.data.data.start_date;
          dataCategoryTemporary.start_date_input_type =
            masterCategory.masterStartDateCollections[1];
          let startDate = response.data.data.start_date.split("/");
          dataCategoryTemporary.start_date_input_date =
            masterCategory.masterDateCollections[1];
          if (startDate[0]) {
            masterCategory.masterDateCollections.map((item) => {
              if (item.id === startDate[0]) {
                dataCategoryTemporary.start_date_input_date = item;
              }
            });
          }
          dataCategoryTemporary.start_date_input_month =
            masterCategory.masterMonthCollections[0];
          if (startDate[1]) {
            masterCategory.masterMonthCollections.map((item) => {
              if (item.id === startDate[1]) {
                dataCategoryTemporary.start_date_input_month = item;
              }
            });
          }
        }
        dataCategoryTemporary.start_after = response.data.data.start_after;
        dataCategoryTemporary.remarks = response.data.data.remarks;
        dataCategoryTemporary.evidence = response.data.data.evidence;
        if (dataCategoryTemporary.evidence) {
          masterCategory.masterEvidenceCollections.map((item) => {
            if (item.id === dataCategoryTemporary.evidence) {
              dataCategoryTemporary.evidence_obj = item;
            }
          });
        }
        dataCategoryTemporary.deduction_type =
          response.data.data.deduction_type;
        if (dataCategoryTemporary.deduction_type) {
          masterCategory.masterDeductionTypeCollections.map((item) => {
            if (item.id === dataCategoryTemporary.deduction_type) {
              dataCategoryTemporary.deduction_type_obj = item;
            }
          });
        }
        dataCategoryTemporary.amount_of_deduction =
          response.data.data.amount_of_deduction;
        dataCategoryTemporary.allowance = response.data.data.allowance;
        dataCategoryTemporary.leave_setting_id =
          response.data.data.leave_setting_id;
        dataCategoryTemporary.status = response.data.data.status;
        dataCategoryTemporary.prior_approval =
          response.data.data.prior_approval;
        dataCategoryTemporary.assign_to_all_employee =
          response.data.data.assign_to_all_employee;
        dataCategoryTemporary.carry_over_setting = response.data.data
          .leaveSetting
          ? response.data.data.leaveSetting.status
          : 0;
        dataCategoryTemporary.total_carry_over = response.data.data.leaveSetting
          ? response.data.data.leaveSetting.carry_over
          : 0;
        setDataCategory({ ...dataCategoryTemporary });
        context.setModalEdit(true);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalEdit(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleUpdate = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      let dataCategoryTemp = dataCategory;
      if (
        dataCategoryTemp.start_date_input_type &&
        dataCategoryTemp.start_date_input_type.id === "custom"
      ) {
        dataCategoryTemp.start_date =
          dataCategoryTemp.start_date_input_date.id +
          "/" +
          dataCategoryTemp.start_date_input_month.id;
      } else {
        dataCategoryTemp.start_date = "joining_date";
      }
      let data = {
        LeaveCategory: dataCategory,
      };
      axiosConfig
        .put(
          URL_API + `/human-resource/timeoff-category/${dataCategory.id}`,
          data
        )
        .then(function (response) {
          // console.log("Response Original : ", response);
          handleUpdateSetting();
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleUpdateSetting = () => {
    let data = {
      LeaveSetting: {
        carry_over_setting: dataCategory.carry_over_setting,
        total_carry_over: dataCategory.total_carry_over,
      },
    };
    axiosConfig
      .put(URL_API + `/human-resource/timeoff-setting/${dataCategory.id}`, data)
      .then(function (response) {
        setLoader(false);
        // console.log("Response Original : ", response);
        context.setModalEdit(false);
        context.setMessages("Berhasil di edit !");
        context.setSnackbarResponse200(true);
        context.setModalProgress(false);
        refreshList(null, null, null, null);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("Error : ", error.response);
        context.setModalProgress(false);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const refreshList = (search, offset, page, limit) => {
    let dataCategoryListTemporary = dataCategoryList;
    dataCategoryListTemporary.options.filter.search =
      search !== null
        ? search
        : dataCategoryListTemporary.options.filter.search;
    dataCategoryListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataCategoryListTemporary.options.paging.offset;
    dataCategoryListTemporary.options.paging.page =
      page !== null ? page : dataCategoryListTemporary.options.paging.page;
    dataCategoryListTemporary.options.paging.limit =
      limit !== null ? limit : dataCategoryListTemporary.options.paging.limit;
    setDataCategoryList({ ...dataCategoryListTemporary });
    getCategoryList();
  };

  // Data Registered User
  const [dataRegisteredUser, setDataRegisteredUser] = useState({
    options: {
      filter: {
        search: "",
        sex_id: "",
        sex_obj: null,
        age: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
    users: [],
  });
  const getRegisteredUser = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/timeoff-category/${dataCategory.id}/list-assigned-user` +
          `?options[filter][search]=${dataRegisteredUser.options.filter.search}` +
          `&options[filter][sex_id]=${dataRegisteredUser.options.filter.sex_id}` +
          `&options[filter][age]=${dataRegisteredUser.options.filter.age}` +
          `&options[paging][limit]=${dataRegisteredUser.options.paging.limit}` +
          `&options[paging][offset]=${dataRegisteredUser.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataRegisteredUserTemp = dataRegisteredUser;
        dataRegisteredUserTemp.list = response.data.data;
        dataRegisteredUserTemp.options.paging.count = response.data.info.count;
        setDataRegisteredUser({ ...dataRegisteredUserTemp });
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };
  const refreshListRegisteredUser = (search, offset, page, limit) => {
    let dataTemp = dataRegisteredUser;
    dataTemp.options.filter.search =
      search !== null ? search : dataTemp.options.filter.search;
    dataTemp.options.paging.offset =
      offset !== null ? offset : dataTemp.options.paging.offset;
    dataTemp.options.paging.page =
      page !== null ? page : dataTemp.options.paging.page;
    dataTemp.options.paging.limit =
      limit !== null ? limit : dataTemp.options.paging.limit;
    setDataRegisteredUser({ ...dataTemp });
    getRegisteredUser();
  };

  // Data Unregistered User
  const [dataUnregisteredUser, setDataUnregisteredUser] = useState({
    options: {
      filter: {
        search: "",
        sex_id: "",
        sex_obj: null,
        age: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
    users: [],
  });
  const getUnregisteredUser = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/timeoff-category/${dataCategory.id}/list-unsigned-user` +
          `?options[filter][search]=${dataUnregisteredUser.options.filter.search}` +
          `&options[filter][sex_id]=${dataUnregisteredUser.options.filter.sex_id}` +
          `&options[filter][age]=${dataUnregisteredUser.options.filter.age}` +
          `&options[paging][limit]=${dataUnregisteredUser.options.paging.limit}` +
          `&options[paging][offset]=${dataUnregisteredUser.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataUnregisteredUserTemp = dataUnregisteredUser;
        dataUnregisteredUserTemp.list = response.data.data;
        dataUnregisteredUserTemp.options.paging.count =
          response.data.info.count;
        setDataUnregisteredUser({ ...dataUnregisteredUserTemp });
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };
  const refreshListUnregisteredUser = (search, offset, page, limit) => {
    let dataTemp = dataUnregisteredUser;
    dataTemp.options.filter.search =
      search !== null ? search : dataTemp.options.filter.search;
    dataTemp.options.paging.offset =
      offset !== null ? offset : dataTemp.options.paging.offset;
    dataTemp.options.paging.page =
      page !== null ? page : dataTemp.options.paging.page;
    dataTemp.options.paging.limit =
      limit !== null ? limit : dataTemp.options.paging.limit;
    setDataUnregisteredUser({ ...dataTemp });
    getUnregisteredUser();
  };

  const assignUser = (id) => {
    setLoader(true);
    context.setModalProgress(true);
    let data = {};
    if (id) {
      data = {
        LeaveCategory: {
          user_id: [id],
        },
      };
    } else {
      data = {
        LeaveCategory: {
          user_id: dataUnregisteredUser.users,
        },
      };
    }
    axiosConfig
      .post(
        URL_API +
          `/human-resource/timeoff-category/${dataCategory.id}/assign-user`,
        data
      )
      .then(async (response) => {
        // console.log("Response Original : ", response);
        context.setMessages("User berhasil di assign !");
        context.setSnackbarResponse200(true);
        await refreshListUnregisteredUser(null, 0, 0, null);
        await refreshListRegisteredUser(null, 0, 0, null);
        let dataTemp = dataUnregisteredUser;
        dataTemp.users = [];
        setDataUnregisteredUser({ ...dataTemp });
        setLoader(false);
        context.setModalProgress(false);
        handleShowModalUser(null, dataCategory);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        context.setModalProgress(false);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const unsignUser = (id) => {
    setLoader(true);
    context.setModalProgress(true);
    let data = {};
    if (id) {
      data = {
        LeaveCategory: {
          user_id: [id],
        },
      };
    } else {
      data = {
        LeaveCategory: {
          user_id: dataRegisteredUser.users,
        },
      };
    }
    axiosConfig
      .delete(
        URL_API +
          `/human-resource/timeoff-category/${dataCategory.id}/unsign-user`,
        {
          data,
        }
      )
      .then(async (response) => {
        // console.log("Response Original : ", response);
        context.setMessages(
          "User berhasil di hapus dari kategori time off ini!"
        );
        context.setSnackbarResponse200(true);
        await refreshListUnregisteredUser(null, 0, 0, null);
        await refreshListRegisteredUser(null, 0, 0, null);
        let dataTemp = dataRegisteredUser;
        dataTemp.users = [];
        setDataRegisteredUser({ ...dataTemp });
        setLoader(false);
        context.setModalProgress(false);
        handleShowModalUser(null, dataCategory);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("Error : ", error.response);
        context.setModalProgress(false);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  return (
    <div>
      <Box>
        <Grid container spacing={3} style={{ marginBottom: "1em" }}>
          <Grid item md={6}>
            <Typography
              component={"span"}
              style={{ display: "inline-block", marginTop: "3px" }}
              className={classes.title333}
            >
              <b>Kategori Time Off</b>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                handleShowModalCreate();
              }}
            >
              Tambah Kategori
            </Button>
          </Grid>
        </Grid>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            classes={classes}
            numSelected={selected.length}
            dataCategoryList={dataCategoryList}
            setDataCategoryList={setDataCategoryList}
            getCategoryList={getCategoryList}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={dataCategoryList.list.length}
            />

            {dataCategoryList &&
              dataCategoryList.list &&
              dataCategoryList.list.length > 0 && (
                <TableBody>
                  {dataCategoryList.list.map((item, i) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                  inputProps={{ "aria-label": "select all desserts" }}
                                /> */}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {dataCategoryList.options.paging.page *
                              dataCategoryList.options.paging.limit +
                              i +
                              1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.type}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          {(item.type === "custom" ||
                            (item.type === "default" &&
                              item.name === "Cuti Tahunan")) && (
                            <IconButton
                              edge="end"
                              onClick={(e) => handleDropdownOpen(e, i)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          )}
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl && anchorIn === i)}
                            onClose={(e) => handleDropdownClose(e, i)}
                          >
                            {item.group_id && (
                              <StyledMenuItem
                                onClick={(e) => handleShowModalEdit(e, item)}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                    >
                                      <b>Edit</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                            )}

                            {(item.type === "custom" ||
                              (item.type === "default" &&
                                item.name === "Cuti Tahunan")) && (
                              <StyledMenuItem
                                onClick={(e) => handleShowModalUser(e, item)}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                    >
                                      <b>Assign User</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                            )}

                            {(item.type === "custom" ||
                              (item.type === "default" &&
                                item.name === "Cuti Tahunan")) && (
                              <StyledMenuItem
                                onClick={() =>
                                  navigate(ToTimeOffCategory + "/" + item.id)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                    >
                                      <b>Edit Allowance</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                            )}

                            {item.type === "custom" && (
                              <StyledMenuItem
                                onClick={(e) => handleShowModalDelete(e, item)}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.title}
                                    >
                                      <b>Hapus</b>
                                    </Typography>
                                  }
                                />
                              </StyledMenuItem>
                            )}
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

            {dataCategoryList &&
              dataCategoryList.list &&
              dataCategoryList.list.length <= 0 &&
              !isLoaderEmpty && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title}
                          style={{
                            color: "grey",
                            marginTop: 16,
                            marginBottom: 16,
                          }}
                        >
                          <b>Data kategori time off tidak tersedia</b>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  colSpan={6}
                  count={dataCategoryList.options.paging.count}
                  rowsPerPage={dataCategoryList.options.paging.limit}
                  page={dataCategoryList.options.paging.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  labelRowsPerPage="Baris setiap halaman"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterCategory={masterCategory}
        setMasterCategory={setMasterCategory}
        dataCategory={dataCategory}
        setDataCategory={setDataCategory}
        listError={context.listError}
        createCategory={createCategory}
      />
      <DialogCreateDefault
        classes={classes}
        isModalCreateDefault={context.isModalCreateDefault}
        setModalCreateDefault={context.setModalCreateDefault}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterCategory={masterCategory}
        setMasterCategory={setMasterCategory}
        dataCategory={dataCategory}
        setDataCategory={setDataCategory}
        listError={context.listError}
        createCategoryDefault={createCategoryDefault}
      />
      <DialogEdit
        classes={classes}
        isModalEdit={context.isModalEdit}
        setModalEdit={context.setModalEdit}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterCategory={masterCategory}
        setMasterCategory={setMasterCategory}
        dataCategory={dataCategory}
        setDataCategory={setDataCategory}
        listError={context.listError}
        handleUpdate={handleUpdate}
      />
      <DialogDelete
        classes={classes}
        isModalDelete={context.isModalDelete}
        setModalDelete={context.setModalDelete}
        dataCategory={dataCategory}
        setDataCategory={setDataCategory}
        handleDelete={handleDelete}
      />
      <DialogDetail
        classes={classes}
        isModalDetail={context.isModalDetail}
        setModalDetail={context.setModalDetail}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterCategory={masterCategory}
        dataCategory={dataCategory}
        dataRegisteredUser={dataRegisteredUser}
        setDataRegisteredUser={setDataRegisteredUser}
        refreshListRegisteredUser={refreshListRegisteredUser}
        dataUnregisteredUser={dataUnregisteredUser}
        setDataUnregisteredUser={setDataUnregisteredUser}
        refreshListUnregisteredUser={refreshListUnregisteredUser}
        assignUser={assignUser}
        unsignUser={unsignUser}
        setLoader={setLoader}
      />
    </div>
  );
}

export default ViewTabCategory;
