import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    color: "#888",
    textDecoration: "none",
  },
  cardField: {
    borderRadius: 15,
    border: "3px solid #F3F2F2",
    padding: "7px 30px",
    margin: "0px 0px 30px 0px",
    maxWidth: "80%",
  },
});

const useStyle = makeStyles(styles);

const TheySaid = () => {
  const classes = useStyle();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box pb={15}>
      <Container>
        <Box mb={10} display="flex" justifyContent="center">
          <Typography variant="h4" className={classes.title333}>
            <b>Kata mereka yang sudah menggunakan Performate</b>
          </Typography>
        </Box>

        <Box mt={5}>
          <Slider {...settings}>
            {listSlider.map((item, i) => (
              <Box key={i} pb={4} display="flex" justifyContent="center">
                <Box px={2}>{item.youtube}</Box>
                <Box px={2} mt={2}>
                  <Typography variant="h6" className={classes.title333}>
                    <b>{item.name}</b>
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title333}>
                    {item.position}
                  </Typography>
                  <Box my={0.8} />
                  <Typography variant="subtitle1" className={classes.title333}>
                    {item.company}
                  </Typography>
                  <Box my={2} />
                  <Typography variant="subtitle2" className={classes.title333}>
                    {item.comment}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default TheySaid;

const listSlider = [
  {
    name: "Iim Rusamsi",
    position: "Ketua Umum",
    company: "Oke Oce Indonesia",
    comment:
      "Dengan Performate, Saya dapat mengukur dan monitor kinerja dari tim, dan memasang target sehingga dapat focus terhadap pencapaian perusahaan",
    youtube: (
      <iframe
        width="100%"
        height="290"
        src="https://www.youtube.com/embed/HYFrmEbNfSA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    ),
  },
  {
    name: "Budiman Indrajaya",
    position: "CEO",
    company: "PT. URUN Bangun Negri",
    comment:
      "performate membantu saya dalam management project dan mengontrol secara terstruktur dan sistematis setiap hari apa yang sudah apa belum",
    youtube: (
      <iframe
        width="100%"
        height="290"
        src="https://www.youtube.com/embed/tybF7zYDtVY"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    ),
  },
  {
    name: "Deni Darmawan",
    position: "CEO",
    company: "Madinah Group Indonesia",
    comment:
      "Performate menghemat waktu saya 20% untuk me-report laporan sebagai Marketing Manager",
    youtube: (
      <iframe
        width="100%"
        height="290"
        src="https://www.youtube.com/embed/e5tEV0MfGIU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    ),
  },
  {
    name: "Mimin Sarimin",
    position: "Head Of IT",
    company: "Nako Coffee",
    comment:
      "Performate menghemat waktu saya 20% untuk me-report laporan sebagai Marketing Manager",
    youtube: (
      <iframe
        width="100%"
        height="290"
        src="https://www.youtube.com/embed/e5tEV0MfGIU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    ),
  },
  {
    name: "Uca Markuca",
    position: "Staff",
    company: "Nako Coffee",
    comment:
      "Performate menghemat waktu saya 20% untuk me-report laporan sebagai Marketing Manager",
    youtube: (
      <iframe
        width="100%"
        height="290"
        src="https://www.youtube.com/embed/e5tEV0MfGIU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    ),
  },
];
