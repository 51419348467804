import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogEdit = (props) => {
  const {
    classes,
    isModalEdit,
    setModalEdit,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    masterDataLocation,
    setMasterDataLocation,
    dataLocation,
    setDataLocation,
    listError,
    handleUpdate,
  } = props;

  const context = useContext(ContextAttendanceSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );
  const [cityOpen, setCityOpen] = React.useState(false);
  const [cityOptions, setCityOptions] = React.useState([]);
  const [postalCodeOpen, setPostalCodeOpen] = React.useState(false);
  const [postalCodeOptions, setPostalCodeOptions] = React.useState([]);

  const onChangeHandleLocation = async value => {
    axiosConfig
      .get(`${URL_API}/human-resource/location-static/city`, { params: { search: value } })
      .then(function (response) {
        setCityOptions(response.data.data);
      })
      .catch(function (error) {
        
      });
  };

  const onChangeHandlePostalCode = async value => {
    axiosConfig
      .get(`${URL_API}/human-resource/location-static/postal-code`, { params: { search: value, city_id: dataLocation.city_id } })
      .then(function (response) {
        setPostalCodeOptions(response.data.data);
      })
      .catch(function (error) {
        
      });
  };

  React.useEffect(() => {
    if (!cityOpen) {
      setCityOptions([]);
    }
    if (!postalCodeOpen) {
      setPostalCodeOpen([]);
    }
  }, [cityOpen, postalCodeOpen]);

  const checkError =  async value => {
    return false;
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
          disableTypography={true}
        >
          <Typography variant="h4">Edit Daftar Lokasi</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <Box p={1} my={1}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Tulis Nama Lokasi</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. Kantor, Gedung 1..."
                    value={dataLocation.location_name}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.location_name = event.target.value;
                      setDataLocation({...dataLocationTemporary});
                    }}
                    error={listError.some(item => 'location_name' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'location_name') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Tipe Lokasi</b>
                  </Typography>
                  <Box my={0.5} />
                  <Autocomplete
                    options={masterDataLocation.masterLocationStaticTypeCollections}
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name;
                      } else {
                        return "";
                      }
                    }}
                    value={dataLocation.location_static_type}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.location_static_type = newValue;
                      dataLocationTemporary.location_static_type_id = newValue ? newValue.id : '';
                      setDataLocation({...dataLocationTemporary});
                    }}
                    noOptionsText="Tidak ada pilihan"
                    popupIcon={<ArrowDropDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontSize: 13 }}
                        {...params}
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Pilih Tipe Lokasi"
                        error={listError.some(item => 'location_static_type_id' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'location_static_type') {
                            return item.description;
                          }
                        })}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Pilih Negara</b>
                  </Typography>
                  <Box my={0.5} />
                  <Autocomplete
                    options={masterDataLocation.masterCountryCollections}
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name;
                      } else {
                        return "";
                      }
                    }}
                    value={dataLocation.country}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.country = newValue;
                      dataLocationTemporary.country_id = newValue ? newValue.id : '';
                      setDataLocation({...dataLocationTemporary});
                    }}
                    noOptionsText="Tidak ada pilihan"
                    popupIcon={<ArrowDropDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontSize: 13 }}
                        {...params}
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Pilih Negara"
                        error={listError.some(item => 'country_id' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'country_id') {
                            return item.description;
                          }
                        })}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Pilih Kota</b>
                  </Typography>
                  <Box my={0.5} />
                  <Autocomplete
                    options={cityOptions}
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name;
                      } else {
                        return "";
                      }
                    }}
                    value={dataLocation.city}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.city = newValue;
                      dataLocationTemporary.city_id = newValue ? newValue.id : '';
                      dataLocationTemporary.postal_code = {};
                      dataLocationTemporary.postal_code_id = '';
                      setDataLocation({...dataLocationTemporary});
                    }}
                    noOptionsText="Tidak ada pilihan"
                    popupIcon={<ArrowDropDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontSize: 13 }}
                        {...params}
                        fullWidth
                        variant="outlined"
                        onChange={ev => {
                          // dont fire API if the user delete or not entered anything
                            onChangeHandleLocation(ev.target.value);
                        }}
                        size="small"
                        label="Pilih Kota"
                        error={listError.some(item => 'city_id' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'city_id') {
                            return item.description;
                          }
                        })}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Pilih Kode Pos</b>
                  </Typography>
                  <Box my={0.5} />
                  <Autocomplete
                    options={postalCodeOptions}
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name;
                      } else {
                        return "";
                      }
                    }}
                    value={dataLocation.postal_code}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.postal_code = newValue;
                      dataLocationTemporary.postal_code_id = newValue ? newValue.id : '';
                      setDataLocation({...dataLocationTemporary});
                    }}
                    noOptionsText="Tidak ada pilihan"
                    popupIcon={<ArrowDropDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontSize: 13 }}
                        {...params}
                        fullWidth
                        variant="outlined"
                        onChange={ev => {
                          // dont fire API if the user delete or not entered anything
                            onChangeHandlePostalCode(ev.target.value);
                        }}
                        size="small"
                        label="Pilih Kode Pos"
                        error={listError.some(item => 'postal_code_id' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'postal_code_id') {
                            return item.description;
                          }
                        })}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} style={{ textAlign: "left" }}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Tulis Garis Lintang</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. -6.228..."
                    value={dataLocation.location_coordinates_lat_min}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.location_coordinates_lat_min = event.target.value;
                      setDataLocation({...dataLocationTemporary});
                    }}
                    error={listError.some(item => 'location_coordinates_lat_min' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'location_coordinates_lat_min') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item md={6} xs={12} style={{ textAlign: "left" }}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Tulis Garis Bujur</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 106.93..."
                    value={dataLocation.location_coordinates_long_min}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.location_coordinates_long_min = event.target.value;
                      setDataLocation({...dataLocationTemporary});
                    }}
                    error={listError.some(item => 'location_coordinates_long_min' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'location_coordinates_long_min') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Tulis Radius</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={dataLocation.radius}
                    onChange={(event, newValue) => {
                      let dataLocationTemporary = dataLocation;
                      dataLocationTemporary.radius = event.target.value;
                      setDataLocation({...dataLocationTemporary});
                    }}
                    error={listError.some(item => 'radius' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'radius') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                  <div>
                    <Typography variant="subtitle2" className={classes.title333}>
                      <b>Tulis Alamat</b>
                    </Typography>
                    <Box my={0.5} />
                    <TextField
                      id="outlined-multiline-static-description"
                      rows="4"
                      className={classes.textField}
                      variant="outlined"
                      multiline
                      fullWidth
                      value={dataLocation.address}
                      onChange={(event, newValue) => {
                        let dataLocationTemporary = dataLocation;
                        dataLocationTemporary.address = event.target.value;
                        setDataLocation({...dataLocationTemporary});
                      }}
                      error={listError.some(item => 'address' === item.key)}
                      helperText={listError.map((item) => {
                        if (item.key === 'address') {
                          return item.description;
                        }
                      })}
                    />
                  </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setModalEdit(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant="outlined"
              className={classes.button1}
              onClick={handleUpdate}
            >
              Simpan !
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogEdit;