import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import PictResultValue from "../../../../assets/images/Group_2620.png";
// import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";

import {
  FormatDecimal,
  FormatThousand,
} from "../../../../utilities/FormatDecimal";

const TextOverDotLineChartSecond = (props) => {
  const { classes, goalDetailState } = props;

  return (
    <List>
      <ListItem>
        <ListItemIcon
          style={{
            marginLeft: 8,
          }}>
          <img src={PictResultValue} style={{ width: 40, height: 40 }} />
        </ListItemIcon>

        <ListItemText
          className={classes.listItemtext}
          primary={
            <Typography variant="subtitle2" className={classes.title}>
              <b>
                {FormatDecimal(
                  goalDetailState.calculatedValue.resultOriented.result_value
                )}
                &nbsp; (
                {FormatDecimal(
                  goalDetailState.calculatedValue.resultOriented
                    .result_value_achievement * 100
                )}
                %)
              </b>
              {/* {
                        goalDetailState.valueType.code == VALUE_TYPE_PERCENTAGE ? (

                        <b>
                            {numeral(goalDetailState.calculatedValue.result_value).format('0.00')}
                            &nbsp;
                            ({numeral(goalDetailState.calculatedValue.result_value_percent).format('0.00')}%)
                        </b>

                        ) : (
                            
                        <b>
                            {numeral(goalDetailState.calculatedValue.result_value).format('0,0')}
                            &nbsp;
                            ({numeral(goalDetailState.calculatedValue.result_value_percent).format('0,0')}%)
                        </b>
                        )
                } */}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}>
              Result Value
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
};

export default TextOverDotLineChartSecond;
