import React, { useState, useContext } from "react";
import { Box, FormControl, NativeSelect, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import ContextProject from "../../context/ContextProject";
import { SET_PAGINATE_TASK } from "../../context/types";

const PaginateTask = ({ classes }) => {
  const { projectTState, projectTDispatch, setTriggerTask } =
    useContext(ContextProject);

  const [page, setPage] = useState(1);

  const handlePagination = (e, value) => {
    setPage(value);
    const limitTask = projectTState.limitTask;
    const offsetTask = (value - 1) * projectTState.limitTask;

    // // console.log("linitz", limitTask);
    // // console.log("offstz", offsetTask);

    projectTDispatch({
      type: SET_PAGINATE_TASK,
      payload: { limitTask, offsetTask },
    });
    setTriggerTask(Math.floor(Math.random() * 100));
  };

  const handleSelect = (e) => {
    const valuePerPage = e.target.value;

    projectTDispatch({
      type: SET_PAGINATE_TASK,
      payload: { limitTask: valuePerPage, offsetTask: 0 },
    });
    setTriggerTask(Math.floor(Math.random() * 100));
  };

  return (
    <Box
      mt={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography className={classes.title888} variant="caption">
          Total Records{" "}
          <span
            style={{
              backgroundColor: "#aaa",
              color: "#fff",
              padding: "0 5px",
              borderRadius: 4,
            }}
          >
            <b>{projectTState.countTask}</b>
          </span>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography className={classes.title888} variant="caption">
          Records Per Page
        </Typography>
        <FormControl size="small" style={{ margin: "0 10px", minWidth: 50 }}>
          <NativeSelect
            defaultValue={50}
            onChange={handleSelect}
            inputProps={{
              name: "name",
              id: "uncontrolled-native",
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </NativeSelect>
        </FormControl>

        <Pagination
          variant="text"
          shape="rounded"
          page={page}
          count={Math.ceil(projectTState.countTask / projectTState.limitTask)}
          onChange={handlePagination}
        />
      </Box>
    </Box>
  );
};

export default PaginateTask;
