import React, { useContext } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../../../Style/StyleGrouping";
import ContextAttendanceReportAdmin from "../../../Context/ContextAttendanceReportAdmin";
import UnitFilter from "./Unit/UnitFilter";
import UserFilter from "./User/UserFilter";
import OrderBy from "./OrderBy/OrderBy";
import ScheduleFilter from "./Schedule/ScheduleFilter";

const useStyles = makeStyles(styles);

const DetailedType = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceReportAdmin);

  return (
    <Box display="flex">
        <Box mr={1.5}>
            <UserFilter classes={classes} />
        </Box>
        <Box mr={1.5}>
            <UnitFilter classes={classes} />
        </Box>
        <Box mr={1.5}>
            <ScheduleFilter classes={classes} />
        </Box>

        <Box ml={5}>
            <OrderBy classes={classes} />
        </Box>

        {context.loader && (
            <Box ml={5} display="flex" alignItems="center">
                <CircularProgress
                    size={16}
                    style={{ color: "#d64253", marginRight: 5 }}
                />
                <Typography variant="subtitle2" className={classes.title888}>
                    Initial data loading...
                </Typography>
            </Box>
        )}
    </Box>
  );
};

export default DetailedType;
