import React from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircleIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    color: "#888",
    textDecoration: "none",
  },
  titlePrimary: {
    ...title,
    color: "#d64253",
    textDecoration: "none",
  },
  btnBuy: {
    zIndex: "20",
    color: "#555",
    padding: "10px 20px",
    border: "1.5px solid #555",
    fontWeight: "600",
    fontSize: "12px",
    textTransform: "capitalize",
    borderRadius: "5px",
    lineHeight: "15px",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      background: "rgba(0, 0, 0, 0.1)",
    },
  },
  btnBuy2: {
    zIndex: "20",
    color: "#d64253",
    padding: "10px 20px",
    border: "1.5px solid #d64253",
    fontWeight: "600",
    fontSize: "12px",
    textTransform: "capitalize",
    borderRadius: "5px",
    lineHeight: "15px",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      background: "rgba(0, 0, 0, 0.1)",
    },
  },
});

const useStyles = makeStyles(styles);

const ListStartUp = () => {
  const classes = useStyles();

  const OptionPrice = ({ name, price, benefits }) => (
    <Box display="flex" flexDirection="column" maxWidth={200}>
      <Typography variant="subtitile2" className={classes.title555}>
        {name}
      </Typography>
      <Typography
        variant="h5"
        className={name === "FREE" ? classes.titlePrimary : classes.title333}>
        {price}
      </Typography>
      <Box mt={1}>
        {benefits.map((benefit, i) => (
          <Box key={i} display="flex" alignItems="center">
            <CircleIcon
              style={{ color: "#888", fontSize: 11, marginRight: 5 }}
            />
            <Typography variant="caption" className={classes.title888}>
              {benefit}
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        size="small"
        style={{ marginTop: 15 }}
        className={name === "FREE" ? classes.btnBuy2 : classes.btnBuy}>
        Buy
      </Button>
    </Box>
  );

  return (
    <Box py={15}>
      <Container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell>
                  <OptionPrice
                    name="FREE"
                    price="Rp. 0"
                    benefits={[
                      "Timespan 6 Months",
                      "User Maximum 10",
                      "CS SUpport 24 Hours",
                    ]}
                  />
                </TableCell>
                <TableCell>
                  <OptionPrice
                    name="PERSONAL"
                    price="Rp. 36.000/User"
                    benefits={[
                      "Timespan monthly",
                      "User Unlimited",
                      "CS SUpport 24 Hours",
                    ]}
                  />
                </TableCell>
              </TableRow>
              <TableRow style={{ borderBottom: "2px solid #333" }}>
                <TableCell align="left">
                  <Typography variant="h6" className={classes.title333}>
                    <b>Fitur</b>
                  </Typography>
                </TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {startUp.map((item, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{item.name}</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="center">{item.free}</TableCell>
                  <TableCell align="center">{item.personal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default ListStartUp;

const startUp = [
  {
    name: "TimeSpan",
    free: "6 Months",
    personal: "Monthly",
  },
  {
    name: "Jumlah User",
    free: "Max 10",
    personal: "Unlimited",
  },
  {
    name: "CS Support",
    free: "24 Hours",
    personal: "24 Hours",
  },
  {
    name: "Structure Organizations",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Staffs Database",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Goal",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Measured Activities",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Project",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Direct Link",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Weighting",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Reporting",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Archiving",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "Drive",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
  {
    name: "To Do List",
    free: <CheckCircleIcon style={{ color: "#d64253" }} />,
    personal: <CheckCircleIcon style={{ color: "#d64253" }} />,
  },
];
