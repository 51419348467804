import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ClickAwayListener,
  Divider,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import classNames from "classnames";

import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";
import ContextAllMA from "../context/AllMaContext";

import filterSvg from "../../../../assets/images/SVG/Filter.svg";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
import AnchorFilterWeight from "./AnchorFilterWeight";
import AnchorFilterGoal from "./AnchorFilterGoal";

export const AnchorFilterMA = ({
  classes,
  weightValue,
  onWeightValue,
  goalId,
  onGoalId,
}) => {
  const context = useContext(ContextAllMA);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loadGoal, setLoadGoal] = useState(null);

  const [goalCollections, setGoalCollections] = useState([]);
  const [goalValue, setGoalValue] = useState("");

  const [openWeight, setOpenWeight] = useState(false);
  const [openGoal, setOpenGoal] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (anchorEl !== null) {
      setLoadGoal(true);
      const delayDebounceFn = setTimeout(() => {
        AxiosConfig.get(
          `${URL_API}/swd/goal?options[paging][offset]=0&options[paging][limit]=10&options[filter][search]=${goalValue}`
        )
          .then((res) => {
            if (res.status === 200) {
              const result = res.data.data;

              setGoalCollections(result);
              setLoadGoal(false);
              // handleClose();
            }
          })
          .catch((error) => {
            // console.log("Error : ", error);
            setLoadGoal(false);

            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);
          });
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [goalValue]);

  const ExpandTrue = () => (
    <ExpandMore style={{ color: "#8F8F8F" }} fontSize="small" />
  );
  const ExpandFalse = ({ color }) => (
    <ExpandLess style={{ color }} fontSize="small" />
  );

  const handleWeight = () => setOpenWeight((s) => !s);
  const handleGoal = () => setOpenGoal((s) => !s);

  const handleShow = (event) => {
    if (anchorEl && anchorEl?.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl?.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  // const handleShowResult = () => {
  //   context.setLoader(true);
  //   setLoading(true);

  //   let URL = "";
  //   const targetURL = `${URL_API}/swd/measured-activity?options[filter][search]=${
  //     context.valueSearch
  //   }&options[filter][status_id]=${
  //     context.statusAllMAIdAll
  //   }&options[paging][offset]=${
  //     context.offset === -10 ? 0 : context.offset
  //   }&options[paging][limit]=${context.rowsPerPage}&options[filter][goal_id]=${
  //     goalId.id
  //   }`;
  //   const minWeight = `&options[filter][weighting][from]=${weightValue[0]}`;
  //   const maxWeight = `&options[filter][weighting][to]=${weightValue[1]}`;

  //   if (weightValue[0] === 0 && weightValue[1] === 100) {
  //     URL = targetURL;
  //   } else {
  //     URL = `${targetURL}${minWeight}${maxWeight}`;
  //   }

  //   AxiosConfig.get(URL)
  //     .then((response) => {
  //       // console.log("Res COLLECTION MA weight : ", response);

  //       if (response.status === 200) {
  //         if (response.data.data !== null) {
  //           context.setCollectionMAList(response.data.data);
  //           context.setCollectionMALength(response.data.info.count);

  //           context.setWeightValueAll({
  //             min: weightValue[0],
  //             max: weightValue[1],
  //           });

  //           handleClose();
  //         }
  //       }
  //       context.setLoader(false);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       context.setLoader(false);
  //       // console.log("Error : ", error);
  //       setLoading(false);

  //       const { listError, dialogErr, resStatus, errorMessage } =
  //         handleError(error);
  //       // Error State
  //       setOpenDialogError(dialogErr);
  //       setTextErrorMessage(errorMessage);
  //       setListError(listError);
  //       setErrorStatus(resStatus);
  //     });
  // };

  return (
    <Box>
      <Button
        size="small"
        variant="outlined"
        className={classes.btnFilter}
        onClick={handleShow}
      >
        <img width={20} src={filterSvg} />
        <span style={{ marginLeft: 8 }}>Filter</span>
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow in={Boolean(anchorEl)} id="menu-list">
            <Paper className={classes.dropdown} style={{ zIndex: 30 }}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <div>
                  <List dense>
                    <ListItem
                      button
                      onClick={handleWeight}
                      style={{
                        backgroundColor:
                          openWeight && "rgba(251, 234, 234, 0.5)",
                        color: openWeight && "#C8203C",
                      }}
                    >
                      <ListItemText primary="Weight" />
                      {openWeight ? (
                        <ExpandFalse color="#C8203C" />
                      ) : (
                        <ExpandTrue />
                      )}
                    </ListItem>
                    <Collapse in={openWeight} timeout="auto" unmountOnExit>
                      <Box px={3} py={2}>
                        <AnchorFilterWeight
                          classes={classes}
                          weightValue={weightValue}
                          onWeightValue={onWeightValue}
                        />
                      </Box>
                    </Collapse>

                    <ListItem
                      button
                      onClick={handleGoal}
                      style={{
                        backgroundColor: openGoal && "rgba(251, 234, 234, 0.5)",
                        color: openGoal && "#C8203C",
                      }}
                    >
                      <ListItemText primary="Goal" />
                      {openGoal ? (
                        <ExpandFalse color="#C8203C" />
                      ) : (
                        <ExpandTrue />
                      )}
                    </ListItem>
                    <Collapse in={openGoal} timeout="auto" unmountOnExit>
                      <Box px={3} py={2}>
                        <AnchorFilterGoal
                          classes={classes}
                          goalValue={goalValue}
                          onGoalValue={setGoalValue}
                          onGoalId={onGoalId}
                          goalCollections={goalCollections}
                          loadGoal={loadGoal}
                          onClose={handleClose}
                        />
                      </Box>
                    </Collapse>
                  </List>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};
