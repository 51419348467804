import React, { useContext, useState } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Button,
  IconButton,
  ListItemText,
  Box,
  Tooltip,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";

import moment from "moment";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";
import ContextAllMA from "../context/AllMaContext";

import DialogEditResultMA from "./DialogEditResultMA";
import DialogDeleteMAR from "./DialogDeleteMAR";
import DialogEvidences from "./DialogEvidences";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";

const ListItemMaResult = ({
  classes,
  item,
  maDetailState,
  isMeasuredActivityDetailShow,
  userToken,
  collectionMaResultList,
}) => {
  const context = useContext(ContextAllMA);

  const [anchorElListMaResult, setAnchorElListMaResult] = useState(null);
  const [openDialogEditResultMA, setOpenDialogEditResultMA] = useState(false);
  const [openDialogDeleteMAR, setOpenDialogDeleteMAR] = useState(false);
  const [openEvidences, setOpenEvidences] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDropdownOpenMaResult = (event) => {
    event.preventDefault();
    setAnchorElListMaResult(event.currentTarget);
  };

  function handleDropdownCloseMaResult() {
    setAnchorElListMaResult(null);
  }

  const handleUnlock = () => {
    AxiosConfig.post(
      `${URL_API}/swd/measured-activity/${maDetailState?.id}/result/unlock/${item?.id}`
    )
      .then((res) => {
        if (res.status === 200) {
          context.setSuccessCreateMAR(Math.floor(Math.random() * 1000 + 1));
        }
      })
      .catch((error) => {
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  let editAllowed = false;
  let deleteAllowed = false;

  item.policy &&
    item.policy.forEach((item) => {
      if (item.actionName === "update") {
        editAllowed = item.result;
      } else if (item.actionName === "delete") {
        deleteAllowed = item.result;
      }
    });

  return (
    <TableRow style={{ backgroundColor: "#fbfbfb" }}>
      <TableCell
        className={classes.txtBody}
        align="left"
        style={{ borderLeft: "1px solid #e9e9e9" }}
      >
        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: item?.is_locked ? "#888" : "#55dc87" }}
        >
          <b>
            {maDetailState.valueType.id === "2"
              ? FormatDecimal(item.result_value) + " %"
              : FormatDecimal(item.result_value)}
          </b>
        </Typography>
      </TableCell>
      <TableCell className={classes.txtBody} align="left">
        <Tooltip
          title={item.description !== null ? item.description : "-"}
          placement="top-end"
        >
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "grey" }}
          >
            {item.description !== null ? item.description : "-"}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.txtBody} align="left">
        <Typography
          variant="subtitle2"
          className={classes.title555}
          style={{ opacity: item?.is_locked ? 0.5 : 1 }}
        >
          <i style={{ fontSize: 12 }}>
            {`${moment(item.result_date).format("DD MMM YYYY")}`}
          </i>
          ,<i style={{ fontSize: 12 }}> {item.result_time.slice(0, 5)}</i>
        </Typography>
      </TableCell>
      <TableCell className={classes.txtBody} align="left">
        <Box display="flex" alignItems="center" width={70}>
          <IconButton
            size="medium"
            onClick={() => setOpenEvidences(true)}
            disabled={item.is_locked}
          >
            <PhotoLibraryOutlinedIcon style={{ color: "#888" }} />
          </IconButton>
          <Typography variant="caption" className={classes.title888}>
            + {item.measuredActivityResultEvidence.length}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        className={classes.txtBody}
        align="right"
        style={{ borderRight: "1px solid #e9e9e9" }}
      >
        {!item.is_locked && (
          <IconButton size="small" onClick={handleDropdownOpenMaResult}>
            <MoreHorizIcon />
          </IconButton>
        )}

        {item.is_locked && (
          <Button
            startIcon={
              <LockOpenRoundedIcon style={{ color: "#55dc87", fontSize: 14 }} />
            }
            size="small"
            className={classes.expandGRBtn}
            onClick={handleUnlock}
          >
            <Typography
              className={classes.titleGRList}
              variant="subtitle2"
              style={{ color: "#55dc87" }}
            >
              Unlock
            </Typography>
          </Button>
        )}

        <StyledMenu
          id="customized-menu-goal-result"
          anchorEl={anchorElListMaResult}
          keepMounted
          open={Boolean(anchorElListMaResult)}
          onClose={handleDropdownCloseMaResult}
        >
          <StyledMenuItem
            disabled={!editAllowed}
            onClick={() => {
              setOpenDialogEditResultMA(true);
              setAnchorElListMaResult(null);
            }}
          >
            <ListItemText
              primary={
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Edit</b>
                </Typography>
              }
            />
          </StyledMenuItem>
          <StyledMenuItem
            disabled={!deleteAllowed}
            onClick={(e) => {
              setOpenDialogDeleteMAR(true);
              setAnchorElListMaResult(null);
            }}
          >
            <ListItemText
              primary={
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Delete</b>
                </Typography>
              }
            />
          </StyledMenuItem>
        </StyledMenu>
      </TableCell>

      <DialogEditResultMA
        openDialogEditResultMA={openDialogEditResultMA}
        setOpenDialogEditResultMA={setOpenDialogEditResultMA}
        maDetailState={maDetailState}
        classes={classes}
        item={item}
        evidenceCount={item.measuredActivityResultEvidence.length}
      />

      <DialogDeleteMAR
        openDialogDeleteMAR={openDialogDeleteMAR}
        setOpenDialogDeleteMAR={setOpenDialogDeleteMAR}
        classes={classes}
        item={item}
      />

      <DialogEvidences
        classes={classes}
        openEvidences={openEvidences}
        setOpenEvidences={setOpenEvidences}
        item={item}
        maDetailState={maDetailState}
        collectionMaResultList={collectionMaResultList}
        userToken={userToken}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </TableRow>
  );
};

export default ListItemMaResult;
