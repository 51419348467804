import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  IconButton,
  InputBase,
  Box,
  Button,
  Chip,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Search as SearchIcon, Cancel as CancelIcon } from "@material-ui/icons";

import { useDebouncedCallback } from "use-debounce";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import {
  STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_ACTIVE,
  STATUS_OVERDUE,
  STATUS_COMPLETED,
} from "../../../../constants/config-status-goal";

import ContextAllMA from "../context/AllMaContext";
import ListStatusAllMADraft from "../ComponentStatusAllMA/ListStatusAllMADraft";
import ListStatusAllMAApproved from "../ComponentStatusAllMA/ListStatusAllMAApproved";
import ListStatusAllMAActive from "../ComponentStatusAllMA/ListStatusAllMAActive";
import ListStatusAllMAOverdue from "../ComponentStatusAllMA/ListStatusAllMAOverdue";
import ListStatusAllMACompleted from "../ComponentStatusAllMA/ListStatusAllMACompleted";

import { styles, theme } from "../style/StyleAllMA";
import Snackbeer from "../../../../components/Snackbeer";
import DialogConfirmWeight from "../../MeasureActivities/Components/DialogConfirmWeight";
import AnchorFilterWeight from "./AnchorFilterWeight";
import DialogCreateMA from "../components/DialogCreateMA";
import { AnchorFilterMA } from "./AnchorFilterMA";
import DialogError from "../../../../components/DialogError";

const useStyle = makeStyles(styles);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#282560",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

function ViewAll() {
  // Context State
  const context = useContext(ContextAllMA);
  const classes = useStyle();
  let inputRefSearch = useRef(null);

  const userToken = localStorage.getItem("userToken");
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  const [disableCreateMA, setDisableCreateMA] = useState(false);

  const [userTokenState, setUserTokenState] = useState("");

  // CONFIRM WEIGHT
  const [openDialogConfirmWeight, setOpenDialogConfirmWeight] = useState(false);

  const [openCreateMA, setOpenCreateMA] = useState(false);

  const [valTab, setValTab] = useState(1);
  const [weightValue, setWeightValue] = useState([0, 100]);
  const [goalId, setGoalId] = useState({ id: "", name: "" });

  // LIST OF LOCAL COMPONENT STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  useEffect(() => {
    if (valTab === 0) {
      context.setStatusAllMAIdAll(2); //*Approved
      localStorage.setItem("status_allma_id", "2");
    }

    if (valTab === 1) {
      context.setStatusAllMAIdAll(3); //*Active
      localStorage.setItem("status_allma_id", "3");
    }

    if (valTab === 2) {
      context.setStatusAllMAIdAll(6); //*Overdue
      localStorage.setItem("status_allma_id", "6");
    }

    if (valTab === 3) {
      context.setStatusAllMAIdAll(4); //*Completed
      localStorage.setItem("status_allma_id", "4");
    }
  }, [valTab]);

  useEffect(() => {
    setUserTokenState(userToken);

    /**
   ==================================================================================
    HANDLE INTERACT WHILE SEARCHING
   ==================================================================================
   */
    const valueSearch = localStorage.getItem("value_search_ma");

    if (valueSearch !== null && valueSearch !== undefined) {
      context.setSearchRun(true);
      context.setValueSearchState(valueSearch);
    } else {
      context.setSearchRun(false);
      context.setValueSearchState("");
    }
    // ============================= MIN_MAX WEIGHT =============================
    let URL = "";
    let URL2 = "";
    const targetURL = `${URL_API}/swd/measured-activity?options[filter][search]=${valueSearch}&options[filter][status_id]=${
      context.statusAllMAIdAll
    }&options[paging][offset]=${
      context.offset === -10 ? 0 : context.offset
    }&options[paging][limit]=${context.rowsPerPage}&options[filter][goal_id]=${
      goalId.id
    }`;
    const targetURL2 = `${URL_API}/swd/measured-activity?options[filter][status_id]=${context.statusAllMAIdAll}&options[paging][offset]=0&options[paging][limit]=10&options[filter][goal_id]=${goalId.id}`;
    const minWeight = `&options[filter][weighting][from]=${context.weightValueAll.min}`;
    const maxWeight = `&options[filter][weighting][to]=${context.weightValueAll.max}`;

    if (
      context.weightValueAll.min === 0 &&
      context.weightValueAll.max === 100
    ) {
      URL = targetURL;
      URL2 = targetURL2;
    } else {
      URL = `${targetURL}${minWeight}${maxWeight}`;
      URL2 = `${targetURL2}${minWeight}${maxWeight}`;
    }

    if (userToken !== undefined) {
      if (valueSearch !== null && valueSearch !== undefined) {
        context.setLoader(true);

        axiosConfig
          .get(URL)
          .then((response) => {
            console.log(
              "Response Original COLLECTION MA DID MOUNT SEARCH : ",
              response
            );

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setCollectionMAList(response.data.data);
                context.setCollectionMALength(response.data.info.count);
              }
            }
            context.setLoader(false);
          })
          .catch((error) => {
            context.setLoader(false);
            // console.log("Error : ", error);
          });
      } else {
        context.setLoader(true);

        axiosConfig
          .get(URL2)
          .then((response) => {
            // console.log("Response Original COLLECTION MA : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setCollectionMAList(response.data.data);
                context.setCollectionMALength(response.data.info.count);
              }
            }
            context.setLoader(false);
          })
          .catch((error) => {
            context.setLoader(false);
            // console.log("Error : ", error);

            if (error.response !== undefined) {
              if (error.response?.status === 429) {
                setErrorStatus(429);
                setTextErrorInformation("Too Many Request !");
                setOpenDialogError(true);
              }
            }
          });
      }
    } else {
      // console.log("No Access Token available!");
    }
  }, [
    context.statusAllMAIdAll,
    context.dataTabActive,
    context.deleteSuccess,
    context.successSetAsComplete,
    context.successDuplicate,
    context.isSuccessCreateMAR,
    context.weightValueAll,
    context.clearSearchResult,
    goalId,
    valTab,
    // test add dependencies
  ]);

  /**
   ==================================================================================
    HANDLE SEARCH FOR EVERY STATUS (Draft, Active, Completed, etc)
   ==================================================================================
   */
  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      const statusAllMAId = localStorage.getItem("status_allma_id");
      // ============================= MIN_MAX WEIGHT =============================

      let URL = "";
      let URL2 = "";
      const targetURL = `${URL_API}/swd/measured-activity?options[filter][search]=${value}&options[filter][status_id]=${statusAllMAId}&options[paging][offset]=${
        context.offset === -10 ? 0 : context.offset
      }&options[paging][limit]=${
        context.rowsPerPage
      }&options[filter][goal_id]=${goalId.id}`;
      const targetURL2 = `${URL_API}/swd/measured-activity?options[filter][status_id]=${statusAllMAId}&options[paging][offset]=${
        context.offset === -10 ? 0 : context.offset
      }&options[paging][limit]=${
        context.rowsPerPage
      }&options[filter][goal_id]=${goalId.id}`;
      const minWeight = `&options[filter][weighting][from]=${context.weightValueAll.min}`;
      const maxWeight = `&options[filter][weighting][to]=${context.weightValueAll.max}`;

      if (
        context.weightValueAll.min === 0 &&
        context.weightValueAll.max === 100
      ) {
        URL = targetURL;
        URL2 = targetURL2;
      } else {
        URL = `${targetURL}${minWeight}${maxWeight}`;
        URL2 = `${targetURL2}${minWeight}${maxWeight}`;
      }

      if (value !== "") {
        // console.log("I want to know All MA : ", value);
        localStorage.setItem("value_search_ma", value);

        context.setValueSearchState(value);

        if (userToken !== undefined) {
          context.setLoader(true);

          axiosConfig
            .get(URL)
            .then((response) => {
              // console.log("Response Original ALL MA from search : ", response);

              if (response.status === 200) {
                if (response.data.data !== null) {
                  context.setSearchRun(true);
                  context.setCollectionMAList(response.data.data);
                  context.setCollectionMALength(response.data.info.count);
                }
              }
              context.setLoader(false);
            })
            .catch((error) => {
              context.setLoader(false);
              // console.log("Error : ", error.response);
            });
        } else {
          // console.log("No Access Token available!");
        }
      } else {
        console.log(`Value search ${typeof value} kosong`);
        context.setValueSearchState("");
        context.setSearchRun(false);

        localStorage.removeItem("value_search_ma");
        context.setLoader(true);

        axiosConfig
          .get(URL2)
          .then((response) => {
            // console.log("Response Original COLLECTION ALL MA : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                context.setCollectionMAList(response.data.data);
                context.setCollectionMALength(response.data.info.count);
              }
            }
            context.setLoader(false);
          })
          .catch((error) => {
            context.setLoader(false);
            // console.log("Error : ", error.response);
          });
      }
    }, []),
    1000,

    { maxWait: 5000 }
  );

  // Handle Disable Create MA depending on status code 403
  useEffect(() => {
    const valueSearch = localStorage.getItem("value_search_ma");

    context.setValueSearchState(valueSearch);

    if (userToken !== undefined) {
      axiosConfig
        .get(`${URL_API}/swd/measured-activity/create`)
        .then((response) => {
          // // console.log("Response Create Goal", response);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          if (error.response?.status === 403) {
            setDisableCreateMA(true);
          }
        });
    } else {
      // console.log("User Token Not Exist");
    }
  }, []);

  /**
   ==================================================================================
    HANDLE CHANGE TAB STATUS (Draft, Active, Completed, etc)
   ==================================================================================
   */
  // // console.log("context.statusAllMAIdAll", context.statusAllMAIdAll);

  const handleChangeTab = (event, newValue) => {
    // context.setValueTabAll(newValue);
    context.setStatusAllMAIdAll();
    setValTab(newValue);

    if (newValue === 0) {
      context.setStatusAllMAIdAll(2); //*Approved
      localStorage.setItem("status_allma_id", "2");
    }

    if (newValue === 1) {
      context.setStatusAllMAIdAll(3); //*Active
      localStorage.setItem("status_allma_id", "3");
    }

    if (newValue === 2) {
      context.setStatusAllMAIdAll(6); //*Overdue
      localStorage.setItem("status_allma_id", "6");
    }

    if (newValue === 3) {
      context.setStatusAllMAIdAll(4); //*Completed
      localStorage.setItem("status_allma_id", "4");
    }
  };

  /**
   ==================================================================================
    HANDLE CLEAR RESULT SEARCH
   ==================================================================================
   */
  useEffect(() => {
    if (
      context.valueSearch === "" &&
      context.isSearchRun === false &&
      context.clearSearchResult === true
    ) {
      context.setLoader(true);

      // ============================= MIN_MAX WEIGHT =============================
      let URL = "";
      const targetURL = `${URL_API}/swd/measured-activity?options[filter][status_id]=${context.statusAllMAIdAll}&options[paging][offset]=0&options[paging][limit]=10&options[filter][goal_id]=${goalId.id}`;
      const minWeight = `&options[filter][weighting][from]=${context.weightValueAll.min}`;
      const maxWeight = `&options[filter][weighting][to]=${context.weightValueAll.max}`;

      if (
        context.weightValueAll.min === 0 &&
        context.weightValueAll.max === 100
      ) {
        URL = targetURL;
      } else {
        URL = `${targetURL}${minWeight}${maxWeight}`;
      }

      if (
        context.userTokenStateGlobal !== undefined &&
        context.userTokenStateGlobal !== null
      ) {
        axiosConfig
          .get(URL)
          .then(function (response) {
            // console.log("Response Original COLLECTION ALL MA : ", response);

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                context.setCollectionMAList(response.data.data);
                context.setCollectionMALength(response.data.info.count);
              }
            }

            context.setLoader(false);
            context.setClearSearchResult(false);
          })
          .catch(function (error) {
            context.setLoader(false);
            // console.log("Error : ", error.response);

            context.setClearSearchResult(false);
          });
      }
    }
  }, [context.valueSearch, context.isSearchRun, context.clearSearchResult]);

  /**
   ==================================================================================
    HANDLE PAGINATION WHEN STATE PAGE CHANGE
   ==================================================================================
   */
  useEffect(() => {
    if (context.loaderForPaging === true) {
      context.setLoader(true);

      const valueSearch = localStorage.getItem("value_search_ma");

      // ============================= MIN_MAX WEIGHT =============================
      let URL = "";
      let URL2 = "";
      const targetURL = `${URL_API}/swd/measured-activity?options[filter][search]=${valueSearch}&options[filter][status_id]=${
        context.statusAllMAIdAll
      }&options[paging][offset]=${
        context.offset === -10 ? 0 : context.offset
      }&options[paging][limit]=${
        context.rowsPerPage
      }&options[filter][goal_id]=${goalId.id}`;
      const targetURL2 = `${URL_API}/swd/measured-activity?options[filter][status_id]=${
        context.statusAllMAIdAll
      }&options[paging][offset]=${
        context.offset === -10 ? 0 : context.offset
      }&options[paging][limit]=${
        context.rowsPerPage
      }&options[filter][goal_id]=${goalId.id}`;
      const minWeight = `&options[filter][weighting][from]=${context.weightValueAll.min}`;
      const maxWeight = `&options[filter][weighting][to]=${context.weightValueAll.max}`;

      if (
        context.weightValueAll.min === 0 &&
        context.weightValueAll.max === 100
      ) {
        URL = targetURL;
        URL2 = targetURL2;
      } else {
        URL = `${targetURL}${minWeight}${maxWeight}`;
        URL2 = `${targetURL2}${minWeight}${maxWeight}`;
      }

      if (valueSearch !== null) {
        axiosConfig
          .get(URL)
          .then((response) => {
            // console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                // setFind(response.data.data);
                context.setCollectionMAList(response.data.data);
                context.setCollectionMALength(response.data.info.count);
              }
            }
            context.setLoaderForPaging(false);
            context.setLoader(false);
          })
          .catch((error) => {
            context.setLoader(false);
            context.setLoaderForPaging(false);
            // console.log("Error : ", error);
          });
      } else {
        axiosConfig
          .get(URL2)
          .then((response) => {
            // console.log("Response Original COLLECTION GOAL : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                // setFind(response.data.data);
                context.setCollectionMAList(response.data.data);
                context.setCollectionMALength(response.data.info.count);
              }
            }
            context.setLoaderForPaging(false);
            context.setLoader(false);
          })
          .catch((error) => {
            context.setLoader(false);
            context.setLoaderForPaging(false);
            // console.log("Error : ", error);
          });
      }
    }
  }, [
    context.offset,
    context.rowsPerPage,
    context.page,
    context.paramsFilterGoal,
    context.loaderForPaging,
    context.statusGoalId,
    context.weightValueAll,
    valTab,
  ]);

  /**
   ==================================================================================
    Show Measured Activity Counter Status
   ==================================================================================
   */

  const [maCounter, setMACounter] = useState([]);

  useEffect(() => {
    axiosConfig
      .get(`${URL_API}/swd/measured-activity/count-status`)
      .then((response) => {
        // // console.log("Response Status Counter", response);
        const result = response.data.data;

        if (response.status === 200) {
          const filterstatusDraft = result.filter((item) => item.id !== "1");

          setMACounter(filterstatusDraft);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });
  }, [
    context.deleteSuccess,
    context.successSetAsComplete,
    context.successDuplicate,
    context.isSuccessCreateMAR,
  ]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  const handleRemoveWeight = () => {
    setWeightValue([0, 100]);
    context.setWeightValueAll({ min: 0, max: 100 });
  };

  const handleRemoveGoal = () => {
    setGoalId({ id: "", name: "" });
  };

  return (
    <Fragment>
      <Box
        bgcolor="#fafafa"
        position="fixed"
        zIndex={100}
        width="100%"
        mt={6}
        pt={3}
        pb={1.2}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr={9}
          pl={2}
        >
          <StyledTabs
            indicatorColor="primary"
            value={valTab}
            onChange={handleChangeTab}
            aria-label="simple tabs example"
          >
            {listTabs.length > 0 &&
              listTabs.map((item, i) => {
                let counter = 0;

                if (maCounter.length > 0) {
                  if (maCounter[i].code === item.code) {
                    counter = maCounter[i].count;
                  }
                }

                return (
                  <Tab
                    classes={{ root: classes.widthTab }}
                    label={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{
                          textTransform: "capitalize",
                          marginBottom: -10,
                        }}
                      >
                        <b>{item.label}</b>
                        <span
                          style={{
                            color: "#1D80DB",
                            backgroundColor: "#D7E4F7",
                            fontWeight: "700",
                            paddingLeft: 4,
                            paddingRight: 4,
                            fontSize: 14,
                            marginLeft: 5,
                            borderRadius: 5,
                          }}
                        >
                          {counter}
                        </span>
                      </Typography>
                    }
                    {...a11yProps(i)}
                  />
                );
              })}
          </StyledTabs>

          <Box>
            {superior &&
              (context.statusAllMAIdAll === 2 ||
                context.statusAllMAIdAll === 3) && (
                <Box display="flex" alignItems="center">
                  <Button
                    size="small"
                    onClick={() => setOpenCreateMA(true)}
                    variant="outlined"
                    className={classes.button}
                    disabled={disableCreateMA}
                    style={{ borderRadius: 10 }}
                  >
                    Buat Measure Activity baru
                  </Button>
                </Box>
              )}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr={9}
          pl={3}
          mt={3}
        >
          <Box display="flex" alignItems="center">
            <div className={classes.search}>
              <InputBase
                startAdornment={
                  <SearchIcon
                    style={{ color: "#C4C4C4", fontSize: 18, marginLeft: 12 }}
                  />
                }
                endAdornment={
                  <IconButton
                    size="small"
                    onClick={() => {
                      context.setValueSearchState("");
                      context.setSearchRun(false);
                      context.setClearSearchResult(true);
                      localStorage.removeItem("value_search_ma");

                      setTimeout(() => {
                        inputRefSearch.current.value = "";
                      }, 200);
                    }}
                  >
                    <CancelIcon
                      style={{
                        color: "#C4C4C4",
                        fontSize: 18,
                        marginRight: 12,
                      }}
                    />
                  </IconButton>
                }
                onChange={(e) => handleSearch(e.target.value)}
                inputRef={inputRefSearch}
                placeholder={
                  context.valueSearchState !== "" ||
                  context.valueSearchState !== undefined
                    ? context.valueSearchState
                    : "Search Measure Activity..."
                }
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "Search" }}
              />
            </div>

            <AnchorFilterMA
              classes={classes}
              weightValue={weightValue}
              onWeightValue={setWeightValue}
              goalId={goalId}
              onGoalId={setGoalId}
            />
            <Box mx={1} />
            {(weightValue[0] > 0 || weightValue[1] < 100) && (
              <Chip
                variant="outlined"
                size="small"
                className={classes.chipFilterSort}
                label={
                  <Typography variant="caption">{`${weightValue[0]} - ${weightValue[1]}`}</Typography>
                }
                onDelete={handleRemoveWeight}
                deleteIcon={<CancelIcon style={{ color: "#fff" }} />}
                style={{ marginLeft: 8 }}
              />
            )}
            {goalId.id !== "" && (
              <Chip
                variant="outlined"
                size="small"
                className={classes.chipFilterSort}
                label={<Typography variant="caption">{goalId.name}</Typography>}
                onDelete={handleRemoveGoal}
                deleteIcon={<CancelIcon style={{ color: "#fff" }} />}
                style={{ marginLeft: 8 }}
              />
            )}
          </Box>

          <Box display="flex" alignItems="center">
            <Button
              onClick={() => context.setModalExportMA(true)}
              variant="outlined"
              className={classes.btnFilter}
              style={{ borderWidth: 1 }}
            >
              Export CSV
            </Button>
          </Box>
        </Box>
      </Box>

      <Box pt={20} bgcolor="#fafafa">
        <TabPanel value={valTab} index={0}>
          <ListStatusAllMAApproved />
        </TabPanel>
        <TabPanel value={valTab} index={1}>
          <ListStatusAllMAActive />
        </TabPanel>
        <TabPanel value={valTab} index={2}>
          <ListStatusAllMAOverdue />
        </TabPanel>
        <TabPanel value={valTab} index={3}>
          <ListStatusAllMACompleted />
        </TabPanel>
      </Box>

      <Snackbeer
        setModalResponse200={context.setModalResponse200}
        isModalResponse200={context.isModalResponse200}
        messages={context.messages}
      />

      <DialogConfirmWeight
        classes={classes}
        openDialogConfirmWeight={openDialogConfirmWeight}
        setOpenDialogConfirmWeight={setOpenDialogConfirmWeight}
        setModalContinue={context.setModalCreate}
      />

      <DialogCreateMA
        classes={classes}
        openCreateMA={openCreateMA}
        setOpenCreateMA={setOpenCreateMA}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default ViewAll;

const listTabs = [
  {
    id: 2,
    label: "Approved",
    code: STATUS_APPROVED,
    status_id: 2,
  },

  {
    id: 3,
    label: "Active",
    code: STATUS_ACTIVE,
    status_id: 3,
  },

  {
    id: 4,
    label: "Overdue",
    code: STATUS_OVERDUE,
    status_id: 6,
  },

  {
    id: 5,
    label: "Complete",
    code: STATUS_COMPLETED,
    status_id: 4,
  },
];
