import React, { useRef, useState } from "react";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Box,
  InputBase,
} from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import { fade } from "@material-ui/core/styles";
import PictREFRESH from "../../../../../assets/images/Group_2725.png";

function EnhancedTableToolbar({
  classes,
  numSelected,
  dataCategoryList,
  setDataCategoryList,
  getCategoryList,
}) {
  const timeout = useRef(null);
  
  const doSearch = (event) => {
    if(timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => getCategoryList(), 1000);
  }

  // const doSearch = setTimeout(() => getCategoryList(), 1000);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography variant="subtitle1" className={classes.title333} style={{textAlign: "left"}}>
          <b>{numSelected}</b> Ditandai
        </Typography>
      ) : (
        <Typography variant="subtitle1" className={classes.title333} style={{textAlign: "left"}}>
            <b>Daftar Kategori</b>
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete">
            <DeleteIcon style={{ color: "#d64253" }} />
          </IconButton>
        </Tooltip>
      )}
      
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <div className={classes.search} style={{marginRight: "0px"}}>
            <InputBase
              startAdornment={
                <SearchIcon
                  style={{ color: "#C4C4C4", fontSize: 18, marginLeft: 12 }}
                />
              }
              endAdornment={
                <IconButton
                  size="small"
                  onClick={() => {
                    let dataCategoryListTemporary = dataCategoryList;
                    dataCategoryListTemporary.options.filter.search = "";
                    dataCategoryListTemporary.options.paging.offset = 0;
                    dataCategoryListTemporary.options.paging.page = 0;
                    setDataCategoryList({...dataCategoryListTemporary});
                    getCategoryList();
                  }}
                >
                  <CancelIcon
                    style={{
                      color: "#C4C4C4",
                      fontSize: 18,
                      marginRight: 12,
                    }}
                  />
                </IconButton>
              }
              onChange={(e) => {
                let dataCategoryListTemporary = dataCategoryList;
                dataCategoryListTemporary.options.filter.search = e.target.value;
                dataCategoryListTemporary.options.paging.offset = 0;
                dataCategoryListTemporary.options.paging.page = 0;
                setDataCategoryList({...dataCategoryListTemporary});
                doSearch();
              }}
              value={dataCategoryList.options.filter.search}
              placeholder="Cari Kategori..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "Search" }}
            />
          </div>
          <Box mx={1} />
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton style={{ padding: 4 }} onClick={getCategoryList}>
            <img
              src={PictREFRESH}
              style={{ width: 17, height: 17 }}
              alt="."
            />
          </IconButton>
        </Box>
      </Box>
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
