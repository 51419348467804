import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import moment from "moment";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import "moment/locale/id";

const DialogDateRange = (props) => {
  const {
    classes,
    userTokenState,
    isShowDateRangeComponent,
    setShowDateRangeComponent,

    onStartDate,
    onEndDate,

    // setEndDateInDateRange,
    // endDateInDateRange
  } = props;

  /*
        ```````````````````
        COMPONENT DID MOUNT 

        ```````````````````
    */

  const [inputIntervalId, setInputIntervalId] = useState("");
  const [masterIntervalCollections, setMasterIntervalCollections] = useState(
    []
  );

  useEffect(() => {
    if (userTokenState !== undefined) {
      axiosConfig
        .get(URL_API + "/swd/master-period/create")
        .then(function (response) {
          // console.log("Res date-range : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              if (response.data.data.masterIntervalCollections.length > 0) {
                setMasterIntervalCollections(
                  response.data.data.masterIntervalCollections
                );
              }
            }
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  }, []);

  /*
        ````````````````````````
        HANDLE DATE RANGE VALUE  

        ```````````````````````
    */

  const [startDateInDateRange, setStartDateInDateRange] = useState(new Date());
  const [endDateInDateRange, setEndDateInDateRange] = useState(new Date());

  const handleSelectDateRange = (e) => {
    // setStartDateInDateRange(e.selection.startDate);
    // setEndDateInDateRange(e.selection.endDate);

    // onStartDate(moment(e.selection.startDate).format("YYYY-MM-DD"));
    // onEndDate(moment(e.selection.endDate).format("YYYY-MM-DD"));

    // // console.log("E : ", e);
    setStartDateInDateRange(e.selection.startDate);
    setEndDateInDateRange(e.selection.endDate);

    // let newInterval = getIntervalFromStartDateAndEndDate( evt.selection.startDate, evt.selection.endDate );

    // if (newInterval) {

    //     setInputIntervalId( newInterval['id'] );
    // }
  };

  // const [ inputEndDate, setInputEndDate ] = useState( moment().format( 'YYYY-MM-DD' ) );

  const handleClickIntervalId = (evt, interval) => {
    evt.preventDefault();
    // change start date and end date
    let newEndDate = getIntervalEndDate(
      startDateInDateRange.format("YYYY-MM-DD"),
      interval
    );
    // set input interval id
    setInputIntervalId(interval["id"]);
    // set form input end date
    setInputEndDate(moment(newEndDate).format("DD MMMM YYYY"));
    // set selected end date in date range
    setEndDateInDateRange(moment(newEndDate));
  };

  const getIntervalEndDate = (startDate, interval) => {
    // define default output
    let outp = moment(startDate).format("YYYY-MM-DD");

    // define default end date
    let momentEndDate = moment(startDate);
    // when interval is defined and not null
    if (interval && interval.unit !== null && interval.value !== null) {
      if (interval.unit.toLowerCase() == "year") {
        // when interval unit rule is year
        outp = momentEndDate
          .add(interval.value, "year")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (interval.unit.toLowerCase() == "month") {
        // when interval unit rule is month
        outp = momentEndDate
          .add(interval.value, "month")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else {
        // set default value when there is no rule in interval
        outp = momentEndDate.format("YYYY-MM-DD");
      }
    } else {
      // set default value when interval is undefined or null
      outp = momentEndDate.format("YYYY-MM-DD");
    }

    return outp;
  };

  const [inputStartDate, setInputStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [inputEndDate, setInputEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [fakeInputStartDate, setFakeInputStartDate] = useState(
    moment().format("DD MMMM YYYY")
  );
  const [fakeInputEndDate, setFakeInputEndDate] = useState(
    moment().format("DD MMMM YYYY")
  );

  const handleClickDateRange = (evt) => {
    // console.log(
    //   "Start date : ",
    //   moment(startDateInDateRange).format("YYYY-MM-DD")
    // );
    // // console.log("End date : ", moment(endDateInDateRange).format("YYYY-MM-DD"));

    localStorage.setItem(
      "start_date",
      moment(startDateInDateRange).format("YYYY-MM-DD")
    );
    localStorage.setItem(
      "end_date",
      moment(endDateInDateRange).format("YYYY-MM-DD")
    );

    setInputStartDate(moment(startDateInDateRange).format("YYYY-MM-DD"));
    setInputEndDate(moment(endDateInDateRange).format("YYYY-MM-DD"));

    onStartDate(moment(startDateInDateRange).format("YYYY-MM-DD"));
    onEndDate(moment(endDateInDateRange).format("YYYY-MM-DD"));

    setFakeInputStartDate(moment(startDateInDateRange).format("DD MMMM YYYY"));
    setFakeInputEndDate(moment(endDateInDateRange).format("DD MMMM YYYY"));

    setShowDateRangeComponent(false);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={isShowDateRangeComponent}
      onClose={() => setShowDateRangeComponent(false)}
      fullWidth
    >
      <DialogTitle
        style={{ textAlign: "center" }}
        id="customized-dialog-title"
        onClose={() => setShowDateRangeComponent(false)}
      >
        <Typography variant="h6" className={classes.title}>
          <b>Pilih Mulai dan Berakhir</b>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                marginLeft: "-250px",
                transform: "scale(0.80, 0.80)",
                paddingTop: 0,
                marginTop: 0,
              }}
            >
              <DateRangePicker
                ranges={[
                  {
                    startDate: startDateInDateRange,
                    endDate: endDateInDateRange,
                    key: "selection",
                  },
                ]}
                rangedCalendars={true}
                onInit={handleSelectDateRange}
                onChange={handleSelectDateRange}
                months={2}
                direction={"horizontal"}
                staticRanges={[]}
                inputRanges={[]}
                style={{ fontFamily: "Roboto" }}
              />
            </div>
          </Grid>
        </Grid>

        {
          // <!-- interval selector -->
        }
        {/* <Grid container>
                <Grid item xs={12}>
                    {
                        masterIntervalCollections.length > 0 && masterIntervalCollections.map((item, i) => (
                            <Fragment key={ item['id'] }>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    // color="secondary" 
                                    className={ classes.dateRangePerbaruiSampul }
                                    onClick={ (e) => handleClickIntervalId(e, item) }
                                    style={ item['id'] == inputIntervalId ? {
                                        textTransform: 'capitalize',
                                        color: 'white'
                                    } : {
                                        textTransform: 'capitalize',
                                        backgroundColor: 'rgba(0,0,0,0.1)',
                                        color: 'gray'
                                    } }
                                >
                                    { item.name }
                                </Button>&nbsp;
                            </Fragment>
                        ))
                    }
                </Grid>
            </Grid> */}
        {
          // <!-- /interval selector -->
        }

        {
          // <!-- span -->
        }
        <Grid container>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
        </Grid>
        {
          // <!-- /span -->
        }

        <Grid container>
          <Grid item xs={5}>
            <Button
              className={classes.dateRangePerbaruiSampul}
              variant="outlined"
              onClick={() => setShowDateRangeComponent(false)}
              fullWidth
            >
              Batal
            </Button>
          </Grid>
          <Grid item xs={2}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Button
              className={classes.button}
              variant="contained"
              // color="secondary"
              onClick={handleClickDateRange}
              fullWidth
            >
              Simpan
            </Button>
          </Grid>
        </Grid>

        {
          // <!-- /span --?
        }
        <Grid container>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
        </Grid>
        {
          // <!-- /span -->
        }
      </DialogContent>
    </Dialog>
  );
};

export default DialogDateRange;
