import React, { Fragment, useContext } from "react";
import {
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ContextGoalDetail from "../Context/ContextGoalDetail";

import PictDone from "../../../../assets/images/Group_2614.png";

import {
  FormatDecimal,
  FormatThousand,
} from "../../../../utilities/FormatDecimal";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

const IconExpectedValue = (props) => {
  const { classes, goalDetailState } = props;
  const context = useContext(ContextGoalDetail);

  return (
    <Fragment>
      <ListItemIcon style={{ marginLeft: 8 }}>
        <img src={PictDone} style={{ width: 40, height: 40 }} alt="." />
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}
          >
            {MyLang() ? bahasa.expectedVal : english.expectedVal}
          </Typography>
        }
        secondary={
          <LightTooltip
            placement="bottom-start"
            title={
              <>
                <span>
                  {context.modeRupiahOn === true
                    ? `Expected value : Rp ${FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .expected_result_value
                      )}`
                    : "Expected value : " +
                      FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .expected_result_value
                      )}
                </span>
                &nbsp;
                <span>
                  (
                  {FormatDecimal(
                    goalDetailState.calculatedValue.resultOriented
                      .expected_result_value_achievement * 100
                  )}{" "}
                  %)
                </span>
              </>
            }
          >
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "white" }}
            >
              <b>
                {context.modeRupiahOn === true
                  ? `Rp ${FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .expected_result_value
                    )}`
                  : FormatDecimal(
                      goalDetailState.calculatedValue.resultOriented
                        .expected_result_value
                    )}
              </b>
              &nbsp;
              <b>
                (
                {FormatDecimal(
                  goalDetailState.calculatedValue.resultOriented
                    .expected_result_value_achievement * 100
                )}{" "}
                %)
              </b>
            </Typography>
          </LightTooltip>
        }
      />
    </Fragment>
  );
};

export default IconExpectedValue;
