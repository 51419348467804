import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";

import { URL_API } from "../../../../../constants/config-api";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDelete,
    setModalDelete,
    dataBreak,
    setDataBreak,
    handleDelete,
  } = props;

  const context = useContext(ContextAttendanceSetting);

  const [loader, setLoader] = useState(false);

  return (
    <Dialog
      open={isModalDelete}
      onClose={() => setModalDelete(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: "left" }}></DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <div id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Apakah Anda yakin ingin hapus{" "}
              {dataBreak.title !== ""
                ? dataBreak.title
                : ""}{" "}
              ?
            </b>
          </Typography>
        </div>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          onClick={handleDelete}
          variant="contained"
          className={classes.button}
          // fullWidth
        >
          {loader !== true ? (
            "Hapus"
          ) : (
            <CircularProgress size={20} style={{ color: "white" }} />
          )}
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
};

export default DialogDelete;
