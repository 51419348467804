export const ToMaintenance = '/maintenance';
export const ToDashboard = '/dashboard';
export const ToRegister = '/register';
export const ToLogin = '/login';
export const ToOTP = '/otp';
export const ToNewWelcome = '/new-welcome';
export const ToNewWelcomeVerify = '/new-welcome-verify';

export const ToCompletionMemberhipOptions = '/completion-membership-options';
export const ToCompletionProfile = '/completion-profile';

export const ToCompletionCompanyStructureQuestionONE =
  '/completion-company-structure-one';
export const ToCompletionCompanyStructure =
  '/completion-company-structure-second';
export const ToCompletionCompanyStructureQuestionTHIRD =
  '/completion-company-structure-third'; //* Udah ga kepake !
export const ToCompletionCompanyStructureFormBantuSaya =
  '/completion-company-structure-empty-form-help-me';

export const ToCompletionCompanyStructureFormulirKosong =
  '/completion-company-structure-empty-form';

export const ToStructureOrganization = '/structure-organization';

export const ToMembershipStatus = '/membership-status';
export const ToMembershipStatusDeactivate = '/membership-status-deactivate';
export const ToMembershipPaymentStatusAfterCountDown =
  '/membership-payment-status-after-countdown';
export const ToMembershipStatusIncreaseQuotaSEMENTARA =
  '/membership-status-increase-quota';
export const ToMembershipStatusScenarioPENDING_SEMENTARA =
  '/membership-status-pending-or-active';
export const ToMembershipStatusManageSEMENTARA = '/membership-status-manage';
export const ToMembershipStatusManagePENDING_SEMENTARA =
  '/membership-status-manage-pending';

export const ToRole = '/role';
export const ToRoleDetail = '/role-detail';
export const ToRoleAdd = '/role-add';
export const ToRoleEdit = '/role-edit';
export const ToUserManagement = '/user-management';
export const ToUserManagementManagePassword =
  '/user-management-manage-password';

export const ToCompanyProfile = '/company-profile';
export const ToCompanyProfileSetting = '/company-profile-setting';

export const ToCompanySetting = '/company-setting';

export const ToEmptyStateGeneral = '/general-empty';
export const ToOrganigram = '/organigram';
export const ToHrEmployeeIdentitas = '/hr-employee-identity-step-one';
export const ToHrEmployeeIdentitasAlamat = '/hr-employee-identity-step-one-b';
export const ToHrEmployeeIdentitasInfoTambahan =
  '/hr-employee-identity-step-one-c';

export const ToHrEmployeeKeluarga = '/hr-employee-family-step-two';

export const ToHrEmployeeKepegawaianInfoPegawai =
  '/hr-employee-kpgwn-step-three';
export const ToHrEmployeeKepegawaianInfoPosisi =
  '/hr-employee-kpgwn-step-three-b';

export const ToHrEmployeeKeahlian = '/hr-employee-expertise-step-four';
export const ToHrEmployeeRiwayat = '/hr-employee-history-step-five';
export const ToHrEmployeeRiwayatPendidikanFormal =
  '/hr-employee-history-step-five-b';
export const ToHrEmployeeRiwayatPendidikanINFORMAL =
  '/hr-employee-history-step-five-c';
export const ToHrEmployeeRiwayatOrganisasi = '/hr-employee-history-step-five-d';
export const ToHrEmployeeRiwayatPengalamanKerja =
  '/hr-employee-history-step-five-e';

export const ToHrEmployeeRiwayatUrgentContact = '/hr-employee-urgent-contacts';

export const ToHrEmployeeProfileDetail = '/hr-employee-profile-detail';
export const ToHrEmployeeProfileDetailSeeDetail =
  '/hr-employee-profile-detail-see-detail';

export const ToPreForgetPassword = '/pre-forget-password';
export const ToForgotPassword = '/forgot-password';

export const ToFormUnit = '/form-unit-structure-organization';

/*
    ``````
    HR-SO

    ``````
*/
export const ToSOTable = '/so-table';
export const ToSOMaster = '/so-master';

export const ToNewSO = '/new-so';
export const ToNewSoTreeViewChartPreview = '/new-so-treeview-chart-preview';
export const ToNewSoTreeViewOrgChart = '/new-so-treeview-org-chart';

export const ToHomeOfNewSO = '/new-so'; //*Versi ini config-navigate-url-with-hookrouter.js --- BELOM DI PAKE

/*
    ```
    7WD

    ```
*/
export const To7wdPeriode = '/7wd-period';

/*
    ````
    GOAL

    ````
*/
export const ToGoal = '/goal';
export const ToGoalDetail = '/goal-detail';
export const ToSubGoalDetail = '/sub-goal-detail';
export const ToGoalDetailTabComplete = '/goal-detail-tab-complete';
export const ToGoalDetailALL = '/goal-detail-ALL';

/*
    `````````
    MA DETAIL

    ````````
*/
export const ToMeasuredActivity = '/ma';
// export const ToMeasuredActivityBintang= '/ma*';
export const ToMeasuredActivityDetail = '/ma-detail'; //*Tidak di pakai
/*
    `````````
    ALL MA

    ````````
*/
export const ToAllMA = '/all-ma';
export const ToAllMADetail = '/allma-detail';

/*
    ```````````````
    SPECIAL - PF DRIVE 

    ``````````````
*/
export const ToDrive = '/drive';

/*
    ```````````````
    SPECIAL - PF REPORT 

    ``````````````
*/
export const ToReports = '/reports';

/*
    ```````````````
    SPECIAL - PF PROJECT 

    ``````````````
*/
export const ToProject = '/project';

/*
    ```````````````
    SPECIAL - PF ARCHIVE

    ``````````````
*/
export const ToArchive = '/archive';

/*
    ```````````````
    SPECIAL - TASK 

    ``````````````
*/

export const ToTask = '/task';

/*
    ```````````````
    TIME MANAGEMENT - Attendance 

    ``````````````
*/

export const ToAttendance = '/attendance';
export const ToAttendanceSetting = '/attendance/setting';
export const ToAttendanceListRequest = '/attendance/list-request';
export const ToAttendanceReportAdmin = '/attendance/reports';

/*
    ```````````````
    TIME MANAGEMENT - Time Off 

    ``````````````
*/

export const ToTimeOff = '/time-off';
export const ToTimeOffSetting = '/time-off/setting';
export const ToTimeOffCategory = '/time-off/category';

/*
    ```````````````
    TIME MANAGEMENT - Overtime 

    ``````````````
*/

export const ToOvertime = '/overtime';
export const ToOvertimeSetting = '/overtime/setting';

/*
    ```````````````
    TIME MANAGEMENT - Exception 

    ``````````````
*/

export const ToException = '/exception';

/*
    ```````````````
    AD - Attendance Dashboard 

    ``````````````
*/

export const ToAttendanceDashboard = '/attendance-dashboard';
export const ToMyAttendance = '/my-attendance';
export const ToHistoryClockInOut = '/history-clock-in-out';
export const ToHistoryCheckInOut = '/history-check-in-out';
export const ToCheckInCheckOut = '/check-in-check-out';
export const ToAttendanceReport = '/attendance-report';
export const ToTeam = '/attendance-dashboard/team';
export const ToInbox = '/attendance-dashboard/inbox';
export const ToProfile = '/attendance-dashboard/profile';
export const ToProfilePresence = '/attendance-dashboard/profile/presence';
export const ToProfileAbsence = '/attendance-dashboard/profile/absence';
export const ToProfileChangePin = '/attendance-dashboard/profile/change-pin';
export const ToMySchedule = '/my-schedule';
export const ToAttendanceTimeOff = '/attendance-time-off';
export const ToAttendanceOvertime = '/attendance-overtime';
export const ToRequestAttendance = '/request-attendance';

/*
    ```````````````````
    HOME - LANDING PAGE 

    ```````````````````
*/

export const ToLandingPage = '/*';
