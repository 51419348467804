import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import ContextAttendanceReportAdmin from "../../../Context/ContextAttendanceReportAdmin";

const TotalRecords = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.title555} variant="subtitle2">
        Total :{" "}
        <span
          style={{
            backgroundColor: "#aaa",
            color: "#fff",
            padding: "0 5px",
            borderRadius: 4,
          }}>
          <b>{context.dataSettingDetail.paging.count}</b>
        </span>{" "}
        Records
      </Typography>
    </Box>
  );
};

export default TotalRecords;
