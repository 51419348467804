import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
  IconButton,
  Select,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Radio,
  Chip,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
} from "@material-ui/core";

const DialogCreateSubProject = ({
  classes,
  openCreateSubProject,
  setOpenCreateSubProject,
}) => {
  // conditional
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open={openCreateSubProject}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{}}>
        <Box p={2} pb={0}>
          <Typography variant="h5" className={classes.title333}>
            Create Sub Project
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box p={2} pt={0}></Box>
      </DialogContent>

      <DialogActions>
        <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={() => setOpenCreateSubProject(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            size="small"
            variant={loading ? "contained" : "outlined"}
            className={classes.button1}
            disabled={loading}
            // onClick={handleCreateProject}
          >
            {loading ? "Creating Project..." : "Create"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateSubProject;
