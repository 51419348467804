import React, { useState, useContext } from "react";
import {
  Tooltip,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import classNames from "classnames";
import moment from "moment";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextTask from "../../Context/ContextTask";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";

const AnchorOwner = ({ classes, row, ownerCollections }) => {
  const { setTriggerTodo } = useContext(ContextTask);
  const completed = row.toggle.completed === "2" ? true : false;
  const dataUpdate = {
    Todolist: {
      title: row.name.title,
      description: row.name.description !== null ? row.name.description : "",
      remarks: row.name.remarks !== null ? row.name.remarks : "",
      owner_id: row.name.owner_id,
      status: row.status !== null ? row.status.id : null,
      priority: row.priority !== null ? row.priority.id : null,
      due_date: moment(row.due_date).format("YYYY-MM-DD HH:mm:ss"),
      start_date: moment(row.start_date).format("YYYY-MM-DD HH:mm:ss"),
      tags: row.name.tags !== null ? row.name.tags : "",
      actual_result: row.result.actual !== null ? Number(row.result.actual) : 0,
      target_result: Number(row.result.target),
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [loadOwner, setLoadOwner] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleChooseOwner = (e, owner) => {
    setLoadOwner(true);

    const data = {
      Todolist: {
        ...dataUpdate.Todolist,
        owner_id: owner.id,
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${row.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTodo(Math.floor(Math.random() * 100));
          setLoadOwner(false);
          handleClose();
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadOwner(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <div>
      <Tooltip
        title={row.owner.full_name}
        onClick={completed ? null : handleShow}
      >
        {row.owner.photo_url !== null ? (
          <Box className={classes.justHover} display="flex" alignItems="center">
            <ImageSmallUser item={row.owner.photo_url} index={1} />
            <span style={{ color: "#555", fontSize: 12, marginLeft: 5 }}>
              {TruncateTextCustom(row.owner.full_name, 5)}
            </span>
          </Box>
        ) : (
          <Box className={classes.justHover} display="flex" alignItems="center">
            <AccountCircleIcon
              className={classes.justHover}
              style={{ color: "#888" }}
            />
            <span style={{ color: "#555", fontSize: 12, marginLeft: 5 }}>
              {TruncateTextCustom(row.owner.full_name, 5)}
            </span>
          </Box>
        )}
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <Box p={2} width={300}>
                  <Typography className={classes.title555} variant="subtitle2">
                    <b>Owner</b>
                  </Typography>
                  <Box mt={2}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={ownerCollections}
                      getOptionLabel={(option) =>
                        `${option.member.first_name} ${option.member.last_name}`
                      }
                      noOptionsText="Tidak ada owner"
                      onChange={(e, owner) => handleChooseOwner(e, owner)}
                      popupIcon={
                        <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ fontSize: 13 }}
                          fullWidth
                          label="Search owner"
                          variant="standard"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadOwner ? (
                                  <CircularProgress color="inherit" size={14} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default AnchorOwner;
