import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, CircularProgress } from "@material-ui/core";

import logoPerformate from "../../../assets/images/performate-03.png";

import logoMadinahCare from "../../../assets/images/landing/logo-madinah-care.PNG";
import logoMadinahFlash from "../../../assets/images/landing/logo-madinah-flash.PNG";
import logoMadinahGroup from "../../../assets/images/landing/logo-madinah-group.PNG";
import logoMadinahMultimedia from "../../../assets/images/landing/logo-madinah-multimedia.PNG";
import logoMadinahStore from "../../../assets/images/landing/logo-madinah-store.PNG";

import axiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";

import DialogSuccessDemoSchedule from "./components/DialogSuccessDemoSchedule";
import DialogError from "../../../components/DialogError";

import "../Style/landing.css";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  alternateBackground: {
    backgroundColor: "#f5f5f5",
  },
  textField: {
    width: "100%",
    margin: "6px 0px 0px 0px",
  },
  buttonSend: {
    width: "100%",
    marginTop: "30px",
  },
  boxForm: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    padding: "30px",
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Roboto",
  },
  textPro1: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    fontFamily: "Roboto",
    color: "#333",
    fontSize: 16,
    fontWeight: "600",
    paddingLeft: 15,
    paddingRight: 15,
  },
  textPro2: {
    height: theme.spacing(13.5),
    fontFamily: "Roboto",
    color: "#222",
    fontSize: 16,
    fontWeight: "700",
    paddingLeft: 25,
    paddingRight: 25,
  },
  buttonNext: {
    backgroundColor: "#d1354a",
    color: "#fff",
    minWidth: 305,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 15,
    marginRight: 15,
    fontSize: 15,
    fontWeight: "600",
    fontFamily: "Roboto",
    height: 50,
    textTransform: "Capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#c1272d",
    },
  },
});

const useStyles = makeStyles(styles);

const DemoSchedule = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  const fullnameRef = useRef("");
  const mobileRef = useRef("");
  const first_nameRef = useRef("");
  const last_nameRef = useRef("");

  const [loading, setLoading] = useState(false);
  const [dialogSuccessDemo, setDialogSuccessDemo] = useState(false);
  const [demoData, setDemoData] = useState({
    name: "",
    group_name: "",
    email: "",
    phone: "",
    description: "",
  });

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDemoChange = (name, value) => {
    setDemoData({ ...demoData, [name]: value });
  };

  const handleSubmitDemo = (e) => {
    e.preventDefault();

    const data = {
      DemoSchedule: {
        name: demoData.name,
        group_name: demoData.group_name,
        email: demoData.email,
        phone: demoData.phone,
        description: demoData.description,
        fullname: fullnameRef.current.value,
        mobile: mobileRef.current.value,
        first_name: first_nameRef.current.value,
        last_name: last_nameRef.current.value,
      },
    };
    // console.log("submit DemDat", data);

    setLoading(true);

    axiosConfig
      .post(`${URL_API}/landing-page/demo-schedule`, data)
      .then((response) => {
        // console.log("Response Demo Ori", response);

        if (response.status === 200) {
          setDialogSuccessDemo(true);
        }

        setDemoData({
          name: "",
          group_name: "",
          email: "",
          phone: "",
          description: "",
        });

        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <div>
      <section style={{ paddingTop: "100px", textAlign: "center" }}>
        <div className="box-form" style={{ width: "40%", margin: "0 auto" }}>
          <h2>Jadwalkan demo produk</h2>
          <p>
            Tim kami dengan senang hati akan membantu menjawab pertanyaan anda
            dan menunjukan bagaimana produk kami bekerja
          </p>
          <br />
          <div
            className={classes.boxForm}
            style={{ width: "70%", margin: "0 auto", textAlign: "left" }}
          >
            <span>Nama Lengkap</span>
            <TextField
              id="outlined-email-input"
              label="Tulis Nama Lengkap"
              className={classes.textField}
              type="text"
              name="name"
              autoComplete="text"
              margin="normal"
              variant="outlined"
              inputProps={{ className: classes.input }} //==> WORK
              value={demoData.name}
              onChange={(e) => handleDemoChange(e.target.name, e.target.value)}
              // helperText={isErrorEmailValidation == true ? MessageErrorValidationEmail : ''} //* ==> Waiting request from pak boz !
              // error={isErrorEmailValidation} //* ==> Waiting request from pak boz !
              //helperText='Email yang Anda masukkan akan di pakai untuk akun Superadmin'
            />
            <br />
            <br />
            <span>Nama Perusahaan/Organisasi</span>
            <TextField
              id="outlined-email-input"
              label="Tulis Nama Organisasi"
              className={classes.textField}
              type="text"
              name="group_name"
              autoComplete="text"
              margin="normal"
              variant="outlined"
              inputProps={{ className: classes.input }} //==> WORK
              value={demoData.group_name}
              onChange={(e) => handleDemoChange(e.target.name, e.target.value)}
              // helperText={isErrorEmailValidation == true ? MessageErrorValidationEmail : ''} //* ==> Waiting request from pak boz !
              // error={isErrorEmailValidation} //* ==> Waiting request from pak boz !
              //helperText='Email yang Anda masukkan akan di pakai untuk akun Superadmin'
            />
            <br />
            <br />
            <span>Email</span>
            <TextField
              id="outlined-email-input"
              label="Tulis Email"
              className={classes.textField}
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              inputProps={{ className: classes.input }} //==> WORK
              value={demoData.email}
              onChange={(e) => handleDemoChange(e.target.name, e.target.value)}
              // helperText={isErrorEmailValidation == true ? MessageErrorValidationEmail : ''} //* ==> Waiting request from pak boz !
              // error={isErrorEmailValidation} //* ==> Waiting request from pak boz !
              //helperText='Email yang Anda masukkan akan di pakai untuk akun Superadmin'
            />
            <br />
            <br />
            <span>Nomor Telepon</span>
            <TextField
              id="outlined-email-input"
              label="Tulis Nomor Telepon"
              className={classes.textField}
              type="text"
              name="phone"
              autoComplete="text"
              margin="normal"
              variant="outlined"
              inputProps={{ className: classes.input }} //==> WORK
              value={demoData.phone}
              onChange={(e) => handleDemoChange(e.target.name, e.target.value)}
              // helperText={isErrorEmailValidation == true ? MessageErrorValidationEmail : ''} //* ==> Waiting request from pak boz !
              // error={isErrorEmailValidation} //* ==> Waiting request from pak boz !
              //helperText='Email yang Anda masukkan akan di pakai untuk akun Superadmin'
            />

            <br />
            <br />
            <span>Apa yang dapat kami bantu ?</span>
            <TextField
              id="outlined-email-input"
              label="Tulis keperluan anda"
              className={classes.textField}
              type="text"
              name="description"
              autoComplete="text"
              margin="normal"
              variant="outlined"
              multiline
              rows="3"
              inputProps={{ className: classes.input }} //==> WORK
              value={demoData.description}
              onChange={(e) => handleDemoChange(e.target.name, e.target.value)}
              // helperText={isErrorEmailValidation == true ? MessageErrorValidationEmail : ''} //* ==> Waiting request from pak boz !
              // error={isErrorEmailValidation} //* ==> Waiting request from pak boz !
              //helperText='Email yang Anda masukkan akan di pakai untuk akun Superadmin'
            />

            <input
              type="hidden"
              id="fullname"
              name="fullname"
              ref={fullnameRef}
            />
            <input type="hidden" id="mobile" name="mobile" ref={mobileRef} />
            <input
              type="hidden"
              id="first_name"
              name="first_name"
              ref={first_nameRef}
            />
            <input
              type="hidden"
              id="last_name"
              name="last_name"
              ref={last_nameRef}
            />

            <br />
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonSend}
              onClick={handleSubmitDemo}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "#fff", paddingTop: 3, paddingBottom: 3 }}
                  size={17}
                />
              ) : (
                "Kirim"
              )}
            </Button>
          </div>
        </div>
        <br />
        <br />
      </section>

      <section
        className={classes.alternateBackground}
        style={{ paddingTop: "0px", textAlign: "center" }}
      >
        <br />
        <br />
        <h3 className="text-client-title">Klien dan Partner</h3> <br />
        <div className="box-client" style={{ width: "80%", margin: "0 auto" }}>
          <div>
            <img src={logoMadinahGroup} width="50%" />
          </div>

          <div>
            <img src={logoMadinahStore} width="50%" />
          </div>

          <div>
            <img src={logoMadinahFlash} width="100%" />
          </div>

          <div>
            <img src={logoMadinahCare} width="70%" />
          </div>

          <div>
            <img src={logoMadinahMultimedia} width="80%" />
          </div>
        </div>
        <br />
        <br />
      </section>

      <DialogSuccessDemoSchedule
        classes={classes}
        dialogSuccessDemo={dialogSuccessDemo}
        setDialogSuccessDemo={setDialogSuccessDemo}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default DemoSchedule;
