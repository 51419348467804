import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const CircularBarNA = () => {
  return (
    <CircularProgressbar
      value={100}
      text="N/A"
      styles={buildStyles({
        pathColor: "#f0f0f0",
        textColor: "black",
        pathTransition: "none",
        textSize: 18,
      })}
    />
  );
};

export default CircularBarNA;
