import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

import { useDropzone } from "react-dropzone";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import axios from "axios";

// import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
// import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
// import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
// import word_icon from "../../../../../assets/images/icons_file/Word.png";
// import video_icon from "../../../../../assets/images/icons_file/Video.png";
// import text_icon from "../../../../../assets/images/icons_file/Text.png";

import FormatBytes from "../../../../../utilities/FormatBytes";
import ListItemAttachment from "./ListItemAttachment";
import handleError from "../../../../Report/components/global/handleError";
import DialogError from "../../../../../components/DialogError";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 400],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#3054CA",
  },
}))(LinearProgress);

const Attachment = ({
  classes,
  todo,
  triggerAttachment,
  onTriggerAttachment,
  status403,
  completed,
}) => {
  const maxSize = 31457280;

  const [toggleAttachment, setToggleAttachment] = useState(true);
  const [files, setFiles] = useState([]);
  const [fileCollection, setFileCollection] = useState([]);
  const [progress, setProgress] = useState(0);
  const [percentage, setPercentage] = useState("0");
  const [cancelUpload, setCancelUpload] = useState(null);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    AxiosConfig.get(`${URL_API}/todolist/${todo.id}/attachment`)
      .then((res) => {
        const result = res.data.data;
        if (res.status === 200) {
          setFileCollection(result);
        }
      })
      .catch((err) => {
        // console.log("Err", err);
      });
  }, [triggerAttachment]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      setFiles((prev) => {
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        return prev.concat(acceptedFiles);
      });

      // // console.log("ACEP ACEP", acceptedFiles);
      fileRejections.forEach((file) => {
        // File is too large
        if (file.size > maxSize) {
          alert("Ukuran File tidak boleh melebihi 30 MB");
        }

        // Rejected File
        if (file) {
          // // console.log("file reject", file);
          alert(`File ${file.name}, tidak mendukung untuk di-upload!`);
        }
      });

      // AUTO UPLOAD FILE(S)
      setProgress(0);
      setPercentage("0");

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      setCancelUpload(source);

      return Promise.all(
        acceptedFiles.length > 0 &&
          acceptedFiles.map((file) => {
            setLoading(true);

            const dataFile = new FormData();

            dataFile.append("todolistAttachment[content]", file);
            dataFile.append("todolistAttachment[description]", file.name);
            dataFile.append(
              "todolistAttachment[attachment_category_id]",
              "c9441e2a-972d-47da-a07c-a1eb5215de05"
            );

            const config = {
              onUploadProgress: (eventProgress) => {
                const { loaded, total } = eventProgress;
                let percent = Math.round((loaded * 100) / total);

                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

                if (percent <= 100) {
                  setPercentage(percent);
                  setProgress((prev) => (prev = percent));
                }
              },
              cancelToken: source.token,
            };

            return AxiosConfig.post(
              `${URL_API}/todolist/${todo.id}/attachment`,
              dataFile,
              config
            );
          })
      )
        .then((res) => {
          // console.log("Upload File TDL", res);

          setFiles([]);
          onTriggerAttachment(Math.floor(Math.random() * 100));
          setProgress(0);
          setPercentage("0");

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);

          setProgress(0);
          setPercentage("0");
          setFiles([]);
          onTriggerAttachment(null);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    },
    maxSize: maxSize,
    accept:
      ".txt, .xlsx, .xls, .doc, .docx, .pdf, .ppt, .pptx, text/plain, text/csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,	application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpeg, image/png, image/gif, audio/mpeg, audio/wav, video/mp4, video/x-m4v, video/mpeg, video/ms-asf, video/x-ms-wmv, video/quicktime, video/x-msvideo, application/x-shockwave-flash, application/xml, text/xml",
  });

  const handleCancelUpload = () => {
    if (window.confirm("Cancel upload file(s) ?")) {
      cancelUpload.cancel();
    }
  };

  return (
    <Paper className={classes.dataArea}>
      <Box p={2} pt={1} display="flex" alignItems="center">
        <Typography
          variant="subtitile2"
          className={classes.title333}
          style={{ marginRight: 5 }}
        >
          <b>Attachment</b>
        </Typography>
        {toggleAttachment ? (
          <IconButton
            size="small"
            onClick={() => setToggleAttachment(!toggleAttachment)}
          >
            <KeyboardArrowUpIcon style={{ color: "#C8203C" }} />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            onClick={() => setToggleAttachment(!toggleAttachment)}
          >
            <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
          </IconButton>
        )}
      </Box>

      {toggleAttachment && (
        <Box p={2} pt={0}>
          <div className={classes.uploadContainer}>
            <div
              {...getRootProps()}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {completed ? null : <input {...getInputProps()} />}
              <CloudUploadOutlinedIcon
                fontSize="large"
                style={{ color: "#E0E0E0" }}
              />
              <span className={classes.title}>
                Drag 'n' drop some files here
              </span>
              <span>or</span>
              <Button
                size="small"
                variant="outlined"
                className={classes.uploadBtn}
                disabled={status403 || completed}
              >
                Choose File
              </Button>
            </div>
          </div>

          {loading && (
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >{`${percentage}%`}</Typography>
              </Box>
              <Button
                size="small"
                className={classes.btnCancelUpload}
                onClick={handleCancelUpload}
              >
                Cancel
              </Button>
            </Box>
          )}

          <List dense>
            {files.map((file) => {
              return (
                <ListItem key={file.name}>
                  <ListItemText
                    primary={
                      <Typography
                        className={classes.title555}
                        variant="subtitle2"
                      >
                        {file.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        className={classes.title888}
                        variant="caption"
                      >
                        {FormatBytes(Number(file.size))}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>

          <Typography className={classes.title} variant="caption">
            <b>
              Files ({fileCollection.length}
              {fileCollection.length === 5 ? " - max" : null})
            </b>
          </Typography>

          <Box mt={2}>
            {fileCollection.length > 0 &&
              fileCollection.map((file, i) => {
                return (
                  <ListItemAttachment
                    key={i}
                    classes={classes}
                    file={file}
                    setTriggerAttachment={onTriggerAttachment}
                    completed={completed}
                  />
                );
              })}
          </Box>
        </Box>
      )}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default Attachment;
