import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SubjectRoundedIcon from "@material-ui/icons/SubjectRounded";

import moment from "moment";

import ContextTask from "../../Context/ContextTask";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import ResultBarDetail from "../global/ResultBarDetail";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import icon_high from "../../../../assets/images/SVG/icon_high.svg";
import icon_medium from "../../../../assets/images/SVG/icon_medium.svg";
import icon_low from "../../../../assets/images/SVG/icon_low.svg";

import Description from "./detail/Description";
import TaskInformation from "./detail/TaskInformation";
import Notes from "./detail/Notes";
import Attachment from "./detail/Attachment";
import Checklist from "./detail/Checklist";

import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
import { DRAWER_OPEN } from "../../Context/types";
import {
  statusCollections,
  statusWithoutDone,
  priorityCollections,
} from "../global/statsprio";

const TodoDetails = ({ classes }) => {
  const {
    todoState,
    setTriggerTodo,
    todoDispatch,
    triggerAttachment,
    setTriggerAttachment,
  } = useContext(ContextTask);
  const todo = todoState.todoDetails;
  const checked = todo.is_complete.id === "2" ? true : false;
  const targetVal = Number(todo.target_result);
  const actualVal =
    todo.actual_result === null ? 0 : Number(todo.actual_result);
  const percentage = (actualVal / targetVal) * 100;
  const todayB4Start = moment(new Date()).isAfter(todo?.start_date, "day");

  let statusColor;
  let icon;

  switch (todo.status && todo.status.id) {
    case "TO DO":
      statusColor = "#777777";
      break;
    case "DOING":
      statusColor = "#4194AC";
      break;
    case "DONE":
      statusColor = "#1E874D";
      break;
    case "ON HOLD":
      statusColor = "#B68812";
      break;
    case "WAITING":
      statusColor = "#92739F";
      break;
    default:
      statusColor = "#444444";
  }

  switch (todo.priority && todo.priority.id) {
    case "HIGH":
      icon = icon_high;
      break;
    case "MEDIUM":
      icon = icon_medium;
      break;
    case "LOW":
      icon = icon_low;
      break;
    default:
      icon = null;
  }

  // =================================================================
  // ~~~ STATE ~~~
  // =================================================================
  const [ownerCollection, setOwnerCollection] = useState([]);
  // GET Values for Update
  const [title, setTitle] = useState(
    todo.title !== null ? todo.title : "Add title"
  );
  const [description, setDescription] = useState(
    todo.description !== null ? todo.description : "no description"
  );
  const [remarks, setRemarks] = useState(
    todo.remarks !== null ? todo.remarks : "Write your notes..."
  );
  const [ownerId, setOwnerId] = useState(todo.owner);
  const [statusId, setStatusId] = useState(
    todo.status !== null ? todo.status.id : ""
  );
  const [priorityId, setPriorityId] = useState(
    todo.priority !== null ? todo.priority.id : ""
  );
  const [selectedDueDate, setSelectedDueDate] = useState(todo.due_date);
  const [startDate, setStartDate] = useState(todo.start_date);
  const [tags, setTags] = useState(todo.tags !== null ? todo.tags : "");
  const [actual, setActual] = useState(Number(todo.actual_result));
  const [target, setTarget] = useState(Number(todo.target_result));
  // conditional
  const [loading, setLoading] = useState(false);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [status403, setStatus403] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // =================================================================
  // ~~~ SIDE EFFECT ~~~
  // =================================================================
  useEffect(() => {
    if (todoState.drawer) {
      setLoading(true);

      AxiosConfig.get(`${URL_API}/todolist/${todo.id}/update`)
        .then((res) => {
          const result = res.data.data;
          // console.log("TODO Update form", result);
          if (res.status === 200) {
            setOwnerCollection(result.ownerCollections);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          // console.log("ERR", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setStatus403(true);
            }
          }

          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [todoState.drawer]);

  // =================================================================
  // ~~~ FUNCTION || METHODS ~~~
  // =================================================================
  const handleUpdateTodo = () => {
    setLoadUpdate(true);

    const isDoing = Number(actual) > 0 && Number(target) > 0;

    const data = {
      Todolist: {
        title,
        description,
        remarks,
        owner_id: ownerId?.id,
        status: isDoing ? "DOING" : statusId,
        priority: priorityId,
        due_date: moment(selectedDueDate).format("YYYY-MM-DD HH:mm:ss"),
        start_date: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        tags,
        actual_result: Number(actual),
        target_result: Number(target),
        task_group_id: null,
        project_id: null,
        custom_phase_id: null,
      },
    };

    // // console.log("TDL Update", data);

    AxiosConfig.put(`${URL_API}/todolist/${todo.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          todoDispatch({
            type: DRAWER_OPEN,
            payload: {
              drawer: false,
            },
          });

          setTriggerTodo(Math.floor(Math.random() * 100));
          setLoadUpdate(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadUpdate(false);
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Box minWidth={550}>
      <Box p={3}>
        <TextField
          type="text"
          variant="standard"
          fullWidth
          // style={{ width: 300 }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          InputProps={{
            style: { fontSize: 20, color: "#555", fontWeight: "bold" },
            disableUnderline: true,
          }}
          disabled={status403}
        />
      </Box>

      <Box
        p={3}
        pt={6}
        bgcolor="#FCFCFC"
        boxShadow="0px 4px 10px 0px rgba(0,0,0,0.1) inset"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="subtitile2" className={classes.title555}>
            Task Status is
          </Typography>
          <Box mx={0.5} />
          <Typography variant="subtitile2" style={{ color: statusColor }}>
            <b>{todo.status !== null ? todo.status.name : "Not Set"}</b>
          </Typography>

          <Box mx={0.5} />
          <Divider orientation="vertical" flexItem />
          <Box mx={0.5} />

          <Typography variant="subtitile2" className={classes.title555}>
            Completed
          </Typography>
          <Box mx={0.5} />
          {checked ? (
            <CheckCircleRoundedIcon style={{ color: "#2ecc71" }} />
          ) : (
            <CancelRoundedIcon style={{ color: "#d64253" }} />
          )}
        </Box>

        <Box mt={2}>
          <Box mb={0.5} display="flex" justifyContent="space-between">
            <Typography variant="caption" className={classes.title555}>
              {FormatDecimal(percentage)}%
            </Typography>
            <Typography variant="caption" className={classes.title555}>
              {actualVal} / {targetVal}
            </Typography>
          </Box>
          <ResultBarDetail percentage={percentage} color={statusColor} />
        </Box>

        <Box mt={4}>
          <Description
            classes={classes}
            description={description}
            setDescription={setDescription}
            status403={status403}
          />
        </Box>

        <Box mt={4}>
          <TaskInformation
            classes={classes}
            icon={icon}
            statusColor={statusColor}
            ownerCollection={ownerCollection}
            loading={loading}
            ownerId={ownerId}
            setOwnerId={setOwnerId}
            statusId={statusId}
            setStatusId={setStatusId}
            priorityId={priorityId}
            setPriorityId={setPriorityId}
            selectedDueDate={selectedDueDate}
            setSelectedDueDate={setSelectedDueDate}
            startDate={startDate}
            setStartDate={setStartDate}
            tags={tags}
            setTags={setTags}
            actual={actual}
            setActual={setActual}
            target={target}
            setTarget={setTarget}
            statusCollections={statusCollections}
            priorityCollections={priorityCollections}
            status403={status403}
            projectType="TEAM"
          />
        </Box>

        <Box mt={4}>
          <Notes classes={classes} remarks={remarks} setRemarks={setRemarks} />
        </Box>

        <Box mt={4}>
          <Attachment
            classes={classes}
            todo={todo}
            triggerAttachment={triggerAttachment}
            onTriggerAttachment={setTriggerAttachment}
            status403={status403}
          />
        </Box>

        {/* <Box mt={4}>
          <Checklist classes={classes} todo={todo} />
        </Box> */}

        <Box mt={6} display="flex" justifyContent="flex-end">
          <Button
            size="small"
            variant={
              loadUpdate || status403 || checked || actual > target
                ? "contained"
                : "outlined"
            }
            disabled={loadUpdate || status403 || checked || actual > target}
            className={classes.button1}
            onClick={handleUpdateTodo}
          >
            {loadUpdate ? "Updating Todo..." : "Update"}
          </Button>
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default TodoDetails;
