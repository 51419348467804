import React, { useReducer, useState } from "react";
import ContextReports from "./ContextReports";

import { filterReducer, initialFilter } from "./reducers/filterReducer";
import { reportReducer, initialReport } from "./reducers/reportReducer";
import { tableReducer, initialTable } from "./reducers/tableReducer";
import { summaryReducer, initialSummary } from "./reducers/summaryReducer";
import { tableSmrReducer, initialTableSmr } from "./reducers/tableSmrReducer";
import { initialBar, barReducer } from "./reducers/barReducer";
import { initialPie, pieReducer } from "./reducers/pieReducer";

const ContextGlobalReports = (props) => {
  // ========== |REDUCER| ==========
  const [tableState, tableDispatch] = useReducer(tableReducer, initialTable);
  const [filterState, filterDispatch] = useReducer(
    filterReducer,
    initialFilter
  );
  const [reportState, reportDispatch] = useReducer(
    reportReducer,
    initialReport
  );
  const [summaryState, summaryDispatch] = useReducer(
    summaryReducer,
    initialSummary
  );
  const [tableSmrState, tableSmrDispatch] = useReducer(
    tableSmrReducer,
    initialTableSmr
  );
  const [barState, barDispatch] = useReducer(barReducer, initialBar);
  const [pieState, pieDispatch] = useReducer(pieReducer, initialPie);

  // ========== |STATE| ==========
  const [trigerReset, setTrigerReset] = useState(null);

  return (
    <ContextReports.Provider
      value={{
        filterState,
        filterDispatch,
        tableState,
        tableDispatch,
        reportState,
        reportDispatch,
        summaryState,
        summaryDispatch,
        tableSmrState,
        tableSmrDispatch,
        barState,
        barDispatch,
        pieState,
        pieDispatch,
        trigerReset,
        setTrigerReset,
      }}>
      {props.children}
    </ContextReports.Provider>
  );
};

export default ContextGlobalReports;
