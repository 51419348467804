import React, { useEffect, useState } from "react";
import {
  Fade,
  Chip,
  Typography,
  Avatar,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LinkOffRoundedIcon from "@material-ui/icons/LinkOffRounded";

import TruncateTextShortSuperPendek from "../../../../../utilities/TruncateTextShortSuperPendek";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";

import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../../assets/images/icons_file/Text.png";

import DialogPreviewImage from "../../../../Drive/View/components/DialogPreviewImage";
import DialogPreviewVideo from "../../../../Drive/View/components/DialogPreviewVideo";
import DialogPreviewPDF from "../../../../Drive/View/components/DialogPreviewPDF";
import DialogPreviewDocument from "../../../../Drive/View/components/DialogPreviewDocument";
import DialogPreviewText from "../../../../Drive/View/components/DialogPreviewText";

import DialogDeleteEvidenceMA from "../DialogDeleteEvidenceMA";

function DriveItem({
  i,
  tile,
  classes,
  showFade,
  deleteAllowed,
  listPictState,
  setListPictState,
  setSuccessCreateMA,
}) {
  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [openDialogPreviewImage, setOpenDialogPreviewImage] = useState(false);
  const [openDialogPreviewVideo, setOpenDialogPreviewVideo] = useState(false);
  const [openDialogPreviewDocument, setOpenDialogPreviewDocument] =
    useState(false);
  const [openDialogPreviewText, setOpenDialogPreviewText] = useState(false);
  const [openDialogDeleteEvidence, setOpenDialogDeleteEvidence] =
    useState(false);
  const [openDialogPreviewPDF, setOpenDialogPreviewPDF] = useState(false);
  const [response_type, setResponseType] = useState(null);
  const [response404, setResponse404] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`${URL_API}/drive/file/${tile.content}?mode=thumbnail`, {
        responseType: "blob",
      })
      .then((response) => {
        // // console.log("Response Thumbnail", response);

        if (response.status === 200) {
          // // console.log("response.data.type", response.data.type);

          setResponseType(response.data.type);
          setImage(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error.response);
        setLoading(false);

        if (error.response !== undefined) {
          if (error.response.status === 404) {
            setResponse404(true);
          }
        }
      });
  }, []);

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([image], { type: "image/*" });

  let source = "";
  if (response_type !== null) {
    if (response_type.includes("image")) {
      source = URL.createObjectURL(blobUrlImage);
    } else if (response_type === "application/pdf") {
      source = pdf_icon;
    } else if (response_type.includes("video")) {
      source = video_icon;
    } else if (
      response_type === "application/msword" ||
      response_type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      source = word_icon;
    } else if (
      response_type === "application/vnd.ms-powerpoint" ||
      response_type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      source = ppt_icon;
    } else if (
      response_type === "application/vnd.ms-excel" ||
      response_type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      source = excel_icon;
    } else if (response_type === "text/plain") {
      source = text_icon;
    }
  }

  const handleClick = () => {
    if (response_type !== null) {
      if (response_type.includes("image")) {
        setOpenDialogPreviewImage(true);
      } else if (
        response_type === "application/vnd.ms-excel" ||
        response_type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        response_type === "application/vnd.ms-powerpoint" ||
        response_type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        response_type === "application/msword" ||
        response_type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setOpenDialogPreviewDocument(true);
      } else if (response_type === "application/pdf") {
        setOpenDialogPreviewPDF(true);
      } else if (response_type.includes("video")) {
        setOpenDialogPreviewVideo(true);
      } else if (response_type === "text/plain") {
        setOpenDialogPreviewText(true);
      }
    }
  };

  return (
    <span key={i}>
      <Fade in={showFade}>
        <Tooltip
          title={response404 ? "File di Drive sudah tidak ada" : tile.label}
        >
          <Chip
            avatar={
              loading ? (
                <CircularProgress size={12} style={{ color: "#d1354a" }} />
              ) : !response404 ? (
                <Avatar
                  alt={tile.label}
                  src={source}
                  style={{
                    borderRadius: 5,
                    width: 32,
                    border: "2px solid #f2f2f2",
                    margin: 3,
                  }}
                />
              ) : null
            }
            icon={
              response404 ? (
                <LinkOffRoundedIcon style={{ color: "#d35400" }} />
              ) : null
            }
            label={
              <Typography variant="caption" className={classes.title}>
                {TruncateTextShortSuperPendek(tile.label)}
              </Typography>
            }
            style={{
              margin: 3,
              textDecorationLine: response404 && "line-through",
            }}
            onClick={!response404 ? handleClick : null}
            onDelete={
              deleteAllowed ? () => setOpenDialogDeleteEvidence(true) : null
            }
            deleteIcon={
              deleteAllowed ? (
                <HighlightOffIcon
                  fontSize="small"
                  style={{ color: "#d1354a" }}
                />
              ) : null
            }
          />
        </Tooltip>
      </Fade>

      <DialogPreviewImage
        openDialogPreviewImage={openDialogPreviewImage}
        setOpenDialogPreviewImage={setOpenDialogPreviewImage}
        itemType="image/jpeg"
        itemId={tile.content}
        itemName={tile.label}
        origin="EvidenceDrive"
      />

      <DialogPreviewVideo
        openDialogPreviewVideo={openDialogPreviewVideo}
        setOpenDialogPreviewVideo={setOpenDialogPreviewVideo}
        itemId={tile.content}
        itemName={tile.label}
        origin="EvidenceDrive"
      />

      <DialogPreviewDocument
        openDialogPreviewDocument={openDialogPreviewDocument}
        setOpenDialogPreviewDocument={setOpenDialogPreviewDocument}
        itemId={tile.content}
        itemName={tile.label}
        origin="EvidenceDrive"
      />

      <DialogPreviewPDF
        openDialogPreviewPDF={openDialogPreviewPDF}
        setOpenDialogPreviewPDF={setOpenDialogPreviewPDF}
        itemId={tile.content}
        itemName={tile.label}
        origin="EvidenceDrive"
      />

      <DialogPreviewText
        openDialogPreviewText={openDialogPreviewText}
        setOpenDialogPreviewText={setOpenDialogPreviewText}
        itemId={tile.content}
        itemName={tile.label}
        classes={classes}
        origin="EvidenceDrive"
      />

      <DialogDeleteEvidenceMA
        openDialogDeleteEvidence={openDialogDeleteEvidence}
        setOpenDialogDeleteEvidence={setOpenDialogDeleteEvidence}
        item={tile}
        classes={classes}
        listPictState={listPictState}
        setListPictState={setListPictState}
        setSuccessCreateMA={setSuccessCreateMA}
      />
    </span>
  );
}

export default DriveItem;
