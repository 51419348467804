import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
  DialogActions,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";

const DialogErrorImport = ({
  classes,
  isOpenDialogError,
  setOpenDialogError,
  textErrorInformation,
  errorStatus,
  listError,
}) => {
  // console.log("List Err", listError);

  return (
    <Dialog
      open={isOpenDialogError}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Terjadi kesalahan</b>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box>
          {textErrorInformation !== "" && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {textErrorInformation}
            </Alert>
          )}
        </Box>

        <Box>
          {listError?.length > 0 &&
            listError?.map((task, i) => (
              <Alert
                severity={task.length > 0 ? "warning" : "success"}
                key={i}
                style={{ marginBottom: 5 }}
              >
                <AlertTitle>
                  <Typography variant="subtitle2">
                    <b>Project Task {i + 1}</b>
                  </Typography>
                </AlertTitle>
                {task?.length > 0 &&
                  task?.map((item, j) => (
                    <Box key={j} display="flex" alignItems="center">
                      <ClearRoundedIcon
                        style={{ fontSize: 13, color: "#DE6775" }}
                      />
                      <Box mx={0.5} />
                      <Typography variant="caption">
                        {item?.description}
                      </Typography>
                    </Box>
                  ))}
              </Alert>
            ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={() => setOpenDialogError(false)}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogErrorImport;
