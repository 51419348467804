import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Slide,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

import moment from 'moment';

import { A } from 'hookrouter';

import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

import CircleInPercentNEW from '../../../../components/CircleInPercentNEW';
import CircleInPercentForExsponent from '../../../../components/CircleInPercentForExsponent';

import ImageSmallUser from '../../../../components/ImageSmallUser';
import TruncateTextCustom from '../../../../utilities/TruncateTextCustom';
import { FormatDecimal } from '../../../../utilities/FormatDecimal';
import FormatTitleCase from '../../../../utilities/FormatTitleCase';

import {
  StyledMenu,
  StyledMenuItem,
} from '../../../../components/StyledMenuDropdown';
import DialogDuplicate from './DialogDuplicate';
import DialogDelete from './DialogDelete';
import DialogSetAsComplete from './DialogSetAsComplete';
import DialogArchive from './DialogArchive';
import DialogInfoSchedule from '../../../../components/DialogInfoSchedule';

import icon_project_team from '../../../../assets/images/icon_project_team.png';
import icon_project_personal from '../../../../assets/images/icon_project_personal.png';

import MyLang from '../../../../utilities/MyLang';
import { bahasa, english } from '../../../../constants/config-lang';

import '../Style/custom.css';

const ListCardItemGoalv2 = ({
  classes,
  item,
  handleDetail,
  setSelected,
  selected,
  isSelected,
  key,
}) => {
  const statusUserLogin = localStorage.getItem('status_user_login');
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === 'superadmin'
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  /* ===============================================
  STATE COMPONENT
 ================================================= */
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalDuplicate, setModalDuplicate] = useState(false);
  const [isModalDeleteGoalMaster, setModalDeleteGoalMaster] =
    useState(false);
  const [isOpenDialogSetAsComplete, setOpenDialogSetAsComplete] =
    useState(false);
  const [isOpenDialogArchive, setOpenDialogArchive] = useState(false);
  // Dialog Info Schedule
  const [openDialogInfoSchedule, setOpenDialogInfoSchedule] =
    useState(false);

  // Function Show Dropdown List
  function handleDropdownOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
  }

  // Function For Handling Dialog Set As Comlpete
  const handleDialogSetAsComplete = () => {
    handleDropdownClose();
    // setOpenDialogSetAsComplete(true);
    setOpenDialogInfoSchedule(true);
  };

  // Function For Handling Dialog DUPLICATE is Show or Not
  function handleDialogDuplicate() {
    handleDropdownClose();
    setModalDuplicate(true);
  }

  // Function For Handling Dialog Delete Goal
  function handleDialogDelete() {
    handleDropdownClose();
    setModalDeleteGoalMaster(true);
  }

  // Function For Handling Dialog Archive Goal
  function handleDialogArchive() {
    handleDropdownClose();
    setOpenDialogArchive(true);
  }

  const handleChangeCheckbox = (e, item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // // console.log("SELECTEDZ ", newSelected);

    setSelected(newSelected);
  };

  const handleRightClick = (data) => {
    // // console.log("Data goal", data);
  };
  /* =================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
    ================================================== */

  let viewDetailAllowed = false;
  let deleteAllowed = false;
  let setCompleteAllowed = false;

  item.policy &&
    item.policy.map((isAllowed) => {
      if (isAllowed.actionName === 'viewDetail') {
        viewDetailAllowed = isAllowed.result;
      } else if (isAllowed.actionName === 'delete') {
        deleteAllowed = isAllowed.result;
      } else if (isAllowed.actionName === 'completed') {
        setCompleteAllowed = isAllowed.result;
      }
    });

  let INTERVAL_COLOR;
  let INTERVAL_BG;

  if (item?.update_frequency?.interval === 'hourly') {
    INTERVAL_BG = '#D7E4F7';
    INTERVAL_COLOR = '#576FC2';
  } else if (item?.update_frequency?.interval === 'daily') {
    INTERVAL_BG = '#D7F3F4';
    INTERVAL_COLOR = '#009345';
  } else if (item?.update_frequency?.interval === 'weekly') {
    INTERVAL_BG = '#FBE0B3';
    INTERVAL_COLOR = '#FAA922';
  } else if (item?.update_frequency?.interval === 'monthly') {
    INTERVAL_BG = '#FDDBD8';
    INTERVAL_COLOR = '#C8203C';
  }

  const isItemSelected = isSelected(item.id);

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      {/* <List dense className={classes.listCardItem}> */}
      <Paper
        className={classes.shadowSection}
        style={{ position: 'relative' }}
      >
        {item.goal_category === 'PROJECT' &&
          item?.project_type === 'TEAM' && (
            <Tooltip title="Team Project">
              <Box className="ribbon3">
                <img
                  alt="icon team"
                  src={icon_project_team}
                  width={20}
                />
              </Box>
            </Tooltip>
          )}
        {item.goal_category === 'PROJECT' &&
          item?.project_type === 'PERSONAL' && (
            <Tooltip title="Personal Project">
              <Box className="ribbon2">
                <img
                  alt="icon personal"
                  src={icon_project_personal}
                  width={20}
                />
              </Box>
            </Tooltip>
          )}
        {/* <ListItem> */}
        <Box
          p={1}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Box width={50}>
              {/* {item.goal_category === "OPERATIONAL" && ( */}
              <Checkbox
                size="small"
                // disabled={!setCompleteAllowed}
                checked={isItemSelected}
                checkedIcon={
                  <CheckBoxRoundedIcon
                    style={{ color: '#0F68D2', fontSize: 18 }}
                  />
                }
                icon={
                  <CheckBoxOutlineBlankRoundedIcon
                    style={{ color: '#0F68D2', fontSize: 18 }}
                  />
                }
                onClick={(e) => handleChangeCheckbox(e, item)}
              />
              {/* )} */}
            </Box>
            <Box width={70} ml={6}>
              <div className={classes.circularBeside}>
                {item.calculatedValue.resultOriented.overall_result_achievement
                  .toString()
                  .indexOf('e') !== -1 ? (
                  <CircleInPercentForExsponent
                    classes={classes}
                    item={item}
                  />
                ) : (
                  <CircleInPercentNEW classes={classes} item={item} />
                )}
              </div>
            </Box>
            <Box ml={5} minWidth={375}>
              <Tooltip title={item.name}>
                <Typography
                  variant="subtitle1"
                  onContextMenu={() => handleRightClick(item)}
                >
                  <A
                    href={`/goal-detail/${item.id}`}
                    className={classes.toDetails}
                    style={{
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                  >
                    {/* <b>{FormatTitleCase(item.name)}</b> */}
                    {/* <b>{TruncateTextCustom(item.name, 40)}</b> */}
                    <b>{item.name}</b>
                  </A>
                </Typography>
              </Tooltip>
              <Typography
                variant="caption"
                className={classes.titleSub}
              >
                {item.period !== null && item.period.name}
                {item.start_date !== null &&
                  item.end_date !== null &&
                  item.period === null && (
                    <span>
                      {moment(item.start_date).format('DD MMMM') +
                        ' - ' +
                        moment(item.end_date).format('DD MMMM YYYY')}
                    </span>
                  )}
                <span>
                  {item.start_date == null &&
                    item.end_date !== null &&
                    item.period == null &&
                    moment(item.end_date).format('DD MMMM YYYY')}
                </span>
                <span
                  style={{
                    color: INTERVAL_COLOR,
                    backgroundColor: INTERVAL_BG,
                    borderRadius: 5,
                    padding: '2px 10px',
                    marginLeft: 10,
                  }}
                >
                  {item?.update_frequency?.interval}
                </span>
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              minWidth={250}
              maxWidth={350}
            >
              {item.member !== null &&
              item.member.photo_url !== null &&
              item.member.self.rel.photo_url !== null ? (
                <ImageSmallUser
                  item={item.member.self.rel.photo_url}
                  index={1}
                />
              ) : (
                <AccountCircleRoundedIcon
                  style={{ color: '#c4c5c4' }}
                />
              )}

              <Box ml={2}>
                <Typography
                  variant="subtitle1"
                  className={classes.title333}
                >
                  {TruncateTextCustom(
                    `${item?.member?.first_name} ${item?.member?.last_name}`,
                    17
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.title888}
                >
                  <span>
                    {item.owner
                      ? TruncateTextCustom(
                          item.owner?.structure_position_title_name,
                          18
                        )
                      : '-'}
                  </span>
                </Typography>
              </Box>
            </Box>

            <Box minWidth={175} ml={4}>
              <Typography
                variant="subtitle1"
                className={classes.title333}
              >
                {FormatDecimal(
                  item.calculatedValue.resultOriented.result_value
                )}
              </Typography>
            </Box>

            <Box width={150} ml={4}>
              <Typography
                variant="subtitle1"
                className={classes.title333}
              >
                {item.last_goal_result_date !== null
                  ? moment(item.last_goal_result_date).format(
                      'DD MMMM YYYY'
                    )
                  : '-'}
              </Typography>
              <Typography
                variant="caption"
                className={classes.title333}
              >
                {item.last_goal_result_date !== null
                  ? moment(item.last_goal_result_date).format(
                      'h:mm:ss a'
                    )
                  : '-'}
              </Typography>
            </Box>

            <Box width={20} mx={4}>
              {item.goal_category === 'OPERATIONAL' && (
                <IconButton edge="end" onClick={handleDropdownOpen}>
                  <MoreVertIcon />
                </IconButton>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
              >
                {superior && (
                  <StyledMenuItem onClick={handleDialogArchive}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                    >
                      <b>
                        {MyLang() ? bahasa.archive : english.archive}
                      </b>
                    </Typography>
                  </StyledMenuItem>
                )}
                {superior &&
                  item.status_id !== '4' &&
                  item.status_id !== '2' && (
                    <StyledMenuItem
                      onClick={handleDialogSetAsComplete}
                      disabled={!setCompleteAllowed}
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                      >
                        <b>
                          {MyLang()
                            ? bahasa.setAsComplete
                            : english.setAsComplete}
                        </b>
                      </Typography>
                    </StyledMenuItem>
                  )}
                {superior && (
                  <StyledMenuItem onClick={handleDialogDuplicate}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                    >
                      <b>
                        {MyLang()
                          ? bahasa.duplicate
                          : english.archive}
                      </b>
                    </Typography>
                  </StyledMenuItem>
                )}
                {superior && (
                  <StyledMenuItem
                    onClick={handleDialogDelete}
                    disabled={!deleteAllowed}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                    >
                      <b>
                        {MyLang() ? bahasa.delete : english.delete}
                      </b>
                    </Typography>
                  </StyledMenuItem>
                )}
              </StyledMenu>
            </Box>
          </Box>
        </Box>

        <DialogArchive
          classes={classes}
          isOpenDialogArchive={isOpenDialogArchive}
          setOpenDialogArchive={setOpenDialogArchive}
          item={item}
        />

        <DialogSetAsComplete
          classes={classes}
          isOpenDialogSetAsComplete={isOpenDialogSetAsComplete}
          setOpenDialogSetAsComplete={setOpenDialogSetAsComplete}
          goalDetailState={item}
        />

        <DialogInfoSchedule
          openDialogInfoSchedule={openDialogInfoSchedule}
          setOpenDialogInfoSchedule={setOpenDialogInfoSchedule}
          onOpenDialog={setOpenDialogSetAsComplete}
        />

        <DialogDuplicate
          classes={classes}
          item={item}
          isModalDuplicate={isModalDuplicate}
          setModalDuplicate={setModalDuplicate}
        />
        <DialogDelete
          classes={classes}
          isModalDeleteGoalMaster={isModalDeleteGoalMaster}
          setModalDeleteGoalMaster={setModalDeleteGoalMaster}
          goalDetailState={item}
        />
      </Paper>
    </Slide>
  );
};

export default ListCardItemGoalv2;
