import { container, title } from './styleKIt';

const landingPageStyle = (theme) => ({
  container: {
    // zIndex: "12",
    color: '#FFFFFF',
    ...container,
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
    textAlign: 'center',
    maxWidth: 900,
    fontSize: 30,
    padding: '0px 20px',
    '@media (min-width: 576px)': {
      fontSize: 50,
    },
  },
  heroDesc: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    margin: '30px auto 0px auto',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
    padding: '0px 15px',
    textAlign: 'center',
    maxWidth: 300,
    '@media (min-width: 576px)': {
      maxWidth: 700,
    },
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  btnFree: {
    zIndex: '20',
    color: '#fff',
    position: 'relative',
    padding: '10px 20px',
    fontWeight: '400',
    fontSize: '16px',
    textTransform: 'capitalize',
    borderRadius: '5px',
    lineHeight: '20px',
    textDecoration: 'none',
    // margin: "7px",
    display: 'inline-flex',
    backgroundColor: '#d64253',
    '&:hover,&:focus': {
      background: 'rgba(209, 53, 74,.6)',
    },
  },
  btnSales: {
    zIndex: '20',
    color: '#fff',
    position: 'relative',
    padding: '10px 20px',
    border: '1px solid #fff',
    fontWeight: '400',
    fontSize: '16px',
    textTransform: 'capitalize',
    borderRadius: '5px',
    lineHeight: '20px',
    textDecoration: 'none',
    // margin: "7px",
    display: 'inline-flex',
    backgroundColor: 'transparent',
    '&:hover,&:focus': {
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  btnSales2: {
    zIndex: '20',
    color: '#0F256B',
    position: 'relative',
    padding: '10px 20px',
    border: '1px solid #0F256B',
    fontWeight: '400',
    fontSize: '16px',
    textTransform: 'capitalize',
    borderRadius: '5px',
    lineHeight: '20px',
    textDecoration: 'none',
    // margin: "7px",
    display: 'inline-flex',
    backgroundColor: 'transparent',
    '&:hover,&:focus': {
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  btnHero: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
  },
  imgHero: {
    paddingTop: 50,
    maxWidth: '100%',
    // maxHeight: 450,
    position: 'relative',
    zIndex: -1,
    '@media (min-width: 576px)': {
      // marginTop: -180,
      maxHeight: 450,
    },
    '@media (min-width: 768px)': {
      // marginTop: -200,
      maxHeight: 400,
    },
    '@media (min-width: 992px)': {
      // marginTop: -280,
      maxHeight: 450,
    },
    '@media (min-width: 1200px)': {
      // marginTop: -380,
      maxHeight: 450,
    },
  },
});

export default landingPageStyle;
