import React, { Fragment } from "react";
import { Box, Radio, FormControlLabel } from "@material-ui/core";

import clsx from "clsx";

const FilterCompleted = ({ classes, completedId, onCompletedId }) => {
  const handleChooseCompleted = (e) => {
    onCompletedId(e.target.value);

    // console.log("val vompltd÷", e.target.value);
  };

  return (
    <Box pt={1} pb={2} px={3} display="flex" flexWrap="wrap">
      {completedCollections.map((item, i) => (
        <Fragment key={i}>
          <FormControlLabel
            label={
              <p
                style={{ color: "#555", fontSize: 14 }}
                className={classes.title}
              >
                {item.name}
              </p>
            }
            control={
              <Radio
                className={classes.rootRadio}
                disableRipple
                color="default"
                // disabled={disableFloatRadio && item.code === "comma"}
                name={item.name}
                // value={completed}
                value={item.id}
                checked={item.id === completedId}
                onChange={handleChooseCompleted}
                checkedIcon={
                  <span
                    className={clsx(
                      classes.iconRadio,
                      classes.checkedIconRadio
                    )}
                  />
                }
                icon={<span className={classes.iconRadio} />}
              />
            }
          />
        </Fragment>
      ))}
    </Box>
  );
};

export default FilterCompleted;

const completedCollections = [
  {
    id: "1",
    code: "todolist_is_uncompleted_id",
    name: "incompleted",
    sort_order: 0,
  },
  {
    id: "2",
    code: "todolist_is_completed_id",
    name: "completed",
    sort_order: 1,
  },
];
