import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";

import grow1 from "../../../../assets/images/grow1.png";
import grow2 from "../../../../assets/images/grow2.png";
import grow3 from "../../../../assets/images/grow3.png";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    color: "#888",
    textDecoration: "none",
  },
  cardField: {
    borderRadius: 15,
    border: "3px solid #F3F2F2",
    padding: "7px 30px",
    margin: "0px 0px 30px 0px",
    maxWidth: "80%",
  },
});

const useStyle = makeStyles(styles);

const GrowUp = () => {
  const classes = useStyle();

  return (
    <Box my={15}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6}>
          <Box mb={3}>
            <Typography variant="h4" className={classes.title333}>
              <b>Ratusan bisnis berkembang bersama Performate</b>
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="subtitle1" className={classes.title888}>
              “Performate hadir untuk meningkatkan kinerja karyawan / tim dan
              perusahaan di Indonesia agar terus berkembang"
            </Typography>
          </Box>

          <Box>
            {listGrow.map((item, i) => (
              <Box key={i} display="flex" alignItems="center">
                <Box width={75}>
                  <img src={item.icon} height={60} width="100%" />
                </Box>
                <Box ml={5}>
                  <Typography variant="h6" className={classes.title555}>
                    <b>{item.title}</b>
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title888}>
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box mx={4} bgcolor="#F2F4F7" borderRadius={10}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/CUQTSQmHBYE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <Box p={3}>
              <Typography variant="subtitle1" className={classes.title555}>
                Pengenalan Perfomate
              </Typography>
              <Box my={2} />
              <Typography variant="caption" className={classes.title888}>
                performate.id
              </Typography>
              <Box my={1} />
              <Typography variant="subtitle2" className={classes.title888}>
                “Performate membantu dalam mengambil keputusan bisnis dengan
                tepat dan cepat, penggunaan aplikasinya juga ramah.”
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GrowUp;

const listGrow = [
  {
    title: "1000+",
    description: "Jumlah goal dan strategi dibuat",
    icon: grow1,
  },
  {
    title: "1000+",
    description: "Pengguna terdaftar di performate",
    icon: grow2,
  },
  {
    title: "10+ kota",
    description: "terjangkau oleh performate",
    icon: grow3,
  },
];
