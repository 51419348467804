export default function MyLang() {
  const getLang = localStorage.getItem("lang");
  let lang = false;

  if (getLang !== undefined && getLang !== null) {
    if (getLang === "bahasa") {
      lang = true;
    } else {
      lang = false;
    }
  } else {
    lang = false;
  }

  return lang;
}
