import { Button, Grid, Typography } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import React from "react";
import { navigate } from "hookrouter";
import {
  ToTimeOffSetting,
} from "../../../../constants/config-redirect-url";

function TimeOffListMenu({
  classes,
  numSelected,
  rowCount,
  onSelectAllClick,
}) {

  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ width: "100%", marginBottom : "25px" }}>
        <Grid item>
          <Button
            onClick={() => navigate(ToTimeOffSetting)}
            variant="contained"
            color="default"
            className={classes.button}
            style={{ marginLeft: "0px", width : "100%" }}
          >
            <div style={{padding : "5px"}}>
              <div>
                <SettingsIcon style={{ color: "#ffffff" }} />
              </div>
              <div style={{paddingRight: "16px", paddingLeft: "16px"}}>
                <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color: "#fff"}}>
                  <b>TIME OFF SETTING</b>
                </Typography>
              </div>
            </div>
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TimeOffListMenu;
