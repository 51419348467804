import React, { useReducer, useState } from "react";
import ContextArchive from "./ContextArchive";

import { archiveReducer, initialArchive } from "./reducers/archiveReducer";

const ContextGlobalArchive = ({ children }) => {
  // ========== |REDUCER| ==========
  const [archiveState, archiveDispatch] = useReducer(
    archiveReducer,
    initialArchive
  );
  return (
    <ContextArchive.Provider
      value={{
        archiveState,
        archiveDispatch,
      }}>
      {children}
    </ContextArchive.Provider>
  );
};

export default ContextGlobalArchive;
