import React, { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { navigate } from "hookrouter";
import ContextGoal from "../Context/ContextGoal";

const styles = () => ({
  title333: {
    color: "#333",
  },
  title888: {
    color: "#888",
  },
  btnGrey: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    color: "#333",
    textTransform: "capitalize",
    padding: "2px 12px",
    backgroundColor: "#DFDFDF",
    "&:hover": {
      backgroundColor: "#B1B1B1",
    },
  },
});

const useStyle = makeStyles(styles);

const DialogArchiveSuccess = ({
  openDialogArchiveSuccess,
  setOpenDialogArchiveSuccess,
  onOpenDialog,
}) => {
  const classes = useStyle();
  const context = useContext(ContextGoal);

  return (
    <Dialog
      open={openDialogArchiveSuccess}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <CheckCircleOutlineIcon style={{ fontSize: 44, color: "#2ecc71" }} />
      </DialogTitle>

      <DialogContent style={{ width: 500 }}>
        <Alert severity="success">
          <AlertTitle>Berhasil</AlertTitle>
          Permintaan archived anda berhasil, dan saat ini sedang diproses oleh
          server kami. Jika proses archive nya sudah selesai, kami akan
          mengirimkan email kepada anda.
          <br />
          <br />
          Archive anda (archive's title) dapat dilihat di halaman{" "}
          <strong>Archive</strong> dengan status{" "}
          <strong>Archiving In Progress.</strong>
        </Alert>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
        }}>
        <Button
          variant="outlined"
          fullWidth
          size="medium"
          className={classes.btnGrey}
          onClick={() => {
            setOpenDialogArchiveSuccess(false);
            onOpenDialog(false);
            context.setOpenSnackbeer(true);
          }}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          fullWidth
          className={classes.btnGrey}
          onClick={() => navigate("/archive")}>
          Continue to Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogArchiveSuccess;
