import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Button } from "@material-ui/core";

import help1 from "../../../../assets/images/blog1.jpg";
import help2 from "../../../../assets/images/blog2.jpg";
import help3 from "../../../../assets/images/blog3.jpg";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    color: "#888",
    textDecoration: "none",
  },
  btnSeeMore: {
    color: "#1976D2",
    padding: "0px",
    fontWeight: "700",
    fontSize: "16px",
    textTransform: "capitalize",
    border: "none",
    backgroundColor: "transparent",
  },
  cardBlog: {
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
    backgroundColor: "#fff",
    borderRadius: 15,
  },
});

const useStyle = makeStyles(styles);

const Blogs = () => {
  const classes = useStyle();

  return (
    <Box py={15}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" className={classes.title333}>
          <b>Blog</b>
        </Typography>

        <Button className={classes.btnSeeMore} variant="outlined" size="small">
          Lihat lebih banyak
        </Button>
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          {listBlogs.map((item, i) => (
            <Grid item key={i} xs={12} sm={12} md={4} justify="center">
              <Box p={2} className={classes.cardBlog}>
                <img src={item.img} height={200} width="100%" />
              </Box>
              <Box mt={2}>
                <Typography variant="caption" className={classes.title555}>
                  <b>{item.tag}</b>
                </Typography>
                <Typography variant="subtitle1" className={classes.title333}>
                  <b>{item.title}</b>
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Blogs;

const listBlogs = [
  {
    title:
      "Cara membaca dashboard lebih efektif untuk pemula maupun profesional",
    tag: "Dashboard Tips",
    img: help1,
  },
  {
    title: "Performate hadir dengan fitur baru yaitu Weighting",
    tag: "New Feature - Weighting",
    img: help2,
  },
  {
    title: "Dengan satu platform dapat me manage banyak hal",
    tag: "Manage",
    img: help3,
  },
];
