import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
});

const useStyle = makeStyles(styles);

const UserTesti = () => {
  const classes = useStyle();

  return (
    <Box mt={15} py={15} bgcolor="#FAFAFA">
      <Container>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={6}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/jpQQXxLMqng"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box mb={3}>
              <Typography variant="h5" className={classes.title333}>
                <b>Iim Rusamsi</b>
              </Typography>
              <Typography variant="subtitle2" className={classes.title333}>
                Ketua Umum
              </Typography>
              <Typography variant="subtitle1" className={classes.title333}>
                Oke Oce Indonesia
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant="h6" className={classes.title555}>
                “Dengan Performate, Saya dapat mengukur dan monitor kinerja dari
                tim, dan memasang target sehingga dapat focus terhadap
                pencapaian perusahaan”
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box m={16} />

        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={6}>
            <Box mb={3}>
              <Typography variant="h5" className={classes.title333}>
                <b>Budiman Indrajaya</b>
              </Typography>
              <Typography variant="subtitle2" className={classes.title333}>
                CEO
              </Typography>
              <Typography variant="subtitle1" className={classes.title333}>
                PT. URUN Bangun Negri
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant="h6" className={classes.title555}>
                “performate membantu saya dalam management project dan
                mengontrol secara terstruktur dan sistematis setiap hari apa
                yang sudah apa belum”
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/SiIZVut3m1E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default UserTesti;
