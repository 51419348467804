import React, { useCallback, useEffect, useState, useContext, Fragment, useRef} from "react";
import { select, line, curveCardinal, axisBottom, axisRight, scaleLinear,  max, min} from "d3";

const Path = () => {

    const svgRef = useRef()

    
    const [data, setData ] = useState([25,30, 45, 60, 20, 50, 500000000000 ]);
    
    useEffect(() => {
        
        const svg = select(svgRef.current);

        const maxWew = max(data)
        const minWew = min(data)

        const xScale = scaleLinear()
            // .domain([0, data.length - 1])
            .domain([0, data.length - 1])
            .range([0, 300]);

        const yScale = scaleLinear()
            // .domain([0, 150])
            .domain([20, maxWew])
            .range([150, 0]);

        const xAxis = axisBottom(xScale)
            .ticks(data.length)
            .tickFormat(index => index + 1);

        svg
            .select(".x-axis")
            .style("transform", "translateY(150px)")
            .call(xAxis);


        const yAxis = axisRight(yScale);
            svg
              .select(".y-axis")
              .style("transform", "translateX(300px)")
              .call(yAxis);
        
        const myLine = line()
            .x((value, i) => xScale(i) )
            .y(yScale)
            .curve(curveCardinal)

        // svg
        //     .selectAll('path')
        //     .data([data])
        //     .join('path')
        //     .attr('d', value => myLine(value))
        //     .attr("fill", 'none')
        //     .attr('stroke', 'cyan')

        svg
            .selectAll('.line')
            .data([data])
            .join('path')
            .attr("class", "line")
            .attr("d", myLine)
            
            // .attr('d', value => myLine(value))
            .attr("fill", 'none')
            .attr('stroke', 'cyan')

        

    },[data])

    return(

        <svg ref={svgRef}>
            <g className="x-axis" />
            <g className="y-axis" />

        </svg>
    )
};

export default Path;