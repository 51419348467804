import React, { useContext, useEffect, useState } from "react";
import {
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { Draggable } from "react-beautiful-dnd";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import DialogError from "../../../../../components/DialogError";
import handleError from "../../../../Report/components/global/handleError";
import ContextProject from "../../../context/ContextProject";

const ListItemPhase = ({
  classes,
  item,
  index,
  onTriggerPhase,
  isSorting,
  selected,
  setSelected,
  isSelected,
  loading,
  setLoading,
}) => {
  const project_id = window.location.pathname.split("/")[2];
  const isItemSelected = isSelected(item.id);
  const { setTriggerKanban, setTriggerTask } = useContext(ContextProject);

  const [title, setTitle] = useState("");
  const [openEdit, setOpenEdit] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    setTitle(item.title);
  }, [item]);

  const handleDeletePhase = () => {
    setLoading(true);

    AxiosConfig.delete(
      `${URL_API}/project/${project_id}/custom-phase/${item.id}`
    )
      .then((res) => {
        // // console.log("Del phase", res);

        if (res.status === 200) {
          onTriggerPhase(Math.floor(Math.random() * 100));
          setTriggerKanban(Math.floor(Math.random() * 100));
          setTriggerTask(Math.floor(Math.random() * 100));
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
        setLoading(false);
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setLoading(true);

      const data = {
        title,
      };

      AxiosConfig.put(
        `${URL_API}/project/${project_id}/custom-phase/${item.id}`,
        data
      )
        .then((res) => {
          // // console.log("Update phase", res);

          if (res.status === 200) {
            onTriggerPhase(Math.floor(Math.random() * 100));
            setOpenEdit(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.log("ERROR", error);
          setLoading(false);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  };

  // // console.log("isItemSelected", isItemSelected);

  return (
    <>
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        isDragDisabled={isSorting}
      >
        {(provided) => (
          <Paper
            elevation={0}
            variant="outlined"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ListItem disabled={isSorting}>
              <ListItemIcon>
                <Checkbox
                  size="small"
                  disableRipple
                  checked={isItemSelected}
                  disabled={loading}
                  onChange={() => handleChangeCheckbox(item)}
                />
              </ListItemIcon>
              {!openEdit && (
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                    >
                      {item.title}
                    </Typography>
                  }
                  onClick={() => setOpenEdit(true)}
                />
              )}
              {openEdit && (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={title}
                  onChange={handleTitle}
                  disabled={loading}
                  onKeyPress={(e) => handleKeyPress(e)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() => setOpenEdit(false)}
                      >
                        <CloseRoundedIcon
                          style={{ color: "#888", fontSize: 18 }}
                        />
                      </IconButton>
                    ),
                  }}
                />
              )}
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  disabled={loading}
                  onClick={handleDeletePhase}
                >
                  <DeleteIcon style={{ color: "#888", fontSize: 18 }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        )}
      </Draggable>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default ListItemPhase;
