import React, { useContext, useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Box,
  Checkbox,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import clsx from "clsx";
import ContextProject from "../../context/ContextProject";
import DialogDeleteTasks from "../dialogs/DialogDeleteTasks";
import AnchorTaskGroup from "../anchor/AnchorTaskGroup";
import AnchorMoveGroup from "../anchor/AnchorMoveGroup";
import AnchorTaskMultiStatus from "../anchor/AnchorTaskMultiStatus";
import AnchorTaskMultiPriority from "../anchor/AnchorTaskMultiPriority";
import AnchorHideTaskGroup from "../anchor/AnchorHideTaskGroup";

const checkStyle = makeStyles((theme) => ({
  // ========================================
  // Rounded Icon
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",

    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
}));

const TableTaskHeader = ({ classes, project }) => {
  const classCheck = checkStyle();

  const { selectTask, setSelectTask, projectTState } =
    useContext(ContextProject);
  const method = project?.achievement_calculation_method;

  const [openDeleteTasks, setOpenDeleteTasks] = useState(false);

  // Length Of Collection Task
  let itemCount = 0;

  if (projectTState.taskData.length > 0) {
    itemCount = projectTState.taskData.length;
  }
  if (projectTState.groupTaskData.length > 0) {
    const groupTaskDatas = projectTState.groupTaskData
      .map((n) => n.todolists)
      .flat();

    itemCount = groupTaskDatas.length;
  }

  /* =========================
  selected All List Item
  ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let newSelecteds = [];
      const taskDatas = projectTState.taskData.map((n) => n.id);
      const groupTaskDatas = projectTState.groupTaskData
        .map((n) => n.todolists)
        .flat()
        .map((n) => n.id);

      if (taskDatas.length > 0 && groupTaskDatas.length === 0) {
        newSelecteds = taskDatas;
      } else if (taskDatas.length === 0 && groupTaskDatas.length > 0) {
        newSelecteds = groupTaskDatas;
      }

      setSelectTask(newSelecteds);
      // // console.log("selAll", newSelecteds);
      // // console.log("groupTaskDatas", groupTaskDatas);
      return;
    }
    setSelectTask([]);
  };

  // // console.log("projectTState.taskData", projectTState.taskData);
  // // console.log("projectTState.groupTaskData", projectTState.groupTaskData);

  return (
    <TableHead
      className={classes.tableHeader}
      style={{ position: "sticky", zIndex: 10, top: 0 }}
    >
      <TableRow style={{ borderTop: "1px solid #D9D9D9" }}>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            borderTopLeftRadius: 8.5,
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <Box display="flex">
            <span> Task List ({projectTState.countTask})</span>
            <Box mx={1} />
            <AnchorHideTaskGroup classes={classes} />
          </Box>
        </TableCell>
        <TableCell
          align="center"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
            borderLeft: "1px solid #D9D9D9",
            minWidth: 135,
          }}
        >
          Assignee
        </TableCell>
        {projectTState.hideTaskGroup && (
          <TableCell
            align="center"
            className={classes.titleHead}
            style={{
              backgroundColor: "#fff",
              minWidth: 80,
            }}
          >
            Group
          </TableCell>
        )}
        <TableCell
          align="center"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
            minWidth: 80,
          }}
        >
          Status
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
            minWidth: 100,
          }}
        >
          Priority
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
            minWidth: 120,
          }}
        >
          Due Date
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
            minWidth: 120,
          }}
        >
          Finished Date
        </TableCell>
        {method !== "SCHEDULE" && (
          <TableCell
            align="center"
            padding="none"
            className={classes.titleHead}
            style={{
              backgroundColor: "#fff",
              minWidth: 150,
            }}
          >
            Scope
          </TableCell>
        )}
        {method !== "SCOPE" && (
          <TableCell
            align="center"
            padding="none"
            className={classes.titleHead}
            style={{
              backgroundColor: "#fff",
              minWidth: 150,
            }}
          >
            Schedule
          </TableCell>
        )}
        <TableCell
          align="right"
          className={classes.titleHead}
          style={{
            borderTopRightRadius: 8.5,
            backgroundColor: "#fff",
            minWidth: 30,
          }}
        />
      </TableRow>

      {selectTask.length > 0 && (
        <TableRow style={{ border: "1px solid #CFEAF1" }}>
          <TableCell>
            <Box ml={1.8} display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={classCheck.checkboxRoot}
                checkedIcon={
                  <CheckCircleRoundedIcon
                    style={{ color: "#106ba3", fontSize: 21 }}
                  />
                }
                indeterminateIcon={
                  <RemoveCircleRoundedIcon
                    style={{ color: "#106ba3", fontSize: 21 }}
                  />
                }
                indeterminate={
                  selectTask.length > 0 && selectTask.length < itemCount
                }
                checked={itemCount > 0 && selectTask.length === itemCount}
                onChange={handleSelectAllClick}
              />

              <Box mx={0.25} />

              <Typography variant="caption" style={{ color: "#106ba3" }}>
                {selectTask.length}
              </Typography>

              <Box mx={3} />

              <Button
                size="small"
                variant="outlined"
                className={classes.btnMultiTask}
                onClick={() => setOpenDeleteTasks(true)}
              >
                Delete
              </Button>
              <Box mx={0.5} />
              <AnchorTaskMultiStatus classes={classes} />
              <Box mx={0.5} />
              <AnchorTaskMultiPriority classes={classes} />
              <Box mx={0.5} />
              <AnchorMoveGroup classes={classes} />
              <Box mx={0.5} />
              <AnchorTaskGroup classes={classes} />
            </Box>
          </TableCell>

          <TableCell align="right" />
          <TableCell align="right" />
          <TableCell align="right" />
          <TableCell align="right" />
          <TableCell align="right" />
          <TableCell align="right" />
          <TableCell align="right" />
          <TableCell align="right" />
        </TableRow>
      )}

      <DialogDeleteTasks
        classes={classes}
        openDeleteTasks={openDeleteTasks}
        setOpenDeleteTasks={setOpenDeleteTasks}
      />
    </TableHead>
  );
};

export default TableTaskHeader;
