import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from "@material-ui/core";

function EnhancedTableHeadCheckInCheckOut({
  classes,
  numSelected,
  rowCount,
  onSelectAllClick,
}) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>No</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
        <Typography variant="subtitle2" className={classes.title}>
            <b>
            <i>Tanggal</i>
            </b>
        </Typography>
        </TableCell>

        {/* <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Nama Jadwal</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Schedule In</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Duration</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Schedule Out</i>
            </b>
          </Typography>
        </TableCell> */}

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Check In</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              <i>Check Out</i>
            </b>
          </Typography>
        </TableCell>

        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHeadCheckInCheckOut;
