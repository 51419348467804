import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Tab, Tabs, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./style/styleArchive";

import AxiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import ContextArchive from "./context/ContextArchive";
import {
  ARCHIVE_ENTITY_LIST,
  ARCHIVE_GROUP_LIST,
  ARCHIVE_TABS,
  ARCHIVE_USER_LIST,
} from "./context/types";
import Entity from "./view/entity";
import User from "./view/user";
import Group from "./view/group";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#d1354a",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#333",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(styles);

const ViewArchive = () => {
  const classes = useStyles();
  const { archiveState, archiveDispatch } = useContext(ContextArchive);

  // ========================================================================
  // ~ Side Effect | Group archive ~
  // ========================================================================
  useEffect(() => {
    if (archiveState.archiveTabs === 0) {
      archiveDispatch({
        type: ARCHIVE_GROUP_LIST,
        payload: { archiveGroupList: [], loadGroup: true },
      });

      AxiosConfig.get(`${URL_API}/swd/archived/group`)
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Archvove group", result);

          if (res.status === 200) {
            archiveDispatch({
              type: ARCHIVE_GROUP_LIST,
              payload: {
                archiveGroupList: result.results.data,
                loadGroup: false,
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error));
        });
    }
  }, [archiveState.archiveTabs]);

  // ========================================================================
  // ~ Side Effect | User archive ~
  // ========================================================================
  useEffect(() => {
    if (archiveState.archiveTabs === 1) {
      archiveDispatch({
        type: ARCHIVE_USER_LIST,
        payload: { archiveUserList: [], loadUser: true },
      });

      AxiosConfig.get(`${URL_API}/swd/archived/user`)
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Archvove user", result);

          if (res.status === 200) {
            archiveDispatch({
              type: ARCHIVE_USER_LIST,
              payload: {
                archiveUserList: result.results.data,
                loadUser: false,
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error));
        });
    }
  }, [archiveState.archiveTabs]);

  // ========================================================================
  // ~ Side Effect | Entity archive ~
  // ========================================================================
  useEffect(() => {
    if (archiveState.archiveTabs === 2) {
      archiveDispatch({
        type: ARCHIVE_ENTITY_LIST,
        payload: { archiveEntityList: [], loadEntity: true },
      });

      AxiosConfig.get(`${URL_API}/swd/archived/entity`)
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Archvove entity", result);

          if (res.status === 200) {
            archiveDispatch({
              type: ARCHIVE_ENTITY_LIST,
              payload: {
                archiveEntityList: result.results.data,
                loadEntity: false,
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error));
        });
    }
  }, [archiveState.archiveTabs]);

  const handleTabs = (e, newValue) => {
    archiveDispatch({
      type: ARCHIVE_TABS,
      payload: { archiveTabs: newValue },
    });
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Box className={classes.root}>
      <Box marginBottom={2} className={classes.layoutMarginLeftAndTop}>
        <Box pt={3}>
          <Typography variant="h4" className={classes.title333}>
            <b>List Archive</b>
          </Typography>
        </Box>

        <Box mt={5} display="flex" justifyContent="space-between">
          <Box>
            <StyledTabs value={archiveState.archiveTabs} onChange={handleTabs}>
              <StyledTab label="Group" />
              <StyledTab label="User" />
              <StyledTab label="Entity" />
            </StyledTabs>
          </Box>

          <Box mr={3}>
            <Button size="large" className={classes.btnGrey}>
              Restore
            </Button>
          </Box>
        </Box>

        <Box>
          <TabPanel value={archiveState.archiveTabs} index={0}>
            <Group classes={classes} />
          </TabPanel>
          <TabPanel value={archiveState.archiveTabs} index={1}>
            <User classes={classes} />
          </TabPanel>
          <TabPanel value={archiveState.archiveTabs} index={2}>
            <Entity classes={classes} />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewArchive;
