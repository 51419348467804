import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  Divider,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import ContextTask from "../../../Context/ContextTask";

import FilterAssignee from "./custom/FilterAssignee";
import FilterStatus from "./custom/FilterStatus";
import FilterPriority from "./custom/FilterPriority";
import FilterCompleted from "./custom/FilterCompleted";
import { SET_CUSTOM_FILTER } from "../../../Context/types";

const FilterCustom = ({ classes, onClose, ownerCollections }) => {
  const { todoState, todoDispatch, setTriggerTodo } = useContext(ContextTask);

  const [ownerId, setOwnerId] = useState(todoState.ownerId);
  const [statusId, setStatusId] = useState([]);
  const [priorityId, setPriorityId] = useState([]);
  const [completedId, setCompletedId] = useState(todoState.completedId);

  const [openStatus, setOpenStatus] = useState(false);
  const [openOwner, setOpenOwner] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openCompleted, setOpenCompleted] = useState(false);

  const ExpandTrue = () => (
    <ExpandMore style={{ color: "#8F8F8F" }} fontSize="small" />
  );
  const ExpandFalse = ({ color }) => (
    <ExpandLess style={{ color }} fontSize="small" />
  );

  const handleStatus = () => setOpenStatus((s) => !s);
  const handleOwner = () => setOpenOwner((s) => !s);
  const handlePriority = () => setOpenPriority((s) => !s);
  const handleCompleted = () => setOpenCompleted((s) => !s);

  // console.log("todoState.filterCompleted", todoState.filterCompleted);

  const handleFilterCustom = () => {
    // // console.log("OWNER ID", ownerId);
    // // console.log("STATUS ID", statusId);
    // // console.log("PRIORITY ID", priorityId);
    // // console.log("COMPLETED ID", completedId);

    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: ownerId.length > 0 ? true : false,
        filterStatus: statusId.length > 0 ? true : false,
        filterPriority: priorityId.length > 0 ? true : false,
        filterCompleted: todoState.filterCompleted
          ? true
          : completedId !== ""
          ? true
          : false,
        ownerId,
        statusId,
        priorityId,
        completedId: completedId,
      },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
    onClose();
  };

  return (
    <div>
      <Box pl={2} p={1}>
        <Typography variant="caption" className={classes.title555}>
          <b>Custom Filters</b>
        </Typography>
      </Box>

      <Box minWidth={250} maxWidth={330}>
        <List dense>
          <ListItem
            button
            onClick={handleOwner}
            style={{
              backgroundColor: openOwner && "rgba(251, 234, 234, 0.5)",
              color: openOwner && "#C8203C",
            }}
          >
            <ListItemText primary="Assignee" />
            {openOwner ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openOwner} timeout="auto" unmountOnExit>
            <FilterAssignee
              classes={classes}
              ownerCollections={ownerCollections}
              onOwnerId={setOwnerId}
            />
          </Collapse>
          <Divider />
          <ListItem
            button
            onClick={handleStatus}
            style={{
              backgroundColor: openStatus && "rgba(251, 234, 234, 0.5)",
              color: openStatus && "#C8203C",
            }}
          >
            <ListItemText primary="Status" />
            {openStatus ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openStatus} timeout="auto" unmountOnExit>
            <FilterStatus classes={classes} onStatusId={setStatusId} />
          </Collapse>
          <Divider />
          <ListItem
            button
            onClick={handlePriority}
            style={{
              backgroundColor: openPriority && "rgba(251, 234, 234, 0.5)",
              color: openPriority && "#C8203C",
            }}
          >
            <ListItemText primary="Priority" />
            {openPriority ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openPriority} timeout="auto" unmountOnExit>
            <FilterPriority classes={classes} onPriorityId={setPriorityId} />
          </Collapse>
          <Divider />
          <ListItem
            button
            onClick={handleCompleted}
            style={{
              backgroundColor: openCompleted && "rgba(251, 234, 234, 0.5)",
              color: openCompleted && "#C8203C",
            }}
          >
            <ListItemText primary="Completed" />
            {openCompleted ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openCompleted} timeout="auto" unmountOnExit>
            <FilterCompleted
              classes={classes}
              completedId={completedId}
              onCompletedId={setCompletedId}
            />
          </Collapse>
          <Divider />
        </List>
      </Box>

      <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          size="small"
          varian="outlined"
          className={classes.button}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Box mx={0.5} />
        <Button
          size="small"
          variant="outlined"
          className={classes.button1}
          onClick={handleFilterCustom}
        >
          Show Result
        </Button>
      </Box>
    </div>
  );
};

export default FilterCustom;
