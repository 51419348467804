import React from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const ShowExtendPackage = ({
  classes,
  userLicenseCount,
  licenseDurationCount,
  pmoVisitCount,
  onUserLicense,
  onLicenseDuration,
  onPmoVisitTicket,
  onExtendPackage,
  pmoPrice,
  userPrice,
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box marginBottom={1}>
        <Box marginBottom={1} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" className={classes.title}>
            <b>Lisensi User</b>
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#ff9e0c" }}>
            <b>
              Rp {new Intl.NumberFormat("id-ID").format(userPrice)}/
              {userLicenseCount} User
            </b>
          </Typography>
        </Box>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={userLicenseCount}
          onChange={(e) => onUserLicense(e)}
          style={{ width: 250, backgroundColor: "#fff" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">User</InputAdornment>,
          }}
        />
      </Box>
      <Box marginBottom={1}>
        <Box marginBottom={1}>
          <Typography variant="subtitle2" className={classes.title}>
            <b>Durasi Lisensi</b>
          </Typography>
        </Box>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={licenseDurationCount}
          onChange={(e) => onLicenseDuration(e)}
          style={{ width: 250, backgroundColor: "#fff" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">Bulan</InputAdornment>,
          }}
        />
      </Box>
      <Box marginBottom={1}>
        <Box marginBottom={1} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" className={classes.title}>
            <b>Consultant Visit</b>
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#ff9e0c" }}>
            <b>
              Rp {new Intl.NumberFormat("id-ID").format(pmoPrice)}/
              {pmoVisitCount} Tiket
            </b>
          </Typography>
        </Box>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={pmoVisitCount}
          onChange={(e) => onPmoVisitTicket(e)}
          style={{ width: 250, backgroundColor: "#fff" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">Tiket</InputAdornment>,
          }}
        />
      </Box>
      <Box>
        <Button
          variant="outlined"
          className={classes.btnExtndBg}
          startIcon={<CancelIcon style={{ color: "#ff9e0c" }} />}
          onClick={() => onExtendPackage(false)}>
          <b>Batal</b>
        </Button>
      </Box>
    </Box>
  );
};

export default ShowExtendPackage;
