import React, { useContext } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Tooltip,
} from "@material-ui/core";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";

import { navigate } from "hookrouter";
import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";
import ContextGlobalDrive from "../../context/ContextDrive";

function MAGridGallery({ classes, item, count }) {
  const context = useContext(ContextGlobalDrive);
  const evidenceCount = count[item.measured_activity_id];

  const handleNavigateGallery = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    let maName = data.measuredActivity.name.replace(/,+/g, " ");
    breadcrumb.push(`MA : ${maName}`);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.measured_activity_id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.measured_activity_id}`);
  };

  return (
    <Grid item md={3}>
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : -20,
          backgroundColor: "#fffee0",
        }}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#ffbe76" }}>
              <TrendingUpRoundedIcon style={{ color: "#e67e22" }} />
            </Avatar>
          }
          title={
            <Tooltip title={item !== undefined && item.measuredActivity.name}>
              <Typography
                className={classes.folderNameText}
                variant="subtitle2"
                onClick={() => handleNavigateGallery(item)}>
                {TruncateTextShortNameGoal(
                  `MA : ${item !== undefined && item.measuredActivity.name}`
                )}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Typography style={{ color: "#999", fontSize: 13 }}>
              {`${evidenceCount.length} Evidence(s)`}
            </Typography>
          }
        />
      </Card>
    </Grid>
  );
}

export default MAGridGallery;
