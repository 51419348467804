import React, { useContext, useLayoutEffect, useRef } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import ContextReports from '../../../../context/ContextReports';
// Chart
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import '../../../../style/bar-chart.css';

// set chart animated
am4core.useTheme(am4themes_animated);

const BarChart = ({ classes }) => {
  const { barState, summaryState, tableSmrState } =
    useContext(ContextReports);
  const chartRef = useRef(null);

  const fixedData = barState.barData?.map((item) => {
    return {
      ...item,
      goal:
        typeof item?.goal === 'string'
          ? item?.goal
          : item?.goal.toFixed(2),
      ma:
        typeof item?.ma === 'string' ? item?.ma : item?.ma.toFixed(2),
    };
  });

  useLayoutEffect(() => {
    let chart = am4core.create('chartdiv', am4charts.XYChart);

    // Get Data Chart
    chart.data = fixedData;

    // const catY = category_y && barState.barPreset[0].category_y;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category =
      summaryState.selectedGroup.field;
    categoryAxis.renderer.minGridDistance = 60;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    // valueAxis.max = 100;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 105;

    if (summaryState.selectedEntities.includes('goal')) {
      let seriesGoal = chart.series.push(
        new am4charts.ColumnSeries()
      );

      seriesGoal.dataFields.categoryX =
        summaryState.selectedGroup.field;
      seriesGoal.dataFields.valueY = 'goal';
      seriesGoal.dataFields.valueX = 'goal_status';
      seriesGoal.name = 'Goal';
      seriesGoal.tooltipText = '{name}: {valueY}';

      const valueLabel = seriesGoal.bullets.push(
        new am4charts.LabelBullet()
      );
      valueLabel.label.text = 'Goal';
      valueLabel.label.fontSize = 14;
      valueLabel.label.dy = -8;
      // seriesGoal.fill = am4core.color("#555");

      const colTempGoal = seriesGoal.columns.template;
      colTempGoal.focusable = true;
      colTempGoal.hoverOnFocus = true;
      colTempGoal.tooltipText = 'xx {valueY}';
      colTempGoal.fill = am4core.color('#35B970');

      colTempGoal.adapter.add('fill', (fill, target) => {
        // // console.log("Val Y", target.dataItem && target.dataItem.valueY);
        if (
          summaryState.selectedFields.code !==
          'overall_result_achievement'
        ) {
          return am4core.color('#3498db');
        } else {
          if (target.dataItem && target.dataItem.valueY < 85) {
            return am4core.color('#F28181');
          } else if (
            target.dataItem &&
            target.dataItem.valueY > 85 &&
            target.dataItem.valueY < 100
          ) {
            return am4core.color('#E8A700');
          } else return fill;
        }
      });
    }

    if (summaryState.selectedEntities.includes('ma')) {
      let seriesMa = chart.series.push(new am4charts.ColumnSeries());

      seriesMa.dataFields.categoryX =
        summaryState.selectedGroup.field;
      seriesMa.dataFields.valueY = 'ma';
      seriesMa.name = 'MA';
      seriesMa.tooltipText = '{name}: {valueY}';

      const valueLabelMa = seriesMa.bullets.push(
        new am4charts.LabelBullet()
      );
      valueLabelMa.label.text = 'MA';
      valueLabelMa.label.fontSize = 14;
      valueLabelMa.label.dy = -8;

      const colTempMa = seriesMa.columns.template;
      colTempMa.focusable = true;
      colTempMa.hoverOnFocus = true;
      colTempMa.tooltipText = 'xx {valueY}';
      colTempMa.fill = am4core.color('#10ac84');

      colTempMa.adapter.add('fill', (fill, target) => {
        // // console.log("Val Y", target.dataItem && target.dataItem.valueY);
        if (
          summaryState.selectedFields.code !==
          'overall_result_achievement'
        ) {
          return am4core.color('#95a5a6');
        } else {
          if (target.dataItem && target.dataItem.valueY < 85) {
            return am4core.color('#e74c3c');
          } else if (
            target.dataItem &&
            target.dataItem.valueY > 85 &&
            target.dataItem.valueY < 100
          ) {
            return am4core.color('#F79F1F');
          } else return fill;
        }
      });
    }

    chart.scrollbarX = new am4core.Scrollbar();
    chart.cursor = new am4charts.XYCursor();
    // chart.legend = new am4charts.Legend();

    /**
     * ========================================================
     * Enabling responsive features
     * ========================================================
     */
    chart.responsive.enabled = true;
    chart.responsive.rules.push({
      relevant: function (target) {
        return false;
      },
      state: function (target, stateId) {
        return;
      },
    });

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [barState, tableSmrState.paginating]);

  return (
    <Box mt={2}>
      {barState.loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress
            size={16}
            style={{ color: '#d64253', marginRight: 5 }}
          />
          <Typography
            className={classes.title888}
            variant="subtitle1"
          >
            Loading...
          </Typography>
        </Box>
      ) : (
        <>
          <div
            id="chartdiv"
            style={{ width: '100%', height: '400px' }}
          ></div>
          <Box
            display="flex"
            flexWrap="wrap"
            mt={2}
            justifyContent="center"
          >
            {summaryState.selectedFields.code !==
            'overall_result_achievement'
              ? resExpStats.map((el) => (
                  <Box display="flex" alignItems="center">
                    <div
                      style={{
                        backgroundColor: el.color,
                        height: 20,
                        width: 20,
                        borderRadius: 3,
                        marginRight: 5,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                      style={{ marginRight: 10 }}
                    >
                      {el.label}
                    </Typography>
                  </Box>
                ))
              : achieveStats.map((el) => (
                  <Box display="flex" alignItems="center">
                    <div
                      style={{
                        backgroundColor: el.color,
                        height: 20,
                        width: 20,
                        borderRadius: 3,
                        marginRight: 5,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                      style={{ marginRight: 10 }}
                    >
                      {el.label}
                    </Typography>
                  </Box>
                ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default BarChart;

const achieveStats = [
  { color: '#35B970', label: 'Achieved' },
  { color: '#E8A700', label: 'Warning' },
  { color: '#CF364A', label: 'Not achieved' },
];

const resExpStats = [
  { color: '#3498db', label: 'Goal' },
  { color: '#95a5a6', label: 'MA' },
];
