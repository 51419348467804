export const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  absenceCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
    borderRadius: 11,
    padding: "0px 20px",
    margin: "20px 0px",
  },
  presenceCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)",
    borderRadius: 11,
    padding: "0px 20px",
    marginTop: "1rem",
  },
  exportButton: {
    display: "flex",
    borderRadius: 11,
    border: `2px solid purple`,
    justifyContent: "center",
    alignItems: "flex-end",
    // gap: "20px",
    gap: "10px",
    padding: "20px",
  },
});
