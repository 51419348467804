import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import ContextGoalDetail from "../../../7WD/Goal/Context/ContextGoalDetail";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogSetWeightDel from "./DialogSetWeightDel";
import DialogInfoSplitWeight from "../../../../components/DialogInfoSplitWeight";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDeleteMA,
    setModalDeleteMA,
    maDetailState,
    setMeasuredActivityDetailShow,
  } = props;

  const context = useContext(ContextGoalDetail);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [userTokenState, setUserTokenState] = useState("");
  const [loader, setLoader] = useState(false);
  const [openDialogSetWeight, setOpenDialogSetWeight] = useState(false);
  const [openInfoSplitWeight, setOpenInfoSplitWeight] = useState(false);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  // const [ isModalResponse200, setModalResponse200 ] = useState(false);//*Moved To Global State

  useEffect(() => {
    if (isModalDeleteMA == true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isModalDeleteMA]);

  const handleDelete = () => {
    setLoader(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    if (userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/swd/measured-activity/${maDetailState.id}`)
        .then(function (response) {
          // console.log("Response Original : ", response);

          if (response.status === 200) {
            const dataLog = {
              user_activity_log: {
                module: "goal",
                event: "delete",
                user_id: user?.user_id,
                user_email: user?.user_email,
                nama: `${user?.member_first_name} ${user?.member_last_name}`,
                position:
                  user?.member_position?.structure_position_title_name ===
                  undefined
                    ? ""
                    : user?.member_position?.structure_position_title_name,
                device_type: device_os,
                device_model: "",
                device_version: OSName,
                long: "",
                lat: "",
                entity_id: maDetailState?.id,
                entity_name: maDetailState?.name,
                remarks: "Menghapus MA 1",
                comment: "",
              },
            };

            setDataRemarks(dataLog);

            // setModalDeleteMA(false);
            // setMeasuredActivityDetailShow(true);
            context.setModalResponse200(true);

            setOpenRemarksLog(true);
          }

          // axiosConfig
          //   .get(
          //     `${URL_API}/swd/measured-activity/weighting/${maDetailState.goal_id}`
          //   )
          //   .then((res) => {
          //     const result = res.data.data;
          //     const listMA = result.ma_id.default_value;

          //     if (listMA.length === 0) {
          //       setModalDeleteMA(false);
          //       setMeasuredActivityDetailShow(true);
          //       context.setModalResponse200(true);
          //     } else {
          //       // setOpenDialogSetWeight(true);

          //       const result = res.data.data;
          //       const listMA = result.ma_id.default_value;

          //       // FOR MAKE SURE 100
          //       const to100 = (arr) => {
          //         let lists = arr.map((list) => (list === null ? 0 : list));

          //         if (lists.reduce((acc, curr) => acc + curr, 0) <= 100) {
          //           lists[lists.length - 1] += Math.abs(
          //             100 - lists.reduce((acc, curr) => acc + curr, 0)
          //           );
          //         } else if (
          //           lists.reduce((acc, curr) => acc + curr, 0) >= 100
          //         ) {
          //           lists[lists.length - 1] -= Math.abs(
          //             100 - lists.reduce((acc, curr) => acc + curr, 0)
          //           );
          //         }

          //         return lists;
          //       };

          //       // CONDITION TO 100
          //       const toaverage = (arr) => {
          //         const lists = arr.map((list) => Number(list.weight));
          //         const origin = lists.map((ori) => (ori === null ? 0 : ori));
          //         const is0 = origin.reduce((acc, curr) => acc + curr, 0);
          //         const avg = 100 / arr.length;
          //         const reborn = arr.map((born) => Number(avg.toFixed()));

          //         if (is0 === 0) {
          //           // console.log("REBORN wght", reborn);
          //           return to100(reborn);
          //         } else {
          //           // console.log("ORIGIN wght", origin);
          //           return to100(origin);
          //         }
          //       };

          //       // // console.log("SUM wght", toaverage(result));
          //       // =========== THE ARR WEIGHT AFTER CALCULATION ===========
          //       const ARRAY_WEIGHT = toaverage(listMA);
          //       // =========== THE ARR WEIGHT AFTER CALCULATION ===========

          //       const mapMA =
          //         listMA.length > 0 &&
          //         listMA.map((el, i) => {
          //           return {
          //             ma_id: el.id,
          //             weight: Number(ARRAY_WEIGHT[i]).toFixed(),
          //           };
          //         });

          //       const data = {
          //         weightings: mapMA,
          //       };

          //       axiosConfig
          //         .post(
          //           `${URL_API}/swd/measured-activity/weighting/${maDetailState.goal_id}/store`,
          //           data
          //         )
          //         .then((res) => {
          //           // console.log("RES WEIGHT", res);

          //           if (res.status === 200) {
          //             // setLoadWeight(false);
          //             // setOpenDialogSetWeight(false);
          //             // setMeasuredActivityDetailShow(true);
          //             // context.setModalResponse200(true);

          //             setOpenInfoSplitWeight(true);
          //           }
          //         })
          //         .catch((error) => {
          //           // console.log("Error : ", error);

          //           // Handle Error
          //           const { listError, dialogErr, resStatus, errorMessage } =
          //             handleError(error);
          //           // Error State
          //           setOpenDialogError(dialogErr);
          //           setTextErrorMessage(errorMessage);
          //           setListError(listError);
          //           setErrorStatus(resStatus);
          //         });
          //     }
          //   });

          setLoader(false);
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          setLoader(false);

          setModalDeleteMA(false);
          alert("Whoops, something went wrong !");
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <Fragment>
      <Dialog
        open={isModalDeleteMA}
        onClose={() => setModalDeleteMA(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}
        ></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}
            >
              <b>
                Apakah Anda yakin ingin menghapus <i>MA</i>:{" "}
                <i>{maDetailState.name}</i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader === true ? (
              <CircularProgress size={16} style={{ color: "white" }} />
            ) : (
              "Yakin"
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks={
          dataRemarks !== null ? dataRemarks.user_activity_log?.entity_name : ""
        }
        dataRemarks={dataRemarks}
        onCloseRemarks={setModalDeleteMA}
        onTriggerRemarks={context.setModalResponse200}
        onBack={setMeasuredActivityDetailShow}
        origin="need-back"
        editRemarks={true}
      />

      <DialogSetWeightDel
        classes={classes}
        openDialogSetWeight={openDialogSetWeight}
        setOpenDialogSetWeight={setOpenDialogSetWeight}
        setMeasuredActivityDetailShow={setMeasuredActivityDetailShow}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogInfoSplitWeight
        openInfoSplitWeight={openInfoSplitWeight}
        setOpenInfoSplitWeight={setOpenInfoSplitWeight}
        onOpenDialog={setModalDeleteMA}
        onTrigger={context.setModalResponse200}
        origin="deletema2"
      />
    </Fragment>
  );
};

export default DialogDelete;
