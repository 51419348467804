import React, { useState, useContext, useEffect, Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  Box,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogChangeOwnerFolder({
  item,
  classes,
  openDialogChangeOwner,
  setOpenDialogChangeOwner,
}) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
        LOCAL STATE
    ==================================================== */
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [ownerId, setOwnerId] = useState(null);
  const [ownerDetail, setOwnerDetail] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
    LifeCycle Hook
  ==================================================== */
  useEffect(() => {
    if (openDialogChangeOwner) {
      setLoading(true);

      axiosConfig
        .get(`${URL_API}/drive/folder/${item.id}:change-owner`)
        .then((response) => {
          // console.log("ChangeOwner Create", response);

          const result = response.data.data;

          if (response.status === 200) {
            setOwnerCollections(result.ownerCollections);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);
        });
    }
  }, [openDialogChangeOwner]);

  /* ====================================================
    LOCAL Functions / Methods
  ==================================================== */

  // Choose Select Owner
  const handleSelectOwner = (e, owner) => {
    // console.log("OWNER", owner);

    if (owner !== null) {
      setOwnerId(owner.id);
      setOwnerDetail(owner);
    }
  };

  const handleChangeOwner = () => {
    const data = {
      DriveFolder: {
        owner_id: ownerId,
      },
    };

    setLoader(true);

    axiosConfig
      .put(`${URL_API}/drive/folder/${item.id}:change-owner`, data)
      .then((response) => {
        // console.log("Response Change Owner", response);

        if (response.status === 200) {
          context.setSuccessTriggerFolder(Math.floor(Math.random() * 1000 + 1));
          context.setModalResponse200(true);
          context.setMessages("Owner changed successfully");

          setOwnerDetail(null);
          setOwnerId(null);
          setOwnerCollections([]);

          setOpenDialogChangeOwner(false);
        }
        setLoader(false);
      })
      .catch((error) => {
        // console.log("Error : ", error.response);
        setLoader(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogChangeOwner}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Change Owner</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 375 }}>
        <Box marginBottom={2} textAlign="center">
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#333" }}
          >
            Apakah anda yakin untuk mengubah <i>Owner</i> dari{" "}
            <strong>
              {item.owner !== undefined &&
                item.owner !== null &&
                `${item.owner.member.first_name} ${item.owner.member.last_name}`}
            </strong>{" "}
            menjadi
            <strong>
              {ownerDetail !== null
                ? ` ${ownerDetail.member.first_name} ${ownerDetail.member.last_name}`
                : " -"}
            </strong>{" "}
            {ownerDetail !== null && "?"}
          </Typography>
        </Box>

        <Box marginTop={2} marginBottom={4}>
          <Autocomplete
            id="combo-box-demo"
            options={ownerCollections}
            getOptionLabel={(option) =>
              `${option.member.first_name} ${option.member.last_name}`
            }
            className={classes.title}
            noOptionsText="No options"
            loading={loading}
            onChange={(e, owner) => handleSelectOwner(e, owner)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                style={{ fontSize: 12 }}
                className={classes.title}
                label="Search Owner"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>

        <DialogContentText id="alert-dialog-description">
          <DialogActions
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              className={classes.button2}
              onClick={() => {
                setOpenDialogChangeOwner(false);
                setOwnerDetail(null);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              disabled={ownerId === null || loader}
              onClick={handleChangeOwner}
            >
              {loader ? <CircularProgress color="#fff" size={15} /> : "Change"}
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogChangeOwnerFolder;
