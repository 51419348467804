import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import StickyFooter from "react-sticky-footer";
import DoneIcon from "@material-ui/icons/Done";

import GenerateID from "../../../utilities/GenerateID";
import Redirect from "../../../utilities/Redirect";
import {
  ToHrEmployeeKepegawaianInfoPegawai,
  ToHrEmployeeProfileDetail,
} from "../../../constants/config-redirect-url";

import ButtonSpecialSimpanToDB from "./Components/ButtonSpecialSimpanToDB";
import DialogAddUrgentContacts from "./Components/DialogAddUrgentContacts";
import DialogEditUrgentContacts from "./Components/DialogEditUrgentContacts";
import DialogDeleteUrgentContact from "./Components/DialogDeleteUrgentContact";

import { styles } from "./Style/StyleUrgentContact";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewUrgentContact = (props) => {
  const { classes } = props;

  /*
        
        ````````````````````````````````````
        COMPONENT DID MOUNT

        ````````````````````````````````````
    */
  const [listData, setListData] = useState("");

  let dataList = [];
  const employeeDataKontakDarurat = localStorage.getItem(
    "employee_data_kontak_darurat"
  );
  let employeeDataKontakDaruratAfterParse =
    employeeDataKontakDarurat !== null
      ? JSON.parse(employeeDataKontakDarurat)
      : [];

  useEffect(() => {
    // console.log("employeeDataKontakDaruratAfterParse : ", employeeDataKontakDaruratAfterParse)

    setListData(employeeDataKontakDaruratAfterParse);
  }, []);

  /*
        ```````````
        HANDLE FORM 
        ```````````
    */
  const [nameAnggotaKeluarga, setNameAnggotaKeluarga] = useState("");

  const handleChangeNamaAnggotaKeluarga = (e) =>
    setNameAnggotaKeluarga(e.target.value);

  // const [ hubunganKeluarga, setHubunganKeluarga ] = useState('');
  // const handleChangeHubunganKeluarga = (e) => setHubunganKeluarga(e.target.value);

  const [hubunganKeluarga, setHubunganKeluarga] = useState([]);
  const handleChangeHubunganKeluarga = (name) => (e) => {
    setHubunganKeluarga({ ...hubunganKeluarga, [name]: e.target.value });
  };

  const [nomorHandphone, setNomorHandphone] = useState("");
  const handleChangeNoHandphone = (e) => setNomorHandphone(e.target.value);

  const handleSimpanDataAnggotaKeluarga = () => {
    let data = {
      id: GenerateID(),
      name_anggota_keluarga: nameAnggotaKeluarga,
      hubungan_keluarga: hubunganKeluarga.name,
      no_hp: nomorHandphone,
    };

    if (listData.length > 0) {
      // console.log("Run v1")

      const newList = [...listData, data];

      // console.log("newList : ", newList);

      localStorage.setItem(
        "employee_data_kontak_darurat",
        JSON.stringify(newList)
      );
      setListData([...listData, data]);
    } else {
      // console.log("Run v2");

      dataList.push(data);
      localStorage.setItem(
        "employee_data_kontak_darurat",
        JSON.stringify(dataList)
      );
      window.location.reload();
    }

    setModalTambahAnggotaKeluarga(false);
  };

  /*
        ```````````````````
        HANDLE MODAL TAMBAH

        ```````````````````
    */
  const [modalTambahAnggotaKeluarga, setModalTambahAnggotaKeluarga] =
    useState(false);
  const handleModalTambah = () => {
    setNameAnggotaKeluarga("");
    setNomorHandphone("");

    setModalTambahAnggotaKeluarga(true);
  };

  /*
        ````````````````````
        HANDLE MODAL  UPDATE

        ````````````````````
    */

  const [modalEdit, setModalEdit] = useState(false);
  const [dataDetailEdit, setDataDetailEdit] = useState("");

  const handleEditDataDetailAnggotaKeluarga = (e, data) => {
    e.preventDefault();
    setModalEdit(true);

    // // console.log("Data edit detail : ", data);

    setDataDetailEdit(data);
  };

  const [isUpdatedWork, setUpdatedWork] = useState(false);

  const handleSimpanPerubahan = () => {
    setUpdatedWork(true);

    let data = {
      id: dataDetailEdit.id,
      name_anggota_keluarga:
        nameAnggotaKeluarga !== ""
          ? nameAnggotaKeluarga
          : dataDetailEdit.name_anggota_keluarga,
      hubungan_keluarga:
        hubunganKeluarga !== ""
          ? hubunganKeluarga.name
          : dataDetailEdit.hubungan_keluarga,
      no_hp: nomorHandphone !== "" ? nomorHandphone : dataDetailEdit.no_hp,
    };

    // console.log("Perubahan data : ", data);

    setListData(listData.map((item) => (item.id === data.id ? data : item)));
    setModalEdit(false);
  };

  useEffect(() => {
    if (isUpdatedWork == true) {
      localStorage.setItem(
        "employee_data_kontak_darurat",
        JSON.stringify(listData)
      );
    }
  }, [isUpdatedWork]);

  /*
        ```````````````````
        HANDLE MODAL DELETE

        ```````````````````
    */

  const [dataDetailDelete, setDataDetailDelete] = useState("");
  const [modalDelete, setModalDelete] = useState(false);

  const handleDeleteDataDetail = (e, data) => {
    e.preventDefault();

    // console.log("Data : ", data);

    setModalDelete(true);
    setDataDetailDelete(data);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Paper elevation={1} className={classes.paperKeluarga} square={true}>
        <Grid container>
          <Grid item xs={10} style={{ textAlign: "left" }}>
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Kontak Darurat</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              // onClick={() => setModalTambahAnggotaKeluarga(true)}
              onClick={handleModalTambah}
            >
              <b>+ Tambah Kontak Darurat</b>
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            {/* <IconButton className={classes.iconEdit}>
                            <i className='material-icons'>edit</i>
                        </IconButton> */}
          </Grid>
        </Grid>

        {listData.length > 0 &&
          listData.map((item, i) => (
            <Grid container key={i}>
              <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.titleHeader}>
                  <b>{item.name_anggota_keluarga}</b>
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle2" className={classes.titleHeader}>
                  <b>
                    {item !== undefined ? item.hubungan_keluarga.value : ""}
                  </b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    onClick={(e) =>
                      handleEditDataDetailAnggotaKeluarga(e, item)
                    }
                    style={{ color: "#cc0707", cursor: "pointer" }}
                  >
                    Edit{" "}
                  </span>{" "}
                  &nbsp;&nbsp;- &nbsp;&nbsp;
                  <span
                    // onClick={() => setModalDeleteAnggotaKeluarga(true)}
                    // onClick = {() => localStorage.removeItem('employee_data_kontak_darurat') }
                    onClick={(e) => handleDeleteDataDetail(e, item)}
                    style={{ color: "#cc0707", cursor: "pointer" }}
                  >
                    Hapus
                  </span>
                </Typography>

                <Typography
                  variant="subtitle2"
                  className={classes.titlenomorHandphone}
                >
                  {"No Telepon : " + item.no_hp}
                </Typography>
                <br />
              </Grid>
            </Grid>
          ))}
        <br />
      </Paper>

      <DialogAddUrgentContacts
        classes={classes}
        setModalTambahAnggotaKeluarga={setModalTambahAnggotaKeluarga}
        modalTambahAnggotaKeluarga={modalTambahAnggotaKeluarga}
        nameAnggotaKeluarga={nameAnggotaKeluarga}
        handleChangeHubunganKeluarga={handleChangeHubunganKeluarga}
        hubunganKeluarga={hubunganKeluarga}
        handleChangeNoHandphone={handleChangeNoHandphone}
        nomorHandphone={nomorHandphone}
        handleSimpanDataAnggotaKeluarga={handleSimpanDataAnggotaKeluarga}
        handleChangeNamaAnggotaKeluarga={handleChangeNamaAnggotaKeluarga}
      />

      <DialogEditUrgentContacts
        classes={classes}
        setModalEdit={setModalEdit}
        modalEdit={modalEdit}
        dataDetailEdit={dataDetailEdit}
        handleChangeHubunganKeluarga={handleChangeHubunganKeluarga}
        handleChangeNoHandphone={handleChangeNoHandphone}
        handleChangeNamaAnggotaKeluarga={handleChangeNamaAnggotaKeluarga}
        nameAnggotaKeluarga={nameAnggotaKeluarga}
        hubunganKeluarga={hubunganKeluarga}
        nomorHandphone={nomorHandphone}
        handleSimpanPerubahan={handleSimpanPerubahan}

        // handleSimpanDataAnggotaKeluarga = {handleSimpanDataAnggotaKeluarga}
      />

      <DialogDeleteUrgentContact
        classes={classes}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        listData={listData}
        setListData={setListData}
        dataDetailDelete={dataDetailDelete}
      />

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* 
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                className={classes.button}
                                style={{margin : 14}}
                                disabled={false}
                                
                                onClick={() => Redirect(ToHrEmployeeProfileDetail)}
                            >
                                Lanjut  
                            </Button> 
                        */}
          <ButtonSpecialSimpanToDB classes={classes} />
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewUrgentContact);
