import React from "react";
import { Typography, Box, TextField, Checkbox } from "@material-ui/core";

import clsx from "clsx";
import MyLang from "../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../constants/config-lang";

const SetAchievements = ({
  classes,
  entity,
  achievement,
  onAchievement,
  enabled,
  onEnabled,
  onReadyData,
  lockSetting,
}) => {
  // // console.log("ACHEW", entity, achievement);

  const handleMinValue = (e) => {
    onAchievement({
      ...achievement,
      min: e.target.value,
    });
    onReadyData("open");
  };

  const handleMaxValue = (e) => {
    onAchievement({
      ...achievement,
      max: e.target.value,
    });
    onReadyData("open");
  };

  return (
    <Box mt={4}>
      <Typography variant="subtitle2" className={classes.title333}>
        <b>{entity}</b>
      </Typography>

      <Box mt={2} mb={4} display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.title888}>
          <b>{MyLang() ? bahasa.min : english.min} : </b>
        </Typography>
        <Box mx={1} />
        <TextField
          size="small"
          type="number"
          variant="outlined"
          disabled={!enabled || lockSetting}
          value={Number(achievement.min)}
          error={Number(achievement.min) >= Number(achievement.max)}
          helperText={
            Number(achievement.min) >= Number(achievement.max)
              ? MyLang()
                ? bahasa.min
                : english.min
              : null
          }
          onChange={handleMinValue}
        />

        <Box mx={3} />

        <Typography variant="subtitle2" className={classes.title888}>
          <b>{MyLang() ? bahasa.max : english.max} : </b>
        </Typography>
        <Box mx={1} />
        <TextField
          size="small"
          type="number"
          variant="outlined"
          disabled={!enabled || lockSetting}
          value={Number(achievement.max)}
          error={Number(achievement.max) <= Number(achievement.min)}
          helperText={
            Number(achievement.max) <= Number(achievement.min)
              ? MyLang()
                ? bahasa.max
                : english.max
              : null
          }
          onChange={handleMaxValue}
        />

        <Box mx={2} />

        <Box display="flex" alignItems="center">
          <Checkbox
            className={classes.checkboxRoot}
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            checked={enabled}
            onChange={onEnabled}
            disableRipple
            color="default"
            disabled={lockSetting}
          />
          <Typography variant="caption" className={classes.title555}>
            {MyLang() ? bahasa.enable : english.enable}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SetAchievements;
