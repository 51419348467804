import React, { useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Box, Paper, Typography, Grid, Button } from "@material-ui/core";

import Redirect from "../../../utilities/Redirect";
import { ToRegister } from "../../../constants/config-redirect-url";

import PictBackgroundImage from "../../../assets/images/bglogin.png";
import FormSubmit from "./ComponentSignin/FormSubmit";
import styles from "./Style/SignInStyle";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

// const useStyles = makeStyles(theme => ({

const ViewSignin = (props) => {
  const { classes } = props;

  //*
  // const db = useIndexedDB('video');//*Store inside Indexed DB of "Attachment"
  // console.log(JSON.stringify(db))

  /*
        ````````
        Dropdown

        ````````
    */
  const [languages, setLanguage] = useState({
    language: "",
  });

  const handleChangeDropdown = (name) => (event) => {
    setLanguage({ ...languages, [name]: event.target.value });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        style={{
          backgroundImage: "url(" + PictBackgroundImage + ")",
          backgroundSize: "cover",
          height: "92vh",
        }}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ paddingTop: 120 }}>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            style={{ textAlign: "center" }}></Grid>
          <Grid item sm={12} xs={12} md={6} lg={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Paper className={classes.root} elevation={8}>
                <Box mb={3} p={1}>
                  <Typography variant="h5" className={classes.title555}>
                    <b>Login to Performate.id</b>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2" className={classes.title888}>
                    Selamat datang kembali! Silahkan login dengan data yang
                    telah dibuat saat pendaftaran
                  </Typography>
                </Box>
                <FormSubmit props={classes} />
                <br />
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="subtitle2" className={classes.title}>
                    Belum punya akun?
                  </Typography>
                  <Button
                    color="primary"
                    className={classes.buttonAction}
                    onClick={() => Redirect(ToRegister)}>
                    <Typography variant="subtitle2" className={classes.title}>
                      daftar
                    </Typography>
                  </Button>
                </Box>
                <br />
                <br />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewSignin);
