import React from "react";
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import ImageSmallUser from "../../../../components/ImageSmallUser";

const CardFirstIconPersonName = (props) => {
  const { classes, item, i } = props;

  return (
    <ListItem style={{ paddingLeft: 0, marginRight: 24 }}>
      <ListItemAvatar>
        <Avatar>
          {item.member.photo_url !== null ? (
            <ImageSmallUser item={item.member.self.rel.photo_url} index={1} />
          ) : (
            <PersonIcon />
          )}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography variant="subtitle2" className={classes.title}>
            <b>
              {item.member_first_name} {item.member_last_name}
            </b>
          </Typography>
        }
        secondary={
          <Typography variant="subtitle2" className={classes.title}>
            {item.structure_position_title_name + " "}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default CardFirstIconPersonName;
