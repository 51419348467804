import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import AlarmIcon from "@material-ui/icons/Alarm";

const DialogActive = ({
  classes,
  openDialogActive,
  setOpenDialogActive,
  subscription,
  day,
}) => {
  const handleClose = () => {
    setOpenDialogActive(false);
  };

  return (
    <Dialog
      open={openDialogActive}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="center">
          <AlarmIcon style={{ marginRight: 5, color: "#d1354a" }} />
          <Typography
            variant="h6"
            className={classes.title}
            style={{ color: "#d1354a" }}>
            {/* <b>{Math.abs(day)} hari lagi!</b> */}
            <b>
              {day === 0
                ? "Kurang dari 24 jam lagi!"
                : `${Math.abs(day)} hari lagi!`}
            </b>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center", width: 450 }}>
        <Typography variant="subtitle2" className={classes.title}>
          Masa berlaku langganan Anda akan segera habis, berakhir pada tanggal{" "}
          {subscription.finish_date}. Setelah masa berlaku habis status
          langganan Anda akan berubah menjadi masa tenggang (overdue).
        </Typography>
        <br />
        <Typography variant="subtitle2" className={classes.title}>
          Harap hubungi CS/konsultan kami melalui email <i>cs@performate.id</i>{" "}
          untuk melakukan perpanjangan layanan.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", marginTop: 20, marginBottom: 5 }}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleClose}>
          Saya Mengerti
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogActive;
