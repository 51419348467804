import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { URL_API } from '../../../constants/config-api';
import AxiosConfig from '../../../constants/config-axios';
import ContextAttendanceDashboard from '../Context/ContextAttendanceDashboard';
import { VERIFICATION_METHOD } from '../Context/types';

function PrerequestAttendance({ children }) {
	const { attendanceDashboardState, attendanceDashboardDispatch } = useContext(ContextAttendanceDashboard);

	useEffect(() => {
		if (isEmpty(attendanceDashboardState.verificationMethod)) {
			attendanceDashboardDispatch({
				type: VERIFICATION_METHOD,
				payload: { verificationMethod: {}, loadVerificationMethod: true },
			});
			AxiosConfig.get(`${URL_API}/human-resource/attendance/verification-method`)
				.then(res => {
					const result = res.data.data;
					console.log('RES verification method ', result);
					if (res.status === 200) {
						attendanceDashboardDispatch({
							type: VERIFICATION_METHOD,
							payload: {
								verificationMethod: result,
								loadVerificationMethod: false,
							},
						});
					}
				})
				.catch(error => console.log('Error', error));
		}
	}, []);

	return <>{children}</>;
}

export default PrerequestAttendance;
