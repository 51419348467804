const extractTable = (arr) => {
  const data =
    arr !== undefined &&
    arr.length > 0 &&
    arr.map((item) => {
      const prop = Object.keys(item);
      const val = Object.values(item);
      const type = item.type;

      // // console.log("ITEM EXT", item);

      var result = {};
      let title = {
        content: item.title,
        id: item.goal_ma_id,
        nested: item.nested_level,
        type,
        interval: item.interval,
        updated_at: item.updated_at,
      };
      let fullName = {
        content: item.full_name,
        photo_url: item.photo_url,
        type,
      };

      // Loop Trough to add prop
      prop.forEach((key, i) => {
        return (result[key] = {
          content: val[i],
          type,
        });
      });

      result["title"] = title;
      result["full_name"] = fullName;

      // // console.log("resulT", result);

      return result;
    });

  return data.length > 0 ? data : [];
};

export default extractTable;
