/*
    
    ``````````````````````````````````````````````````````````````````````````
    FILE INI KEMUNKGINAN SUDAH TERGANTIKAN 'ViewMembershipStatusKelolaPending',
    sementara belum di hapus dulu sampai benar- benar yakin sudah tidak 
    di butuhkan !

    ``````````````````````````````````````````````````````````````````````````
*/

import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Popover,
  Fab,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import PictPaketGold from "../../../assets/images/SVG/Group_2.svg";
import PictBackgroundImage from "../../../assets/images/Group_1922.png";

import Redirect from "../../../utilities/Redirect";
import {
  ToMembershipStatusDeactivate,
  ToMembershipStatusIncreaseQuotaSEMENTARA,
} from "../../../constants/config-redirect-url";

// import './backgroundPaper.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 2,
  },
  subRoot2: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  subRoot3: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  button: {
    width: "503px",
    height: "42px",
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },
  title: {
    color: "#5a5959",
    fontFamily: "Roboto",
  },
  titleDialog1: {
    fontFamily: "Roboto",
    marginBottom: theme.spacing(1),
  },
  titleDialog2: {
    fontFamily: "Roboto",
    marginBottom: theme.spacing(2),
  },
  titleDialog3: {
    fontFamily: "Roboto",
    color: "#07cc56",
    fontWeight: "bold",
  },
  titleDialog4: {
    fontFamily: "Roboto",
  },
  titleMenangguhkan: {
    fontFamily: "Roboto",
    color: "#333333",
    marginTop: theme.spacing(16),
    marginLeft: theme.spacing(4),
  },
  titleBack: {
    fontFamily: "Roboto",
    color: "#333333",
    marginTop: theme.spacing(16),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    cursor: "pointer",
    fontWeight: "bold",
  },
  leftSentence: {
    color: "#5a5959",
    fontFamily: "Roboto",
  },
  statusChip: {
    backgroundColor: "#24d872",
    color: "white",
    padding: 3,
  },
  buttonTambahUser: {
    // marginRight:theme.spacing(4),
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "grey",
  },
  buttonPerpanjangNow: {
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    marginRight: theme.spacing(1),
  },
  buttonModal: {
    // height: 32,
    borderRadius: 3,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    width: "100%",
    textTransform: "capitalize",
  },
});

const ViewMembershipStatusKelola = (props) => {
  const { classes } = props;

  /*
        ```````````````````````
        HANDLE MODAL ADD USER

        ```````````````````````
    */

  const [isModalAddUser, setModalAddUser] = useState(false);
  const closeModalTambahUser = () => setModalAddUser(false);

  /*
        `````````````````````````
        HANDLE MODAL EXTEND NOW

        `````````````````````````
    */
  const [isExtendNow, setExtendNow] = useState(false);
  const closeModalExtendNow = () => setExtendNow(false);

  const handleRedirectToDeactivate = () =>
    Redirect(ToMembershipStatusDeactivate);

  return (
    <MuiThemeProvider theme={theme}>
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              Kelola Keanggotaan
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.subRoot2} elevation={8}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <br />
            <br />
            <Typography variant="subtitle1" className={classes.leftSentence}>
              Paket Keanggotaan &#8287;&#8287;&#8287;&#8287;&#8287;: &#8287;
              &#8287; &#8287; <b>Gold</b>{" "}
              <img
                src={PictPaketGold}
                alt="Gold Image"
                style={{ width: 20, height: 20 }}
              />
            </Typography>

            <Typography variant="subtitle1" className={classes.leftSentence}>
              Jumlah User
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;:
              &#8287; &#8287; &#8287; <b>80 User </b>
              <a href="#" style={{ textDecoration: "none", marginLeft: 17 }}>
                Detail
              </a>
            </Typography>

            <Typography variant="subtitle1" className={classes.leftSentence}>
              Durasi
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;:
              &#8287; &#8287; &#8287; <b>3 Bulan</b>
              <a href="#" style={{ textDecoration: "none", marginLeft: 24 }}>
                Detail
              </a>
            </Typography>

            <Typography variant="subtitle1" className={classes.leftSentence}>
              Masa Berlaku Awal &#8287;&#8287;&#8287;&#8287;&#8287;: &#8287;
              &#8287; &#8287; <b>26 Januari 2019 - 26 Maret 2019</b>
            </Typography>

            <Typography variant="subtitle1" className={classes.leftSentence}>
              Total Harga
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;:
              &#8287; &#8287; &#8287; <b>Rp 12.000.000</b>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <br />
            <br />
            <Typography variant="subtitle1" className={classes.title}>
              Status
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;
              : &#8287;&#8287;{" "}
              <Chip label="Aktif" className={classes.statusChip} />
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Sisa Masa Berlaku
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;
              : &#8287;&#8287;<b>36 Hari Lagi</b>
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Jatuh Tempo
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;
              : &#8287;&#8287;<b>1 April 2019</b>
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Masa Berlaku Terakhir &#8287;&#8287;&#8287;&#8287;&#8287;&#8287; :
              &#8287;&#8287;<b>1 April 2019</b>
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Terakhir di tangguhkan &#8287;&#8287;&#8287;&#8287; :
              &#8287;&#8287;<b>5 Maret 2019 -10 Maret 2019</b>
              <a href="#" style={{ textDecoration: "none", marginLeft: 24 }}>
                Detail
              </a>
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
      </Paper>

      <Paper className={classes.subRoot3} elevation={0}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              className={classes.buttonPerpanjangNow}
              onClick={() => setExtendNow(true)}>
              Perpanjang sekarang
            </Button>
            <Button
              variant="outlined"
              className={classes.buttonTambahUser}
              onClick={() => setModalAddUser(true)}>
              + Tambah user
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.subRoot4} elevation={0}>
        <Grid container>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              className={classes.titleMenangguhkan}>
              Apakah anda ingin
              <span
                onClick={handleRedirectToDeactivate}
                style={{ color: "#c1272d", cursor: "pointer" }}>
                &nbsp; menangguhkan masa aktif keanggotaan ?
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography
              variant="subtitle1"
              className={classes.titleBack}
              onClick={() =>
                Redirect(ToMembershipStatusIncreaseQuotaSEMENTARA)
              }>
              Kembali
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* 

                `````````````````
                INCREASE QUOTA

                `````````````````

            */}
      <Dialog
        open={isModalAddUser}
        onClose={closeModalTambahUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.titleDialog1}>
            <b>Tambah User</b>
          </Typography>
          <img
            src={PictPaketGold}
            alt="icon-logo"
            style={{ width: 60, height: 60 }}
          />
          <br />
          <Typography variant="h4" className={classes.titleDialog2}>
            <b>Gold</b>
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            Sisa Masa Berlaku 36 Hari lagi
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            Masukan jumlah user{" "}
            <input type="text" style={{ width: 20, textAlign: "center" }} />
          </Typography>

          <Typography variant="subtitle1" className={classes.titleDialog3}>
            Total User : 99
          </Typography>
          <Typography
            variant="caption"
            className={classes.titleDialog2}
            style={{ color: "grey" }}>
            2 User lagi untuk menjadi anggota silver
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h4"
              className={classes.titleDialog4}
              style={{ color: "black" }}>
              <b>Rp 12.000.000</b>
            </Typography>
            <Typography
              variant="caption"
              className={classes.titleDialog4}
              style={{ color: "#eb7d00" }}>
              <b>Harga per user Rp 50.000</b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={closeModalTambahUser}
            color="primary"
            size="large"
            className={classes.buttonModal}>
            Tambah
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      {/* 

                `````````````````
                EXTEND NOW

                `````````````````
                
            */}
      <Dialog
        open={isExtendNow}
        onClose={closeModalExtendNow}
        aria-labelledby="alert-dialog-title-extend"
        aria-describedby="alert-dialog-description-extend">
        <DialogTitle
          id="alert-dialog-title-extend"
          style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.titleDialog1}>
            <b>Perpanjang Keanggotaan</b>
          </Typography>
          <img
            src={PictPaketGold}
            alt="icon-logo"
            style={{ width: 60, height: 60 }}
          />
          <br />
          <Typography variant="h4" className={classes.titleDialog2}>
            <b>Gold</b>
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            Jumlah User : 80
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            Durasi : 3 bulan
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description-extend">
            <Typography
              variant="h4"
              className={classes.titleDialog4}
              style={{ color: "black" }}>
              <b>Rp 12.000.000</b>
            </Typography>
            <Typography
              variant="caption"
              className={classes.titleDialog4}
              style={{ color: "#eb7d00" }}>
              <b>Potongan 10%</b>
            </Typography>
            <br />
            <Typography
              variant="caption"
              className={classes.titleDialog4}
              style={{ color: "#eb7d00" }}>
              <b>Harga per-user Rp 50.000</b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={closeModalTambahUser}
            color="primary"
            size="large"
            className={classes.buttonModal}>
            Perpanjang
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewMembershipStatusKelola);
