import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
  fileText: {
    fontFamily: "Roboto",
    fontWeight: "600",
  },
}));

const DialogPreviewDoc = ({ openDoc, setOpenDoc, file }) => {
  const classes = useStyles();
  const userToken = localStorage.getItem("userToken");

  const [docPreview, setDocPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openDoc) {
      setLoading(true);
      AxiosConfig.get(
        `${URL_API}/todolist/${file.todolist_id}/attachment/${file.id}`,
        {
          responseType: "blob",
        }
      )
        .then((response) => {
          // console.log("Response DOC", response);

          if (response.status === 200) {
            setDocPreview(response.config.url);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoading(false);
        });
    }
  }, [openDoc]);

  const handleClosePreview = () => {
    setLoading(false);
    setOpenDoc(false);
  };

  return (
    <div>
      {loading ? (
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={handleClosePreview}
        >
          <CircularProgress color="#f28181" />
        </Backdrop>
      ) : (
        <Dialog
          open={openDoc}
          aria-labelledby="customized-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl" //*Default "md"
          style={{ height: "auto", width: "100%" }}
        >
          <DialogTitle id="customized-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" className={classes.fileText}>
                {file.label}
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setOpenDoc(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ width: 700 }}>
            <iframe
              name={file.label}
              width="100%"
              height="600"
              frameborder="0"
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${docPreview}?token=${userToken}&embedded=true`}
            ></iframe>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default DialogPreviewDoc;
