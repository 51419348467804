import { globalBahasa } from "./language/bahasa";
import { globalEnglish } from "./language/english";

// Kasmus Performate Bahasa Indonesia
export const bahasa = {
  ...globalBahasa,
};

// Performate English Dictionary
export const english = {
  ...globalEnglish,
};
