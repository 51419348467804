import React from "react";
import {
  Dialog,
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormSubmitRegister from "./FormSubmitRegister";

import regisImg from "../../../../assets/images/regis_img.png";
import { navigate } from "hookrouter";

function DialogRegister({ classes }) {
  return (
    <Dialog
      open={true}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Grid container>
        <Grid item lg={8}>
          <Box p={2} pb={0} display="flex" justifyContent="flex-end">
            <IconButton size="small" onClick={() => navigate("/")}>
              <HighlightOffIcon style={{ color: "#555" }} />
            </IconButton>
          </Box>
          <Box pl={5} pt={1} pb={2}>
            <Typography variant="h6" className={classes.title555}>
              Selamat Datang di Performate
            </Typography>
            <Typography variant="subtitle1" className={classes.title888}>
              mari mulai dengan data dirimu
            </Typography>
          </Box>
          <Box pl={4}>
            <FormSubmitRegister classes={classes} />
          </Box>
          <Box
            py={2}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Typography variant="subtitle2" className={classes.title}>
              Sudah punya akun?
            </Typography>
            <Button
              color="primary"
              className={classes.buttonAction}
              onClick={() => navigate("/login")}>
              <Typography variant="subtitle2" className={classes.title}>
                login
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <img src={regisImg} height="100%" width="100%" />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default DialogRegister;
