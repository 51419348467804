import React, { useState, useReducer } from "react";
import ContextProject from "./ContextProject";

import { initialProject, projectReducer } from "./reducer/projectTeamReducer";

// import store from "../utils/store";

// const dataStorage = JSON.parse(localStorage.getItem("dataKanban"));

// const initialState = () => {
//   if (dataStorage) {
//     return dataStorage;
//   } else {
//     localStorage.setItem("dataKanban", JSON.stringify(store));
//     return store;
//   }
// };

const ContextGlobalProject = (props) => {
  // ========== |REDUCER| ==========
  const [projectTState, projectTDispatch] = useReducer(
    projectReducer,
    initialProject
  );

  const [triggerProject, setTriggerProject] = useState(null);
  const [triggerTask, setTriggerTask] = useState(null);
  const [selectTask, setSelectTask] = useState([]);
  const [triggerAttachment, setTriggerAttachment] = useState(null);
  const [triggerKanban, setTriggerKanban] = useState(null);
  const [openSuccessImportTasks, setOpenSuccessImportTasks] = useState(false);

  const [projectKanban, setProjectKanban] = useState(null);

  // KANBAN
  const [dataKanban, setDataKanban] = useState(null);
  const [oriKanban, setOriKanban] = useState(null);
  const [offlineMethod, setOfflineMethod] = useState(null);

  return (
    <ContextProject.Provider
      value={{
        triggerProject,
        setTriggerProject,
        triggerTask,
        setTriggerTask,
        selectTask,
        setSelectTask,
        triggerAttachment,
        setTriggerAttachment,
        projectTState,
        projectTDispatch,
        openSuccessImportTasks,
        setOpenSuccessImportTasks,
        dataKanban,
        setDataKanban,
        triggerKanban,
        setTriggerKanban,
        projectKanban,
        setProjectKanban,
        oriKanban,
        setOriKanban,
        offlineMethod,
        setOfflineMethod,
      }}
    >
      {props.children}
    </ContextProject.Provider>
  );
};

export default ContextGlobalProject;
