import React, { useState } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const Description = ({
  classes,
  description,
  setDescription,
  status403,
  projectType,
}) => {
  const [toggleDesc, setToggleDesc] = useState(true);

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Paper className={classes.dataArea}>
      <Box p={2} pt={1} display="flex" alignItems="center">
        <Typography
          variant="subtitile2"
          className={classes.title333}
          style={{ marginRight: 5 }}
        >
          <b>Description</b>
        </Typography>
        {toggleDesc ? (
          <IconButton size="small" onClick={() => setToggleDesc(!toggleDesc)}>
            <KeyboardArrowUpIcon style={{ color: "#C8203C" }} />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={() => setToggleDesc(!toggleDesc)}>
            <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
          </IconButton>
        )}
      </Box>

      {toggleDesc && (
        <Box p={2} pt={0}>
          <TextField
            type="text"
            size="small"
            variant="standard"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={handleDescription}
            InputProps={{
              style: { fontSize: 13, color: "#555" },
              disableUnderline: true,
            }}
            disabled={status403 || projectType === "PERSONAL"}
          />
        </Box>
      )}
    </Paper>
  );
};

export default Description;
