export const statusCollections = [
  {
    id: "TO DO",
    code: "TO DO",
    name: "To Do",
    sort_order: 0,
  },
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    sort_order: 1,
  },
  {
    id: "DONE",
    code: "DONE",
    name: "Done",
    sort_order: 2,
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    sort_order: 3,
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    sort_order: 3,
  },
];

export const statusWithoutDone = [
  {
    id: "TO DO",
    code: "TO DO",
    name: "To Do",
    sort_order: 0,
  },
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    sort_order: 1,
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    sort_order: 3,
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    sort_order: 3,
  },
];

export const statusWithoutTodo = [
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    sort_order: 1,
  },
  {
    id: "DONE",
    code: "DONE",
    name: "Done",
    sort_order: 2,
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    sort_order: 3,
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    sort_order: 3,
  },
];

export const priorityCollections = [
  {
    id: "HIGH",
    code: "HIGH",
    name: "High",
    sort_order: 0,
  },
  {
    id: "MEDIUM",
    code: "MEDIUM",
    name: "Medium",
    sort_order: 1,
  },
  {
    id: "LOW",
    code: "LOW",
    name: "Low",
    sort_order: 2,
  },
];
