import React, { useState } from "react";
import { MenuItem, Typography, Divider, Dialog } from "@material-ui/core";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DialogTableFullScreen from "./DialogTableFullScreen";

const ListItemMenu = ({ classes, setAnchorMenu, rate }) => {
  const [openDialogFullScreen, setOpenDialogFullScreen] = useState(false);

  const handleFullScreen = () => {
    setAnchorMenu(null);
    setOpenDialogFullScreen(true);
  };

  return (
    <>
      <MenuItem style={{ width: 190 }} onClick={handleFullScreen}>
        <FullscreenIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Full Screen
        </Typography>
      </MenuItem>
      {/* <MenuItem style={{ width: 190 }}>
        <ExitToAppOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Export
        </Typography>
      </MenuItem>

      <Divider />

      <MenuItem style={{ width: 190 }}>
        <DeleteOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Delete
        </Typography>
      </MenuItem> */}

      <DialogTableFullScreen
        classes={classes}
        openDialogFullScreen={openDialogFullScreen}
        setOpenDialogFullScreen={setOpenDialogFullScreen}
        rate={rate}
      />
    </>
  );
};

export default ListItemMenu;
