import React, { Fragment } from "react";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

const IconTargetRangeValue = (props) => {
  const { classes, goalDetailState } = props;

  return (
    <Fragment>
      <ListItemIcon style={{ marginLeft: 8 }}>
        <IconButton style={{ backgroundColor: "#aed9ff", padding: "6px" }}>
          <TrackChangesIcon />
        </IconButton>
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}
          >
            Target Range Value
            {MyLang() ? bahasa.targetRangeVal : english.targetRangeVal}
          </Typography>
        }
        secondary={
          <LightTooltip
            placement="bottom-start"
            title={
              `${
                MyLang() ? bahasa.targetRangeVal : english.targetRangeVal
              } : ` + FormatDecimal(goalDetailState.target_range_value)
            }
          >
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "white" }}
            >
              <b>{FormatDecimal(goalDetailState.target_range_value)} </b>
            </Typography>
          </LightTooltip>
        }
      />
    </Fragment>
  );
};

export default IconTargetRangeValue;
