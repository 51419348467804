import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import moment from "moment";
import FormatBytes from "../../../../utilities/FormatBytes";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";

const propertyForDrive = [
  "File name : ",
  "Size : ",
  "Folder name : ",
  "Owner : ",
  "Date Created : ",
  "Last modified : ",
];
const propertyForGallery = [
  "File name : ",
  "Size : ",
  "Goal name : ",
  "Owner : ",
  "Date Created : ",
  "Last modified : ",
];

const propertyForGalleryMA = [
  "File name : ",
  "Size : ",
  "MA name : ",
  "Owner : ",
  "Date Created : ",
  "Last modified : ",
];

function DialogInfoFiles({
  openDialogInfoFile,
  setOpenDialogInfoFile,
  item,
  classes,
  origin,
}) {
  return (
    <Dialog
      open={openDialogInfoFile}
      onClose={() => setOpenDialogInfoFile(false)}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent style={{ width: 380, paddingBottom: 30, marginTop: 20 }}>
        <Box style={{ textAlign: "center" }}>
          <InfoOutlinedIcon
            style={{ fontSize: 30, color: "#888", marginBottom: 12 }}
          />
        </Box>

        <Grid container justify="flex-end">
          <Grid item md={5}>
            <List className={classes.title}>
              {origin === "Drive" &&
                propertyForDrive.map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText secondary={<b>{item}</b>} />
                  </ListItem>
                ))}

              {origin === "Gallery" &&
                propertyForGallery.map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText secondary={<b>{item}</b>} />
                  </ListItem>
                ))}

              {origin === "GalleryMA" &&
                propertyForGalleryMA.map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText secondary={<b>{item}</b>} />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item md={6}>
            {origin === "Drive" && (
              <List className={classes.title}>
                <ListItem>
                  <Tooltip title={item.name} placement="top-end">
                    <ListItemText
                      secondary={TruncateTextCustom(item.name, 18)}
                    />
                  </Tooltip>
                </ListItem>

                <ListItem>
                  <ListItemText secondary={FormatBytes(item.size)} />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={
                      item.folder_name === null ? "-" : item.folder_name
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={
                      item.owner !== undefined && item.owner !== null
                        ? `${item.owner.member.first_name} ${item.owner.member.last_name}`
                        : "User has been deleted"
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={moment(item.created_at).format(
                      "DD MMM YYYY, HH:mm"
                    )}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={moment(item.modified_at).format(
                      "DD MMM YYYY, HH:mm"
                    )}
                  />
                </ListItem>
              </List>
            )}

            {origin === "Gallery" && (
              <List className={classes.title}>
                <ListItem>
                  <Tooltip title={item.label} placement="top-end">
                    <ListItemText
                      secondary={TruncateTextCustom(item.label, 18)}
                    />
                  </Tooltip>
                </ListItem>

                <ListItem>
                  <ListItemText secondary={FormatBytes(item.size)} />
                </ListItem>

                <ListItem>
                  <Tooltip
                    title={
                      item.goal !== undefined &&
                      item.goal !== null &&
                      item.goal.name
                    }
                    placement="top-end">
                    <ListItemText
                      secondary={TruncateTextCustom(
                        item.goal !== undefined &&
                          item.goal !== null &&
                          item.goal.name,
                        20
                      )}
                    />
                  </Tooltip>
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={
                      item.owner !== null &&
                      item.owner !== undefined &&
                      `${item.owner.member_first_name} ${item.owner.member_last_name}`
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={moment(item.created_at).format(
                      "DD MMM YYYY, HH:mm"
                    )}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={moment(item.updated_at).format(
                      "DD MMM YYYY, HH:mm"
                    )}
                  />
                </ListItem>
              </List>
            )}

            {origin === "GalleryMA" && (
              <List className={classes.title}>
                <ListItem>
                  <Tooltip title={item.label} placement="top-end">
                    <ListItemText
                      secondary={TruncateTextCustom(item.label, 18)}
                    />
                  </Tooltip>
                </ListItem>

                <ListItem>
                  <ListItemText secondary={FormatBytes(item.size)} />
                </ListItem>

                <ListItem>
                  <Tooltip
                    title={
                      item.measuredActivity !== undefined &&
                      item.measuredActivity !== null &&
                      item.measuredActivity.name
                    }
                    placement="top-end">
                    <ListItemText
                      secondary={TruncateTextCustom(
                        item.measuredActivity !== undefined &&
                          item.measuredActivity !== null &&
                          item.measuredActivity.name,
                        20
                      )}
                    />
                  </Tooltip>
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={
                      item.owner !== null &&
                      item.owner !== undefined &&
                      `${item.owner.member_first_name} ${item.owner.member_last_name}`
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={moment(item.created_at).format(
                      "DD MMM YYYY, HH:mm"
                    )}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={moment(item.updated_at).format(
                      "DD MMM YYYY, HH:mm"
                    )}
                  />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default DialogInfoFiles;
