import React, { useState } from "react";

import { Dialog, DialogContent, DialogActions, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import { URL_API } from "../../../../constants/config-api";

import { FORMAT_TYPE_IMAGE } from "../../../../constants/config-type-file";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    fontFamily: "Roboto",
  },
  img: {
    // height: 255,
    height: "auto",
    display: "block",
    //   maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
  title: {
    fontFamily: "Roboto",
  },

  paperRadius: {
    borderRadius: "30px",
  },
}));

const DialogNavImg = ({
  //   classes,
  openDialogNavImg,
  setOpenDialogNavImg,
  listPictState,
  userToken,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  // const maxSteps = listPictState.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const images =
    listPictState.length > 0 &&
    listPictState.filter((el) => el.format_type_id === FORMAT_TYPE_IMAGE);

  /*
          `````````````````
          HANDLE MAX DIALOG
  
          `````````````````
      */
  //   const [maxWidth, setMaxWidth] = useState("md");

  return (
    <Dialog
      open={openDialogNavImg}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl" //*Default "md"
      onClose={() => setOpenDialogNavImg(false)}
    >
      <DialogContent
        style={{ textAlign: "center", height: "auto", width: 700 }}
      >
        <Grid container spacing={24} alignItems="center" justify="center">
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            autoplay={false}
            auto
          >
            {images.length > 0 &&
              images.map((step, index) => {
                // // console.log("Step : ", step)
                return (
                  <div key={step.id}>
                    {Math.abs(activeStep - index) <= 2 &&
                    step.format_type_id === FORMAT_TYPE_IMAGE ? (
                      <img
                        className={classes.img}
                        // src={URL_API + '/' + step.self.rel.attachment + "?token=" + userToken + "&mode=thumbnail"}
                        src={
                          URL_API +
                          "/" +
                          step.self.rel.content +
                          "?token=" +
                          userToken
                        }
                        alt={step.name}
                      />
                    ) : null}
                  </div>
                );
              })}
          </AutoPlaySwipeableViews>
        </Grid>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Typography variant="subtitle1" className={classes.title}></Typography>
        <MobileStepper
          style={{
            backgroundColor: "transparent",
          }}
          steps={images.length}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === images.length - 1}
            >
              {/* Next */}
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {/* Back */}
            </Button>
          }
        />
      </DialogActions>
      <br />
    </Dialog>
  );
};

export default DialogNavImg;
