import React from "react";
// @material-ui/core components
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Container,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchOutlined";

import styles from "./Style/landingStyle";

import HeaderNew from "./ComponentViewLandingPage/home/HeaderNew";
import NavRight from "./ComponentViewLandingPage/NaviRight";
import NavLeft from "./ComponentViewLandingPage/NavLeft";
import MapLinks from "./ComponentViewLandingPage/support/MapLinks";
import FastSolution from "./ComponentViewLandingPage/support/FastSolution";
import TouchUs from "./ComponentViewLandingPage/home/TouchUs";
import Footer from "./ComponentViewLandingPage/Footer";
import FloatingWhatsapp from "./ComponentViewLandingPage/components/FloatingWhatsapp";

import bgTouch from "../../assets/images/bgTouch.png";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const ViewSupport = () => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <HeaderNew
        color="transparent"
        routes={dashboardRoutes}
        brand="Performate"
        rightLinks={<NavRight origin="2" />}
        leftLinks={<NavLeft origin="2" />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white",
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={20} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            className={classes.title}
            style={{ color: "#333" }}>
            <b>Ada yang bisa kami bantu ?</b>
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.heroDesc}
            style={{ color: "#333" }}>
            Temukan jawaban dari pertanyaan anda disini
          </Typography>
        </Box>
        {/* <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth={650}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: 18, color: "#888" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box> */}
      </Box>

      <MapLinks />
      <FastSolution />

      <Box
        style={{
          backgroundImage: "url(" + bgTouch + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}>
        <Container>
          <TouchUs />
        </Container>
      </Box>

      <FloatingWhatsapp />

      <Footer />
    </MuiThemeProvider>
  );
};

export default ViewSupport;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d64253", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {},
});
