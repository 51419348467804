import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const HideExtendPersonal = ({
  classes,
  onExtendPackage,
  userLicenseCount,
  licenseDurationCount,
  pmoVisitCount,
  togglePmo,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Box display="flex">
          <Box display="flex" alignItems="center" marginRight={4}>
            <PersonIcon
              style={{ color: "#ff9e0c", marginRight: 5 }}
              fontSize="small"
            />
            <Typography variant="subtitle2" className={classes.title}>
              <b>{userLicenseCount} User</b>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginRight={4}>
            <WatchLaterIcon
              style={{ color: "#ff9e0c", marginRight: 5 }}
              fontSize="small"
            />
            <Typography variant="subtitle2" className={classes.title}>
              <b>{licenseDurationCount} Bulan</b>
            </Typography>
          </Box>
          {togglePmo && (
            <Box display="flex" alignItems="center" marginRight={4}>
              <ConfirmationNumberIcon
                style={{ color: "#ff9e0c", marginRight: 5 }}
                fontSize="small"
              />
              <Typography variant="subtitle2" className={classes.title}>
                <b>{pmoVisitCount} Consultant Visit</b>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Button
          variant="outlined"
          className={classes.btnExtndBg}
          startIcon={<AddCircleIcon style={{ color: "#ff9e0c" }} />}
          onClick={() => onExtendPackage(true)}>
          <b>Tambah</b>
        </Button>
      </Box>
    </Box>
  );
};

export default HideExtendPersonal;
