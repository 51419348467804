import React, { useContext, useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  Box,
  Checkbox,
  LinearProgress,
  FormControlLabel,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import clsx from "clsx";
import _ from "lodash";

import ContextGlobalDrive from "../../context/ContextDrive";
import EnhancedTableHead from "./EnhancedTableHead";
import FoldersList from "./FoldersList";

import FilesList from "./FilesList";
import sortArrOfObj from "../../../../utilities/sortArrOfObj";
import FolderGallery from "./FolderGallery";
import UserListGallery from "./UserListGallery";
import GoalListGallery from "./GoalListGallery";
import EvidenceListGallery from "./EvidenceListGallery";
import MAListGallery from "./MAListGallery";
import EvidenceListMAGallery from "./EvidenceListMAGallery";
import FolderRecycleBin from "./FolderRecycleBin";
import RecycleBinFolder from "./RecycleBinFolder";
import RecycleBinFile from "./RecycleBinFile";
import FolderRecovered from "./FolderRecovered";
import RecoveredFolderList from "./RecoveredFolderList";
import RecoveredFilesList from "./RecoveredFilesList";

function ListView({
  classes,
  listFolders,
  listFiles,
  listGalleryGR,
  listGoalGallery,
  listGalleryEvidence,
  listMAGallery,
  listMAGalleryEvidence,
  listFolderRecycleBin,
  listFileRecycleBin,
  listRecoveredFolder,
  listRecoveredFile,
}) {
  const context = useContext(ContextGlobalDrive);
  const currentLoc = window.location.pathname;

  // Beadcrumb
  let breadcrumb = localStorage.getItem("breadcrumb");
  breadcrumb = breadcrumb ? breadcrumb.split(",") : [];

  // Initial Hide Folder Recovered if no folders or files on it.
  let showFolderRecovered = false;

  let folderCount = 0;
  let fileCount = 0;

  const isFolderSelected = (id) => context.selectedFolder.indexOf(id) !== -1;
  const isFileSelected = (id) => context.selectedFile.indexOf(id) !== -1;
  const sortOptions = [
    {
      name: "By Name",
      value: "name",
    },
    {
      name: "By Created date",
      value: "created_at",
    },
    {
      name: "By Modified date",
      value: "modified_at",
    },
    {
      name: "By Size",
      value: "size",
    },
  ];

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [textSorting, setTextSorting] = useState("Sort");
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [sorting, setSorting] = useState("name");
  const [toggleSort, setToggleSort] = useState(false);

  const [pathLoc, setPathLoc] = useState("");

  // GET Id Folder from pahtname
  useEffect(() => {
    const pathArray = currentLoc.split("/");
    const checkFolderId = pathArray[pathArray.length - 1];

    if (checkFolderId !== undefined && checkFolderId !== "") {
      setPathLoc(checkFolderId);
    }
  }, [currentLoc]);

  /* ====================================================
    LOCAL Functions / Methods
  ==================================================== */

  // List Gallery Variable
  // const listGalleryGoal = _.uniqBy(listGoalGallery, "goal_id"); // Goal Gallery by User
  // const countGallery = _.groupBy(listGoalGallery, "goal_id"); // Count Goal Gallery
  const listGalleryMA = _.uniqBy(listMAGallery, "measured_activity_id"); // MA Gallery By Goal
  const countMAGallery = _.groupBy(listMAGallery, "measured_activity_id"); // Count MA Gallery

  // Search Value Drive
  const searchValueDrive = localStorage.getItem("filemanager_search");

  // Set Length Files & Folders
  if (breadcrumb[0] === "Recycle Bin") {
    folderCount = listFolderRecycleBin.length;
    fileCount = listFileRecycleBin.length;
  } else if (breadcrumb[0] === "Gallery" && breadcrumb.length === 3) {
    fileCount = listGalleryEvidence.length;
  } else if (breadcrumb[0] === "Gallery" && breadcrumb.length === 4) {
    fileCount = listMAGalleryEvidence.length;
  } else if (breadcrumb[0] === "Recovered Files & Folder") {
    folderCount = listRecoveredFolder.length;
    fileCount = listRecoveredFile.length;
  } else {
    folderCount = listFolders.length;
    fileCount = listFiles.length;
  }

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  // HandleSelectSort
  const handleSelctSort = (data) => {
    setTextSorting(data.name);
    setSorting(data.value);
    setAnchorElSort(null);
  };

  // Selected All Folder List
  const handleSelectAllFolder = (event) => {
    if (event.target.checked) {
      const newSelectedsFolder = listFolders.map((n) => n.id);
      const newSelectedsRB = listFolderRecycleBin.map((n) => n.id);
      const newSelectedsFoldersRecovered = listRecoveredFolder.map((n) => n.id);

      if (breadcrumb[0] === "Recycle Bin") {
        context.setSelectedFolder(newSelectedsRB);
      } else if (breadcrumb[0] === "Recovered Files & Folder") {
        context.setSelectedFolder(newSelectedsFoldersRecovered);
      } else {
        context.setSelectedFolder(newSelectedsFolder);
      }

      // // console.log("selAll", newSelecteds);
      return;
    }
    context.setSelectedFolder([]);
  };

  // Selected All File List
  const handleSelectAllFile = (event) => {
    if (event.target.checked) {
      const newSelectedsFile = listFiles.map((n) => n.id);
      const newSelectedsRB = listFileRecycleBin.map((n) => n.id);
      const newSelectedsGalleryGoal = listGalleryEvidence.map((n) => n.id);
      const newSelectedsGalleryMA = listMAGalleryEvidence.map((n) => n.id);
      const newSelectedsFilesRecovered = listRecoveredFile.map((n) => n.id);

      if (breadcrumb[0] === "Recycle Bin") {
        context.setSelectedFile(newSelectedsRB);
      } else if (breadcrumb[0] === "Gallery" && breadcrumb.length === 3) {
        context.setSelectedFile(newSelectedsGalleryGoal);
      } else if (breadcrumb[0] === "Gallery" && breadcrumb.length === 4) {
        context.setSelectedFile(newSelectedsGalleryMA);
      } else if (breadcrumb[0] === "Recovered Files & Folder") {
        context.setSelectedFile(newSelectedsFilesRecovered);
      } else {
        context.setSelectedFile(newSelectedsFile);
      }
      // // console.log("selAll", newSelecteds);
      return;
    }
    context.setSelectedFile([]);
  };

  // Some conditional for Folder Recovered, Show it or Hide it
  if (context.folderRestored.length > 0 || context.fileRestored.length > 0) {
    // console.log("Show Recovered", true);
    showFolderRecovered = true;
  } else {
    showFolderRecovered = false;
  }

  return (
    <Box style={{ marginTop: 5 }}>
      <TableContainer>
        <div className={classes.rootFolder}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              {context.toggleSelect && (
                <FormControlLabel
                  label={
                    <p
                      style={{ color: "#aaa", fontSize: 14 }}
                      className={classes.title}
                    >
                      Select Folder
                    </p>
                  }
                  control={
                    <Checkbox
                      style={{ marginLeft: 20 }}
                      indeterminate={
                        context.selectedFolder.length > 0 &&
                        context.selectedFolder.length < folderCount
                      }
                      checked={
                        folderCount > 0 &&
                        context.selectedFolder.length === folderCount
                      }
                      onChange={handleSelectAllFolder}
                      className={classes.checkboxRoot}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                    />
                  }
                />
              )}

              {context.toggleSelect && (
                <FormControlLabel
                  label={
                    <p
                      style={{ color: "#aaa", fontSize: 14 }}
                      className={classes.title}
                    >
                      Select File
                    </p>
                  }
                  control={
                    <Checkbox
                      style={{ marginLeft: 20 }}
                      indeterminate={
                        context.selectedFile.length > 0 &&
                        context.selectedFile.length < fileCount
                      }
                      checked={
                        fileCount > 0 &&
                        context.selectedFile.length === fileCount
                      }
                      onChange={handleSelectAllFile}
                      className={classes.checkboxRoot}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                    />
                  }
                />
              )}
            </Box>

            <Box display="flex">
              <Button
                className={classes.buttonSimple}
                onClick={handleClickSort}
              >
                {textSorting}
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorElSort}
                keepMounted
                open={Boolean(anchorElSort)}
                onClose={() => setAnchorElSort(null)}
              >
                {sortOptions.map((item, idx) => (
                  <MenuItem
                    key={idx}
                    className={classes.textItemSort}
                    onClick={() => handleSelctSort(item)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>

              <IconButton onClick={() => setToggleSort((prev) => !prev)}>
                {toggleSort ? (
                  <ArrowDownwardIcon style={{ fontSize: 18 }} />
                ) : (
                  <ArrowUpwardIcon style={{ fontSize: 18 }} />
                )}
              </IconButton>
            </Box>
          </Box>

          {context.loadFolders && (
            <Box>
              <LinearProgress />
            </Box>
          )}
          <Table size="small">
            <EnhancedTableHead classes={classes} />
            <TableBody>
              {/* Folder Gallery */}
              {searchValueDrive === null && breadcrumb.length === 0 && (
                <FolderGallery classes={classes} />
              )}

              {/* Folder Recycle Bin */}
              {searchValueDrive === null && breadcrumb.length === 0 && (
                <FolderRecycleBin classes={classes} />
              )}

              {searchValueDrive === null &&
                breadcrumb.length === 0 &&
                showFolderRecovered && <FolderRecovered classes={classes} />}

              {pathLoc === "gallery" &&
                listGalleryGR.length > 0 &&
                listGalleryGR
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <UserListGallery key={i} item={item} classes={classes} />
                  ))}

              {pathLoc === "recyclebin" &&
                listFolderRecycleBin.length > 0 &&
                listFolderRecycleBin
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <RecycleBinFolder
                      key={i}
                      item={item}
                      classes={classes}
                      isFolderSelected={isFolderSelected}
                    />
                  ))}

              {pathLoc === "recovered" &&
                listRecoveredFolder.length > 0 &&
                listRecoveredFolder
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <RecoveredFolderList
                      key={i}
                      item={item}
                      classes={classes}
                      isFolderSelected={isFolderSelected}
                    />
                  ))}

              {breadcrumb.length === 2 &&
                listGoalGallery.length > 0 &&
                listGoalGallery
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <GoalListGallery
                      key={i}
                      item={item}
                      classes={classes}
                      // count={countGallery}
                    />
                  ))}

              {/* Loop Folders */}
              {listFolders
                .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                .map((item, index) => {
                  return (
                    <FoldersList
                      classes={classes}
                      item={item}
                      index={index}
                      key={item.id}
                      isFolderSelected={isFolderSelected}
                    />
                  );
                })}

              {/* List MA Gallery */}
              {breadcrumb.length === 3 &&
                listGalleryMA.length > 0 &&
                listGalleryMA
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, index) => (
                    <MAListGallery
                      key={index}
                      item={item}
                      classes={classes}
                      count={countMAGallery}
                    />
                  ))}

              {pathLoc === "recyclebin" &&
                listFileRecycleBin.length > 0 &&
                listFileRecycleBin
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <RecycleBinFile
                      key={i}
                      item={item}
                      classes={classes}
                      isFileSelected={isFileSelected}
                    />
                  ))}

              {pathLoc === "recovered" &&
                listRecoveredFile.length > 0 &&
                listRecoveredFile
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <RecoveredFilesList
                      key={i}
                      item={item}
                      classes={classes}
                      isFileSelected={isFileSelected}
                    />
                  ))}

              {/* Evidence Gallery List */}
              {breadcrumb.length === 3 &&
                listGalleryEvidence.length > 0 &&
                listGalleryEvidence
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <EvidenceListGallery
                      key={i}
                      item={item}
                      classes={classes}
                      isFileSelected={isFileSelected}
                    />
                  ))}

              {/* Evidence MA Gallery List */}
              {breadcrumb.length === 4 &&
                listMAGalleryEvidence.length > 0 &&
                listMAGalleryEvidence
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <EvidenceListMAGallery
                      key={i}
                      item={item}
                      classes={classes}
                      isFileSelected={isFileSelected}
                    />
                  ))}

              {/* List Gallery Search */}
              {searchValueDrive !== null &&
                searchValueDrive !== undefined &&
                listGalleryEvidence.length > 0 &&
                listGalleryEvidence
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <EvidenceListGallery
                      key={i}
                      item={item}
                      classes={classes}
                    />
                  ))}

              {/* List Gallery MA Search */}
              {searchValueDrive !== null &&
                searchValueDrive !== undefined &&
                context.listGalleryEvidenceMAR.length > 0 &&
                context.listGalleryEvidenceMAR
                  .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                  .map((item, i) => (
                    <EvidenceListMAGallery
                      key={i}
                      item={item}
                      classes={classes}
                    />
                  ))}

              {/* Loop Files */}
              {listFiles
                .sort(sortArrOfObj(sorting, toggleSort ? "desc" : "asc"))
                .map((item, index) => {
                  return (
                    <FilesList
                      classes={classes}
                      item={item}
                      index={index}
                      key={item.id}
                      isFileSelected={isFileSelected}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </Box>
  );
}

export default ListView;
