import React, { useEffect, useState } from "react";
import { styles } from "../Style/StyleAttendanceHome";
import { navigate } from "hookrouter";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
import { isEmpty } from "lodash";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#9B1B2D",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#EC5267",
  },
}))(LinearProgress);

const ReportCard = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [data, setData] = useState({ absence: {}, presence: {} });

  useEffect(() => {
    fetchAbsence();
    fetchPresence();
  }, []);

  const fetchAbsence = async () => {
    try {
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/report-account/absence`
      );
      setData({ ...data, absence: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPresence = async () => {
    try {
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/report-account/presence`
      );
      setData({ ...data, presence: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentDateNumber = () => {
    let date = new Date();
    return date.getDate();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background:
            "linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)",
          borderRadius: 11,
          padding: "10px 20px",
          margin: "10px 10px",
        }}
      >
        <CardContent style={{ flex: "1 0 auto" }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <img
                src={require("../../../../assets/images/note.png")}
                alt="attendance"
                width={75}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: 25,
                }}
              >
                <Grid item xs={8}>
                  <Typography variant="body2" style={{ color: "white" }}>
                    <b>{data.presence.kehadiran}</b>/{getCurrentDateNumber()}{" "}
                    Kehadiran
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    style={{ color: "white", textAlign: "right" }}
                  >
                    {data.presence.kehadiran
                      ? Math.floor(
                          (data.presence.kehadiran / getCurrentDateNumber()) *
                            100
                        )
                      : 0}
                    %
                  </Typography>
                </Grid>
              </Grid>
              <BorderLinearProgress
                variant="determinate"
                value={
                  data.presence.kehadiran
                    ? Math.floor(
                        (data.presence.kehadiran / getCurrentDateNumber()) * 100
                      )
                    : 0
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
};

export default ReportCard;
