import React, { useContext, useState } from "react";
import { Box, Chip, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import ContextTask from "../../Context/ContextTask";
import { SET_CUSTOM_FILTER } from "../../Context/types";
import DialogDeleteTodos from "./dialog/DialogDeleteTodos";
import DialogCompleteTodos from "./dialog/DialogCompleteTodos";

const Label = ({ classes }) => {
  const { todoState, todoDispatch, setTriggerTodo, selected } =
    useContext(ContextTask);

  const [openDeleteTodos, setOpenDeleteTodos] = useState(false);
  const [openCompleteTodos, setOpenCompleteTodos] = useState(false);

  const handleIncomplete = () => {
    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: todoState.filterOwner,
        filterStatus: todoState.filterStatus,
        filterPriority: false,
        filterCompleted: todoState.filterCompleted,
        ownerId: todoState.ownerId,
        statusId: todoState.statusId,
        priorityId: todoState.priorityId,
        completedId: todoState.completedId,
      },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  const handleOwner = () => {
    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: false,
        filterStatus: todoState.filterStatus,
        filterPriority: todoState.filterPriority,
        filterCompleted: todoState.filterCompleted,
        ownerId: todoState.ownerId,
        statusId: todoState.statusId,
        priorityId: todoState.priorityId,
        completedId: todoState.completedId,
      },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  const handleStatus = () => {
    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: todoState.filterOwner,
        filterStatus: false,
        filterPriority: todoState.filterPriority,
        filterCompleted: todoState.filterCompleted,
        ownerId: todoState.ownerId,
        statusId: todoState.statusId,
        priorityId: todoState.priorityId,
        completedId: todoState.completedId,
      },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  const handlePriority = () => {
    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: todoState.filterOwner,
        filterStatus: todoState.filterStatus,
        filterPriority: false,
        filterCompleted: todoState.filterCompleted,
        ownerId: todoState.ownerId,
        statusId: todoState.statusId,
        priorityId: todoState.priorityId,
        completedId: todoState.completedId,
      },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  const handleCompleted = () => {
    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: todoState.filterOwner,
        filterStatus: todoState.filterStatus,
        filterPriority: todoState.filterPriority,
        filterCompleted: false,
        ownerId: todoState.ownerId,
        statusId: todoState.statusId,
        priorityId: todoState.priorityId,
        completedId: todoState.completedId,
      },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  return (
    <Box display="flex" alignItems="center" mb={2}>
      {selected.length > 0 && (
        <Box mr={1}>
          <Button
            size="small"
            variant="outlined"
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={() => setOpenDeleteTodos(true)}
          >
            Delete {selected.length}
          </Button>
        </Box>
      )}
      {selected.length > 0 && (
        <Box mr={4}>
          <Button
            size="small"
            variant="outlined"
            className={classes.button}
            startIcon={<CheckCircleRoundedIcon />}
            onClick={() => setOpenCompleteTodos(true)}
          >
            Set Complete {selected.length}
          </Button>
        </Box>
      )}
      {todoState.filterIncomplete && (
        <Chip
          variant="outlined"
          size="small"
          label="Incomplete"
          onDelete={handleIncomplete}
        />
      )}
      <Box mx={0.25} />
      {todoState.filterOwner && (
        <Chip
          variant="outlined"
          size="small"
          label={`Owner: ${todoState.ownerId
            .map((el) => el.member.first_name)
            .join(", ")}`}
          onDelete={handleOwner}
        />
      )}
      <Box mx={0.25} />
      {todoState.filterStatus && (
        <Chip
          variant="outlined"
          size="small"
          label={`Status: ${todoState.statusId.join(", ")}`}
          onDelete={handleStatus}
        />
      )}
      <Box mx={0.25} />
      {todoState.filterPriority && (
        <Chip
          variant="outlined"
          size="small"
          label={`Priority: ${todoState.priorityId.join(", ")}`}
          onDelete={handlePriority}
        />
      )}
      <Box mx={0.25} />
      {todoState.filterCompleted && (
        <Chip
          variant="outlined"
          size="small"
          label={
            todoState.completedId === "2"
              ? "Completed"
              : todoState.completedId === "1"
              ? "Incompleted"
              : ""
          }
          onDelete={handleCompleted}
        />
      )}

      <DialogDeleteTodos
        classes={classes}
        openDeleteTodos={openDeleteTodos}
        setOpenDeleteTodos={setOpenDeleteTodos}
      />

      <DialogCompleteTodos
        classes={classes}
        openCompleteTodos={openCompleteTodos}
        setOpenCompleteTodos={setOpenCompleteTodos}
      />
    </Box>
  );
};

export default Label;
