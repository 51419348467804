import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  IconButton,
  Divider,
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import axios from "axios";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import ContextNewSO from "../Context/ContextNewSO";

import DialogError from "../../../components/DialogError";

import { styles } from "../Style/StyleDialog";
import { VISIBILITY_DISABLED } from "../Constants/config-classification-so-master";

import { URL_API } from "../../../constants/config-api";

/*
    ````````````````````````````````````````````
    GLOBAL STATE : 

        context : {

            dataDetail : String,
            userTokenState: String,
            setOpenDialogTambahHead: Boolean,
            isOpenDialogEditNodeSO: Boolean,
            setOpenDialogEditNodeSO : Boolean
            
        };


     URUTAN PILIHAN in Edit Posisi - HEAD: 
        
        1. Ubah Kategori Posisi || "panel1"
        2. Ubah Atasan || "panel2"
        3. Ubah Unit || "panel3"
        4. Ubah Nama Jabatan || "panel4"
        5. Ubah Karyawan || "panel5"
        
    ````````````````````````````````````````````    
*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogEditNodeSoHEAD = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  const [listJenisUnit, setListJenisUnit] = useState([]);
  const [listNamaUnit, setListNamaUnit] = useState([]);

  const [listPositionTitle, setListPositionTitle] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const [listAtasan, setListAtasan] = useState([]);

  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabledPilihAtasan, setDisablePilihAtasan] = useState(false);
  const [isDisabledStructureUnitType, setDisabledStructureUnitType] =
    useState(false);
  const [isDisabledStructureUnit, setDisabledStructureUnit] = useState(false);
  const [isDisabledStructurePositionTitle, setDisabledStructurePositionTitle] =
    useState(false);
  const [isDisabledEmployee, setDisabledEmployee] = useState(false);

  /*
        `````````````````````
        PILIH KATEGORI POSISI

        `````````````````````
    */
  const [isKategoriPosisiChoosed, setKategoriPosisiChoosed] = useState(false);
  const [kategoriPosisiName, setKategoriPosisiName] = useState("");
  const [kategoriPosisiId, setKategoriPosisiId] = useState(1);

  const handleChooseKategoriPosisi = (e, item) => {
    e.preventDefault();
    // console.log("Item : ", item);

    setKategoriPosisiChoosed(true);
    setKategoriPosisiName(item.name);
    setKategoriPosisiId(item.id);

    //*Set state to next panel
    if (isDisabledPilihAtasan !== true) {
      setExpanded("panel2");
      handleChange("panel2");
    }
  };

  /*
            ````````````
            PILIH ATASAN

            ````````````
        */
  const [isAtasanChoosed, setAtasanChoosed] = useState(false);
  const [atasanName, setAtasanName] = useState("");
  const [atasanId, setAtasanId] = useState("");

  const handleChooseAtasan = (e, item) => {
    e.preventDefault();
    // console.log("Item : ", item);

    setAtasanChoosed(true);
    setAtasanName(item.structure_position_title_name);
    setAtasanId(item.id);

    //*Set state to next panel
    if (isDisabledStructureUnit !== true) {
      setExpanded("panel3");
      handleChange("panel3");
    }
  };

  useEffect(() => {
    if (
      context.isOpenDialogEditNodeSO == true ||
      kategoriPosisiId !== 0 ||
      context.isSucessCreateEmployee == true ||
      context.isSuccessEditMemberEmployee == true
    ) {
      //*
      context.setSuccessEditNodeSOInHEAD(false);

      //*
      context.setTreeItems([
        {
          id: null,
          parent_id: null,
          structure_position_title_id: null,
          structure_position_title_name: null,

          structure_unit_id: null,
          structure_unit_name: null,

          structure_unit_type_id: null,
          structure_unit_type_name: null,

          member_first_name: null,
          member_last_name: null,
          self: null,

          classification: {
            id: null,
          },
          child: [],
        },
      ]);

      /*
                ``````````````````````````````````````````````
                if "STRUCTURE_POSITION_CLASSIFICATION_ID_HEAD"

                ``````````````````````````````````````````````
            */

      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/structure-position/${context.dataDetailIconMoreHorizon.id}/update?classification=${kategoriPosisiId}`
          )
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data.updated_at !== null) {
                setUpdatedAt(response.data.data.updated_at);
              }

              //************************************************************************************ */

              if (response.data.data.fields.hasOwnProperty("user_id")) {
                if (
                  response.data.data.fields.user_id.visibility ==
                  VISIBILITY_DISABLED
                ) {
                  setDisabledEmployee(true);
                }

                if (response.data.data.userCollections !== null) {
                  if (response.data.data.userCollections !== undefined) {
                    if (response.data.data.userCollections.length > 0) {
                      setListEmployee(response.data.data.userCollections);
                    }
                  }
                }
              }

              if (response.data.data.fields.hasOwnProperty("parent_id")) {
                if (
                  response.data.data.fields.parent_id.visibility ==
                  VISIBILITY_DISABLED
                ) {
                  // setDisablePilihAtasan(true);
                }

                if (response.data.data.parentCollections !== undefined) {
                  if (response.data.data.parentCollections.length > 0) {
                    setListAtasan(response.data.data.parentCollections);
                  }
                }
              }

              if (
                response.data.data.fields.hasOwnProperty(
                  "structure_unit_type_id"
                )
              ) {
                if (
                  response.data.data.fields.structure_unit_type_id.visibility ==
                  VISIBILITY_DISABLED
                ) {
                  setDisabledStructureUnitType(true);
                }

                if (response.data.data.structureUnitTypeCollections !== null) {
                  if (
                    response.data.data.structureUnitTypeCollections !==
                    undefined
                  ) {
                    if (
                      response.data.data.structureUnitTypeCollections.length > 0
                    ) {
                      setListJenisUnit(
                        response.data.data.structureUnitTypeCollections
                      );
                    }
                  }
                }
              } else {
                setDisabledStructureUnitType(true);
              } //Kalau ga ada property-nya maka JADIIN BATU AJA !

              if (
                response.data.data.fields.hasOwnProperty("structure_unit_id")
              ) {
                if (
                  response.data.data.fields.structure_unit_id.visibility ==
                  VISIBILITY_DISABLED
                ) {
                  setDisabledStructureUnit(true);
                }

                if (response.data.data.structureUnitCollections !== null) {
                  if (response.data.data.structureUnitCollections.length > 0) {
                    setListNamaUnit(
                      response.data.data.structureUnitCollections
                    );
                  }
                }
              }

              if (
                response.data.data.fields.hasOwnProperty(
                  "structure_position_title_id"
                )
              ) {
                if (
                  response.data.data.fields.structure_position_title_id
                    .visibility == VISIBILITY_DISABLED
                ) {
                  setDisabledStructurePositionTitle(true);
                }

                if (
                  response.data.data.structurePositionTitleCollections !== null
                ) {
                  if (
                    response.data.data.structurePositionTitleCollections !==
                    undefined
                  ) {
                    if (
                      response.data.data.structurePositionTitleCollections
                        .length > 0
                    ) {
                      setListPositionTitle(
                        response.data.data.structurePositionTitleCollections
                      );
                    }
                  }
                }
              }

              //********************************************************************/
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }
    }
  }, [
    context.isOpenDialogEditNodeSO,
    kategoriPosisiId,
    context.isSucessCreateEmployee,
    context.isSuccessEditMemberEmployee,
  ]);

  /*  
        `````````````````````````````````````````````````````````````````````````````````
           HANDLE DELETE EMPLOYEE MEMBER && HANDLE DROPDOWN EDIT & DELETE EMPLOYEE MEMBER

        `````````````````````````````````````````````````````````````````````````````````
    */

  //    const [ isOpenDialogDeleteMemberEmployee, setOpenDialogDeleteMemberEmployee ] = useState(false);
  //    const [ isSuccessDeleteMemberEmployee, setSuccessDeleteMemberEmployee ] = useState(false);

  const [anchorElMemberEmployee, setAnchorElMemberEmployee] = useState(null);

  const handleOpenDropdownEditAndDeleteMemberEmployee = (e, data) => {
    setAnchorElMemberEmployee(e.currentTarget);
    // console.log("Data from 'more_horizon' Member Employee : ", data);

    context.setDataDetail(data);

    //setSuccessDeleteMemberEmployee(false);
    // context.setSuccessEditNamaUnit(false);
  };

  const handleCloseDropdownEditAndDeleteMemberEmployee = () => {
    setAnchorElMemberEmployee(null);
  };

  /*
        ```````
        EXPAND

        ```````
    */
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  /*
        ``````````````````````
        DIALOG EDIT JENIS UNIT

        ``````````````````````
    */
  const [namaJenisUnitChoosed, setNamaJenisUnitChoosed] = useState("");

  const handleChooseJenisUnit = (e, item) => {
    e.preventDefault();
    // console.log("Item : ", item);

    context.setJenisUnitChoosed(true);
    setNamaJenisUnitChoosed(item.name);
    context.setStructureUnitTypeId(item.id);
  };

  const handleChooseNamaUnit = (e, item) => {
    e.preventDefault();
    // console.log("Nama Unit : ", item);

    context.setNamaUnitChoosedNow(true);
    context.setNamaUnitChoosed(item.name);
    context.setStructureUnitId(item.id);

    context.setJenisUnitChoosed(false);

    //*Set state to next panel
    if (isDisabledStructurePositionTitle !== true) {
      setExpanded("panel4");
      handleChange("panel4");
    }
  };

  /*
        ``````````````````
        GET LIST NAMA UNIT

        ``````````````````
    */
  useEffect(() => {
    if (context.isJenisUnitChoosed == true) {
      if (context.userTokenState !== "") {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/structure-position/create?classification=1&options[filter][structure_unit_type_id]=${context.structureUnitTypeId}`
          )
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data !== null) {
                if (response.data.data.structureUnitCollections !== undefined) {
                  setListNamaUnit(response.data.data.structureUnitCollections);
                }
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }
    }
  }, [context.isJenisUnitChoosed]);

  /*
        ````````````````````````````
        DIALOG EDIT POSITION TITLE

        ````````````````````````````
    */
  // const [ isOpenDialogCreatePositionTitle, setOpenDialogCreatePositionTitle ] = useState(false); //*Moved to Global State

  const handleTambahJabatan = () => {
    context.setOpenDialogEditNodeSO(false);
    context.setOpenDialogCreatePositionTitleInEditNodeSoHEAD(true);
  };

  const handleChoosePositionTitle = (e, data) => {
    e.preventDefault();

    // console.log("Wew Position Title : ", data);
    context.setPositionTitleId(data.id);
    context.setPositionTitleName(data.name);
    context.setPositionTitleChoose(true);

    //*Set state to next panel
    setExpanded("panel5");
    handleChange("panel5");
  };

  /*
        ````````````````````
        DIALOG EDIT KARYAWAN

        ````````````````````
    */
  const handleChooseEmployee = (e, data) => {
    e.preventDefault();

    // console.log("Wew Employee : ", data);
    context.setEmployeeId(data.id);
    context.setFirstName(data.member?.first_name);
    context.setLastName(data.member?.last_name);

    //*Set state to next panel
    setExpanded("panelX");
    handleChange("panelX");
  };

  // const [ userTokenState, setUserTokenState ] = useState('');

  // useEffect(() => {

  //     if(context.isOpenDialogEditNodeSO == true){

  //         const userToken = localStorage.getItem('userToken');
  //         setUserTokenState(userToken);

  //     };
  //     // console.log("Context in Dialog Delete: ", context.dataDetail.id);

  // }, [context.isOpenDialogEditNodeSO]);

  /*
        ````````````````````
        HANDLE DIALOG ERROR

        ````````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [listError, setListError] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);

  const [loader, setLoader] = useState(false);

  const handleSubmitSimpanPerubahan = () => {
    setLoader(true);

    let data = {
      StructurePosition: {
        updated_at: updatedAt,
        parent_id:
          atasanId !== ""
            ? atasanId
            : context.dataDetailIconMoreHorizon.parent_id,
        structure_position_title_id:
          context.positionTitleId !== ""
            ? context.positionTitleId
            : context.dataDetailIconMoreHorizon.structure_position_title_id,
        structure_unit_id:
          context.structureUnitId !== ""
            ? context.structureUnitId
            : context.dataDetailIconMoreHorizon.structure_unit_id,
        structure_unit_type_id:
          context.structureUnitTypeId !== ""
            ? context.structureUnitTypeId
            : context.dataDetailIconMoreHorizon.structure_unit_type_id,
        user_id:
          context.employeeId !== ""
            ? context.employeeId
            : context.dataDetailIconMoreHorizon.user_id,
      },
    };

    // console.log("Data : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .put(
          URL_API +
            `/human-resource/structure-position/${context.dataDetailIconMoreHorizon.id}?classification=${kategoriPosisiId}`,
          data
        )
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          context.setSnackbarResponse200(true);
          context.setMessages("Perubahan berhasil di simpan :)");

          context.setDataDetail({ id: context.dataDetail.parent_id });
          context.setSuccessEditNodeSOInHEAD(true);

          context.setOpenDialogEditNodeSO(false);
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              if (error.response.data.info.errors !== null) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);

                  //     if(error.response.data.info.errors[0].code == "VALIDATION_ERROR"){

                  //         setTextErrorInformation(error.response.data.info.errors[0].description)
                  //         setOpenDialogError(true);
                  //     }
                }
              }
            }
            if (error.response.status === 422) {
              setErrorStatus(422);
              if (error.response.data.info.errors !== null) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                  // if(error.response.data.info.errors[0].code === "HAS_CHILD"){

                  //     setTextErrorInformation(error.response.data.info.errors[0].description)
                  //     setOpenDialogError(true);

                  // };
                }
              }
            }

            if (error.response.status == 404) {
              setErrorStatus(404);
              setTextErrorInformation(
                "Error 404 : Whoops, something went wrong !"
              );
              setOpenDialogError(true);
            }
          } else {
            setTextErrorInformation("Whoops, something went wrong !");
            setOpenDialogError(true);
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogEditNodeSO}
        onClose={() => context.setOpenDialogEditNodeSO(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Edit <i>Posisi</i>
            </b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <div className={classes.root}>
            {/* 
                            `````````````````````
                            PILIH KATEGORI POSISI

                            `````````````````````
                        */}
            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <List style={{ padding: 0 }} className={classes.root}>
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <IconButton size="small">
                      {expanded !== "panel1" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>

                    <ListItemText
                      id="ubah-kategori-posisi"
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {isKategoriPosisiChoosed == true ? (
                            <Fragment>
                              <b>Terpilih {kategoriPosisiName} </b>
                              <IconButton size="small">
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 14 }}
                                />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {kategoriPosisiId == 1 ? (
                                <b>
                                  Kategori Posisi ( <i>HEAD</i> )
                                </b>
                              ) : (
                                "-"
                              )}
                            </Fragment>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#d64253" }}
                      >
                        Ubah <i>Kategori Posisi</i>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <div
                  className={classes.columnSeratusPersen}
                  style={{ textAlign: "left" }}
                >
                  {listKategoriPosisi.length > 0 &&
                    listKategoriPosisi.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          variant="outlined"
                          className={classes.buttonOutlined}
                          onClick={(e) => handleChooseKategoriPosisi(e, item)}
                        >
                          <b>{item.name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}
                </div>
              </ExpansionPanelDetails>
              <Divider />
            </ExpansionPanel>

            {/* 
                            ````````````
                            PILIH ATASAN

                            ````````````
                        */}
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              disabled={
                isDisabledPilihAtasan == true ||
                context.dataDetail.structure_unit_type_name == null
                  ? true
                  : false
              }
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <List style={{ padding: 0 }} className={classes.root}>
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <IconButton size="small">
                      {expanded !== "panel2" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>

                    <ListItemText
                      id="ubah-atasan"
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {isAtasanChoosed == true ? (
                            <Fragment>
                              <b>Terpilih {atasanName} </b>
                              <IconButton size="small">
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 14 }}
                                />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>{<b>Atasan</b>}</Fragment>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#d64253" }}
                      >
                        Ubah <i>Atasan</i>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <div
                  className={classes.columnSeratusPersen}
                  style={{ textAlign: "left" }}
                >
                  {listAtasan.length > 0 &&
                    listAtasan.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          variant="outlined"
                          className={classes.buttonOutlined}
                          onClick={(e) => handleChooseAtasan(e, item)}
                        >
                          <b>{item.structure_position_title_name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}
                </div>
              </ExpansionPanelDetails>
              <Divider />
            </ExpansionPanel>

            {/* 
                            ````````````````
                            PILIH JENIS UNIT

                            ```````````````
                        */}

            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              disabled={
                isDisabledStructureUnitType == true
                  ? // context.dataDetail.structure_unit_type_name == null
                    true
                  : false
              }
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <List style={{ padding: 0 }} className={classes.root}>
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <IconButton size="small">
                      {expanded !== "panel3" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>

                    <ListItemText
                      id="ubah-karyawan"
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {context.namaUnitChoosed !== "" ? (
                            <Fragment>
                              <b>
                                {"Terpilih " + namaJenisUnitChoosed + " "}{" "}
                                {context.namaUnitChoosed}
                              </b>
                              <IconButton size="small">
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 14 }}
                                />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {context.dataDetailIconMoreHorizon
                                .structure_unit_name !== null
                                ? context.dataDetailIconMoreHorizon
                                    .structure_unit_type_name +
                                  " " +
                                  context.dataDetailIconMoreHorizon
                                    .structure_unit_name
                                : "-"}
                            </Fragment>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#d64253" }}
                      >
                        Ubah Unit
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <div
                  className={classes.columnSeratusPersen}
                  style={{ textAlign: "left" }}
                >
                  {listJenisUnit.length > 0 &&
                    context.isJenisUnitChoosed !== true &&
                    listJenisUnit.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          onClick={(e) => handleChooseJenisUnit(e, item)}
                          variant="outlined"
                          className={classes.buttonOutlined}
                          endIcon={
                            <ArrowForwardIosIcon
                              style={{ fontSize: "12px", marginBottom: 1 }}
                            />
                          }
                        >
                          <b>{item.name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}

                  {listJenisUnit.length == 0 && (
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "red" }}
                    >
                      <IconButton size="small" style={{ marginBottom: 1 }}>
                        <ErrorIcon style={{ color: "red" }} />
                      </IconButton>
                      <b>
                        Belum ada <i>Jenis Unit</i>{" "}
                      </b>
                    </Typography>
                  )}

                  {listNamaUnit.length == 0 &&
                    context.isJenisUnitChoosed == true && (
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "red" }}
                      >
                        <IconButton size="small" style={{ marginBottom: 1 }}>
                          <ErrorIcon style={{ color: "red" }} />
                        </IconButton>
                        <b>
                          Belum ada <i>Nama Unit</i>{" "}
                        </b>
                      </Typography>
                    )}

                  {listNamaUnit.length > 0 &&
                    context.isJenisUnitChoosed == true &&
                    listNamaUnit.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          onClick={(e) => handleChooseNamaUnit(e, item)}
                          variant="outlined"
                          className={classes.buttonOutlined}
                          endIcon={
                            <ArrowForwardIosIcon
                              style={{ fontSize: "12px", marginBottom: 1 }}
                            />
                          }
                        >
                          <b>{item.name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}
                </div>
              </ExpansionPanelDetails>
              <Divider />

              <ExpansionPanelActions>
                {context.isJenisUnitChoosed == true ? (
                  <Fragment>
                    <Button
                      onClick={() => context.setJenisUnitChoosed(false)}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.buttonOutlined}
                      style={{ color: "grey" }}
                    >
                      Kembali ke pilihan &nbsp; <i>Jenis Unit</i>
                    </Button>
                    <Button
                      onClick={() =>
                        context.setOpenDialogCreateNamaUnitInEditHEAD(true)
                      }
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.buttonOutlined}
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Tambah &nbsp;<i> Nama Unit</i>
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    onClick={() => {
                      context.setOpenDialogCreateJenisUnitInEditHEAD(true);
                      context.setOpenDialogEditNodeSO(false);
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.buttonOutlined}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Tambah &nbsp;
                    <i>
                      {" "}
                      Jenis Unit{" "}
                      {listJenisUnit.length == 0 ? " sekarang" : "lagi"}
                    </i>
                  </Button>
                )}
              </ExpansionPanelActions>
            </ExpansionPanel>

            {/* 
                        ``````````````````
                        PILIH NAMA JABATAN

                        ``````````````````
                    */}
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              disabled={isDisabledStructurePositionTitle == true ? true : false}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <List style={{ padding: 0 }} className={classes.root}>
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <IconButton size="small">
                      {expanded !== "panel4" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>

                    <ListItemText
                      id="ubah-jabatan"
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {context.isPositionTitleChoosed == true ? (
                            <Fragment>
                              <b>Terpilih {context.positionTitleName} </b>
                              <IconButton size="small">
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 14 }}
                                />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {context.dataDetailIconMoreHorizon
                                .structure_position_title_name !== null ? (
                                <b>
                                  Jabatan ({" "}
                                  <i>
                                    {
                                      context.dataDetailIconMoreHorizon
                                        .structure_position_title_name
                                    }{" "}
                                  </i>{" "}
                                  )
                                </b>
                              ) : (
                                "-"
                              )}
                            </Fragment>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#d64253" }}
                      >
                        Ubah Jabatan
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <div
                  className={classes.columnSeratusPersen}
                  style={{ textAlign: "left" }}
                >
                  {listPositionTitle.length == 0 && (
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "red" }}
                    >
                      <IconButton size="small" style={{ marginBottom: 1 }}>
                        <ErrorIcon style={{ color: "red" }} />
                      </IconButton>
                      <b>
                        Belum ada <i>Nama Jabatan</i>
                      </b>
                    </Typography>
                  )}

                  {listPositionTitle.length > 0 &&
                    listPositionTitle.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          onClick={(e) => handleChoosePositionTitle(e, item)}
                          variant="outlined"
                          className={classes.buttonOutlined}
                        >
                          <b>{item.name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}
                </div>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  onClick={handleTambahJabatan}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp;<i>Jabatan</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>

            {/* 
                        ``````````````
                        EDIT KARYAWAN

                        ``````````````
                    */}
            <ExpansionPanel
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              disabled={isDisabledEmployee == true ? true : false}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <List style={{ padding: 0 }} className={classes.root}>
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <IconButton size="small">
                      {expanded !== "panel5" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>

                    <ListItemText
                      id="ubah-karyawan"
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {context.firstName !== "" ? (
                            <Fragment>
                              <b>
                                Terpilih {context.firstName + " "}{" "}
                                {context.lastName}
                              </b>
                              <IconButton size="small">
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 14 }}
                                />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <b>
                                <i>
                                  {context.dataDetailIconMoreHorizon
                                    .member_first_name !== null
                                    ? context.dataDetailIconMoreHorizon
                                        .member_first_name +
                                      " " +
                                      context.dataDetailIconMoreHorizon
                                        .member_last_name
                                    : "-"}
                                </i>
                              </b>
                            </Fragment>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#d64253" }}
                      >
                        Ubah <i>User</i>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <List className={classes.rootList}>
                  {listEmployee.length > 0 &&
                    listEmployee.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem
                          button
                          style={{ padding: 0 }}
                          onClick={(e) => handleChooseEmployee(e, item)}
                        >
                          <IconButton>
                            <AccountCircleIcon fontSize="large" />
                          </IconButton>

                          <ListItemText
                            id="label-employee"
                            primary={
                              // <Button
                              //     onClick={(e) => handleChooseEmployee(e, item)}
                              //     variant='outlined'
                              //     className={classes.buttonOutlined}
                              // >
                              //     <b>{item.first_name + " "}  {item.last_name}</b>
                              // </Button>
                              <Typography
                                variant="subtitle1"
                                className={classes.title}
                              >
                                <b>
                                  {item.member?.first_name + " "}{" "}
                                  {item.member?.last_name}
                                </b>
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                                style={{ color: "grey" }}
                              >
                                {item.email}
                              </Typography>
                            }
                          />

                          <ListItemSecondaryAction>
                            <Button
                              size="small"
                              variant="outlined"
                              className={classes.buttonOutlined}
                              style={
                                item.status.code ===
                                "user_status_id_enum_pending"
                                  ? {
                                      backgroundColor: "#ffd34f", //*yellow
                                      padding: 0,
                                    }
                                  : {
                                      backgroundColor: "#96ff96", //*green
                                      padding: 0,
                                    }
                              }
                            >
                              <Typography
                                variant="caption"
                                className={classes.title}
                                style={{ color: "white", marginLeft: 0 }}
                              >
                                {item.status.name}
                              </Typography>
                            </Button>
                          </ListItemSecondaryAction>

                          {/* <ListItemSecondaryAction>
                                                    <IconButton 
                                                        size='small'
                                                        onClick={(e) => handleOpenDropdownEditAndDeleteMemberEmployee(e, item)}
                                                        // onClick={() => console.error("Muncul edit & delete")}
                                                    >
                                                        <MoreHorizIcon/>
                                                    </IconButton>

                                                <StyledMenu
                                                    id="customized-menu-goal-result"
                                                    anchorEl={anchorElMemberEmployee} //*Position Title
                                                    keepMounted
                                                    open={Boolean(anchorElMemberEmployee)}
                                                    onClose={handleCloseDropdownEditAndDeleteMemberEmployee}
                                                >
                                                        <StyledMenuItem 
                                                            onClick= {() => context.setOpenDialogEditMemberEmployeeInEditHEAD(true)}
                                                        >                         
                                                            <ListItemText 
                                                                
                                                                primary = {
                                                                    <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                        <IconButton  size='small'>
                                                                            <EditIcon style={{color: 'green'}} />
                                                                        </IconButton>
                                                                        <b>Edit</b>
                                                                    </Typography>
                                                                }  
                                                            />

                                                        </StyledMenuItem>
                                                        <StyledMenuItem
                                                            onClick= {() => context.setOpenDialogDeleteEmployeeMember(true)}
                                                        >                         
                                                            <ListItemText 
                                                                primary = {

                                                                    <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                        <IconButton size='small'>
                                                                            <DeleteForeverIcon style={{color: 'red'}} />
                                                                        </IconButton>
                                                                        <b>Delete</b>
                                                                    </Typography>
                                                                }  
                                                            />
                                                        </StyledMenuItem>
                                                    </StyledMenu>

                                                </ListItemSecondaryAction> */}
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}

                  {listEmployee.length == 0 && (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        id="label-employee"
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: "red" }}
                          >
                            <IconButton
                              size="small"
                              style={{ marginBottom: 1 }}
                            >
                              <ErrorIcon style={{ color: "red" }} />
                            </IconButton>
                            <b>
                              Belum ada <i>User</i>
                            </b>
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  onClick={() => {
                    handleChange("panelX");
                    setExpanded("panelX");

                    context.setFirstName("Vacant");
                    context.setLastName("");
                    context.setEmployeeId(null);
                  }}
                  variant="outlined"
                  size="small"
                  className={classes.buttonOutlined}
                >
                  <i style={{ color: "black" }}>Set as vacant</i>
                </Button>

                <Button
                  onClick={() =>
                    context.setOpenDialogCreateEmployeeInEditHEAD(true)
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp; <i>User</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </div>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleSubmitSimpanPerubahan}
            variant="contained"
            className={classes.buttonDialog}
          >
            {loader !== true ? (
              "Simpan perubahan"
            ) : (
              <CircularProgress size={24} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        listError={listError}
        errorStatus={errorStatus}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogEditNodeSoHEAD);

const listKategoriPosisi = [
  {
    id: 1,
    name: "Head",
  },
  {
    id: 2,
    name: "Deputy Head",
  },
  {
    id: 3,
    name: "Assistant",
  },
  {
    id: 4,
    name: "Staff",
  },
];
