import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  Box,
} from "@material-ui/core";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogRemoveUnit({
  item,
  classes,
  openDialogRemoveUnit,
  setOpenDialogRemoveUnit,
}) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  /* ====================================================
    FUNCTION / LOCAL METHODS
  ==================================================== */
  const handleRemoveUnit = () => {
    setLoading(true);

    axiosConfig
      .delete(
        `${URL_API}/drive/folder/${context.detailFolder.id}/permission/${item.id}`
      )
      .then((response) => {
        // console.log("Response Set Persmission", response);

        if (response.status === 200) {
          context.setSuccessTriggerPermission(
            Math.floor(Math.random() * 1000 + 1)
          );

          setOpenDialogRemoveUnit(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogRemoveUnit}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Remove Unit</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 375 }}>
        <Box marginBottom={2} textAlign="center">
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#333" }}
          >
            Apakah Anda yakin ingin menghapus Unit <br />
            <strong>
              {item.subject !== null
                ? `${item.subject.structure_unit_type_name} - 
              ${item.subject.structure_unit_name}`
                : "(-)"}
            </strong>{" "}
            ?
          </Typography>
        </Box>

        <DialogContentText id="alert-dialog-description">
          <DialogActions
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 35,
              marginBottom: 10,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              className={classes.button2}
              onClick={() => setOpenDialogRemoveUnit(false)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              disabled={
                context.detailFolder.id !== item.object_id ? true : false
              }
              onClick={handleRemoveUnit}
            >
              {loading ? <CircularProgress color="#fff" size={15} /> : "Remove"}
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogRemoveUnit;
