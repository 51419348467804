import React, { useCallback, useState } from "react";
import {
	Box,
	DialogActions,
	Grid,
	IconButton,
	InputAdornment,
	InputBase,
	TextField,
} from "@material-ui/core";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import { Autocomplete } from "@material-ui/lab";
import { useDebouncedCallback } from "use-debounce/lib";
import PictREFRESH from "../../../../../assets/images/Group_2725.png";

function TableFilterUserUnregistered({
	classes,
	masterCategory,
	dataUnregisteredUser,
	setDataUnregisteredUser,
	refreshListUnregisteredUser
}) {
    const [locale, setLocale] = useState("id");

	const [handleSearch] = useDebouncedCallback(
		useCallback((value) => {
			handleRefreshToFirstPage()
		}, []),
		1000,

		{ maxWait: 5000 }
	);

	const handleRefreshToFirstPage = () => {
        refreshListUnregisteredUser(null, 0, 0, null)
	}

    return (
		<Box
		  display="flex"
		  justifyContent="space-between"
		  alignItems="center"
		>
			<Box display="flex" alignItems="center">
				<div className={classes.search}>
					<InputBase
                        startAdornment={
                            <SearchIcon
                            style={{ color: "#C4C4C4", fontSize: 18, marginLeft: 12 }}
                            />
                        }
                        endAdornment={
                            <IconButton
                            size="small"
                            onClick={() => {
                                let dataListTemporary = dataUnregisteredUser;
                                dataListTemporary.options.filter.search = "";
                                setDataUnregisteredUser({...dataListTemporary});
                                handleRefreshToFirstPage();
                            }}
                            >
                            <CancelIcon
                                style={{
                                color: "#C4C4C4",
                                fontSize: 18,
                                marginRight: 12,
                                }}
                            />
                            </IconButton>
                        }
                        onChange={(e) => {
                            let dataListTemporary = dataUnregisteredUser;
                            dataListTemporary.options.filter.search = e.target.value;
                            setDataUnregisteredUser({...dataListTemporary});
                            handleSearch(e.target.value)
                        }}
                        value={dataUnregisteredUser.options.filter.search}
                        placeholder="Telusuri nama user..."
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "Search" }}
					/>
				</div>
				<div className={classes.searchAutocomplete}>
					<Autocomplete
						options={masterCategory.masterFilterGenderCollections}
						getOptionLabel={(option) => {
							if (option && option.name) {
							return option.name;
							} else {
							return "";
							}
						}}
						className={classes.autocompleteTextField}
						value={dataUnregisteredUser.options.filter.sex_obj}
						onChange={(event, newValue) => {
							let dataTemporary = dataUnregisteredUser;
							dataTemporary.options.filter.sex_obj = newValue;
							dataTemporary.options.filter.sex_id = newValue ? newValue.id : "";
							setDataUnregisteredUser({...dataTemporary});
							refreshListUnregisteredUser(null, 0, 0, null)
						}}
						noOptionsText="Tidak ada pilihan"
						popupIcon={<ArrowDropDownIcon />}
						renderInput={(params) => (
						  <TextField
							style={{ fontSize: 13 }}
							{...params}
							fullWidth
							variant="outlined"
							size="small"
							placeholder="Telusuri Jenis Kelamin"
						  />
						)}
					/>
				</div>
				<div className={classes.search}>
					<InputBase
						style={{paddingTop: "0.75px", paddingBottom: "0.75px"}}
						type="number"
						placeholder="Telusuri Umur"
						endAdornment={
							<IconButton
							size="small"
							onClick={() => {
								let dataListTemporary = dataUnregisteredUser;
								dataListTemporary.options.filter.age = "";
								setDataUnregisteredUser({...dataListTemporary});
								handleRefreshToFirstPage();
							}}
							>
							<CancelIcon
								style={{
								color: "#C4C4C4",
								fontSize: 18,
								marginRight: 12,
								}}
							/>
							</IconButton>
						}
						onChange={(event, newValue) => {
							let dataTemp = dataUnregisteredUser;
							dataTemp.options.filter.age = event.target.value;
							setDataUnregisteredUser({...dataTemp});
							handleSearch(event.target.value)
						}}
						value={dataUnregisteredUser.options.filter.age}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						inputProps={{ "aria-label": "Search" }}
					/>
				</div>
				<Box mx={1} />
			</Box>

			<Box display="flex" alignItems="center">
				<IconButton style={{ padding: 4 }} 
                    onClick={() => {
                        refreshListUnregisteredUser(null, 0, 0, null);
                    }}
				>
					<img
					src={PictREFRESH}
					style={{ width: 17, height: 17 }}
					alt="."
					/>
				</IconButton>
			</Box>
		</Box>
    );
}

export default TableFilterUserUnregistered;
