import React from "react";
import { styles } from "../Style/StyleAttendanceHome";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Grid,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";
import AdminMenu from "./AdminMenu";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const HomeMenu = ({ data }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Grid item xs={6}>
        <AdminMenu />
        <div>
          <Typography variant="h6" style={{ color: "#262261" }}>
            <b> Menu </b>
          </Typography>
          <Paper style={{ padding: "10px 20px" }} variant="outlined">
            <Grid
              container
              spacing={1}
              style={{ justifyContent: "space-between" }}
            >
              {data.map((item, index) => (
                <Grid item xs={2} key={index} style={{
                  flexGrow: "0",
                  maxWidth: "20%",
                  flexBasis: "20%"
                }}>
                  <Paper
                    variant="outlined"
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    <IconButton
                      aria-label={item.name}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                      }}
                    >
                      {item.icon}
                    </IconButton>
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: 8,
                        fontSize: 14,
                        color: "#262261",
                      }}
                    >
                      {item.name}
                    </p>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </div>
      </Grid>
    </MuiThemeProvider>
  );
};

export default HomeMenu;
