import {
  TODOS_COLLECTION,
  DRAWER_OPEN,
  SET_TODO_DETAILS,
  SET_PAGINATE_TODO,
  SET_SEARCH,
  SET_SORT_NAME,
  SET_SORT_DATE,
  SET_CUSTOM_FILTER,
} from "../types";

export const initialTodos = {
  loadTodos: false,
  todoData: [],
  count: 0,
  drawer: false,
  todoDetails: null,
  pageLimit: 10,
  pageOffset: 0,
  search: "",
  sortName: false,
  sortDate: false,
  filterOwner: false,
  filterStatus: false,
  filterPriority: false,
  filterCompleted: true,
  ownerId: [],
  statusId: [],
  priorityId: [],
  completedId: "1",
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Table ~
export const todoReducer = (state, { type, payload }) => {
  switch (type) {
    case TODOS_COLLECTION:
      return {
        ...state,
        loadTodos: payload.loadTodos,
        todoData: [...payload.todoData],
        count: payload.count,
      };
    case DRAWER_OPEN:
      return {
        ...state,
        drawer: payload.drawer,
      };
    case SET_TODO_DETAILS:
      return {
        ...state,
        todoDetails: payload.todoDetails,
      };
    case SET_PAGINATE_TODO:
      return {
        ...state,
        pageLimit: payload.pageLimit,
        pageOffset: payload.pageOffset,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: payload.search,
      };
    case SET_SORT_NAME:
      return {
        ...state,
        sortName: payload.sortName,
      };
    case SET_SORT_DATE:
      return {
        ...state,
        sortDate: payload.sortDate,
      };
    case SET_CUSTOM_FILTER:
      return {
        ...state,
        filterOwner: payload.filterOwner,
        filterStatus: payload.filterStatus,
        filterPriority: payload.filterPriority,
        filterCompleted: payload.filterCompleted,
        ownerId: payload.ownerId,
        statusId: payload.statusId,
        priorityId: payload.priorityId,
        completedId: payload.completedId,
      };
    default:
      return state;
  }
};
