import React from "react";
import {
  Box,
  Button,
  Switch,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const SettingsGantt = ({
  classes,
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  const handleToggle = (e) => {
    onViewListChange(e.target.checked);
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Quarter of Day
      </Button>
      <Box mx={1} />

      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Half of Day
      </Button>
      <Box mx={1} />

      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={() => onViewModeChange(ViewMode.Day)}
      >
        Day
      </Button>
      <Box mx={1} />

      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        Week
      </Button>
      <Box mx={1} />

      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        Month
      </Button>
      <Box mx={1} />

      <FormControlLabel
        label={
          <Typography variant="caption" className={classes.title555}>
            {isChecked ? "Hide Task List" : "Show Task List"}
          </Typography>
        }
        control={<IOSSwitch checked={isChecked} onChange={handleToggle} />}
      />
    </Box>
  );
};

export default SettingsGantt;
