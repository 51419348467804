export const styles = (theme) => ({
  root: {
    // padding: theme.spacing(5, 2),
    // marginTop: theme.spacing(4),
    // width: 575,
    borderRadius: 2,
    width: "100%",
  },
  button: {
    borderRadius: 5,
    color: "#fff",
    backgroundColor: "#d1354a",
    "&:hover": {
      backgroundColor: "#f28181",
    },
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },
  buttonModalDelete: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
    "&:hover": {
      color: "#f28181",
    },
  },
  buttonDeleteUser: {
    fontSize: 14,
    lineHeight: 3.06,
    letterSpacing: 1.01,
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#2194e1",
    "&:hover": {
      textDecoration: "underline",
      background: "none",
      color: "#7aaef1",
    },
  },
  buttonUpgrade: {
    borderRadius: 5,
    color: "#fff",
    backgroundColor: "#ffbb34",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1.5),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#e5a82e",
    },
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
    // margin: 12
  },
  iconPreviewGrid: {
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
  iconPreviewList: {
    marginTop: theme.spacing(3),
    width: 24,
    height: 24,
  },

  iconFilter: {
    marginLeft: theme.spacing(7),
    width: 20,
    height: 20,
    cursor: "pointer",
  },

  paper: {
    padding: "1px 2px",
    // padding: theme.spacing(1,2),
    display: "flex",
    alignItems: "center",
    width: 600,
    height: 52,
  },
  input: {
    //   marginLeft: 8,
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    //   margin: 4,
    margin: theme.spacing(2),
  },

  chipVerifified: {
    backgroundColor: "green",
  },
  title: {
    fontFamily: "Roboto",
  },
  imagePictDefault: {
    marginTop: theme.spacing(7),
    height: 272,
  },
  listItemText: {
    width: "100%",
    // marginRight: theme.spacing(8)
  },
  listRoot: {
    width: "100%",
  },
  imageAvatar: {
    width: 50,
    height: 40,
  },

  //* DIALOG MODAL LANJUT
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 7),
    // transition: theme.transitions.create('width'),
    // width: '100%',
    // [theme.breakpoints.up('md')]: {
    //     width: 200,
    // },
    color: "transparent",
    // color: '#cc0707'
  },
  /*
        ````````````````````````
        TOOLBAR PAGINATION TABLE

        ````````````````````````
    */
  toolbar: {
    backgroundColor: "transparent",
  },

  /*
        ````````````````````
        HANDLE TAB USER & USER INVITED

        ````````````````````
    */

  outlinedTabActive: {
    borderBottom: `3px solid #d64253`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: "1px 2px",
    borderRadius: 3,
  },

  outlinedTabNotActive: {
    borderBottom: `3px solid transparent`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: "1px 2px",
    borderRadius: 3,
  },
  warningPasswordBg: {
    backgroundColor: "rgba(255, 187, 52, 0.2)",
    borderRadius: 5,
    // opacity: 0.2,
    width: 635,
  },
  warningPasswordTxt: {
    color: "#e5a82e",
    fontFamily: "Roboto",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    paddingLeft: 15,
    fontSize: 14,
  },
});
