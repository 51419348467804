import React, { useContext, useEffect, useState } from "react";
import { styles } from "../Style/StyleMyAttendance";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Room } from "@material-ui/icons";
import PinInput from "react-pin-input";

import ContextMyAttendance from "../Context/ContextMyAttendance";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const PinDialog = ({
  handleClose,
  open,
  verification,
  pinValue,
  setPinValue,
  loader,
}) => {
  const classes = useStyles();
  const context = useContext(ContextMyAttendance);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {"Masukkan PIN Anda"}
        </DialogTitle>
        <DialogContent>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{ borderRadius: "10px", border: "2px solid grey" }}
            onComplete={(value, index) => {
              setPinValue(value);
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setPinValue(false);
              }
            }}
            type="numeric"
          />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            className={classes.fixedBottomButton}
            style={{
              backgroundColor: pinValue === false ? "#808080" : "#262261",
              color: "#ffffff",
            }}
            onClick={() => pinValue !== false && verification()}
            disabled={loader || pinValue === false}
          >
            <b>Input</b>
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default PinDialog;
