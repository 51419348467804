import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import queryString from "query-string";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
import ListItemGroup from "./phase/ListItemGroup";
import ContextProject from "../../context/ContextProject";
import { FILTER_TASK, SET_GROUP_BY } from "../../context/types";

const DialogTaskGroup = ({ classes, openTaskGroup, setOpenTaskGroup }) => {
  const project_id = window.location.pathname.split("/")[2];
  const { projectTDispatch, projectTState, setTriggerKanban } =
    useContext(ContextProject);

  const [title, setTitle] = useState("");

  const [listGroup, setListGroup] = useState([]);
  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [triggerGroup, setTriggerGroup] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    // // console.log("parsed", parsed);

    if (parsed.group === "group" && selected.length > 0) {
      handleGroupByTaskGroup();
    }
  }, [selected]);

  useEffect(() => {
    // if (openTaskGroup) {
    setLoading(true);

    axiosConfig
      .get(`${URL_API}/project/${project_id}/task-group`)
      .then((res) => {
        const result = res.data.data;

        const taskGroupIds = result.length > 0 ? result.map((el) => el.id) : [];

        // console.log("RES grp", res);
        if (res.status === 200) {
          setListGroup(result);
          setSelected(taskGroupIds);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
    // }
  }, [openTaskGroup, triggerGroup]);

  const handleAddGroup = () => {
    setLoading(true);

    const data = {
      name: title,
      todos: [],
    };

    axiosConfig
      .post(`${URL_API}/project/${project_id}/task-group`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerGroup(Math.floor(Math.random() * 100));
          setTitle("");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddGroup();
    }
  };

  const handleCloseGroup = () => {
    setOpenTaskGroup(false);
    setTitle("");
  };

  const handleGroupByTaskGroup = () => {
    const taskGorups =
      selected.length > 0
        ? selected
            .map((el, i) => {
              return `options[filter][fields][task_group_id][${i}]=${el}`;
            })
            .join("&")
        : null;

    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: projectTState.periods,
        filterAssignee: projectTState.filterAssignee,
        filterAchievement: projectTState.filterAchievement,
        filterStatus: projectTState.filterStatus,
        filterPriority: projectTState.filterPriority,
        filterComplete: projectTState.filterComplete,
        filterGroup: projectTState.filterGroup,
        filterByGroup: projectTState.filterByGroup,
        filterPhase: taskGorups,
      },
    });

    projectTDispatch({
      type: SET_GROUP_BY,
      payload: {
        groupBy: "task_group",
      },
    });

    setTriggerKanban(Math.floor(Math.random() * 100));
    setOpenTaskGroup(false);
  };

  return (
    <Dialog
      open={openTaskGroup}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" className={classes.title333}>
          <b>Task Group</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          {listGroup.length > 0 &&
            listGroup.map((item, i) => (
              <ListItemGroup
                key={i}
                item={item}
                selected={selected}
                setSelected={setSelected}
                isSelected={isSelected}
                onTriggerGroup={setTriggerGroup}
                classes={classes}
                loading={loading}
                setLoading={setLoading}
              />
            ))}
        </List>

        <Box mt={2} />
        {openAdd && (
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Add a title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={() => setOpenAdd(false)}>
                  <CloseRoundedIcon style={{ color: "#888", fontSize: 18 }} />
                </IconButton>
              ),
            }}
          />
        )}
        {!openAdd && (
          <Button
            size="small"
            variant={loading ? "contained" : "outlined"}
            className={classes.button1}
            disabled={loading}
            // onClick={handleCustomPhase}
            onClick={() => setOpenAdd(true)}
          >
            Add
          </Button>
        )}
      </DialogContent>

      <DialogActions>
        <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={handleCloseGroup}
          >
            Close
          </Button>
          <Box mx={0.5} />
          <Button
            size="small"
            variant={selected.length === 0 ? "contained" : "outlined"}
            disabled={selected.length === 0}
            className={classes.button1}
            onClick={handleGroupByTaskGroup}
          >
            Group by Task Group
          </Button>
        </Box>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogTaskGroup;
