import React, { useContext, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useTable, useExpanded } from 'react-table';

import { styles, theme } from '../../style/styleTable';
import ContextGlobalReports from '../../context/ContextReports';
import Pagination from './Pagination';
import TotalRecords from './TotalRecords';
import { FormatDecimal } from '../../../../utilities/FormatDecimal';
import {
  truncateJob,
  truncateName,
  truncateTitle,
} from '../global/truncate';

import goal_icon from '../../../../assets/images/SVG/goal_table.svg';
import ma_icon from '../../../../assets/images/SVG/ma_table.svg';
import arrow_indent from '../../../../assets/images/SVG/arrow_return_right.svg';

import '../../style/table.css';
import moment from 'moment';
// import ImageSmallUser from "../../../../components/ImageSmallUser";

const useStyles = makeStyles(styles);

const OraCell = ({ value }) => {
  const content = value.content;
  const type = value.type;

  const txtVal =
    content <= 85
      ? 'not achieved'
      : content > 85 && content < 100
      ? 'warning'
      : 'achieved';
  const txtColor =
    content <= 85
      ? '#CF364A'
      : content > 85 && content < 100
      ? '#E8A700'
      : '#1E874D';
  const bgColor =
    content <= 85
      ? 'rgba(242, 129, 129, 0.3)'
      : content > 85 && content < 100
      ? 'rgba(232, 167, 0, 0.3)'
      : 'rgba(53, 185, 112, 0.3)';

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {content !== null && content !== 'N/A' && (
          <Box
            width={90}
            borderRadius={5}
            mr={1.5}
            p={0.5}
            style={{
              color: txtColor,
              backgroundColor: bgColor,
              fontSize: 13,
              textAlign: 'center',
            }}
          >
            <b>{txtVal}</b>
          </Box>
        )}
        <Typography
          variant="subtitle2"
          style={{
            margin: '5px 0',
            fontWeight: 'normal',
            color: '#333',
          }}
        >
          {content === null
            ? 'N/A'
            : content === 'N/A'
            ? 'N/A'
            : `${FormatDecimal(content)}%`}
        </Typography>
      </div>
    </ThemeProvider>
  );
};

const PercentCell = ({ value }) => {
  const content = value.content;
  const type = value.type;

  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="subtitle2"
        style={{
          margin: '5px 0',
          fontWeight: 'normal',
          color: '#333',
        }}
      >
        {content === null
          ? 'N/A'
          : content === 'N/A'
          ? 'N/A'
          : `${FormatDecimal(content)}%`}
      </Typography>
    </ThemeProvider>
  );
};

const PercentDiffCell = ({ value }) => {
  const content = value.content;
  const type = value.type;

  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="subtitle2"
        style={{
          margin: '5px 0',
          color: Number(content) <= 0 ? '#CF364A' : '#27ae60',
          fontWeight: 'normal',
        }}
      >
        {content === null
          ? 'N/A'
          : content === 'N/A'
          ? 'N/A'
          : `${FormatDecimal(content)}%`}
      </Typography>
    </ThemeProvider>
  );
};

const TitleCell = ({ value }) => {
  const classes = useStyles();
  const { tableState } = useContext(ContextGlobalReports);
  const detail =
    value.type === 'goal' ? 'goal-detail' : 'allma-detail';
  // const compareField = true;
  const compareField =
    tableState.selectedOrder !== undefined &&
    tableState.selectedOrder.length > 0 &&
    tableState.selectedOrder.some(
      (el) => el.field === 'node_path_named'
    );

  const handleToDetail = () => {
    const url = `/${detail}/${value.id}`;
    window.open(url, '_blank');
  };

  let INTERVAL_COLOR;
  let INTERVAL_BG;

  if (value?.interval === 'hourly') {
    INTERVAL_BG = '#D7E4F7';
    INTERVAL_COLOR = '#576FC2';
  } else if (value?.interval === 'daily') {
    INTERVAL_BG = '#D7F3F4';
    INTERVAL_COLOR = '#009345';
  } else if (value?.interval === 'weekly') {
    INTERVAL_BG = '#FBE0B3';
    INTERVAL_COLOR = '#FAA922';
  } else if (value?.interval === 'monthly') {
    INTERVAL_BG = '#FDDBD8';
    INTERVAL_COLOR = '#C8203C';
  }

  // // console.log("VALUE TITLE", value);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        alignItems="center"
        ml={value.nested > 2 ? value.nested : 0}
      >
        {compareField && value.nested > 1 ? (
          <img
            width="15"
            src={value.nested > 1 && arrow_indent}
            style={{ marginRight: 10 }}
          />
        ) : null}
        <img
          width="20"
          src={value.type === 'goal' ? goal_icon : ma_icon}
          style={{ marginRight: 15 }}
        />
        <Tooltip
          TransitionComponent={Zoom}
          title={`Update terakhir : ${moment(value.updated_at).format(
            'DD MMM YYYY hh:mm'
          )}`}
        >
          <Typography
            variant="subtitle2"
            onClick={handleToDetail}
            className={classes.txtTitleGoal}
            style={{
              // width: 285,
              margin: '5px 0',
              fontWeight: 'normal',
              color: '#333',
            }}
          >
            {truncateTitle(value.content)} -{' '}
            <span
              style={{
                color: INTERVAL_COLOR,
                backgroundColor: INTERVAL_BG,
                borderRadius: 5,
                padding: '1px 5px',
                fontSize: 11,
              }}
            >
              {value?.interval}
            </span>
          </Typography>
        </Tooltip>
        {/* <span
          style={{
            color: INTERVAL_COLOR,
            backgroundColor: INTERVAL_BG,
            borderRadius: 5,
            padding: "2px 10px",
            marginLeft: 10,
            fontSize: 10,
          }}
        >
          {value?.interval}
        </span> */}
      </Box>
    </ThemeProvider>
  );
};
const FullNameCell = ({ value }) => {
  // // console.log("Trace Image", value.photo_url);

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" alignItems="center">
        {/* <ImageSmallUser item={value.photo_url} index={1} /> */}
        <AccountCircleIcon
          fontSize="large"
          style={{ color: '#888' }}
        />
        <Tooltip TransitionComponent={Zoom} title={value.content}>
          <Typography
            variant="subtitle2"
            style={{
              // width: 195,
              margin: '5px 0 5px 7px',
              fontWeight: 'normal',
              color: '#333',
            }}
          >
            {truncateName(value.content)}
          </Typography>
        </Tooltip>
      </Box>
    </ThemeProvider>
  );
};
const JobTitleCell = ({ value }) => (
  <ThemeProvider theme={theme}>
    <Tooltip TransitionComponent={Zoom} title={value.content}>
      <Typography
        variant="subtitle2"
        style={{
          // width: 185,
          margin: '5px 0',
          fontWeight: 'normal',
          color: '#333',
        }}
      >
        {truncateJob(value.content)}
      </Typography>
    </Tooltip>
  </ThemeProvider>
);
const DateCell = ({ value }) => (
  <ThemeProvider theme={theme}>
    <Typography
      variant="subtitle2"
      style={{
        width: 100,
        margin: '5px 0',
        fontWeight: 'normal',
        color: '#333',
      }}
    >
      {moment(value.content).format('DD MMM YYYY')}
    </Typography>
  </ThemeProvider>
);
const NotOraCell = ({ value }) => (
  <ThemeProvider theme={theme}>
    <Typography
      variant="subtitle2"
      style={{
        fontWeight: 'normal',
        color: '#333',
      }}
    >
      {value.content}
    </Typography>
  </ThemeProvider>
);
const NumCell = ({ value }) => (
  <ThemeProvider theme={theme}>
    <Typography
      variant="subtitle2"
      style={{
        fontWeight: 'normal',
        color: '#333',
      }}
    >
      {value.content === 'no data'
        ? value.content
        : value.content === null
        ? 'N/A'
        : value.content === 'N/A'
        ? 'N/A'
        : FormatDecimal(value.content)}
    </Typography>
  </ThemeProvider>
);

const NumDiffCell = ({ value }) => (
  <ThemeProvider theme={theme}>
    <Typography
      variant="subtitle2"
      style={{
        fontWeight: 'normal',
        color:
          value.content === 'no data'
            ? '#CF364A'
            : value.content === 'N/A'
            ? '#CF364A'
            : Number(value.content) <= 0
            ? '#CF364A'
            : '#27ae60',
      }}
    >
      {value.content === 'no data'
        ? value.content
        : value.content === null
        ? 'N/A'
        : value.content === 'N/A'
        ? 'N/A'
        : FormatDecimal(value.content)}
    </Typography>
  </ThemeProvider>
);

const CalMethodCell = ({ value }) => (
  <ThemeProvider theme={theme}>
    <Typography
      variant="subtitle2"
      style={{
        width: 80,
        fontWeight: 'normal',
        color: '#333',
      }}
    >
      {value.content}
    </Typography>
  </ThemeProvider>
);

// =========================================================
// ~ Table Main Component ~
// =========================================================

const TableMain = () => {
  const { tableState, filterState } = useContext(
    ContextGlobalReports
  );

  // ====================== | Map Table Header, and add some prop | ======================
  // const PRESETS = [];
  const PRESETS =
    tableState.fieldPresets.selected_fields !== undefined &&
    tableState.fieldPresets.selected_fields.length > 0 &&
    tableState.fieldPresets.selected_fields.map((item) => {
      const lastItem =
        tableState.fieldPresets.selected_fields[
          tableState.fieldPresets.selected_fields.length - 1
        ];

      let color = '';
      const filedName = item.data_field_name;

      const getOra =
        tableState.fieldCompare.length > 0 &&
        tableState.fieldCompare.some(
          (el) =>
            el.data_field_name ===
            'overall_result_achievement_compare'
        );
      const getRes =
        tableState.fieldCompare.length > 0 &&
        tableState.fieldCompare.some(
          (el) => el.data_field_name === 'result_value_compare'
        );
      const getAct =
        tableState.fieldCompare.length > 0 &&
        tableState.fieldCompare.some(
          (el) =>
            el.data_field_name === 'expected_result_value_compare'
        );

      if (getOra && filedName === 'overall_result_achievement') {
        color = '#1976D2';
      } else if (
        getOra &&
        filedName === 'overall_result_achievement_compare'
      ) {
        color = '#1976D2';
      } else if (getRes && filedName === 'result_value') {
        color = '#6A5E9A';
      } else if (getRes && filedName === 'result_value_compare') {
        color = '#6A5E9A';
      } else if (getAct && filedName === 'expected_result_value') {
        color = '#6ab04c';
      } else if (
        getAct &&
        filedName === 'expected_result_value_compare'
      ) {
        color = '#6ab04c';
      } else {
        color = '#d1354a';
      }

      // // console.log("Dat Fild", item.data_field_name);
      // // console.log("item tabel", item);

      return {
        Header: item.caption,
        accessor: filedName,
        Cell:
          filedName === 'overall_result_achievement'
            ? OraCell
            : filedName === 'title'
            ? TitleCell
            : filedName === 'full_name'
            ? FullNameCell
            : filedName === 'position_title'
            ? JobTitleCell
            : filedName === 'calculation_method_id'
            ? CalMethodCell
            : filedName === 'start_date' || filedName === 'end_date'
            ? DateCell
            : filedName === 'expected_result_value' ||
              filedName === 'overall_actual_achievement' ||
              filedName === 'start_value' ||
              filedName === 'current_period_target_value' ||
              filedName === 'result_value' ||
              filedName === 'gap_result_value' ||
              filedName === 'actual_value' ||
              filedName === 'expected_actual_value' ||
              filedName === 'gap_actual_value'
            ? NumCell
            : NotOraCell,
        style: {
          backgroundColor: color,
          borderTopRightRadius: lastItem.id === item.id ? 8.5 : 0,
        },
      };
    });

  // // console.log("Detailed PRESET", tableState.fieldPresets.selected_fields);

  const PRESET_COMPARE =
    tableState.fieldCompare !== undefined &&
    tableState.fieldCompare.length > 0 &&
    tableState.fieldCompare.map((item) => {
      const firstItem = tableState.fieldCompare[0];
      const lastItem = tableState.fieldCompare[1];
      const fieldName = item.data_field_name;

      return {
        Header: item.caption,
        accessor: fieldName,
        Cell:
          fieldName === 'overall_result_achievement_compare'
            ? PercentCell
            : NumCell,
        style: {
          backgroundColor:
            fieldName === 'overall_result_achievement_compare'
              ? '#1976D2'
              : fieldName === 'result_value_compare'
              ? '#6A5E9A'
              : fieldName === 'expected_result_value_compare'
              ? '#6ab04c'
              : '#d1354a',
          borderTopLeftRadius: firstItem.id === item.id ? 8.5 : 0,
          borderTopRightRadius:
            tableState.fieldCompare.length > 1 &&
            lastItem.id === item.id
              ? 8.5
              : tableState.fieldCompare.length === 1
              ? 8.5
              : 0,
        },
      };
    });

  const PRESET_DIFF =
    tableState.fieldDiff !== undefined &&
    tableState.fieldDiff.length > 0 &&
    tableState.fieldDiff.map((item) => {
      const firstItem = tableState.fieldDiff[0];
      const lastItem = tableState.fieldDiff[1];

      return {
        Header: item.caption,
        accessor: item.data_field_name,
        Cell:
          item.data_field_name ===
          'overall_result_achievement_difference'
            ? PercentDiffCell
            : NumDiffCell,
        style: {
          backgroundColor: '#d1354a',
          borderTopLeftRadius: firstItem.id === item.id ? 8.5 : 0,
          borderTopRightRadius:
            tableState.fieldDiff.length > 1 && lastItem.id === item.id
              ? 8.5
              : tableState.fieldDiff.length === 1
              ? 8.5
              : 0,
        },
      };
    });

  // ====================== | COLUMN for Table header | ======================
  const COLUMN = [
    {
      Header:
        filterState.dateRange.code === 'all_time'
          ? '~'
          : filterState.dateRange.code === 'all_time_seven_days_ago'
          ? `Created date - ${filterState.dateRange.endDate}`
          : `${filterState.dateRange.startDate} - ${filterState.dateRange.endDate}`,
      className: 'header',
      style: {
        backgroundColor: '#F9F9F9',
        color: '#333',
        fontWeight: 'bold',
        borderBottom: 'none',
      },
      columns: [
        {
          id: 'expander',
          Header: ({
            getToggleAllRowsExpandedProps,
            isAllRowsExpanded,
          }) => (
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? (
                <IconButton size="small">
                  <KeyboardArrowDownIcon style={{ color: '#fff' }} />
                </IconButton>
              ) : (
                <IconButton size="small">
                  <KeyboardArrowRightIcon style={{ color: '#fff' }} />
                </IconButton>
              )}
            </span>
          ),
          Cell: ({ row }) =>
            row.canExpand ? (
              <span
                style={{ width: 50 }}
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 0.5}rem`,
                  },
                })}
              >
                {row.isExpanded ? (
                  <IconButton size="small">
                    <KeyboardArrowDownIcon />
                  </IconButton>
                ) : (
                  <IconButton size="small">
                    <KeyboardArrowRightIcon />
                  </IconButton>
                )}
              </span>
            ) : null,
          style: {
            backgroundColor: '#d1354a',
            borderTopLeftRadius: 8.5,
          },
        },
      ].concat(PRESETS.length > 0 ? PRESETS : []),
    },
  ];

  // ====================== | COMPARISON Table Header | ======================
  const dateOpt = tableState.comparison.date_option;
  const COMPARISON = [
    {
      Header: `${moment(
        dateOpt !== undefined && dateOpt.start_date
      ).format('DD MMM')} - ${moment(
        dateOpt !== undefined && dateOpt.finish_date
      ).format('DD MMM')}`,
      style: {
        backgroundColor: '#F9F9F9',
        color: '#333',
        fontWeight: 'bold',
        borderBottom: 'none',
      },
      columns: PRESET_COMPARE.length > 0 ? PRESET_COMPARE : [],
    },
    {
      Header: 'Difference',
      style: {
        backgroundColor: '#F9F9F9',
        color: '#333',
        fontWeight: 'bold',
        borderBottom: 'none',
      },
      columns: PRESET_DIFF.length > 0 ? PRESET_DIFF : [],
    },
  ];

  const classes = useStyles();
  // .concat(tableState.fieldCompare.length > 0 ? COMPARISON : [])
  const columns = useMemo(
    () =>
      COLUMN.concat(
        tableState.fieldCompare.length > 0 ? COMPARISON : []
      ),
    [tableState, filterState.dateRange]
  );
  const data = useMemo(
    () => tableState.dataPresets,
    [tableState.dataPresets]
  );

  // // console.log("DATAS", tableState.dataPresets);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable({ columns, data }, useExpanded);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer className={classes.shadowTable}>
        <Table
          size="small"
          aria-label="a dense table"
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      className={classes.titleHead}
                      {...column.getHeaderProps({
                        style: column.style,
                      })}
                    >
                      <b>{column.render('Header')}</b>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            {...getTableBodyProps()}
            style={{ backgroundColor: '#fff' }}
          >
            {rows.map((row, i) => {
              prepareRow(row);

              return (
                <TableRow {...row.getRowProps()} {...row.subRows}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={classes.title}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        {tableState.loadTable && (
          <>
            <CircularProgress
              size={16}
              style={{ color: '#d64253', marginRight: 5 }}
            />
            <Typography
              variant="subtitle2"
              className={classes.title888}
            >
              Loading...
            </Typography>
          </>
        )}

        {tableState.dataPresets.length === 0 &&
          !tableState.loadTable && (
            <Typography
              variant="subtitle2"
              className={classes.title888}
            >
              Data not found
            </Typography>
          )}
      </Box>

      {/* PAGINATION */}
      <Box display="flex" justifyContent="space-between" mt={1}>
        <TotalRecords classes={classes} />
        <Pagination classes={classes} />
      </Box>
    </ThemeProvider>
  );
};

export default TableMain;
