import React, { useState, useEffect } from "react";

import { Dialog, DialogTitle, DialogContent, Grid } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

// import { Picture } from 'react-responsive-picture';

import { URL_API } from "../../../../constants/config-api";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    fontFamily: "Roboto",
  },
  img: {
    height: "auto",
    display: "block",
    overflow: "hidden",
    width: "100%",
  },
  title: {
    fontFamily: "Roboto",
  },
}));

const DialogBiggerPictInAllAndYou = (props) => {
  const { isOpenDialog, setOpenDialog, listPictState, userToken } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  /*
        ``````````````````` ```````
        COMPONENT DID MOUNT IF TRUE

        ``````````````````` ```````
    */
  const [listPictBigger, setListPictBigger] = useState([]);

  useEffect(() => {
    if (isOpenDialog == true) {
      setListPictBigger(listPictState);
    }
  }, [isOpenDialog]);

  /*
        `````````````````
        HANDLE MAX DIALOG

        `````````````````
    */
  const [maxWidth, setMaxWidth] = useState("sm");

  return (
    <Dialog
      open={isOpenDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={maxWidth}
      // fullScreen
      style={{ height: "auto", width: "100%" }}>
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Grid
          container
          spacing={24}
          alignItems="center"
          justify="center"></Grid>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <Grid container spacing={24} alignItems="center" justify="center">
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            autoplay={false}
            auto>
            {listPictBigger.length > 0 &&
              listPictBigger.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img
                      className={classes.img}
                      src={URL_API + "/" + step + "?token=" + userToken}
                      alt="Hai"
                    />
                  ) : null}
                </div>
              ))}
          </AutoPlaySwipeableViews>
        </Grid>
      </DialogContent>
      {/* <DialogActions style={{alignItems: "center", justifyContent:'center'}}>
                <Typography 
                    variant='subtitle1' 
                    className={classes.title}
                >

                </Typography>
                    <MobileStepper
                    steps={listPictBigger.length}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={

                        <Button size="small" onClick={handleNext} disabled={activeStep === listPictBigger.length - 1}>
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton = {

                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        </Button>
                    }
                />
            </DialogActions> */}
      <br />
    </Dialog>
  );
};

export default DialogBiggerPictInAllAndYou;
