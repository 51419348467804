import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  DialogTitle,
} from "@material-ui/core";

import moment from "moment";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";

import DialogErrorImport from "../../../../components/DialogErrorImport";

import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    // width: "100%",
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    minWidth: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

const DialogImportProject = ({
  classes,
  project,
  openImportProject,
  setOpenImportProject,
}) => {
  const { setTriggerTask, setOpenSuccessImportTasks } =
    useContext(ContextProject);

  const { CSVReader } = useCSVReader();

  const [csvs, setCsvs] = useState([]);
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState("");

  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const handleDownloadSample = () => {
    window
      .open(
        "https://api-test.performate.id/template/CSV_Projects_Taks_Import.csv",
        "_blank"
      )
      .focus();
  };

  const handleImportProject = () => {
    const csvList = csvs.map((c) => {
      const due = c?.due_date !== "" && c?.due_date.split(" ");
      const start = c?.start_date !== "" && c?.start_date.split(" ");
      const end = c?.end_date !== "" && c?.end_date.split(" ");
      const completion =
        c?.completion_date !== "" && c?.completion_date.split(" ");

      const newdue = due
        ? due.length > 1
          ? due[0].split("/").reverse().join("-").concat(` ${due[1]}`)
          : due[0].split("/").reverse().join("-")
        : "";
      const newstart = start
        ? start.length > 1
          ? start[0].split("/").reverse().join("-").concat(` ${start[1]}`)
          : start[0].split("/").reverse().join("-")
        : "";
      const newend = end
        ? end.length > 1
          ? end[0].split("/").reverse().join("-").concat(` ${end[1]}`)
          : end[0].split("/").reverse().join("-")
        : "";
      const newCompletion = completion
        ? completion.length > 1
          ? completion[0]
              .split("/")
              .reverse()
              .join("-")
              .concat(` ${completion[1]}`)
          : completion[0].split("/").reverse().join("-")
        : "";

      return {
        title: c?.title,
        owner_email: c?.owner_email,
        is_complete: c?.is_complete,
        priority: c?.priority,
        task_group_name: c?.task_group,
        start_date:
          newstart === "" ? "" : moment(newstart).format("YYYY-MM-DD"),
        due_date:
          newdue === "" ? "" : moment(newdue).format("YYYY-MM-DD HH:mm:ss"),
        end_date: newend === "" ? "" : moment(newend).format("YYYY-MM-DD"),
        completion_date:
          newCompletion === ""
            ? ""
            : moment(newCompletion).format("YYYY-MM-DD HH:mm:ss"),
        status: c?.status,
        tags: c?.tags,
        description: c?.description,
        target_result: c?.target_result !== "" ? Number(c?.target_result) : "",
        actual_result: c?.actual_result !== "" ? Number(c?.actual_result) : "",
        remarks: c?.notes,
        entity_type: c.entity_type,
        parent_id: c.parent_id,
        local_id: c.local_id,
      };
    });

    for (let i = 0; i < csvList.length; i++) {
      if (
        csvList[i].actual_result === "" &&
        (csvList[i].status !== "DONE" || csvList[i].status !== "DOING")
      ) {
        delete csvList[i].actual_result;
      }
      if (csvList[i].end_date === "" && csvList[i].status !== "DONE") {
        delete csvList[i].end_date;
      }
      if (csvList[i].completion_date === "" && csvList[i].status !== "DONE") {
        delete csvList[i].completion_date;
      }
      if (csvList[i].target_result === "") {
        delete csvList[i].target_result;
      }
      if (csvList[i].task_group_name === "") {
        delete csvList[i].task_group_name;
      }
      if (csvList[i].tags === "") {
        delete csvList[i].tags;
      }
      if (csvList[i].remarks === "") {
        delete csvList[i].remarks;
      }
      if (csvList[i].tags === "") {
        delete csvList[i].tags;
      }
      if (csvList[i].description === "") {
        delete csvList[i].description;
      }
      if (csvList[i].entity_type === "project_task") {
        delete csvList[i].parent_id;
      }
    }

    const data = {
      ProjectGoals: {
        id: project?.id,
        data: Object.assign({}, csvList),
      },
    };

    // // console.log("DATA", data);
    // // console.log("csvList", csvList);
    // // console.log("scvs", csvs);

    setLoading(true);

    axiosConfig
      .post(`${URL_API}/project/import`, data)
      .then((res) => {
        // console.log("Res CSV : ", res);

        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setOpenImportProject(false);
          setLoading(false);
          setOpenSuccessImportTasks(true);
        }
      })
      .catch((error) => {
        // console.log("ERROR : ", error);
        setLoading(false);

        if (error.response !== undefined) {
          let resStatus = error.response.status;

          setErrorStatus(resStatus);

          if ([422, 400].includes(resStatus)) {
            if (
              error?.response?.data?.info?.errors !== null &&
              error?.response?.data?.info?.errors !== undefined
            ) {
              if (error?.response?.data?.info?.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error?.response?.data?.info?.errors);
              }
            }
          }

          if ([502, 503, 500, 403, 429, 405, 404, 401].includes(resStatus)) {
            setOpenDialogError(true);

            if (error.response?.data?.hasOwnProperty("message")) {
              setTextErrorMessage(error.response.data.message);
            }
            if (error.response?.data?.info?.hasOwnProperty("message")) {
              setTextErrorMessage(error.response.data.info.message);
            }
          }
        }
      });
  };

  return (
    <Dialog
      open={openImportProject}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box p={1}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>Import to Project</b>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 500 }}>
        <CSVReader
          onUploadAccepted={(results) => {
            const result = results.data;
            const keys = result[0];
            const values = result.slice(1);

            const maped = values
              .map((item) => {
                if (keys.length === item.length) {
                  const arr = item.map((val, i) => {
                    return {
                      [keys[i]]: val,
                    };
                  });

                  console.log("arr", arr);

                  return Object.assign({}, ...arr);
                }
              })
              .filter((el) => el !== undefined);

            // console.log("---------------------------");
            // console.log(result);
            // console.log("maped", maped);
            // console.log("values", values);
            // console.log("---------------------------");
            setCsvs(maped);
            setZoneHover(false);
          }}
          onDragOver={(event) => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={(event) => {
            event.preventDefault();
            setZoneHover(false);
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
            Remove,
          }) => (
            <>
              <div
                {...getRootProps()}
                style={Object.assign(
                  {},
                  styles.zone,
                  zoneHover && styles.zoneHover
                )}
              >
                {acceptedFile ? (
                  <>
                    <div style={styles.file}>
                      <div style={styles.info}>
                        <span style={styles.size}>
                          {formatFileSize(acceptedFile.size)}
                        </span>
                        <span style={styles.name}>{acceptedFile.name}</span>
                      </div>
                      <div style={styles.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        {...getRemoveFileProps()}
                        style={styles.remove}
                        onMouseOver={(event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                        onMouseOut={(event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  </>
                ) : (
                  "Drop CSV file here or click to upload"
                )}
              </div>
            </>
          )}
        </CSVReader>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            size="small"
            variant="outlined"
            className={classes.btnSmallTrans}
            style={{ fontSize: 13, fontWeight: "normal" }}
            onClick={handleDownloadSample}
          >
            Download Sample Import Project
          </Button>
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            size="medium"
            className={classes.button}
            onClick={loading ? null : () => setOpenImportProject(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            variant={loading || csvs.length === 0 ? "contained" : "outlined"}
            size="medium"
            disabled={loading || csvs.length === 0}
            className={classes.button1}
            onClick={handleImportProject}
          >
            {loading ? "Importing..." : "Import"}
          </Button>
        </Box>
      </DialogActions>

      <DialogErrorImport
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogImportProject;
