import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

import axios from "axios";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import DialogError from "../../../../components/DialogError";
import DialogLanjutInviteAnggota from "./DialogLanjutInviteAnggota";

// import GearPicture from "../../../../assets/images/Group_1214.png";
// import PictInfo from "../../../../assets/images/icon-info-24px.svg";
// import AvatarDummy from "../../../../assets/images/Avatar_dummy.png";
import { URL_API } from "../../../../constants/config-api";
import { useGetHttp } from "../../../../utilities-hook/useGetHttp";

import { fade } from "@material-ui/core/styles";

const DialogModalInviteAnggota = (props) => {
  const { classes, isModalInvite, setModalInvite } = props;

  const [userTokenState, setUserTokenState] = useState("");
  const [loader, setLoader] = useState(false);

  /*
        ``````````````````
        GET LIST ROLE NOW

        ``````````````````
    */
  // const [ listRoleNow, setListRoleNow ] = useState([]);

  const [loading, fetchedData, setFetchedData] = useGetHttp(
    URL_API + `/account-management/user/batch/create`,
    [isModalInvite]
  );

  let listAnggota = [];

  if (fetchedData !== null && fetchedData !== undefined) {
    if (fetchedData.memberCollections !== undefined) {
      listAnggota = [...fetchedData.memberCollections];
    }
  }

  /*
        ```````````````````````````````
        HANDLE CHOOSE USER IN CHECKBOX

        ```````````````````````````````
    */

  const [selected, setSelected] = useState([]);

  // const handleClickCheckbox = (e, id) => {

  const handleClickCheckbox = (e, item) => {
    // console.log("Item checkbox - single choose: ", item);

    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // console.log("Item checkbox - munltiple choose:: : ", newSelected);

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    if (isModalInvite == true) {
      const userToken = localStorage.getItem("userToken");

      if (userToken !== null) {
        setUserTokenState(userToken);

        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          crossorigin: true,
          crossDomain: true,
          Authorization: "bearer " + userToken,
        };

        axios.defaults.headers.common = headers;
        axios
          .get(URL_API + `/account-management/user/batch/create`)
          .then((response) => {
            // console.log("Original response : ", response);

            if (response.status == 200) {
              if (response.data.data !== null) {
                if (response.data.data.roleCollections !== undefined) {
                  if (response.data.data.roleCollections.length > 0) {
                    setRoleId(response.data.data.roleCollections[0].id);
                  }
                }
              }

              // const templateListRole = [];

              // if(response.data.data !== undefined) {

              //     Object.getOwnPropertyNames(response.data.data.roleOptions).forEach((val, idx, array) => {
              //             const data = {
              //                 key: val,
              //                 value: response.data.data.roleOptions[val]
              //             };
              //             templateListRole.push(data);
              //         }
              //     )
              // };

              // setListRoleNow(templateListRole)
            }
          })
          .catch((error) => {
            // console.log("Error response : ", error.response);
          });
      } else {
        // console.log("Ga dapet User Token !");
      }
    }
  }, [isModalInvite]);

  /*
        ``````````````````````````
        HANDLE SIMPAN DATA & MODAL LANJUT DIALOG

        ``````````````````````````
    */

  const [isModalLanjut, setModalLanjut] = useState(false);

  const handleSubmitInvite = () => {
    setLoader(true);
    // console.log("selected checbox oke", selected);

    // setModalLanjut(true);

    let list = [];

    if (selected.length > 0) {
      selected.map((item, i) => {
        let okeData = {
          member_id: item,
          role_id: [roleId],
        };

        list.push(okeData);
      });
    }

    // // console.log("list : ", list);

    let data = {
      User: list,
    };

    // console.log("Data : ", data);

    if (userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + `/account-management/user/batch`, data)
        .then(function (response) {
          setLoader(false);
          // console.log("Res Userz : ", response);

          setModalInvite(false);
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);
              setOpenDialogError(true);
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              setOpenDialogError(true);
            }
          } else {
            setOpenDialogError(true);
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*  
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isModalInvite}
        onClose={() => setModalInvite(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Grid container>
            <Grid item sm={8}>
              <Typography variant="h6" className={classes.title}>
                <b>
                  Pilih <i>User</i>
                </b>
              </Typography>
            </Grid>

            <Grid item sm={4}></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ textAlign: "left" }}>
          <Grid container>
            <Grid item sm={12}>
              <List className={classes.listRoot}>
                {listAnggota.length > 0 ? (
                  listAnggota.map((item, i) => {
                    const isItemSelected = isSelected(item.id);

                    return (
                      <ListItem
                        key={i}
                        style={{ paddingLeft: 0, marginRight: 24 }}
                        button
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        onClick={(e) => handleClickCheckbox(e, item)}
                      >
                        <Checkbox checked={isItemSelected} />
                        {/* <img src={AvatarDummy} alt="Gear Picture" className={classes.imageAvatar} /> */}
                        <AccountCircleIcon
                          fontSize="large"
                          style={{ color: "grey" }}
                        />
                        &nbsp;&nbsp;
                        <ListItemText
                          className={classes.listItemtext}
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                            >
                              <b>
                                {item.first_name} {item.last_name}
                              </b>
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                            >
                              {item.email}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      Silahkan anda menambahkan <i>User</i> !
                    </b>
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>

          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.titleModal}
            ></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center " }}
        >
          <Button
            onClick={handleSubmitInvite}
            variant="contained"
            className={classes.button}
            style={{ minWidth: 230 }}
            disabled={listAnggota.length === 0 ? true : false}
          >
            {loader !== true ? (
              "Invite"
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogLanjutInviteAnggota
        classes={classes}
        // listAnggota = { listAnggota }
        isModalLanjut={isModalLanjut}
        setModalLanjut={setModalLanjut}
        selected={selected}
        // listRoleNow = { listRoleNow }
      />

      <DialogError
        classes={classes}
        setOpenDialogError={setOpenDialogError}
        isOpenDialogError={isOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogModalInviteAnggota;
