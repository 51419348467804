import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import queryString from "query-string";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

import ListItemPhase from "./phase/ListItemPhase";
import ContextProject from "../../context/ContextProject";
import { FILTER_TASK, SET_GROUP_BY } from "../../context/types";

const DialogCustomPhase = ({
  classes,
  openCustomPhase,
  setOpenCustomPhase,
}) => {
  const project_id = window.location.pathname.split("/")[2];
  const { projectTDispatch, projectTState, setTriggerKanban, setTriggerTask } =
    useContext(ContextProject);

  const [title, setTitle] = useState("");
  const [phaseCollection, setPhaseCollection] = useState([]);
  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isSorting, setSorting] = useState(false);
  const [triggerPhase, setTriggerPhase] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    // // console.log("parsed", parsed);

    if (parsed.group === "phase" && selected.length > 0) {
      handleGroupByPhase();
    }
  }, [selected]);

  useEffect(() => {
    // if (openCustomPhase) {
    setLoading(true);

    axiosConfig
      .get(`${URL_API}/project/${project_id}/custom-phase`)
      .then((res) => {
        const result = res.data.data;
        // console.log("RES list phase", res);

        const phaseIds = result.length > 0 ? result.map((el) => el.id) : [];

        if (res.status === 200) {
          setPhaseCollection(result);
          setSelected(phaseIds);
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
    // }
  }, [triggerPhase]);

  // useEffect(() => {
  //   const phaseIds =
  //     phaseCollection.length > 0 ? phaseCollection.map((el) => el.id) : [];

  //   setSelected(phaseIds);
  // }, [phaseCollection]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(phaseCollection);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPhaseCollection(items);
    // // console.log("ITEMS phs", items);
    setSorting(true);

    const getPhaseIds = items.map((el) => el.id);

    const data = {
      custom_phases: getPhaseIds,
    };

    // console.log("data", data);

    axiosConfig
      .put(`${URL_API}/project/${project_id}/custom-phase/sort-order`, data)
      .then((res) => {
        // // console.log("RES phase sort", res);

        if (res.status === 200) {
          setTriggerPhase(Math.floor(Math.random() * 100));
          // setOpenCustomPhase(false);
          setSorting(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("ERROR", error);
        setSorting(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleCustomPhase = () => {
    setLoading(true);

    const data = {
      title,
    };

    axiosConfig
      .post(`${URL_API}/project/${project_id}/custom-phase`, data)
      .then((res) => {
        // // console.log("RES phase", res);

        if (res.status === 200) {
          setTriggerPhase(Math.floor(Math.random() * 100));
          // setOpenCustomPhase(false);
          setTitle("");
          setOpenAdd(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("ERROR", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleClosePhase = () => {
    setOpenCustomPhase(false);
    setTitle("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCustomPhase();
    }
  };

  const handleGroupByPhase = () => {
    const phases =
      selected.length > 0
        ? selected
            .map((el, i) => {
              return `options[filter][fields][custom_phase_id][${i}]=${el}`;
            })
            .join("&")
        : null;

    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: projectTState.periods,
        filterAssignee: projectTState.filterAssignee,
        filterAchievement: projectTState.filterAchievement,
        filterStatus: projectTState.filterStatus,
        filterPriority: projectTState.filterPriority,
        filterComplete: projectTState.filterComplete,
        filterGroup: projectTState.filterGroup,
        filterByGroup: projectTState.filterByGroup,
        filterPhase: phases,
      },
    });

    projectTDispatch({
      type: SET_GROUP_BY,
      payload: {
        groupBy: "custom_phase",
      },
    });

    setTriggerKanban(Math.floor(Math.random() * 100));
    setTriggerTask(Math.floor(Math.random() * 100));
    setOpenCustomPhase(false);
  };

  // ==================

  // ===================
  // // console.log("SELECTED", selected);

  return (
    <Dialog
      open={openCustomPhase}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" className={classes.title333}>
          <b>Create Custom Phase</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="phase">
            {(provided) => (
              <List
                dense
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ overflowX: "auto" }}
              >
                {phaseCollection.length > 0 &&
                  phaseCollection.map((item, i) => (
                    <ListItemPhase
                      key={i}
                      index={i}
                      item={item}
                      onTriggerPhase={setTriggerPhase}
                      classes={classes}
                      isSorting={isSorting}
                      selected={selected}
                      setSelected={setSelected}
                      isSelected={isSelected}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  ))}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        <Box mt={2} />
        {openAdd && (
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Add a title"
            value={title}
            disabled={loading}
            onChange={(e) => setTitle(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={() => setOpenAdd(false)}>
                  <CloseRoundedIcon style={{ color: "#888", fontSize: 18 }} />
                </IconButton>
              ),
            }}
          />
        )}
        {!openAdd && (
          <Button
            size="small"
            variant={loading ? "contained" : "outlined"}
            className={classes.button1}
            disabled={loading}
            // onClick={handleCustomPhase}
            onClick={() => setOpenAdd(true)}
          >
            Add
          </Button>
        )}
      </DialogContent>

      <DialogActions>
        <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={handleClosePhase}
          >
            Close
          </Button>
          <Box mx={0.5} />
          <Button
            size="small"
            variant={selected.length === 0 ? "contained" : "outlined"}
            disabled={selected.length === 0}
            className={classes.button1}
            onClick={handleGroupByPhase}
          >
            Group by Phase
          </Button>
        </Box>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogCustomPhase;
