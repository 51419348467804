import { Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import ContextAttendanceReportAdmin from "./Context/ContextAttendanceReportAdmin";
import ReportType from "./Components/ReportType/Index";

import { styles } from "./Style/StyleAttendanceReportAdmin";
import Grouping from "./Components/Grouping/Index";
import DetailedType from "./Components/ReportType/DetailedType/Index";
import SummaryType from "./Components/ReportType/SummaryType/Index";

import DialogErrorTM from "./Components/Global/DialogErrorTM";
import { URL_API } from "../../../constants/config-api";
import ChipFilterSummaryType from "./Components/Grouping/SummaryType/ChipFilter/Index";
import ChipFilterDetailType from "./Components/Grouping/DetailedType/ChipFilter/Index";

const useStyles = makeStyles(styles);

const ViewAttendanceReports = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceReportAdmin);
  
  useEffect(() => {
    context.getReportSummaryList();
  }, []);

  return (
    <Box className={classes.root} style={{minWidth: "1300px"}}>
      <Box marginBottom={2} className={classes.layoutMarginLeftAndTop}>
        <ReportType 
          classes={classes} 
        />
      </Box>
      <Divider orientation="horizontal" />
      <div
        style={{
          backgroundColor: "#fff",
          padding: `20px 0`,
        }}>
        <Box className={classes.layoutMarginLeft}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginRight={2}>
            <Grouping classes={classes} />
          </Box>
          {context.reportState.valueTab === 0 && 
            <Box
              display="flex"
              alignItems="center"
              marginTop={2}
              marginRight={2}>
              <ChipFilterSummaryType classes={classes} />
            </Box>
          }
          {context.reportState.valueTab === 1 && 
            <Box
              display="flex"
              alignItems="center"
              marginTop={2}
              marginRight={2}>
              <ChipFilterDetailType classes={classes} />
            </Box>
          }
        </Box>
      </div>

      <div className={classes.dataArea}>
        <Box className={classes.layoutMarginLeft}>
          <Box marginTop={1.5} marginRight={2}>
            {context.reportState.valueTab === 0 && <SummaryType classes={classes} />}
            {context.reportState.valueTab === 1 && <DetailedType classes={classes} />}
          </Box>
        </Box>
      </div>

      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorMessage}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
    </Box>
  );
};

export default ViewAttendanceReports;
