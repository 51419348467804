import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Container,
} from "@material-ui/core";

import styles from "./Style/landingStyle";

import HeaderNew from "./ComponentViewLandingPage/home/HeaderNew";
import NavRight from "./ComponentViewLandingPage/NaviRight";
import NavLeft from "./ComponentViewLandingPage/NavLeft";
import ListPricing from "./ComponentViewLandingPage/pricing/ListPricing";
import ListStartUp from "./ComponentViewLandingPage/pricing/ListStartUp";
import TouchUs from "./ComponentViewLandingPage/home/TouchUs";
import Footer from "./ComponentViewLandingPage/Footer";
import FloatingWhatsapp from "./ComponentViewLandingPage/components/FloatingWhatsapp";

import bgTouch from "../../assets/images/bgTouch.png";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const ViewPricing = () => {
  const classes = useStyles();
  const [group, setGroup] = useState(1);

  return (
    <div>
      <HeaderNew
        color="transparent"
        routes={dashboardRoutes}
        brand="Performate"
        rightLinks={<NavRight origin="2" />}
        leftLinks={<NavLeft origin="2" />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white",
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={20} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            className={classes.title}
            style={{ color: "#333" }}>
            <b>Tingkatkan Cara anda dalam monitoring kinerja tim sekarang</b>
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.heroDesc}
            style={{ color: "#333" }}>
            Pilih paket yang paling sesuai dengan kebutuhan Anda.
          </Typography>

          <Box mt={6}>
            <ButtonGroup size="large">
              <Button
                size="large"
                style={{ fontSize: 22, padding: "15px 30px" }}
                className={group === 0 ? classes.btnFree : classes.btnSales2}
                onClick={() => setGroup(0)}>
                Start Up
              </Button>
              <Button
                size="large"
                style={{ fontSize: 22, padding: "15px 30px" }}
                className={group === 1 ? classes.btnFree : classes.btnSales2}
                onClick={() => setGroup(1)}>
                Enterprise
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {group === 1 ? <ListPricing /> : <ListStartUp />}

      <Box
        style={{
          backgroundImage: "url(" + bgTouch + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}>
        <Container>
          <TouchUs />
        </Container>
      </Box>

      <FloatingWhatsapp />

      <Footer />
    </div>
  );
};

export default ViewPricing;
