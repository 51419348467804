import React, { useState } from "react";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import DialogEditPosition from "./DialogEditPosition";
import DialogDeletePosition from "./DialogDeletePosition";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    padding: "2px 15px",
  },
}))(MenuItem);

const ListItemPosition = ({ classes, item, onTriggerPosition }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [openEditPosition, setOpenEditPosition] = useState(false);
  const [openDeletePosition, setOpenDeletePosition] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenEditPosition(true);
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
    setOpenDeletePosition(true);
  };

  return (
    <>
      <ListItem>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction>
          <IconButton size="small" onClick={handleClick}>
            <MoreVertRoundedIcon style={{ fontSize: 20 }} />
          </IconButton>

          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={handleEdit}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <EditRoundedIcon style={{ fontSize: 16 }} />
              </ListItemIcon>
              <ListItemText secondary="Edit" />
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem onClick={handleDelete}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <DeleteRoundedIcon style={{ fontSize: 16, color: "#d64253" }} />
              </ListItemIcon>
              <ListItemText
                secondary={<span style={{ color: "#d64253" }}>Delete</span>}
              />
            </StyledMenuItem>
          </StyledMenu>
        </ListItemSecondaryAction>
      </ListItem>

      <DialogEditPosition
        classes={classes}
        item={item}
        openEditPosition={openEditPosition}
        setOpenEditPosition={setOpenEditPosition}
        onTriggerPosition={onTriggerPosition}
      />

      <DialogDeletePosition
        classes={classes}
        item={item}
        openDeletePosition={openDeletePosition}
        setOpenDeletePosition={setOpenDeletePosition}
        onTriggerPosition={onTriggerPosition}
      />
    </>
  );
};

export default ListItemPosition;
