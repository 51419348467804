import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from "@material-ui/core";

function EnhancedTableHead({
  classes,
  numSelected,
  rowCount,
  onSelectAllClick,
}) {
  return (
    <TableHead>
      <TableRow style={{borderTop: "1px solid rgba(224, 224, 224, 0.4)"}}>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" className={classes.title333} style={{textAlign: "left"}}>
            <b>No</b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title333} style={{textAlign: "left"}}>
            <b>Nama Istirahat</b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" className={classes.title333} style={{textAlign: "left"}}>
            <b>Tanggal Dibuat</b>
          </Typography>
        </TableCell>

        <TableCell align="left" style={{width: "300px"}}>
          <Typography variant="subtitle2" className={classes.title333} style={{textAlign: "left"}}>
            <b>List Shift</b>
          </Typography>
        </TableCell>
        
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
