import React, { Fragment, useState, useEffect, useContext } from "react";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import ContextTask from "../../../../Context/ContextTask";

const useStyles = makeStyles((theme) => ({
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 5,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#b1354a", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a", // #106ba3 #b1354a
    },
  },
  statusItem: {
    color: "#fff",
    fontSize: 12,
    borderRadius: 15,
    padding: "3px 7px",
    width: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FilterStatus = ({ onStatusId }) => {
  const classes = useStyles();
  const { todoState } = useContext(ContextTask);

  const [selected, setSelected] = useState(todoState.statusId);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    onStatusId(selected);
  }, [selected]);

  return (
    <Box pt={1} pb={2} px={3} display="flex" flexWrap="wrap">
      {statusCollections.map((item, i) => {
        const isItemSelected = isSelected(item.id);

        const handleChangeCheckbox = (item) => {
          const selectedIndex = selected.indexOf(item.id);

          let newSelected = [];

          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item.id);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1)
            );
          }

          // // console.log("selected ALL MA", wrapSelected);

          setSelected(newSelected);
        };

        return (
          <Fragment key={i}>
            <FormControlLabel
              label={
                <p
                  style={{ backgroundColor: item.color, fontSize: 14 }}
                  className={classes.statusItem}
                >
                  {item.name}
                </p>
              }
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  disableRipple
                  color="default"
                  checked={isItemSelected}
                  onChange={() => handleChangeCheckbox(item)}
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                />
              }
            />
          </Fragment>
        );
      })}
    </Box>
  );
};

export default FilterStatus;

const statusCollections = [
  {
    id: "TO DO",
    code: "TO DO",
    name: "To Do",
    color: "#888888",
  },
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    color: "#73CDEE",
  },
  {
    id: "DONE",
    code: "DONE",
    name: "Done",
    color: "#74CB80",
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    color: "#EFCB0F",
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    color: "#92739F",
  },
];
