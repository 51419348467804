import React, { Fragment } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  IconButton,
} from "@material-ui/core";

import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { navigate } from "hookrouter";

function UserListGallery({ classes, item }) {
  const handleNavigateGallery = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    breadcrumb.push(`${item.member_first_name} ${item.member_last_name}`);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#fffee0" }}>
        <TableCell padding="checkbox" />
        <TableCell>
          <Box display="flex" alignItems="center">
            <Avatar
              style={{
                width: 34,
                height: 34,
                backgroundColor: "#ffbe76",
              }}>
              <PersonRoundedIcon style={{ fontSize: 22, color: "#e67e22" }} />
            </Avatar>

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15 }}
              onClick={() => handleNavigateGallery(item)}>
              {item.member_first_name === null
                ? "Vacant"
                : `${item.member_first_name} ${item.member_last_name}`}
            </Typography>

            <span style={{ color: "#999", marginLeft: 5, marginRight: 5 }}>
              -
            </span>

            <Typography style={{ color: "#999", fontSize: 13 }}>
              {item.structure_position_title_name}
            </Typography>
          </Box>
        </TableCell>

        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />

        <TableCell align="right">
          <IconButton>
            <MoreVertIcon style={{ color: "transparent" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default UserListGallery;
