import React, { forwardRef } from "react";
import {
  Dialog,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Table from "./Table";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTableFullScreen = ({
  classes,
  openDialogFullScreen,
  setOpenDialogFullScreen,
  rate,
}) => {
  const handleClose = () => setOpenDialogFullScreen(false);

  return (
    <Dialog
      fullScreen
      open={openDialogFullScreen}
      TransitionComponent={Transition}>
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}>
        <Toolbar>
          <IconButton
            size="small"
            edge="start"
            aria-label="close"
            onClick={handleClose}>
            <CloseIcon style={{ fontSize: 18, color: "#555" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box display="flex" justifyContent="center" mt={4}>
        <Typography className={classes.title555} variant="h5">
          <b>Table Summary</b>
        </Typography>
      </Box>

      <Box p={8}>
        <Paper className={classes.shadowSection} style={{ padding: 20 }}>
          <Table classes={classes} rate={rate} />
        </Paper>
      </Box>
    </Dialog>
  );
};

export default DialogTableFullScreen;
