import React, { useState, useContext } from "react";
import { MenuItem, Typography, Divider } from "@material-ui/core";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
// import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
// import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
// import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
// import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
// import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import ContextReports from "../../context/ContextReports";
import DialogSaveConfig from "./DialogSaveConfig";
import DialogShareReport from "./DialogShareReport";
import DialogExport from "./DialogExport";
import DialogExportSummary from "./DialogExportSummary";

const ListItemConfig = ({ classes }) => {
  const { reportState } = useContext(ContextReports);

  const [openDialogExport, setOpenDialogExport] = useState(false);
  const [openDialogSaveConfig, setOpenDialogSaveConfig] = useState(false);
  const [openDialogShareReport, setOpenDialogShareReport] = useState(false);
  const [openDialogExportSummary, setOpenDialogExportSummary] = useState(false);

  const handleDialogExport = () => {
    if (reportState.valueTab === 1) {
      setOpenDialogExport(true);
    } else {
      setOpenDialogExportSummary(true);
    }
  };

  return (
    <>
      {/* <MenuItem
        style={{ width: 220 }}
        onClick={() => setOpenDialogSaveConfig(true)}>
        <SaveOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Save Configuration
        </Typography>
      </MenuItem> */}
      {/* <MenuItem
        style={{ width: 220 }}
        onClick={() => setOpenDialogShareReport(true)}>
        <ShareOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Share Report
        </Typography>
      </MenuItem> */}
      <MenuItem
        style={{ width: 220 }}
        // disabled={reportState.valueTab === 2}
        onClick={handleDialogExport}>
        <ExitToAppOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          {reportState.valueTab === 1 ? "Export Detailed" : "Export Summary"}
        </Typography>
      </MenuItem>
      {/* <MenuItem style={{ width: 220 }}>
        <DeleteForeverOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Remove Config & Filters
        </Typography>
      </MenuItem>
      <MenuItem style={{ width: 220 }} disabled>
        <SendOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Save and Submit
        </Typography>
      </MenuItem>

      <Divider />

      <MenuItem style={{ width: 220 }}>
        <DeleteOutlinedIcon
          fontSize="small"
          style={{ color: "#8F8F8F", marginRight: 10 }}
        />
        <Typography variant="subtitle2" className={classes.title}>
          Delete
        </Typography>
      </MenuItem> */}

      <DialogSaveConfig
        classes={classes}
        openDialogSaveConfig={openDialogSaveConfig}
        setOpenDialogSaveConfig={setOpenDialogSaveConfig}
      />
      <DialogShareReport
        classes={classes}
        openDialogShareReport={openDialogShareReport}
        setOpenDialogShareReport={setOpenDialogShareReport}
      />
      <DialogExport
        classes={classes}
        openDialogExport={openDialogExport}
        setOpenDialogExport={setOpenDialogExport}
      />
      <DialogExportSummary
        classes={classes}
        openDialogExportSummary={openDialogExportSummary}
        setOpenDialogExportSummary={setOpenDialogExportSummary}
      />
    </>
  );
};

export default ListItemConfig;
