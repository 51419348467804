import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import DialogSayGoodbye from "./DialogSayGoodbye";

const DialogUnsubscribeMembership = ({
  classes,
  openDialogUnsibscribe,
  setOpenDialogUnsubscribe,
}) => {
  const [openDialogSayGoodbye, setOpenDialogSayGoodbye] = useState(false);

  return (
    <Dialog
      open={openDialogUnsibscribe}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => setOpenDialogUnsubscribe(false)}>
            <HighlightOffRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <WarningRoundedIcon
          style={{ fontSize: 40, color: "#f1c40f", marginBottom: 7 }}
        />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Unsubscribe membership anda ?</b>
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.title}
          style={{ color: "#888", marginTop: 10 }}>
          Unsubscribe membership akan membuat seluruh tim anda kehilangan semua
          access akun Performate.
        </Typography>
      </DialogContent>
      <DialogActions style={{ margin: 10 }}>
        <Button
          variant="outlined"
          fullWidth
          className={classes.button2}
          onClick={() => setOpenDialogSayGoodbye(true)}>
          Ya! Lanjutkan
        </Button>
      </DialogActions>

      <DialogSayGoodbye
        classes={classes}
        openDialogSayGoodbye={openDialogSayGoodbye}
        setOpenDialogSayGoodbye={setOpenDialogSayGoodbye}
        setOpenDialogUnsubscribe={setOpenDialogUnsubscribe}
      />
    </Dialog>
  );
};

export default DialogUnsubscribeMembership;
