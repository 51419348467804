import React, { useContext, useEffect, useState, useRef } from "react";
import { styles } from "../Style/StyleCheckInCheckOut";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Input,
  Grid,
  InputAdornment,
  TextField,
  Card,
} from "@material-ui/core";
import { Room, CameraAlt, Error } from "@material-ui/icons";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Webcam from "react-webcam";

import ContextCheckInCheckOut from "../Context/ContextCheckInCheckOut";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const containerStyle = {
  width: "400px",
  height: "200px",
};

const LiveAttendanceDialog = ({
  handleClose,
  open,
  verification,
  liveAttendanceValue,
  setLiveAttendanceValue,
  verificationMethod,
  isCheckIn,
}) => {
  const webcamRef = useRef();
  const classes = useStyles();
  const context = useContext(ContextCheckInCheckOut);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const center = {
    lat: liveAttendanceValue.lat,
    lng: liveAttendanceValue.long,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBcNgwF6ypP2rL1Ma4FlmH4JjPIGBDI9Ds",
  });

  const [map, setMap] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    navigator.permissions
      .query({ name: "camera" })
      .then((permissionObj) => {
        console.log(permissionObj);
      })
      .catch((error) => {
        // console.log("Got error :", error);
      });
    getLocation();
  }, [isCheckIn]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
              position.coords.latitude +
              "," +
              position.coords.longitude +
              "&key=AIzaSyBcNgwF6ypP2rL1Ma4FlmH4JjPIGBDI9Ds"
          )
            .then((response) => response.json())
            .then((responseJson) => {
              setLiveAttendanceValue({
                ...liveAttendanceValue,
                location: responseJson.results[1].formatted_address,
                lat: position.coords.latitude,
                long: position.coords.longitude,
              });
              center.lat = position.coords.latitude;
              center.lng = position.coords.longitude;
              setPreviewImage(null);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const takePicture = () => {
    console.log(webcamRef.current.getScreenshot());
    setLiveAttendanceValue({
      ...liveAttendanceValue,
      photo: webcamRef.current.getScreenshot(),
    });
    setPreviewImage(webcamRef.current.getScreenshot());
    setOpenCamera(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      {openCamera ? (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Card
              style={{
                margin: "0 auto",
                width: "100%",
                height: "80vh",
                background: "black",
                maxWidth: "500px",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 24,
                borderBottomRightRadius: 24,
                marginBottom: "1rem",
              }}
            >
              <div id="webcam">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
              </div>
              <button
                className={classes.cameraButton}
                onClick={() => takePicture()}
              ></button>
            </Card>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {verificationMethod.check_in === null ? "CHECK IN" : "CHECK OUT"}
          </DialogTitle>
          <DialogContent>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={7}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                <Marker position={center} />
              </GoogleMap>
            ) : (
              <div>Loading...</div>
            )}

            <Grid
              container
              spacing={3}
              style={{
                marginTop: "20px",
              }}
            >
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="input-time">Pukul</InputLabel>
                  <Input
                    id="input-time"
                    type="time"
                    value={liveAttendanceValue.time}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="input-location">Lokasi</InputLabel>
                  <Input
                    id="input-location"
                    startAdornment={
                      <InputAdornment position="start">
                        <Room />
                      </InputAdornment>
                    }
                    value={liveAttendanceValue.location}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              fullWidth
              label="Catatan"
              placeholder="Masukkan deskripsi (Opsional)"
              onChange={(e) => {
                setLiveAttendanceValue({
                  ...liveAttendanceValue,
                  note: e.target.value,
                });
              }}
            />
            <div className={classes.cameraDiv}>
              <Button
                fullWidth
                variant="text"
                color="primary"
                style={{
                  height: "3rem",
                }}
                startIcon={<CameraAlt />}
                onClick={() => setOpenCamera(true)}
              >
                Ambil Foto Selfie
              </Button>
              {previewImage != null && (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    margin: "20px",
                  }}
                >
                  <img src={previewImage} alt="" style={{ width: "150px" }} />
                  <div
                    style={{ position: "absolute", right: -15, top: -15 }}
                    onClick={() => {
                      setLiveAttendanceValue({
                        ...liveAttendanceValue,
                        photo: null,
                      });
                      setPreviewImage(null);
                    }}
                  >
                    <img
                      src={require("../../../../assets/images/ic_minus_red.svg")}
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                color: "#262261",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                fontSize: "x-small",
              }}
            >
              <Error />
              <span style={{ marginLeft: "2px" }}>
                wajib mengupload foto selfie untuk attendance.
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              className={classes.fixedBottomButton}
              style={{
                backgroundColor: "#262261",
                color: "#ffffff",
                fontWeight: "bold",
              }}
              disabled={previewImage === null ? true : false}
              onClick={() => {
                verification();
              }}
            >
              {verificationMethod.check_in === null ? "CHECK IN" : "CHECK OUT"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </MuiThemeProvider>
  );
};

export default LiveAttendanceDialog;
