import React, { Fragment, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";

import DialogChangePassword from "./DialogChangePassword";

function FormChangePassword({ classes }) {
  // Password Form Value
  const [value, setValue] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const [dialogChangePassword, setDialogChangePassword] = useState(false);

  // Show / Hide Password
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideForm, setShowHideForm] = useState(false);

  // Handle Input Form (Password)
  const handleChangePassword = (name, data) => {
    setValue({
      ...value,
      [name]: data,
    });
  };

  // Handle Hide / Show Text Password
  const handleClickShowPassword = () => {
    setShowHidePassword((prevState) => !prevState);
  };

  const handleOpenChangePassword = () => {
    if (
      value.old_password !== "" &&
      value.new_password !== "" &&
      value.new_password_confirmation !== ""
    ) {
      setDialogChangePassword(true);
    }
  };

  return (
    <Fragment>
      <Box style={{ paddingTop: 50, paddingLeft: 60 }}>
        {!showHideForm && (
          <Fragment>
            <Button
              className={classes.buttonOutlined}
              startIcon={<EditIcon />}
              onClick={() => setShowHideForm(true)}>
              Ubah Password
            </Button>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#888", marginTop: 15 }}>
              Disarankan untuk tidak tidak terlalu sering mengubah Password
            </Typography>
          </Fragment>
        )}

        {showHideForm && (
          <Fragment>
            <div className={classes.warningPasswordBg}>
              <Typography
                variant="subtitle1"
                className={classes.warningPasswordTxt}>
                <b>
                  Sebaiknya gunakan kata sandi yang kuat yang tidak Anda gunakan
                  di tempat lain
                </b>
              </Typography>
            </div>

            <Box style={{ marginTop: 25 }}>
              <Typography variant="subtitle1" className={classes.title}>
                <b>Kata Sandi Sekarang</b>
              </Typography>
              <TextField
                id="outlined-bare"
                name="old_password"
                className={classes.textField}
                onChange={(e) =>
                  handleChangePassword(e.target.name, e.target.value)
                }
                value={value.old_password}
                variant="outlined"
                type={showHidePassword ? "text" : "password"}
                fullWidth
                inputProps={{
                  className: classes.title,
                }}
                style={{ width: 700 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {showHidePassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box style={{ marginTop: 35 }}>
              <Typography variant="subtitle1" className={classes.title}>
                <b>Kata Sandi Baru</b>
              </Typography>
              <TextField
                id="outlined-bare"
                name="new_password"
                className={classes.textField}
                onChange={(e) =>
                  handleChangePassword(e.target.name, e.target.value)
                }
                value={value.new_password}
                variant="outlined"
                type="password"
                fullWidth
                inputProps={{
                  className: classes.title,
                }}
                style={{ width: 700 }}
                helperText="Kata sandi baru tidak boleh sama dengan kata sandi lama dan harus terdiri dari 6 karakter"
              />
            </Box>

            <Box style={{ marginTop: 35 }}>
              <Typography variant="subtitle1" className={classes.title}>
                <b>Konfirmasi Kata Sandi</b>
              </Typography>
              <TextField
                id="outlined-bare"
                name="new_password_confirmation"
                className={classes.textField}
                onChange={(e) =>
                  handleChangePassword(e.target.name, e.target.value)
                }
                value={value.new_password_confirmation}
                variant="outlined"
                type="password"
                fullWidth
                inputProps={{
                  className: classes.title,
                }}
                style={{ width: 700 }}
                error={
                  value.new_password === value.new_password_confirmation
                    ? false
                    : true
                }
                helperText={
                  value.new_password === value.new_password_confirmation
                    ? ""
                    : "Konfirmasi password harus cocok!"
                }
              />
            </Box>

            <Box style={{ marginTop: 35, marginLeft: -8 }}>
              <Button
                className={classes.buttonOutlined2}
                onClick={() => setShowHideForm(false)}>
                Batal
              </Button>
              <Button
                className={classes.button2}
                onClick={handleOpenChangePassword}>
                Atur Kata Sandi Baru
              </Button>
            </Box>
          </Fragment>
        )}
      </Box>

      <DialogChangePassword
        dialogChangePassword={dialogChangePassword}
        setDialogChangePassword={setDialogChangePassword}
        classes={classes}
        value={value}
        setValue={setValue}
      />
    </Fragment>
  );
}

export default FormChangePassword;
