import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";
import ContextOvertime from "../Context/ContextOvertime";
import moment from "moment";
import { URL_API } from "../../../../constants/config-api";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const DialogDetail = (props) => {
  const {
    classes,
    isModalDetail,
    setModalDetail,
    userLoginName,
    dataOvertimeList,
    timeConvert
  } = props;


  const context = useContext(ContextOvertime);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );
  const [loader, setLoader] = useState(false);
  let overtime = dataOvertimeList.detail;

  return (
    <Fragment>
      <Dialog
        open={isModalDetail}
        onClose={() => setModalDetail(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='lg'
        scroll='paper'
        style={{paddingBottom : "30px"}}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
        >
          <Typography variant="h4">Detail Overtime</Typography>
        </DialogTitle>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={12}>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color : "#d64253"}}>
                <b>Information</b>
              </Typography>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={6}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Nama Lengkap</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.employee && overtime.employee.member ?
                            overtime.employee.member.first_name + " " + overtime.employee.member.last_name:
                            "" 
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={6} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Tanggal</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.workday ? 
                            moment(overtime.workday).format("DD-MM-YYYY") :
                            "" 
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={12}>
            <DialogContent style={{ textAlign: "left" }} className={classes.noteContent}>
              <Grid container className={classes.grid12Center}>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Note</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>: { }
                      { overtime.description }
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={12}>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color : "#d64253"}}>
                <b>Schedule</b>
              </Typography>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={6}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Compensation Type</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.compensation_overtime_category ?
                            overtime.compensation_overtime_category.compensation_name :
                            "" 
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={6} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Shift</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.shift?.title
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={6}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Schedule In</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.shift?.schedule_in
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={6} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Schedule Out</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.shift?.schedule_out
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={12}>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color : "#d64253"}}>
                <b>Overtime</b>
              </Typography>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={6}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Schedule In</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.schedule_in
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={6} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Schedule Out</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          overtime.schedule_out
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "100px"}}>
          <Grid item md={6}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Overtime Duration</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          timeConvert(overtime.overtime_duration)
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={6} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Rest Duration</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          timeConvert(overtime.rest_duration)
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
}

export default DialogDetail;