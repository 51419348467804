import React, { useContext, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Slide,
  Checkbox,
  Tooltip,
  Grid,
  TextField,
} from "@material-ui/core";

import moment from "moment";

import { A } from "hookrouter";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import ContextTimeOffCategoryAllowance from "../Context/ContextTimeOffCategoryAllowance";
import { Button } from "react-bootstrap";

const ListCardItemUser = ({
  classes,
  item,
  dataUserList,
  setDataUserList,
  handleSaveRemaining,
  keyItem,
}) => {
  const context = useContext(ContextTimeOffCategoryAllowance);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;
  const [anchorEl, setAnchorEl] = useState(null);

  // Function Show Dropdown List
  function handleDropdownOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
  }

  function handleDialogDelete() {}

  const handleRightClick = (data) => {
    // // console.log("Data goal", data);
  };

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit key={keyItem}>
      <tr key={keyItem}>
        <td>
          <Paper
            className={classes.shadowSection}
            style={{
              position: "relative",
              marginLeft: "-1px",
              marginRight: "-1px",
            }}
          >
            <Box
              p={1}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Box width={50} ml={2}>
                  <Typography variant="subtitle1" className={classes.title333}>
                    {(dataUserList.options.paging.page - 1) * dataUserList.options.paging.limit + keyItem + 1}
                  </Typography>
                </Box>
                <Box ml={2} width={350} display="flex" alignItems="center">
                  {item.user !== null &&
                  item.user.member !== null &&
                  item.user.member.photo_url !== null &&
                  item.user.member.self.rel.photo_url !== null ? (
                    <ImageSmallUser
                      item={item.user.member.self.rel.photo_url}
                      index={1}
                    />
                  ) : (
                    <AccountCircleRoundedIcon style={{ color: "#c4c5c4" }} />
                  )}

                  <Box ml={2}>
                    <Typography
                      variant="subtitle1"
                      className={classes.title333}
                    >
                      {TruncateTextCustom(
                        `${item?.user?.member?.first_name} ${item?.user?.member?.last_name}`,
                        17
                      )}
                    </Typography>
                    <Typography variant="caption" className={classes.title888}>
                      <span>
                        {item.owner
                          ? TruncateTextCustom(
                              item.owner?.structure_position_title_name,
                              18
                            )
                          : "-"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.boxTable}
              >
                <Box width={"40%"}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                    style={{ textAlign: "left" }}
                  >
                    {item.allowance}
                  </Typography>
                </Box>

                <Box width={"40%"}>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="number"
                        style={{ width: "125px" }}
                        placeholder="e.g. 1, 2..."
                        value={item.remaining}
                        onChange={(event, newValue) => {
                          let dataTemporary = dataUserList;
                          let keyTemp = (dataUserList.options.paging.page - 1) * dataUserList.options.paging.limit + keyItem;
                          dataTemporary.listTemp[keyTemp].remaining = event.target.value;
                          setDataUserList({ ...dataTemporary });
                        }}
                        error={context.listError.some(
                          (item) => "remaining" === item.key
                        )}
                        helperText={context.listError.map((item) => {
                          if (item.key === "remaining") {
                            return item.description;
                          }
                        })}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box width={"20%"}>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    style={{
                      marginLeft: "0px",
                      padding: "10px",
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      handleSaveRemaining(item);
                    }}
                  >
                    Simpan Remaining
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </td>
      </tr>
    </Slide>
  );
};

export default ListCardItemUser;
